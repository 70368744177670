import { Brands, Disclaimers, Languages, LicenseTypes } from '../Helpers/enums';

export class CreatePersonalMailboxRequest {
    requests: Array<CreatePersonalMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class CreatePersonalMailboxFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    PrimarySMTP: string = '';
    PrimarySMTPDomain: string = '';
    //DisplayNameLabel:string = '';
    MailAddresses: Array<string> = new Array<string>('');
    Initials: string = '';
    //Company:string = '';
    Brand: string = '';
    Office365Licenses: string = 'STANDARDPACK';
    MailboxLanguage: string = 'en-US';
    EnableArchive: boolean = false;
    EnableLitigationHold: boolean = false;
    LitigationHoldDuration: string = '';
    Hidden: boolean = false;
    External: boolean = false;
    //Disclaimer:string = '';
    //CustomDisplayName:string = '';
    Merge: boolean = false;
    MEUExternalEmailAddress: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.PrimarySMTP = fileContent.PrimarySMTP;
            this.PrimarySMTPDomain = fileContent.PrimarySMTPDomain;
            //this.DisplayNameLabel = fileContent.DisplayNameLabel;
            this.MailAddresses = fileContent.MailAddresses;
            this.Initials = fileContent.Initials;
            //this.Company = fileContent.Company;
            this.Brand = fileContent.Brand;
            this.Office365Licenses = fileContent.Office365Licenses;
            this.MailboxLanguage = fileContent.MailboxLanguage;
            this.EnableArchive = fileContent.EnableArchive;
            this.EnableLitigationHold = fileContent.EnableLitigationHold;
            this.LitigationHoldDuration = fileContent.LitigationHoldDuration;
            this.Hidden = fileContent.Hidden;
            this.External = fileContent.External;
            //this.Disclaimer = fileContent.Disclaimer;
            //this.CustomDisplayName = fileContent.CustomDisplayName;
            this.Merge = fileContent.Merge;
            this.MEUExternalEmailAddress = fileContent.MEUExternalEmailAddress;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreatePersonalMailboxCsvErrorFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDInvalid: boolean = false;
    PrimarySMTP: string = '';
    PrimarySMTPInvalid: boolean = false
    PrimarySMTPDomain: string = '';
    PrimarySMTPDomainInvalid: boolean = false;
    MailAddresses: Array<string> = new Array<string>('');
    MailAddressesInvalid: Array<boolean> = new Array<boolean>(false);
    Initials: string = '';
    InitialsInvalid: boolean = false;
    Brand: string = '';
    BrandInvalid: boolean = false;
    Office365Licenses: string = 'STANDARDPACK';
    Office365LicensesInvalid: boolean = false;
    MailboxLanguage: string = 'en-US';
    MailboxLanguageInvalid: boolean = false;
    EnableArchive: boolean = false;
    EnableArchiveInvalid: boolean = false;
    EnableLitigationHold: boolean = false;
    EnableLitigationHoldInvalid: boolean = false;
    LitigationHoldDuration: string = '';
    LitigationHoldDurationInvalid: boolean = false;
    Hidden: boolean = false;
    HiddenInvalid: boolean = false;
    External: boolean = false;
    ExternalInvalid: boolean = false;
    Merge: boolean = false;
    MergeInvalid: boolean = false;
    MEUExternalEmailAddress: string = '';
    MEUExternalEmailAddressInvalid: boolean = false;
    CsvFileName: string = '';
    CsvImportLine: number = null;
    FileContentInEditMode: boolean = false;
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDInvalid = fileContent.GroupIDInvalid;
            this.PrimarySMTP = fileContent.PrimarySMTP;
            this.PrimarySMTPInvalid = fileContent.PrimarySMTPInvalid;
            this.PrimarySMTPDomain = fileContent.PrimarySMTPDomain;
            this.PrimarySMTPDomainInvalid = fileContent.PrimarySMTPDomainInvalid;
            this.MailAddresses = fileContent.MailAddresses;
            this.MailAddressesInvalid = fileContent.MailAddressesInvalid;
            this.Initials = fileContent.Initials;
            this.InitialsInvalid = fileContent.InitialsInvalid;
            this.Brand = fileContent.Brand;
            this.BrandInvalid = fileContent.BrandInvalid;
            this.Office365Licenses = fileContent.Office365LicensesInvalid ? 'STANDARDPACK' : fileContent.Office365Licenses;
            this.Office365LicensesInvalid = fileContent.Office365LicensesInvalid;
            this.MailboxLanguage = fileContent.MailboxLanguageInvalid ? 'en-US' : fileContent.MailboxLanguage;
            this.MailboxLanguageInvalid = fileContent.MailboxLanguageInvalid;
            this.EnableArchive = fileContent.EnableArchiveInvalid ? false : fileContent.EnableArchive;
            this.EnableArchiveInvalid = fileContent.EnableArchiveInvalid;
            this.EnableLitigationHold = fileContent.EnableLitigationHold;
            this.EnableLitigationHoldInvalid = fileContent.EnableLitigationHoldInvalid
            this.LitigationHoldDuration = fileContent.LitigationHoldDurationInvalid ? '' : fileContent.LitigationHoldDuration;
            this.LitigationHoldDurationInvalid = fileContent.LitigationHoldDurationInvalid;
            this.Hidden = fileContent.HiddenInvalid ? false : fileContent.Hidden;
            this.HiddenInvalid = fileContent.HiddenInvalid;
            this.External = fileContent.ExternalInvalid ? false : fileContent.External;
            this.ExternalInvalid = fileContent.ExternalInvalid;
            this.Merge = fileContent.MergeInvalid ? false : fileContent.Merge;
            this.MergeInvalid = fileContent.MergeInvalid;
            this.MEUExternalEmailAddress = fileContent.MEUExternalEmailAddress;
            this.MEUExternalEmailAddressInvalid = fileContent.MEUExternalEmailAddressInvalid;
            this.CsvFileName = fileContent.CsvFileName;
            this.CsvImportLine = fileContent.CsvImportLine;
            this.FileContentInEditMode = fileContent.FileContentInEditMode;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetDistributionListMembersRequest {
    requests: Array<GetDistributionListMembersFileContent>;
    localIT: String;

    constructor() { }
}

export class GetDistributionListMembersFileContent {
    ListID: string = '';
    Recursive: boolean = false;
    TicketID: string = '';
    Product: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.Recursive = fileContent.Recursive;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetIdentityPersonalDataRequest {
    requests: Array<GetIdentityPersonalDataFileContent>;
    localIT: String;

    constructor() { }
}

export class GetIdentityPersonalDataFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetUserInfoRequest {
    requests: Array<GetUserInfoFileContent>;
    localIT: String;

    constructor() { }
}

export class GetUserInfoFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    InformationToShow: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.InformationToShow = fileContent.InformationToShow;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetMailboxInfoRequest {
    requests: Array<GetMailboxInfoFileContent>;
    localIT: String;

    constructor() { }
}

export class GetMailboxInfoFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddOktaUserToGroupRequest {
    requests: Array<AddOktaUserToGroupFileContent>;
    localIT: String;

    constructor() { }
}

export class AddOktaUserToGroupFileContent {
    OktaUserNames: Array<string> = new Array('');
    OktaGroupName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaUserNames = fileContent.OktaUserNames;
            this.OktaGroupName = fileContent.OktaGroupName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetOktaGroupMembersRequest {
    requests: Array<GetOktaGroupMembersFileContent>;
    localIT: String;

    constructor() { }
}

export class GetOktaGroupMembersFileContent {
    OktaGroupName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaGroupName = fileContent.OktaGroupName;
            this.TicketID = fileContent.TicketID;
        }
    }
}
export class RemoveOktaUserFromGroupRequest {
    requests: Array<RemoveOktaUserFromGroupFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveOktaUserFromGroupFileContent {
    OktaUserNames: Array<string> = new Array('');
    OktaGroupName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaUserNames = fileContent.OktaUserNames;
            this.OktaGroupName = fileContent.OktaGroupName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ResetOktaUserMfaRequest {
    requests: Array<ResetOktaUserMfaFileContent>;
    localIT: String;

    constructor() { }
}

export class ResetOktaUserMfaFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    OktaMfaProvider: string = '';
    Platform: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.OktaMfaProvider = fileContent.OktaMfaProvider;
            this.Platform = fileContent.Platform;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ResetOktaB2BUserMfaRequest {
    requests: Array<ResetOktaB2BUserMfaFileContent>;
    localIT: String;

    constructor() { }
}

export class ResetOktaB2BUserMfaFileContent {
    GroupID: string = '';
    OktaUsername: string = '';
    OktaMfaProvider: string = '';
    Platform: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.OktaUsername = fileContent.OktaUsername;
            this.OktaMfaProvider = fileContent.OktaMfaProvider;
            this.Platform = fileContent.Platform;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddDistributionListOwnerRequest {
    requests: Array<AddDistributionListOwnerFileContentForSending>;
    localIT: String;

    constructor() { }
}

export class AddDistributionListOwnerFileContent {
    ListID: string = '';
    AdditionalOwners: Array<string> = new Array<string>('');
    AdditionalOwnersSendAs: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.AdditionalOwners = fileContent.AdditionalOwners;
            this.AdditionalOwnersSendAs = fileContent.AdditionalOwnersSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddDistributionListOwnerFileContentForSending {
    ListID: string = '';
    AdditionalOwners: string = '';
    AdditionalOwnersSendAs: boolean = false;
    TicketID: string = '';

    constructor(unformatedAddDistributionListOwnerFileContent?: AddDistributionListOwnerFileContent) {
        if (unformatedAddDistributionListOwnerFileContent) {
            this.ListID = unformatedAddDistributionListOwnerFileContent.ListID;
            this.AdditionalOwners = unformatedAddDistributionListOwnerFileContent.AdditionalOwners.toString();
            this.AdditionalOwnersSendAs = unformatedAddDistributionListOwnerFileContent.AdditionalOwnersSendAs;
            this.TicketID = unformatedAddDistributionListOwnerFileContent.TicketID;
        }
    }
}

export class RemoveDistributionListOwnerRequest {
    requests: Array<RemoveDistributionListOwnerFileContentForSending>;
    localIT: String;

    constructor() { }
}

export class RemoveDistributionListOwnerFileContent {
    ListID: string = '';
    OwnersToRemove: Array<string> = new Array<string>('');
    RemoveOwnersSendAs: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.OwnersToRemove = fileContent.OwnersToRemove;
            this.RemoveOwnersSendAs = fileContent.RemoveOwnersSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveDistributionListOwnerFileContentForSending {
    ListID: string = '';
    OwnersToRemove: string = '';
    RemoveOwnersSendAs: boolean = false;
    TicketID: string = '';

    constructor(unformatedRemoveDistributionListOwnerFileContent?: RemoveDistributionListOwnerFileContent) {
        if (unformatedRemoveDistributionListOwnerFileContent) {
            this.ListID = unformatedRemoveDistributionListOwnerFileContent.ListID;
            this.OwnersToRemove = unformatedRemoveDistributionListOwnerFileContent.OwnersToRemove.toString();
            this.RemoveOwnersSendAs = unformatedRemoveDistributionListOwnerFileContent.RemoveOwnersSendAs;
            this.TicketID = unformatedRemoveDistributionListOwnerFileContent.TicketID;
        }
    }
}

export class ChangeDistributionListOwnerRequest {
    requests: Array<ChangeDistributionListOwnerFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeDistributionListOwnerFileContent {
    Owner: string = '';
    OwnerDisplayName: string = '';
    ListID: string = '';
    OwnerSendAs: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Owner = fileContent.Owner;
            this.OwnerDisplayName = fileContent.OwnerDisplayName;
            this.ListID = fileContent.ListID;
            this.OwnerSendAs = fileContent.OwnerSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddDistributionListMembersRequest {
    requests: Array<AddDistributionListMembersFileContent>;
    localIT: String;

    constructor() { }
}

export class AddDistributionListMembersFileContent {
    ListID: string = '';
    Members: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.Members = fileContent.Members;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableMobileDeviceRequest {
    requests: Array<EnableMobileDeviceFileContentForSending>;
    localIT: String;

    constructor() { }
}

export class EnableMobileDeviceFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    DeviceIDs: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.DeviceIDs = fileContent.DeviceIDs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableMobileDeviceFileContentForSending {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    DeviceIDs: string = '';
    TicketID: string = '';

    constructor(unformatedEnableMobileDeviceFileContent?: EnableMobileDeviceFileContent) {
        if (unformatedEnableMobileDeviceFileContent) {
            this.GroupID = unformatedEnableMobileDeviceFileContent.GroupID;
            this.GroupIDDisplayName = unformatedEnableMobileDeviceFileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = unformatedEnableMobileDeviceFileContent.GroupIDDisplayNameError;
            this.DeviceIDs = unformatedEnableMobileDeviceFileContent.DeviceIDs.toString();
            this.TicketID = unformatedEnableMobileDeviceFileContent.TicketID;
        }
    }
}

export class MailboxDelegationRequest {
    requests: Array<MailboxDelegationFileContent>;
    localIT: String;
    constructor() { }
}
export class MailboxDelegationFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    FullAccessUsers: Array<string> = new Array<string>('');
    AddSendAs: boolean = false;
    RemoveSendAs: boolean = false;
    PermissionToAdd: string = '';
    SendAsUsers: Array<string> = new Array<string>('');
    SendOnBehalfUsers: Array<string> = new Array<string>('');    
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.FullAccessUsers = fileContent.FullAccessUsers;
            this.AddSendAs = fileContent.AddSendAs;
            this.RemoveSendAs = fileContent.RemoveSendAs;
            this.PermissionToAdd = fileContent.PermissionToAdd;
            this.SendAsUsers = fileContent.SendAsUsers;
            this.SendOnBehalfUsers = fileContent.SendOnBehalfUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddMailboxFullAccessRequest {
    requests: Array<AddMailboxFullAccessFileContent>;
    localIT: String;

    constructor() { }
}

export class AddMailboxFullAccessFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    FullAccessUsers: Array<string> = new Array<string>('');
    AddSendAs: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.FullAccessUsers = fileContent.FullAccessUsers;
            this.AddSendAs = fileContent.AddSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveMailboxFullAccessRequest {
    requests: Array<RemoveMailboxFullAccessFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveMailboxFullAccessFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    FullAccessUsers: Array<string> = new Array<string>('');
    RemoveSendAs: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.FullAccessUsers = fileContent.FullAccessUsers;
            this.RemoveSendAs = fileContent.RemoveSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddMailboxSendAsRequest {
    requests: Array<AddMailboxSendAsFileContent>;
    localIT: String;

    constructor() { }
}

export class AddMailboxSendAsFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    SendAsUsers: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.SendAsUsers = fileContent.SendAsUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveMailboxSendAsRequest {
    requests: Array<RemoveMailboxSendAsFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveMailboxSendAsFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    SendAsUsers: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.SendAsUsers = fileContent.SendAsUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AssignLicenseRequest {
    requests: Array<AssignLicenseFileContent>;
    localIT: String;

    constructor() { }
}

export class AssignLicenseFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    LicenseSKU: string = '';
    CsvFileName: string = '';
    CsvImportLine: number = null;
    FileContentInEditMode: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.LicenseSKU = fileContent.LicenseSKU;
            this.CsvFileName = fileContent.CsvFileName;
            this.CsvImportLine = fileContent.CsvImportLine;
            this.FileContentInEditMode = fileContent.FileContentInEditMode;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AssignLicenseCsvErrorFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDInvalid: boolean = false;
    LicenseSKU: string = '';
    LicenseSKUInvalid: boolean = false;
    CsvFileName: string = '';
    CsvImportLine: number = null;
    FileContentInEditMode: boolean = false;
    TicketID: string = '';

    constructor(fileContent?, defaultLicense?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDInvalid = fileContent.GroupIDInvalid;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.LicenseSKU = fileContent.LicenseSKUInvalid ? defaultLicense : fileContent.LicenseSKU;
            this.LicenseSKUInvalid = fileContent.LicenseSKUInvalid;
            this.CsvFileName = fileContent.CsvFileName;
            this.CsvImportLine = fileContent.CsvImportLine;
            this.FileContentInEditMode = fileContent.FileContentInEditMode;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableLitigationHoldRequest {
    requests: Array<EnableLitigationHoldFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableLitigationHoldFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    LitigationHoldDuration: number = 7;
    LitigationHoldOwner: string = '';
    AssignOrUpgradeLicense: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.LitigationHoldDuration = fileContent.LitigationHoldDuration;
            this.LitigationHoldOwner = fileContent.LitigationHoldOwner;
            this.AssignOrUpgradeLicense = fileContent.AssignOrUpgradeLicense;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateDistributionListRequest {
    requests: Array<CreateDistributionListFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateDistributionListFileContent {
    Name: string = '';
    DLType: string = 'Security';
    Discriminant: string = '';
    Owner: string = '';
    OwnerDisplayName: string = '';
    AdditionalOwners: Array<string> = new Array<string>('');
    PrimarySmtp: string = '';
    PrimarySmtpDomain: string = '';
    Members: Array<string> = new Array<string>('');
    Moderators: Array<string> = new Array<string>('');
    AcceptFrom: Array<string> = new Array<string>('');
    RejectFrom: Array<string> = new Array<string>('');
    MailAddresses: Array<string> = new Array<string>('');
    Hidden: boolean = false;
    HideInternet: boolean = false;
    OwnerSendAs: boolean = false;
    AdditionalOwnersSendAs: boolean = false;
    TicketID: string = '';

    constructor(createDistributionListData?) {
        if (createDistributionListData) {
            this.Name = createDistributionListData.Name;
            this.DLType = createDistributionListData.DLType;
            this.Discriminant = createDistributionListData.Discriminant;
            this.Owner = createDistributionListData.Owner;
            this.OwnerDisplayName = createDistributionListData.OwnerDisplayName
            this.AdditionalOwners = createDistributionListData.AdditionalOwners && createDistributionListData.AdditionalOwners.length == 1 && createDistributionListData.AdditionalOwners[0] == '' ? new Array<string>() : createDistributionListData.AdditionalOwners;
            this.PrimarySmtp = createDistributionListData.PrimarySmtp;
            this.PrimarySmtpDomain = createDistributionListData.PrimarySmtpDomain;
            this.Members = createDistributionListData.Members && createDistributionListData.Members.length == 1 && createDistributionListData.Members[0] == '' ? new Array<string>() : createDistributionListData.Members;
            this.Moderators = createDistributionListData.Moderators && createDistributionListData.Moderators.length == 1 && createDistributionListData.Moderators[0] == '' ? new Array<string>() : createDistributionListData.Moderators;
            this.AcceptFrom = createDistributionListData.AcceptFrom && createDistributionListData.AcceptFrom.length == 1 && createDistributionListData.AcceptFrom[0] == '' ? new Array<string>() : createDistributionListData.AcceptFrom;
            this.RejectFrom = createDistributionListData.RejectFrom && createDistributionListData.RejectFrom.length == 1 && createDistributionListData.RejectFrom[0] == '' ? new Array<string>() : createDistributionListData.RejectFrom;
            this.MailAddresses = createDistributionListData.MailAddresses && createDistributionListData.MailAddresses.length == 1 && createDistributionListData.MailAddresses[0] == '' ? new Array<string>() : createDistributionListData.MailAddresses;
            this.Hidden = createDistributionListData.Hidden;
            this.HideInternet = createDistributionListData.HideInternet;
            this.OwnerSendAs = createDistributionListData.OwnerSendAs;
            this.AdditionalOwnersSendAs = createDistributionListData.AdditionalOwnersSendAs;
            this.TicketID = createDistributionListData.TicketID;
        }
    }
}

export class AddMailboxSendOnBehalfRequest {
    requests: Array<AddMailboxSendOnBehalfFileContent>;
    localIT: String;

    constructor() { }
}

export class AddMailboxSendOnBehalfFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    SendOnBehalfUsers: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.SendOnBehalfUsers = fileContent.SendOnBehalfUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveMailboxSendOnBehalfRequest {
    requests: Array<RemoveMailboxSendOnBehalfFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveMailboxSendOnBehalfFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    SendOnBehalfUsers: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.SendOnBehalfUsers = fileContent.SendOnBehalfUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableArchiveOnlineRequest {
    requests: Array<EnableArchiveOnlineFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableArchiveOnlineFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ChangeMailboxDisplayNameRequest {
    requests: Array<ChangeMailboxDisplayNameFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeMailboxDisplayNameFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    ExtensionAttribute11: string = '';
    Discriminant: String = '';
    CountryCode: string = '';
    Site: string = '';
    Name: String = '';
    Brand: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.ExtensionAttribute11 = fileContent.ExtensionAttribute11;
            this.Discriminant = fileContent.Discriminant;
            this.Name = fileContent.Name;
            this.CountryCode = fileContent.CountryCode;
            this.Site = fileContent.Site;
            this.Brand = fileContent.Brand;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ChangeMailboxManagerRequest {
    requests: Array<ChangeMailboxManagerFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeMailboxManagerFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Manager: String = '';
    ManagerDisplayName: string = '';
    ManagerSendAs: Boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Manager = fileContent.Manager;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerSendAs = fileContent.ManagerSendAs;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ChangeMailboxLanguageRequest {
    requests: Array<ChangeMailboxLanguageFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeMailboxLanguageFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    Language: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.Language = fileContent.Language;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ChangeMailboxPrimarySMTPRequest {
    requests: Array<ChangeMailboxPrimarySMTPFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeMailboxPrimarySMTPFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    PrimarySmtp: string = '';
    SIPAddress: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.PrimarySmtp = fileContent.PrimarySmtp;
            this.SIPAddress = fileContent.SIPAddress;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ExpireOktaCloudAccountPasswordRequest {
    requests: Array<ExpireOktaCloudAccountPasswordFileContent>;
    localIT: String;

    constructor() { }
}

export class ExpireOktaCloudAccountPasswordFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DisableLitigationHoldRequest {
    requests: Array<DisableLitigationHoldFileContent>;
    localIT: String;

    constructor() { }
}

export class DisableLitigationHoldFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateSharedMailboxRequest {
    requests: Array<CreateSharedMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateSharedMailboxFileContent {
    Name: string = '';
    Manager: string = '';
    ManagerDisplayName: string = '';
    ManagerSendAs: boolean = false;
    PrimarySMTP: string = '';
    PrimarySMTPDomain: string = '';
    Discriminant: string = '';
    MailboxLanguage: string = 'en-US';
    EnableArchive: boolean = false;
    Hidden: boolean = false;
    EnableLitigationHold: boolean = false;
    LitigationHoldDuration: string = '';
    MailAddresses: Array<string> = new Array<string>('');
    AdditionalManager: Array<string> = new Array<string>('');
    AdditionalManagerSendAs: boolean = false;
    Brand: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.Manager = fileContent.Manager;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerSendAs = fileContent.ManagerSendAs;
            this.PrimarySMTP = fileContent.PrimarySMTP;
            this.PrimarySMTPDomain = fileContent.PrimarySMTPDomain;
            this.Discriminant = fileContent.Discriminant;
            this.MailboxLanguage = fileContent.MailboxLanguage;
            this.EnableArchive = fileContent.EnableArchive;
            this.Hidden = fileContent.Hidden;
            this.EnableLitigationHold = fileContent.EnableLitigationHold;
            this.LitigationHoldDuration = fileContent.LitigationHoldDuration;
            this.MailAddresses = fileContent.MailAddresses && fileContent.MailAddresses.length == 1 && fileContent.MailAddresses[0] == '' ? new Array<string>() : fileContent.MailAddresses;
            this.AdditionalManager = fileContent.AdditionalManager && fileContent.AdditionalManager.length == 1 && fileContent.AdditionalManager[0] == '' ? new Array<string>() : fileContent.AdditionalManager;
            this.AdditionalManagerSendAs = fileContent.AdditionalManagerSendAs;
            this.Brand = fileContent.Brand;
            this.TicketID;
        }
    }
}

export class CreateOktaCloudAccountRequest {
    requests: Array<CreateOktaCloudAccountFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateOktaCloudAccountFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Usage: string = 'O365/AZURE';
    SponsorGroupID: string = '';
    SponsorDisplayName: string = '';
    SponsorDisplayNameError: string = '';
    ProfileType: string = '';
    LastName: string = '';
    FirstName: string = '';
    EmailAddress: string = '';
    Company: string = '';
    CompanyImportedFromSponsor: boolean = false;
    ExternalCompany: string = '';
    GBU: string = '';
    GBUImportedFromSponsor: boolean = false;
    BusinessUnit: string = '';
    BuImportedFromSponsor: boolean = false;
    OrgID: string = '';
    OrgIDImportedFromSponsor: boolean = false;
    CountryCode: string = '';
    ExpirationDate: string = '';
    TicketID: string = '';

    constructor(fileContent?, defaultNull?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Usage = fileContent.Usage;
            this.SponsorGroupID = fileContent.SponsorGroupID;
            this.SponsorDisplayName = fileContent.SponsorDisplayName;
            this.SponsorDisplayNameError = fileContent.SponsorDisplayNameError;
            this.ProfileType = fileContent.ProfileType;
            this.LastName = fileContent.LastName;
            this.FirstName = fileContent.FirstName;
            this.EmailAddress = fileContent.EmailAddress;
            this.Company = fileContent.Company;
            this.ExternalCompany = fileContent.ExternalCompany;
            this.GBU = fileContent.GBU;
            this.BusinessUnit = fileContent.BusinessUnit;
            this.OrgID = fileContent.OrgID;
            this.CountryCode = fileContent.CountryCode;
            this.ExpirationDate = fileContent.ExpirationDate;
            this.TicketID = fileContent.TicketID;
            this.CompanyImportedFromSponsor = fileContent.CompanyImportedFromSponsor;
            this.GBUImportedFromSponsor = fileContent.GBUImportedFromSponsor;
            this.BuImportedFromSponsor = fileContent.BuImportedFromSponsor;
            this.OrgIDImportedFromSponsor = fileContent.OrgIDImportedFromSponsor;
        } else if (defaultNull) {
            this.GroupID = '';
            this.GroupIDDisplayName = '';
            this.GroupIDDisplayNameError = '';
            this.Usage = '';
            this.SponsorGroupID = '';
            this.SponsorDisplayName = '';
            this.SponsorDisplayNameError = '';
            this.ProfileType = '';
            this.LastName = '';
            this.FirstName = '';
            this.EmailAddress = '';
            this.Company = '';
            this.ExternalCompany = '';
            this.GBU = '';
            this.BusinessUnit = '';
            this.OrgID = '';
            this.CountryCode = '';
            this.ExpirationDate = '';
            this.TicketID = '';
            this.CompanyImportedFromSponsor = false;
            this.GBUImportedFromSponsor = false;
            this.BuImportedFromSponsor = false;
            this.OrgIDImportedFromSponsor = false;
        }
    }
}

export class CreateOktaB2BCloudAccountRequest {
    requests: Array<CreateOktaB2BCloudAccountFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateOktaB2BCloudAccountFileContent {
    OktaUsername: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    SponsorGroupID: string = '';
    SponsorDisplayName: string = '';
    SponsorDisplayNameError: string = '';
    LastName: string = '';
    FirstName: string = '';
    Company: string = '';
    CompanyImportedFromSponsor: boolean = false;
    ExternalCompany: string = '';
    OrgID: string = '';
    OrgIDImportedFromSponsor: boolean = false;
    CountryCode: string = '';
    ExpirationDate: string = '';
    OktaGroupName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaUsername = fileContent.OktaUsername;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.SponsorGroupID = fileContent.SponsorGroupID;
            this.SponsorDisplayName = fileContent.SponsorDisplayName;
            this.SponsorDisplayNameError = fileContent.SponsorDisplayNameError;
            this.LastName = fileContent.LastName;
            this.FirstName = fileContent.FirstName;
            this.Company = fileContent.Company;
            this.ExternalCompany = fileContent.ExternalCompany;
            this.OrgID = fileContent.OrgID;
            this.CountryCode = fileContent.CountryCode;
            this.ExpirationDate = fileContent.ExpirationDate;
            this.CompanyImportedFromSponsor = fileContent.CompanyImportedFromSponsor;
            this.OrgIDImportedFromSponsor = fileContent.OrgIDImportedFromSponsor;
            this.OktaGroupName = fileContent.OktaGroupName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UpdateOktaB2BCloudAccountRequest {
    requests: Array<UpdateOktaB2BCloudAccountFileContent>;
    localIT: String;

    constructor() { }
}

export class UpdateOktaB2BCloudAccountFileContent {
    OktaUsername: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    NewOktaUsername: string = '';
    NewOktaUsernameDisplayName: string = '';
    NewOktaUsernameDisplayNameError: string = '';
    SponsorGroupID: string = '';
    SponsorDisplayName: string = '';
    SponsorDisplayNameError: string = '';
    LastName: string = '';
    FirstName: string = '';
    Company: string = '';
    ExternalCompany: string = '';
    OrgID: string = '';
    CountryCode: string = '';
    ExpirationDate: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaUsername = fileContent.OktaUsername;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.NewOktaUsername = fileContent.NewOktaUsername;
            this.NewOktaUsernameDisplayName = fileContent.NewOktaUsernameDisplayName;
            this.NewOktaUsernameDisplayNameError = fileContent.NewOktaUsernameDisplayNameError;
            this.SponsorGroupID = fileContent.SponsorGroupID;
            this.SponsorDisplayName = fileContent.SponsorDisplayName;
            this.SponsorDisplayNameError = fileContent.SponsorDisplayNameError;
            this.LastName = fileContent.LastName;
            this.FirstName = fileContent.FirstName;
            this.Company = fileContent.Company;
            this.ExternalCompany = fileContent.ExternalCompany;
            this.OrgID = fileContent.OrgID;
            this.CountryCode = fileContent.CountryCode;
            this.ExpirationDate = fileContent.ExpirationDate;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class OktaB2BCloudAccountRequest {
    requests: Array<OktaB2BCloudAccountFileContent>;
    localIT: String;

    constructor() { }
}

export class OktaB2BCloudAccountFileContent {
    OktaUsername: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OktaUsername = fileContent.OktaUsername;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DeleteO365ObjectRequest {
    requests: Array<DeleteO365ObjectFileContent>;
    localIT: String;

    constructor() { }
}

export class DeleteO365ObjectFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    NewOwner: string = '';
    NewOwnerDisplayName: string = '';
    PreventMailboxReconnect: boolean = false;
    PreventResubscribeDL: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.NewOwner = fileContent.NewOwner;
            this.NewOwnerDisplayName = fileContent.NewOwnerDisplayName;
            this.PreventMailboxReconnect = fileContent.PreventMailboxReconnect;
            this.PreventResubscribeDL = fileContent.PreventResubscribeDL;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateMEURequest {
    requests: Array<CreateMEUFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateMEUFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    Brand: string = '';
    MailAddresses: Array<string> = new Array<string>('');
    PrimarySmtpAddress: string = '';
    PrimarySmtpDomain: string = '';
    Initials: string = '';
    Merge: boolean = false;
    External: boolean = false;
    Hidden: boolean = false;
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.Brand = fileContent.Brand;
            this.MailAddresses = fileContent.MailAddresses;
            this.PrimarySmtpAddress = fileContent.PrimarySmtpAddress;
            this.PrimarySmtpDomain = fileContent.PrimarySmtpDomain;
            this.Initials = fileContent.Initials;
            this.Merge = fileContent.Merge;
            this.External = fileContent.External;
            this.Hidden = fileContent.Hidden;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateApplicationMailboxRequest {
    requests: Array<CreateApplicationMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateApplicationMailboxFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Name: string = '';
    Manager: string = '';
    ManagerDisplayName: string = '';
    ManagerDisplayNameError = '';
    ManagerSendAs: boolean = false;
    PrimarySMTP: string = '';
    Brand: string = '';
    Office365Licenses: string = '';
    Discriminant: string = '';
    MailboxLanguage: string = 'en-US';
    EnableArchive: boolean = false;
    Hidden: boolean = false;
    EnableLitigationHold: boolean = false;
    LitigationHoldDuration: string = '';
    MailAddresses: Array<string> = new Array<string>('');
    AdditionalManager: Array<string> = new Array<string>('');
    AdditionalManagerSendAs: boolean = false;
    ExternalSend: boolean = false;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Name = fileContent.Name;
            this.Manager = fileContent.Manager;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerDisplayNameError = fileContent.ManagerDisplayNameError;
            this.ManagerSendAs = fileContent.ManagerSendAs;
            this.PrimarySMTP = fileContent.PrimarySMTP;
            this.Brand = fileContent.Brand;
            this.Office365Licenses = fileContent.Office365Licenses;
            this.Discriminant = fileContent.Discriminant;
            this.MailboxLanguage = fileContent.MailboxLanguage;
            this.EnableArchive = fileContent.EnableArchive;
            this.Hidden = fileContent.Hidden;
            this.EnableLitigationHold = fileContent.EnableLitigationHold;
            this.LitigationHoldDuration = fileContent.LitigationHoldDuration;
            this.MailAddresses = fileContent.MailAddresses && fileContent.MailAddresses.length == 1 && fileContent.MailAddresses[0] == '' ? new Array<string>() : fileContent.MailAddresses;
            this.AdditionalManager = fileContent.AdditionalManager && fileContent.AdditionalManager.length == 1 && fileContent.AdditionalManager[0] == '' ? new Array<string>() : fileContent.AdditionalManager;
            this.AdditionalManagerSendAs = fileContent.AdditionalManagerSendAs;
            this.ExternalSend = fileContent.ExternalSend;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateRoomMailboxRequest {
    requests: Array<CreateRoomMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateRoomMailboxFileContent {
    ResourceType: string = 'RoomMailbox';
    Name: string = '';
    CountryCode: string = '';
    Site: string = '';
    Brand: string = '';
    Manager: string = '';
    ManagerDisplayName: string = '';
    ManagerSendAs: boolean = false;
    MailboxLanguage: string = 'en-US';
    Hidden: boolean = false;
    MailAddresses: Array<string> = new Array<string>('');
    AdditionalManager: Array<string> = new Array<string>('');
    AdditionalManagerSendAs: boolean = false;
    ResourceDelegates: Array<string> = new Array<string>('');
    Capacity: number = 0;
    RoomLocation: string = '';
    TimeZone: string = '';
    WorkingHoursStartTime: string = '07:00';
    WorkingHoursEndTime: string = '19:00';
    ScheduleOnlyDuringWorkHours: boolean = false;
    Sensitive: boolean = false;
    BookingProfile: string = 'Open';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ResourceType = fileContent.ResourceType;
            this.Name = fileContent.Name;
            this.CountryCode = fileContent.CountryCode;
            this.Site = fileContent.Site;
            this.Brand = fileContent.Brand;
            this.Manager = fileContent.Manager;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerSendAs = fileContent.ManagerSendAs;
            this.MailboxLanguage = fileContent.MailboxLanguage;
            this.Hidden = fileContent.Hidden;
            this.MailAddresses = fileContent.MailAddresses && fileContent.MailAddresses.length == 1 && fileContent.MailAddresses[0] == '' ? new Array<string>() : fileContent.MailAddresses;
            this.AdditionalManager = fileContent.AdditionalManager && fileContent.AdditionalManager.length == 1 && fileContent.AdditionalManager[0] == '' ? new Array<string>() : fileContent.AdditionalManager;
            this.AdditionalManagerSendAs = fileContent.AdditionalManagerSendAs;
            this.ResourceDelegates = fileContent.ResourceDelegates && fileContent.ResourceDelegates.length == 1 && fileContent.ResourceDelegates[0] == '' ? new Array<string>() : fileContent.ResourceDelegates;;
            this.Capacity = fileContent.Capacity;
            this.RoomLocation = fileContent.RoomLocation;
            this.TimeZone = fileContent.TimeZone;
            this.WorkingHoursStartTime = fileContent.WorkingHoursStartTime;
            this.WorkingHoursEndTime = fileContent.WorkingHoursEndTime;
            this.ScheduleOnlyDuringWorkHours = fileContent.ScheduleOnlyDuringWorkHours;
            this.Sensitive = fileContent.Sensitive;
            this.BookingProfile = fileContent.BookingProfile;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DistributionListMailAddressesRequest {
    requests: Array<DistributionListMailAddressesFileContent>;
    localIT: String;

    constructor() { }
}

export class DistributionListMailAddressesFileContent {
    ListID: string = '';
    MailAddresses: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.MailAddresses = fileContent.MailAddresses && fileContent.MailAddresses.length == 1 && fileContent.MailAddresses[0] == '' ? new Array<string>() : fileContent.MailAddresses;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DistributionListDeliveryRestrictionRequest {
    requests: Array<DistributionListDeliveryRestrictionFileContent>;
    localIT: String;

    constructor() { }
}

export class DistributionListDeliveryRestrictionFileContent {
    ListID: string = '';
    AcceptFrom: Array<string> = new Array<string>('');
    RejectFrom: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.AcceptFrom = fileContent.AcceptFrom && fileContent.AcceptFrom.length == 1 && fileContent.AcceptFrom[0] == '' ? new Array<string>() : fileContent.AcceptFrom;
            this.RejectFrom = fileContent.RejectFrom && fileContent.RejectFrom.length == 1 && fileContent.RejectFrom[0] == '' ? new Array<string>() : fileContent.RejectFrom;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class MailboxMailAddressesRequest {
    requests: Array<MailboxMailAddressesFileContent>;
    localIT: String;

    constructor() { }
}

export class MailboxMailAddressesFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    MailAddresses: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.MailAddresses = fileContent.MailAddresses && fileContent.MailAddresses.length == 1 && fileContent.MailAddresses[0] == '' ? new Array<string>() : fileContent.MailAddresses;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ChangeDistributionListPrimarySMTPRequest {
    requests: Array<ChangeDistributionListPrimarySMTPFileContent>;
    localIT: String;

    constructor() { }
}

export class ChangeDistributionListPrimarySMTPFileContent {
    ListID: string = '';
    PrimarySmtpAddress: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.PrimarySmtpAddress = fileContent.PrimarySmtpAddress;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveDistributionListSendAsRequest {
    requests: Array<RemoveDistributionListSendAsContent>;
    localIT: String;

    constructor() { }
}

export class RemoveDistributionListSendAsContent {
    ListID: string = '';
    SendAsUsers: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.SendAsUsers = fileContent.SendAsUsers;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UndeleteO365ObjectRequest {
    requests: Array<UndeleteO365ObjectFileContent>;
    localIT: String;

    constructor() { }
}

export class UndeleteO365ObjectFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UndeleteDistributionListRequest {
    requests: Array<UndeleteDistributionListFileContent>;
    localIT: String;

    constructor() { }
}

export class UndeleteDistributionListFileContent {
    ListID: String = '';
    ListIDDisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.ListIDDisplayName = fileContent.ListIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}
export class HideUnHideDistributionListRequest {
    requests: Array<HideUnHideDistributionListFileContent>;
    localIT: String;

    constructor() { }
}

export class HideUnHideDistributionListFileContent {
    ListID: String = '';
    Reason: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.Reason = fileContent.Reason;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class HideUnHideMailboxRequest {
    requests: Array<HideUnHideMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class HideUnHideMailboxFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Reason: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Reason = fileContent.Reason;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CheckLocalItsCardsRequest {
    requests: Array<CheckLocalItsCardsFileContent>;
    localIT: String;

    constructor() { }
}

export class CheckLocalItsCardsFileContent {
    Name: String = '';
    Manager: any = '';
    Members: Array<any> = new Array<any>();
    LocalITContactEmail: String = '';
    ContactPhoneNumber: String = '';
    Quotas: any = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.Manager = fileContent.Manager;
            this.Members = fileContent.Members;
            this.LocalITContactEmail = fileContent.LocalITContactEmail;
            this.ContactPhoneNumber = fileContent.ContactPhoneNumber;
            this.Quotas = fileContent.Quotas;
        }
    }
}

export class CreateAADGroupRequest {
    requests: Array<CreateAADGroupFileContent>;
    requestParams: Object;
    localIT: String;

    constructor() { }
}

export class CreateAADGroupFileContent {
    GroupPurpose: String = '';
    GroupDisplayName: String = '';
    GroupDescription: String = '';
    GroupOwners: Array<string> = new Array<string>('');
    WorkplaceType: String = '';
    OrgID: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupPurpose = fileContent.GroupPurpose;
            this.GroupDisplayName = fileContent.GroupDisplayName;
            this.GroupDescription = fileContent.GroupDescription;
            this.GroupOwners = fileContent.GroupOwners;
            this.WorkplaceType = fileContent.WorkplaceType;
            this.OrgID = fileContent.OrgID;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateAADDynamicGroupRequest {
    requests: Array<CreateAADDynamicGroupFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateAADDynamicGroupFileContent {
    OrgID: String = '';
    GroupDisplayName: String = '';
    GroupDescription: String = '';
    GroupOwners: Array<string> = new Array<string>('');
    FilterRules: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.OrgID = fileContent.OrgID;
            this.GroupDisplayName = fileContent.GroupDisplayName;
            this.GroupDescription = fileContent.GroupDescription;
            this.GroupOwners = fileContent.GroupOwners;
            this.FilterRules = fileContent.FilterRules;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateAADDynamicGroupExpression {
    "AndOr" : string = '';
    "Property" : string = '';
    "Operator" : string = '';
    "Value" : string = '';

    constructor(initialValue?) {
        if (initialValue) {
            this.AndOr = initialValue;
        }
    }
};

export class AddIntuneUserRequest {
    requests: Array<AddIntuneUserFileContent>;
    localIT: String;

    constructor() { }
}

export class AddIntuneUserFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    MobileMailClient: String = 'Outlook Mobile';
    Applications: Array<any> = new Array<any>();
    AirWatchMigration: boolean = true;
    ManagedLocalIT = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.MobileMailClient = fileContent.MobileMailClient;
            this.Applications = fileContent.Applications;
            this.AirWatchMigration = fileContent.AirWatchMigration;
            this.ManagedLocalIT = fileContent.ManagedLocalIT;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ModifyIntuneUserRequest {
    requests: Array<ModifyIntuneUserFileContent>;
    localIT: String;

    constructor() { }
}

export class ModifyIntuneUserFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    MobileMailClient: String = '';
    AddApplications: Array<any> = new Array<any>();
    RemoveApplications: Array<any> = new Array<any>();
    AirWatchMigration: boolean;
    OktaMfaBypass: boolean;
    ManagedLocalIT = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.MobileMailClient = fileContent.MobileMailClient;
            this.AddApplications = fileContent.AddApplications;
            this.RemoveApplications = fileContent.RemoveApplications;
            this.AirWatchMigration = fileContent.AirWatchMigration;
            this.OktaMfaBypass = fileContent.OktaMfaBypass;
            this.ManagedLocalIT = fileContent.ManagedLocalIT;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveIntuneUserRequest {
    requests: Array<RemoveIntuneUserFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveIntuneUserFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class BlockUserRequest {
    requests: Array<BlockUserFileContent>;
    localIT: String;

    constructor() { }
}

export class BlockUserFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    DisableMailboxRules: boolean = true;
    RemoveMailboxForwarding: boolean = true;
    RemoveMailboxPermissions: boolean = true;
    RemoveApplicationConsents: boolean = true;
    DisableOneDriveExternalSharing: boolean = true;
    RetireIntuneDevices: boolean = true;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.DisableMailboxRules = fileContent.DisableMailboxRules;
            this.RemoveMailboxForwarding = fileContent.RemoveMailboxForwarding;
            this.RemoveMailboxPermissions = fileContent.RemoveMailboxPermissions;
            this.RemoveApplicationConsents = fileContent.RemoveApplicationConsents;
            this.DisableOneDriveExternalSharing = fileContent.DisableOneDriveExternalSharing;
            this.RetireIntuneDevices = fileContent.RetireIntuneDevices;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableUserRequest {
    requests: Array<EnableUserFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableUserFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ResetUserTokenRequest {
    requests: Array<ResetUserTokenFileContent>;
    localIT: String;

    constructor() { }
}

export class ResetUserTokenFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ResetGuestMFARequest {
    requests: Array<ResetGuestMFAFileContent>;
    localIT: String;

    constructor() { }
}

export class ResetGuestMFAFileContent {
    Guest: String = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Guest = fileContent.Guest;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddAADGroupMemberRequest {
    requests: Array<AddAADGroupMemberFileContent>;
    localIT: String;

    constructor() { }
}

export class AddAADGroupMemberFileContent {
    UsersGID: Array<string> = new Array('');
    AADGroupDisplayName: string = '';
    AADGroupObjectID: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.UsersGID = fileContent.UsersGID;
            this.AADGroupDisplayName = fileContent.AADGroupDisplayName;
            this.AADGroupObjectID = fileContent.AADGroupObjectID;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DeleteAzureADGroupRequest {
    requests: Array<DeleteAzureADGroupFileContent>;
    localIT: String;

    constructor() { }
}

export class DeleteAzureADGroupFileContent {
    GroupDisplayName: string = '';
    GroupObjectID: string = '';
    Identity: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupDisplayName = fileContent.GroupDisplayName;
            this.GroupObjectID = fileContent.GroupObjectID;
            this.Identity = fileContent.Identity;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UneleteAzureADGroupRequest {
    requests: Array<UndeleteAzureADGroupFileContent>;
    localIT: String;

    constructor() { }
}

export class UndeleteAzureADGroupFileContent {
    GroupDisplayName: string = '';
    GroupObjectID: string = '';
    Identity: string = '';
    GroupOwners: Array<string> = new Array<string>('');
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupDisplayName = fileContent.GroupDisplayName;
            this.GroupObjectID = fileContent.GroupObjectID;
            this.Identity = fileContent.Identity;
            this.GroupOwners = fileContent.GroupOwners;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableTeamsPhoneSystemRequest {
    requests: Array<EnableTeamsPhoneSystemFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableTeamsPhoneSystemFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    UserTel: string = '';
    VRPolicy: String = '';
    CallingPolicy: string = 'AllowCallingDefault';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.UserTel = fileContent.UserTel;
            this.VRPolicy = fileContent.VRPolicy;
            this.CallingPolicy = fileContent.CallingPolicy;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveTeamsPhoneSystemRequest {
    requests: Array<RemoveTeamsPhoneSystemFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveTeamsPhoneSystemFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UnlockOktaUserRequest {
    requests: UnlockOktaUserFileContent;
    localIT: String;

    constructor() { }
}

export class UnlockOktaUserFileContent {
    GroupID: string = '';
    OktaUserID: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.OktaUserID = fileContent.OktaUserID;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UnlockOktaB2BUserRequest {
    requests: UnlockOktaB2BUserFileContent;
    localIT: String;

    constructor() { }
}

export class UnlockOktaB2BUserFileContent {
    GroupID: string = '';
    OktaUserID: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.OktaUserID = fileContent.OktaUserID;
            this.TicketID = fileContent.TicketID;
        }
    }
}
export class BusinessUnitRequest {
    requests: BusinessUnitFileContent;
    localIT: String;

    constructor() { }
}

export class BusinessUnitFileContent {
    Name: string = '';
    Type: string = '';
    Code: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.Type = fileContent.Type;
            this.Code = fileContent.Code;
        }
    }
}

export class EntityRequest {
    requests: EntityFileContent;
    localIT: String;

    constructor() { }
}

export class EntityFileContent {
    Name: string = '';
    BU: string = '';
    OrgID: string = '';
    HeadOfIT: Array<string> = new Array<string>();
    CyberSecurity: Array<string> = new Array<string>();
    CDO: Array<string> = new Array<string>();
    Helpdesk: Array<string> = new Array<string>();
    ADDomain: string = '';
    ITCard: string = '';
    ITInfraMngr: Array<string> = new Array<string>();
    ITSupportMngr: Array<string> = new Array<string>();
    O365AdoptSPOC: Array<string> = new Array<string>();
    ADAdmin: Array<string> = new Array<string>();
    Assistant: Array<string> = new Array<string>();
    HRContact: Array<string> = new Array<string>();
    Communication: Array<string> = new Array<string>();
    Perf_Budget: Array<string> = new Array<string>();
    Projects: Array<string> = new Array<string>();
    Applications: Array<string> = new Array<string>();
    SSAM: Array<string> = new Array<string>();
    AWSChampion: Array<string> = new Array<string>();
    AzureChampion: Array<string> = new Array<string>();
    FormerName: string = '';
    BUHead: boolean = false;

    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.BU = fileContent.BU;
            this.OrgID = fileContent.OrgID;
            this.CyberSecurity = fileContent.CyberSecurity;
            this.CDO = fileContent.CDO;
            this.Helpdesk = fileContent.Helpdesk;
            this.ADDomain = fileContent.ADDomain;
            this.ITCard = fileContent.ITCard;
            this.ITInfraMngr = fileContent.ITInfraMngr;
            this.ITSupportMngr = fileContent.ITSupportMngr;
            this.O365AdoptSPOC = fileContent.O365AdoptSPOC;
            this.SSAM = fileContent.SSAM;
            this.ADAdmin = fileContent.ADAdmin;
            this.Assistant = fileContent.Assistant;
            this.HRContact = fileContent.HRContact;
            this.Communication = fileContent.Communication;
            this.Perf_Budget = fileContent.Perf_Budget;
            this.Projects = fileContent.Projects;
            this.Applications = fileContent.Applications;
            this.AWSChampion = fileContent.AWSChampion;
            this.AzureChampion = fileContent.AzureChampion;
            this.FormerName = fileContent.FormerName;
            this.BUHead = fileContent.BUHead
        }
    }
}

export class CompanyRequest {
    requests: CompanyFileContent;
    localIT: String;

    constructor() { }
}

export class CompanyFileContent {
    Name: string = '';
    ID: string = '';
    OrgID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.ID = fileContent.ID;
            this.OrgID = fileContent.OrgID;
        }
    }
}


export class CreateGuestUserRequest {
    requests: Array<CreateGuestUserFileContent>;
    localIT: String;

    constructor() { }
}

export class CreateGuestUserFileContent {
    ExternalCompany: string = '';
    GuestEmailAddress: string = '';
    GuestFirstName: string = '';
    GuestLastName: string = '';
    SendInvitationMessage: boolean = true;
    Sponsor: string = '';
    SponsorDisplayName: string = '';
    SponsorDisplayNameError: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.ExternalCompany = fileContent.ExternalCompany;
            this.GuestEmailAddress = fileContent.GuestEmailAddress;
            this.GuestFirstName = fileContent.GuestFirstName;
            this.GuestLastName = fileContent.GuestLastName;
            this.SendInvitationMessage = fileContent.SendInvitationMessage;
            this.Sponsor = fileContent.Sponsor;
            this.SponsorDisplayName = fileContent.SponsorDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UpdateGuestUserRequest {
    requests: Array<UpdateGuestUserFileContent>;
    localIT: String;

    constructor() { }
}

export class UpdateGuestUserFileContent {
    Guest: string = '';
    ExternalCompany: string = '';
    GuestDisplayName: string = '';
    GuestDisplayNameError: string = '';
    GuestEmailAddress: string = '';
    GuestFirstName: string = '';
    GuestLastName: string = '';
    Sponsor: string = '';
    SponsorDisplayName: string = '';
    SponsorDisplayNameError: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.ExternalCompany = fileContent.ExternalCompany;
            this.GuestEmailAddress = fileContent.GuestEmailAddress;
            this.GuestFirstName = fileContent.GuestFirstName;
            this.GuestLastName = fileContent.GuestLastName;
            this.Sponsor = fileContent.Sponsor;
            this.SponsorDisplayName = fileContent.SponsorDisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class DeleteGuestUserRequest {
    requests: Array<DeleteGuestUserFileContent>;
    localIT: String;

    constructor() { }
}

export class DeleteGuestUserFileContent {
    Guest: string = '';
    TicketID: string = '';


    constructor(fileContent?) {
        if (fileContent) {
            this.Guest = fileContent.Guest;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UMRequest {
    requests: UMFileContent;
    localIT: String;

    constructor() { }
}

export class UMFileContent {
    Name: string = '';
    ID: string = '';
    OrgID: string = '';
    Code: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Name = fileContent.Name;
            this.ID = fileContent.ID;
            this.OrgID = fileContent.OrgID;
            this.Code = fileContent.Code;
        }
    }
}

export class TransferEmployeeRequest {
    requests: Array<TransferEmployeeFileContent>;
    localIT: String;

    constructor() { }
}

export class TransferEmployeeFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    ADSrc: string = '';
    ADDst: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.ADSrc = fileContent.ADSrc;
            this.ADDst = fileContent.ADDst;
            this.TicketID = fileContent.TicketID;
        }
    }
}


export class EnableTeamsRoomMailboxRequest {
    requests: Array<EnableTeamsRoomMailboxFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableTeamsRoomMailboxFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    ManagerDisplayName: string = '';
    ManagerDisplayNameError: string = '';
    ExtensionAttribute11: string = '';
    Name: string = '';
    CountryCode: string = '';
    Site: string = '';
    Brand: string = '';
    Manager: string = '';
    MailboxLanguage: string = 'en-US';
    AdditionalManager: Array<string> = new Array<string>('');
    Capacity: number = 0;
    ResourceDelegates: Array<string> = new Array<string>('');
    TimeZone: string = '';
    WorkingHoursStartTime: string = '07:00';
    WorkingHoursEndTime: string = '19:00';
    ScheduleOnlyDuringWorkHours: boolean = false;
    EnableTeamsTelephony: boolean = false;
    PhoneNumber: number = null;
    VRPolicy: string = '';
    Sensitive: boolean = false;
    BookingProfile: string = 'Open';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerDisplayNameError = fileContent.ManagerDisplayNameError;
            this.ExtensionAttribute11 = fileContent.ExtensionAttribute11;
            this.Name = fileContent.Name;
            this.CountryCode = fileContent.CountryCode;
            this.Site = fileContent.Site;
            this.Manager = fileContent.Manager;
            this.MailboxLanguage = fileContent.MailboxLanguage;
            this.AdditionalManager = fileContent.AdditionalManager;
            this.Capacity = fileContent.Capacity;
            this.ResourceDelegates = fileContent.ResourceDelegates;
            this.TimeZone = fileContent.TimeZone;
            this.WorkingHoursStartTime = fileContent.WorkingHoursStartTime;
            this.WorkingHoursEndTime = fileContent.WorkingHoursEndTime;
            this.ScheduleOnlyDuringWorkHours = fileContent.ScheduleOnlyDuringWorkHours;
            this.EnableTeamsTelephony = fileContent.EnableTeamsTelephony;
            this.PhoneNumber = fileContent.PhoneNumber;
            this.VRPolicy = fileContent.VRPolicy;
            this.Brand = fileContent.Brand;
            this.Sensitive = fileContent.Sensitive;
            this.BookingProfile = fileContent.BookingProfile;
            this.TicketID = fileContent.TicketID;
        }
    }
}
export class PublicDnsRequest {
    requests: Array<PublicDnsFileContent>;
    localIT: String;

    constructor() { }
}

export class PublicDnsFileContent {
    BusinessUnit: string = '';
    Entity = '';
    TicketID: string = '';
    DnsRecords: Array<PublicDnsRecord> = new Array<PublicDnsRecord>();
    GROUPID: string = '';

    constructor(PublicDnsFileContent?) {
        if (PublicDnsFileContent) {
            this.BusinessUnit = PublicDnsFileContent.BusinessUnit;
            this.Entity = PublicDnsFileContent.Entity;
            this.TicketID = PublicDnsFileContent.TicketID;
            this.DnsRecords = PublicDnsFileContent.DnsRecords;
            this.GROUPID = PublicDnsFileContent.GROUPID;
        }
    }
}

export class PublicDnsRecord {
    RecordType: string = '';
    ChangeType: string = '';
    RecordSubtype: string = '';
    Environment: string = '';
    Comments: string = '';
    DnsZone: string = '';
    DnsSubzone: string = '';
    RecordName: string = '';
    RecordNameError: string = '';
    RecordValue: Array<RecordValue> = new Array<RecordValue>(new RecordValue());
    Priority: number = null;
    Ttl: number;
    ErrorMessage: string = '';
    WarningMessage: string = '';
    Status: string = 'ReadyToExecute';

    constructor(fileContent?) {
        if (fileContent) {
            this.RecordType = fileContent.RecordType;
            this.ChangeType = fileContent.ChangeType;
            this.RecordSubtype = fileContent.RecordSubtype;
            this.Environment = fileContent.Environment;
            this.Comments = fileContent.Comments;
            this.DnsZone = fileContent.DnsZone;
            this.DnsSubzone = fileContent.DnsSubzone;
            this.RecordName = fileContent.RecordName;
            this.RecordNameError = fileContent.RecordNameError;
            this.RecordValue = fileContent.RecordValue;
            this.Priority = fileContent.Priority;
            this.Ttl = fileContent.Ttl;
            this.ErrorMessage = fileContent.ErrorMessage;
            this.WarningMessage = fileContent.WarningMessage;
            this.Status = fileContent.Status;
        }
    }
}

export class RecordValue {
    Value: string = '';
    Error: string = '';
    Warning: string = '';

    constructor(recordValue?) {
        if (recordValue) {
            this.Value = recordValue.Value;
            this.Error = recordValue.Error;
            this.Warning = recordValue.Warning;
        }
    }
}

export class ZScalerAuthBypassFileContent {
    ByPassType: string = '';
    ActionType: string = '';
    ConfiguredName: string = '';
    URL: string = '';
    RemoveURL: Array<any> = new Array<any>();
    Period: string = '';
    Description: string = '';
    TicketID: string = '';
    ValidatedCISO: boolean = false;
    Test: boolean = false;

    constructor(fileContent?) {
        if (fileContent) {
            this.ByPassType = fileContent.ByPassType;
            this.ActionType = fileContent.ActionType;
            this.ConfiguredName = fileContent.ConfiguredName;
            this.URL = fileContent.URL;
            this.RemoveURL = fileContent.RemoveURL;
            this.Period = fileContent.Period;
            this.Description = fileContent.Description;
            this.TicketID = fileContent.TicketID;
            this.ValidatedCISO = fileContent.ValidatedCISO;
            this.Test = fileContent.Test;
        }
    }
}

export class ZScalerFirewallFileContent{
    DestinationType: string = '';
    ActionType: string = '';
    Destination: string = '';
    RemoveAddresses: Array<any> = new Array<any>(); 
    Protocol:string = '';
    Description: string = '';
    TicketID: string= '';
    ValidatedCISO: boolean = false;

    constructor(fileContent?) {
        if (fileContent) {
            this.DestinationType = fileContent.DestinationType;
            this.ActionType = fileContent.ActionType;
            this.Destination = fileContent.Destination;
            this.RemoveAddresses = fileContent.RemoveAddresses;
            this.Protocol = fileContent.Protocol;
            this.Description = fileContent.Description;
            this.TicketID = fileContent.TicketID;
            this.ValidatedCISO = fileContent.ValidatedCISO;
        }
    }
}

export class ZScalerTunnelingContent{
    Description: string = '';
    TicketID: string= '';
    Urls: Array<string> = new Array<string>('');
    ValidatedByCISO:boolean = false;

    constructor(fileContent?) {
        if (fileContent) {
            this.Urls = fileContent.Urls;
            this.ValidatedByCISO = fileContent.ValidatedByCISO;
            this.Description = fileContent.Description;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class EnableTeamsCAPPhoneRequest {
    requests: Array<EnableTeamsCAPPhoneFileContent>;
    localIT: String;

    constructor() { }
}

export class EnableTeamsCAPPhoneFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Name: string = '';
    Site: string = '';
    Location: string = '';
    Manager: string = '';
    ManagerDisplayName: string = '';
    ManagerDisplayNameError: string = '';
    Brand: string = '';
    IPPhonePolicy: string = 'CommonAreaPhoneDefault - with Search Enabled';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Name = fileContent.Name;
            this.Site = fileContent.Site;
            this.Location = fileContent.Location;
            this.Manager = fileContent.Manager;
            this.ManagerDisplayName = fileContent.ManagerDisplayName;
            this.ManagerDisplayNameError = fileContent.ManagerDisplayNameError;
            this.Brand = fileContent.Brand;
            this.IPPhonePolicy = fileContent.IPPhonePolicy;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddMWUserRequest {
    requests: Array<AddMWUserFileContent>;
    localIT: String;

    constructor() { }
}

export class AddMWUserFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    Skynote: boolean = true;
    Subdelegation: boolean = false;
    ManagedLocalIT: string = '';
    LocalAdmin: boolean = false;
    ReleaseRing: string = 'R4';
    AddApplications: Array<any> = new Array<any>();
    RemoveApplications: Array<any> = new Array<any>();
    TicketID: string = '';
    OrgID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.Skynote = fileContent.Skynote;
            this.Subdelegation = fileContent.Subdelegation;
            this.ManagedLocalIT = fileContent.ManagedLocalIT;
            this.LocalAdmin = fileContent.LocalAdmin;
            this.ReleaseRing = fileContent.ReleaseRing;
            this.AddApplications = fileContent.AddApplications;
            this.RemoveApplications = fileContent.RemoveApplications;
            this.TicketID = fileContent.TicketID;
            this.OrgID = fileContent.OrgID;
        }
    }
}

export class DeleteDistributionListRequest {
    requests: Array<DeleteDistributionListFileContent>;
    localIT: String;

    constructor() { }
}

export class DeleteDistributionListFileContent {
    ListID: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.ListID = fileContent.ListID;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class RemoveAzureADDeviceRequest {
    requests: Array<RemoveAzureADDeviceFileContent>;
    localIT: String;

    constructor() { }
}

export class RemoveAzureADDeviceFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    Devices: Array<any> = new Array<any>();
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.Devices = fileContent.Devices;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class GetBitlockerKeyRequest {
    requests: Array<GetBitlockerKeyFileContent>;
    localIT: String;

    constructor() { }
}

export class GetBitlockerKeyFileContent {
    Serial: string = '';
    DisplayName: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.Serial = fileContent.Serial;
            this.DisplayName = fileContent.DisplayName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class AddAllocIDPermissionRequest {
    requests: Array<AddAllocIDPermissionFileContent>;
    localIT: String;

    constructor() { }
}

export class AddAllocIDPermissionFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    Role: string = '';
    Profile: string = '';
    Scope: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.Role = fileContent.Role;
            this.Profile = fileContent.Profile;
            this.Scope = fileContent.Scope;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class CreateGIDRequest {
    requests: Array<CreateGIDFileContent>;
    localIT: String;

    constructor() { }
}
export class CreateGIDFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    LegalName: string = '';
    LegalFirstName: string = '';
    BirthName: string = '';
    PreferredName: string = '';
    PreferredFirstName: string = '';
    BirthDate: string = '';
    EmailAddress: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.LegalName = fileContent.LegalName;
            this.LegalFirstName = fileContent.LegalFirstName;
            this.BirthName = fileContent.BirthName;
            this.PreferredName = fileContent.PreferredName;
            this.PreferredFirstName = fileContent.PreferredFirstName;
            this.BirthDate = fileContent.BirthDate;
            this.EmailAddress = fileContent.EmailAddress;            
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class ActivateGIDRequest {
    requests: Array<ActivateGIDFileContent>;
    localIT: String;

    constructor() { }
}
export class ActivateGIDFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    LastName: string = '';
    FirstName: string = '';
    BirthDate: string = '';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.BirthDate = fileContent.BirthDate;
            this.LastName = fileContent.LastName;
            this.FirstName = fileContent.FirstName;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class UpdateAllocIDDataRequest {
    requests: Array<UpdateAllocIDDataFileContent>;
    localIT: String;

    constructor() { }
}
export class UpdateAllocIDDataFileContent {
    GroupID: string = '';
    GroupIDDisplayName: string = '';
    BirthDate: string = '';
    DataToRemove: Array<any> = new Array<any>();
    TicketID: string = '';
    LegalName: string = '';
    LegalFirstName: string = '';
    BirthName: string = '';
    PreferredName: string = '';
    PreferredFirstName: string = '';
    EmailAddress: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.BirthDate = fileContent.BirthDate;
            this.DataToRemove = fileContent.DataToRemove;            
            this.TicketID = fileContent.TicketID;
            this.LegalName = fileContent.LegalName;
            this.LegalFirstName = fileContent.LegalFirstName;
            this.BirthName = fileContent.BirthName;
            this.PreferredName = fileContent.PreferredName;
            this.PreferredFirstName = fileContent.PreferredFirstName;
            this.EmailAddress = fileContent.EmailAddress; 
        }
    }
}

export class SetUserRingRequest {
    requests: Array<SetUserRingFileContent>;
    localIT: String;

    constructor() { }
}

export class SetUserRingFileContent {
    GroupID: String = '';
    GroupIDDisplayName: string = '';
    GroupIDDisplayNameError: string = '';
    ExtensionAttribute11: string = '';
    ReleaseRing: string = 'R4';
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.GroupID = fileContent.GroupID;
            this.GroupIDDisplayName = fileContent.GroupIDDisplayName;
            this.GroupIDDisplayNameError = fileContent.GroupIDDisplayNameError;
            this.ExtensionAttribute11 = fileContent.ExtensionAttribute11;
            this.ReleaseRing = fileContent.ReleaseRing;
            this.TicketID = fileContent.TicketID;
        }
    }
}

export class SetOffice365RetentionPolicyRequest {
    requests: Array<SetOffice365RetentionPolicyFileContent>;
    localIT: String;

    constructor() { }
}

export class SetOffice365RetentionPolicyFileContent {
    SiteUrl: String = '';
    RetentionPeriod: number = 7;
    TicketID: string = '';

    constructor(fileContent?) {
        if (fileContent) {
            this.SiteUrl = fileContent.SiteUrl;
            this.RetentionPeriod = fileContent.RetentionPeriod;
            this.TicketID = fileContent.TicketID;
        }
    }
}