import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../../Services/requestContainer.service';
import { CustomerProfile} from '../../../../Models/CustomerProfile';
import { CustomerProfilesService } from '../../../../Services/customerProfiles.service';
import { UserService } from '../../../../Services/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'deleteProfileConfirmDialog',
    templateUrl: './deleteProfileConfirmDialog.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../../app.component.css']
})

export class DeleteProfileConfirmDialog {

    public fileContentsList = new Array<CustomerProfile>();
    public customerProfile = new CustomerProfile();
    public selectedLocalIT:String = '';
    public isAllowedToEditLocalItCard:boolean = false;

    constructor(private userService: UserService, private requestContainerService: RequestContainerService, public dialogRef: MatDialogRef<DeleteProfileConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private customerProfilesService: CustomerProfilesService, private router: Router, public dialog: MatDialog, public snackBar: MatSnackBar,) {    
        if (data && data.messageID) {
            this.customerProfilesService.getCustomerProfileById(data.messageID).subscribe(response => { 
                this.customerProfile = new CustomerProfile(response);
            }); 

            this.isAllowedToEditLocalItCard = data.isAdmin ? data.isAdmin : false;
        }
    }

    ngOnInit(){
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    deleteCustomerProfile(){      
        this.customerProfilesService.deleteCustomerProfileById(this.customerProfile.id)
            .subscribe( response => {
                let successSnackBar = this.snackBar.open("CUSTOMER PROFILE DELETED !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],                  
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.dialogRef.close();
                    this.router.navigate(['/customerProfiles']);
                })     
            }, 
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
            }
        );
    }
}
