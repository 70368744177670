import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/businessRules";
export const API_VERSION = "/v1";

@Injectable()  
export class BusinessRulesService {  
  constructor(private http: HttpClient) { }

  getSpecificListByProduct(propertyName, productName) : Observable<string[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getSpecificListByProduct';
    let params = new HttpParams();
    params = params.append('propertyName', propertyName);
    params = params.append('productName', productName);
    return this.http.get(request, {params: params}) as Observable<string[]>;
  }

  getSpecificListByKey(listKey, propertyName) : Observable<any[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getSpecificListByKey';
    let params = new HttpParams();
    params = params.append('listKey', listKey);
    params = params.append('propertyName', propertyName);
    return this.http.get(request, {params: params}) as Observable<any[]>;
  }

  getAllProducts() : Observable<any[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getAllProducts';
    return this.http.get(request) as Observable<any[]>;
  }
}