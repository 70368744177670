import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from "../../environments/environment";

import { RequestItem } from "../Models/RequestItem";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/requests";
export const API_VERSION = "/v1";

@Injectable()  
export class RequestItemService {  
  constructor(private http: HttpClient) { }

  getRequestItems(selectedLocalIT,filters, index, itemPerPage, order, isCSV = "false") : Observable<RequestItem[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getRequestItems';
    let params = new HttpParams();
    Object.keys(filters).forEach(element => {
      if(filters[element] != null){
        params = params.append(element,filters[element]);
      }
    });
    params = params.append('selectedLocalIT', selectedLocalIT);
    params = params.append('order', order);
    params = params.append('index', index);
    params = params.append('itemPerPage', itemPerPage);
    params = params.append('isCSV', isCSV);
    return this.http.get(request, {params: params}) as Observable<RequestItem[]>;
  }  
  getRequestItemsCount(selectedLocalIT,filters, index, itemPerPage, order, isCSV = "false") : Observable<number>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getRequestItemsCount';
    let params = new HttpParams();
    Object.keys(filters).forEach(element => {
      if(filters[element] != null){
        params = params.append(element,filters[element]);
      }
    });
    params = params.append('selectedLocalIT', selectedLocalIT);
    params = params.append('order', order);
    params = params.append('index', index);
    params = params.append('itemPerPage', itemPerPage);
    params = params.append('isCSV', isCSV);
    return this.http.get(request, {params: params}) as Observable<number>;
  }

  getRequestItemByGuid(requestItemGuid, localIT) : Observable<RequestItem>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getRequestByGuid';
    let params = new HttpParams();
    params = params.append('localIT', localIT);
    params = params.append('guid', requestItemGuid);
    return this.http.get(request, {params: params}) as Observable<RequestItem>;
  }

  retryRequest(requestItemGuid) : any {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/retryRequest';
    let params = new HttpParams();
    params = params.append('guid', requestItemGuid);
    return this.http.put(request, {}, {params: params}) as Observable<RequestItem>;
  }

  updateSquidRequest(data) : any {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateSquidRequest';
    return this.http.patch(request, data) as Observable<RequestItem>;
  }
}