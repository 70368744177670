<br />
<div class="row">
    <div class="col-5">
        <h4>Request history from {{ selectedLocalIT }} ({{ length }}):</h4>
    </div>
    <div class="col-4 offset-3 font-weight-bold" style="display: none">
        <div class="row" style="text-align: right">
            <div class="">
                High Impact Quota: <span
                    [ngStyle]="{'color':getQuotaColor(selectedLocalITQuota.High, selectedLocalITQuota.HighDefault), 'font-size':'large'}">{{
                    selectedLocalITQuota.High }}</span> / <span
                    [ngStyle]="{'color':selectedLocalITQuota.HighDefault && selectedLocalITQuota.HighDefault > 0 ? '#1976d2' : '#d32f2f', 'font-size':'large'}">{{
                    selectedLocalITQuota.HighDefault }}</span>
            </div>
        </div>
        <div class="row" style="text-align: right">
            <div class="">
                Low Impact Quota: <span
                    [ngStyle]="{'color':getQuotaColor(selectedLocalITQuota.Low, selectedLocalITQuota.LowDefault), 'font-size':'large'}">{{
                    selectedLocalITQuota.Low }}</span> / <span
                    [ngStyle]="{'color':selectedLocalITQuota.LowDefault && selectedLocalITQuota.LowDefault > 0 ? '#1976d2' : '#d32f2f', 'font-size':'large'}">{{
                    selectedLocalITQuota.LowDefault }}</span>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<div class="row">
    <div class="col-4">
        <label class="font-weight-bold" for="ticketIDInput">Ticket ID:</label>
        <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control" [(ngModel)]="ticketID">
    </div>
</div>
<br />
<div class="row">
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [min]="minHistoryDate"
            [max]="maxHistoryDate" [matDatepicker]="fromDatePicker" placeholder="From" [value]="fromDate"
            (dateChange)="fromDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
    </div>
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [min]="minHistoryDate"
            [max]="maxHistoryDate" [matDatepicker]="toDatePicker" placeholder="To" [value]="toDate"
            (dateChange)="toDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
    </div>
    <div>
        <button id='ClearFilters' class="btn btn-cancel" (click)="clearFilters()" [disabled]="clearFiltersDisabled"
            style="display:inline;margin-right:20px;margin-left: 20px;">Clear filters</button>
        <button id='refreshDataButton' class="btn btn-primary" (click)="refreshData(true)"
            [disabled]="refreshButtonDisabled" style="display:inline;">Refresh</button>
    </div>
    <div class="col-12">
        <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<br />
<ng-table _ngcontent-c1 [ngClass]="config.className" [rows]="rows" [columns]="columns"
    (cellClicked)="onCellClick($event)"></ng-table>
<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length"
    [itemsPerPage]="itemsPerPage" [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false"
    (pageChanged)="changePage($event)" (numPages)="numPages = $event">
</pagination>
<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>