import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'messageDialog',
    templateUrl: './messageDialog.component.html'
})

export class MessageDialog {
    public Data: any;

    constructor(public dialogRef: MatDialogRef<MessageDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.Data = data;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}