import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { jwtDecode } from "jwt-decode";
import { formatUserGroupsByEnv, formatFullName } from '../Helpers/utils';
import { BehaviorSubject } from 'rxjs';
import { UserInfo, UserAdmin } from '../Models/UserInfo';
import { LocalITsService } from './localITs.service';
import { OktaAuthService } from '../../app/app.service';

import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { LocalItCard } from 'app/Models/LocalItCard';

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/webAdministrations";
export const API_VERSION = "/v1";

//TO BE IMPLEMENTED
//Check https://angularfirebase.com/lessons/sharing-data-between-angular-components-four-methods/

@Injectable()
export class UserService {
    //'IT_999','IT_EGS','IT_WSD'
    public localITsAllowedToShowAllRequests: Array<String> = new Array<String>();
    public userAdminList: Array<UserAdmin> = new Array<UserAdmin>();
    private accessTokenPayload = new BehaviorSubject<any>('');
    currentAccessTokenPayload = this.accessTokenPayload.asObservable();
    currentAccessTokenPayloadValue;

    private currentUserInfo = new BehaviorSubject<UserInfo>(new UserInfo());
    currentUserInfoObs = this.currentUserInfo.asObservable();

    private selectedUserLocalIT = new BehaviorSubject<String>('');
    selectedUserLocalITObs = this.selectedUserLocalIT.asObservable();

    private selectedUserLocalITCard = new BehaviorSubject<LocalItCard>(new LocalItCard());
    selectedUserLocalITCardObs = this.selectedUserLocalITCard.asObservable();

    constructor(private oauthService: OktaAuthService, private localITsService: LocalITsService, private http: HttpClient) { }

    ngOnInit() {
        this.currentAccessTokenPayload.subscribe(currentToken => this.currentAccessTokenPayloadValue = currentToken);
    }

    updateSelectedUserLocalIT(newLocalIT: String) {
        this.selectedUserLocalIT.next(newLocalIT);
        if (newLocalIT) {
            this.localITsService.getLocalITByName(newLocalIT).subscribe(response => this.selectedUserLocalITCard.next(response));
        } else {
            this.selectedUserLocalITCard.next(new LocalItCard());
        }
    }

    updateAccessTokenPayload(newAccessToken) {
        if (newAccessToken) {
            this.getUserAdminList().subscribe(response => {
                this.userAdminList = response;

                var newPayload = jwtDecode(newAccessToken) as any;
                this.accessTokenPayload.next(newPayload);
                var updatedUserInfo = new UserInfo();
                updatedUserInfo.GroupID = newPayload.sub.split("@")[0];
                updatedUserInfo.FirstName = newPayload.given_name;
                updatedUserInfo.LastName = newPayload.family_name;
                updatedUserInfo.FullName = formatFullName(newPayload.given_name, newPayload.family_name);
                updatedUserInfo.LocalITs = formatUserGroupsByEnv(newPayload.groups, environment.envName);
                updatedUserInfo.IsAdmin = this.userAdminList.find(admin => admin.GroupID.toUpperCase() == updatedUserInfo.GroupID.toUpperCase() && (admin.Role === 'PEO admin' || admin.Role === 'Global admin')) != null;

                if (updatedUserInfo.LocalITs !== null) {
                    updatedUserInfo.IsInLocalITCard = updatedUserInfo.LocalITs.some(function (group, index, array) {
                        return group.toUpperCase().startsWith("IT_");
                    });

                    if (!updatedUserInfo.IsInLocalITCard) {
                        updatedUserInfo.IsOrgDirOnly = updatedUserInfo.LocalITs.includes("Z");
                    }

                    updatedUserInfo.LocalITs = updatedUserInfo.LocalITs.filter(function (value, index, array) {
                        return value.toUpperCase().startsWith("IT_");
                    });
                }

                if (Array.isArray(updatedUserInfo.LocalITs) && updatedUserInfo.LocalITs.some(l => this.localITsAllowedToShowAllRequests.indexOf(l) >= 0)) {
                    updatedUserInfo.LocalITs.push('SHOW ALL');
                }

                this.updateCurrentUserInfo(updatedUserInfo);
            });
        }
    }

    updateCurrentUserInfo(updatedUserInfo: UserInfo) {
        this.currentUserInfo.next(updatedUserInfo);
    }

    async getCurrentUserGID() {
        let claims = await this.oauthService.getUser();
        if (claims) {
            return claims['preferred_username'].split("@")[0];
        }
        return null;
    }

    getUserAdminList(): Observable<UserAdmin[]> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserAdminList';
        return this.http.get(request) as Observable<UserAdmin[]>;
    }
}