import { Component, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { WebAdministrationService } from '../../../Services/webAdministration.service';
import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserAdmin, UserAdminRequest } from '../../../Models/UserInfo';

@Component({
    selector: 'manageAdminUsers',
    templateUrl: './manageAdminUsers.component.html',
    providers: [WebAdministrationService],
    styleUrls: ['../../../app.component.css']
})

export class ManageAdminUsersComponent{
    public userAdminList = new Array<UserAdmin>();
    public roleTypes = new Array<string>();
    public selectedLocalIT:String = '';
    
    constructor(private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private webAdministraionService: WebAdministrationService,  public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public dialog: MatDialog){
    
    }

    ngOnInit(){
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.webAdministraionService.getUserAdminList()
            .subscribe(response => {this.userAdminList = response;});

        this.businessRulesService.getSpecificListByKey('DLTYPES', 'RoleTypes')
            .subscribe(response => {this.roleTypes = response;});
    }
        
    ngOnDestroy(){
    }

    sendRequest(){
        if(!this.userAdminList && this.userAdminList.length < 1){
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : {statusText:"A minimum of 1 administator is required!"} } });
        }else{
            var data = new UserAdminRequest();
            data.UserAdminList = this.userAdminList;
                        
            this.webAdministraionService.updateUserAdminList(data)
                .subscribe( response => {
                    let successSnackBar = this.snackBar.open("Admin Update Sent!", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        //this.router.navigate(['/history']);
                    })     
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );
        }        
    }

    addUserAdmin(){
        this.userAdminList.push(new UserAdmin());
    }

    removeUserAdmin(index){
        this.userAdminList.splice(index, 1);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }
}