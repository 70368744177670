import { Component, Input } from '@angular/core';
import { AddIntuneUserDetails } from '../../../../Models/History/AddIntuneUserDetails';

@Component({
    selector: 'AddIntuneUserHistoryDetails',
    templateUrl: './addIntuneUserHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class AddIntuneUserHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: AddIntuneUserDetails;
    productCustomInfoCollapsed: boolean = false;
}