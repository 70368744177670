import { RequestItem } from "app/Models/RequestItem";
import { booleanToStringConverter, dateToFormatedLocaleString } from "../../Helpers/utils";

export class GetUserInfoDetails{
    UserAccountInformations: UserAccountInformations;
    UserAccountDetailedInformationsByServices: UserAccountDetailedInformationsByServices;    
    PEODatabaseInformations: PEODatabaseInformations;
    Office365Informations: Office365Informations;
    ExchangeOnlineInformations: ExchangeOnlineInformations;
    SkypeForBusinessInformations: SkypeForBusinessInformations;
    OktaInformations: OktaInformations;
    AdditionalAccountsOkta = {"okta" : [],
                              "oktaB2B" : []};
    AdditionalAccountsAzure = [];
    InformationToShow;


    constructor(requestItem?: RequestItem, userAccountInformations?, userAccountDetailedInformationsByServices?, office365Informations?, exchangeOnlineInformations?, skypeForBusinessInformations?, oktaInformations?){
        if(requestItem){
            //User Account General Informations
            this.UserAccountInformations = new UserAccountInformations(requestItem);
            //User Account Detailed Informations By Services
            this.UserAccountDetailedInformationsByServices = requestItem.Output ? new UserAccountDetailedInformationsByServices(requestItem.Output) : new UserAccountDetailedInformationsByServices();
            //Exchange Online Details
            this.ExchangeOnlineInformations = requestItem.Output ? new ExchangeOnlineInformations(requestItem.Output.ExchangeOnline) : new ExchangeOnlineInformations();
            //Skype For Business Details
            this.SkypeForBusinessInformations = requestItem.Output ? new SkypeForBusinessInformations(requestItem.Output.SkypeForBusinessOnline) : new SkypeForBusinessInformations();
            //O365 Licences and service plan Details
            this.Office365Informations = requestItem.Output ? new Office365Informations(requestItem.Output.AzureAD) : new Office365Informations();  
            //Okta Details
            this.OktaInformations = requestItem.Output && requestItem.Output.Okta ? new OktaInformations(requestItem.Output.Okta) : new OktaInformations();
            this.InformationToShow = requestItem.Parameters.INFORMATIONTOSHOW;
            this.AdditionalAccountsOkta = requestItem.Output && requestItem.Output.AdditionalAccountsOkta ? requestItem.Output.AdditionalAccountsOkta : {"okta" : [],
                                                                                                                                                         "oktaB2B" : []};
            this.AdditionalAccountsAzure = requestItem.Output && requestItem.Output.AdditionalAccountsAzure ? requestItem.Output.AdditionalAccountsAzure : [];
        }else{
            this.UserAccountInformations = userAccountInformations ? userAccountInformations : new UserAccountInformations();
            this.UserAccountDetailedInformationsByServices = userAccountDetailedInformationsByServices ? userAccountDetailedInformationsByServices : new UserAccountDetailedInformationsByServices();
            this.Office365Informations = office365Informations ? office365Informations : new Office365Informations();
            this.ExchangeOnlineInformations = exchangeOnlineInformations ? exchangeOnlineInformations : new ExchangeOnlineInformations();
            this.SkypeForBusinessInformations = skypeForBusinessInformations ? skypeForBusinessInformations : new SkypeForBusinessInformations();
            this.OktaInformations = oktaInformations ? oktaInformations : new OktaInformations();
        }
    };
}

export class OktaMFA{
    Provider: string;
    FactorType: string;
    Status: string;
    DeviceType: string;
    DeviceName: string;
    CreationDate: string;
    ModificationDate: string;
    
    constructor() {}
}

export class OktaInformations{
    OktaMFAs: Array<OktaMFA>;

    constructor(requestItemOutputOktaData?){
        if(requestItemOutputOktaData && requestItemOutputOktaData._EnrolledFactors){
            this.OktaMFAs = new Array<OktaMFA>();

            if(Array.isArray(requestItemOutputOktaData._EnrolledFactors)){
                requestItemOutputOktaData._EnrolledFactors.forEach(oktaMFA => {
                    var formatedOktaMFA:OktaMFA = new OktaMFA();
                    formatedOktaMFA.Provider = oktaMFA.provider ? oktaMFA.provider : "";
                    formatedOktaMFA.FactorType = oktaMFA.factorType ? oktaMFA.factorType : "";
                    formatedOktaMFA.Status = oktaMFA.status ? oktaMFA.status : "";
                    formatedOktaMFA.CreationDate = dateToFormatedLocaleString(oktaMFA.created);
                    formatedOktaMFA.ModificationDate = dateToFormatedLocaleString(oktaMFA.lastUpdated);
                    formatedOktaMFA.DeviceType = oktaMFA.profile && oktaMFA.profile.deviceType ? oktaMFA.profile.deviceType : "";
                    formatedOktaMFA.DeviceName = oktaMFA.profile && oktaMFA.profile.name ? oktaMFA.profile.name : "";

                    this.OktaMFAs.push(formatedOktaMFA);
                });
            }else{
                var formatedOktaMFA:OktaMFA = new OktaMFA();
                    formatedOktaMFA.Provider = requestItemOutputOktaData._EnrolledFactors.provider ? requestItemOutputOktaData._EnrolledFactors.provider : "";
                    formatedOktaMFA.FactorType = requestItemOutputOktaData._EnrolledFactors.factorType ? requestItemOutputOktaData._EnrolledFactors.factorType : "";
                    formatedOktaMFA.Status = requestItemOutputOktaData._EnrolledFactors.status ? requestItemOutputOktaData._EnrolledFactors.status : "";
                    formatedOktaMFA.CreationDate = dateToFormatedLocaleString(requestItemOutputOktaData._EnrolledFactors.created);
                    formatedOktaMFA.ModificationDate = dateToFormatedLocaleString(requestItemOutputOktaData._EnrolledFactors.lastUpdated);
                    formatedOktaMFA.DeviceType = requestItemOutputOktaData._EnrolledFactors.profile && requestItemOutputOktaData._EnrolledFactors.profile.deviceType ? requestItemOutputOktaData._EnrolledFactors.profile.deviceType : "";
                    formatedOktaMFA.DeviceName = requestItemOutputOktaData._EnrolledFactors.profile && requestItemOutputOktaData._EnrolledFactors.profile.name ? requestItemOutputOktaData._EnrolledFactors.profile.name : "";

                    this.OktaMFAs.push(formatedOktaMFA);
            }            
        }else{
            this.OktaMFAs = new Array<OktaMFA>();
        }  
    }
}

export class PEODatabaseInformations{
    OktaBusinessUnit: string;
    MelindaBusinessUnit: string;
    Country: string;
    CountryCode: string;
    Department: string;
    MailHost: string;
    ManagerialUnit: string;
    Title: string;
    Trigram: string;

    constructor(requestItemOutputData?, oktaBusinessUnit?: string, melindaBusinessUnit?: string, country?: string, countryCode?: string, department?: string, mailHost?: string, managerialUnit?: string, title?: string, trigram?: string){      
        this.OktaBusinessUnit = oktaBusinessUnit ? oktaBusinessUnit : "";
        this.MelindaBusinessUnit = melindaBusinessUnit ? melindaBusinessUnit : "";
        this.Country = country ? country : "";
        this.CountryCode = countryCode ? countryCode : "";
        this.Department = department ? department : "";
        this.MailHost = mailHost ? mailHost : "";
        this.ManagerialUnit = managerialUnit ? managerialUnit : "";
        this.Title = title ? title : "";
        this.Trigram = trigram ? trigram : "";
    }
}

export class Office365Informations{
    Licenses: Array<{
        Provider: string;
        Name: string;
        ServicePlans: Array<{
            Name: string;
            Status: string;
        }>;
    }>;
    AADSecurityGroupOwnerships;
    AADSynchronizedSecurityGroupOwnerships;
    AADApplicationOwnerships;
    AADGuestUserSponsorships;

    constructor(azureADOutputData?, licenses?, AADSecurityGroupOwnerships?, AADSynchronizedSecurityGroupOwnerships?, AADApplicationOwnerships?, AADGuestUserSponsorships?){
        if(azureADOutputData && azureADOutputData._LicenseDetail){
            this.Licenses = new Array;

            if(Array.isArray(azureADOutputData._LicenseDetail)){
                azureADOutputData._LicenseDetail.forEach(license => {
                    var currentLicense: any = new Object();
                    currentLicense.Provider = "";
                    currentLicense.Name = license.SkuPartNumber ? license.SkuPartNumber : "";
                    currentLicense.ServicePlans = new Array;

                    if(license.ServicePlans){
                        license.ServicePlans.forEach(servicePlan => {
                            currentLicense.ServicePlans.push({
                                Name: servicePlan.ServicePlanName ? servicePlan.ServicePlanName : "",
                                Status: servicePlan.ProvisioningStatus ? servicePlan.ProvisioningStatus : ""
                            });
                        });
                    }

                    this.Licenses.push(currentLicense);
                });
            }else{
                var currentLicense: any = new Object();                
                currentLicense.Provider = "";
                currentLicense.Name = azureADOutputData._LicenseDetail.SkuPartNumber ? azureADOutputData._LicenseDetail.SkuPartNumber : "";
                currentLicense.ServicePlans = new Array;

                if(azureADOutputData._LicenseDetail.ServicePlans){
                    azureADOutputData._LicenseDetail.ServicePlans.forEach(servicePlan => {
                        currentLicense.ServicePlans.push({
                            Name: servicePlan.ServicePlanName ? servicePlan.ServicePlanName : "",
                            Status: servicePlan.ProvisioningStatus ? servicePlan.ProvisioningStatus : ""
                        });
                    });
                }

                this.Licenses.push(currentLicense);
            }            
        }else{
            this.Licenses = licenses ? licenses : new Array;
        }
        if(azureADOutputData) {
            this.AADSecurityGroupOwnerships = azureADOutputData._AADSecurityGroupOwnerships;
            this.AADSynchronizedSecurityGroupOwnerships = azureADOutputData._AADSynchronizedSecurityGroupOwnerships;
            this.AADApplicationOwnerships = azureADOutputData._AADApplicationOwnerships;
            this.AADGuestUserSponsorships = azureADOutputData._AADGuestUserSponsorships;
        }
    }
}

export class UserAccountInformations{    
    IsDeleted : boolean;
    TargetedUserGID: string;
    AccountType: string;
    IsTrustedDomain: string;
    PasswordProvider: string;
    OktaSyncFlag: string;
    OktaMFA: string;
    Trigram: string;

    constructor(requestItem?, targetedUserGID?: string, accountType?: string, isTrustedDomain?: boolean, passwordProvider?: string, oktaSyncFlag?: string, isOktaEnrolled?: boolean, trigram?: string) {
        if(requestItem && requestItem.Output){
            this.IsDeleted = requestItem.Parameters && requestItem.Parameters._DeletedUser ? requestItem.Parameters._DeletedUser : false;
            this.TargetedUserGID = requestItem.Parameters && requestItem.Parameters.GROUPID ? requestItem.Parameters.GROUPID : "";
            this.IsTrustedDomain = requestItem.Output.ActiveDirectory ? booleanToStringConverter(requestItem.Output.ActiveDirectory._TrustedDomain, 'No', 'Yes') : '';
            this.PasswordProvider = requestItem.Output.Okta && requestItem.Output.Okta.credentials && requestItem.Output.Okta.credentials.provider ? 
                                            requestItem.Output.Okta.credentials.provider.type + " (" + requestItem.Output.Okta.credentials.provider.name + ")" : "";

            if(this.PasswordProvider && this.PasswordProvider.toUpperCase().includes('OKTA')){
                this.AccountType = 'Cloud Account';
            }else if (this.PasswordProvider && this.PasswordProvider.toUpperCase().includes('ACTIVE_DIRECTORY')){
                this.AccountType = 'Synced Account';
            }else{
                this.AccountType = '';
            }

            this.OktaSyncFlag = requestItem.Output.Okta && requestItem.Output.Okta.profile ? requestItem.Output.Okta.profile.C_flags : "";

            this.OktaMFA = "";
            if(requestItem.Output.Okta && requestItem.Output.Okta._EnrolledFactors){
                this.OktaMFA += "Activated";
            }else{
                this.OktaMFA += "Deactivated";
            }
            
            this.Trigram = requestItem.Output.ExchangeOnline && requestItem.Output.ExchangeOnline.CustomAttribute9 ? requestItem.Output.ExchangeOnline.CustomAttribute9 : "";
        }else{
            this.TargetedUserGID = targetedUserGID ? targetedUserGID : "";
            this.AccountType = accountType ? accountType : "";
            this.IsTrustedDomain = booleanToStringConverter(isTrustedDomain);
            this.PasswordProvider = passwordProvider ? passwordProvider : "";
            this.OktaSyncFlag = oktaSyncFlag ? oktaSyncFlag : "";
            this.OktaMFA = booleanToStringConverter(isOktaEnrolled);
            this.Trigram = trigram ? trigram : "";
        }
    }
}

export class MobileDeviceInformations {
    FirstSyncTime: string = "";
    LastSuccessSync: string = "";
    DeviceType: string = "";
    DeviceID: string = "";
    DeviceUserAgent: string = "";
    DeviceModel: string = "";
    DeviceOS: string = "";
    DeviceAccessState: string = "";
    DeviceAccessStateReason: string = "";

    constructor(mobileDeviceInfo?){
        if(mobileDeviceInfo){
            this.FirstSyncTime = dateToFormatedLocaleString(mobileDeviceInfo.FirstSyncTime);
            this.LastSuccessSync = dateToFormatedLocaleString(mobileDeviceInfo.LastSuccessSync);
            this.DeviceType = mobileDeviceInfo.DeviceType ? mobileDeviceInfo.DeviceType : "";
            this.DeviceID = mobileDeviceInfo.DeviceID ? mobileDeviceInfo.DeviceID : "";
            this.DeviceUserAgent = mobileDeviceInfo.DeviceUserAgent ? mobileDeviceInfo.DeviceUserAgent : "";
            this.DeviceModel = mobileDeviceInfo.DeviceModel ? mobileDeviceInfo.DeviceModel : "";
            this.DeviceOS = mobileDeviceInfo.DeviceOS ? mobileDeviceInfo.DeviceOS : "";
            this.DeviceAccessState = mobileDeviceInfo.DeviceAccessState ? mobileDeviceInfo.DeviceAccessState : "";
            this.DeviceAccessStateReason = mobileDeviceInfo.DeviceAccessStateReason ? mobileDeviceInfo.DeviceAccessStateReason : "";
        }
    }
}

export class OwnershipInformation {
    Alias: string = "";
    DisplayName: string = "";
    Type: string = "";

    constructor(ownershipInfo?){
        if(ownershipInfo){
            this.Alias = ownershipInfo.Alias ? ownershipInfo.Alias : "";
            this.DisplayName = ownershipInfo.DisplayName ? ownershipInfo.DisplayName : "";
            this.Type = ownershipInfo.Type ? ownershipInfo.Type : "";
        }
    }
}

export class ExchangeOnlineInformations{
    IsAccountDisabled: string;
    ArchiveQuota: string;
    ArchiveStatus: string;
    IsAuditEnabled: string;
    CustomAttribute9: string;
    IsInactiveMailbox: string;
    IsMailboxEnabled: string;
    IsSoftDeletedByDisable: string;
    IsSoftDeletedByRemove: string;
    Languages: string;
    ProhibitSendQuota: string;
    ProhibitSendReceiveQuota: string;
    ProtocolSettings: string;
    RecipientLimits: string;
    RecipientType: string;
    RecipientTypeDetails: string;
    RemoteRecipientType: string;
    UsageLocation: string;
    MobileDevicesInformations: Array<MobileDeviceInformations> = new Array<MobileDeviceInformations>();
    DistributionListOwnership: Array<OwnershipInformation> = new Array<OwnershipInformation>();
    MailboxOwnership: Array<OwnershipInformation> = new Array<OwnershipInformation>();
    AuthenticationPolicy: string;
    LastUserActionTime: string;

    constructor(exchangeOnlineOutputData?, isAccountDisabled?: boolean, archiveQuota?: string, authenticationPolicy?: string, lastUserActionTime?: string, archiveStatus?: string, isAuditEnabled?: boolean,
        customAttribute9?: string, isInactiveMailbox?: boolean, isMailboxEnabled?: boolean, isSoftDeletedByDisable?: boolean,
        isSoftDeletedByRemove?: boolean, languages?: string, prohibitSendQuota?: string, prohibitSendReceiveQuota?: string,
        protocolSettings?: string, recipientLimits?: string, recipientType?: string, recipientTypeDetails?: string,
        remoteRecipientType?: string, usageLocation?: string){
            if(exchangeOnlineOutputData){
                this.IsAccountDisabled = booleanToStringConverter(exchangeOnlineOutputData.AccountDisabled);
                this.ArchiveQuota = exchangeOnlineOutputData.ArchiveQuota ? exchangeOnlineOutputData.ArchiveQuota : "";
                this.ArchiveStatus = exchangeOnlineOutputData.ArchiveStatus ? exchangeOnlineOutputData.ArchiveStatus : "";
                this.IsAuditEnabled = booleanToStringConverter(exchangeOnlineOutputData.AuditEnabled);
                this.CustomAttribute9 = exchangeOnlineOutputData.CustomAttribute9 ? exchangeOnlineOutputData.CustomAttribute9 : "";
                this.IsInactiveMailbox = booleanToStringConverter(exchangeOnlineOutputData.IsInactiveMailbox);
                this.IsMailboxEnabled = booleanToStringConverter(exchangeOnlineOutputData.IsMailboxEnabled);
                this.IsSoftDeletedByDisable = booleanToStringConverter(exchangeOnlineOutputData.IsSoftDeletedByDisable);
                this.IsSoftDeletedByRemove = booleanToStringConverter(exchangeOnlineOutputData.IsSoftDeletedByRemove);
                
                let stringFormatedLanguages:string = "";
                if(exchangeOnlineOutputData.Languages){
                    for(let i = 0; i < exchangeOnlineOutputData.Languages.length; i++){
                        stringFormatedLanguages = stringFormatedLanguages.concat(exchangeOnlineOutputData.Languages[i]);
                        if(exchangeOnlineOutputData.Languages.length != 1 && exchangeOnlineOutputData.Languages.length != i){
                            stringFormatedLanguages = stringFormatedLanguages.concat(" / ");
                        }
                    }
                }
                
                this.AuthenticationPolicy = exchangeOnlineOutputData.AuthenticationPolicy ? exchangeOnlineOutputData.AuthenticationPolicy : "";
                this.LastUserActionTime = exchangeOnlineOutputData.LastUserActionTime ? exchangeOnlineOutputData.LastUserActionTime : "";
                this.Languages = stringFormatedLanguages;
                this.ProhibitSendQuota = exchangeOnlineOutputData.ProhibitSendQuota ? exchangeOnlineOutputData.ProhibitSendQuota : "";
                this.ProhibitSendReceiveQuota = exchangeOnlineOutputData.ProhibitSendReceiveQuota ? exchangeOnlineOutputData.ProhibitSendReceiveQuota : "";
                
                if(Array.isArray(exchangeOnlineOutputData.ProtocolSettings)){
                    var formatedProtocolSettings = "";
                    exchangeOnlineOutputData.ProtocolSettings.forEach(protocolSetting => {
                        if(protocolSetting){
                            if(protocolSetting.indexOf('§') > -1){    
                                if(protocolSetting.indexOf('§1') == protocolSetting.indexOf('§')){
                                    formatedProtocolSettings = formatedProtocolSettings.concat(protocolSetting.substr(0, protocolSetting.indexOf('§')) + ' ');
                                }
                            }else{
                                formatedProtocolSettings = formatedProtocolSettings.concat(protocolSetting + ' ');
                            }                            
                        }                        
                    });

                    this.ProtocolSettings = formatedProtocolSettings;
                }else if(exchangeOnlineOutputData.ProtocolSettings){
                    this.ProtocolSettings = exchangeOnlineOutputData.ProtocolSettings;
                }else {
                    this.ProtocolSettings = "";
                }

                this.RecipientLimits = exchangeOnlineOutputData.RecipientLimits ? exchangeOnlineOutputData.RecipientLimits : "";
                this.RecipientType = exchangeOnlineOutputData.RecipientType ? exchangeOnlineOutputData.RecipientType : "";
                this.RecipientTypeDetails = exchangeOnlineOutputData.RecipientTypeDetails ? exchangeOnlineOutputData.RecipientTypeDetails : "";
                this.RemoteRecipientType = exchangeOnlineOutputData.RemoteRecipientType ? exchangeOnlineOutputData.RemoteRecipientType : "";
                this.UsageLocation = exchangeOnlineOutputData.UsageLocation ? exchangeOnlineOutputData.UsageLocation : "";

                if(Array.isArray(exchangeOnlineOutputData._MobileDeviceInfo)){
                    exchangeOnlineOutputData._MobileDeviceInfo.forEach(mobileDeviceInformation => {
                        this.MobileDevicesInformations.push(new MobileDeviceInformations(mobileDeviceInformation));
                    });
                }else if(exchangeOnlineOutputData._MobileDeviceInfo){
                    this.MobileDevicesInformations.push(new MobileDeviceInformations(exchangeOnlineOutputData._MobileDeviceInfo));
                }
                if(Array.isArray(exchangeOnlineOutputData._OwnerOfDistributionLists)){
                    exchangeOnlineOutputData._OwnerOfDistributionLists.forEach(ownershipInformation => {
                        this.DistributionListOwnership.push(new OwnershipInformation(ownershipInformation));
                    });
                }else if(exchangeOnlineOutputData._OwnerOfDistributionLists){
                    this.DistributionListOwnership.push(new OwnershipInformation(exchangeOnlineOutputData._OwnerOfDistributionLists));
                }
                if(Array.isArray(exchangeOnlineOutputData._OwnerOfMailboxes)){
                    exchangeOnlineOutputData._OwnerOfMailboxes.forEach(ownershipInformation => {
                        this.MailboxOwnership.push(new OwnershipInformation(ownershipInformation));
                    });
                }else if(exchangeOnlineOutputData._OwnerOfMailboxes){
                    this.MailboxOwnership.push(new OwnershipInformation(exchangeOnlineOutputData._OwnerOfMailboxes));
                }
                
            }else{
                this.IsAccountDisabled = booleanToStringConverter(isAccountDisabled);
                this.ArchiveQuota = archiveQuota ? archiveQuota : "";
                this.ArchiveStatus = archiveStatus ? archiveStatus : "";
                this.IsAuditEnabled = booleanToStringConverter(isAuditEnabled);
                this.CustomAttribute9 = customAttribute9 ? customAttribute9 : "";
                this.IsInactiveMailbox = booleanToStringConverter(isInactiveMailbox);
                this.IsMailboxEnabled = booleanToStringConverter(isMailboxEnabled);
                this.IsSoftDeletedByDisable = booleanToStringConverter(isSoftDeletedByDisable);
                this.IsSoftDeletedByRemove = booleanToStringConverter(isSoftDeletedByRemove);
                this.Languages = languages ? languages : "";
                this.ProhibitSendQuota = prohibitSendQuota ? prohibitSendQuota : "";
                this.ProhibitSendReceiveQuota = prohibitSendReceiveQuota ? prohibitSendReceiveQuota : "";
                this.ProtocolSettings = protocolSettings ? protocolSettings : "";
                this.RecipientLimits = recipientLimits ? recipientLimits : "";
                this.RecipientType = recipientType ? recipientType : "";
                this.RecipientTypeDetails = recipientTypeDetails ? recipientTypeDetails : "";
                this.RemoteRecipientType = remoteRecipientType ? remoteRecipientType : "";
                this.UsageLocation = usageLocation ? usageLocation : "";
                this.AuthenticationPolicy = authenticationPolicy ? authenticationPolicy : "";
                this.LastUserActionTime = lastUserActionTime ? lastUserActionTime : "";
            }
    }
}

export class SkypeForBusinessInformations{    
    AllowAnonymousUsersToDialOut: string = '';
    AllowExternalUsersToRecordMeeting: string = '';
    AllowFederatedParticipantJoinAsSameEnterprise: string = '';
    AllowLargeMeetings: string = '';
    AllowNonEnterpriseVoiceUsersToDialOut: string = '';
    Anchor: string = '';
    ApplicationSharingMode: string = '';
    AppSharingBitRate: number = null;
    AudioBitRate: string = '';
    MediaBitRateKb: string = '';
    Description: string = '';
    DialPlan: string = '';
    DisablePowerPointAnnotations: string = '';
    EnableAppDesktopSharing: string = '';
    EnableDialInConferencing: string = '';
    EnableOnlineMeetingPromptForLyncResources: string = '';
    FileTransferBitRate: number = null;
    Identity: string = '';
    Key: string = '';
    MaxMeetingSize: number = null;
    MaxVideoConferenceResolution: string = '';
    MeetingRoomEnabled: string = '';
    OnlineVoiceRoutingPolicy: string = '';
    TeamsCallingPolicy: string = '';
    PhoneSystemLicense: string = '';
    PSComputerName: string = '';
    RunspaceId: string = '';
    ScopeClass: string = '';
    SkypeForBusinesOnlineLicense: string = '';
    TeamsLicense: string = '';
    TeamsUpgradeEffectiveMode: string = '';
    TotalReceiveVideoBitRate: string = '';
    TypedIdentity: string = '';
    VideoBitRate: string = '';
    XsAnyAttributes: string = '';
    XsAnyElements: string = '';
    EnterpriseVoiceEnabled: string = '';
    InternationalCallsAllowed: string = '';
    OnPremLineURI: string = '';
    OtherIPPhone: string = '';
    TeamsAppPermissionPolicy: string = '';
    TeamsAppSetupPolicy: string = '';
    HybridPSTNSiteName: string = '';
    HybridPSTNSiteFqdn: string = '';
    SipAddress: string = '';
    UserPrincipalName: string = '';
    AllowIPAudio: string = '';
    AllowIPVideo: string = '';
    EnableP2PVideo: string = '';
    TenantDialPlan: string = '';

    constructor(skypeForBusinessOutputData?){
        if(skypeForBusinessOutputData){  
            this.DialPlan = skypeForBusinessOutputData.DialPlan ? skypeForBusinessOutputData.DialPlan : "";              
            this.EnterpriseVoiceEnabled = booleanToStringConverter(skypeForBusinessOutputData.EnterpriseVoiceEnabled);              
            this.OnPremLineURI = skypeForBusinessOutputData.OnPremLineURI ? skypeForBusinessOutputData.OnPremLineURI : skypeForBusinessOutputData.LineUri ? skypeForBusinessOutputData.LineUri : '';  
            this.OtherIPPhone = skypeForBusinessOutputData.OtherIPPhone ? skypeForBusinessOutputData.OtherIPPhone : '';
            this.TeamsAppPermissionPolicy = skypeForBusinessOutputData.TeamsAppPermissionPolicy ? skypeForBusinessOutputData.TeamsAppPermissionPolicy : '';
            this.TeamsAppSetupPolicy = skypeForBusinessOutputData.TeamsAppSetupPolicy ? skypeForBusinessOutputData.TeamsAppSetupPolicy : '';
            this.OnlineVoiceRoutingPolicy = skypeForBusinessOutputData.OnlineVoiceRoutingPolicy ? skypeForBusinessOutputData.OnlineVoiceRoutingPolicy : '';
            this.TeamsCallingPolicy = skypeForBusinessOutputData.TeamsCallingPolicy ? skypeForBusinessOutputData.TeamsCallingPolicy : '';
            this.PhoneSystemLicense = skypeForBusinessOutputData.PhoneSystemLicense ? skypeForBusinessOutputData.PhoneSystemLicense : '';
            this.SipAddress = skypeForBusinessOutputData.SipAddress ? skypeForBusinessOutputData.SipAddress : '';
            this.SkypeForBusinesOnlineLicense = skypeForBusinessOutputData.SkypeForBusinesOnlineLicense ? skypeForBusinessOutputData.SkypeForBusinesOnlineLicense : '';
            this.TeamsLicense = skypeForBusinessOutputData.TeamsLicense ? skypeForBusinessOutputData.TeamsLicense : '';
            this.TeamsUpgradeEffectiveMode = skypeForBusinessOutputData.TeamsUpgradeEffectiveMode ? skypeForBusinessOutputData.TeamsUpgradeEffectiveMode : '';
            this.TenantDialPlan = skypeForBusinessOutputData.TenantDialPlan ? skypeForBusinessOutputData.TenantDialPlan : "";

            if(skypeForBusinessOutputData._ConferencingPolicy){
                this.AllowIPAudio = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowIPAudio);
                this.AllowIPVideo = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowIPVideo);
                this.EnableP2PVideo = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.EnableP2PVideo);
                this.AudioBitRate = skypeForBusinessOutputData._ConferencingPolicy.AudioBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.AudioBitRateKb + ' kb/s' : '';
                this.VideoBitRate = skypeForBusinessOutputData._ConferencingPolicy.VideoBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.VideoBitRateKb + ' kb/s' : '';
                this.MediaBitRateKb = skypeForBusinessOutputData._ConferencingPolicy.MediaBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.MediaBitRateKb + ' kb/s' : '';
                this.Identity = skypeForBusinessOutputData._ConferencingPolicy.Identity ? skypeForBusinessOutputData._ConferencingPolicy.Identity : '';
                this.MaxVideoConferenceResolution = skypeForBusinessOutputData._ConferencingPolicy.MaxVideoConferenceResolution ? skypeForBusinessOutputData._ConferencingPolicy.MaxVideoConferenceResolution : '';
                this.TotalReceiveVideoBitRate = skypeForBusinessOutputData._ConferencingPolicy.TotalReceiveVideoBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.TotalReceiveVideoBitRateKb + ' kb/s' : '';
                
                /* All those information are now hidden because not retrieved by the PEO ENGINE to limit the size of getUserInfo request in DB */
                /*
                this.AllowAnonymousUsersToDialOut = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowAnonymousUsersToDialOut);
                this.AllowExternalUsersToRecordMeeting = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowExternalUsersToRecordMeeting);
                this.AllowFederatedParticipantJoinAsSameEnterprise = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowFederatedParticipantJoinAsSameEnterprise);
                this.AllowLargeMeetings = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowLargeMeetings);
                this.AllowNonEnterpriseVoiceUsersToDialOut= booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.AllowNonEnterpriseVoiceUsersToDialOut);
                this.Anchor = skypeForBusinessOutputData._ConferencingPolicy.Anchor ? skypeForBusinessOutputData._ConferencingPolicy.Anchor : "";
                this.ApplicationSharingMode = skypeForBusinessOutputData._ConferencingPolicy.ApplicationSharingMode ? skypeForBusinessOutputData._ConferencingPolicy.ApplicationSharingMode : "";
                this.AppSharingBitRate = skypeForBusinessOutputData._ConferencingPolicy.AppSharingBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.AppSharingBitRateKb : "";                
                this.Description = skypeForBusinessOutputData._ConferencingPolicy.Description ? skypeForBusinessOutputData._ConferencingPolicy.Description : "";
                this.DisablePowerPointAnnotations = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.DisablePowerPointAnnotations);
                this.EnableAppDesktopSharing = skypeForBusinessOutputData._ConferencingPolicy.EnableAppDesktopSharing ? skypeForBusinessOutputData._ConferencingPolicy.EnableAppDesktopSharing : "";
                this.EnableDialInConferencing = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.EnableDialInConferencing);
                this.EnableOnlineMeetingPromptForLyncResources = booleanToStringConverter(skypeForBusinessOutputData._ConferencingPolicy.EnableOnlineMeetingPromptForLyncResources);
                this.FileTransferBitRate = skypeForBusinessOutputData._ConferencingPolicy.FileTransferBitRateKb ? skypeForBusinessOutputData._ConferencingPolicy.FileTransferBitRateKb : null;                
                this.Key = skypeForBusinessOutputData._ConferencingPolicy.Key ? skypeForBusinessOutputData._ConferencingPolicy.Key : "";
                this.MaxMeetingSize = skypeForBusinessOutputData._ConferencingPolicy.MaxMeetingSize ? skypeForBusinessOutputData._ConferencingPolicy.MaxMeetingSize : null;                
                this.RunspaceId = skypeForBusinessOutputData._ConferencingPolicy.RunspaceId ? skypeForBusinessOutputData._ConferencingPolicy.RunspaceId : "";
                this.ScopeClass = skypeForBusinessOutputData._ConferencingPolicy.ScopeClass ? skypeForBusinessOutputData._ConferencingPolicy.ScopeClass : "";                
                this.TypedIdentity = skypeForBusinessOutputData._ConferencingPolicy.TypedIdentity ? skypeForBusinessOutputData._ConferencingPolicy.TypedIdentity : "";               
                this.XsAnyAttributes = skypeForBusinessOutputData._ConferencingPolicy.XsAnyAttributes ? skypeForBusinessOutputData._ConferencingPolicy.XsAnyAttributes : "";
                this.XsAnyElements = skypeForBusinessOutputData._ConferencingPolicy.XsAnyElements ? skypeForBusinessOutputData._ConferencingPolicy.XsAnyElements : "";
                */
            }

            if(skypeForBusinessOutputData._PSTNSettings){
                this.HybridPSTNSiteName = skypeForBusinessOutputData._PSTNSettings.HybridPstnSiteName ? skypeForBusinessOutputData._PSTNSettings.HybridPstnSiteName : '';
                this.HybridPSTNSiteFqdn = skypeForBusinessOutputData._PSTNSettings.HybridPstnSiteFqdn ? skypeForBusinessOutputData._PSTNSettings.HybridPstnSiteFqdn : '';
                this.InternationalCallsAllowed = skypeForBusinessOutputData._PSTNSettings.AllowInternationalCalls? typeof(skypeForBusinessOutputData._PSTNSettings.AllowInternationalCalls) == 'boolean' ? booleanToStringConverter(skypeForBusinessOutputData._PSTNSettings.AllowInternationalCalls): skypeForBusinessOutputData._PSTNSettings.AllowInternationalCalls : '';  
            }
            if(skypeForBusinessOutputData._MeetingRoomEnabled){
                this.MeetingRoomEnabled = booleanToStringConverter(skypeForBusinessOutputData._MeetingRoomEnabled.Enabled);
            }
        }
    }
}

export class UserAccountDetailedInformationsByServices{
    Melinda: UserAccountDetailedInformations;
    Office365: UserAccountDetailedInformations;
    ExchangeOnline: UserAccountDetailedInformations;
    SkypeForBusiness: UserAccountDetailedInformations;
    Okta: UserAccountDetailedInformations;

    constructor(requestItemOutputData?, userAccountInformationsMelinda?: UserAccountDetailedInformations, userAccountInformationsOffice365?: UserAccountDetailedInformations, 
        userAccountInformationsExchangeOnline?: UserAccountDetailedInformations, userAccountInformationsMelindaSkypeForBusiness?: UserAccountDetailedInformations, 
        userAccountInformationsOkta?: UserAccountDetailedInformations){

        if(requestItemOutputData){
            this.Melinda = requestItemOutputData.ActiveDirectory ? new UserAccountDetailedInformations(requestItemOutputData.ActiveDirectory, 'Melinda') : new UserAccountDetailedInformations();
            this.Office365 = requestItemOutputData.AzureAD ? new UserAccountDetailedInformations(requestItemOutputData.AzureAD, 'Office365') : new UserAccountDetailedInformations();
            this.ExchangeOnline = requestItemOutputData.ExchangeOnline ? new UserAccountDetailedInformations(requestItemOutputData.ExchangeOnline, 'ExchangeOnline') : new UserAccountDetailedInformations();
            this.SkypeForBusiness = requestItemOutputData.SkypeForBusinessOnline ? new UserAccountDetailedInformations(requestItemOutputData.SkypeForBusinessOnline, 'SkypeForBusinessOnline') : new UserAccountDetailedInformations();
            this.Okta = requestItemOutputData.Okta ? new UserAccountDetailedInformations(requestItemOutputData.Okta, 'Okta') : new UserAccountDetailedInformations();                
        }else{
            this.Melinda = userAccountInformationsMelinda ? userAccountInformationsMelinda : new UserAccountDetailedInformations();            
            this.Office365 = userAccountInformationsOffice365 ? userAccountInformationsOffice365 : new UserAccountDetailedInformations();            
            this.ExchangeOnline = userAccountInformationsExchangeOnline ? userAccountInformationsExchangeOnline : new UserAccountDetailedInformations();            
            this.SkypeForBusiness = userAccountInformationsMelindaSkypeForBusiness ? userAccountInformationsMelindaSkypeForBusiness : new UserAccountDetailedInformations();            
            this.Okta = userAccountInformationsOkta ? userAccountInformationsOkta : new UserAccountDetailedInformations();
        }            
            
    }
}

export class UserAccountDetailedInformations{
    AccountType: string;
    IsProvisioned: string;
    Status: string;
    FirstName: string;
    LastName: string;
    DisplayName: string;
    PrimarySMTPAddress: string;
    Company: string;
    GBU: string;
    BusinessUnit: string;
    OrgID: string;
    ManagerialUnit: string;
    MailAddresses: Array<string>;
    IsLicensed: string;
    PreferredLanguage: string;
    LocalIT: string;
    CostCenter: string;
    UserPrincipalName: string;
    Site: string;
    Department: string;

    constructor(userServiceAccountOutputData?, accountType?: string, isProvisioned?: boolean, status?: string, firstName?: string, lastName?: string, displayName?: string, primarySMTPAddress?: string, company?: string, businessUnit?: string, managerialUnit?: string, mailAddresses?: Array<string>){
        if(userServiceAccountOutputData && accountType){            
            let emails = new Array<string>();
            switch (accountType) {
                case 'Melinda':
                    //True because ExchangeOnline not null
                    this.IsProvisioned = booleanToStringConverter(true);
                    this.Status = "";
                    this.FirstName = userServiceAccountOutputData.GivenName ? userServiceAccountOutputData.GivenName : "";
                    this.LastName = userServiceAccountOutputData.Surname ? userServiceAccountOutputData.Surname : "";
                    this.DisplayName = userServiceAccountOutputData.Name ? userServiceAccountOutputData.Name : "";
                    
                    if(userServiceAccountOutputData.proxyAddresses){
                        let unformatedPrimarySMTP = userServiceAccountOutputData.proxyAddresses.find(function(element) {
                            if(element.includes('SMTP:')){
                                return element;
                            }else{
                                return '';
                            }
                          }
                        );
                        unformatedPrimarySMTP = Array.isArray(unformatedPrimarySMTP) ? unformatedPrimarySMTP[0] : unformatedPrimarySMTP;
                        this.PrimarySMTPAddress = unformatedPrimarySMTP ? unformatedPrimarySMTP.substr(5) : '';
                    }else{
                        this.PrimarySMTPAddress = '';
                    }
                    
                    this.Company = userServiceAccountOutputData.Company ? userServiceAccountOutputData.Company : "";
                    this.BusinessUnit = userServiceAccountOutputData.extensionAttribute14 ? userServiceAccountOutputData.extensionAttribute14 : "";
                    //Don't know where to find it
                    this.ManagerialUnit = "";                    
                    this.MailAddresses = userServiceAccountOutputData.proxyAddresses ? userServiceAccountOutputData.proxyAddresses : new Array<string>();
                    break;            
                case 'Office365':
                    this.IsProvisioned = booleanToStringConverter(true);
                    this.Status = booleanToStringConverter(userServiceAccountOutputData.AccountEnabled, 'No', 'Yes');               
                    //Is Licensed true only if a pack license is assigned to the user
                    if(userServiceAccountOutputData._LicenseDetail){
                       this.IsLicensed = 'Yes';
                    }else{
                       this.IsLicensed = 'No';
                    }

                    this.FirstName = userServiceAccountOutputData.GivenName ? userServiceAccountOutputData.GivenName : "";
                    this.LastName = userServiceAccountOutputData.Surname ? userServiceAccountOutputData.Surname : "";
                    this.DisplayName = userServiceAccountOutputData.DisplayName ? userServiceAccountOutputData.DisplayName : "";
                    this.PrimarySMTPAddress = userServiceAccountOutputData.Mail ? userServiceAccountOutputData.Mail : "";
                    this.Company = userServiceAccountOutputData.CompanyName ? userServiceAccountOutputData.CompanyName : "";
                    this.BusinessUnit = "";
                    //Don't know where to find it
                    this.ManagerialUnit = "";                    
                    this.MailAddresses = userServiceAccountOutputData.ProxyAddresses ? userServiceAccountOutputData.ProxyAddresses : new Array<string>();                    
                    this.PreferredLanguage = userServiceAccountOutputData.PreferredLanguage ? userServiceAccountOutputData.PreferredLanguage : "";
                    this.UserPrincipalName = userServiceAccountOutputData.UserPrincipalName ? userServiceAccountOutputData.UserPrincipalName : "";
                    break;
                case 'ExchangeOnline':
                    //True because ExchangeOnline not null
                    this.IsProvisioned = booleanToStringConverter(true);
                    //Not sure the MailboxEnabled properties is to use as status
                    this.Status = booleanToStringConverter(userServiceAccountOutputData.IsMailboxEnabled, 'Disabled', 'Enabled');
                    this.FirstName = "";
                    this.LastName = "";
                    this.DisplayName = userServiceAccountOutputData.DisplayName ? userServiceAccountOutputData.DisplayName : "";
                    this.PrimarySMTPAddress = userServiceAccountOutputData.PrimarySmtpAddress ? userServiceAccountOutputData.PrimarySmtpAddress : "";
                    this.Company = userServiceAccountOutputData.CustomAttribute9 ? userServiceAccountOutputData.CustomAttribute9 : "";
                    this.BusinessUnit = userServiceAccountOutputData.customAttribute12 ? userServiceAccountOutputData.customAttribute12 : "";
                    //Don't know where to find it
                    this.ManagerialUnit = "";                    
                    this.MailAddresses = userServiceAccountOutputData.EmailAddresses ? userServiceAccountOutputData.EmailAddresses : new Array<string>();                    
                    break;
                case 'SkypeForBusinessOnline':
                    //True because SkypeForBusinessOnline not null
                    this.IsProvisioned = booleanToStringConverter(true);
                    break;
                case 'Okta':
                    //True because ExchangeOnline not null
                    this.IsProvisioned = booleanToStringConverter(true);
                    this.Status = userServiceAccountOutputData.status ? userServiceAccountOutputData.status : "";
                    this.FirstName = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.firstName ? userServiceAccountOutputData.profile.firstName : "";
                    this.LastName = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.lastName ? userServiceAccountOutputData.profile.lastName : "";
                    this.DisplayName = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.displayName ? userServiceAccountOutputData.profile.displayName : "";
                    this.PrimarySMTPAddress = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.email ? userServiceAccountOutputData.profile.email : "";
                    this.Company = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.organization ? userServiceAccountOutputData.profile.organization : "";
                    this.BusinessUnit = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.division ? userServiceAccountOutputData.profile.division : "";
                    this.GBU = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.C_msDSCloudExtensionAttribute3 ? userServiceAccountOutputData.profile.C_msDSCloudExtensionAttribute3 : "";
                    this.OrgID = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.C_msDSCloudExtensionAttribute2 ? userServiceAccountOutputData.profile.C_msDSCloudExtensionAttribute2 : "";
                    //Don't know where to find it
                    this.ManagerialUnit = "";
                    this.LocalIT = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.c_msDSCloudExtensionAttribute1 ? userServiceAccountOutputData.profile.c_msDSCloudExtensionAttribute1 : "";
                    this.CostCenter = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.c_costCenter ? userServiceAccountOutputData.profile.c_costCenter : "";
                    this.Site = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.C_physicalDeliveryOfficeName ? userServiceAccountOutputData.profile.C_physicalDeliveryOfficeName : "";
                    this.Department = userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.department ? userServiceAccountOutputData.profile.department : "";
                    
                    if(userServiceAccountOutputData.profile && userServiceAccountOutputData.profile.email){                        
                        emails.push(userServiceAccountOutputData.profile.email)
                    }
                    this.MailAddresses = emails;
                    break;
                default:
                   break;
            }
        }else{
            this.IsProvisioned = booleanToStringConverter(isProvisioned);
            this.Status = status ? status : "";
            this.FirstName = firstName ? firstName : "";
            this.LastName = lastName ? lastName : "";
            this.DisplayName = displayName ? displayName : "";
            this.PrimarySMTPAddress = primarySMTPAddress ? primarySMTPAddress : "";
            this.Company = company ? company : "";
            this.BusinessUnit = businessUnit ? businessUnit : "";
            this.ManagerialUnit = managerialUnit ? managerialUnit : "";
            this.MailAddresses = mailAddresses ? mailAddresses : new Array<string>();
        }
    }
}
