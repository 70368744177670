import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '../app/app.service';
import { UserService } from './Services/user.service';
import { LocalItCard } from './Models/LocalItCard';
import { UserInfo } from './Models/UserInfo';

@Injectable()
export class AuthGuard  {
  public currentUserInfo: UserInfo = new UserInfo();
  public selectedLocalITCard: LocalItCard = new LocalItCard();

  constructor(private oauthService: OktaAuthService, private router: Router, private userService: UserService) {
    this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
    this.userService.selectedUserLocalITCardObs.subscribe(updatedLocalITDetails => this.selectedLocalITCard = new LocalItCard(updatedLocalITDetails));
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.oauthService.isAuthenticated()) {
      if (route && route.url && route.url[0]) {
        //Manage Product Access
        if (route.url[0].path == 'products') {
          if (route.url[1] && route.url[1].path) {
            if (this.selectedLocalITCard && ((Array.isArray(this.selectedLocalITCard.Products) && this.selectedLocalITCard.Products.some(p => (p.Name.split('-').join('')).toUpperCase() == route.url[1].path.toUpperCase() && p.Enabled))
              || (this.selectedLocalITCard.Pilot && Array.isArray(this.selectedLocalITCard.PilotProducts) && this.selectedLocalITCard.PilotProducts.some(p => (p.Name.split('-').join('')).toUpperCase() == route.url[1].path.toUpperCase())))
            ) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }

        //Manage Administration Access
        if (route.url[0].path == 'administration') {
          if (this.currentUserInfo && this.currentUserInfo.IsAdmin) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
      return true;
    } else {
      this.oauthService.login('/');
    }
    return false;
  }
}