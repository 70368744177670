export class CreateDetails{
    Output
    ProductName: string = '';

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName;
            this.Output = requestItemOutput;
        }
    }
}