import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { AddAllocIDPermissionFileContent, AddAllocIDPermissionRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './addAllocIDPermission.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddAllocIDPermissionComponent{
    public fileContentsList = new Array <AddAllocIDPermissionFileContent>();
    public fileContent = new AddAllocIDPermissionFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    topLocalIT: Array<string> =  new Array<string>();
    isTopLocalITSelected = false;
    public selectedLocalIT:String = '';
    public roles:Array<string> = new Array<string>();
    public profiles:Array<string> = new Array<string>();
    public scopes:Array<string> = new Array<string>();
    
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private localITsService: LocalITsService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.localITsService.getLocalITsWithManageAllPermission().subscribe(response => {
            this.topLocalIT = response;
            this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
                if (this.topLocalIT.indexOf(this.selectedLocalIT as string) !== -1) {
                    this.isTopLocalITSelected = true;
                }
                else {
                    this.isTopLocalITSelected = false;
                }
            });
        });

        this.businessRulesService.getSpecificListByKey('ALLOCID', 'Roles')
            .subscribe(response => {this.roles = response;});
        this.businessRulesService.getSpecificListByKey('ALLOCID', 'Profiles')
            .subscribe(response => {this.profiles = response;});
        this.businessRulesService.getSpecificListByKey('ALLOCID', 'Scopes')
            .subscribe(response => {this.scopes = response;});
    }

    ngOnDestroy(){
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array <AddAllocIDPermissionFileContent>();
    }

    clearFileContentForms(){
        this.fileContent.GroupID = '';
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    //Add the current fileContent to the fileContentList and clean the forms (Need reflexion for the Edit)
    addFileContentToList(){
        var tempObject = new AddAllocIDPermissionFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        var data = new AddAllocIDPermissionRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        this.requestContainerService.sendProductRequest(data, '/addAllocIDPermission')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }
}