import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { environment } from "../../environments/environment";


export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/companies";
export const API_VERSION = "/v1";

@Injectable()  
export class CompaniesService {  
  constructor(private http: HttpClient) { }
}