import { Component, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { HomeService } from '../../../../Services/home.service';
import { UserService } from '../../../../Services/user.service';

import { TopBarMessage, TopBarMessageRequest } from '../../../../Models/TopBarMessage';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../../Models/UserInfo';


@Component({
    selector: 'manageTopBarMessage',
    templateUrl: './manageTopBarMessage.component.html',
    providers: [HomeService],
    styleUrls: ['../../../../app.component.css']
})

export class ManageTopBarMessageComponent{
    public fileContentsList = new Array<TopBarMessage>();
    public topBarMessage = new TopBarMessage();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public isCreation:boolean = true;
    public messageId:string = null;
    public startTime:string = '';
    public endTime:string = '';
    
    constructor(private homeService: HomeService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public dialog: MatDialog){
    
    }

    ngOnInit(){
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    
        this.route.queryParams.subscribe(params => { 
            this.messageId = params['IT'] || null;

            if(this.messageId){ 
                this.isCreation = false;    
                this.homeService.getMessageInfoById(this.messageId).subscribe(response => { 
                    this.topBarMessage = new TopBarMessage(response);

                    if(this.topBarMessage.StartDateTime != ''){ 
                        this.startTime = this.topBarMessage.StartDateTime.split('T')[1].split(':')[0] + ":" + this.topBarMessage.StartDateTime.split('T')[1].split(':')[1];   
                    }
                  
                    if(this.topBarMessage.EndDateTime != ''){ 
                        this.endTime = this.topBarMessage.EndDateTime.split('T')[1].split(':')[0] + ":" + this.topBarMessage.EndDateTime.split('T')[1].split(':')[1];   
                    }
                });               
            }
        });
    }
        
    ngOnDestroy(){
    }

    sendRequest(){   
        var data = new TopBarMessageRequest();
        data.requestParams = this.topBarMessage;
        data.localIT = this.selectedLocalIT;
        

        this.homeService.addOrUpdateMessageInfo(data)
            .subscribe( response => {
                let successSnackBar;
                if(this.isCreation){
                    successSnackBar = this.snackBar.open("ALERT MESSAGE CREATED !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });
                }else{
                    successSnackBar = this.snackBar.open("ALERT MESSAGE UPDATED !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });
                }

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['administration/topBarMessages']);
                })     
            }, 
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
            }
        );
    }

    fromDateChanged(newValue){
        var tempDate = new Date(newValue);
        if(!isNaN(tempDate.valueOf())){
            if(this.startTime != ''){
                tempDate = new Date(tempDate.setHours(Number(this.startTime.split(':')[0]),Number(this.startTime.split(':')[1]),0,0));
            }else{
                tempDate = new Date(tempDate.setHours(0,0,0,0));
            }
        }
    
        this.topBarMessage.StartDateTime = tempDate.toISOString();
    }
    
    toDateChanged(newValue){
        var tempDate = new Date(newValue);
        if(!isNaN(tempDate.valueOf())){
            if (this.endTime != ''){
                tempDate = new Date(tempDate.setHours(Number(this.endTime.split(':')[0]),Number(this.endTime.split(':')[1]),0,0));
            }else{
                tempDate = new Date(tempDate.setHours(23,59,59,999));
            }
        }
    
        this.topBarMessage.EndDateTime = tempDate.toISOString();
    }

    changeShowStatus(){
        this.topBarMessage.Show = !this.topBarMessage.Show;
    }

    addStartTimeToDate(){
        if (this.startTime != ''){
            this.topBarMessage.StartDateTime = this.topBarMessage.StartDateTime.split('T')[0] + 'T' + this.startTime + ':00.000Z';
        }    
    }

    addEndTimeToDate(){
        if (this.endTime != ''){
            this.topBarMessage.EndDateTime = this.topBarMessage.EndDateTime.split('T')[0] + 'T' + this.endTime + ':00.000Z';
        } 
    }
}