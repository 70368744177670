<h2 mat-dialog-title class="text-primary font-weight-bold">Url Deletion Confirmation</h2>
<mat-dialog-content class="font-weight-bold">
    Are you sure you want to delete the Url(s) below:
    <ul *ngFor="let url of Data">
        <li>
            {{url}}
        </li>
    </ul>
</mat-dialog-content>
<br />
<mat-dialog-actions style="float: right">
    <button class="btn btn-danger" style="margin-right:10px;" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" [mat-dialog-close]="true">Yes</button>
</mat-dialog-actions>