<h4>Add Zscaler ByPass:</h4>
<div class="center" *ngIf="isWait">
  <mat-spinner diameter=50 
                        mode="indeterminate"
                        color="primary">
  </mat-spinner> 
</div>
<br /><br />
<form [formGroup]="addZscalerBypassRequestForm">
  <div class="row form-group">
    <div class="col-4">
      <label for="type" class="required">ByPass Type:</label>
      <select *ngIf="bypassTypes.length > 0" id="type" formControlName="type" class="form-control" required>
        <option disabled value="null">Select a bypass type</option>
        <option *ngFor="let bypassType of bypassTypes" [value]="bypassType.Name">{{bypassType.Name}}</option>
      </select>
      <small id="urlinfo" class="form-text text-muted">"Function":  Bypassed to maintain functionality.</small>
      <small id="urlinfo" class="form-text text-muted">"Privacy": Bypassed to safeguard user privacy.</small>
      <small id="urlinfo" class="form-text text-muted">"Trust": Bypassed to prevent Zscaler from accessing this traffic.</small>
    </div>
    <div class="col-4">
      <label for="url" class="required">Add New URL:</label>
      <input id="url" type="text" formControlName="url" class="form-control" placeholder="www.safemarch.com" required />
      <small *ngIf="addZscalerBypassRequestForm.controls.url.errors?.pattern" id="alertUser"
        class="form-text text-danger">{{alertUserInvalidUrl}}</small>
    </div>
    <div class="col-4">
      <label for="validatedByCiso" class="required">Validated with my CISO:</label>
      <div>
        <div style="display: inline-block;margin-right: 5px;">
          <label><input type="radio" id="validatedByCiso" formControlName="validatedByCiso" class="form-control"
              [value]="true">Yes</label>
        </div>
        <div style="display: inline-block;">
          <label><input type="radio" id="validatedByCiso" formControlName="validatedByCiso" class="form-control"
              [value]="false">No</label>
        </div>
      </div>
      <small *ngIf="!addZscalerBypassRequestForm.value.validatedByCiso" id="alertUser"
        class="form-text text-muted">{{alertUser}}</small>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-4">
      <label class="required" for="isBypassTest">Is it a ByPass Test:</label>
      <div id="isBypassTest">
        <div style="display: inline-block;margin-right: 5px;">
          <label><input type="radio" id="isBypassTestInputOpt1" formControlName="isBypassTest" class="form-control"
              [value]="true">Yes</label>
        </div>
        <div style="display: inline-block;">
          <label><input type="radio" id="isBypassTestInputOpt2" formControlName="isBypassTest" class="form-control"
              [value]="false">No</label>
        </div>
      </div>
      <small *ngIf="addZscalerBypassRequestForm.value.isBypassTest" id="alertUser"
        class="form-text text-muted">{{alertUser}}</small>
    </div>
    <div class="col-4">
      <label class="required" for="endDate">ByPass End Date:</label>
      <div class="col-12" style="display:inline">
        <input class="form-control" style="width: 70%; float: left;" matInput [min]="minDate" [max]="maxDate"
          [matDatepicker]="endDatePicker" placeholder="Period" formControlName="endDate">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </div>
    </div>
    <div class="col-4">
      <label class="required" for="description">Description:</label>
      <div style="display:flex">
        <textarea type="text" id="description" formControlName="description" class="form-control" required>
          </textarea>
      </div>
      <small id="urlinfo" class="form-text text-muted">Add a short description to justify this addition and explain why
        it’s required.</small>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-4">
      <label for="ticketID">Ticket ID:</label>
      <input type="text" id="ticketID" formControlName="ticketID" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-2 offset-8" style="padding-top:33px;float: left;">
      <button type="submit" class="btn btn-primary" (click)="sendRequest();"
        [disabled]="(addZscalerBypassRequestForm.invalid)">Send Request</button>
    </div>
  </div>
</form>