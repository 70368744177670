import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';

import { EnableTeamsRoomMailboxFileContent, EnableTeamsRoomMailboxRequest } from '../../../Models/FileContents';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { booleanToStringConverter, removeArrayEmptyValues } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';



@Component({
    selector: 'BodyContent',
    templateUrl: './enableTeamsRoomMailbox.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class EnableTeamsRoomMailboxComponent{
    //Hardcoded List Values
    public languages:Array<any> = new Array<any>();
    public managementScopes:Array<any> = new Array<any>();
    public timeZones:Array<any> = new Array<any>();
    public workingHours:Array<any> = new Array<any>();
    public licenseTypes: Array<any> = new Array<any>();
    public vRPolicies:Array<string> = new Array<string>();
    public bookingProfiles:Array<string> = new Array<string>();
    public requestContainer:any = {};
    public fileContentsList = new Array<EnableTeamsRoomMailboxFileContent>();
    public fileContent = new EnableTeamsRoomMailboxFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public subscribedLicenses = [];
    public availableLicensesCounter = null;
    public customerProfileImported:boolean = false;
    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
    public message: string = "";
    public maxCharacters = 46;
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';
    public groupIdNoAdminPattern: string = inputValidationHelper.groupIdNoAdminFormatRegEx;
    public namePattern:string = inputValidationHelper.mailboxNameRegEx;
    private source = new EnableTeamsRoomMailboxFileContent();
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, private oktaApiService: OktaApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private customerProfilesService: CustomerProfilesService, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        //this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.vRPolicies = [];
                this.fileContent  = new EnableTeamsRoomMailboxFileContent();

                this.businessRulesService.getSpecificListByKey('DLTYPES', 'VRPolicies')
                .subscribe(response => {
                    this.vRPolicies = response[this.selectedLocalIT.toString()];
        
                    if (this.vRPolicies) {
                        this.fileContent.VRPolicy = this.vRPolicies[0];
                    }
                });
                this.graphApiService.getSubscribedLicenses().subscribe(subscribedLicenses => {
                    this.subscribedLicenses = subscribedLicenses,
                    this.businessRulesService.getSpecificListByKey('LICENSESKU', 'RoomLicenses').subscribe(response => {
                        this.licenseTypes = response,
                            this.licenseTypes = this.licenseTypes.sort(function (a, b) {
                                var x = a.name.toLowerCase();
                                var y = b.name.toLowerCase();
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            })
                    });
                });
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        this.fileContent = cb[0];
                        this.getAADUser();
                        this.calculateCharacters();
                    }
                    if (cb && this.fileContent.License) {
                        this.getNumberOfAvailableLicences(this.fileContent.License);
                    }
                });
            });
        
        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => { this.languages = response; });
        this.businessRulesService.getSpecificListByKey('WORKINGHOURS', 'WorkingHours')
            .subscribe(response => {this.workingHours = response;});
        this.businessRulesService.getSpecificListByKey('TIMEZONES', 'TimeZones')
            .subscribe(response => {this.timeZones = response;});
        this.businessRulesService.getSpecificListByKey('BOOKING-PROFILES', 'BookingProfiles')
            .subscribe(response => {this.bookingProfiles = response;});
        delete this.source.GroupID; 
    }

    ngOnDestroy(){
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdNoAdminFormat(groupID);
    }

    calculateCharacters() {
        this.message = `The length of Site (${this.fileContent.Site.length}) + Name (${this.fileContent.Name.length}) should not exceed ${this.maxCharacters} characters. Remaining characters: ${this.maxCharacters - this.fileContent.Site.length - this.fileContent.Name.length}`;
    }

    errorsInForm() {
        return this.fileContent.Site.length + this.fileContent.Name.length > this.maxCharacters;
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<EnableTeamsRoomMailboxFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new EnableTeamsRoomMailboxFileContent();
        this.message = "";
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new EnableTeamsRoomMailboxFileContent(this.fileContent);
        tempObject.AdditionalManager = removeArrayEmptyValues(tempObject.AdditionalManager);
        tempObject.ResourceDelegates = removeArrayEmptyValues(tempObject.ResourceDelegates);
        this.clearTelephonyFields(tempObject);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    clearTelephonyFields(tempObject){
        if(!(tempObject.EnableTeamsTelephony)){
            tempObject.PhoneNumber = '';
            tempObject.VRPolicy = '';
        }
    }

    getAADUser() {
        if (this.fileContent.GroupID) {
            let user = null;
            this.graphApiService.getUser(this.fileContent.GroupID.toString()).subscribe({
                next: (response) => {
                    user = response;
                    if (!user) {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on EntraID!';
                        return;
                    }
                    if (user.onPremisesExtensionAttributes.extensionAttribute11 && !user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase().match('ROOM')) {
                        this.fileContent.GroupIDDisplayNameError = `The mailbox type ${user.onPremisesExtensionAttributes.extensionAttribute11} is not supported for this product`;
                        return;
                    }
                    else if (user.onPremisesSyncEnabled) {
                        this.fileContent.GroupIDDisplayNameError =  `${this.fileContent.GroupID.toString()} is not cloud only`;
                        return;
                    }
                    this.fileContent.GroupIDDisplayName = user.displayName ? user.displayName : `No displayName!`;
                    this.fileContent.GroupIDDisplayNameError = '';
                    if (user?.onPremisesExtensionAttributes?.extensionAttribute11) {
                        this.fileContent.ExtensionAttribute11 = user?.onPremisesExtensionAttributes?.extensionAttribute11?.toUpperCase();
                    }

                    if (!this.fileContent.Manager && user?.manager?.mailNickname) {
                        this.fileContent.Manager = user?.manager?.mailNickname;
                    }
                    let fields = user.displayName.split(" \(");
                    fields = fields.slice(0,fields.length - 1).join(" (");
                    fields = fields.split("-");
                    if (fields.length >= 4) {
                        if (fields[1].length == 2 && !this.fileContent.CountryCode) {
                            this.fileContent.CountryCode = fields[1];
                        }
                        if (fields[2].length >= 2 && fields[2].length <= 10 && !this.fileContent.Site) {
                            this.fileContent.Site = fields[2];
                        }
                        if (!this.fileContent.Name) {
                            this.fileContent.Name = fields.slice(3,fields.length).join("-");
                        }
                    }
                    this.fileContent.MailboxLanguage = '';
                },
                error: (error) => {
                    this.fileContent.GroupIDDisplayName = '';
                    if (error && error.status && error.status == '404') {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on EntraID!';
                    }
                    else {
                        this.fileContent.GroupIDDisplayNameError = 'Error when trying to check the user in EntraID';
                    }
                }
            });
        }
    }

    getNumberOfAvailableLicences(licenseSKU) {
        if (licenseSKU) {
            let currentLicense = this.subscribedLicenses.find(obj => obj.skuPartNumber == licenseSKU);
            this.availableLicensesCounter = currentLicense ? (currentLicense.prepaidUnits.enabled + currentLicense.prepaidUnits.warning - currentLicense.consumedUnits) : 0;
        }
        else {
            this.availableLicensesCounter = null;
        }
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?) {
        //Add the current fileContent to the fileContentsList if the form is valid
        if (isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError && !this.fileContent.ManagerDisplayNameError && !this.errorsInForm()) {
            this.addFileContentToList();
        }
        
        //Initiatiate the request data
        var data = new EnableTeamsRoomMailboxRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/enableTeamsRoomMailbox')
            .subscribe( response => {
                            this.clearPage();

                            //Display a success notification for 2sec
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            //Redirect to request history page
                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            //In case of API call error display a popup with information about the error
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    importCustomerProfile(){
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if(inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)){
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if (response){
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;

                        if(response.TimeZone){
                            this.fileContent.TimeZone = response.TimeZone;
                            document.getElementById("TimeZoneDropdownList").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }

                        if(response.MailboxLanguage){
                            this.fileContent.MailboxLanguage = response.MailboxLanguage;
                            document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }

                        if(missingVal){
                            this.importDefaultCustomerProfile();
                        }
                    }else{
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile(){
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if(inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)){
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if(response){
                        if(!this.fileContent.TimeZone){
                            if(response.TimeZone){
                                this.fileContent.TimeZone = response.TimeZone;
                                document.getElementById("TimeZoneDropdownList").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }

                        if(!this.fileContent.MailboxLanguage){
                            if(response.MailboxLanguage){
                                this.fileContent.MailboxLanguage = response.MailboxLanguage;
                                document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                    }                    
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public emptyForm() {
        this.fileContent = Object.assign(this.fileContent,this.source);
        this.message = '';
        this.setBackEmptyForm();
    }

    public setBackEmptyForm(){
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        if (this.customerProfileImported) {
            if (!this.fileContent.GroupID) {
                document.getElementById("TimeZoneDropdownList").style.border = "1px solid rgba(0,0,0,.15)";
                document.getElementById("createMailboxLanguageDropDown").style.border = "1px solid rgba(0,0,0,.15)";
            }
            var groupId = this.fileContent.Manager;
            if(!inputValidationHelper.checkGroupIdNoAdminFormat(groupId)){
                this.fileContent.TimeZone = "";
                this.fileContent.MailboxLanguage = "";
                this.customerProfileImported = false;
            }  
        }
    }
}