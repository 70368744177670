import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { OktaApiService } from '../../../Services/oktaApi.service';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'GroupIDInputFieldOkta',
    templateUrl: './groupIDInputFieldOkta.component.html',
    styleUrls: ['../../../app.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class GroupIDInputFieldOktaComponent{
    public groupIDPattern:string = '';
    groupIDDisplayNameValue:string = '';
    groupIDValue:string = '';
    groupIDDisplayNameErrorValue:string = '';
    groupIDProfileValue;

    constructor(private oktaApiService: OktaApiService){}    

    @Input() nameSuffix: string;
    @Input() required: boolean = true;
    @Input() groupIDShouldNotExist: string;
    @Input() groupIDShouldBeAnOktaCloudAccount: boolean;
    @Input() groupIDShouldNotBeAnOktaCloudAccount: boolean;
    @Input() groupIDShouldBeActive: boolean;
    @Input() noAdmin: string;
    @Input() label: string;
    @Input() B2B: string;
    @Output() groupIDChange = new EventEmitter();
    @Output() groupIDDisplayNameChange = new EventEmitter();
    @Output() groupIDDisplayNameErrorChange = new EventEmitter();
    @Output() groupIDProfileChange = new EventEmitter();

    @Input()
    get groupID() {
        return this.groupIDValue;
    }

    set groupID(val){
        this.groupIDValue = val;
        this.groupIDChange.emit(this.groupIDValue);
        this.groupIDDisplayName = '';
        this.groupIDDisplayNameError = '';
        this.groupIDPattern = this.noAdmin == 'noAdmin' ? inputValidationHelper.groupIdNoAdminFormatRegEx : this.noAdmin == 'onlyAdmin' ? inputValidationHelper.groupIdAdminFormatRegEx: inputValidationHelper.groupIdFormatRegEx;
    }

    get groupIDDisplayNameError() {
        return this.groupIDDisplayNameErrorValue;
    }

    get groupIDDisplayName() {
        return this.groupIDDisplayNameValue;
    }

    get groupIDProfile(){
        return this.groupIDProfileValue;
    }

    set groupIDDisplayName(val){
        this.groupIDDisplayNameValue = val;
        this.groupIDDisplayNameChange.emit(this.groupIDDisplayNameValue);
    }

    set groupIDDisplayNameError(val){
        this.groupIDDisplayNameErrorValue = val;
        this.groupIDDisplayNameErrorChange.emit(this.groupIDDisplayNameErrorValue);
    }

    set groupIDProfile(val){
        this.groupIDProfileValue = val;
        this.groupIDProfileChange.emit(this.groupIDProfileValue);
    }


    checkGroupIDValidity(){
        let groupIDValueCopy = new String(this.groupIDValue ? this.groupIDValue : '');
        if (this.noAdmin == 'noAdmin' ? inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy) : this.noAdmin == 'onlyAdmin' ? inputValidationHelper.checkGroupIdAdminFormat(groupIDValueCopy) : inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.oktaApiService[`get${this.B2B ? this.B2B : ''}UserByGroupID`](groupIDValueCopy.toString()).subscribe(
                response => {
                    if(this.groupIDShouldBeAnOktaCloudAccount && response.provider && response.provider.name != 'OKTA'){
                        this.groupIDDisplayNameError = groupIDValueCopy + " isn't an Okta Cloud Account!";
                        this.groupIDDisplayName = '';
                    }
                    else if (this.groupIDShouldNotBeAnOktaCloudAccount && response.provider && response.provider.name == 'OKTA') {
                        this.groupIDDisplayNameError = groupIDValueCopy + " is an Okta Cloud Account!";
                        this.groupIDDisplayName = '';
                    } 
                    else if (this.groupIDShouldBeActive && !['PROVISIONED','ACTIVE'].includes(response.status.toUpperCase())) {
                        this.groupIDDisplayNameError = `${groupIDValueCopy} in not active but [${response.status}]`;
                    } 
                    else {
                        if (response.displayName) {
                            this.groupIDDisplayName = response.displayName;
                        }
                        else {
                            this.groupIDDisplayName = response.lastName + ' ' + response.firstName;
                        }
                        this.groupIDProfile = response;
                        this.groupIDDisplayNameError = '';
                    }
                },
                error => {
                    if(error && error.status && error.status == '404'){                        
                        this.groupIDDisplayNameError = this.groupIDShouldNotExist ? groupIDValueCopy + ' is available!' : groupIDValueCopy + ' is not found in OKTA!';
                        this.groupIDDisplayName = '';
                    }else{
                        this.groupIDDisplayNameError = 'Error when trying to check GroupID!';
                        this.groupIDDisplayName = '';
                    }
                }
            )
        }
    }

}