interface HashTable<T> {
    [key: string]: T;
}

export class LocalItCard {
    Name:string = '';
    DisplayName:string = '';
    Manager:LocalItCardManager = new LocalItCardManager();
    Members:Array<LocalItCardMember> = new Array<LocalItCardMember>(new LocalItCardMember());
    LocalITContactEmail:string = '';
    ContactPhoneNumber:string = '';
    ManageAll:boolean = false;
    ShowAll:boolean = false;
    Pilot:boolean = false;
    Quotas:LocalItCardQuota = new LocalItCardQuota();
    Products:Array<LocalItCardProduct> = new Array<LocalItCardProduct>();
    PilotProducts:Array<LocalItCardProduct> = new Array<LocalItCardProduct>();

    constructor(localItCard?:LocalItCard){
        if(localItCard){
            var tmpProducts = new Array();
            var tmpPilotProducts = new Array();

            this.Name = localItCard.Name ? localItCard.Name : '';
            this.DisplayName = localItCard.DisplayName ? localItCard.DisplayName : '';
            this.Manager = localItCard.Manager ? localItCard.Manager : new LocalItCardManager();
            if(localItCard.Members){
                this.Members = localItCard.Members.length > 0 ? localItCard.Members : new Array<LocalItCardMember>(new LocalItCardMember());
            }else{
                this.Members = new Array<LocalItCardMember>(new LocalItCardMember());
            }
            this.LocalITContactEmail = localItCard.LocalITContactEmail ? localItCard.LocalITContactEmail : '';
            this.ContactPhoneNumber = localItCard.ContactPhoneNumber ? localItCard.ContactPhoneNumber : '';
            this.ManageAll = localItCard.ManageAll? localItCard.ManageAll : false;
            this.ShowAll = localItCard.ShowAll? localItCard.ShowAll : false;
            this.Pilot = localItCard.Pilot? localItCard.Pilot : false;
            this.Quotas = localItCard.Quotas ? localItCard.Quotas : new LocalItCardQuota();
            if(localItCard.Products){ 
                Object.keys(localItCard.Products).forEach(function(elementKey){
                    if (elementKey != "id"){
                        if(localItCard.Products[elementKey] != "PILOT"){
                            tmpProducts.push(new LocalItCardProduct(elementKey,localItCard.Products[elementKey]));
                            tmpPilotProducts.push(new LocalItCardProduct(elementKey,localItCard.Products[elementKey]));
                        }else{
                            tmpPilotProducts.push(new LocalItCardProduct(elementKey,localItCard.Products[elementKey]));
                        }                        
                    }
                });
                this.Products = tmpProducts;
                this.PilotProducts = tmpPilotProducts;
            }
        }
    }
}

export class LocalItCardFormatedForSending {
    Name:string = '';
    DisplayName:string = '';
    Manager:string = '';
    Members:Array<string> = new Array<string>();
    LocalITContactEmail:string = '';
    ContactPhoneNumber:string = '';
    ManageAll:boolean = false;
    ShowAll:boolean = false;
    Pilot:boolean = false;
    Quotas:LocalItCardQuota = new LocalItCardQuota();
    Products: HashTable<string> = {};
    PilotProducts: HashTable<string> = {};

    constructor(localItCard?:LocalItCard){
        if(localItCard){
            var tmpProducts = {};
            
            this.Name = localItCard.Name ? localItCard.Name : '';
            this.DisplayName = localItCard.DisplayName ? localItCard.DisplayName : '';
            this.Manager = localItCard.Manager && localItCard.Manager.GroupID ? localItCard.Manager.GroupID : '';
            this.Members = Array.isArray(localItCard.Members)? localItCard.Members.map(member => member.GroupID) : new Array<string>();
            this.LocalITContactEmail = localItCard.LocalITContactEmail ? localItCard.LocalITContactEmail : '';
            this.ContactPhoneNumber = localItCard.ContactPhoneNumber ? localItCard.ContactPhoneNumber : '';
            this.ManageAll = localItCard.ManageAll? localItCard.ManageAll : false;
            this.ShowAll = localItCard.ShowAll? localItCard.ShowAll : false;
            this.Pilot = localItCard.Pilot? localItCard.Pilot : false;
            this.Quotas = localItCard.Quotas ? localItCard.Quotas : new LocalItCardQuota();
            if(localItCard.Products){ 
                localItCard.Products.forEach(function(element){
                    tmpProducts[element.Name] = element.Enabled;
                });
                this.Products = tmpProducts;
            }
        }
    }
}

export class ManageLocalITCardRequest{
    requests: Array<LocalItCardFormatedForSending>;
    localIT: String;
    isCreation: boolean;

    constructor(){}
}

export class ManageLocalITCardMembersRequest{
    newMembers: Array<string>;
    localIT: String;
    editedLocalIT: String;

    constructor(){}
}

export class LocalItCardPreview {
    Name:string = ' ';
    ManagerName:string = ' ';
    MembersNames:string = ' ';

    constructor(localItCard?){
        if(localItCard){
            this.Name = localItCard.Name ? localItCard.Name : ' ';
            this.ManagerName = localItCard.Manager && localItCard.Manager.Name ? "[" + localItCard?.Manager?.GroupID?.toUpperCase() + "] " + localItCard.Manager.Name : ' ';
            if (localItCard.Members){
                localItCard.Members.forEach(element => {
                    this.MembersNames +=  "[" + element?.GroupID?.toUpperCase() + "] " + element.Name + "<br>";    
                });
            }
        }
    }
}

export class LocalItCardManager {
    GroupID:string = '';
    Email:string = '';
    Name:string = '';  

    constructor(manager?){
        if(manager){
            this.GroupID = manager.GroupID ? manager.GroupID : '';
            this.Email = manager.Email ? manager.Email : '';
            this.Name = manager.Name ? manager.Name : '';            
        }
    }
}

export class LocalItCardMember {
    Name:string = '';
    Email:string = '';
    GroupID:string = '';

    constructor(member?){
        if(member){
            this.Name = member.Name ? member.Name : '';
            this.Email = member.Email ? member.Email : '';
            this.GroupID = member.GroupID ? member.GroupID : '';
        }
    }
}

export class LocalItCardQuota {
    High:number = 0;
    Low:number = 0;
    HighDefault:number = 0;
    LowDefault:number = 0;

    constructor(quota?){
        if(quota){
            this.High = quota.High ? quota.High : 0;
            this.Low = quota.Low ? quota.Low : 0;
            this.HighDefault = quota.HighDefault ? quota.HighDefault : 0;
            this.LowDefault = quota.LowDefault ? quota.LowDefault : 0;
        }
    }
}

export class LocalItCardProduct {
    Name:string = '';
    Enabled:any = false;

    constructor(name?: string, enabled?: any){
        this.Name = name ? name : '';
        this.Enabled = enabled ? enabled : false;
    }
}


