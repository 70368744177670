import { Component, Input } from '@angular/core';
import { GetMailboxInfoDetails } from '../../../../Models/History/GetMailboxInfoDetails';

@Component({
    selector: 'GetMailboxInfoHistoryDetails',
    templateUrl: './getMailboxInfoHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetMailboxInfoHistoryDetailsComponent{
    @Input() getMailboxInfoDetails: GetMailboxInfoDetails;
    mailboxInfoCollapsed: boolean = true;
    recipientInfoCollapsed: boolean = true;
    mobileDeviceInfoCollapsed: boolean = true;
    fullAccessPermCollapsed: boolean = true;
    sendAsPermCollapsed: boolean = true;
    sendOnBehalfPermCollapsed: boolean = true;
    groupMembershipCollapsed: boolean = true;

    showAll(){
        this.mailboxInfoCollapsed = false;
        this.recipientInfoCollapsed = false;
        this.mobileDeviceInfoCollapsed = false;
        this.fullAccessPermCollapsed = false;
        this.sendAsPermCollapsed = false;
        this.sendOnBehalfPermCollapsed = false;
        this.groupMembershipCollapsed = false;
    }
    
    hideAll(){
        this.mailboxInfoCollapsed = true;
        this.recipientInfoCollapsed = true;
        this.mobileDeviceInfoCollapsed = true;
        this.fullAccessPermCollapsed = true;
        this.sendAsPermCollapsed = true;
        this.sendOnBehalfPermCollapsed = true;
        this.groupMembershipCollapsed = true;
    }
}