import { Component, Input } from '@angular/core';
import { booleanToStringConverter } from '../../../../Helpers/utils';

@Component({
    selector: 'RequestDetailsParameters',
    templateUrl: './requestDetailsParameters.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class RequestDetailsParametersComponent{
    @Input() productName: string;
    @Input() parameters: any;

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    isParameterArray(param){
        return Array.isArray(param);
    }

    parameterContains(param, value:string){
        if (!param || !value){
            return false;
        }

        if(Array.isArray(param)){
            return param.includes(value) || param.includes(value.toUpperCase());
        }else{
            return param.toUpperCase() == value.toUpperCase();
        }
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }
}