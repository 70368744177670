export class CreateMEUDetails{
    DisplayName: string = '';
    Alias: string = '';
    PrimarySmtpAddress: string = '';
    ExternalEmailAddress: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.DisplayName = requestItemOutput.DisplayName ? requestItemOutput.DisplayName : '';
            this.Alias = requestItemOutput.Alias ? requestItemOutput.Alias : '';
            this.PrimarySmtpAddress = requestItemOutput.PrimarySmtpAddress ? requestItemOutput.PrimarySmtpAddress : '';
            this.ExternalEmailAddress = requestItemOutput.ExternalEmailAddress ? requestItemOutput.ExternalEmailAddress : '';
        }
    }
}