<br /><br />
<h5 class="text-danger">Reminder : Licenses assigned will be charged back to the entity of the user. Do not forget to remove the license when the user does not need it anymore. Feel free to contact <a href="mailto:ensemble.project@engie.com">ensemble.project&#64;engie.com</a> for more information.</h5>
<br /><br />
<h5 class="text-warning">Warning: after a downgrade from Microsoft 365 E5 to Microsoft 365 E3, Microsoft 365 F3, the user won't be able to use Skype Phone System, Audioconf and Power BI anymore. If the user needs one of these features, you will to have assign him the required license.</h5>
<h5 class="text-warning">Warning: you cannot assign a license to a Shared Mailbox with this product. If you need to raise quota, please use the "Enable Archive Online" product instead.</h5>
<br /><br />

<h4>Assign License:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputField>            
        </div>
        <div class="col-4">
            <label class="required" for="licensesInput">License SKU:</label>
            <select id="licensesInput" class="form-control" (change)="getNumberOfAvailableLicences($event.currentTarget.value)"
                [(ngModel)]="fileContent.LicenseSKU" name="licensesInput" required>
                <option *ngFor="let licenseSKU of assignableLicenses | orderby: 'name' : false : true" [value]="licenseSKU.code">{{licenseSKU.name}}</option>
            </select>
            <small *ngIf="availableLicensesCounter !== null" id="availableLicensesCounter"
                [class]="availableLicensesCounter < 1 ? 'form-text text-danger' : 'form-text text-primary'">{{ availableLicensesCounter < 1 ? 'Not available, provisioning in process' : availableLicensesCounter + ' license(s) available' }}</small>
            <small *ngIf="fileContent && fileContent.LicenseSKU == 'ENTERPRISEPACK'"
                class="text-warning">Warning : Please make sure that you subscribe to an EMS E3 or a CoreCal Bridge while assigning an Office 365 E3 to a user.</small>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>       
    </div>    
</form>
<div class="row form-group" style="padding-top:33px"> 
    <div class="col-2 offset-1">
        <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
    </div>
</div>
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-2">
                <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            </div>
            <div class="col-4">
                <b>License SKU:</b> {{ listedFileContent.LicenseSKU }}
            </div>
        </div>
    </div>
</div>