<h4>Create MEU:</h4>

<!-- ADD NEW FILECONTENT FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.GroupID)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.GroupID)">Please fill a valid Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.GroupID)">Based on Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <span style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.GroupID"></GroupIDInputField>          
        </div>
        <div class="col-4">
            <label for="brandInput">Brand:</label>
            <input type="text" id="brandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="brandInput">
        </div> 
        <div class="col-4">            
            <label for="mailAdressesInput">Mail Addresses:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'mailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'mailAdressesInput' + i}}">
                    <i id="mailAdressesAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'mailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'mailAdressesInput' + i}}">
                    <i id="{{'mailAdressesRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">  
        <div class="col-4">
            <label for="primarySmtpAddressInput">Primary SMTP:</label>
            <input type="text" id="primarySmtpAddressInput" class="form-control"
                [(ngModel)]="fileContent.PrimarySmtpAddress" name="primarySmtpAddressInput">
        </div>   
        <div class="col-4">
            <label for="primarySmtpDomainInput">Primary SMTP Domain:</label>
            <input type="text" id="primarySmtpDomainInput" class="form-control"
                [(ngModel)]="fileContent.PrimarySmtpDomain" name="primarySmtpDomainInput">
        </div>             
        <div class="col-4">
            <label for="initialsInput">Initials:</label>
            <input type="text" id="initialsInput" class="form-control"
                [(ngModel)]="fileContent.Initials" name="initialsInput">
        </div>
    </div> 
    <div class="row form-group"> 
        <div class="col-4">
            <label for="createMEUMergeInput">Merge:</label>
            <div id="createMEUMergeInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUMergeInputOpt1" name="createMEUMergeInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Merge" #createMEUMergeInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUMergeInputOpt2" name="createMEUMergeInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Merge" #createMEUMergeInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMEUExternalInput">External:</label>
            <div id="createMEUExternalInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUExternalInputOpt1" name="createMEUExternalInput" class="form-control" [value]="true" [(ngModel)]="fileContent.External" #createMEUExternalInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUExternalInputOpt2" name="createMEUExternalInput" class="form-control" [value]="false" [(ngModel)]="fileContent.External" #createMEUExternalInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMEUHiddenInput">Hidden:</label>
            <div id="createMEUHiddenInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUHiddenInputOpt1" name="createMEUHiddenInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Hidden" #createMEUMergeInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMEUHiddenInputOpt2" name="createMEUHiddenInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Hidden" #createMEUMergeInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div> 
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div> 
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b> - 
            {{ listedFileContent.PrimarySmtpAddress }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>
                <div class="col-4">
                    <b>Mail Addresses:</b>
                    <div *ngFor="let mailAddress of listedFileContent.MailAddresses">
                        {{ mailAddress }}
                    </div>
                </div>              
            </div>
            <div class="row"> 
                <div class="col-4">
                    <b>Primary SMTP:</b> {{ listedFileContent.PrimarySmtpAddress }}
                </div>
                <div class="col-4">
                    <b>Primary SMTP Domain:</b> {{ listedFileContent.PrimarySmtpDomain }}
                </div>
                <div class="col-4">
                    <b>Initials:</b> {{ listedFileContent.Initials }}
                </div>
            </div>
            <div class="row">  
                  
                <div class="col-4">
                    <b>Merge:</b> {{ booleanToStringConverterImport(listedFileContent.Merge, 'No', 'Yes') }}
                </div>   
                <div class="col-4">
                    <b>External:</b> {{ booleanToStringConverterImport(listedFileContent.External, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Hidden:</b> {{ booleanToStringConverterImport(listedFileContent.Hidden, 'No', 'Yes') }}
                </div>                    
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>