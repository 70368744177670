<h4>Public DNS:</h4>

<form *ngIf="requestStatus=='ReadyToProcess' || fileContentsList.DnsRecords.length == 0" #fileContentForm="ngForm">
    <div class='row form-group'>
        <div *ngIf="noSuccessRequests()" class="col-4">
            <label class="required" for="businessUnitDropdownList">Business Unit:</label>
            <select id="businessUnitDropdownList" class="form-control"
                (change)=getEntities($event.currentTarget.value)
                [(ngModel)]="fileContentsList.BusinessUnit" name="businessUnitDropdownList"
                required #businessUnitDropdownList="ngModel">
                <option *ngFor="let businessUnit of BUnits | orderby: 'Code' : false : true" [value]="businessUnit.Code" title="{{businessUnit.Name}}">{{businessUnit.Code}}</option>
            </select>
            <div *ngIf="businessUnitDropdownList.invalid && (businessUnitDropdownList.dirty || businessUnitDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="businessUnitDropdownList.errors.required">
                    Business Unit is required.
                </div>         
            </div>
        </div>
        <div *ngIf="noSuccessRequests()" class="col-4">
            <label class="required" for="entityDropdownList">Entity:</label>
            <select id="entityDropdownList" class="form-control"
                [(ngModel)]="fileContentsList.Entity" name="entityDropdownList"
                required #entityDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let entity of Entities | orderby: 'Name' : false : true" [value]="entity.OrgID" title="{{entity.OrgID}}">{{entity.Name}}</option>
            </select>
            <div *ngIf="entityDropdownList.invalid && (entityDropdownList.dirty || entityDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="entityDropdownList.errors.required">
                    Entity is required.
                </div>         
            </div>
        </div>
        <div *ngIf="noSuccessRequests()" class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContentsList.TicketID" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label class="required" for="recordTypeDropdownList">Record Type:</label>
            <select id="recordTypeDropdownList" class="form-control"
                (change)="clearFileContent()"
                [(ngModel)]="fileContent.RecordType" name="recordTypeDropdownList"
                required #recordTypeDropdownList="ngModel">
                <option *ngFor="let recordType of RecordTypes | orderby: '' : false : true" [value]="recordType">{{recordType}}</option>
            </select>
            <div *ngIf="recordTypeDropdownList.invalid && (recordTypeDropdownList.dirty || recordTypeDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="recordTypeDropdownList.errors.required">
                    RecordType is required.
                </div>         
            </div>
        </div>
    
        <div *ngIf="fileContent && fileContent.RecordType && fileContentsList.Entity" class="col-4">
            <label class="required" for="changeTypeDropdownList">Change Type:</label>
            <select id="changeTypeDropdownList" class="form-control"
                [(ngModel)]="fileContent.ChangeType" name="changeTypeDropdownList"
                required #changeTypeDropdownList="ngModel">
                <option *ngFor="let changeType of ChangeTypes | orderby: '' : false : true" [value]="changeType">{{changeType}}</option>
            </select>
            <div *ngIf="changeTypeDropdownList.invalid && (changeTypeDropdownList.dirty || changeTypeDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="changeTypeDropdownList.errors.required">
                    Change Type is required.
                </div>         
            </div>
        </div>
        <div *ngIf="fileContent && fileContent.RecordType && fileContentsList.Entity" class="col-4">
            <label class="required" for="environmentDropdownList">Environment:</label>
            <select id="environmentDropdownList" class="form-control"
                [(ngModel)]="fileContent.Environment" name="environmentDropdownList"
                required #environmentDropdownList="ngModel">
                <option *ngFor="let environment of Environments | orderby: '' : false : true" [value]="environment">{{environment}}</option>
            </select>
            <div *ngIf="environmentDropdownList.invalid && (environmentDropdownList.dirty || environmentDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="environmentDropdownList.errors.required">
                    Environment is required.
                </div>         
            </div>
        </div>
    </div>
    <div *ngIf="fileContent && fileContent.RecordType  && fileContentsList.Entity" class='row form-group'>
        <div *ngIf="fileContent && fileContent.RecordType=='TXT'" class="col-4">
            <label class="required" for="recordSubtypeDropdownList">Record Subtype:</label>
            <select id="recordSubtypeDropdownList" class="form-control"
                [(ngModel)]="fileContent.RecordSubtype" name="recordSubtypeDropdownList"
                required #recordSubtypeDropdownList="ngModel" (change)="checkRecordValue()">
                <option *ngFor="let recordSubtype of RecordSubtypes | orderby: '' : false : true" [value]="recordSubtype">{{recordSubtype}}</option>
            </select>
            <div *ngIf="recordSubtypeDropdownList.invalid && (recordSubtypeDropdownList.dirty || recordSubtypeDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="recordSubtypeDropdownList.errors.required">
                    Record Subtype is required.
                </div>         
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="dnsZoneDropdownList">DNS Zone:</label>
            <select id="dnsZoneDropdownList" class="form-control"
                (change)="getSubzones($event.currentTarget.value)"
                [(ngModel)]="fileContent.DnsZone" name="dnsZoneDropdownList"
                required #dnsZoneDropdownList="ngModel">
                <option *ngFor="let dnsZone of DnsZones | orderby: '' : false : true" [value]="dnsZone">{{dnsZone}}</option>
            </select>
            <div *ngIf="dnsZoneDropdownList.invalid && (dnsZoneDropdownList.dirty || dnsZoneDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="dnsZoneDropdownList.errors.required">
                    DNS Zone is required.
                </div>         
            </div>
        </div>
        <div *ngIf="DnsSubzones && DnsSubzones.length > 0" class="col-4">
            <label for="dnsSubzoneDropdownList">DNS Subzone:</label>
            <select id="dnsSubzoneDropdownList" class="form-control"
                (change)="getRecords(recordNameInput.invalid); checkZoneAccuracy(recordNameInput.invalid); checkRecordNameRedundancy(recordNameInput.invalid)"
                [(ngModel)]="fileContent.DnsSubzone" name="dnsSubzoneDropdownList"
                #dnsSubzoneDropdownList="ngModel" >
                <option selected="selected"></option>
                <option *ngFor="let dnsSubzone of DnsSubzones | orderby: '' : false : true" [value]="dnsSubzone">{{dnsSubzone}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="recordNameInput">Record Name:</label>
            <input type="text" id="recordNameInput" name="recordNameInput" class="form-control"
                [pattern]="RecordNamePattern"
                (change)="getRecords(recordNameInput.invalid); checkZoneAccuracy(recordNameInput.invalid); checkRecordNameRedundancy(recordNameInput.invalid)"
                (keyup)="getRecords(recordNameInput.invalid); checkZoneAccuracy(recordNameInput.invalid)"
                [(ngModel)]="fileContent.RecordName" #recordNameInput="ngModel">
            <div *ngIf="recordNameInput.invalid && (recordNameInput.dirty || recordNameInput.touched)" class="alert alert-danger">
                <div *ngIf="recordNameInput.errors.pattern">
                    Please enter a valid DNS record name.
                </div>          
            </div>
            <div *ngIf="fileContent && fileContent.RecordNameError && (recordNameInput.dirty || recordNameInput.touched)" class="alert alert-danger">
                {{fileContent.RecordNameError}}            
            </div>
        </div>
        <div *ngIf="fileContent && fileContent.RecordType=='MX'" class="col-4">
            <label class="required" for="recordValueInput">Record Value:</label>
            <textarea rows="1" id="recordValueInput" name="recordValueInput" class="form-control"
                [(ngModel)]="fileContent.RecordValue[0].Value" required #recordValueInput="ngModel" (keyup)="checkRecordValue()" (change)="checkRecordValue()"></textarea>
            <div *ngIf="recordValueInput.invalid && (recordValueInput.dirty || recordValueInput.touched)" class="alert alert-danger">
                <div *ngIf="recordValueInput.errors.required">
                    Record Value is required.
                </div>             
            </div>
            <div *ngIf="fileContent && fileContent.RecordValue[0].Warning && !fileContent.RecordValue[0].Error && (recordValueInput.dirty || recordValueInput.touched)"
                class="text-warning">{{fileContent.RecordValue[0].Warning}}
            </div>
            <div *ngIf="fileContent && fileContent.RecordValue[0].Error && (recordValueInput.dirty || recordValueInput.touched)" class="alert alert-danger">
                {{fileContent.RecordValue[0].Error}}            
            </div>
        </div>
        <div  *ngIf="fileContent && fileContent.RecordType=='TXT'" class="col-12">
            <label class="required" for="recordValueInput">Record Value:</label>
            <div *ngFor="let recordValue of fileContent.RecordValue; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                        <textarea rows="1" id="{{'recordValueInput' + i}}" class="form-control col-11"
                        [(ngModel)]="fileContent.RecordValue[i].Value" name="{{'recordValueInput' + i}}"
                        [ngClass]="{'has-danger-forced':(fileContent.RecordValue[i].Error && (recordValueInput.dirty || recordValueInput.touched || recordValueInput.value))}"
                        #recordValueInput="ngModel" (keyup)="checkRecordValue()" (change)="checkRecordValue()"></textarea>
                    <i *ngIf="isFirst" id="recordValueInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="position: absolute; right: 1%;" (click)="addInputRow(fileContent.RecordValue)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'recordValueInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="position: absolute; right: 1%;" (click)="removeInputRow(fileContent.RecordValue, i)">remove_circle_outline</i>
                    <div *ngIf="fileContent && fileContent.RecordValue[i].Warning && !fileContent.RecordValue[i].Error && fileContent.RecordValue[i].Value" class="text-warning">
                        <div class="break"></div>
                        {{fileContent.RecordValue[i].Warning}}
                    </div>
                    <div *ngIf="fileContent && fileContent.RecordValue[i].Error && (recordValueInput.dirty || recordValueInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        {{fileContent.RecordValue[i].Error}}            
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="fileContent && fileContent.RecordType=='MX'" class="col-4">
            <label class="required" for="priorityInput">Priority:</label>
            <input type="number" min="{{priorityRange.min}}" max="{{priorityRange.max}}" id="priorityInput" class="form-control"
                [(ngModel)]="fileContent.Priority" name="priorityInput" required #priorityInput="ngModel" 
                appMinValue={{priorityRange.min}} appMaxValue={{priorityRange.max}} (keyup)="checkMXRecords()" (change)="checkMXRecords()">
            <div *ngIf="priorityInput.invalid && (priorityInput.dirty || priorityInput.touched)" class="alert alert-danger">
                <div *ngIf="priorityInput.errors.required">
                    Priority is required.
                </div>
                <div *ngIf="priorityInput.errors.minValue">
                    Priority can't be lower than {{priorityRange.min}}
                </div>
                <div *ngIf="priorityInput.errors.maxValue">
                    Priority can't be higher than {{priorityRange.max}}
                </div>          
            </div>
        </div>
        <div class="col-4">
            <label for="ttlInput">TTL:</label>
            <input type="number" min="{{ttlRange.min}}" max="{{ttlRange.max}}" id="ttlInput" name="ttlInput" class="form-control"
                [(ngModel)]="fileContent.Ttl" #ttlInput="ngModel" appMinValue={{ttlRange.min}} appMaxValue={{ttlRange.max}}>
            <div *ngIf="ttlInput.invalid && (ttlInput.dirty || ttlInput.touched)" class="alert alert-danger">
                <div *ngIf="ttlInput.errors.minValue">
                    TTL can't be lower than {{ttlRange.min}}
                </div>
                <div *ngIf="ttlInput.errors.maxValue">
                    TTL can't be higher than {{ttlRange.max}}
                </div>          
            </div>
        </div>
        <div class="col-4">
            <label for="commentsInput">Comments:</label>
            <input type="text" id="commentsInput" name="commentsInput" class="form-control" [maxlength]="128"
                [(ngModel)]="fileContent.Comments" #commentsInput="ngModel">
        </div>
    </div>
    <div class="col-12">
        <h5 *ngIf="FullRecordPreview" class="text-primary" style="white-space: pre-line">{{FullRecordPreview}}</h5>
        <h5 class="text-warning" style="white-space: pre-line">{{fileContent.WarningMessage}}</h5>
        <h5 class="text-danger" style="white-space: pre-line">{{fileContent.ErrorMessage}}</h5>
    </div>
        
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="button" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || errorsInForm() || (fileContentsList.DnsRecords && fileContentsList.DnsRecords.length == 9)" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid)" [disabled]="!this.fileContentsList.Entity || ((fileContentForm.invalid || errorsInForm()) && (modificationIndex < 0 && (!fileContentsList.DnsRecords || (fileContentsList.DnsRecords && fileContentsList.DnsRecords.length == 0))))">Send Request</button>
        </div>
    </div>
</form>

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList.DnsRecords && fileContentsList.DnsRecords.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1">
            <button type="submit" class="btn btn-primary" (click)="clearPage();" style="margin-top:32px">Clear All</button>
        </div>
    </div>
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div *ngIf="fileContentsList.DnsRecords && fileContentsList.DnsRecords.length > 0" class="row">
    <div class="col-4">
        <b>Business Unit:</b> {{ fileContentsList.BusinessUnit }}
    </div>
    <div class="col-4">
        <b>Entity:</b> {{ fileContentsList.Entity }}
    </div>
    <div class="col-4">
        <b>Ticket ID:</b> {{ fileContentsList.TicketID }}
    </div>
</div>
<div class="row" *ngFor="let listedFileContent of fileContentsList.DnsRecords; let i = index">
    <a *ngIf="listedFileContent.Status!='Modifying'" class="col-12" [ngStyle]="{'word-wrap': 'break-word', 'color': listedFileContent.Status=='Failure'? 'red' : (listedFileContent.Status=='Success'? 'lightgreen' : (listedFileContent.Status=='Running'? 'grey': '#0275d8'))}" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.1em;">
            {{ listedFileContent.ChangeType }} - 
            {{ listedFileContent.RecordType }} -
            {{ listedFileContent.Environment }} -
            <span *ngIf="listedFileContent.RecordSubtype"> {{ listedFileContent.RecordSubtype }} - </span>
            <span *ngIf="listedFileContent.RecordName"> <b>{{ listedFileContent.RecordName }}</b>.</span>
            <span *ngIf="listedFileContent.DnsSubzone"> <b>{{ listedFileContent.DnsSubzone }}</b>.</span>
            <b>{{ listedFileContent.DnsZone }}</b> -
            <span *ngIf="listedFileContent.Priority"> {{ listedFileContent.Priority }} -</span>
            <span *ngFor="let recordValue of listedFileContent.RecordValue"> [{{ recordValue.Value }}] </span>
            <i *ngIf="listedFileContent.Status!='Failure'" [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i><i *ngIf="listedFileContent.Status=='Failure'" [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">expand_more</i>
            <i *ngIf="listedFileContent.Status!='Success' && listedFileContent.Status!='Running' && requestStatus!='Running' && requestStatus!='Success'" id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div *ngIf="listedFileContent.Status!='Modifying'" class="{{listedFileContent.Status=='Failure' ? 'collapse show ' : 'collapse '}}" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Change type:</b> {{ listedFileContent.ChangeType }}
                </div>
                <div class="col-4">
                    <b>Record Type:</b> {{ listedFileContent.RecordType }}
                </div>
                <div class="col-4">
                    <b>Environment:</b> {{ listedFileContent.Environment }}
                </div> 
                <div *ngIf="listedFileContent.RecordType == 'TXT'" class="col-4">
                    <b>Record Subtype:</b> {{ listedFileContent.RecordSubtype }}
                </div>
                <div class="col-4">
                    <b>Dns Zone:</b> {{ listedFileContent.DnsZone }}
                </div>
                <div class="col-4">
                    <b>Dns Subzone:</b> {{ listedFileContent.DnsSubzone }}
                </div>
                <div class="col-4">
                    <b>Record Name:</b> {{ listedFileContent.RecordName }}
                </div>
                <div *ngIf="listedFileContent.RecordType == 'MX'" class="col-4">
                    <b>Record Value:</b> {{ listedFileContent.RecordValue[0].Value }}
                </div>
                <div *ngIf="listedFileContent.RecordType == 'TXT'" style="word-wrap: break-word" class="col-12">
                    <b>Record Value:</b>
                    <div *ngFor="let recordValue of listedFileContent.RecordValue">
                        {{ recordValue.Value }}
                    </div>
                </div>
                <div *ngIf="listedFileContent.RecordType == 'MX'" class="col-4">
                    <b>Priority:</b> {{ listedFileContent.Priority }}
                </div>
                <div class="col-4">
                    <b>TTL:</b> {{ listedFileContent.Ttl }}
                </div>
                <div class="col-4">
                    <b>Comments:</b> {{ listedFileContent.Comments }}
                </div>
                <div  *ngIf="listedFileContent.Status == 'Failure'" class="col-12">
                    <div class="text-danger">
                        {{ listedFileContent.ErrorMessage }}
                    </div>
                </div> 
            </div>
            <div *ngIf="listedFileContent.Status!='Success' && requestStatus!='Running' && requestStatus!='Success'" class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
                <div *ngIf="listedFileContent.Status=='Failure'" class="col-2 offset-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="modifyFileContentFromList(i)">Modify</button>
                </div>
            </div>
        </div>
    </div>
</div>