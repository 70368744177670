<div class="row ">
    <div class="offset-10 col-1 form-group">
        <button class="btn btn-primary" (click)="showAll()">Show All</button>
    </div>
    <div class="col-1 form-group">
        <button class="btn btn-primary  " (click)="hideAll()">Hide All</button>
    </div>
</div>

<br />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER ACCOUNT INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr>
                <th style="text-align: center" colspan="7">USER ACCOUNT INFORMATION</th>
            </tr>
            <tr>
                <th>Group ID</th>
                <th>Account Type</th>
                <th>Trusted Domain</th>
                <th>Password Provider</th>
                <th>Okta Sync Flag</th>
                <th>Okta MFA</th>
                <th>Trigram</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ getUserInfoDetails.UserAccountInformations.TargetedUserGID }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.AccountType }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.IsTrustedDomain }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.PasswordProvider }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.OktaSyncFlag }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.OktaMFA }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.Trigram }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER ACCOUNT INFORMATION'))" />
<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER ACCOUNT INFORMATION'))" />

<!-- 

    Melinda Section

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MELINDA USER INFORMATION/DELETED USER INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="melindaInfoCollapsed=!melindaInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    MELINDA USER INFORMATION
                    <i *ngIf="melindaInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!melindaInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!melindaInfoCollapsed">
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.Status }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.ManagerialUnit }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.MailAddresses | orderby : ''">
                        <span>{{ mail }}</span><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MELINDA USER INFORMATION/DELETED USER INFORMATION'))" />
<br
    *ngIf="!melindaInfoCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MELINDA USER INFORMATION/DELETED USER INFORMATION')))" />

<!-- 

ADDITIONAL ACCOUNTS Section

-->
<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="additionalAccountsCollapsed=!additionalAccountsCollapsed">
                <th style="text-align: center" colspan="7">
                    ADDITIONAL ACCOUNTS INFORMATION
                    <i *ngIf="additionalAccountsCollapsed && ((getUserInfoDetails.AdditionalAccountsOkta.okta && getUserInfoDetails.AdditionalAccountsOkta.okta.length > 0) || (getUserInfoDetails.AdditionalAccountsOkta.oktaB2B && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B.length > 0) || (getUserInfoDetails.AdditionalAccountsAzure && getUserInfoDetails.AdditionalAccountsAzure.length > 0))" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!additionalAccountsCollapsed && ((getUserInfoDetails.AdditionalAccountsOkta.okta && getUserInfoDetails.AdditionalAccountsOkta.okta.length > 0) || (getUserInfoDetails.AdditionalAccountsOkta.oktaB2B && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B.length > 0) || (getUserInfoDetails.AdditionalAccountsAzure && getUserInfoDetails.AdditionalAccountsAzure.length > 0))" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!additionalAccountsCollapsed && ((getUserInfoDetails.AdditionalAccountsOkta.okta && getUserInfoDetails.AdditionalAccountsOkta.okta.length > 0) || (getUserInfoDetails.AdditionalAccountsOkta.oktaB2B && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B.length > 0) || (getUserInfoDetails.AdditionalAccountsAzure && getUserInfoDetails.AdditionalAccountsAzure.length > 0))">
                <th>GroupID</th>
                <th>Status</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>DisplayName</th>
                <th>Primary SMTP E-Mail Address</th>
                <th>Company</th>
            </tr>
        </thead>
        <tbody *ngIf="!additionalAccountsCollapsed || (getUserInfoDetails.AdditionalAccountsOkta.okta && getUserInfoDetails.AdditionalAccountsOkta.okta.length == 0 && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B.length == 0 && getUserInfoDetails.AdditionalAccountsAzure && getUserInfoDetails.AdditionalAccountsAzure.length == 0)">
            <tr *ngIf="getUserInfoDetails.AdditionalAccountsOkta.okta && getUserInfoDetails.AdditionalAccountsOkta.okta.length == 0 && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B && getUserInfoDetails.AdditionalAccountsOkta.oktaB2B.length == 0 && getUserInfoDetails.AdditionalAccountsAzure && getUserInfoDetails.AdditionalAccountsAzure.length == 0">
                <th>No additional accounts found</th>
            </tr>
            <tr *ngFor="let oktaAccount of getUserInfoDetails.AdditionalAccountsOkta.okta">
                <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTA)'}}</td>
                <td>{{ oktaAccount.status }}</td>
                <td>{{ oktaAccount.profile.firstName }}</td>
                <td>{{ oktaAccount.profile.lastName }}</td>
                <td>{{ oktaAccount.profile.displayName }}</td>
                <td>{{ oktaAccount.profile.email }}</td>
                <td>{{ oktaAccount.profile.organization }}</td>
            </tr>
            <tr *ngFor="let oktaAccount of getUserInfoDetails.AdditionalAccountsOkta.oktaB2B">
                <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTAB2B)'}}</td>
                <td>{{ oktaAccount.status }}</td>
                <td>{{ oktaAccount.profile.firstName }}</td>
                <td>{{ oktaAccount.profile.lastName }}</td>
                <td>{{ oktaAccount.profile.displayName }}</td>
                <td>{{ oktaAccount.profile.email }}</td>
                <td>{{ oktaAccount.profile.organization }}</td>
            </tr>
            <tr *ngFor="let aadAccount of getUserInfoDetails.AdditionalAccountsAzure">
                <td>{{ aadAccount.userPrincipalName.split('@')[0] + ' (Azure)'}}</td>
                <td>{{ 'Account Enabled: ' + aadAccount.accountEnabled }}</td>
                <td>{{ aadAccount.givenName }}</td>
                <td>{{ aadAccount.surname }}</td>
                <td>{{ aadAccount.displayName }}</td>
                <td>{{ aadAccount.mail }}</td>
                <td>{{ aadAccount.companyName }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION'))" />

<!--

    OKTA Section

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA USER INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="oktaInfoCollapsed=!oktaInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    OKTA USER INFORMATION
                    <i *ngIf="oktaInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!oktaInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!oktaInfoCollapsed">
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Status }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>GBU</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.GBU }}</td>
            </tr>
            <tr>
                <th>OrgID</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.OrgID }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.ManagerialUnit }}</td>
            </tr>
            <tr>
                <th>Local IT</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.LocalIT }}</td>
            </tr>
            <tr>
                <th>Cost Center</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.CostCenter }}</td>
            </tr>
            <tr>
                <th>Site</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Site }}</td>
            </tr>
            <tr>
                <th>Department</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Department }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.MailAddresses | orderby : ''">
                        <span>{{ mail }}</span><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA USER INFORMATION'))" />
<br
    *ngIf="!oktaInfoCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA USER INFORMATION')))" />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA MFA'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="oktaMFACollapsed=!oktaMFACollapsed">
                <th style="text-align: center" colspan="5">
                    OKTA MFA
                    <i *ngIf="oktaMFACollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!oktaMFACollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!oktaMFACollapsed">
                <th>Provider</th>
                <th>Type</th>
                <th>Status</th>
                <th>Device Type</th>
                <th>Creation Date</th>
            </tr>
        </thead>
        <tbody *ngIf="!oktaMFACollapsed">
            <!-- And to finish, the flyboat! Flyboat!?-->
            <tr *ngFor="let oktaMFA of getUserInfoDetails.OktaInformations.OktaMFAs">
                <td>{{ oktaMFA.Provider }}</td>
                <td>{{ oktaMFA.FactorType }}</td>
                <td>{{ oktaMFA.Status }}</td>
                <td>{{ oktaMFA.DeviceType }} <span *ngIf="oktaMFA.DeviceName">({{ oktaMFA.DeviceName }})</span></td>
                <td>{{ oktaMFA.CreationDate }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA MFA'))" />
<br
    *ngIf="!oktaMFACollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA MFA')))" />

<!-- 
    
    OFFICE365 Section

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OFFICE365 USER INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="office365InfoCollapsed=!office365InfoCollapsed">
                <th style="text-align: center" colspan="2">
                    OFFICE365 USER INFORMATION
                    <i *ngIf="office365InfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!office365InfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!office365InfoCollapsed">
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>
                    {{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Status ? 'Account Enabled: ' + getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Status : '' }}<br
                        *ngIf="getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Status && getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsLicensed" />
                    {{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsLicensed ? 'IsLicensed: ' + getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsLicensed : '' }}
                </td>
            </tr>
            <tr>
                <th>OnPremisesSyncEnabled</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.OnPremisesSyncEnabled }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Language</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.PreferredLanguage }}</td>
            </tr>
            <tr>
                <th>User Principal Name</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.UserPrincipalName }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.MailAddresses | orderby : ''">
                        <span>{{ mail }}</span><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OFFICE365 USER INFORMATION'))" />
<br
    *ngIf="!office365InfoCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OFFICE365 USER INFORMATION')))" />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER LICENSES'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="userLicensesCollapsed=!userLicensesCollapsed">
                <th style="text-align: center" colspan="2">
                    USER LICENSES
                    <i *ngIf="userLicensesCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!userLicensesCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!userLicensesCollapsed">
                <th>License Name</th>
                <th>Service Plan (Provisioning Status)</th>
            </tr>
        </thead>
        <tbody *ngIf="!userLicensesCollapsed">
            <tr *ngFor="let license of getUserInfoDetails.Office365Informations.Licenses">
                <th>{{ license.Name }}</th>
                <td>
                    <div *ngFor="let servicePlan of license.ServicePlans">
                        <span>{{ servicePlan.Name }} (<b
                                [ngStyle]="{'color': getStatusColorImport(servicePlan.Status)}">{{ servicePlan.Status }}</b>)</span><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER LICENSES'))" />
<br
    *ngIf="!userLicensesCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER LICENSES')))" />

<!--

AAD SECURITY GROUPS OWNED Section

-->
<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SECURITY GROUPS OWNED'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection"
                (click)="AADSecurityGroupOwnershipsCollapsed=!AADSecurityGroupOwnershipsCollapsed">
                <th style="text-align: center" colspan="2">
                    AAD SECURITY GROUPS OWNED
                    <i *ngIf="AADSecurityGroupOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!AADSecurityGroupOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody
            *ngIf="!AADSecurityGroupOwnershipsCollapsed && !Array.isArray(getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships}}</td>
            </tr>
        </tbody>
        <tbody
            *ngIf="!AADSecurityGroupOwnershipsCollapsed && Array.isArray(getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships)">
            <tr *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SECURITY GROUPS OWNED'))" />
<br
    *ngIf="!AADSecurityGroupOwnershipsCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SECURITY GROUPS OWNED')))" />

<!--

AAD SYNCHRONIZED SECURITY GROUPS OWNED Section

-->
<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection"
                (click)="AADSynchronizedSecurityGroupOwnershipsCollapsed=!AADSynchronizedSecurityGroupOwnershipsCollapsed">
                <th style="text-align: center" colspan="2">
                    AAD SYNCHRONIZED SECURITY GROUPS OWNED
                    <i *ngIf="AADSynchronizedSecurityGroupOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody
            *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed && !Array.isArray(getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships}}</td>
            </tr>
        </tbody>
        <tbody
            *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed && Array.isArray(getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships)">
            <tr
                *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED'))" />
<br
    *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')))" />

<!--

AAD APPLICATIONS OWNED

-->
<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="AADApplicationOwnershipsCollapsed=!AADApplicationOwnershipsCollapsed">
                <th style="text-align: center" colspan="2">
                    AAD APPLICATIONS OWNED
                    <i *ngIf="AADApplicationOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!AADApplicationOwnershipsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody
            *ngIf="!AADApplicationOwnershipsCollapsed && !Array.isArray(getUserInfoDetails.Office365Informations.AADApplicationOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADApplicationOwnerships}}</td>
            </tr>
        </tbody>
        <tbody
            *ngIf="!AADApplicationOwnershipsCollapsed && Array.isArray(getUserInfoDetails.Office365Informations.AADApplicationOwnerships)">
            <tr *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADApplicationOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED'))" />
<br
    *ngIf="!AADApplicationOwnershipsCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED')))" />

<!--

AAD GUEST USER SPONSORSHIPS

-->
<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="AADGuestUsersCollapsed=!AADGuestUsersCollapsed">
                <th style="text-align: center" colspan="2">
                    AAD GUEST USER SPONSORSHIPS
                    <i *ngIf="AADGuestUsersCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!AADGuestUsersCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody
            *ngIf="!AADGuestUsersCollapsed && !Array.isArray(getUserInfoDetails.Office365Informations.AADGuestUserSponsorships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADGuestUserSponsorships}}</td>
            </tr>
        </tbody>
        <tbody
            *ngIf="!AADGuestUsersCollapsed && Array.isArray(getUserInfoDetails.Office365Informations.AADGuestUserSponsorships)">
            <tr *ngFor="let mail of getUserInfoDetails.Office365Informations.AADGuestUserSponsorships">
                <td>{{ mail }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED'))" />
<br
    *ngIf="!AADApplicationOwnershipsCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED')))" />

<!--

    MOBILE INFORMATION

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER MOBILE DEVICE INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="mobileDeviceInformationsCollapsed=!mobileDeviceInformationsCollapsed">
                <th style="text-align: center" colspan="9">
                    USER MOBILE DEVICE INFORMATION
                    <i *ngIf="mobileDeviceInformationsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!mobileDeviceInformationsCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!mobileDeviceInformationsCollapsed">
                <th>ID</th>
                <th>Type</th>
                <th>Model</th>
                <th>OS</th>
                <th>User Agent</th>
                <th>Access State</th>
                <th>Access State Reason</th>
                <th>First Sync Time</th>
                <th>Last Success Sync</th>
            </tr>
        </thead>
        <tbody *ngIf="!mobileDeviceInformationsCollapsed">
            <tr *ngFor="let mobileDevice of getUserInfoDetails.ExchangeOnlineInformations.MobileDevicesInformations">
                <td>{{ mobileDevice.DeviceID }}</td>
                <td>{{ mobileDevice.DeviceType }}</td>
                <td>{{ mobileDevice.DeviceModel }}</td>
                <td>{{ mobileDevice.DeviceOS }}</td>
                <td>{{ mobileDevice.DeviceUserAgent }}</td>
                <td>{{ mobileDevice.DeviceAccessState }}</td>
                <td>{{ mobileDevice.DeviceAccessStateReason }}</td>
                <td>{{ mobileDevice.FirstSyncTime }}</td>
                <td>{{ mobileDevice.LastSuccessSync }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER MOBILE DEVICE INFORMATION'))" />
<br
    *ngIf="!mobileDeviceInformationsCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER MOBILE DEVICE INFORMATION')))" />

<!-- 
    
    EXCHANGE ONLINE    

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="exchangeOnlineInfoCollapsed=!exchangeOnlineInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    EXCHANGE ONLINE USER INFORMATION
                    <i *ngIf="exchangeOnlineInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!exchangeOnlineInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!exchangeOnlineInfoCollapsed">
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.Status }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.PrimarySMTPAddress }}
                </td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.ManagerialUnit }}
                </td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.MailAddresses | orderby : ''">
                        <span>{{ mail }}</span><br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER INFORMATION'))" />
<br
    *ngIf="!exchangeOnlineInfoCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER INFORMATION')))" />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER CONFIGURATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="exchangeOnlineUserConfCollapsed=!exchangeOnlineUserConfCollapsed">
                <th style="text-align: center" colspan="2">
                    EXCHANGE ONLINE USER CONFIGURATION
                    <i *ngIf="exchangeOnlineUserConfCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!exchangeOnlineUserConfCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!exchangeOnlineUserConfCollapsed">
            <tr>
                <th>AccountDisabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsAccountDisabled }}</td>
            </tr>
            <tr>
                <th>ArchiveQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ArchiveQuota }}</td>
            </tr>
            <tr>
                <th>ArchiveStatus</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ArchiveStatus }}</td>
            </tr>
            <tr>
                <th>AuditEnabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsAuditEnabled }}</td>
            </tr>
            <tr>
                <th>CustomAttribute9</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.CustomAttribute9 }}</td>
            </tr>
            <tr>
                <th>IsInactiveMailbox</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsInactiveMailbox }}</td>
            </tr>
            <tr>
                <th>IsMailboxEnabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsMailboxEnabled }}</td>
            </tr>
            <tr>
                <th>IsSoftDeletedByDisable</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsSoftDeletedByDisable }}</td>
            </tr>
            <tr>
                <th>IsSoftDeletedByRemove</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsSoftDeletedByRemove }}</td>
            </tr>
            <tr>
                <th>Languages</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.Languages }}</td>
            </tr>
            <tr>
                <th>ProhibitSendQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProhibitSendQuota }}</td>
            </tr>
            <tr>
                <th>ProhibitSendReceiveQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProhibitSendReceiveQuota }}</td>
            </tr>
            <tr>
                <th>ProtocolSettings</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProtocolSettings }}</td>
            </tr>
            <tr>
                <th>RecipientLimits</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientLimits }}</td>
            </tr>
            <tr>
                <th>RecipientType</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientType }}</td>
            </tr>
            <tr>
                <th>RecipientTypeDetails</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientTypeDetails }}</td>
            </tr>
            <tr>
                <th>RemoteRecipientType</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RemoteRecipientType }}</td>
            </tr>
            <tr>
                <th>UsageLocation</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.UsageLocation }}</td>
            </tr>
            <tr>
                <th>Authentication Policy</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.AuthenticationPolicy }}</td>
            </tr>
            <tr>
                <th>LastUserActionTime</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.LastUserActionTime }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER CONFIGURATION'))" />
<br
    *ngIf="!exchangeOnlineUserConfCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER CONFIGURATION')))" />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('DISTRIBUTION LISTS OWNED'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="distributionListOwnerCollapsed=!distributionListOwnerCollapsed">
                <th style="text-align: center;" colspan="3">
                    DISTRIBUTION LISTS OWNED
                    <i *ngIf="distributionListOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership.length > 0"
                        style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!distributionListOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership.length > 0"
                        style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr
                *ngIf="!distributionListOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership.length > 0">
                <th>Alias</th>
                <th>Display Name</th>
                <th>RecipientTypeDetails</th>
            </tr>
        </thead>
        <tbody
            *ngIf="!distributionListOwnerCollapsed || getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership.length == 0">
            <tr *ngIf="getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership.length == 0">
                <th>No Distribution Lists owned</th>
            </tr>
            <tr
                *ngFor="let ownedDistributionList of getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership">
                <td>{{ ownedDistributionList.Alias }}</td>
                <td>{{ ownedDistributionList.DisplayName }}</td>
                <td>{{ ownedDistributionList.Type }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('DISTRIBUTION LISTS OWNED'))" />
<br
    *ngIf="!distributionListOwnerCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('DISTRIBUTION LISTS OWNED')))" />

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MAILBOXES OWNED'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="mailboxOwnerCollapsed=!mailboxOwnerCollapsed">
                <th style="text-align: center;" colspan="3">
                    MAILBOXES OWNED
                    <i *ngIf="mailboxOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership.length > 0"
                        style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!mailboxOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership.length > 0"
                        style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr
                *ngIf="!mailboxOwnerCollapsed && getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership.length > 0">
                <th>Alias</th>
                <th>Display Name</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody
            *ngIf="!mailboxOwnerCollapsed || getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership.length == 0">
            <tr *ngIf="getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership.length == 0">
                <th>No mailboxes owned</th>
            </tr>
            <tr *ngFor="let ownedMailbox of getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership">
                <td>{{ ownedMailbox.Alias }}</td>
                <td>{{ ownedMailbox.DisplayName }}</td>
                <td>{{ ownedMailbox.Type }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MAILBOXES OWNED'))" />
<br
    *ngIf="!mailboxOwnerCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MAILBOXES OWNED')))" />

<!-- 
    
    TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO)

-->

<div *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION'))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="skypeForBusinessInfoCollapsed=!skypeForBusinessInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION
                    <i *ngIf="skypeForBusinessInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!skypeForBusinessInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!skypeForBusinessInfoCollapsed">
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.SkypeForBusiness.IsProvisioned }}
                </td>
            </tr>
            <tr>
                <th>Sip Address</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.SipAddress }}</td>
            </tr>
            <tr>
                <th>Skype for Business Online License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.SkypeForBusinesOnlineLicense }}</td>
            </tr>
            <tr>
                <th>Teams License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsLicense }}</td>
            </tr>
            <tr>
                <th>Phone System License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.PhoneSystemLicense }}</td>
            </tr>
            <tr>
                <th>On Premise Line URI</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OnPremLineURI }}</td>
            </tr>
            <tr>
                <th>Other IP Phone</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OtherIPPhone }}</td>
            </tr>
            <tr>
                <th>Teams App Permission Policy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsAppPermissionPolicy }}</td>
            </tr>
            <tr>
                <th>Teams App Setup Policy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsAppSetupPolicy }}</td>
            </tr>
            <tr>
                <th>Teams/SFBO Enterprise Voice Enabled</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnterpriseVoiceEnabled }}</td>
            </tr>
            <tr>
                <th>Dial Plan</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.DialPlan }}</td>
            </tr>
            <tr>
                <th>Tenant Dial Plan</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TenantDialPlan }}</td>
            </tr>
            <tr>
                <th>International Calls Allowed</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.InternationalCallsAllowed }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteName !== ''">
                <th>SFBO - CCE PSTN Site Name</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteName }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteFqdn !== ''">
                <th>SFBO - CCE PSTN Site FQDN</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteFqdn }}</td>
            </tr>
            <tr>
                <th>Teams - OnlineVoiceRoutingPolicy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OnlineVoiceRoutingPolicy }}</td>
            </tr>
            <tr>
                <th>Teams - CallingPolicy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsCallingPolicy }}</td>
            </tr>
            <tr>
                <th>Teams - TeamsUpgradeEffectiveMode</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsUpgradeEffectiveMode }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MeetingRoomEnabled !== ''">
                <th>Teams/SFBO MeetingRoom Enabled</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MeetingRoomEnabled }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br
    *ngIf="getUserInfoDetails.SkypeForBusinessInformations.Identity && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION')))" />
<br
    *ngIf="getUserInfoDetails.SkypeForBusinessInformations.Identity && (!skypeForBusinessInfoCollapsed && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION'))))" />

<div *ngIf="getUserInfoDetails.SkypeForBusinessInformations.Identity && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE CONFERENCING POLICY')))"
    class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="skypeConfPolicyCollapsed=!skypeConfPolicyCollapsed">
                <th style="text-align: center" colspan="2">
                    TEAMS/SKYPE CONFERENCING POLICY
                    <i *ngIf="skypeConfPolicyCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!skypeConfPolicyCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!skypeConfPolicyCollapsed">
            <tr>
                <th>AllowIPAudio</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowIPAudio }}</td>
            </tr>
            <tr>
                <th>AllowIPVideo</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowIPVideo }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.AudioBitRate">
                <th>AudioBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AudioBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.EnableP2PVideo">
                <th>EnableP2PVideo</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnableP2PVideo }}</td>
            </tr>
            <tr>
                <th>Identity</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.Identity }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MaxVideoConferenceResolution">
                <th>MaxVideoConferenceResolution</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MaxVideoConferenceResolution }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.TotalReceiveVideoBitRate">
                <th>TotalReceiveVideoBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TotalReceiveVideoBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.VideoBitRate">
                <th>VideoBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.VideoBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MediaBitRateKb">
                <th>MediaBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MediaBitRateKb }}</td>
            </tr>
            <!--
                <tr>
                    <th>AllowAnonymousUsersToDialOut</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowAnonymousUsersToDialOut }}</td>                
                </tr>
                <tr>
                    <th>AllowExternalUsersToRecordMeeting</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowExternalUsersToRecordMeeting }}</td>                
                </tr>
                <tr>
                    <th>AllowFederatedParticipantJoinAsSameEnterprise</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowFederatedParticipantJoinAsSameEnterprise }}</td>                
                </tr>
                <tr>
                    <th>AllowLargeMeetings</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowLargeMeetings }}</td>                
                </tr>
                <tr>
                    <th>AllowNonEnterpriseVoiceUsersToDialOut</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowNonEnterpriseVoiceUsersToDialOut }}</td>                
                </tr>
                <tr>
                    <th>Anchor</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.Anchor }}</td>                
                </tr>
                <tr>
                    <th>ApplicationSharingMode</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.ApplicationSharingMode }}</td>                
                </tr>
                <tr>
                    <th>AppSharingBitRate</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AppSharingBitRate }} kb/s</td>                
                </tr>
                <tr>
                    <th>Description</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.Description }}</td>                
                </tr>
                <tr>
                    <th>DisablePowerPointAnnotations</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.DisablePowerPointAnnotations }}</td>                
                </tr>
                <tr>
                    <th>EnableAppDesktopSharing</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnableAppDesktopSharing }}</td>                
                </tr>
                <tr>
                    <th>EnableDialInConferencing</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnableDialInConferencing }}</td>                
                </tr>
                <tr>
                    <th>EnableOnlineMeetingPromptForLyncResources</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnableOnlineMeetingPromptForLyncResources }}</td>                
                </tr>
                <tr>
                    <th>FileTransferBitRate</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.FileTransferBitRate }} kb/s</td>                
                </tr>
                <tr>
                    <th>Key</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.Key }}</td>                
                </tr>
                <tr>
                    <th>MaxMeetingSize</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MaxMeetingSize }}</td>                
                </tr>            
                <tr>
                    <th>PSComputerName</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.PSComputerName }}</td>                
                </tr>
                <tr>
                    <th>RunspaceId</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.RunspaceId }}</td>                
                </tr>
                <tr>
                    <th>ScopeClass</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.ScopeClass }}</td>                
                </tr>
                <tr>
                    <th>TypedIdentity</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TypedIdentity }}</td>                
                </tr>
                <tr>
                    <th>XsAnyAttributes</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.XsAnyAttributes }}</td>                
                </tr>
                <tr>
                    <th>XsAnyElements</th>
                    <td>{{ getUserInfoDetails.SkypeForBusinessInformations.XsAnyElements }}</td>                
                </tr>
            -->
        </tbody>
    </table>
</div>

<!-- Hidden Section For PDF Export Without order by -->

<div id="hiddenTable" style="display: none">
    <table id="table0" title="Get-User-Info Details {{getUserInfoDetails.UserAccountInformations.TargetedUserGID}}">
    </table>
    <table id="table1" 
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER ACCOUNT INFORMATION'))"
        title="USER ACCOUNT INFORMATION">
        <thead>
            <tr>
                <th>Group ID</th>
                <th>Account Type</th>
                <th>Trusted Domain</th>
                <th>Password Provider</th>
                <th>Okta Sync Flag</th>
                <th>Okta MFA</th>
                <th>Trigram</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ getUserInfoDetails.UserAccountInformations.TargetedUserGID }}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.AccountType}}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.IsTrustedDomain}}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.PasswordProvider}}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.OktaSyncFlag}}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.OktaMFA}}</td>
                <td>{{ getUserInfoDetails.UserAccountInformations.Trigram}}</td>
            </tr>
        </tbody>
    </table>
    <!-- 

    MELINDA USER INFORMATION 

-->

    <table id="table2"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MELINDA USER INFORMATION/DELETED USER INFORMATION'))"
        title="">
        <thead>
            <tr>
                <th>MELINDA USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.Status }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.ManagerialUnit }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Melinda.MailAddresses | orderby : ''">
                        {{ mail + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- 

    ADDITIONAL ACCOUNTS Section

    -->
    <table id="table3" *ngIf="!getUserInfoDetails.InformationToShow || getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" title="ADDITIONAL ACCOUNTS INFORMATION">
        <thead>
            <tr>
                <th>GroupID</th>
                <th>Status</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>DisplayName</th>
                <th>Primary SMTP E-Mail Address</th>
                <th>Company</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let oktaAccount of getUserInfoDetails.AdditionalAccountsOkta.okta">
                <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTA)'}}</td>
                <td>{{ oktaAccount.status }}</td>
                <td>{{ oktaAccount.profile.firstName }}</td>
                <td>{{ oktaAccount.profile.lastName }}</td>
                <td>{{ oktaAccount.profile.displayName }}</td>
                <td>{{ oktaAccount.profile.email }}</td>
                <td>{{ oktaAccount.profile.organization }}</td>
            </tr>
            <tr *ngFor="let oktaAccount of getUserInfoDetails.AdditionalAccountsOkta.oktaB2B">
                <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTAB2B)'}}</td>
                <td>{{ oktaAccount.status }}</td>
                <td>{{ oktaAccount.profile.firstName }}</td>
                <td>{{ oktaAccount.profile.lastName }}</td>
                <td>{{ oktaAccount.profile.displayName }}</td>
                <td>{{ oktaAccount.profile.email }}</td>
                <td>{{ oktaAccount.profile.organization }}</td>
            </tr>
            <tr *ngFor="let aadAccount of getUserInfoDetails.AdditionalAccountsAzure">
                <td>{{ aadAccount.userPrincipalName.split('@')[0] + ' (Azure)'}}</td>
                <td>{{ 'Account Enabled: ' + aadAccount.accountEnabled }}</td>
                <td>{{ aadAccount.givenName }}</td>
                <td>{{ aadAccount.surname }}</td>
                <td>{{ aadAccount.displayName }}</td>
                <td>{{ aadAccount.mail }}</td>
                <td>{{ aadAccount.companyName }}</td>
            </tr>
        </tbody>
    </table>

    <!--

    OKTA USER INFORMATION 

-->

    <table id="table4"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA USER INFORMATION'))"
        title="">
        <thead>
            <tr>
                <th>OKTA USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Status }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>OrgID</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.OrgID }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.ManagerialUnit }}</td>
            </tr>
            <tr>
                <th>Local IT</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.LocalIT }}</td>
            </tr>
            <tr>
                <th>Cost Center</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.CostCenter }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Okta.MailAddresses | orderby : ''">
                        {{ mail + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>
    <!--

    OKTA MFA 

-->

    <table id="table5"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OKTA MFA'))"
        title="OKTA MFA">
        <thead class="thead-default">
            <tr>
                <th>Provider</th>
                <th>Type</th>
                <th>Status</th>
                <th>Device Type</th>
                <th>Creation Date</th>
            </tr>
        </thead>
        <tbody>
            <!-- And to finish, the flyboat! Flyboat!?-->
            <tr *ngFor="let oktaMFA of getUserInfoDetails.OktaInformations.OktaMFAs">
                <td>{{ oktaMFA.Provider }}</td>
                <td>{{ oktaMFA.FactorType }}</td>
                <td>{{ oktaMFA.Status }}</td>
                <td>{{ oktaMFA.DeviceType }} <span *ngIf="oktaMFA.DeviceName">({{ oktaMFA.DeviceName }})</span></td>
                <td>{{ oktaMFA.CreationDate }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    OFFICE365 USER INFORMATION

-->

    <table id="table6"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('OFFICE365 USER INFORMATION'))"
        title="">
        <thead>
            <tr>
                <th>OFFICE365 USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>
                    {{ (getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Status ? 'Account Enabled: ' + getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Status : '') + '\r\n'}}
                    {{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsLicensed ? 'IsLicensed: ' + getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.IsLicensed : '' }}
                </td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.FirstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.LastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.PrimarySMTPAddress }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.ManagerialUnit }}</td>
            </tr>
            <tr>
                <th>Language</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.PreferredLanguage }}</td>
            </tr>
            <tr>
                <th>User Principal Name</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.UserPrincipalName }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.Office365.MailAddresses | orderby : ''">
                        {{ mail + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    USER LICENSES

-->
    <table id="table7"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER LICENSES'))"
        title="USER LICENSES">
        <thead class="thead-default">
            <tr>
                <th>License Name</th>
                <th>Service Plan (Provisioning Status)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let license of getUserInfoDetails.Office365Informations.Licenses">
                <td>{{ license.Name }}</td>
                <td>
                    <div *ngFor="let servicePlan of license.ServicePlans">
                        <span>{{ servicePlan.Name }} ({{ servicePlan.Status + ")\r\n" }}</span></div>
                </td>
            </tr>
        </tbody>
    </table>

    <!--

AAD SECURITY GROUPS OWNED Section

-->
    <table id="table8"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SECURITY GROUPS OWNED'))"
        title="">
        <thead class="thead-default">
            <tr>
                <th>AAD SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody *ngIf="!Array.isArray(getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="Array.isArray(getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships)">
            <tr *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADSecurityGroupOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
    <!--

AAD SYNCHRONIZED SECURITY GROUPS OWNED Section

-->
    <table id="table9"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED'))"
        title="">
        <thead class="thead-default">
            <tr>
                <th>AAD SYNCHRONIZED SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody *ngIf="!Array.isArray(getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="Array.isArray(getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships)">
            <tr
                *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADSynchronizedSecurityGroupOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
    <!--

AAD APPLICATIONS OWNED

-->
    <table id="table10"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD APPLICATIONS OWNED'))"
        title="">
        <thead class="thead-default">
            <tr>
                <th>AAD APPLICATIONS OWNED</th>
            </tr>
        </thead>
        <tbody *ngIf="!Array.isArray(getUserInfoDetails.Office365Informations.AADApplicationOwnerships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADApplicationOwnerships }}</td>
            </tr>
        </tbody>
        <tbody *ngIf="Array.isArray(getUserInfoDetails.Office365Informations.AADApplicationOwnerships)">
            <tr *ngFor="let displayName of getUserInfoDetails.Office365Informations.AADApplicationOwnerships">
                <td>{{ displayName }}</td>
            </tr>
        </tbody>
    </table>
    <!--

AAD GUEST USER SPONSORSHIPS

-->
    <table id="table11"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS'))"
        title="">
        <thead class="thead-default">
            <tr>
                <th>AAD GUEST USER SPONSORSHIPS</th>
            </tr>
        </thead>
        <tbody *ngIf="!Array.isArray(getUserInfoDetails.Office365Informations.AADGuestUserSponsorships)">
            <tr>
                <td>{{ getUserInfoDetails.Office365Informations.AADGuestUserSponsorships}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="Array.isArray(getUserInfoDetails.Office365Informations.AADGuestUserSponsorships)">
            <tr *ngFor="let mail of getUserInfoDetails.Office365Informations.AADGuestUserSponsorships">
                <td>{{ mail }}</td>
            </tr>
        </tbody>
    </table>
    <!--

    USER MOBILE DEVICE INFORMATION

-->

    <table id="table12"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('USER MOBILE DEVICE INFORMATION'))"
        title="USER MOBILE DEVICE INFORMATION">
        <thead>
            <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Model</th>
                <th>OS</th>
                <th>User Agent</th>
                <th>{{"Access\r\nState"}}</th>
                <th>{{"Access State\r\nReason"}}</th>
                <th>{{"First Sync\r\nTime"}}</th>
                <th>{{"Last Success\r\nSync"}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mobileDevice of getUserInfoDetails.ExchangeOnlineInformations.MobileDevicesInformations">
                <td>{{ mobileDevice.DeviceID }}</td>
                <td>{{ mobileDevice.DeviceType }}</td>
                <td>{{ mobileDevice.DeviceModel }}</td>
                <td>{{ mobileDevice.DeviceOS }}</td>
                <td>{{ mobileDevice.DeviceUserAgent }}</td>
                <td>{{ mobileDevice.DeviceAccessState }}</td>
                <td>{{ mobileDevice.DeviceAccessStateReason }}</td>
                <td>{{ mobileDevice.FirstSyncTime }}</td>
                <td>{{ mobileDevice.LastSuccessSync }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    EXCHANGE ONLINE USER INFORMATION

-->

    <table id="table13"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER INFORMATION'))"
        title="">
        <thead>
            <tr>
                <th>EXCHANGE ONLINE USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.IsProvisioned }}</td>
            </tr>
            <tr>
                <th>Status</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.Status }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.DisplayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.PrimarySMTPAddress }}
                </td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.Company }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.BusinessUnit }}</td>
            </tr>
            <tr>
                <th>Managerial Unit</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.ManagerialUnit }}
                </td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div
                        *ngFor="let mail of getUserInfoDetails.UserAccountDetailedInformationsByServices.ExchangeOnline.MailAddresses | orderby : ''">
                        {{ mail + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    EXCHANGE ONLINE USER CONFIGURATION

-->

    <table id="table14"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('EXCHANGE ONLINE USER CONFIGURATION'))"
        title="">
        <thead>
            <tr>
                <th>EXCHANGE ONLINE USER CONFIGURATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>AccountDisabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsAccountDisabled }}</td>
            </tr>
            <tr>
                <th>ArchiveQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ArchiveQuota }}</td>
            </tr>
            <tr>
                <th>ArchiveStatus</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ArchiveStatus }}</td>
            </tr>
            <tr>
                <th>AuditEnabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsAuditEnabled }}</td>
            </tr>
            <tr>
                <th>CustomAttribute9</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.CustomAttribute9 }}</td>
            </tr>
            <tr>
                <th>IsInactiveMailbox</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsInactiveMailbox }}</td>
            </tr>
            <tr>
                <th>IsMailboxEnabled</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsMailboxEnabled }}</td>
            </tr>
            <tr>
                <th>IsSoftDeletedByDisable</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsSoftDeletedByDisable }}</td>
            </tr>
            <tr>
                <th>IsSoftDeletedByRemove</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.IsSoftDeletedByRemove }}</td>
            </tr>
            <tr>
                <th>Languages</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.Languages }}</td>
            </tr>
            <tr>
                <th>ProhibitSendQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProhibitSendQuota }}</td>
            </tr>
            <tr>
                <th>ProhibitSendReceiveQuota</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProhibitSendReceiveQuota }}</td>
            </tr>
            <tr>
                <th>ProtocolSettings</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.ProtocolSettings }}</td>
            </tr>
            <tr>
                <th>RecipientLimits</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientLimits }}</td>
            </tr>
            <tr>
                <th>RecipientType</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientType }}</td>
            </tr>
            <tr>
                <th>RecipientTypeDetails</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RecipientTypeDetails }}</td>
            </tr>
            <tr>
                <th>RemoteRecipientType</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.RemoteRecipientType }}</td>
            </tr>
            <tr>
                <th>UsageLocation</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.UsageLocation }}</td>
            </tr>
            <tr>
                <th>Authentication Policy</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.AuthenticationPolicy }}</td>
            </tr>
            <tr>
                <th>LastUserActionTime</th>
                <td>{{ getUserInfoDetails.ExchangeOnlineInformations.LastUserActionTime }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    DISTRIBUTION LISTS OWNED

-->

    <table id="table15"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('DISTRIBUTION LISTS OWNED'))"
        title="DISTRIBUTION LISTS OWNED">
        <thead>
            <tr>
                <th>Alias</th>
                <th>Display Name</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dlOwned of getUserInfoDetails.ExchangeOnlineInformations.DistributionListOwnership">
                <td>{{ dlOwned.Alias }}</td>
                <td>{{ dlOwned.DisplayName }}</td>
                <td>{{ dlOwned.Type }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    MAILBOXES OWNED

-->

    <table id="table16"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('MAILBOXES OWNED'))"
        title="MAILBOXES OWNED">
        <thead>
            <tr>
                <th>Alias</th>
                <th>Display Name</th>
                <th>RecipientTypeDetails</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mailboxOwned of getUserInfoDetails.ExchangeOnlineInformations.MailboxOwnership">
                <td>{{ mailboxOwned.Alias }}</td>
                <td>{{ mailboxOwned.DisplayName }}</td>
                <td>{{ mailboxOwned.Type }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION

-->

    <table id="table17"
        *ngIf="!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION'))"
        title="">
        <thead>
            <tr>
                <th>TEAMS/SKYPE FOR BUSINESS ONLINE (SFBO) GENERAL INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Provisioned</th>
                <td>{{ getUserInfoDetails.UserAccountDetailedInformationsByServices.SkypeForBusiness.IsProvisioned }}
                </td>
            </tr>
            <tr>
                <th>Sip Address</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.SipAddress }}</td>
            </tr>
            <tr>
                <th>Skype for Business Online License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.SkypeForBusinesOnlineLicense }}</td>
            </tr>
            <tr>
                <th>Teams License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsLicense }}</td>
            </tr>
            <tr>
                <th>Phone System License</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.PhoneSystemLicense }}</td>
            </tr>
            <tr>
                <th>On Premise Line URI</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OnPremLineURI }}</td>
            </tr>
            <tr>
                <th>Other IP Phone</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OtherIPPhone }}</td>
            </tr>
            <tr>
                <th>Teams App Permission Policy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsAppPermissionPolicy }}</td>
            </tr>
            <tr>
                <th>Teams App Setup Policy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsAppSetupPolicy }}</td>
            </tr>
            <tr>
                <th>Teams/SFBO Enterprise Voice Enabled</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnterpriseVoiceEnabled }}</td>
            </tr>
            <tr>
                <th>Dial Plan</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.DialPlan }}</td>
            </tr>
            <tr>
                <th>Tenant Dial Plan</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TenantDialPlan }}</td>
            </tr>
            <tr>
                <th>International Calls Allowed</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.InternationalCallsAllowed }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteName !== ''">
                <th>SFBO - CCE PSTN Site Name</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteName }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteFqdn !== ''">
                <th>SFBO - CCE PSTN Site FQDN</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.HybridPSTNSiteFqdn }}</td>
            </tr>
            <tr>
                <th>Teams - OnlineVoiceRoutingPolicy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.OnlineVoiceRoutingPolicy }}</td>
            </tr>
            <tr>
                <th>Teams - CallingPolicy</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsCallingPolicy }}</td>
            </tr>
            <tr>
                <th>Teams - TeamsUpgradeEffectiveMode</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TeamsUpgradeEffectiveMode }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MeetingRoomEnabled !== ''">
                <th>Teams/SFBO MeetingRoom Enabled</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MeetingRoomEnabled }}</td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    TEAMS/SKYPE CONFERENCING POLICY

-->

    <table id="table18"
        *ngIf="getUserInfoDetails.SkypeForBusinessInformations.Identity && (!getUserInfoDetails.InformationToShow || (getUserInfoDetails.InformationToShow && getUserInfoDetails.InformationToShow.includes('TEAMS/SKYPE CONFERENCING POLICY')))"
        title="">
        <thead class="thead-default">
            <tr>
                <th>TEAMS/SKYPE CONFERENCING POLICY</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>AllowIPAudio</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowIPAudio }}</td>
            </tr>
            <tr>
                <th>AllowIPVideo</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AllowIPVideo }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.AudioBitRate">
                <th>AudioBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.AudioBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.EnableP2PVideo">
                <th>EnableP2PVideo</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.EnableP2PVideo }}</td>
            </tr>
            <tr>
                <th>Identity</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.Identity }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MaxVideoConferenceResolution">
                <th>MaxVideoConferenceResolution</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MaxVideoConferenceResolution }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.TotalReceiveVideoBitRate">
                <th>TotalReceiveVideoBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.TotalReceiveVideoBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.VideoBitRate">
                <th>VideoBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.VideoBitRate }}</td>
            </tr>
            <tr *ngIf="getUserInfoDetails.SkypeForBusinessInformations.MediaBitRateKb">
                <th>MediaBitRate</th>
                <td>{{ getUserInfoDetails.SkypeForBusinessInformations.MediaBitRateKb }}</td>
            </tr>
        </tbody>
    </table>