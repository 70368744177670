<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let child of items">
      <!-- Handle branch node menu items -->
      <span *ngIf="child.children && child.children.length > 0">
        <button *ngIf="!child.disabled" mat-menu-item [matMenuTriggerFor]="menu.childMenu">
          <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
          <span>{{child.displayName}}</span>
        </button>
        <app-menu-item #menu [items]="child.children"></app-menu-item>
      </span>
      <!-- Handle leaf node menu items -->
      <span *ngIf="(!child.children || child.children.length === 0) && child.href">
        <a class="btn btn-primary" mat-menu-item [href]="child.href" target="_blank">
            {{child.displayName}}
        </a>
    </span>
      <span *ngIf="(!child.children || child.children.length === 0) && child.route">
        <button *ngIf="!child.disabled" mat-menu-item [routerLink]="child.route">
          <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
          <span>{{child.displayName}}</span>
        </button>
      </span>
    </span>
  </mat-menu>
  