import { Component, Input, OnInit } from '@angular/core';
import { GetDistributionListMembersDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'GetDistributionListMembersHistoryDetails',
    templateUrl: './getDistributionListMembersHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetDistributionListMembersHistoryDetailsComponent implements OnInit {
    @Input() getDistributionListMembersDetails: GetDistributionListMembersDetails;
    distributionListInfoCollapsed: boolean = false;

    public rows: Array<any> = [];
    public columns: Array<any> = [
        { title: 'Alias', name: 'Alias', filtering: { filterString: '', placeholder: 'Alias' }, sort: '', className: 'productColumnWidth' },
        { title: 'Display Name', name: 'DisplayName', filtering: { filterString: '', placeholder: 'DisplayName' }, sort: '', className: 'productColumnWidth' },
        { title: 'Email', name: 'PrimarySmtpAddress', filtering: { filterString: '', placeholder: 'Email' }, sort: '', className: 'productColumnWidth' },
        { title: 'Recipient Type Details', name: 'RecipientTypeDetails', filtering: { filterString: '', placeholder: 'RecipientTypeDetails' }, sort: '', className: 'productColumnWidth' }
    ];
    public hiddenColumn = { title: 'Parents', name: 'Parents', filtering: { filterString: '', placeholder: 'Filter children of primarySmtp' }, sort: '', className: 'productColumnWidth' };
    public page: number = 1;
    public itemsPerPage: number = 30;
    public maxSize: number = 5;
    public numPages: number = 1;
    public length: number = 0;
    public parents:  Array<string> = [];
    public dataForExport = [];

    public config: any = {
        paging: true,
        sorting: { columns: this.columns },
        filtering: { filterString: '' },
        className: ['table-striped', 'table-bordered']
    };

    private data: Array<any> = new Array<any>();
    
    refreshData() {

        this.columns = [
            { title: 'Alias', name: 'Alias', filtering: { filterString: '', placeholder: 'Alias' }, sort: '', className: 'productColumnWidth' },
            { title: 'Display Name', name: 'DisplayName', filtering: { filterString: '', placeholder: 'DisplayName' }, sort: '', className: 'productColumnWidth' },
            { title: 'Email', name: 'PrimarySmtpAddress', filtering: { filterString: '', placeholder: 'Email' }, sort: '', className: 'productColumnWidth' },
            { title: 'Recipient Type Details', name: 'RecipientTypeDetails', filtering: { filterString: '', placeholder: 'RecipientTypeDetails' }, sort: '', className: 'productColumnWidth' }
        ];
        this.hiddenColumn = { title: 'Parents', name: 'Parents', filtering: { filterString: '', placeholder: 'Filter children of primarySmtp' }, sort: '', className: 'productColumnWidth' };

        //Refresh the sorting onfiguration with the ne column and update de table with the new config
        if (this.config) {
            this.config.sorting = { columns: this.columns };
        }

        this.onChangeTable(this.config);
    }

    public ngOnInit(): void {
        this.data = this.getDistributionListMembersDetails.Members;
        this.parents = this.data.filter(item => item.MemberCount && item.MemberCount > 0)?.map(item => item.PrimarySmtpAddress)?.sort((a, b) => {
            return a.localeCompare(b, undefined, {sensitivity: 'base'});
        });
        this.parents = [""].concat([...new Set(this.parents)]);
        this.refreshData();
        this.onChangeTable(this.config);
    }

    public changePage(page: any, data: Array<any> = this.data): Array<any> {
        this.page = page.page;
        let start = (page.page - 1) * page.itemsPerPage;
        let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
        return data.slice(start, end);
    }

    public changeSort(data: any, config: any): any {
        if (!config.sorting) {
            return data;
        }

        let columns = this.config.sorting.columns || [];
        let columnName: string = void 0;
        let sort: string = void 0;

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].sort !== '' && columns[i].sort !== false) {
                columnName = columns[i].name;
                sort = columns[i].sort;
            }
        }

        if (!columnName) {
            return data;
        }

        // simple sorting
        return data.sort((previous: any, current: any) => {
        if (previous[columnName] > current[columnName]) {
            return sort === 'desc' ? -1 : 1;
        } else if (previous[columnName] < current[columnName]) {
            return sort === 'asc' ? -1 : 1;
        }
        return 0;
        });
    }

    public changeFilter(data: any, config: any): any {
        let filteredData: Array<any> = data;
        filteredData = filteredData.filter(item => !item.Invisible);
    
        if (this.hiddenColumn.filtering.filterString.length > 0) {
            filteredData = filteredData.filter(item => item.Parents && item.Parents.includes(this.hiddenColumn.filtering.filterString));
        }
        this.columns.forEach((column: any) => {
            if (column.filtering) {
                filteredData = filteredData.filter((item: any) => {
                    if (item[column.name]) {
                        return (item[column.name]).toUpperCase().match((column.filtering.filterString.toUpperCase()));
                    }
                });
            }
        });

        if (!config.filtering) {
            return filteredData;
        }

        if (config.filtering.columnName) {
            return filteredData.filter((item: any) => {
                if (item[config.filtering.columnName]) {
                    item[config.filtering.columnName].toUpperCase().match(this.config.filtering.filterString.toUpperCase());
                }
            });
        }
        if (this.config.filtering.filterString.length > 0 && this.config.filtering.filterString != this.hiddenColumn.filtering.filterString) {
            let tempArray: Array<any> = [];
            filteredData.forEach((item: any) => {
                let flag = false;
                this.columns.forEach((column: any) => {
                    if (item[column.name].toString().toUpperCase().match(this.config.filtering.filterString.toUpperCase())) {
                        flag = true;
                    }
                });
                if (flag) {
                    tempArray.push(item);
                }
            });
            filteredData = tempArray;
        }
        return filteredData;
    }

    public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {    
        if (config.filtering) {
            Object.assign(this.config.filtering, config.filtering);
        }

        if (config.sorting) {
            Object.assign(this.config.sorting, config.sorting);
        }
        let filteredData = this.changeFilter(this.data, config);
        let sortedData = this.changeSort(filteredData, this.config);
        this.rows = page && this.config.paging ? this.changePage(page, sortedData) : sortedData;
        this.length = sortedData.length;
        this.dataForExport = [...sortedData];
    }

    public collapseOrExpand(primarySmtpAddress){
        this.data.filter(item => item.PrimarySmtpAddress == primarySmtpAddress).map(item => item.Expanded = !item.Expanded);
        this.data.filter(item => item.Parents && item.Parents.includes(primarySmtpAddress)).map(item => item.Invisible = !item.Invisible);
        this.onChangeTable(this.config);
    }

    public recursion() {
        return this.data.some(item => item.MemberCount  && item.MemberCount > 0);
    }

    public exportDistributionListMembers(){
        let csvContent = "";

        //Populate the first row with object properties name
        let displayedColumns = this.config.sorting.columns.map(a => a.name);
        if (this.dataForExport.some(item => item.Parents)) {
            displayedColumns.push("Parents");
            this.dataForExport.filter(item => !item.Parents)?.forEach(item => {
                item.Parents = '';
            });
        }
        this.dataForExport.filter(item => item.Alias == ' ' || item.PrimarySmtpAddress == ' ')?.forEach(item => {
            if (item.Alias == ' ') {
                item.Alias = '';
            }
            if (item.PrimarySmtpAddress == ' ') {
                item.PrimarySmtpAddress = '';
            }
        });
        csvContent += displayedColumns.join(';');
        
        //Display the filter used when the data was exported
        let appliedFilters = '';
        if(this.config.filtering.filterString){
            appliedFilters += `globalFilter: '${this.config.filtering.filterString}' `;
        }

        if (this.hiddenColumn.filtering.filterString) {
            appliedFilters += `children of: '${this.hiddenColumn.filtering.filterString}' `;
        }

        for(const column of this.config.sorting.columns){
            if(column.filtering.filterString){
                appliedFilters += `${column.name}: '${column.filtering.filterString}' `;
            }
        }
        
        if(appliedFilters){
            csvContent += `;AppliedFilters ${appliedFilters} \r\n`;
        }else{
            csvContent += "\r\n";
        }            

        //Populate all the data by row
        for (const member of this.dataForExport){
            let row = '';                
            for (let i = 0; i < displayedColumns.length; i++){
                row += `${member[displayedColumns[i]]};`
            }
            csvContent += row + "\r\n";
        }

        this.exportFile(csvContent);
    }

    exportFile(csvContent) {
        try {
            let uri = `data:text/csv;charset=utf-8;header=present,${encodeURIComponent("\uFEFF" + csvContent)}`;
            let link = document.createElement('a');
            link.setAttribute('href', uri);
            link.setAttribute('download', `${this.getDistributionListMembersDetails.ListName}-Members-${new Date().toISOString()}.csv`);
            link.addEventListener('click', () => link.parentNode.removeChild(link));
            document.body.appendChild(link);
        
            link.click();
        } catch (error) {
            console.error(error);
        }
    }
}