import { Component, OnInit } from '@angular/core';
import { GraphApiService } from '../../../Services/graphApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';

@Component({
  selector: 'BodyContent',
  templateUrl: './licensesReport.component.html',
  styleUrls: ['../../../app.component.css']
})

export class LicensesReportComponent implements OnInit {
  public availableLicensesCounter = null;
  public subscribedLicenses = [];
  public assignableLicenses: Array<any> = new Array<any>();

  constructor(private graphApiService: GraphApiService, private businessRulesService: BusinessRulesService) {

  }

  ngOnInit()  {
    this.graphApiService.getSubscribedLicenses().subscribe(subscribedLicenses => {
    this.subscribedLicenses = subscribedLicenses,
      this.businessRulesService.getSpecificListByKey('LICENSESKU', 'AvailableLicenses').subscribe(response => { this.GetLicensesInfo(response); });
    });
    }

  ngOnDestroy()  {
  }

  GetLicensesInfo(availableLicenses) {
    if (availableLicenses) {
        availableLicenses.forEach(license => {
            let currentLicense = this.subscribedLicenses.find(obj => obj.skuPartNumber == license.code);
            this.assignableLicenses.push({Name:license.name, Counter:currentLicense ? (currentLicense.prepaidUnits.enabled + currentLicense.prepaidUnits.warning - currentLicense.consumedUnits) : 0});
        });
    }
}
}