<h4>Reset Okta B2B User Mfa:</h4>
<br /><br />
<h5 class="text-warning"><b>Before</b> launching the MFA reset, please confirm the user identity, through Wipass SD or after manager's approval.</h5>
<br /><br />
<form #fileContentForm="ngForm" (ngSubmit)="fileContentForm.resetForm()" >
    <div class="row form-group">
        <div class="col-4">
            <MailInputFieldOkta label="Okta Username" 
            [(mail)]="fileContent.OktaUsername"
            B2B="OktaB2B">
            </MailInputFieldOkta>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2 offset-8" style="padding-top:33px;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="fileContentForm.invalid">Send Request</button>
        </div>
    </div>
</form>