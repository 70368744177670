import { Component, NgModule, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { GraphApiService } from '../../../Services/graphApi.service';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';

import { CreateApplicationMailboxFileContent, CreateApplicationMailboxRequest } from '../../../Models/FileContents';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

import { ModifyRequest } from '../../../Helpers/modifyRequest';
import { booleanToStringConverter, removeArrayEmptyValues } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';



@Component({
    selector: 'BodyContent',
    templateUrl: './createApplicationMailbox.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateApplicationMailboxComponent {
    
    
    
    
    

    //Hardcoded List Values
    public languages: Array<any> = new Array<any>();
    public licenseTypes: Array<any> = new Array<any>();
    public litigationHoldDurations: Array<string> = new Array<string>();

    public requestContainer: any = {};
    public fileContentsList = new Array<CreateApplicationMailboxFileContent>();
    public fileContent = new CreateApplicationMailboxFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public subscribedLicenses = [];

    public availableLicensesCounter = null;
    public customerProfileImported: boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';
    public namePattern:string = inputValidationHelper.mailboxNameRegEx;

    constructor(private requestContainerService: RequestContainerService, private graphApiService: GraphApiService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private customerProfilesService: CustomerProfilesService, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
    }

    @ViewChildren("createMailboxOffice365LicensesInput") createMailboxOffice365LicensesInput: QueryList<ElementRef>;

    ngOnInit() {
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
        this.graphApiService.getSubscribedLicenses().subscribe(subscribedLicenses => {
            this.subscribedLicenses = subscribedLicenses,
                this.businessRulesService.getSpecificListByKey('LICENSESKU', 'MailboxLicenses')
                    .subscribe(response => {
                        this.licenseTypes = response,
                            this.licenseTypes = this.licenseTypes.sort(function (a, b) {
                                var x = a.name.toLowerCase();
                                var y = b.name.toLowerCase();
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            }),
                            this.getNumberOfAvailableLicences(this.licenseTypes[0].code);
                        this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then(cb => {
                            if (cb && this.fileContent.Office365Licenses) {
                                this.getNumberOfAvailableLicences(this.fileContent.Office365Licenses);
                            }
                        });
                    });
        });
        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => { this.languages = response; });

        this.businessRulesService.getSpecificListByKey('LITIGATIONHOLDDURATIONS', 'AvailableLitigationHoldDurations')
            .subscribe(response => { this.litigationHoldDurations = response; });
    }

    ngOnDestroy() {
        
        
        
        
        
    }

    isGroupIDFormatValid(groupID) {
        return inputValidationHelper.checkGroupIdNoAdminFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateApplicationMailboxFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms() {
        this.fileContent = new CreateApplicationMailboxFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList() {
        var tempObject = new CreateApplicationMailboxFileContent(this.fileContent);
        tempObject.MailAddresses = removeArrayEmptyValues(tempObject.MailAddresses);
        tempObject.AdditionalManager = removeArrayEmptyValues(tempObject.AdditionalManager);
        this.fileContentsList.push(tempObject);
        this.setBackEmptyForm();
        this.clearFileContentForms();
        this.customerProfileImported = false;
    }

    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify) {
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index) {
        arrayToModify.splice(index, 1);
    }

    getNumberOfAvailableLicences(licenseSKU) {
        let currentLicense = this.subscribedLicenses.find(obj => obj.skuPartNumber == licenseSKU);
        this.availableLicensesCounter = currentLicense ? (currentLicense.prepaidUnits.enabled + currentLicense.prepaidUnits.warning - currentLicense.consumedUnits) : 0;
    }

    upgradeLicense(litigationHoldEnabled) {
        if (litigationHoldEnabled && this.fileContent.Office365Licenses == "STANDARDPACK") {
            this.fileContent.Office365Licenses = "ENTERPRISEPACK"
        }
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?) {
        //Add the current fileContent to the fileContentsList if the form is valid
        if (isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError && !this.fileContent.ManagerDisplayNameError) {
            this.addFileContentToList();
        }

        //Initiatiate the request data
        var data = new CreateApplicationMailboxRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/createApplicationMailbox')
            .subscribe(response => {
                this.clearPage();

                //Display a success notification for 2sec
                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                //Redirect to request history page
                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    //In case of API call error display a popup with information about the error
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    importCustomerProfile() {
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if (inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)) {
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if (response) {
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if (response.Office365Licenses) {
                            if (!(response.Office365Licenses == 'STANDARDPACK' && (response.EnableLitigationHold || this.fileContent.EnableLitigationHold))) {
                                this.fileContent.Office365Licenses = response.Office365Licenses;
                                this.getNumberOfAvailableLicences(response.Office365Licenses);
                                this.createMailboxOffice365LicensesInput.changes.subscribe((elements) => {
                                    if (elements["length"] == 0 && this.fileContent.Office365Licenses) {
                                        if (document.getElementById("createMailboxOffice365LicensesInput")) {
                                            document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #388e3c";
                                        }
                                        if (document.getElementById("createMailboxOffice365LicensesInput1")) {
                                            document.getElementById("createMailboxOffice365LicensesInput1").style.border = "2px solid #388e3c";
                                        }
                                    }
                                });
                                if (document.getElementById("createMailboxOffice365LicensesInput")) {
                                    document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #388e3c";
                                }
                                if (document.getElementById("createMailboxOffice365LicensesInput1")) {
                                    document.getElementById("createMailboxOffice365LicensesInput1").style.border = "2px solid #388e3c";
                                }
                            }
                        } else {
                            missingVal = true;
                        }
                        if (response.Discriminant) {
                            this.fileContent.Discriminant = response.Discriminant;
                            document.getElementById("createMailboxDiscriminantInput").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }
                        if (response.EnableArchive) {
                            this.fileContent.EnableArchive = response.EnableArchive;
                            document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }
                        if (response.EnableLitigationHold) {
                            this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                            document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #388e3c";
                            if (response.LitigationHoldDuration) {
                                this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                document.getElementById("createMailboxLitigationHoldDuration").style.border = "2px solid #388e3c";
                            } else {
                                missingVal = true;
                            }
                        } else {
                            missingVal = true;
                        }

                        if (response.MailboxLanguage) {
                            this.fileContent.MailboxLanguage = response.MailboxLanguage;
                            document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }

                        if (missingVal) {
                            this.importDefaultCustomerProfile();
                        }
                    } else {
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if (error.status === 404) {
                        this.showImportedCompanyUserNotFoundError = true;
                    } else {
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile() {
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if (inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)) {
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if (response) {
                        if (!this.fileContent.Office365Licenses) {
                            if (response.Office365Licenses) {
                                if (!(response.Office365Licenses == 'STANDARDPACK' && (response.EnableLitigationHold || this.fileContent.EnableLitigationHold))) {
                                    this.fileContent.Office365Licenses = response.Office365Licenses;
                                    this.getNumberOfAvailableLicences(response.Office365Licenses);
                                    this.createMailboxOffice365LicensesInput.changes.subscribe((elements) => {
                                        if (elements["length"] == 0 && this.fileContent.Office365Licenses) {
                                            if (document.getElementById("createMailboxOffice365LicensesInput")) {
                                                document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #fbc02d";
                                            }
                                            if (document.getElementById("createMailboxOffice365LicensesInput1")) {
                                                document.getElementById("createMailboxOffice365LicensesInput1").style.border = "2px solid #fbc02d";
                                            }
                                        }
                                    });
                                    if (document.getElementById("createMailboxOffice365LicensesInput")) {
                                        document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #fbc02d";
                                    }
                                    if (document.getElementById("createMailboxOffice365LicensesInput1")) {
                                        document.getElementById("createMailboxOffice365LicensesInput1").style.border = "2px solid #fbc02d";
                                    }
                                    this.showImportedDefaultSuccess = true;
                                }
                            }
                        }
                        if (!this.fileContent.Discriminant) {
                            if (response.Discriminant) {
                                this.fileContent.Discriminant = response.Discriminant;
                                document.getElementById("createMailboxDiscriminantInput").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                        if (!this.fileContent.EnableArchive) {
                            if (response.EnableArchive) {
                                this.fileContent.EnableArchive = response.EnableArchive;
                                document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                        if (!this.fileContent.EnableLitigationHold) {
                            if (response.EnableLitigationHold) {
                                this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                                document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #fbc02d";
                                if (!this.fileContent.LitigationHoldDuration) {
                                    if (response.LitigationHoldDuration) {
                                        this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                        document.getElementById("createMailboxLitigationHoldDuration").style.border = "2px solid #fbc02d";
                                    }
                                }
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                        if (!this.fileContent.LitigationHoldDuration) {
                            if (response.LitigationHoldDuration) {
                                this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                document.getElementById("createMailboxLitigationHoldDuration").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }

                        if (!this.fileContent.MailboxLanguage) {
                            if (response.MailboxLanguage) {
                                this.fileContent.MailboxLanguage = response.MailboxLanguage;
                                document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                    } else {
                        this.showImportedNoProfileDefaultSuccess = true;
                    }
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm() {
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        if (this.customerProfileImported) {
            var groupId = this.fileContent.Manager;
            if (!inputValidationHelper.checkGroupIdNoAdminFormat(groupId)) {
                this.fileContent.Office365Licenses = "";
                this.fileContent.Discriminant = "";
                this.fileContent.EnableArchive = false;
                this.fileContent.EnableLitigationHold = false;
                this.fileContent.LitigationHoldDuration = "";
                this.fileContent.MailboxLanguage = "";
                this.customerProfileImported = false;
            }
        }

        if (document.getElementById("createMailboxOffice365LicensesInput")) {
            document.getElementById("createMailboxOffice365LicensesInput").style.border = "1px solid rgba(0,0,0,.15)";
        }
        if (document.getElementById("createMailboxOffice365LicensesInput1")) {
            document.getElementById("createMailboxOffice365LicensesInput1").style.border = "1px solid rgba(0,0,0,.15)";
        }
        document.getElementById("createMailboxDiscriminantInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxEnableArchiveInput").style.border = "none";
        document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "none";
        document.getElementById("createMailboxLitigationHoldDuration").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxLanguageDropDown").style.border = "1px solid rgba(0,0,0,.15)";
    }
}