<div class="m-3">
    <div>
        <div class="row">
            <div class="col-8">
                <h2>Alert Message Details :</h2>
            </div>
            <div class="col-1 offset-4">
                <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary"
                    (click)="deleteLocalITCard()">Delete</button>
            </div>
            <div class="col-1 offset-2">
                <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary"
                    (click)="navigateToLocalITCardEdition()">Edit</button>
            </div>
            <div class="col-1">
                <i style="float: right;" class="material-icons md-36 primaryBlue pointer"
                    (click)="closeDialog()">highlight_off</i>
            </div>
        </div>
    </div>

    <form #fileContentForm="ngForm">

        <div class="row form-group">
            <div class="col-4">
                <label class="required" for="localITNameInput">Title:</label>
                <input type="text" id="localITNameInput" class="form-control" [(ngModel)]="topBarMessage.Title"
                    name="localITNameInput" required [disabled]="true">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-8">
                <label class="required" for="localITDisplayNameInput">Message:</label>
                <input type="text" id="localITDisplayNameInput" class="form-control" [(ngModel)]="topBarMessage.Message"
                    name="localITDisplayNameInput" required [disabled]="true">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-4" style="display: inline;">
                Display Options:
            </div>
        </div>
        <div class="row form-group">
            <div class="col-4">
                <input type="checkbox" [checked]="topBarMessage.Show" [disabled]="true">
                Show Now
            </div>
            <div class="col-4">
                Alert Level:
                <select name="alertlevel" id="alertlevel" [(ngModel)]="topBarMessage.Level" [disabled]="true">
                    <option value="alert-success" class="alert alert-success">Level 1</option>
                    <option value="alert-info" class="alert alert-info">Level 2</option>
                    <option value="alert-warning" class="alert alert-warning">Level 3</option>
                    <option value="alert-danger" class="alert alert-danger">Level 4</option>
                </select>
            </div>
        </div>
        <div *ngIf="!topBarMessage.Show" class="row form-group">
            <div class="col-4" style="display: inline;">
                <input class="form-control" style="width: 50%; float: left;" matInput [matDatepicker]="fromDatePicker"
                    placeholder="From" [value]="topBarMessage.StartDateTime" [disabled]="true">
                <mat-datepicker-toggle style="float: left;" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <input type="time" id="startTimeInput" class="form-control" style="width:100px;" [(ngModel)]="startTime"
                    name="startTimeInput" [disabled]="true">
            </div>
            <div class="col-4" style="display: inline;">
                <input class="form-control" style="width: 50%; float: left;" matInput [matDatepicker]="toDatePicker"
                    placeholder="To" [value]="topBarMessage.EndDateTime" [disabled]="true">
                <mat-datepicker-toggle style="float: left;" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <input type="time" id="endTimeInput" class="form-control" style="width:100px;" [(ngModel)]="endTime"
                    name="endTimeInput" [disabled]="true">
            </div>
        </div>

    </form>
</div>