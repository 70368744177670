<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="personalMailboxInfoCollapsed=!personalMailboxInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    {{ createPersonalMailboxDetails.MailboxType }} MAILBOX INFORMATION 
                    <i *ngIf="personalMailboxInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!personalMailboxInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!personalMailboxInfoCollapsed">
            <tr>
                <th>Display Name</th>
                <td>{{ createPersonalMailboxDetails.DisplayName }}</td>
            </tr>
            <tr>
                <th>Alias</th>
                <td>{{ createPersonalMailboxDetails.Alias }}</td>
            </tr>
            <tr>
                <th>Primary SMTP</th>
                <td>{{ createPersonalMailboxDetails.PrimarySMTP }}</td>
            </tr>
            <tr>
                <th>Remote Routing Address</th>
                <td>{{ createPersonalMailboxDetails.MOERA }}</td>
            </tr>
            <tr>
                <th>Mailbox Type</th>
                <td>{{ createPersonalMailboxDetails.MailboxType }}</td>
            </tr>
            <tr>
                <th>License Reconciliation Needed</th>
                <td>{{ createPersonalMailboxDetails.LicenseReconciliationNeeded }}</td>
            </tr>
        </tbody>
    </table>
</div>