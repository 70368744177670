<h4>Zscaler Tunneling:</h4>
<div class="center nj-spinner" *ngIf="isWait">
    <mat-spinner diameter=50 mode="indeterminate" color="primary">
    </mat-spinner>
</div>
<br /><br />
<form #fileContentForm="ngForm">
    <div class='row form-group'>
        <div class="col-4">
            <label class="required" for="url">Add Destination:</label>
            <div
                *ngFor="let url of fileContent.Urls; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'url' + i}}" class="form-control col-10" [pattern]="urlPattern"
                        [(ngModel)]="fileContent.Urls[i]" name="{{'url' + i}}" #url="ngModel" required placeholder="1.1.1.1 / engie.com">
                    <i *ngIf="isLast" id="{{'urlAddBtn' + i}}"
                        class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                        (click)="addInputRow(fileContent.Urls)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst && !isLast" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'url' + i}}" class="form-control col-10" [pattern]="urlPattern"
                        [(ngModel)]="fileContent.Urls[i]" name="{{'url' + i}}" #url="ngModel" required placeholder="1.1.1.1 / engie.com">
                    <i id="{{'urlRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                        style="margin-left: 5px;"
                        (click)="removeInputRow(fileContent.Urls,i)">remove_circle_outline</i>
                </div>
                <div *ngIf="isLast && !isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'url' + i}}" class="form-control col-10" [pattern]="urlPattern"
                        [(ngModel)]="fileContent.Urls[i]" name="{{'url' + i}}" #url="ngModel" required placeholder="1.1.1.1 / engie.com">
                    <i id="{{'urlRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                        style="margin-left: 5px;"
                        (click)="removeInputRow(fileContent.Urls,i)">remove_circle_outline</i>
                    <i id="{{'urlAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                        style="margin-left: 5px;" (click)="addInputRow(fileContent.Urls)">add_circle_outline</i>
                </div>
            </div>
        </div>

        <div class="col-4">
            <label class="required" for="validatedCISOInput">Validated with my CISO:</label>
            <div id="validatedCISOInput">
                <div style="display: inline-block;margin-right: 5px;">
                    <label><input type="radio" id="validatedCISOInputOpt1" name="validatedCISOInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ValidatedByCISO" #validatedCISOInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="validatedCISOInputOpt2" name="validatedCISOInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ValidatedByCISO" #validatedCISOInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>

    <div class='row form-group'>
        <div class="col-4">
            <label class="required" for="descInput">Description:</label>
            <div style="display:flex">
                <textarea type="text" id="descInput" name="descInput" class="form-control"
                    [(ngModel)]="fileContent.Description" required #descInput="ngModel">
                </textarea>
            </div>
            <small id="urlinfo" class="form-text text-muted">Add a short description to justify this addition and explain why it’s required.</small>
        </div>

        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
 

    <div class="col-2 offset-8" style="padding-top:33px;padding-left: 0px;">
        <button type="submit" class="btn btn-primary" 
        (click)="sendRequest(); fileContentForm.reset();" 
        [disabled]="(fileContentForm.invalid)">Send Request</button>
    </div>
    
</form>