export class RequestBase {
    _key;
    GUID;
    Type;
    Status;
    MaxOldDate;
    ExpirationDate;    
    DateCreated;
    DateModified;   
    Feedback; 
    LastOperationTime;
    Server;
    Source;
    Requestor;
    LocalIT;
    
    constructor(_key, GUID, Type, Status, MaxOldDate, ExpirationDate, DateCreated, DateModified, Feedback, LastOperationTime, Server, Source, Requestor, LocalIT) 
    {
        this._key = _key;
        this.GUID = GUID;
        this.Type = Type;
        this.Status = Status;
        this.MaxOldDate = MaxOldDate;
        this.ExpirationDate = ExpirationDate;
        this.DateCreated = DateCreated;
        this.DateModified = DateModified;
        this.Feedback = Feedback;
        this.LastOperationTime = LastOperationTime;
        this.Server = Server;
        this.Source = Source;
        this.Requestor = Requestor;
        this.LocalIT = LocalIT;
    }
}