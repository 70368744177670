<h4>Delete Zscaler ByPass:</h4>
<br /><br />
<form [formGroup]="deleteZscalerBypassRequestForm">
    <div class="center nj-spinner" *ngIf="isWait">
        <mat-spinner diameter=50 mode="indeterminate" color="primary">
        </mat-spinner>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="type" class="required">ByPass Type:</label>
            <select *ngIf="bypassTypes.length > 0" id="type" formControlName="type" class="form-control"
                (change)="typeChanged()" required>
                <option disabled value="">Select a bypass type</option>
                <option *ngFor="let bypassType of bypassTypes" [value]="bypassType.Name">{{bypassType.Name}}</option>
            </select>
        </div>
        <div class="col-4">
            <mat-form-field>
                <label for="urlFilter" class="required">URLs:</label>
                <mat-chip-grid #chipList>
                    <mat-chip-row *ngFor="let url of selectedUrls" (removed)="removeurl(url)">
                        {{url}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input #chipInput formControlName="urlFilter" [matAutocomplete]="auto"
                        [matChipInputFor]="chipList" (matChipInputTokenEnd)="addurl($event)"/>
                    <input formControlName="urls" style="display: none;" required/>
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUrl($event)">
                    <mat-option *ngFor="let url of filteredUrls | async" [value]="url">
                        {{url}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="ticketID">Ticket ID:</label>
            <input type="text" id="ticketID" formControlName="ticketID" class="form-control">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2 offset-8" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest();"
                [disabled]="(deleteZscalerBypassRequestForm.invalid)">Send Request</button>
        </div>
    </div>
</form>