import { Component, NgModule} from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { ModifyIntuneUserFileContent, ModifyIntuneUserRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './modifyIntuneUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class ModifyIntuneUserComponent{
    
    
    

    public assignableDeviceTypes:Array<string> = new Array<string>();
    public listLocalIT:Array<string> = new Array<string>();
    public applications;
    public localITTeamsGroup;
    public byDefaultTeamsGroup:Array<string> = new Array<string>();
    public topLocalITTeamsGroup:Array<string> = new Array<string>();
    public defaultTeamsGroupList:Array<string> = new Array<string>();
    public groupMembership;
    public commonGroupMembership;
    public fileContentsList = new Array<ModifyIntuneUserFileContent>();
    public fileContent = new ModifyIntuneUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public topLocalIT:Array<string> =  new Array<string>();
    public isTopLocalIT:boolean = false;
    public isAnyLocalITFromOkta:boolean = false;
    public isUserInOkta:boolean = false;
    public howToUseInfo:boolean = true;
    public searchLocalITGroup:string = '';
    public groupIDLabel:string = "Select Intune enabled Local IT:";
    public i = 0;
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    private oktaMfaBypass;
    private mobileMailClient;
    private airWatchMigration;
    private oktaId;
   
    // public testUserProfile: any = null;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private graphApiService: GraphApiService, 
        private oktaApiService: OktaApiService, 
        private localITsService: LocalITsService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog){
            this.clearFileContentForms();
    }
    
    ngOnInit(){
        this.localITsService.getLocalITsWithManageAllPermission()
            .subscribe(response => {this.topLocalIT = response;});
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.clearPage();
                this.fileContentsList = new Array<ModifyIntuneUserFileContent>();
                this.isTopLocalIT = false;
                this.selectedLocalIT = updatedLocalIT;
                this.graphApiService.getGroupByDisplayName("EMM-INTUNE-IT_999-App").subscribe(
                    response => this.byDefaultTeamsGroup = response
                )
            });
        
        this.businessRulesService.getSpecificListByKey('DLTYPES', 'MobileMailClientTypes')
            .subscribe(response => {this.assignableDeviceTypes = response;});
    }

    ngOnDestroy(){
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.localITNotFound = '';
        this.howToUseInfo = true;
    }

    clearFileContentForms(){
        this.fileContent = new ModifyIntuneUserFileContent();
        this.isAnyLocalITFromOkta = false;
        this.isUserInOkta = false;
        this.airWatchMigration = this.mobileMailClient = this.oktaMfaBypass = null;
        this.applications = [];
        this.listLocalIT =  new Array<string>();
    }

    public setBackEmptyForm(){
        this.fileContent.ManagedLocalIT = '';
        this.fileContent.MobileMailClient = "";
        this.fileContent.AirWatchMigration = null;
        this.localITNotFound = '';
        this.isAnyLocalITFromOkta = false;
        this.isUserInOkta = false;
        this.airWatchMigration = this.mobileMailClient = this.oktaMfaBypass = null;
        this.applications = [];
        this.listLocalIT =  new Array<string>();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    addFileContentToList(){
        var tempObject = new ModifyIntuneUserFileContent(this.fileContent);
        if (tempObject.AirWatchMigration == this.airWatchMigration) {
            tempObject.AirWatchMigration = null;
        }
        if (tempObject.MobileMailClient == this.mobileMailClient) {
            tempObject.MobileMailClient = null;
        }
        if (tempObject.OktaMfaBypass == this.oktaMfaBypass) {
            tempObject.OktaMfaBypass = null;
        }
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
        this.setBackEmptyForm();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var data = new ModifyIntuneUserRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/modifyIntuneUser')
            .subscribe( response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );        
    }

    onTeamGroupToggle(tGroup:any, $event:any) {
        var index;
        if ($event.target.checked) {
            this.applications.push(tGroup.displayName);
            this.fileContent.AddApplications.push(tGroup.displayName);
            index = this.fileContent.RemoveApplications.indexOf(tGroup.displayName);
            if(index >= 0){
                this.fileContent.RemoveApplications.splice(index, 1);
            }
        }
        else {
            index = this.applications.indexOf(tGroup.id);
            if(index >= 0){
                this.applications.splice(index, 1);
            }
            this.fileContent.RemoveApplications.push(tGroup.displayName);
            index = this.fileContent.AddApplications.indexOf(tGroup.displayName);
            if(index >= 0){
                this.fileContent.AddApplications.splice(index, 1);
            }
        }
    }

    getLocalItTeamsGroup(localIT:string, firstcall = false){
        if(localIT){
            let groupPattern = `EMM-INTUNE-${localIT}-App`;
            this.graphApiService.getGroupByDisplayName(groupPattern)
                .subscribe(response => {
                    this.localITTeamsGroup = response;
                    this.applications = [];
                    if(response.length === 0){
                        this.localITNotFound = `No AAD group for applications found for Local IT ${localIT}.`;
                        // this.fileContent.ManagedLocalIT = ''
                    } 
                    else{
                        if(localIT === this.selectedLocalIT){
                            this.topLocalITTeamsGroup = response;
                        }
                        // this.fileContent.ManagedLocalIT = this.searchLocalITGroup;
                    }
                    if(localIT != "IT_999"){
                        this.localITTeamsGroup.push(...this.byDefaultTeamsGroup);
                        if(firstcall){
                            //save in memory the IT_999 and SelecetLocalIt teams app
                            this.defaultTeamsGroupList = this.localITTeamsGroup;
                            console.log(this.defaultTeamsGroupList);
                        }
                    }
                    this.setInitalIntuneGroupMembership(localIT)
                }); 
        }
    }

    setInitalIntuneGroupMembership(localIT:string){
        this.fileContent.AirWatchMigration = this.airWatchMigration = this.groupMembership.some(item => item.displayName.toUpperCase().includes("EMM-INTUNE-AIRWATCHEXCLUSION"))
        var emailOutlook = this.groupMembership.some(item => item.displayName.toUpperCase().includes(`EMM-INTUNE-${localIT}-EMAIL-OUTLOOK`))
        var emailNative = this.groupMembership.some(item => item.displayName.toUpperCase().includes(`EMM-INTUNE-${localIT}-EMAIL-NATIVE`))
        if (emailOutlook && emailNative) {
            this.fileContent.MobileMailClient = this.mobileMailClient = "Both";
        }
        else if (emailOutlook) {
            this.fileContent.MobileMailClient = this.mobileMailClient = "Outlook Mobile";
        }
        else if (emailNative) {
            this.fileContent.MobileMailClient = this.mobileMailClient = "Native";
        }
        else {
            this.fileContent.MobileMailClient = this.mobileMailClient = "None";
        }
        var checkedFields = (this.localITTeamsGroup.filter(value => this.groupMembership.some(item => item.id == value.id)));
        if (checkedFields.length > 0) {
            this.applications = checkedFields.map((element) => element.id);
        }
        this.oktaApiService.getUserGroups(this.oktaId)
            .subscribe(
                response => {
                    this.fileContent.OktaMfaBypass = this.oktaMfaBypass = (response.length == 1);
                }
            ),
            (error) => {
                if(error && error.status && error.status == '404'){
                    this.fileContent.OktaMfaBypass = this.oktaMfaBypass = false;
                }else{
                    this.localITNotFound = error.message;
                }
                
            };
    }

    // Get the LocalITs of the user from okta
    getLocalITsFromOkta(){
        let gID = this.fileContent.GroupID.toString() ;
        this.fileContent.ManagedLocalIT = '';
        this.isAnyLocalITFromOkta = false;
        let localITs = [];
        this.localITNotFound = '';
        this.isUserInOkta = false;
        this.howToUseInfo = false;

        if(gID){
            this.isTopLocalIT = this.topLocalIT.some((value) => value === this.selectedLocalIT) 
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        //call the post graph api
                        this.graphApiService.getAADUserMembership(gID,'EMM-INTUNE-')
                            .subscribe(
                                resp => {
                                    this.groupMembership = resp;
                                    //console.log(this.groupMembership);
                                    if(this.groupMembership.length > 0){
                                        var regex = /EMM-INTUNE-IT_...-Common/i
                                        this.commonGroupMembership = (this.groupMembership).filter(item => item.displayName.match(regex));
                                        if (this.commonGroupMembership.length > 0){
                                            this.commonGroupMembership = this.commonGroupMembership.map((element) => (element.displayName).substring(11,17));
                                            this.isUserInOkta = true;
                                        }
                                        
                                    }
                                    if(!this.isUserInOkta){
                                        this.localITNotFound  = `User  ${gID} is not enabled for Intune`
                                        this.clearFileContentForms();
                                        return
                                    }
                                    if(this.isTopLocalIT){
                                        this.listLocalIT = this.commonGroupMembership;
                                    }
                                    this.oktaId = response.id;
                                    let localIT = response.c_msDSCloudExtensionAttribute1;
                                    if(localIT){
                                        let hasMultipleLocalITs = localIT.indexOf(";");
                                        this.isAnyLocalITFromOkta = true;
                                        if(hasMultipleLocalITs > 0){
                                            localITs.push(...localIT.split(";"));
                                        }
                                        else{
                                            localITs.push(localIT);
                                        }
                                        if(!this.isTopLocalIT){
                                            this.listLocalIT = localITs.filter(value => this.commonGroupMembership.includes(value))
                                            if(this.listLocalIT.length == 0 && !this.commonGroupMembership.includes(this.selectedLocalIT.toString())){
                                                this.localITNotFound  = `User  ${gID} is not enabled for Intune for ${localITs.join(", ")}`
                                                this.clearFileContentForms();
                                                return
                                            }
                                            if(!localITs.includes(this.selectedLocalIT.toString())){
                                                this.localITNotFound  = `${this.selectedLocalIT.toString()} is not in the Local IT attribute in OKTA [${localITs.join(", ")}] for ${gID}`
                                                this.clearFileContentForms();
                                                return
                                            }
                                        }                                        

                                        if(this.listLocalIT.includes(this.selectedLocalIT.toString())){
                                            this.fileContent.ManagedLocalIT = this.selectedLocalIT.toString();
                                            this.getLocalItTeamsGroup(this.selectedLocalIT.toString(), false);
                                        }
                                        else if(this.listLocalIT.length >= 1){
                                            this.fileContent.ManagedLocalIT = this.listLocalIT[0];
                                            this.getLocalItTeamsGroup(this.listLocalIT[0], false);
                                        }
                                    }
                                    else{
                                        this.localITNotFound = `Local IT attribute not set in OKTA for ${gID}`;
                                        this.localITTeamsGroup = [];
                                        if(!this.isTopLocalIT && !this.commonGroupMembership.includes(this.selectedLocalIT.toString())){
                                            this.isUserInOkta = false;
                                            this.localITNotFound  = `User  ${gID} is not enabled for Intune for ${this.selectedLocalIT.toString()}`
                                            return
                                        }
                                        if(this.listLocalIT.length >= 1){
                                            this.fileContent.ManagedLocalIT = this.listLocalIT[0];
                                            this.getLocalItTeamsGroup(this.listLocalIT[0], false);
                                        }
                                        else {
                                            this.getLocalItTeamsGroup(this.selectedLocalIT.toString(), false);
                                        }
                                    }
                                }, 
                                error => {                                    
                                    if(error.error){
                                        if (error.error.match(/(?:"message":").*?(?=")/)) {
                                            this.localITNotFound = error.error.match(/(?:"message":").*?(?=")/)[0];
                                        }
                                        else {
                                            this.localITNotFound = error.error;
                                        }
                                    }else{
                                        this.localITNotFound = error.message;
                                    }
                                    this.clearFileContentForms();
                                }
                            );
                
                        
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.localITNotFound  = `${gID} not found in Okta!`;
                        }else{
                            this.localITNotFound = 'Error when trying to search GroupID in Okta!';
                        }
                        this.applications = [];
                        this.localITTeamsGroup = [];
                        this.fileContent.GroupID = '';
                    }
                );
        }
    }
} 