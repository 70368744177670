import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { urlPattern } from 'app/Helpers/utils';
@Component({
    selector: 'BodyContent',
    templateUrl: './addZScalerAuthByPass.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class AddZScalerAuthByPassUserComponent {
    
    public selectedLocalIT: String = '';
    bypassTypes = [];
    isWait = false;
    alertUser: string = "The Url will be deleted automatically after 7 days.";
    alertUserInvalidUrl: string = "This Url is not valid.";
    today = new Date();
    minDate = new Date(new Date().setUTCDate(this.today.getDate() + 7));
    maxDate = new Date(new Date().setUTCDate(this.today.getDate() + 365));
    addZscalerBypassRequestForm = new FormGroup({
        type: new FormControl(''),
        url: new FormControl('', [Validators.required, Validators.pattern(urlPattern)]),
        validatedByCiso: new FormControl(false),
        endDate: new FormControl(new Date(new Date().setUTCDate(this.today.getDate() + 14))),
        description: new FormControl(''),
        isBypassTest: new FormControl(false),
        ticketID: new FormControl(''),
    });
    constructor(
        private zscalerApiService: ZscalerApiService,
        private businessRulesService: BusinessRulesService,
        private userService: UserService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router
    ) { }

    async ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
            });
        this.bypassTypes = await this.businessRulesService.getSpecificListByKey('DLTYPES', 'ByPassTypes').toPromise();
        this.bypassTypes = this.bypassTypes.filter(x => !x.includes('-'));
    }

    ngOnDestroy() {
        
    }

    async sendRequest() {
        try {
            this.isWait = true;
            let data = {};
            data = this.addZscalerBypassRequestForm.value;
            data["localIT"] = this.selectedLocalIT;
            await this.zscalerApiService.addZscalerAuthBypass(data).toPromise();
            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold'],
            });
            await successSnackBar.afterDismissed().toPromise();
            this.router.navigate(['/history']);
        } catch (error) {
            console.log(error);
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        } finally {
            this.isWait = false;
        }
    }
} 