<h4>Create Distribution List:</h4>

<!-- CREATE DISTRIBUTION LIST FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.Owner)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.Owner)">Please fill a Owner GID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.Owner)">Based on Owner GID</small>
        </div>
    </div>
    <div class="row form-group">
        <span style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="createDLNameInput">Name:</label>
            <input type="text" id="createDLNameInput" class="form-control"
                    [(ngModel)]="fileContent.Name" name="createDLNameInput" #createDLNameInput="ngModel" required>
            <div *ngIf="createDLNameInput.invalid && (createDLNameInput.dirty || createDLNameInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="createDLNameInput.errors.required">
                    Distribution List Name is required.
                </div>         
            </div>
        </div>       
        <div class="col-4">
            <GroupIDInputField label="Owner GID" (groupIDDisplayNameChange)="fileContent.OwnerDisplayName=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.Owner"></GroupIDInputField>    
        </div>
        <div class="col-4">
            <label for="createDLOwnerSendAsInput">Owner Send As:</label>
            <div id="createDLOwnerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLOwnerSendAsInputOpt1" name="createDLOwnerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.OwnerSendAs" #createDLOwnerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLOwnerSendAsInputOpt2" name="createDLOwnerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.OwnerSendAs" #createDLOwnerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>    
    <div class="row form-group">         
        <div class="col-4">            
            <label for="createDLMembersInput">Members :</label>
            <div *ngFor="let member of fileContent.Members; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLMembersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Members[i]" name="{{'createDLMembersInput' + i}}">
                    <i id="createDLMembersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.Members)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLMembersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Members[i]" name="{{'createDLMembersInput' + i}}">
                    <i id="{{'createDLMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.Members, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">            
            <label for="createDLAdditionalOwnersInput">Additional Owners:</label>
            <div *ngFor="let additionalOwner of fileContent.AdditionalOwners; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLAdditionalOwnersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.AdditionalOwners[i]" name="{{'createDLAdditionalOwnersInput' + i}}">
                    <i id="createDLAdditionalOwnersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.AdditionalOwners)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLAdditionalOwnersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.AdditionalOwners[i]" name="{{'createDLAdditionalOwnersInput' + i}}">
                    <i id="{{'createDLAdditionalOwnersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.AdditionalOwners, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createDLAdditionalOwnersSendAsInput">Additional Owners Send As:</label>
            <div id="createDLAdditionalOwnersSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLAdditionalOwnersSendAsInputOpt1" name="createDLAdditionalOwnersSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.AdditionalOwnersSendAs" #createDLAdditionalOwnersSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLAdditionalOwnersSendAsInputOpt2" name="createDLAdditionalOwnersSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.AdditionalOwnersSendAs" #createDLAdditionalOwnersSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>        
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="createDLTypeDropdownList">Type:</label>
            <select id="createDLTypeDropdownList" class="form-control"
                [(ngModel)]="fileContent.DLType" name="createDLTypeDropdownList">
                <option *ngFor="let dlType of assignableDLTypes | orderby: '' : false : true" [value]="dlType">{{dlType}}</option>
            </select>
        </div>       
        <div class="col-4">
            <label for="createDLPrimarySmtpInput">Primary Smtp:</label>
            <input type="text" id="createDLPrimarySmtpInput" class="form-control"
                    [(ngModel)]="fileContent.PrimarySmtp" name="createDLPrimarySmtpInput" #createDLPrimarySmtpInput="ngModel">
        </div>
        <div class="col-4">
            <label for="createDLPrimarySmtpDomainInput">Primary Smtp Domain:</label>
            <input type="text" id="createDLPrimarySmtpDomainInput" class="form-control"
                    [(ngModel)]="fileContent.PrimarySmtpDomain" name="createDLPrimarySmtpDomainInput" #createDLPrimarySmtpDomainInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">            
            <label for="createDLMailAdressesInput">Mail Addresses:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLMailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createDLMailAdressesInput' + i}}">
                    <i id="createDLMailAdressesAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLMailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createDLMailAdressesInput' + i}}">
                    <i id="{{'createDLMailAdressesRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createDLAcceptFromInput">Accept From:</label>
            <div *ngFor="let memberAccepted of fileContent.AcceptFrom; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLAcceptFromInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.AcceptFrom[i]" name="{{'createDLAcceptFromInput' + i}}">
                    <i id="createDLAcceptFromInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.AcceptFrom)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLAcceptFromInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.AcceptFrom[i]" name="{{'createDLAcceptFromInput' + i}}">
                    <i id="{{'createDLAcceptFromInputInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.AcceptFrom, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createDLRejectFromInput">Reject From:</label>
            <div *ngFor="let memberRejected of fileContent.RejectFrom; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLRejectFromInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.RejectFrom[i]" name="{{'createDLRejectFromInput' + i}}">
                    <i id="createDLRejectFromInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.RejectFrom)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLRejectFromInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.RejectFrom[i]" name="{{'createDLRejectFromInput' + i}}">
                    <i id="{{'createDLRejectFromInputInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.RejectFrom, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">        
        <div class="col-4">
            <label for="createDLDiscriminantInput">Discriminant:</label>
            <input type="text" id="createDLDiscriminantInput" class="form-control"
                    [(ngModel)]="fileContent.Discriminant" name="createDLDiscriminantInput" #createDLDiscriminantInput="ngModel" [maxlength]="12">
            <div *ngIf="createDLDiscriminantInput.invalid && (createDLDiscriminantInput.dirty || createDLDiscriminantInput.touched)"
                    class="alert alert-danger">     
            </div>
        </div>
        <div class="col-4">            
            <label for="createDLModeratorsInput">Moderators :</label>
            <div *ngFor="let moderator of fileContent.Moderators; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createDLModeratorsInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Moderators[i]" name="{{'createDLModeratorsInput' + i}}">
                    <i id="createDLModeratorsInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.Moderators)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createDLModeratorsInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Moderators[i]" name="{{'createDLModeratorsInput' + i}}">
                    <i id="{{'createDLModeratorsInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.Moderators, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>    
        <div class="col-2">
            <label for="createDLHiddenInput">Hidden:</label>
            <div id="createDLHiddenInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLHiddenInputOpt1" name="createDLHiddenInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Hidden" #createDLHiddenInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLHiddenInputOpt2" name="createDLHiddenInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Hidden" #createDLHiddenInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>  
        <div class="col-2">
            <label for="createDLHiddenFromNet">Hide From Internet:</label>
            <div id="createDLHiddenFromNet">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLHiddenFromNetOpt1" name="createDLHiddenFromNet" class="form-control" [value]="true" [(ngModel)]="fileContent.HideInternet" #createDLHiddenInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createDLHiddenFromNetOpt2" name="createDLHiddenFromNet" class="form-control" [value]="false" [(ngModel)]="fileContent.HideInternet" #createDLHiddenInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>                              
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">                
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.Name }}</b> - 
            {{ listedFileContent.DLType }} - 
            {{ listedFileContent.Discriminant }} - 
            {{ listedFileContent.Owner }} <span *ngIf="listedFileContent.OwnerDisplayName">({{ listedFileContent.OwnerDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div class="col-4">
                    <b>Owner:</b> {{ listedFileContent.Owner }} <span *ngIf="listedFileContent.OwnerDisplayName">({{ listedFileContent.OwnerDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Owner SendAs:</b> {{ booleanToStringConverterImport(listedFileContent.OwnerSendAs, 'No', 'Yes') }}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Members:</b>
                    <div *ngFor="let member of listedFileContent.Members">
                        {{ member }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Owners:</b>
                    <div *ngFor="let additionalOwner of listedFileContent.AdditionalOwners">
                        {{ additionalOwner }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Owners SendAs:</b> {{ booleanToStringConverterImport(listedFileContent.AdditionalOwnersSendAs, 'No', 'Yes') }}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Type:</b> {{ listedFileContent.DLType }}
                </div>
                <div class="col-4">
                    <b>Primary SMTP:</b> {{ listedFileContent.PrimarySmtp }}
                </div>                                
                <div class="col-4">
                    <b>Primary SMTP Domain:</b> {{ listedFileContent.PrimarySmtpDomain }}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Mail Addresses:</b>
                    <div *ngFor="let mail of listedFileContent.MailAddresses">
                        {{ mail }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Accept From:</b>
                    <div *ngFor="let memberAccepted of listedFileContent.AcceptFrom">
                        {{ memberAccepted }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Reject From:</b>
                    <div *ngFor="let memberRejected of listedFileContent.RejectFrom">
                        {{ memberRejected }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Discriminant:</b> {{ listedFileContent.Discriminant }}
                </div>
                <div class="col-4">
                    <b>Moderators:</b>
                    <div *ngFor="let moderator of listedFileContent.Moderators">
                        {{ moderator }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Hidden:</b> {{ booleanToStringConverterImport(listedFileContent.Hidden, 'No', 'Yes') }}
                </div>                           
            </div>      
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>