
<div class="col-12">
    <div class="row"> 
        <span style="font-size:large">Are you sure you want to delete <span style="color: red">{{customerProfile.Company}}</span>'s Customer Profile? </span>       
    </div>
    <br>
    <br>
    <div class="row">
        <div class="col-1 offset-2">
            <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary" (click)="deleteCustomerProfile()">Delete</button>
        </div>
        <div class="col-1 offset-4">
            <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary" (click)="closeDialog()">Cancel</button>
        </div>
    </div>
</div>