import { Component, Input } from '@angular/core';

@Component({
    selector: 'MultiValueInputFields',
    templateUrl: './multiValueInputFields.component.html',
    styleUrls: ['../../../app.component.css']
})

export class MultiValueInputFieldsComponent{
    @Input() label: string;
    @Input() pattern: string;
    @Input() inputsArray: Array<String>;
    @Input() isRequired: boolean;

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }
}