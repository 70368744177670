import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { AddOktaUserToGroupFileContent, AddOktaUserToGroupRequest } from '../../../Models/FileContents';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { removeArrayEmptyValues } from '../../../Helpers/utils';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './addOktaB2BUserToGroup.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddOktaB2BUserToGroupComponent{
    public fileContentsList = new Array<AddOktaUserToGroupFileContent>();
    public fileContent = new AddOktaUserToGroupFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public groupIdOrMailPattern: string = '((' + inputValidationHelper.groupIdFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private router: Router, private snackBar : MatSnackBar, private dialog : MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<AddOktaUserToGroupFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new AddOktaUserToGroupFileContent();
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    addFileContentToList(){
        var tempObject = new AddOktaUserToGroupFileContent(this.fileContent);
        tempObject.OktaUserNames = removeArrayEmptyValues(tempObject.OktaUserNames);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }

        var data = new AddOktaUserToGroupRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/addOktaB2BUserToGroup')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],                  
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            }, 
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
            }
        );
    }
}