import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './SetOutlookPlaces.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class SetOutlookPlacesComponent {
    
    
    

    public fileContent = {
        GroupID: "",
        Capacity: 1,
        FloorNumber: 0,
        FloorLabel: "",
        Street: "",
        PostalCode: null,
        City: "",
        State: "",
        CountryOrRegion: "",
        Building: "",
        Tags: "",
        IsWheelChairAccessible: false,
        AudioDeviceName: "",
        VideoDeviceName: "",
        TicketID: "",
        GroupIDDisplayName: "",
        GroupIDDisplayNameError: ""
    };
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest) {
    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    sendRequest() {
        var data = {
            requests: [this.fileContent],
            localIT: this.selectedLocalIT,
            TicketID: this.fileContent.TicketID
        };

        this.requestContainerService.sendProductRequest(data, '/setOutlookPlaces')
            .subscribe(response => {
                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}