<br/>
<div class="row">
    <div class="col-xs-12 col-lg-12">

        <h2>Licenses report</h2>
        <div class="list-group">
            <div *ngFor="let license of assignableLicenses; let n = index" class="list-group-item">
                <span class="list-group-item-heading">
                    {{license.Name}}
                </span>
                <p class="list-group-item-text">
                    <span [class]="license.Counter < 1 ? 'text-danger' : 'text-primary'">{{ license.Counter < 1 ? 'Not available, provisioning in process' : license.Counter + ' license(s) available' }}</span>
                </p>
            </div>
        </div>
    </div>
</div>