<h4>Create Meraki Network:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="SiteName">Site name:</label>
            <div style="display:flex">
                <input type="text" id="SiteName" name="SiteName" class="form-control" [(ngModel)]="fileContent.SiteName"
                    required #SiteName="ngModel" />
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="OrgID">Entity OrgID:</label>
            <div style="display:flex">
                <input type="text" id="OrgID" name="OrgID" class="form-control" [(ngModel)]="fileContent.OrgID" required
                    #OrgID="ngModel" />
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="ProductTypes">Product Types:</label>
            <div style="display:flex">
                <mat-select id="ProductTypes" class="form-control" name="ProductTypes" [(ngModel)]="fileContent.ProductTypes" #ProductTypes="ngModel" required multiple>
                    <mat-option *ngFor="let producttype of productTypes"
                        [value]="producttype">{{producttype}}</mat-option>
                </mat-select>
            </div>

        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="Notes">Notes:</label>
            <input type="text" id="Notes" name="Notes" class="form-control" [(ngModel)]="fileContent.Notes"
                #Notes="ngModel">
        </div>
        <div class="col-4">
            <label for="CopyFromNetworkID">ID of Meraki network to copy:</label>
            <input type="text" id="CopyFromNetworkID" name="CopyFromNetworkID" class="form-control"
                [(ngModel)]="fileContent.CopyFromNetworkID" #Notes="ngModel">
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>