import { booleanToStringConverter, dateToFormatedLocaleString } from "../../Helpers/utils";

export class GetMailboxInfoDetails{
    GroupID: string;
    MailboxInformation: MailboxInformation = new MailboxInformation();
    RecipientInformation: RecipientInformation = new RecipientInformation();
    MobileDeviceInformation: MobileDeviceInformation = new MobileDeviceInformation();
    PermissionsInformation: PermissionsInformation = new PermissionsInformation();
    GroupMembership: Array<DistributionList> = new Array<DistributionList>();

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.GroupID = requestItemOutput.GroupID;
            this.MailboxInformation = requestItemOutput.Mailbox ? new MailboxInformation(requestItemOutput.Mailbox, requestItemOutput.MailboxStatistics) : new MailboxInformation();
            this.RecipientInformation = requestItemOutput.Recipient ? new RecipientInformation(requestItemOutput.Recipient, requestItemOutput.Mailbox) : new RecipientInformation();
            this.MobileDeviceInformation = requestItemOutput.CASMailbox ? new MobileDeviceInformation(requestItemOutput.CASMailbox) : new MobileDeviceInformation();
            this.PermissionsInformation = requestItemOutput ? new PermissionsInformation(requestItemOutput) : new PermissionsInformation();
            
            if(Array.isArray(requestItemOutput.DistributionLists)){
                requestItemOutput.DistributionLists.forEach(distributionList => {
                    var formatedDistributionList:DistributionList = new DistributionList();
                    formatedDistributionList.DisplayName = distributionList.DisplayName ? distributionList.DisplayName : distributionList.displayName;
                    formatedDistributionList.MailEnabled = booleanToStringConverter(typeof distributionList.MailEnabled == 'undefined' ? distributionList.mailEnabled : distributionList.MailEnabled);
                    formatedDistributionList.SecurityEnabled = typeof distributionList.securityEnabled == 'undefined' ? "" : booleanToStringConverter(distributionList.securityEnabled);
                    formatedDistributionList.GroupType = distributionList.groupTypes ? distributionList.groupTypes : distributionList.ObjectType ? distributionList.ObjectType : "";

                    this.GroupMembership.push(formatedDistributionList);
                });
            }else if (requestItemOutput.DistributionLists){
                var formatedDistributionList:DistributionList = new DistributionList();
                formatedDistributionList.DisplayName = requestItemOutput.DistributionLists.DisplayName ? requestItemOutput.DistributionLists.DisplayName : requestItemOutput.DistributionLists.displayName;
                formatedDistributionList.MailEnabled = booleanToStringConverter(typeof requestItemOutput.DistributionLists.MailEnabled == 'undefined' ? requestItemOutput.DistributionLists.mailEnabled : requestItemOutput.DistributionLists.MailEnabled);
                formatedDistributionList.SecurityEnabled = typeof requestItemOutput.DistributionLists.securityEnabled == 'undefined' ? "" : booleanToStringConverter(requestItemOutput.DistributionLists.securityEnabled);
                formatedDistributionList.GroupType = requestItemOutput.DistributionLists.groupTypes ? requestItemOutput.DistributionLists.groupTypes : requestItemOutput.DistributionLists.ObjectType ? requestItemOutput.DistributionLists.ObjectType : "";

                this.GroupMembership.push(formatedDistributionList);
            }
        }
    }
}

export class MailboxInformation{
    DisplayName: string = "";
    RemoteRecipientType: string = "";
    RecipientTypeDetails: string = "";
    Alias: string = "";
    PrimarySmtpAddress: string = "";
    EmailAddresses: Array<string> = new Array<string>();
    LegacyExchangeDN: string = "";
    ExchangeGuid: string = "";
    ArchiveGuid: string = "";
    ArchiveName: Array<string> = new Array<string>();
    ArchiveStatus: string = "";
    ArchiveState: string = "";
    ForwardingSMTPAddress: Array<string> = new Array<string>();
    ForwardingAddress: Array<string> = new Array<string>();
    WhenMailboxCreated: string = "";
    SIPAddress: string = "";
    WindowsLiveID: string = "";
    UsageLocation: string = "";
    Hidden: string = "";
    MailboxType: string = "";
    MailboxQuota: string = "";
    ProhibitSendQuota: string = "";
    WarningQuota: string = "";
    TotalItemSize: string = "";
    ItemCount: string = "";
    ForwardFromMailbox: string = "";
    HideReason: string = "";
    LitigationHoldEnabled: string = "";
    LitigationHoldDuration: string = "";
    LitigationHoldDurationYears: string = "";
    LitigationHoldDate: string = "";
    LitigationHoldOwner: string = "";

    constructor(mailboxInfo?, mailboxStatistics?){
        if(mailboxInfo){
            this.DisplayName = mailboxInfo.DisplayName ? mailboxInfo.DisplayName : "";
            this.RemoteRecipientType = mailboxInfo.RemoteRecipientType ? mailboxInfo.RemoteRecipientType : "";
            this.RecipientTypeDetails = mailboxInfo.RecipientTypeDetails ? mailboxInfo.RecipientTypeDetails : "";
            this.Alias = mailboxInfo.Alias ? mailboxInfo.Alias : "";
            this.PrimarySmtpAddress = mailboxInfo.PrimarySmtpAddress ? mailboxInfo.PrimarySmtpAddress : "";
            this.EmailAddresses = mailboxInfo.EmailAddresses ? mailboxInfo.EmailAddresses : new Array<string>();
            this.LegacyExchangeDN = mailboxInfo.LegacyExchangeDN ? mailboxInfo.LegacyExchangeDN : "";
            this.ExchangeGuid = mailboxInfo.ExchangeGuid ? mailboxInfo.ExchangeGuid : "";
            this.ArchiveGuid = mailboxInfo.ArchiveGuid ? mailboxInfo.ArchiveGuid : "";
            this.LitigationHoldEnabled = booleanToStringConverter(mailboxInfo.LitigationHoldEnabled);
            this.LitigationHoldDuration = mailboxInfo.LitigationHoldDuration;
            this.LitigationHoldDate = mailboxInfo.LitigationHoldDate;
            this.LitigationHoldOwner = mailboxInfo.LitigationHoldOwner;

            //Ask if it is normal that this is an array
            this.ArchiveName = mailboxInfo.ArchiveName ? mailboxInfo.ArchiveName : new Array<string>();

            this.ArchiveStatus = mailboxInfo.ArchiveStatus ? mailboxInfo.ArchiveStatus : "";
            this.ArchiveState = mailboxInfo.ArchiveState ? mailboxInfo.ArchiveState : "";

            if(Array.isArray(mailboxInfo.ForwardingSMTPAddress)){
                this.ForwardingSMTPAddress = mailboxInfo.ForwardingSMTPAddress;
            }else if (mailboxInfo.ForwardingSMTPAddress){
                this.ForwardingSMTPAddress.push(mailboxInfo.ForwardingSMTPAddress);
            }

            if(Array.isArray(mailboxInfo.ForwardingAddress)){
                this.ForwardingAddress = mailboxInfo.ForwardingAddress;
            }else if (mailboxInfo.ForwardingAddress){
                this.ForwardingAddress.push(mailboxInfo.ForwardingAddress);
            }

            //Format de date moisi
            this.WhenMailboxCreated = mailboxInfo.WhenCreated ? dateToFormatedLocaleString(mailboxInfo.WhenCreated) : "";

            this.SIPAddress = mailboxInfo.SIPAddress ? mailboxInfo.SIPAddress : "";
            this.WindowsLiveID = mailboxInfo.WindowsLiveID ? mailboxInfo.WindowsLiveID : "";
            this.UsageLocation = mailboxInfo.UsageLocation ? mailboxInfo.UsageLocation : "";
            this.Hidden = booleanToStringConverter(mailboxInfo.HiddenFromAddressListsEnabled);
            this.MailboxType = mailboxInfo.CustomAttribute11 ? mailboxInfo.CustomAttribute11 : "";
            this.MailboxQuota = mailboxInfo.ProhibitSendReceiveQuota ? mailboxInfo.ProhibitSendReceiveQuota : "";
            this.ProhibitSendQuota = mailboxInfo.ProhibitSendQuota ? mailboxInfo.ProhibitSendQuota : "";
            this.WarningQuota = mailboxInfo.IssueWarningQuota ? mailboxInfo.IssueWarningQuota : "";
            
            //Where to find this information?
            this.ForwardFromMailbox = mailboxInfo.ForwardFromMailbox ? mailboxInfo.ForwardFromMailbox : "";
            this.HideReason = mailboxInfo.HideReason ? mailboxInfo.HideReason : "";

            if(mailboxStatistics){
                this.TotalItemSize = mailboxStatistics.TotalItemSize ? mailboxStatistics.TotalItemSize : "";    
                this.ItemCount = mailboxStatistics.ItemCount ? mailboxStatistics.ItemCount : "";
            }
        }
    }
}

export class RecipientInformation{
    FirstName: string = "";
    LastName: string = "";
    Company: string = "";
    Title: string = "";
    Location: string = "";
    Department: string = "";
    Trigram: string = "";
    BusinessPhone: string = "";
    HomePhone: string = "";
    Mobile: string = "";
    IPPhone: string = "";
    Fax: string = "";
    Manager: string = "";

    constructor(recipientInfo?, mailboxInfo?){
        if(recipientInfo){
            this.FirstName = recipientInfo.FirstName ? recipientInfo.FirstName : "";
            this.LastName = recipientInfo.LastName ? recipientInfo.LastName : "";
            this.Company = recipientInfo.Company ? recipientInfo.Company : "";
            this.Title = recipientInfo.Title ? recipientInfo.Title : "";
            this.Location = recipientInfo.Office ? recipientInfo.Office : "";
            this.Department = recipientInfo.Department ? recipientInfo.Department : "";
           
            this.BusinessPhone = recipientInfo.Phone ? recipientInfo.Phone : "";
            this.HomePhone = recipientInfo.HomePhone ? recipientInfo.HomePhone : "";
            this.Mobile = recipientInfo.MobilePhone ? recipientInfo.MobilePhone : "";            
            this.Fax = recipientInfo.Fax ? recipientInfo.Fax : "";
            this.Manager = recipientInfo.Manager ? recipientInfo.Manager : "";
            
            if(mailboxInfo){
                this.Trigram = mailboxInfo.CustomAttribute9 ? mailboxInfo.CustomAttribute9 : "";
                this.IPPhone = mailboxInfo.CustomAttribute3 ? mailboxInfo.CustomAttribute3 : "";
            }            
        }
    }
}

export class MobileDeviceInformation{
    ActiveSyncEnabled: string = "";
    EASPolicy: string = "";
    HasDevicePartnership: string = "";
    AllowedDevices: Array<string> = new Array<string>();

    constructor(mobileDeviceInfo?){
        if(mobileDeviceInfo){
            this.ActiveSyncEnabled = booleanToStringConverter(mobileDeviceInfo.ActiveSyncEnabled);
            this.EASPolicy = mobileDeviceInfo.ActiveSyncMailboxPolicy ? mobileDeviceInfo.ActiveSyncMailboxPolicy : "";
            this.HasDevicePartnership = booleanToStringConverter(mobileDeviceInfo.HasActiveSyncDevicePartnership);
            this.AllowedDevices = mobileDeviceInfo.ActiveSyncAllowedDeviceIDs ? mobileDeviceInfo.ActiveSyncAllowedDeviceIDs : new Array<string>();
        }
    }
}

export class PermissionsInformation{
    FullAccessPermissionsMailboxes: Array<string> = new Array<string>();
    SendAsPermissionsMailboxes: Array<string> = new Array<string>();
    SendOnBehalfPermissionsMailboxes: Array<string> = new Array<string>();

    constructor(requestItemOutput?){
        if(requestItemOutput){            
            if(Array.isArray(requestItemOutput.SendOnBehalfPermissions)){
                this.SendOnBehalfPermissionsMailboxes = requestItemOutput.SendOnBehalfPermissions;
            }else if(requestItemOutput.SendOnBehalfPermissions){
                this.SendOnBehalfPermissionsMailboxes.push(requestItemOutput.SendOnBehalfPermissions);
            }

            if(Array.isArray(requestItemOutput.SendAsPermissions)){
                requestItemOutput.SendAsPermissions.forEach(sendAsPermission => {
                    if(sendAsPermission.Trustee){
                        this.SendAsPermissionsMailboxes.push(sendAsPermission.Trustee);
                    }                    
                });
            }else if(requestItemOutput.SendAsPermissions && requestItemOutput.SendAsPermissions.Trustee){
                this.SendAsPermissionsMailboxes.push(requestItemOutput.SendAsPermissions.Trustee);
            }

            if(Array.isArray(requestItemOutput.FullAccessPermissions)){
                requestItemOutput.FullAccessPermissions.forEach(fullAccessPermission => {
                    if(fullAccessPermission.FullAccessUser){
                        this.FullAccessPermissionsMailboxes.push(fullAccessPermission.FullAccessUser);
                    }                    
                });
            }else if(requestItemOutput.FullAccessPermissions && requestItemOutput.FullAccessPermissions.FullAccessUser){
                this.FullAccessPermissionsMailboxes.push(requestItemOutput.FullAccessPermissions.FullAccessUser);
            }
        }
    }
}

export class DistributionList{
    DisplayName: string;
    GroupType: string;
    MailEnabled: string;
    SecurityEnabled: string;
}