import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { guessingProductName } from 'app/Helpers/utils';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-help-link',
  templateUrl: './product-help-link.component.html',
  styleUrls: ['./product-help-link.component.css']
})
export class ProductHelpLinkComponent implements OnInit, OnDestroy {
  @Input() productName?: string;
  subscription: Subscription;
  constructor( private router: Router ) { 
    if(!this.productName ){
      this.productName = guessingProductName();
      this.subscription = this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.productName = guessingProductName();  
        }
      });
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
  }
  
  goToHelp() {
    window.open("https://engie.sharepoint.com/sites/ensemblesupport/knowledgebase/Pages/" + this.productName + ".aspx", "_blank");
  }

}
