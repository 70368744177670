<div class="">
    <div class="row">
        <div class="col-8">
            <h2>{{ requestHistoryDetails.ProductName }} Details {{ requestHistoryDetails.Target }}:</h2>
        </div>
        <div class="col-3">
            <button *ngIf="canBeRetryed()" class="btn btn-primary" style="float: right;" type="button"
                (click)="retry()">Retry</button>
            <button
                *ngIf="(requestHistoryDetails.ProductName == 'Get-User-Info' || requestHistoryDetails.ProductName == 'Get-Mailbox-Info' || requestHistoryDetails.ProductName == 'Get-IdentityPersonalData' || requestHistoryDetails.ProductName == 'Get-DistributionList-Info') && (requestHistoryDetails.Status == 'Success' || requestHistoryDetails.Status == 'Warning')"
                class="btn btn-primary" style="float: right;" type="button" (click)="exportToPdfImport()">PDF
                Export</button>
            <button
                *ngIf="requestHistoryDetails.ProductName == 'Public-Dns' && requestHistoryDetails.Status == 'Failure' && selectedLocalIT==requestItem.LocalIT"
                class="btn btn-primary" style="float: right;" type="button" (click)="modifyRecord()">Modify</button>
            <button *ngIf="showModifyButton()" class="btn btn-primary" style="float: right;" type="button"
                (click)="modifyRecord()">Modify</button>
        </div>
        <div class="col-1">
            <i style="float: right;" class="material-icons md-36 primaryBlue pointer"
                (click)="closeDialog()">highlight_off</i>
        </div>

    </div>
</div>



<!-- 
    Display Request Information:

    Request ID
    Requestor
    Status
    Targeted User (GID)
    Creation Date
    Modification Date
-->
<mat-dialog-content>
    <br />
    <h4>Request Information:</h4>
    <div class="">
        <div class="row">
            <div class="col-4">
                <b>Request GUID:</b> {{ requestHistoryDetails.GUID }}
            </div>
            <div class="col-4">
                <b>Requestor:</b> {{ requestHistoryDetails.Requestor }}
            </div>
            <div class="col-4">
                <b>Status:</b> <span
                    [ngStyle]="{'color': getStatusColorImport(requestHistoryDetails.Status), 'font-weight':'bold'}">{{
                    requestHistoryDetails.Status }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Target:</b> {{ requestHistoryDetails.Target }}
            </div>
            <div class="col-4">
                <b>Creation Date:</b> {{ requestHistoryDetails.CreatedOn }}
            </div>
            <div class="col-4">
                <b>Last Modification:</b> {{ requestHistoryDetails.ModifedOn }}
            </div>
        </div>
        <div class="row" *ngIf="requestHistoryDetails.TicketID">
            <div class="col-4">
                <b>Ticket ID:</b> {{ requestHistoryDetails.TicketID }}
            </div>
        </div>
    </div>
    <br />
    <RequestDetailsParameters [productName]="requestHistoryDetails.ProductName"
        [parameters]="requestHistoryDetails.Parameters"></RequestDetailsParameters>
    <br />
    <div class="">
        <div class="row" style="cursor:pointer;" (click)="requestHistoryCollapsed=!requestHistoryCollapsed">
            <h4>Request History</h4><i *ngIf="requestHistoryCollapsed" style="float: right;margin-bottom:5px;"
                class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!requestHistoryCollapsed"
                style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
        </div>
        <div *ngIf="!requestHistoryCollapsed">
            <div class="row" *ngFor="let record of requestHistoryDetails.History">
                <div class="col-3" style="padding-right:0px;">
                    <b>Record Date:</b> {{ record.Date }}
                </div>
                <div class="col-5"><b>Message:</b> {{ record.Message }}
                </div>
                <style>
                    .col-5 {
                        white-space: pre-line;
                    }
                </style>
                <div class="col-4">
                    <b>Status:</b> <span
                        [ngStyle]="{'color': getStatusColorImport(record.Status), 'font-weight':'bold'}">{{
                        record.Status }}</span>
                </div>
            </div>
        </div>

    </div>

    <br />
    <br />

    <!-- REQUESTS HISTORY DETAILS PRODUC SPECIFIC AREA -->
    <ng-container *ngIf="requestHistoryDetails.Status == 'Success' || requestHistoryDetails.Status == 'Warning'">
        <GetUserInfoHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-User-Info'"
            [getUserInfoDetails]="requestHistoryDetails.GetUserInfoDetails"></GetUserInfoHistoryDetails>
        <GetIdentityPersonalDataHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-IdentityPersonalData'"
            [getIdentityPersonalDataDetails]="requestHistoryDetails.GetIdentityPersonalDataDetails">
        </GetIdentityPersonalDataHistoryDetails>
        <GetDeletedUserInfoHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-Deleted-User-Info'"
            [getDeletedUserInfoDetails]="requestHistoryDetails.GetDeletedUserInfoDetails">
        </GetDeletedUserInfoHistoryDetails>
        <GetDistributionListMembersHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Get-DistributionList-Members' && requestHistoryDetails.GetDistributionListMembersDetails.Members.length > 0"
            [getDistributionListMembersDetails]="requestHistoryDetails.GetDistributionListMembersDetails">
        </GetDistributionListMembersHistoryDetails>
        <GetMailboxInfoHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-Mailbox-Info'"
            [getMailboxInfoDetails]="requestHistoryDetails.GetMailboxInfoDetails"></GetMailboxInfoHistoryDetails>
        <DistributionListOwnerHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Change-DistributionList-Owner' || requestHistoryDetails.ProductName == 'Add-DistributionList-Owner'"
            [distributionListDetails]="requestHistoryDetails.DistributionListOwnerDetails">
        </DistributionListOwnerHistoryDetails>
        <RemoveDistributionListOwnerHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Remove-DistributionList-Owner'"
            [distributionListDetails]="requestHistoryDetails.RemoveDistributionListOwnerDetails">
        </RemoveDistributionListOwnerHistoryDetails>
        <DistributionListMembersHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Add-DistributionList-Members' || requestHistoryDetails.ProductName == 'Set-DistributionList-Members' || requestHistoryDetails.ProductName == 'Remove-DistributionList-Members'"
            [distributionListDetails]="requestHistoryDetails.DistributionListMembersDetails">
        </DistributionListMembersHistoryDetails>
        <CreatePersonalMailboxHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Create-SharedMailbox'"
            [createPersonalMailboxDetails]="requestHistoryDetails.CreatePersonalMailboxDetails">
        </CreatePersonalMailboxHistoryDetails>
        <MailboxPermissionHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Add-Mailbox-FullAccess' 
                                            || requestHistoryDetails.ProductName == 'Remove-Mailbox-FullAccess' 
                                            || requestHistoryDetails.ProductName == 'Add-Mailbox-SendAs' 
                                            || requestHistoryDetails.ProductName == 'Remove-Mailbox-SendAs'
                                            || requestHistoryDetails.ProductName == 'Add-Mailbox-SendOnBehalf' 
                                            || requestHistoryDetails.ProductName == 'Remove-Mailbox-SendOnBehalf'"
            [mailboxPermissionDetails]="requestHistoryDetails.MailboxPermissionDetails">
        </MailboxPermissionHistoryDetails>
        <EnableLitigationHoldHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Enable-LitigationHold'"
            [productCustomHistoryDetails]="requestHistoryDetails.EnableLitigationHoldDetails">
        </EnableLitigationHoldHistoryDetails>
        <CreateDistributionListHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Create-DistributionList'"
            [productCustomHistoryDetails]="requestHistoryDetails.CreateDistributionListDetails">
        </CreateDistributionListHistoryDetails>
        <EnableArchiveOnlineHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Enable-ArchiveOnline'"
            [productCustomHistoryDetails]="requestHistoryDetails.EnableArchiveOnlineDetails">
        </EnableArchiveOnlineHistoryDetails>
        <ChangeMailboxManagerHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Change-Mailbox-Manager'"
            [productCustomHistoryDetails]="requestHistoryDetails.ChangeMailboxManagerDetails"
            (CloseDialog)="closeDialog()"></ChangeMailboxManagerHistoryDetails>
        <DeleteO365ObjectHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Delete-O365Object' || requestHistoryDetails.ProductName == 'Undelete-O365Object'"
            [productCustomHistoryDetails]="requestHistoryDetails.DeleteO365ObjectDetails">
        </DeleteO365ObjectHistoryDetails>
        <CreateMEUHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Create-MEU'"
            [productCustomHistoryDetails]="requestHistoryDetails.CreateMEUDetails"></CreateMEUHistoryDetails>
        <DistributionListMailAddressesHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Add-DistributionList-MailAddresses'
                                                        || requestHistoryDetails.ProductName == 'Remove-DistributionList-MailAddresses'"
            [productCustomHistoryDetails]="requestHistoryDetails.DistributionListMailAddressesDetails">
        </DistributionListMailAddressesHistoryDetails>
        <DistributionListDeliveryRestrictionHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Add-DistributionList-DeliveryRestriction'
                                                        || requestHistoryDetails.ProductName == 'Remove-DistributionList-DeliveryRestriction'"
            [productCustomHistoryDetails]="requestHistoryDetails.DistributionListDeliveryRestrictionDetails">
        </DistributionListDeliveryRestrictionHistoryDetails>
        <MailboxMailAddressesHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Add-Mailbox-MailAddresses' || requestHistoryDetails.ProductName == 'Remove-Mailbox-MailAddresses'"
            [productCustomHistoryDetails]="requestHistoryDetails.MailboxMailAddressesDetails">
        </MailboxMailAddressesHistoryDetails>
        <DistributionListPrimarySMTPDetailsHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Change-DistributionList-PrimarySMTP'"
            [productCustomHistoryDetails]="requestHistoryDetails.ChangeDistributionListPrimarySMTPDetails">
        </DistributionListPrimarySMTPDetailsHistoryDetails>
        <DistributionListSendAsDetailsHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Remove-DistributionList-SendAs'"
            [productCustomHistoryDetails]="requestHistoryDetails.DistributionListSendAsDetails">
        </DistributionListSendAsDetailsHistoryDetails>
        <GetOktaAssignedAppHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-Okta-AssignedApp'"
            [GetOktaAssignedAppDetails]="requestHistoryDetails.GetOktaAssignedAppDetails">
        </GetOktaAssignedAppHistoryDetails>
        <GetOktaGroupMembersHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Get-Okta-Group-Members' || requestHistoryDetails.ProductName == 'Get-OktaB2B-Group-Members'"
            [GetOktaGroupMembersDetails]="requestHistoryDetails.GetOktaGroupMembersDetails">
        </GetOktaGroupMembersHistoryDetails>
        <ChangeMailboxPrimarySMTPHistoryDetails
            *ngIf="requestHistoryDetails.ProductName == 'Change-Mailbox-PrimarySMTP'"
            [changeMailboxPrimarySMTPDetails]="requestHistoryDetails.ChangeMailboxPrimarySMTPDetails">
        </ChangeMailboxPrimarySMTPHistoryDetails>
        <AddIntuneUserHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Add-Intune-User'"
            [productCustomHistoryDetails]="requestHistoryDetails.AddIntuneUserDetails"></AddIntuneUserHistoryDetails>
        <AddAADGroupMemberHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Add-AAD-GroupMember'"
            [productCustomHistoryDetails]="requestHistoryDetails.AddAADGroupMemberDetails">
        </AddAADGroupMemberHistoryDetails>
        <GetDistributionListInfoHistoryDetails *ngIf="requestHistoryDetails.ProductName == 'Get-DistributionList-Info'"
            [getDistributionListInfoDetails]="requestHistoryDetails.GetDistributionListInfoDetails">
        </GetDistributionListInfoHistoryDetails>
        <CreateHistoryDetails
            *ngIf="requestItem.Output && (requestHistoryDetails.ProductName == 'Create-Meraki-Network' || requestHistoryDetails.ProductName == 'Set-Outlook-Places' || requestHistoryDetails.ProductName == 'Create-Guest-User' || requestHistoryDetails.ProductName == 'Update-Guest-User' || requestHistoryDetails.ProductName == 'Enable-Teams-CAPPhone' || requestHistoryDetails.ProductName == 'Delete-AzureADGroup' || requestHistoryDetails.ProductName == 'Undelete-AzureADGroup' || requestHistoryDetails.ProductName == 'Create-ApplicationMailbox' || requestHistoryDetails.ProductName == 'Create-ResourceMailbox' || requestHistoryDetails.ProductName == 'Create-RoomMailbox' || requestHistoryDetails.ProductName == 'Create-AnonymousMailbox' || requestHistoryDetails.ProductName == 'Block-User' || requestHistoryDetails.ProductName == 'Get-BitlockerKey'  || requestHistoryDetails.ProductName == 'Add-Mailbox-Delegation' || requestHistoryDetails.ProductName == 'Remove-Mailbox-Delegation' || requestHistoryDetails.ProductName == 'Enable-Teams-RoomMailbox' || requestHistoryDetails.ProductName == 'Create-AAD-Group' || requestHistoryDetails.ProductName == 'Get-LocalAdminPassword' || requestHistoryDetails.ProductName == 'Create-AdminMailbox' || requestHistoryDetails.ProductName == 'Create-PersonalMailbox')"
            [createDetails]="requestHistoryDetails.CreateDetails"></CreateHistoryDetails>
        <div
            *ngIf="requestHistoryDetails.ProductName == 'Change-Mailbox-Language' && requestHistoryDetails.Status == 'Success'">
            The language {{ requestHistoryDetails.Parameters ? requestHistoryDetails.Parameters.LANGUAGE : '' }} was
            successfully configured on the mailbox
            {{ requestHistoryDetails.Parameters ? requestHistoryDetails.Parameters.GROUPID : '' }}.
        </div>

        <div
            *ngIf="requestHistoryDetails.ProductName == 'Disable-LitigationHold' && requestHistoryDetails.Status == 'Success'">
            Successfully disabled LitigationHold for Mailbox
            {{ requestHistoryDetails.Parameters ? requestHistoryDetails.Parameters.GROUPID : '' }}.
        </div>

        <div
            *ngIf="(requestHistoryDetails.ProductName == 'Create-Okta-CloudAccount' || requestHistoryDetails.ProductName == 'Create-Okta-TechnicalAccount') && (requestHistoryDetails.Status == 'Success' || requestHistoryDetails.Status == 'Warning')">
            Okta Cloud Account
            {{ requestHistoryDetails.CreateOktaCloudAccountDetails ?
            requestHistoryDetails.CreateOktaCloudAccountDetails.GroupID : '' }}
            successfully created with the following password:
            {{ requestHistoryDetails.CreateOktaCloudAccountDetails ?
            requestHistoryDetails.CreateOktaCloudAccountDetails.OktaCloudAccountPassword : '' }}
        </div>

        <div
            *ngIf="(requestHistoryDetails.ProductName == 'Reset-Okta-TechnicalAccount-Password' || requestHistoryDetails.ProductName == 'Activate-Okta-TechnicalAccount') && requestHistoryDetails.Status == 'Success'">
            Okta Technical Account
            {{ requestHistoryDetails.Output ?
            requestHistoryDetails.Output.GroupID : '' }}
            password successfully reseted, new temp password:
            {{ requestHistoryDetails.Output ?
                requestHistoryDetails.Output.Password : '' }}
        </div>

        <div
            *ngIf="requestHistoryDetails.ProductName == 'Hide-DistributionList' && requestHistoryDetails.Status == 'Success'">
            The DistributionList was successfully hidden from the GAL. Please note that this may take up to 24 hours to
            become visible in Outlook.
        </div>

        <div
            *ngIf="requestHistoryDetails.ProductName == 'UnHide-DistributionList' && requestHistoryDetails.Status == 'Success'">
            The DistributionList was successfully imported to the GAL. Please note that this may take up to 24 hours to
            become visible in Outlook.
        </div>

        <div *ngIf="requestHistoryDetails.ProductName == 'Hide-Mailbox' && requestHistoryDetails.Status == 'Success'">
            The Mailbox was successfully hidden from the GAL. Please note that this may take up to 24 hours to become
            visible in Outlook
        </div>

        <div *ngIf="requestHistoryDetails.ProductName == 'UnHide-Mailbox' && requestHistoryDetails.Status == 'Success'">
            The Mailbox was successfully imported to the GAL. Please note that this may take up to 24 hours to become
            visible in Outlook.
        </div>

        <div *ngIf="requestHistoryDetails.ProductName == 'Update-Customer-Profile' && requestHistoryDetails.Status == 'Success'"
            class="row ">
            <table class="table">
                <thead class="thead-default">
                    <tr class="historySection"
                        (click)="isUpdateCustomerProfileDetailsCollapsed=!isUpdateCustomerProfileDetailsCollapsed">
                        <th style="text-align: center;" colspan="3">
                            CUSTOMER PROFILE VALUES (BEFORE/AFTER)
                            <i *ngIf="isUpdateCustomerProfileDetailsCollapsed" style="float: right;"
                                class="material-icons md-36 pointer">chevron_right</i>
                            <i *ngIf="!isUpdateCustomerProfileDetailsCollapsed" style="float: right;"
                                class="material-icons md-36 pointer">expand_more</i>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!isUpdateCustomerProfileDetailsCollapsed">
                    <tr *ngFor="let param of requestHistoryDetails.Output.customerProfilePreviousValues | keyvalue">
                        <th>{{ param.key }}</th>
                        <td>{{ param.value }}</td>
                        <td>{{ requestHistoryDetails.Output.customerProfileNewValues[param.key] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</mat-dialog-content>