<h4>{{ isCreation ? 'Create new' : 'Update' }} Alert Message:</h4>

<!--CREATE/UPDATE NEW LOCAL IT CARD FORM -->
<form #fileContentForm="ngForm">
   
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="localITNameInput">Title:</label>
            <input type="text" id="localITNameInput" class="form-control"
                [(ngModel)]="topBarMessage.Title" name="localITNameInput" required>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-8">
            <label class="required" for="localITDisplayNameInput">Message:</label>
            <input type="text" id="localITDisplayNameInput" class="form-control"
                [(ngModel)]="topBarMessage.Message" name="localITDisplayNameInput" required>
        </div>    
    </div>
    <div class="row form-group">
        <div class="col-4" style="display: inline;">
            Display Options:
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <input type="checkbox" [checked]="topBarMessage.Show" (click)="changeShowStatus()">
            Show Now
        </div>
        <div class="col-4">
            Alert Level:
            <select name="alertlevel" id="alertlevel" [(ngModel)]="topBarMessage.Level">
                <option value="alert-success" class="alert alert-success">Level 1</option>
                <option value="alert-info" class="alert alert-info">Level 2</option>
                <option value="alert-warning" class="alert alert-warning">Level 3</option>
                <option value="alert-danger" class="alert alert-danger">Level 4</option>
            </select>
        </div>
    </div>
    <div *ngIf="!topBarMessage.Show" class="row form-group">
        <div class="col-4" style="display: inline;">
            <input class="form-control" style="width: 50%; float: left;" matInput [matDatepicker]="fromDatePicker" placeholder="From" [value]="topBarMessage.StartDateTime" (dateChange)="fromDateChanged($event.target.value)">
            <mat-datepicker-toggle style="float: left;" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <input type="time" id="startTimeInput" class="form-control" style="width:100px;"
                [(ngModel)]="startTime" name="startTimeInput" (blur)="addStartTimeToDate()">
        </div>
        <div class="col-4" style="display: inline;">
            <input class="form-control" style="width: 50%; float: left;" matInput [matDatepicker]="toDatePicker" placeholder="To" [value]="topBarMessage.EndDateTime" (dateChange)="toDateChanged($event.target.value)">
            <mat-datepicker-toggle style="float: left;" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
            <input type="time" id="endTimeInput" class="form-control" style="width:100px;"
                [(ngModel)]="endTime" name="endTimeInput" (blur)="addEndTimeToDate()">
        </div>   
    </div>

    <div class="col-2 offset-10" style="padding-top:33px" style="display: inline;">
        <button type="submit" class="btn btn-primary" (click)="sendRequest();" [disabled]="fileContentForm.invalid">Save</button>
        <a class="btn btn-primary" routerLink="/administration/topBarMessages" routerLinkActive="active">Cancel</a>
    </div>
</form>