import { Component, Input } from '@angular/core';
import { GetIdentityPersonalDataDetails } from '../../../../Models/History/GetIdentityPersonalDataDetails';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'GetIdentityPersonalDataHistoryDetails',
    templateUrl: './getIdentityPersonalDataHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetIdentityPersonalDataHistoryDetailsComponent{
    Object = Object;
    Array = Array;
    @Input() getIdentityPersonalDataDetails: GetIdentityPersonalDataDetails;

    getStatusColorImport(status:string){
        return getStatusColor(status);
    }
}