<div class='form-group'>
    <label class="required" for="groupIdOrMailInput{{nameSuffix}}">{{label}}:</label>
    <div style="display:flex">
        <input type="text" id="groupIdOrMailInput{{nameSuffix}}" name="groupIdOrMailInput{{nameSuffix}}" class="form-control" [noAdmin]="noAdmin"
                [(ngModel)]="groupID" #groupIdOrMailInput="ngModel"
                required [pattern]="groupIdOrMailPattern">
        <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="checkGroupIDValidity();">search</i>
    </div>
    <small *ngIf="groupIDDisplayName && !groupIdOrMailInput.invalid" id="groupIDDisplayName" class="form-text text-primary">{{ groupIDDisplayName }}</small>
    <small *ngIf="groupIDDisplayNameError && !groupIdOrMailInput.invalid" id="groupIDDisplayNameError" class="form-text text-danger">{{ groupIDDisplayNameError }}</small>
    
    <div *ngIf="groupIdOrMailInput.invalid && (groupIdOrMailInput.dirty || groupIdOrMailInput.touched)"
            class="alert alert-danger">
    
        <div *ngIf="groupIdOrMailInput.errors.required">
            {{label}} is required.
        </div>
        <div *ngIf="groupIdOrMailInput.errors.pattern">
            {{label}} format is invalid.
        </div>               
    </div>    
</div>