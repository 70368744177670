export class GetOktaGroupMembersDetails{
    ProductName: string = '';
    Members = new Array<{C_groupIdentifier: String, firstName: String, lastName: String}>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName ? productName : '';
            if(Array.isArray(requestItemOutput)){
                this.Members = requestItemOutput;
            }else if (requestItemOutput){
                this.Members.push(requestItemOutput);
            }
        }
    }
}