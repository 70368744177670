import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { UserService } from '../../../Services/user.service';

import { CreateMEUFileContent, CreateMEURequest } from '../../../Models/FileContents';

import { removeArrayEmptyValues,booleanToStringConverter } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './createMEU.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateMEUComponent{
    
    
    
    
    public requestContainer:any = {};
    public fileContentsList = new Array<CreateMEUFileContent>();
    public fileContent = new CreateMEUFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    public customerProfileImported:boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private customerProfilesService: CustomerProfilesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog){
        this.clearFileContentForms();
    }

    ngOnInit(){
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    }

    ngOnDestroy(){
        
        
        
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateMEUFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new CreateMEUFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new CreateMEUFileContent(this.fileContent);
        tempObject.MailAddresses = removeArrayEmptyValues(tempObject.MailAddresses);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false){
            this.addFileContentToList();
        }

        //Initiatiate the request data
        var data = new CreateMEURequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/createMEU')
            .subscribe( response => {
                            this.clearPage();

                            //Display a success notification for 2sec
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            //Redirect to request history page
                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            //In case of API call error display a popup with information about the error
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    importCustomerProfile(){
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if(response){
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if(response.PrimarySMTPdomain){
                            this.fileContent.PrimarySmtpDomain = response.PrimarySMTPdomain;
                            document.getElementById("primarySmtpDomainInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(missingVal){
                            this.importDefaultCustomerProfile();
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile(){
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if(response){
                        if(!this.fileContent.PrimarySmtpDomain){   
                            if(response.PrimarySMTPdomain){
                                this.fileContent.PrimarySmtpDomain = response.PrimarySMTPdomain;
                                document.getElementById("primarySmtpDomainInput").style.border = "2px solid #fbc02d";
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                    }                    
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm(){
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        document.getElementById("primarySmtpDomainInput").style.border = "1px solid rgba(0,0,0,.15)";

        if(this.customerProfileImported){
            var groupId = this.fileContent.GroupID;
            if(!inputValidationHelper.checkGroupIdFormat(groupId)){
                this.fileContent.PrimarySmtpDomain = "";
                this.customerProfileImported = false;
            }
        }
    }
}