<h4>Add Mailbox Mail Addresses:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIdOrMailInputField label="Group ID or email address" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIdOrMailInputField>
        </div>
        <div class="col-4">            
            <label class="required" for="mailAdressesInput">Mail Addresses to add:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'mailAdressesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.MailAddresses[i]" name="{{'mailAdressesInput' + i}}" required>
                    <i id="mailAdressesInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'mailAdressesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.MailAddresses[i]" name="{{'mailAdressesInput' + i}}">
                    <i id="{{'mailAdressesInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>      
    </div>
    <div class="row form-group">                
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>


<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            </div>
            <div class="col-4">
                <b>Mail Addresses to add:</b>
                <div *ngFor="let mailAddress of listedFileContent.MailAddresses">
                    {{ mailAddress }}
                </div>
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div>
        </div>
    </div>
</div>