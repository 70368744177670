import { Component, Input } from '@angular/core';
import { DistributionListDeliveryRestrictionDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'DistributionListDeliveryRestrictionHistoryDetails',
    templateUrl: './distributionListDeliveryRestrictionHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DistributionListDeliveryRestrictionHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: DistributionListDeliveryRestrictionDetails;
    productCustomInfoCollapsed: boolean = false;
}