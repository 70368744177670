<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="mailboxPermissionInfoCollapsed=!mailboxPermissionInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    MAILBOX PERMISSION INFORMATION 
                    <i *ngIf="mailboxPermissionInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!mailboxPermissionInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!mailboxPermissionInfoCollapsed">
            <tr>
                <th style="width: 350px;">Mailbox</th>
                <td>{{ mailboxPermissionDetails.Mailbox }}</td>
            </tr>
            <tr>
                <th style="width: 350px;" [ngSwitch]="mailboxPermissionDetails.ProductName">
                    <ng-container *ngSwitchCase="'Add-Mailbox-FullAccess'">
                        Full Access Permissions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Remove-Mailbox-FullAccess'">
                        Remaining Full Access Permissions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Add-Mailbox-SendAs'">
                        Send As Permissions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Remove-Mailbox-SendAs'">
                        Remaining Send As Permissions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Add-Mailbox-SendOnBehalf'">
                        Send On Behalf Permissions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Remove-Mailbox-SendOnBehalf'">
                        Remaining Send On Behalf Permissions
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        Members
                    </ng-container>
                </th>
                <td>
                    <div *ngFor="let member of mailboxPermissionDetails.Trustee | orderby : member">
                        {{ member }}<br />
                    </div>
                </td>                
            </tr>
        </tbody>
    </table>
</div>