import { Component, Input } from '@angular/core';
import { DistributionListPrimarySMTPDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'DistributionListPrimarySMTPDetailsHistoryDetails',
    templateUrl: './distributionListPrimarySMTPHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DistributionListPrimarySMTPDetailsHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: DistributionListPrimarySMTPDetails;
    productCustomInfoCollapsed: boolean = false;
}

