<br />
<h4>Top Bar Messages ({{ length }}):</h4>
<br />
<div class="row">
    <div class="col-4">
        <input *ngIf="config.filtering" placeholder="Filter all columns" [ngTableFiltering]="config.filtering"
            class="form-control" (tableChanged)="onChangeTable(config)" />
    </div>
    <div *ngIf="currentUserInfo.IsAdmin" class="col-3 offset-5" style="text-align: right">
        <a class="btn btn-primary" routerLink="/administration/manageTopBarMessage" routerLinkActive="active">Create New
            Top Bar Message </a>
    </div>
</div>
<br />

<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows"
    [columns]="columns">
</ng-table>
<pagination *ngIf="config.paging"
            class="pagination-sm"
            [(ngModel)]="page"
            [totalItems]="length"
            [itemsPerPage]="itemsPerPage"
            [maxSize]="maxSize"
            [boundaryLinks]="true"
            [rotate]="false"
            (pageChanged)="onChangeTable(config, $event)"
            (numPages)="numPages = $event">
</pagination>
<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>