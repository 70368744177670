<h4>Create Guest User:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="!fileContent.Sponsor">Please fill the Sponsor GroupID, GUID or email address</span> 
    </div>

    <div class="row form-group">
        <div class="col-4">
            <GroupIdGuidOrMailInputField label="Sponsor GroupID, GUID or email address" (groupIDDisplayNameChange)="fileContent.SponsorDisplayName=$event" [(groupID)]="fileContent.Sponsor"  [acceptedTypes]='sponsorAcceptedTypes'></GroupIdGuidOrMailInputField>          
        </div>
        <div class="col-4" *ngIf="fileContent.SponsorDisplayName">
            <label for="guestFirstNameInput" class="required">Guest First Name:</label>
            <input type="text" id="guestFirstNameInput" required name="guestFirstNameInput" class="form-control"
                [(ngModel)]="fileContent.GuestFirstName" #guestFirstNameInput="ngModel">
            <div *ngIf="guestFirstNameInput.invalid && (guestFirstNameInput.dirty || guestFirstNameInput.touched)"
                class="alert alert-danger">
                <div *ngIf="guestFirstNameInput.errors.required">
                    First Name is required.
                </div>             
            </div>
        </div>
        <div class="col-4"  *ngIf="fileContent.SponsorDisplayName">
            <label for="guestLastNameInput" class="required">Guest Last Name:</label>
            <input type="text" id="guestLastNameInput"required name="guestLastNameInput" class="form-control"
                [(ngModel)]="fileContent.GuestLastName" #guestLastNameInput="ngModel">
            <div *ngIf="guestLastNameInput.invalid && (guestLastNameInput.dirty || guestLastNameInput.touched)"
                class="alert alert-danger">
                <div *ngIf="guestLastNameInput.errors.required">
                    Last Name is required.
                </div>             
            </div>
        </div>
    </div>
    <div class="row form-group" *ngIf="fileContent.SponsorDisplayName">
        <div class="col-4">
            <label for="guestEmailAddressInput" class="required">Guest Email Address:</label>
            <input type="text" id="guestEmailAddressInput" required name="guestEmailAddressInput" class="form-control"
                [(ngModel)]="fileContent.GuestEmailAddress" #guestEmailAddressInput="ngModel">
            <div *ngIf="guestEmailAddressInput.invalid && (guestEmailAddressInput.dirty || guestEmailAddressInput.touched)"
                class="alert alert-danger">
                <div *ngIf="guestEmailAddressInput.errors.required">
                    Guest Email Address is required.
                </div>             
            </div>
        </div>
        <div class="col-4">
            <label for="guestExternalCompanyInput" class="required">External Company:</label>
            <input type="text" id="guestExternalCompanyInput" required name="guestExternalCompanyInput" class="form-control"
                [(ngModel)]="fileContent.ExternalCompany" #guestExternalCompanyInput="ngModel">
            <div *ngIf="guestExternalCompanyInput.invalid && (guestExternalCompanyInput.dirty || guestExternalCompanyInput.touched)"
                class="alert alert-danger">
                <div *ngIf="guestExternalCompanyInput.errors.required">
                    External Company is required.
                </div>             
            </div>
        </div>
        <div class="col-4">
            <label for="sendInvitationMessageInput">Send Invitation Message:</label>
            <div id="sendInvitationMessageInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="sendInvitationMessageInputOpt1" name="sendInvitationMessageInput" class="form-control" [value]="true" [(ngModel)]="fileContent.SendInvitationMessage" #sendInvitationMessageInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="sendInvitationMessageInputOpt2" name="sendInvitationMessageInput" class="form-control" [value]="false" [(ngModel)]="fileContent.SendInvitationMessage" #sendInvitationMessageInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div> 
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    
    <div class="row form-group" *ngIf="fileContent.SponsorDisplayName || (fileContentsList && fileContentsList.length > 0)">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || !fileContent.SponsorDisplayName" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->               
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST READY TO BE SENT  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.Name }}</b> 
            {{ listedFileContent.GuestEmailAddress }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Sponsor:</b> {{ listedFileContent.Sponsor }} <span *ngIf="listedFileContent.SponsorDisplayName">({{ listedFileContent.SponsorDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Guest First Name:</b> {{ listedFileContent.GuestFirstName }}
                </div>
                <div class="col-4">
                    <b>Guest Last Name:</b> {{ listedFileContent.GuestLastName }}
                </div> 
                <div class="col-4">
                    <b>Guest Email Address:</b> {{ listedFileContent.GuestEmailAddress }}
                </div>
                <div class="col-4">
                    <b>External Company:</b> {{ listedFileContent.ExternalCompany }}
                </div>
                <div class="col-4">
                    <b>Send Invitation Message:</b> {{ booleanToStringConverterImport(listedFileContent.SendInvitationMessage, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                          
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>