export class CustomerProfile {
    id:string = '';
    BU:string = '';
    Company:string = '';
    Disclaimer:string = '';
    Discriminant:string = '';
    EnableArchive:boolean = false;
    EnableLitigationHold:boolean = false;
    External:boolean = false;
    LitigationHoldDuration:string = '';
    MailboxLanguage:string = '';
    Office365Licenses:string = '';
    PrimaryExternalSMTPdomain:string = '';
    PrimarySMTPdomain:string = '';
    SecondaryEXTERNALSMTPdomain:string = '';
    SecondarySMTPdomain:string = '';
    TimeZone:string = '';
    MobileMailClient:string = '';
    AirWatchMigration:boolean = true;
  
    constructor(customerProfile?:CustomerProfile){
        if(customerProfile){
            this.id = customerProfile.id ? customerProfile.id : '';
            this.BU = customerProfile.BU ? customerProfile.BU : '';
            this.Company = customerProfile.Company ? customerProfile.Company : '';
            this.Disclaimer = customerProfile.Disclaimer ? customerProfile.Disclaimer : '';
            this.Discriminant = customerProfile.Discriminant ? customerProfile.Discriminant : '';
            this.EnableArchive = customerProfile.EnableArchive ? customerProfile.EnableArchive : false;
            this.EnableLitigationHold = customerProfile.EnableLitigationHold ? customerProfile.EnableLitigationHold : false;
            this.External = customerProfile.External ? customerProfile.External : false;
            this.LitigationHoldDuration = customerProfile.LitigationHoldDuration ? customerProfile.LitigationHoldDuration : '';
            this.MailboxLanguage = customerProfile.MailboxLanguage ? customerProfile.MailboxLanguage : '';
            this.Office365Licenses = customerProfile.Office365Licenses ? customerProfile.Office365Licenses : '';
            this.PrimaryExternalSMTPdomain = customerProfile.PrimaryExternalSMTPdomain ? customerProfile.PrimaryExternalSMTPdomain : '';
            this.PrimarySMTPdomain = customerProfile.PrimarySMTPdomain ? customerProfile.PrimarySMTPdomain : '';
            this.SecondaryEXTERNALSMTPdomain = customerProfile.SecondaryEXTERNALSMTPdomain ? customerProfile.SecondaryEXTERNALSMTPdomain : '';
            this.SecondarySMTPdomain = customerProfile.SecondarySMTPdomain ? customerProfile.SecondarySMTPdomain: '';
            this.TimeZone = customerProfile.TimeZone ? customerProfile.TimeZone: '';
            this.MobileMailClient = customerProfile.MobileMailClient ? customerProfile.MobileMailClient: '';
            this.AirWatchMigration = customerProfile.AirWatchMigration ? customerProfile.AirWatchMigration: false;
        }
    }
}

export class CustomerProfilePreview {
    id:string = '';
    Company:string = '';
    BU:string = '';
    Discriminant:string = '';
    PrimarySMTPdomain:string = '';
 
    constructor(customerProfile?){
        if(customerProfile){
            this.id = customerProfile.id ? customerProfile.id : ' ';
            this.Company = customerProfile.Company ? customerProfile.Company : ' ';    
            this.BU = customerProfile.BU ? customerProfile.BU : ' '; 
            this.Discriminant = customerProfile.Discriminant ? customerProfile.Discriminant : ' '; 
            this.PrimarySMTPdomain = customerProfile.PrimarySMTPdomain ? customerProfile.PrimarySMTPdomain : ' ';   
        }
    }
}

export class CustomerProfileRequest{
    requestParams: CustomerProfile;
    localIT: String;

    constructor(){}
}

