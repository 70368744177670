import { Component } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialog } from '../../Template/Dialog/messageDialog.component';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { DnsApiService } from '../../../Services/dnsApi.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { RequestItemService } from '../../../Services/requestItem.service';
import { UserService } from '../../../Services/user.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';
import { PublicDnsFileContent, PublicDnsRecord, RecordValue } from '../../../Models/FileContents';
import { environment } from "../../../../environments/environment";
import { generateUUID } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './publicDns.component.html',
    providers: [RequestContainerService,],
    styleUrls: ['../../../app.component.css']
})

export class PublicDnsComponent {
    
    
    
    
    fileContentsList = new PublicDnsFileContent();
    fileContent = new PublicDnsRecord();
    currentUserInfo = new UserInfo();
    selectedLocalIT: String = '';
    BUnits:Array<object> = new Array<object>();
    Entities:Array<object> = new Array<object>();
    ChangeTypes:Array<string> = new Array<string>("Create");
    Environments:Array<string> = new Array<string>("ACC","DEV","INT","LAB","PRE","PROD");
    RecordSubtypes:Array<string> = new Array<string>("DKIM","DMARC","SPF","OTHER");
    RecordTypes:Array<string> = new Array<string>("MX","TXT");
    DnsSubzones:Array<string> = new Array<string>();
    DnsZones:Array<string> = new Array<string>();
    localITNotFound = '';
    selectedIT = '';
    priorityRange = {min:1,max:65535};
    ttlRange = {min:300,max:2147483647};
    records = [];
    requestStatus = 'ReadyToProcess';
    modificationIndex = -1;
    requestGuid;
    requestKey;
    requestItem: any;
    server = '8.8.8.8';
    FullRecordPreview = '';
    public RecordNamePattern = inputValidationHelper.recordNameRegEx;

    constructor(private userService: UserService, private webAdministrationService : WebAdministrationService, private dnsApiService: DnsApiService, private requestItemService: RequestItemService, public snackBar: MatSnackBar, private router: Router,  private route: ActivatedRoute, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            if (this.selectedLocalIT && this.selectedLocalIT != updatedLocalIT) {
                this.router.navigate(['/']);
            }
            this.selectedLocalIT = updatedLocalIT;
        });
        this.webAdministrationService.getBusinessUnits({Type: "Engie", Status: "Valid", itemPerPage: 5000})
            .subscribe(response => {
                this.BUnits = response.body;
            });
        this.dnsApiService.getDnsZones().subscribe(
            dnsZones => {
                this.DnsZones = dnsZones;
                this.route.queryParams.subscribe(params => { 
                    this.requestGuid = params['requestGUID'];
                    if (this.requestGuid) {
                        this.requestItemService.getRequestItemByGuid(this.requestGuid, this.selectedLocalIT).subscribe(response => {
                            this.requestItem = response;
                            this.requestKey = this.requestItem._key;
                            this.fileContentsList.BusinessUnit = this.requestItem.Parameters.BUSINESSUNIT;
                            this.getEntities(this.requestItem.Parameters.BUSINESSUNIT);
                            this.fileContentsList.TicketID = this.requestItem.Parameters.TICKETID;
                            this.fileContentsList.DnsRecords = this.requestItem.Parameters.DNSRECORDS;
                            this.requestStatus = 'Failure';
                        });
                    }
                });
            },
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
            }
        );
    }

    getSubzones(zone) {
        if (zone) {
            this.DnsSubzones = null;
            this.fileContent.DnsSubzone = '';
            this.dnsApiService.getDnsSubzones(zone).subscribe(
                response => {
                    this.DnsSubzones = response;
                    this.checkZoneAccuracy();
                    this.checkRecordNameRedundancy();
                },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
            this.getRecords();
        }
    }

    getEntities(BU) {
        if (BU) {
            this.fileContentsList.Entity = '';
            this.webAdministrationService.getEntities({ BU: BU, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                this.Entities = entity?.body;
                if (this.requestItem && this.requestItem.Parameters.ENTITY && this.Entities.some((item:any) => item.OrgID == this.requestItem.Parameters.ENTITY)) {
                    this.fileContentsList.Entity = this.requestItem.Parameters.ENTITY;
                }
                if (this.fileContent.ErrorMessage.match("Failed to get the entities for ")) {
                    this.fileContent.ErrorMessage = '';
                }
            },
            (error) =>  {
                this.Entities = [];
                this.fileContent.ErrorMessage = "Failed to get the entities for " + BU + ": " + error.error
            });
        }
    }
    
    getRecords(isRecordNameInvalid?) {
        this.records = [];
        this.fileContent.ErrorMessage = '';
        if (this.fileContent.DnsZone && !isRecordNameInvalid) {
            var domain = this.fileContent.DnsZone;
            if (this.fileContent.DnsSubzone) {
                domain = this.fileContent.DnsSubzone.replace(/\.$/,'') + '.' + domain;
                this.server = environment['ns_subzones'];
            }
            else {
                this.server = environment['ns_zones'];
            }
            if (this.fileContent.RecordName) {
                domain = this.fileContent.RecordName + '.' + domain;
            }
            this.dnsApiService.getDnsRecordsByType(this.fileContent.RecordType, domain, this.server)
                .subscribe(
                    response => {
                        if (response) {
                            if (this.fileContent.RecordType == 'MX') {
                                this.records = response.map(item => ({priority:item.split(' ')[0], exchange:item.split(' ')[1].replace(/\.$/,'')}));
                            }
                            else {
                                this.records = response;
                            }
                            this.checkRecordExistence();
                            this.checkMXRecords();
                        }
                    },
                    error => {
                        this.fileContent.ErrorMessage = "Failed to check records for " + domain + ": " + error.error;
                    }
                );
        }
    }

    checkMXRecords() {
        this.fileContent.WarningMessage = '';
        if (this.records && this.fileContent.Priority) {
            let valuesInFile = [];
            if (this.fileContentsList.DnsRecords && this.fileContentsList.DnsRecords.length > 0 ) {
                valuesInFile = this.fileContentsList.DnsRecords.filter(item =>
                    item.RecordType == this.fileContent.RecordType &&
                    item.RecordName.toLowerCase() == this.fileContent.RecordName.toLowerCase() &&
                    item.DnsZone.toLowerCase() == this.fileContent.DnsZone.toLowerCase() &&
                    item.DnsSubzone.toLowerCase() == this.fileContent.DnsSubzone.toLowerCase() &&
                    item.Status != 'Modifying').map(element => element.Priority);           
            }
            if (this.records.some(item => item.priority < this.fileContent.Priority) || valuesInFile.some(item => item < this.fileContent.Priority)) {
                this.fileContent.WarningMessage += '\nThis (sub)domain already contains MX records with better priority.';
            }
            if (this.records.some(item => item.priority > this.fileContent.Priority) || valuesInFile.some(item => item > this.fileContent.Priority)) {
                this.fileContent.WarningMessage += '\nExisting MX records with lower priority will not be used anymore. Don’t forget to delete them.';
            }
            if (this.records.some(item => item.priority == this.fileContent.Priority) || valuesInFile.some(item => item == this.fileContent.Priority)) {
                this.fileContent.WarningMessage += "\n" + 'MX record with the same priority already exists for this (sub)domain. Load sharing will be performed between them.';
            }
            if (this.fileContent.Priority < 50 && (this.records.every(item => item.priority < 50) && valuesInFile.every(item => item < 50))) {
                this.fileContent.WarningMessage += "\nWe may be wrong but there might be only backup servers declared on this (sub)domain.";
            }
            return;
        }
        if (this.fileContent.Priority && this.fileContent.Priority < 50) {
            this.fileContent.WarningMessage += "\nWe may be wrong but there might be only backup servers declared on this (sub)domain.";
        }
    }

    checkRecordExistence() {
        //Check whether this Record Value already exists
        if (this.fileContent.RecordValue.some(item => item.Value.replace(/"/g,'').trim() != '')) {
            let RecordAlreadyExistsError = "This Record Value already exists.";
            let valuesInRecord = this.fileContent.RecordValue.map(item => item.Value.trim().toLowerCase());
            if (this.fileContent.RecordType == 'TXT') {
                valuesInRecord = valuesInRecord.map(item => {
                    if (item) {
                        let recordValues = item.split(/"\s"/g);
                        recordValues = recordValues.map(item => item.replace(/^"*|"*$/g,'').trim())
                        if (recordValues) {
                            item = recordValues.join('" "');
                            item = '"' + item.replace(/^"*|"*$/g, '') + '"';
                        }
                    }
                    return item;
                });
            }
            let valuesInFile = [];
            if (this.fileContentsList.DnsRecords && this.fileContentsList.DnsRecords.length > 0 ) {
                valuesInFile = this.fileContentsList.DnsRecords.filter(item =>
                    item.RecordType == this.fileContent.RecordType &&
                    item.RecordName.toLowerCase() == this.fileContent.RecordName.toLowerCase() &&
                    item.DnsZone.toLowerCase() == this.fileContent.DnsZone.toLowerCase() &&
                    item.DnsSubzone.toLowerCase() == this.fileContent.DnsSubzone.toLowerCase() &&
                    item.Status != 'Modifying').map(element => element.RecordValue.map(val => val.Value.toLowerCase()));
                valuesInFile = [].concat.apply([], valuesInFile);              
            }
            for (let i = 0; i < this.fileContent.RecordValue.length; i++)  {
                if (this.fileContent.RecordValue[i].Error == RecordAlreadyExistsError) {
                    this.fileContent.RecordValue[i].Error = '';
                }
                if (valuesInRecord[i]) {
                    if (valuesInRecord.lastIndexOf(valuesInRecord[i]) != i) {
                        this.fileContent.RecordValue[i].Error = RecordAlreadyExistsError;
                    }
                    else if (valuesInFile.includes(valuesInRecord[i])) {
                        this.fileContent.RecordValue[i].Error = RecordAlreadyExistsError;
                    }
                    else if (this.fileContent.RecordType == 'MX' && this.records && this.fileContent.RecordValue.length > 0 && this.records.some(item => item.exchange.toLowerCase() == valuesInRecord[i])) {
                        this.fileContent.RecordValue[i].Error = RecordAlreadyExistsError;
                    }
                    else if (this.fileContent.RecordType == 'TXT' && this.records && this.fileContent.RecordValue.length > 0 && this.records.some(item => item.toLowerCase() == valuesInRecord[i])) {
                        this.fileContent.RecordValue[i].Error = RecordAlreadyExistsError;
                    }
                }
            }
            if (this.fileContent.RecordType == 'TXT') {
                this.fileContent.WarningMessage = '';
                let recordsForRecordNameCount = valuesInRecord.filter(item => item != '').length + valuesInFile.length + this.records.length;
                if (recordsForRecordNameCount > 5) {
                    this.fileContent.WarningMessage += '\nThere are ' +  recordsForRecordNameCount + ' records with this record name, are you sure you want to create more?'
                }
            }
        }   
    }

    checkZoneAccuracy(isRecordNameInvalid?) {
        this.fileContent.RecordNameError = '';
        if (this.fileContent.RecordName && this.fileContent.DnsZone && this.DnsSubzones && this.DnsSubzones.length > 0 && !isRecordNameInvalid) {
            var mostAccurateZoneName = '';
            var subZone = this.fileContent.DnsSubzone ? this.fileContent.DnsSubzone.toLowerCase().replace(/^\.|\.$/g,'') : '';
            var subZoneToTest = subZone;
            for (let word of this.fileContent.RecordName.split('.').reverse()) {
                subZoneToTest = word + (subZoneToTest ? "." + subZoneToTest : '');
                if (this.DnsSubzones.map(item => item.replace(/^\.|\.$/g,'').toLowerCase()).includes(subZoneToTest.toLowerCase())) {
                    mostAccurateZoneName = subZoneToTest;
                }
            }
            if (mostAccurateZoneName && (mostAccurateZoneName.toLowerCase() != subZone)) {
                this.fileContent.RecordNameError = "A more accurate DNS zone exists and matches the record you are requesting: " + mostAccurateZoneName + ". Please select the appropriate subzone";
                return;
            }
        }
        if (this.fileContent.RecordName && this.fileContent.DnsZone && !isRecordNameInvalid) {
            let recordPreview = this.fileContent.RecordName + (this.fileContent.DnsSubzone ? '.' + this.fileContent.DnsSubzone.replace(/^\.|\.$/g,'') : '') + '.' + this.fileContent.DnsZone;
            this.FullRecordPreview = '\nFull Record Preview: ' + recordPreview;
        }
        else {
            this.FullRecordPreview = '';
        }
        this.checkRecordExistence();
    }

    checkRecordNameRedundancy(isRecordNameInvalid?) {
        if (this.fileContent.RecordName && this.fileContent.DnsZone && !isRecordNameInvalid) {
            let subZone = this.fileContent.DnsSubzone ? this.fileContent.DnsSubzone.toLowerCase().replace(/^\.|\.$/g,'') : '';
            let zone = this.fileContent.DnsZone.toLowerCase();            
            let recordName = this.fileContent.RecordName.toLowerCase().split('.');
            if ((subZone && (this.fileContent.RecordName.toLowerCase() == subZone || this.fileContent.RecordName.toLowerCase().endsWith('.' + subZone) || recordName[recordName.length -1] == subZone.split('.')[0])) || (!subZone && (this.fileContent.RecordName.toLowerCase() == zone || this.fileContent.RecordName.toLowerCase().endsWith('.' + zone) || recordName[recordName.length -1] == zone.split('.')[0]))) {
                let recordPreview = this.fileContent.RecordName + (subZone ? '.' + subZone : '') + '.' + zone;
                this.dialog.open(MessageDialog, { width: 'auto', height: 'auto', data: { title: 'Warning', message: 'You have entered a record name that is redundant with the selected zone or subzone.<br />The domain will be: [' + recordPreview + ']. If it is not done on purpose,<br />please choose the appropriate subzone/zone and consider removing it from the Record Name.' } });
            }
        }
    }

    getDnsARecords(i) {
        if (this.fileContent.RecordValue[i].Value && this.fileContent.DnsZone) {
            if (this.fileContent.DnsSubzone) {
                this.server = environment['ns_subzones'];
            }
            else {
                this.server = environment['ns_zones'];
            }
            let type = 'a';
            let domain = this.fileContent.RecordValue[i].Value;
            let ipV4RegEx = new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)
            if (ipV4RegEx.test(domain)) {
                type = 'ptr';
                domain = domain + '.in-addr.arpa'
            }
            this.dnsApiService.getDnsRecordsByType(type, domain, this.server)
                .subscribe(
                    response => {
                        var records = response;
                        if (records.length == 0) {
                            this.fileContent.RecordValue[i].Warning = "Please enter a valid IP address or existing dns A record name."
                        }
                        else {
                            this.fileContent.RecordValue[i].Warning = '';
                        }
                        i++;
                        if (i < this.fileContent.RecordValue.length) {
                            this.getDnsARecords(i);
                        }
                    },
                    error => {
                        this.fileContent.RecordValue[i].Error = "Failed to check record existence: " + error.error;
                        return;
                    }
                );
        }
        else {
            i++;
            if (i < this.fileContent.RecordValue.length) {
                this.getDnsARecords(i);
            }
        }
    }

    checkRecordValue() {
        this.fileContent.RecordValue = this.fileContent.RecordValue.map(item => {item.Error = ''; return item;});
        if (this.fileContent.RecordValue.length > 0 && this.fileContent.RecordValue.some(item => item.Value.replace(/"/g,'').trim() != '')) {
            if (this.fileContent.RecordType == 'MX') {
                let recordValueRegEx = new RegExp(/(^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?=^.{4,253}$)(^((?![-\.])[a-zA-Z0-9-_]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$))/i)
                for (let i = 0; i < this.fileContent.RecordValue.length; i++)  {
                    this.fileContent.RecordValue[i].Value = this.fileContent.RecordValue[i].Value.trim();
                    if (this.fileContent.RecordValue[i].Value) {
                        if (!recordValueRegEx.test(this.fileContent.RecordValue[i].Value)) {
                            this.fileContent.RecordValue[i].Error = 'Invalid IPv4 address or DNS A record';
                        }
                    }
                }
                if (this.fileContent.RecordValue.every(item => item.Error != 'Invalid IPv4 address or DNS A record')) {
                    //Check whether record value exists as A record
                    this.getDnsARecords(0);
                }
            }
            else if (this.fileContent.RecordType == 'TXT' && this.fileContent.RecordSubtype) {
                if (this.fileContent.RecordValue.length > 0 && this.fileContent.RecordValue[0].Value.replace(/"/g,'').trim() == '' ) {
                    this.fileContent.RecordValue[0].Error = 'Record Value is required';
                }
                for (let i = 0; i < this.fileContent.RecordValue.length; i++)  {
                    if (this.fileContent.RecordValue[i].Value.replace(/"/g,'').trim() != '') {
                        this.fileContent.RecordValue[i].Warning = '';
                        this.fileContent.RecordValue[i].Value = this.fileContent.RecordValue[i].Value.replace(/\s{2,}/g,' ');
                        let recordValues = this.fileContent.RecordValue[i].Value.split(/"\s"/g);
                        recordValues = recordValues.map(item => item.replace(/^"*|"*$/g,'').trim())
                        recordValues.forEach(recordValue => {
                            if (recordValue.length > 255) {
                                this.fileContent.RecordValue[i].Error = "TXT Record Value contains strings longer than 255 characters";
                            }
                        });
                        let recordValue = recordValues.join('" "');
                        recordValue = '"' + recordValue.replace(/^"*|"*$/g, '') + '"';
                        var doubleQuotes = (recordValue.match(/"/g) || []).length;
                        if (doubleQuotes % 2 != 0) {
                            this.fileContent.RecordValue[i].Error = "There is an unbalance in the number of double quotes";
                        }
                        if ((recordValue.match(/v=dkim1|v=DMARC1|v=spf1/gi) || []).length > 1) {
                            this.fileContent.RecordValue[i].Error = "TXT Record Value matches multiple patterns";
                        }
                        if (!this.fileContent.RecordValue[i].Error) {
                            switch (this.fileContent.RecordSubtype) {
                                case 'DKIM':
                                    let dkimStart = new RegExp(/^"?v=dkim1.*$/i);
                                    if (!dkimStart.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT DKIM record must start with 'v=dkim1'. No whitespace before";
                                        break;
                                    };
                                    let dkimRegex = new RegExp(/^"?v=dkim1(?=.*;\sp=)(?=.*;\sk=)/i);
                                    if (!dkimRegex.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT DKIM record should contain a k= and a p= statement separated by ;  ";
                                    };
                                    break;
                                case 'DMARC':
                                    let dmarcStart = new RegExp(/^"?v=DMARC1.*$/i);
                                    if (!dmarcStart.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT DMARC record must start with 'v=DMARC1'. No whitespace before";
                                        break;
                                    };
                                    let dmarcRegex = new RegExp(/^"?v=DMARC1.*(;\sp=(none|quarantine|reject))/gi);
                                    if (!dmarcRegex.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT DMARC record should contain p=none or quarantine or reject. Other statements separated by ;";
                                    }
                                    dmarcRegex = new RegExp(/^"?v=DMARC1.*(;\srua=mailto:|;\sruf=mailto:)/gi);
                                    if (!dmarcRegex.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT DMARC record should contain contain a 'rua=mailto:' and/or 'ruf=mailto:' statement. All statements separated by ; ";
                                    };
                                    break;
                                case 'SPF':
                                    let spfStart = new RegExp(/^"?v=spf1.*$/i);
                                    if (!spfStart.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT SPF record must start with 'v=spf1'. No whitespace before";
                                        break;
                                    };
                                    let spfRegex = new RegExp(/[-~+?]all"?$/i);
                                    if (!spfRegex.test(recordValue)) {
                                        this.fileContent.RecordValue[i].Warning = "TXT SPF record must end with with -all or ~all or +all or ?all";
                                    };
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
            //Check whether the Record Value already exists
            this.checkRecordExistence();
        }
    }

    noSuccessRequests() {
        return !this.fileContentsList.DnsRecords || this.fileContentsList.DnsRecords.length == 0 || !this.fileContentsList.DnsRecords.some(item => item.Status == 'Success')
    }

    ngOnDestroy() {
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new PublicDnsFileContent();
        this.requestStatus = 'ReadyToProcess';
        this.modificationIndex = -1;
        this.requestGuid = '';
        this.requestKey = '';
        this.requestItem = null;
        this.FullRecordPreview = '';
    }

    clearFileContentForms() {
        this.fileContent = new PublicDnsRecord();
        this.records = [];
        this.DnsSubzones = null;
    }

    clearFileContent() {
        let RecordType = this.fileContent.RecordType;
        this.fileContent = new PublicDnsRecord();
        this.FullRecordPreview = '';
        this.fileContent.RecordType = RecordType;
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push(new RecordValue());
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
        this.checkRecordExistence();
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new PublicDnsRecord(this.fileContent);
        tempObject.RecordValue = tempObject.RecordValue.filter(item => item.Value.replace(/"/g,'').trim() != '' );
        if (this.fileContent.RecordType == 'TXT') {
            tempObject.RecordValue.map(item => {
                if (item.Value) {
                    let recordValues = item.Value.split(/"\s"/g);
                    recordValues = recordValues.map(item => item.replace(/^"*|"*$/g,'').trim())
                    if (recordValues) {
                        item.Value = recordValues.join('" "');
                        item.Value = '"' + item.Value.replace(/^"*|"*$/g, '') + '"';
                    }
                }
                return item;
            });
        }
        if (this.modificationIndex >= 0) {
            tempObject.Status = 'ReadyToExecute';
            tempObject.ErrorMessage = '';
            this.fileContentsList.DnsRecords[this.modificationIndex] = tempObject;
            this.modificationIndex = -1;

        }
        else {
            this.fileContentsList.DnsRecords.push(tempObject);
        }

        this.clearFileContentForms();
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        if (this.fileContentsList.DnsRecords[index].Status = 'Failure') {
            this.requestStatus = 'ReadyToProcess';
        }
        this.fileContentsList.DnsRecords.splice(index, 1);
        if (this.fileContentsList.DnsRecords.length == 0) {
            this.requestStatus = 'ReadyToProcess';
        }
    }

    //Modify selected fileContent from the fileContentsList list
    modifyFileContentFromList(index){
        this.modificationIndex = index;
        this.fileContentsList.DnsRecords[index].Status = 'Modifying';
        this.getSubzones(this.fileContentsList.DnsRecords[index].DnsZone);
        this.fileContent = this.fileContentsList.DnsRecords[index];
        this.fileContent.ErrorMessage = '';
        if (this.fileContent.DnsSubzone) {
            this.getRecords();
        }
        this.requestStatus = 'ReadyToProcess';
    }

    sendDnsRecords(data) {
        this.dnsApiService.createDnsRecords(data)
            .subscribe(
                response => {
                    this.requestItem = response;
                    var index;
                    data.DnsRecords.find((o, i) => {
                        if (o.Status === 'Running') {
                            index = i;
                            return; // stop searching
                        }
                    });
                    this.requestKey = data["requestKey"] = this.requestItem._key;
                    this.requestGuid = data["requestGuid"] = this.requestItem.GUID;
                    this.fileContentsList.DnsRecords = data["DnsRecords"] = this.requestItem.Parameters.DNSRECORDS;
                    data["history"] = this.requestItem.History;
                    if (this.fileContentsList.DnsRecords.some(item => item.Status == 'Failure')) {
                        this.requestStatus = 'Failure';
                    }
                    else if (this.fileContentsList.DnsRecords.some(item => item.Status == 'Running')) {
                        if (this.fileContentsList.DnsRecords[index].Status === 'Running') {
                            this.requestStatus = 'Failure';
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: 'Stopping loop.' } });
                        }
                        else {
                            this.sendDnsRecords(data);
                        }
                    }
                    else {
                        this.requestStatus = 'Success';
                    }
                },
                error => {
                    this.requestStatus = 'Failure';
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    errorsInForm() {
        return (this.fileContent.RecordValue.some(item => item.Error != '') || this.fileContent.ErrorMessage || this.fileContent.RecordNameError);
    }

    sendRequest(isFormInvalid?) {
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false && !this.errorsInForm()){
            this.addFileContentToList();
        }
        if (this.fileContentsList.DnsRecords.length == 0) {
            return;
        }
        //Initiatiate the request data
        for (let i = 0; i < this.fileContentsList.DnsRecords.length; i++)  {
            if (this.fileContentsList.DnsRecords[i].Status == 'ReadyToExecute') {
                this.fileContentsList.DnsRecords[i].Status = 'Running';
                break;
            }
        }
        //Set the target
        var target = [];
        this.fileContentsList.DnsRecords.forEach(item =>{if (!target.includes(item.RecordType)) {target.push(item.RecordType)}});
        this.fileContentsList.GROUPID = target.join();
        let data = { ...this.fileContentsList };
        data["localIT"] = this.selectedLocalIT;
        data["requestKey"] = this.requestKey;
        data["requestGuid"] = this.requestGuid ? this.requestGuid : generateUUID();
        data["history"] = this.requestItem ? this.requestItem.History : [];

        //call the api
        this.requestStatus = 'Running';
        this.sendDnsRecords(data);
    }
} 