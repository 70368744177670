export class MailboxPermissionDetails{
    ProductName: string = '';
    Mailbox: string = '';
    Trustee: Array<string> = new Array<string>();
    Permission: string = '';

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName;
            this.Mailbox = requestItemOutput.Mailbox;
            
            if(Array.isArray(requestItemOutput.Trustee)){
                this.Trustee = requestItemOutput.Trustee;
            }else if (requestItemOutput.Trustee){
                this.Trustee.push(requestItemOutput.Trustee);
            }

            this.Permission = requestItemOutput.Permission;
        }
    }
}