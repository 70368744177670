<div *ngIf="mandatory !== 'false'" class='form-group'>
    <label [class]="mandatory !== 'false' ? 'required' : ''"
        for="groupIdGuidOrMailInput{{nameSuffix}}">{{label}}:</label>
    <div style="display:flex">
        <input type="text" id="groupIdGuidOrMailInput{{nameSuffix}}" name="groupIdGuidOrMailInput{{nameSuffix}}"
            class="form-control" [(ngModel)]="groupID" #groupIdGuidOrMailInput="ngModel" required
            [pattern]="groupIdGuidOrMailPattern">
        <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
            (click)="checkGroupIDValidity();">search</i>
    </div>
    <small *ngIf="groupIDDisplayName && !groupIdGuidOrMailInput.invalid" id="groupIDDisplayName"
        class="form-text text-primary">{{ groupIDDisplayName }}</small>
    <small *ngIf="groupIDDisplayNameError && !groupIdGuidOrMailInput.invalid" id="groupIDDisplayNameError"
        class="form-text text-danger">{{ groupIDDisplayNameError }}</small>

    <div *ngIf="groupIdGuidOrMailInput.invalid && (groupIdGuidOrMailInput.dirty || groupIdGuidOrMailInput.touched)"
        class="alert alert-danger">

        <div *ngIf="groupIdGuidOrMailInput.errors.required">
            {{label}} is required.
        </div>
        <div *ngIf="groupIdGuidOrMailInput.errors.pattern">
            {{label}} format is invalid.
        </div>
    </div>
</div>
<div *ngIf="mandatory === 'false'" class='form-group'>
    <label for="groupIdGuidOrMailInput{{nameSuffix}}">{{label}}:</label>
    <div style="display:flex">
        <input type="text" id="groupIdGuidOrMailInput{{nameSuffix}}" name="groupIdGuidOrMailInput{{nameSuffix}}"
            class="form-control" [(ngModel)]="groupID" #groupIdGuidOrMailInput="ngModel"
            [pattern]="groupIdGuidOrMailPattern">
        <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
            (click)="checkGroupIDValidity();">search</i>
    </div>
    <small *ngIf="groupIDDisplayName && !groupIdGuidOrMailInput.invalid" id="groupIDDisplayName"
        class="form-text text-primary">{{ groupIDDisplayName }}</small>
    <small *ngIf="groupIDDisplayNameError && !groupIdGuidOrMailInput.invalid" id="groupIDDisplayNameError"
        class="form-text text-danger">{{ groupIDDisplayNameError }}</small>

    <div *ngIf="groupIdGuidOrMailInput.invalid && (groupIdGuidOrMailInput.dirty || groupIdGuidOrMailInput.touched)"
        class="alert alert-danger">

        <div *ngIf="groupIdGuidOrMailInput.errors.required">
            {{label}} is required.
        </div>
        <div *ngIf="groupIdGuidOrMailInput.errors.pattern">
            {{label}} format is invalid.
        </div>
    </div>
</div>