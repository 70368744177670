<!-- <div class='form-group'>
    <label class="required" for="groupIDInput">{{label}}:</label>
    <div style="display:flex">
        <input type="text" id="groupIDInput" name="groupIDInput" class="form-control" [noAdmin]="noAdmin"
                [(ngModel)]="groupID" #groupIDInput="ngModel"
                required [pattern]="groupIDPattern">
        <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="checkGroupIDValidity();">search</i>
        <!-
        <span (click)="checkGroupIDValidity();" class="oi oi-person oi-lg text-primary clickableElement" style="margin-left: 5px;height: 36px;width: 36px;"></span>
        -->
    <!-- </div>
    <small *ngIf="groupIDDisplayName && !groupIDInput.invalid" id="groupIDDisplayName" class="form-text text-primary">{{ groupIDDisplayName }}</small>
    <small *ngIf="groupIDDisplayNameError && !groupIDInput.invalid" id="groupIDDisplayNameError" class="form-text text-danger">{{ groupIDDisplayNameError }}</small>
    
    <div *ngIf="groupIDInput.invalid && (groupIDInput.dirty || groupIDInput.touched)"
            class="alert alert-danger">
    
        <div *ngIf="groupIDInput.errors.required">
            {{label}} is required.
        </div>
        <div *ngIf="groupIDInput.errors.pattern">
            {{label}} format is invalid.
        </div>               
    </div>    
</div> --> 

<!-- <GroupIDInputFieldOkta label="Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.GroupID"></GroupIDInputFieldOkta> -->

<div class='form-group'>
    <div class="col-12">
        <label for="chipList">{{label}}:</label>
        <mat-form-field class="example-chip-list" style="width: 100%;">
            <mat-chip-grid #chipList aria-label="selection">
            <mat-chip-row *ngFor="let contact of contactList" [selectable]="selectable"
                    [removable]="removable" (removed)="remove(contact)">
                {{contact}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <input placeholder="New contact..."
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-grid>
        </mat-form-field>
        <small class="form-text text-muted">{{contactCheckNotification}}</small>
    </div>
</div>