<div class="row ">
    <div class="offset-10 col-1 form-group">
        <button class="btn btn-primary"  (click)="showAll()">Show All</button>
    </div>
    <div class="col-1 form-group">
        <button class="btn btn-primary  "  (click)="hideAll()">Hide All</button>
    </div>
</div>

<br />


<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr> 
                <th style="text-align: center" colspan="7"><span style="color:red">DELETED USER</span> ACCOUNT INFORMATION</th>
            </tr>
            <tr>
                <th>Group ID</th>
                <th>New Owner</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ getDeletedUserInfoDetails.GroupId }}</td>
                <td>{{ getDeletedUserInfoDetails.NewOwner }}</td>
            </tr>
        </tbody>
    </table>
</div>


<!-- 

    Delete Infos Section

-->

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="deleteInfoConfCollapsed=!deleteInfoConfCollapsed">
                <th style="text-align: center" colspan="2">
                    DELETION INFORMATION
                    <i *ngIf="deleteInfoConfCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!deleteInfoConfCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!deleteInfoConfCollapsed">
            <tr>
                <th>When Executed</th>
                <td>{{ getDeletedUserInfoDetails.WhenExecuted }}</td>                
            </tr>
            <tr>
                <th>Who Executed</th>
                <td>{{ getDeletedUserInfoDetails.WhoExecuted }}</td>                
            </tr>
            <tr>
                <th>Prevent Mailbox Reconnect</th>
                <td>{{ getDeletedUserInfoDetails.PreventMailboxReconnect }}</td>                
            </tr>
            <tr>
                <th>Prevent Resubscribe Dl</th>
                <td>{{ getDeletedUserInfoDetails.PreventResubscribeDl }}</td>                
            </tr>
            <tr>
                <th>New Owner Alias</th>
                <td>{{ getDeletedUserInfoDetails.NewOwnerAlias }}</td>                
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!deleteInfoConfCollapsed" />

<!-- 

    Melinda Section

-->

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="melindaInfoCollapsed=!melindaInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    MELINDA USER INFORMATION
                    <i *ngIf="melindaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!melindaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!melindaInfoCollapsed">
            <tr>
                <th>Mailbox Links</th>
                <td>
                    <table id="table4" class="OKTA MFA">
                        <thead class="thead-default">
                            <tr>
                                <th>Managed Object Recipient Guid</th>
                                <th>Managed Object Recipient Type Details</th>
                                <th>Managed On Premise</th>
                                <th>Managed Object Alias</th>
                                <th>Filter Attribute</th>
                                <th>Managed Object Displayname</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let MailboxLink of getDeletedUserInfoDetails.MailboxLinks.MailboxLinks">
                                <td>{{ MailboxLink.ManagedObjectRecipientGuid }}</td>
                                <td>{{ MailboxLink.ManagedObjectRecipientTypeDetails }}</td>
                                <td>
                                    <div *ngFor="let ManagedOnPrem of MailboxLink.ManagedOnPremise">
                                        {{ ManagedOnPrem }}<br><br>
                                    </div>
                                </td>
                                <td>{{ MailboxLink.ManagedObjectAlias }}</td>
                                <td>{{ MailboxLink.FilterAttribute }}</td>
                                <td>{{ MailboxLink.ManagedObjectDisplayname }}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <th>Melinda AD Cloud UPN</th>
                <td>{{ getDeletedUserInfoDetails.MelADCloudUPN }}</td>                
            </tr>
            <tr>
                <th>Melinda ADms EA37</th>
                <td>{{ getDeletedUserInfoDetails.MelADmsEA37 }}</td>                
            </tr>
            <tr>
                <th>Melinda ADms EA38</th>
                <td>{{ getDeletedUserInfoDetails.MelADmsEA38 }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Alias</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientAlias }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Company</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientCompany }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Custom Attribute11</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientCustomAttribute11 }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Custom Attribute9</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientCustomAttribute9 }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Department</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientDepartment }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Display Name</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientDisplayName }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Email Addresses</th>
                <td>
                    <tr *ngFor="let MelRecipientEmailAdress of getDeletedUserInfoDetails.MelRecipientEmailAdresses.MelRecipientEmailAdresses">
                        <div>{{ MelRecipientEmailAdress }}<br><br></div>
                    </tr>
                </td>                
            </tr>
            <tr>
                <th>Melinda Recipient Guid</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientGuid }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Name</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientName }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Notes</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientNotes }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Type</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientType }}</td>                
            </tr>
            <tr>
                <th>Melinda Recipient Type Details</th>
                <td>{{ getDeletedUserInfoDetails.MelRecipientTypeDetails }}</td>                
            </tr>
            <tr>
                <th>Melinda User Distinguished Name</th>
                <td>{{ getDeletedUserInfoDetails.MelUserDistinguishedName }}</td>                
            </tr>
            <tr>
                <th>Melinda User First Name</th>
                <td>{{ getDeletedUserInfoDetails.MelUserFirstName }}</td>                
            </tr>
            <tr>
                <th>Melinda User Initials</th>
                <td>{{ getDeletedUserInfoDetails.MelUserInitials }}</td>                
            </tr>
            <tr>
                <th>Melinda User Last Name</th>
                <td>{{ getDeletedUserInfoDetails.MelUserLastName }}</td>                
            </tr>
            <tr>
                <th>Melinda User Linked Master Account</th>
                <td>{{ getDeletedUserInfoDetails.MelUserLinkedMasterAccount }}</td>                
            </tr>
            <tr>
                <th>Melinda User Principal Name</th>
                <td>{{ getDeletedUserInfoDetails.MelUserPrincipalName }}</td>                
            </tr>
            <tr>
                <th>Melinda User Sam Account Name</th>
                <td>{{ getDeletedUserInfoDetails.MelUserSamAccountName }}</td>                
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!melindaInfoCollapsed" />

<!-- 

    Okta Section

-->

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="oktaInfoCollapsed=!oktaInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    OKTA USER INFORMATION
                    <i *ngIf="oktaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!oktaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!oktaInfoCollapsed">
            <tr>
                <th>Okta Company</th>
                <td>{{ getDeletedUserInfoDetails.OktaCompany }}</td>                
            </tr>
            <tr>
                <th>Okta Provider</th>
                <td>{{ getDeletedUserInfoDetails.OktaProvider }}</td>                
            </tr>
            <tr>
                <th>Okta Status</th>
                <td>{{ getDeletedUserInfoDetails.OktaStatus }}</td>                
            </tr>
            <tr>
                <th>Okta Country Code</th>
                <td>{{ getDeletedUserInfoDetails.OktacountryCode }}</td>                
            </tr>
            <tr>
                <th>Okta Display Name</th>
                <td>{{ getDeletedUserInfoDetails.OktaStatus }}</td>                
            </tr>
            <tr>
                <th>Okta User Type</th>
                <td>{{ getDeletedUserInfoDetails.OktacountryCode }}</td>                
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!oktaInfoCollapsed" />

<!-- 

    Exchange Online Section

-->

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="exchangeOnlineUserConfCollapsed=!exchangeOnlineUserConfCollapsed">
                <th style="text-align: center" colspan="2">
                    EXCHANGE ONLINE USER INFORMATION
                    <i *ngIf="exchangeOnlineUserConfCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!exchangeOnlineUserConfCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!exchangeOnlineUserConfCollapsed">
            <tr *ngIf="getDeletedUserInfoDetails.ENTERPRISEPACKAssigned == 'True'">
                <th>ENTERPRISEPACK Assigned</th>
                <td>{{ getDeletedUserInfoDetails.ENTERPRISEPACKAssigned }}</td>                
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.LicenseToActivate">
                <th>Exchange plan to allow litgation hold activated on</th>
                <td>{{ getDeletedUserInfoDetails.LicenseToActivate }}</td>                
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.LicenseToAssign">
                <th>License to allow litgation hold assigned</th>
                <td>{{ getDeletedUserInfoDetails.LicenseToAssign }}</td>                
            </tr>
            <tr>
                <th>Exo Alias</th>
                <td>{{ getDeletedUserInfoDetails.ExoAlias }}</td>                
            </tr>
            <tr>
                <th>Exo Archive Guid</th>
                <td>{{ getDeletedUserInfoDetails.ExoArchiveGuid }}</td>                
            </tr>
            <tr>
                <th>Exo Archive Name</th>
                <td>{{ getDeletedUserInfoDetails.ExoArchiveName }}</td>                
            </tr>
            <tr>
                <th>Exo Archive Status</th>
                <td>{{ getDeletedUserInfoDetails.ExoArchiveStatus }}</td>                
            </tr>
            <tr>
                <th>Exo Company</th>
                <td>{{ getDeletedUserInfoDetails.ExoCompany }}</td>                
            </tr>
            <tr>
                <th>Exo Custom Attribute 11</th>
                <td>{{ getDeletedUserInfoDetails.ExoCustomAttribute11 }}</td>                
            </tr>
            <tr>
                <th>Exo Custom Attribute 9</th>
                <td>{{ getDeletedUserInfoDetails.ExoCustomAttribute9 }}</td>                
            </tr>
            <tr>
                <th>Exo Department</th>
                <td>{{ getDeletedUserInfoDetails.ExoDepartment }}</td>                
            </tr>
            <tr>
                <th>Exo Display Name</th>
                <td>{{ getDeletedUserInfoDetails.ExoDisplayName }}</td>                
            </tr>
            <tr>
                <th>Exo Email Addresses</th>
                <td>{{ getDeletedUserInfoDetails.ExoEmailAdresses }}</td>                
            </tr>
            <tr>
                <th>Exo IMAP Enabled</th>
                <td>{{ getDeletedUserInfoDetails.ExoIMAPEnabled }}</td>                
            </tr>
            <tr>
                <th>Exo Legacy Exchange DN</th>
                <td>{{ getDeletedUserInfoDetails.ExoLegacyExchangeDN }}</td>                
            </tr>
            <tr>
                <th>Exo Litigation Hold Date</th>
                <td>{{ getDeletedUserInfoDetails.ExoLitigationHoldDate }}</td>                
            </tr>
            <tr>
                <th>Exo Litigation Hold Duration</th>
                <td>{{ getDeletedUserInfoDetails.ExoLitigationHoldDuration }}</td>                
             </tr>
            <tr>
                <th>Exo Litigation Hold Enabled</th>
                <td>{{ getDeletedUserInfoDetails.ExoLitigationHoldEnabled }}</td>                
            </tr>
            <tr>
                <th>Exo Litigation Hold Owner</th>
                <td>{{ getDeletedUserInfoDetails.ExoLitigationHoldOwner }}</td>                
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.ExoPersonalSendAsDistributionGroups && getDeletedUserInfoDetails.ExoPersonalSendAsDistributionGroups.DisplayNameInfos.length > 0">
                <th>Personal send-as distribution groups</th>
                <td>
                    <tr *ngFor="let ExoPersonalSendAsDistributionGroup of getDeletedUserInfoDetails.ExoPersonalSendAsDistributionGroups.DisplayNameInfos">
                        <div>{{ ExoPersonalSendAsDistributionGroup }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr>
                <th>Exo POP Enabled</th>
                <td>{{ getDeletedUserInfoDetails.ExoPOPEnabled }}</td>                
            </tr>
            <tr>
                <th>Exo Recipient Type</th>
                <td>{{ getDeletedUserInfoDetails.ExoRecipientType }}</td>                
            </tr>
            <tr>
                <th>Exo Recipient Type Details</th>
                <td>{{ getDeletedUserInfoDetails.ExoRecipientTypeDetails }}</td>                
            </tr>
            <tr>
                <th>Exo Retention Comment</th>
                <td>{{ getDeletedUserInfoDetails.ExoRetentionComment }}</td>                
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!exchangeOnlineUserConfCollapsed" />

<!-- 

    Azure Section

-->

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="azureInfoCollapsed=!azureInfoCollapsed">
                <th style="text-align: center" colspan="2">
                    AZURE INFORMATION
                    <i *ngIf="azureInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!azureInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!azureInfoCollapsed">
            <tr>
                <th>AzureADObjectID</th>
                <td>{{ getDeletedUserInfoDetails.AzureADObjectID }}</td>                
            </tr>
            <tr>
                <th>AzureADUsageLocation</th>
                <td>{{ getDeletedUserInfoDetails.AzureADUsageLocation }}</td>                
            </tr>
            <tr>
                <th>Azure AD Licenses</th>
                <td>
                    <table id="table4" class="OKTA MFA">
                        <thead class="thead-default">
                            <tr>
                                <th>Disabled Plans</th>
                                <th>Sku Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Licence of getDeletedUserInfoDetails.AzureADLicenses.AzureADLicenses">
                                <td>
                                    <div *ngFor="let DisabledPlan of Licence.DisabledPlans">
                                        {{ DisabledPlan }}<br>
                                    </div>
                                </td>
                                <td>{{ Licence.SkuId }}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADApplicationsMemberships && getDeletedUserInfoDetails.AADApplicationsMemberships.DisplayNameInfos.length > 0">
                <th>Azure AD applications memberships</th>
                <td>
                    <tr *ngFor="let AADApplicationsMembership of getDeletedUserInfoDetails.AADApplicationsMemberships.DisplayNameInfos">
                        <div>{{ AADApplicationsMembership }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADServicePrincipalsOwnerships && getDeletedUserInfoDetails.AADServicePrincipalsOwnerships.DisplayNameInfos.length > 0">
                <th>Azure AD owned service principals</th>
                <td>
                    <tr *ngFor="let AADServicePrincipalsOwnership of getDeletedUserInfoDetails.AADServicePrincipalsOwnerships.DisplayNameInfos" >
                        <div>{{ AADServicePrincipalsOwnership }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADApplicationsOwnerships && getDeletedUserInfoDetails.AADApplicationsOwnerships.DisplayNameInfos.length > 0">
                <th>Azure AD owned applications</th>
                <td>
                    <tr *ngFor="let AADApplicationsOwnership of getDeletedUserInfoDetails.AADApplicationsOwnerships.DisplayNameInfos">
                        <div>{{ AADApplicationsOwnership }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADSecurityGroupOwnerships && getDeletedUserInfoDetails.AADSecurityGroupOwnerships.DisplayNameInfos.length > 0">
                <th>Azure AD owned not synchronized Security groups</th>
                <td>
                    <tr *ngFor="let AADSecurityGroupOwnership of getDeletedUserInfoDetails.AADSecurityGroupOwnerships.DisplayNameInfos">
                        <div>{{ AADSecurityGroupOwnership }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADSecurityGroupMemberships && getDeletedUserInfoDetails.AADSecurityGroupMemberships.DisplayNameInfos.length > 0">
                <th>Azure AD not synchronized Security groups memberships</th>
                <td>
                    <tr *ngFor="let AADSecurityGroupMembership of getDeletedUserInfoDetails.AADSecurityGroupMemberships.DisplayNameInfos">
                        <div>{{ AADSecurityGroupMembership }}<br /></div>
                    </tr>
                </td>               
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADSynchronizedSecurityGroupOwnerships && getDeletedUserInfoDetails.AADSynchronizedSecurityGroupOwnerships.DisplayNameInfos.length > 0">
                <th>Azure AD owned synchronized Security groups</th>
                <td>
                    <tr *ngFor="let AADSynchronizedSecurityGroupOwnership of getDeletedUserInfoDetails.AADSynchronizedSecurityGroupOwnerships.DisplayNameInfos">
                        <div>{{ AADSynchronizedSecurityGroupOwnership }}<br /></div>
                    </tr>
                </td>
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADSynchronizedSecurityGroupMemberships && getDeletedUserInfoDetails.AADSynchronizedSecurityGroupMemberships.DisplayNameInfos.length > 0">
                <th>Azure AD synchronized Security groups memberships</th>
                <td>
                    <tr *ngFor="let AADSynchronizedSecurityGroupMembership of getDeletedUserInfoDetails.AADSynchronizedSecurityGroupMemberships.DisplayNameInfos">
                        <div>{{ AADSynchronizedSecurityGroupMembership }}<br /></div>
                    </tr>
                </td>               
            </tr>
            <tr *ngIf="getDeletedUserInfoDetails.AADGuestSponsorships && getDeletedUserInfoDetails.AADGuestSponsorships.GuestMailInfos.length > 0">
                <th>Guest sponsorship</th>
                <td>
                    <tr *ngFor="let AADGuestSponsorship of getDeletedUserInfoDetails.AADGuestSponsorships.GuestMailInfos">
                        <div>{{ AADGuestSponsorship }}<br /></div>
                    </tr>
                </td>               
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!azureInfoCollapsed" />