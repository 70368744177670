<h4>Add Azure AD Group Member:</h4>

<form #fileContentForm="ngForm">
    <div class='row form-group'>
        <div class="col-2">
            <label [class.required]="!isTopLocalITSelected" for="orgIDDropdownList">OrgID:</label>
            <select id="orgIDDropdownList" class="form-control"
                [(ngModel)]="selectedOrgID" name="orgIDDropdownList"
                (change)="getLocalItTeamsGroup($event.target.value)"
                [required]="!isTopLocalITSelected" #orgIDDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let entity of Entities | orderby: 'OrgID' : false : true" [value]="entity.OrgID" title="{{entity.Name}}">{{entity.OrgID}}</option>
            </select>
            <div *ngIf="orgIDDropdownList.invalid && (orgIDDropdownList.dirty || orgIDDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="orgIDDropdownList.errors.required">
                    OrgID is required.
                </div>
            </div>
            <div *ngIf="entitiesError" class="alert alert-danger">
                {{entitiesError}}
            </div>
        </div>
        <div class="col-2" *ngIf="isTopLocalITSelected">
            <label for="localITDropdownList">Get groups for:</label>
            <select id="localITDropdownList" class="form-control" name="localITDropdownList" [(ngModel)]="selectedIT"
                (change)="getOrgIDsManageableByLocalIT($event.target.value)" #localITDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let localITName of localITNames | orderby: '' : false : true"
                    value="{{localITName}}">
                    {{localITName}}</option>
            </select>
        </div>
        <div class="col-4">
            <label class="required" for="auto">Azure AD Group:</label>
            <span *ngIf="localITNotFound" class="badge badge-info">{{localITNotFound}}</span>
            <div *ngIf="!localITNotFound">
                <input type="text"
                    placeholder="Select or start typing group and select"
                    aria-label="Number"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                    class="form-control"
                    id="auto"
                    required>
                <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayWith" (optionSelected)="updateDisplayName($event.option)">
                    <mat-option *ngFor="let app of filteredOptions | async" [value]="app" matTooltip="{{app.description}}">
                        {{app.displayName}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="myControl.hasError('forbiddenNames')">
                    <strong>'{{myControl.errors.forbiddenNames.value}}'</strong> is not selected from the list
                </mat-error>
                <mat-error *ngIf="myControl.hasError('required') && (myControl.touched || myControl.dirty)">
                    Azure AD Group is required
                </mat-error>
                <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="GroupIDInput0">Users to be added (GID):</label>
            <div *ngFor="let userGroupID of fileContent.UsersGID; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'GroupIDInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.UsersGID[i]" name="{{'GroupIDInput' + i}}"
                        [ngClass]="{'has-danger-forced':(GroupIDInput.invalid && (GroupIDInput.dirty || GroupIDInput.touched || GroupIDInput.value))}"
                        [pattern]="groupIDPattern" required #GroupIDInput="ngModel">
                    <i *ngIf="isFirst" id="GroupIDInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow()">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'GroupIDInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(i)">remove_circle_outline</i>
                    <div *ngIf="GroupIDInput.invalid && (GroupIDInput.dirty || GroupIDInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="GroupIDInput.errors.required">
                            Group ID is required.
                        </div>
                        <div *ngIf="GroupIDInput.errors.pattern">
                            Group ID format is invalid.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='row form-group'>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <div class="row form-group">
        <div class="col-1 offset-8" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(); fileContentForm.reset();"
                [disabled]="fileContentForm.invalid || !(fileContent.AADGroupDisplayName) || myControl.hasError('forbiddenNames') || myControl.hasError('required')">Send Request</button>
        </div>
    </div>
</form>