import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from './app.service';

@Component({
  selector: 'callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  public err;
  constructor(private okta: OktaAuthService) { }

  async ngOnInit() {
    try {
      // Handles the response from Okta and parses tokens
      await this.okta.handleAuthentication();
    } catch (error) {
      console.log(error);
      this.err = error;
    }
  }
}