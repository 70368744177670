<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST INFORMATION
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">DistributionList ID</th>
                <td>{{ productCustomHistoryDetails.ListID }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Trustee</th>
                <td>
                    <div *ngFor="let member of productCustomHistoryDetails.Trustee | orderby : member">
                        {{ member }}<br />
                    </div>
                </td> 
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Permission</th>
                <td>{{ productCustomHistoryDetails.Permission }}</td>
            </tr>
        </tbody>
    </table>
</div>