<h4>Create new application mailbox:</h4>

<!-- ADD NEW MAILBOX FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.Manager)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.Manager)">Please fill a valid Manager Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.Manager)">Based on Manager Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-12" style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" noAdmin="noAdmin" groupIDShouldBeActive="true" groupIDShouldNotBeAnOktaCloudAccount="true" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputFieldOkta>
        </div>
        <div class="col-4">
            <label class="required" for="createMailboxNameInput">Name:</label>
            <input type="text" id="createMailboxNameInput" class="form-control" minlength="3" maxlength="42" [pattern]="namePattern"
                [(ngModel)]="fileContent.Name" required name="createMailboxNameInput"  #createMailboxNameInput="ngModel">
            <div *ngIf="createMailboxNameInput.invalid && (createMailboxNameInput.dirty || createMailboxNameInput.touched)"
                class="alert alert-danger">
                <div *ngIf="createMailboxNameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="createMailboxNameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
                <div *ngIf="createMailboxNameInput.errors?.minlength">
                    Name must be between 3 and 42 characters.
                </div>
            </div>
        </div>
        <div class="col-4">
            <GroupIDInputField label="Manager Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.ManagerDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.ManagerDisplayNameError=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.Manager"></GroupIDInputField>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxManagerSendAsInput">Manager Send As:</label>
            <div id="createMailboxManagerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxManagerSendAsInputOpt1" name="createMailboxManagerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ManagerSendAs" #createMailboxManagerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxManagerSendAsInputOpt2" name="createMailboxManagerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ManagerSendAs" #createMailboxManagerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>          
        <div class="col-4">
            <label for="createMailboxPrimarySMTPInput">Primary SMTP:</label>
            <input type="text" id="createMailboxPrimarySMTPInput" class="form-control"
                [(ngModel)]="fileContent.PrimarySMTP" name="createMailboxPrimarySMTPInput">
        </div>
        <div class="col-4">
            <label for="createMailboxDiscriminantInput">Discriminant:</label>
            <input type="text" id="createMailboxDiscriminantInput" class="form-control"
                [(ngModel)]="fileContent.Discriminant" name="createMailboxDiscriminantInput">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxOffice365LicensesInput">Office365 License:</label>
            <select id="createMailboxOffice365LicensesInput" class="form-control" (change)="getNumberOfAvailableLicences($event.currentTarget.value)"
                [(ngModel)]="fileContent.Office365Licenses" name="createMailboxOffice365LicensesInput" #createMailboxOffice365LicensesInput>
                <option *ngFor="let license of licenseTypes | orderby: 'name' : false : true" [value]="license.code">{{license.name}}</option>
            </select>
            <small *ngIf="fileContent && fileContent.Office365Licenses && availableLicensesCounter !== null" id="availableLicensesCounter"
                [class]="availableLicensesCounter < 1 ? 'form-text text-danger' : 'form-text text-primary'">{{ availableLicensesCounter < 1 ? 'Not available, provisioning in process' : availableLicensesCounter + ' license(s) available' }}</small>
        </div>
        <div class="col-4">
            <label for="createMailboxBrandInput">Brand:</label>
            <input type="text" id="createMailboxBrandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="createMailboxBrandInput">
        </div>
        <div class="col-4">
            <label for="createMailboxLanguageDropDown">Language:</label>
            <select id="createMailboxLanguageDropDown" class="form-control"
                [(ngModel)]="fileContent.MailboxLanguage" name="createMailboxLanguageDropDown">
                <option *ngFor="let language of languages" [value]="language.code">{{language.name}}</option>
            </select>
        </div>
    </div>
    <div class="row form-group">   
        <div class="col-4">            
            <label for="createMailboxMailAdressesInput0">Mail Addresses:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxMailAdressesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createMailboxMailAdressesInput' + i}}">
                    <i *ngIf="isFirst" id="createMailboxMailAdressesAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxMailAdressesRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">            
            <label for="createMailboxAdditionalManagerInput0">Additional Manager:</label>
            <div *ngFor="let manager of fileContent.AdditionalManager; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxAdditionalManagerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.AdditionalManager[i]" name="{{'createMailboxAdditionalManagerInput' + i}}"
                        [ngClass]="{'has-danger-forced':(createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched || createMailboxAdditionalManagerInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #createMailboxAdditionalManagerInput="ngModel">
                    <i *ngIf="isFirst" id="createMailboxAdditionalManagerInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.AdditionalManager)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxAdditionalManagerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.AdditionalManager, i)">remove_circle_outline</i>
                    <div *ngIf="createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="createMailboxAdditionalManagerInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxAdditionalManagerSendAsInput">Additional Manager Send As:</label>
            <div id="createMailboxAdditionalManagerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxAdditionalManagerSendAsInputOpt1" name="createMailboxAdditionalManagerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.AdditionalManagerSendAs" #createMailboxAdditionalManagerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxAdditionalManagerSendAsInputOpt2" name="createMailboxAdditionalManagerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.AdditionalManagerSendAs" #createMailboxAdditionalManagerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">        
        <div class="col-4">
            <label for="createMailboxEnableArchiveInput">Enable Archive:</label>
            <div id="createMailboxEnableArchiveInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableArchiveInputOpt1" name="createMailboxEnableArchiveInput" class="form-control" [value]="true" [(ngModel)]="fileContent.EnableArchive" #createMailboxEnableArchiveInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableArchiveInputOpt2" name="createMailboxEnableArchiveInput" class="form-control" [value]="false" [(ngModel)]="fileContent.EnableArchive" #createMailboxEnableArchiveInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div> 
        <div class="col-4">
            <label for="createMailboxHiddenInput">Hidden:</label>
            <div id="createMailboxHiddenInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt1" name="createMailboxHiddenInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt2" name="createMailboxHiddenInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxExternalSendInput">External send:</label>
            <div id="createMailboxExternalSendInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxExternalSendInputOpt1" name="createMailboxExternalSendInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ExternalSend" #createMailboxExternalSendInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxExternalSendInputOpt2" name="createMailboxExternalSendInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ExternalSend" #createMailboxExternalSendInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">        
        <div class="col-4">
            <label for="createMailboxEnableLitigationHoldInput">Enable Litigation Hold:</label>
            <div id="createMailboxEnableLitigationHoldInput">
                <div style="display: inline-block;">
                    <label><input type="radio" (change)="upgradeLicense($event.currentTarget.value)" id="createMailboxEnableLitigationHoldInputOpt1" name="createMailboxEnableLitigationHoldInput" class="form-control" [value]="true" [(ngModel)]="fileContent.EnableLitigationHold" #createMailboxEnableLitigationHoldInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableLitigationHoldInputOpt2" name="createMailboxEnableLitigationHoldInput" class="form-control" [value]="false" [(ngModel)]="fileContent.EnableLitigationHold" #createMailboxEnableLitigationHoldInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>        
        <div class="col-4" [hidden]="!fileContent.EnableLitigationHold">
            <label for="createMailboxLitigationHoldDuration">Litigation Hold Duration:</label>
            <select id="createMailboxLitigationHoldDuration" class="form-control"
                [(ngModel)]="fileContent.LitigationHoldDuration" name="createMailboxLitigationHoldDuration">
                <option *ngFor="let duration of litigationHoldDurations | orderby: '' : false : true" [value]="duration">{{duration}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>   
    </div>    
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError || fileContent.ManagerDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError || fileContent.ManagerDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.Name }}</b> - 
            {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div class="col-4">
                    <b>Manager:</b> {{ listedFileContent.Manager }} <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
                </div>       
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Manager SendAs:</b> {{ booleanToStringConverterImport(listedFileContent.ManagerSendAs, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Primary SMTP:</b> {{ listedFileContent.PrimarySMTP }}
                </div>
                <div class="col-4">
                    <b>Discriminant:</b> {{ listedFileContent.Discriminant }}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Office365 License:</b> {{ listedFileContent.Office365Licenses }}
                </div>
                <div class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>
                <div class="col-4">
                    <b>Language:</b> {{ listedFileContent.MailboxLanguage }}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Mail Addresses:</b>
                    <div *ngFor="let mailAddress of listedFileContent.MailAddresses">
                        {{ mailAddress }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Manager(s):</b>
                    <div *ngFor="let manager of listedFileContent.AdditionalManager">
                        {{ manager }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Manager Send As:</b> {{ booleanToStringConverterImport(listedFileContent.AdditionalManagerSendAs, 'No', 'Yes') }}
                </div>                  
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Enable Archive:</b> {{ booleanToStringConverterImport(listedFileContent.EnableArchive, 'No', 'Yes') }}
                </div>                
                <div class="col-4">
                    <b>Hidden:</b> {{ booleanToStringConverterImport(listedFileContent.Hidden, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>External send:</b> {{ booleanToStringConverterImport(listedFileContent.ExternalSend, 'No', 'Yes') }}
                </div>                   
            </div>
            <div class="row"> 
                <div class="col-4">
                    <b>Enable Litigation Hold:</b> {{ booleanToStringConverterImport(listedFileContent.EnableLitigationHold, 'No', 'Yes') }}
                </div>
                <div class="col-4" *ngIf="listedFileContent.EnableLitigationHold">
                    <b>LitigationHold Duration:</b> {{ listedFileContent.LitigationHoldDuration }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                          
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>