<div class="row ">
        <table class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                    <th style="text-align: center;" colspan="2">
                        OBJECT INFORMATION 
                        <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!productCustomInfoCollapsed">
                <tr>
                    <th class="historyTableColWidthLimitation">Group ID</th>
                    <td>{{ productCustomHistoryDetails.GroupID }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Admin</th>
                    <td>{{ productCustomHistoryDetails.Admin }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">New Owner Alias</th>
                    <td>{{ productCustomHistoryDetails.NewOwnerAlias }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Transferred AAD Security Group Ownerships</th>
                    <td>
                        <div *ngFor="let TransferredAADSecurityGroupOwnership of productCustomHistoryDetails.TransferredAADSecurityGroupOwnerships">
                            {{ TransferredAADSecurityGroupOwnership }}<br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Transferred AAD Application Ownerships</th>
                    <td>
                        <div *ngFor="let TransferredAADApplicationOwnership of productCustomHistoryDetails.TransferredAADApplicationOwnerships">
                            {{ TransferredAADApplicationOwnership }}<br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Transferred AAD Service Principals Ownerships</th>
                    <td>
                        <div *ngFor="let TransferredAADServicePrincipalsOwnership of productCustomHistoryDetails.TransferredAADServicePrincipalsOwnerships">
                            {{ TransferredAADServicePrincipalsOwnership }}<br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Transferred Guest Sponsorships</th>
                    <td>
                        <div *ngFor="let TransferredGuestSponsorship of productCustomHistoryDetails.TransferredGuestSponsorships">
                            {{ TransferredGuestSponsorship }}<br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Deleted Personal Sendas Distribution Groups</th>
                    <td>
                        <div *ngFor="let DeletedPersonalSendasDistributionGroup of productCustomHistoryDetails.DeletedPersonalSendasDistributionGroups">
                            {{ DeletedPersonalSendasDistributionGroup }}<br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Owned Synchronized Security Groups</th>
                    <td>
                        <div *ngFor="let OwnedSynchronizedSecurityGroup of productCustomHistoryDetails.OwnedSynchronizedSecurityGroups">
                            {{ OwnedSynchronizedSecurityGroup }}<br />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>