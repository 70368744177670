import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/oktaAPis";
export const API_VERSION = "/v1";

@Injectable()
export class OktaApiService {
    constructor(private http: HttpClient) { }
    
    clearOktaUserSession(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/clearOktaUserSession';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    clearOktaB2BUserSession(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/clearOktaB2BUserSession';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getUserByGroupID(groupID: string): Observable<any> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserByGroupID';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, { params: params }) as Observable<any>;
    }

    getOktaB2BUserByGroupID(groupID:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getOktaB2BUserByGroupID';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    getOktaB2BUserByMail(oktaUsername:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getOktaB2BUserByMail';
        let params = new HttpParams();
        params = params.append('OktaUsername', oktaUsername);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    suspendOktaUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/suspendOktaUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    suspendOktaB2BUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/suspendOktaB2BUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    unlockOktaUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/unlockOktaUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    unlockOktaB2BUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/unlockOktaB2BUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    unsuspendOktaUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/unsuspendOktaUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    unsuspendOktaB2BUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/unsuspendOktaB2BUser';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    resetOktaUserMfa(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/resetOktaUserMfa';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    resetOktaB2BUserMfa(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/resetOktaB2BUserMfa';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getUserGroups(id:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserGroups';
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get(request, { params: params }) as Observable<any>;
    }

    getUserEnrolledFactors(id: string): Observable<any> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserEnrolledFactors';
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get(request, { params: params }) as Observable<any>;
    }

    expireOktaCloudAccountPassword(data): Observable<any> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/expireOktaCloudAccountPassword';
        return this.http.post(request, data).pipe(map(response => { return response }))
    }

    excludeOktaUserMFA(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/excludeOktaUserMFA';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getAdditionalAccountsOkta(groupID:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAdditionalAccountsOkta';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<any>;
    }
}