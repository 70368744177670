import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'deleteO365ObjectConfirmationDialog',
    templateUrl: './deleteO365ObjectConfirmationDialog.component.html'
})

export class DeleteO365ObjectConfirmationDialog {
    public dialogData: any;

    constructor(public dialogRef: MatDialogRef<DeleteO365ObjectConfirmationDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogData = data;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}