<h4>Set DistributionList Members:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="listIDInput">DistributionList ID:</label>
            <input type="text" id="listIDInput" name="listIDInput" class="form-control"
                    [(ngModel)]="fileContent.ListID" #listIDInput="ngModel" required>

            <div *ngIf="listIDInput.invalid && (listIDInput.dirty || listIDInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="listIDInput.errors.required">
                    DistributionList Id is required.
                </div>          
            </div>            
        </div>
        <div class="col-4">            
            <label class="required" for="additionalMembersInput">New Members (will replace previous ones):</label>
            <div *ngFor="let additionalMember of fileContent.Members; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'additionalMembersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Members[i]" name="{{'additionalMembersInput' + i}}" required>
                    <i id="additionalMembersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalMember()">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'additionalMembersInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.Members[i]" name="{{'additionalMembersInput' + i}}" required>
                    <i id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalMember(i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>        
    </div>
    <div class="row form-group">                
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.ListID }}</b> - 
            <b style="color: #0275d8">{{ listedFileContent.Owner }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-2">
                <b>DistributionList ID:</b> {{ listedFileContent.ListID }}
            </div>
            <div class="col-3">
                <div class="col-12">
                    <b>Additional Members:</b>
                </div>
                <div class="col-12">
                    <span *ngFor="let additionalMember of listedFileContent.Members">{{ additionalMember }}<br/></span>
                </div>            
            </div>
        </div>
    </div>
</div>