import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { DeleteO365ObjectConfirmationDialog } from '../../Template/Dialog/deleteO365ObjectConfirmationDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { GraphApiService } from '../../../Services/graphApi.service';

import { DeleteO365ObjectFileContent, DeleteO365ObjectRequest } from '../../../Models/FileContents';

import { booleanToStringConverter } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './deleteO365Object.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class DeleteO365ObjectComponent{
    public requestContainer:any = {};
    public fileContentsList = new Array<DeleteO365ObjectFileContent>();
    public fileContent = new DeleteO365ObjectFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    private userToDelete;
    
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private graphApiService: GraphApiService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<DeleteO365ObjectFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new DeleteO365ObjectFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentsList and clean the form
    async addFileContentToList(isSendingRequest:Boolean){
        var tempObject = new DeleteO365ObjectFileContent(this.fileContent);
        await this.checkGroupIDValidity(tempObject, true, isSendingRequest);
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false){
            this.addFileContentToList(true);
        }else{
            this.reallySendRequest();
        }
    }

    reallySendRequest(){
        //Initiatiate the request data
        var data = new DeleteO365ObjectRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/deleteO365Object')
            .subscribe( response => {
                            this.clearPage();

                            //Display a success notification for 2sec
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            //Redirect to request history page
                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            //In case of API call error display a popup with information about the error
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }
    
    async checkGroupIDValidity(deleteO365ObjectFileContent:DeleteO365ObjectFileContent, addObjectToList:Boolean, isSendingRequest:Boolean){
        if(inputValidationHelper.checkGroupIdFormat(deleteO365ObjectFileContent.GroupID)){
            this.graphApiService.getUserByGroupID(deleteO365ObjectFileContent.GroupID).subscribe(
                response => {
                    this.userToDelete = response;
                    deleteO365ObjectFileContent.GroupIDDisplayName = this.userToDelete.displayName;
                    if(deleteO365ObjectFileContent.GroupIDDisplayName){
                        if(addObjectToList){
                            let dialogData = <any>deleteO365ObjectFileContent;
                            dialogData.OnPremisesSyncEnabled = this.userToDelete.onPremisesSyncEnabled;
                            let confirmationDialog = this.dialog.open(DeleteO365ObjectConfirmationDialog, { width: 'auto', height: 'auto', data: dialogData });
                        
                            confirmationDialog.afterClosed().subscribe(result => {
                                if(result){
                                    this.fileContentsList.push(deleteO365ObjectFileContent);
                                    this.clearFileContentForms();
                                    if(isSendingRequest){
                                        this.reallySendRequest();
                                    }
                                }
                            });
                        }
                    }else{
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : { statusText: 'Object ' + deleteO365ObjectFileContent.GroupID + ' does not exist!'} } });
                    }
                },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            )
        }
    }
}