import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UndeleteDistributionListFileContent, UndeleteDistributionListRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { DeletedDistributionListsService } from '../../../Services/deletedDistributionLists.service';


import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './undeleteDistributionList.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UndeleteDistributionListComponent{
    
    
    
    

    public fileContentsList = new Array<UndeleteDistributionListFileContent>();
    public fileContent = new UndeleteDistributionListFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public deletedDistributionListsService : DeletedDistributionListsService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<UndeleteDistributionListFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new UndeleteDistributionListFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }
    
    async addFileContentToList(isSendingRequest:Boolean){
        var tempObject = new UndeleteDistributionListFileContent(this.fileContent);
        await this.checkListIDValidity(tempObject, true, isSendingRequest);
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    checkBeforeSendingRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false){
            this.addFileContentToList(true);
        }else{
            this.sendRequest();
        }
    }
    
    sendRequest(){
        var data = new UndeleteDistributionListRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/undeleteDistributionList')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    async checkListIDValidity(undeleteDistributionListFileContent:UndeleteDistributionListFileContent, addObjectToList:Boolean, isSendingRequest:Boolean){
        this.deletedDistributionListsService.getDeletedDistributionListDisplayNameById(undeleteDistributionListFileContent.ListID).subscribe(
            response => {
                undeleteDistributionListFileContent.ListIDDisplayName = response;
                if(undeleteDistributionListFileContent.ListIDDisplayName){
                    if(addObjectToList){
                        this.fileContentsList.push(undeleteDistributionListFileContent);
                        this.clearFileContentForms();
                        if(isSendingRequest){
                            this.sendRequest();
                        }
                    }
                }else{
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : { statusText: 'Distribution List with ID [' + undeleteDistributionListFileContent.ListID + '] does not exist in the backup data !'} } });
                }
            },
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
            }
        )
    }

    updateCurrentFileContentListID(event){
        this.fileContent.ListID = event;
        this.fileContent.ListIDDisplayName = '';
    }
}