import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { GraphApiService } from '../../../Services/graphApi.service';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'GroupIdGuidOrMailInputField',
    templateUrl: './groupIdGuidOrMailInputField.component.html',
    styleUrls: ['../../../app.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class GroupIdGuidOrMailInputFieldComponent implements OnInit {
    public groupIdGuidOrMailPattern: string = `((${inputValidationHelper.groupIdFormatRegEx})|(${inputValidationHelper.guidFormatRegEx})|( ${inputValidationHelper.mailFormatRegEx}))`;
    groupIDDisplayNameValue: string = '';
    groupIDValue: string = '';
    groupIDDisplayNameErrorValue: string = '';

    constructor(private graphApiService: GraphApiService) { }

    @Input() nameSuffix: string;
    @Input() label: string;
    @Input() mandatory?: string;
    //type list : gid, upn, email, guid
    @Input() acceptedTypes?: Array<string>;
    @Output() groupIDChange = new EventEmitter();
    @Output() groupIDDisplayNameChange = new EventEmitter();
    @Output() groupIDDisplayNameErrorChange = new EventEmitter();
    @Input()

    ngOnInit() {
        if (this.acceptedTypes && this.acceptedTypes.length > 0) {
            this.groupIdGuidOrMailPattern = '';
            this.acceptedTypes.forEach(type => {
                if (this.groupIdGuidOrMailPattern === '') {
                    this.groupIdGuidOrMailPattern += '|'
                }
                if (type === 'gid') {
                    this.groupIdGuidOrMailPattern += `(${inputValidationHelper.groupIdFormatRegEx})`
                }
                if (type === 'upn') {
                    this.groupIdGuidOrMailPattern += `(${inputValidationHelper.upnFormatRegEx})`
                }
                if (type === 'email') {
                    this.groupIdGuidOrMailPattern += `(${inputValidationHelper.mailFormatRegEx})`
                }
                if (type === 'guid') {
                    this.groupIdGuidOrMailPattern += `(${inputValidationHelper.guidFormatRegEx})`
                }
            });
        }
    }

    get groupID() {
        return this.groupIDValue;
    }

    set groupID(val) {
        this.groupIDValue = val;
        this.groupIDChange.emit(this.groupIDValue);
        this.groupIDDisplayName = '';
        this.groupIDDisplayNameError = '';
    }

    get groupIDDisplayNameError() {
        return this.groupIDDisplayNameErrorValue;
    }

    get groupIDDisplayName() {
        return this.groupIDDisplayNameValue;
    }

    set groupIDDisplayName(val) {
        this.groupIDDisplayNameValue = val;
        this.groupIDDisplayNameChange.emit(this.groupIDDisplayNameValue);
    }

    set groupIDDisplayNameError(val) {
        this.groupIDDisplayNameErrorValue = val;
        this.groupIDDisplayNameErrorChange.emit(this.groupIDDisplayNameErrorValue);
    }

    checkGroupIDValidity() {
        let groupIDValueCopy = new String(this.groupIDValue ? this.groupIDValue : '');
        if (groupIDValueCopy.indexOf('@') !== -1) {
            if (inputValidationHelper.checkMailAddressFormat(groupIDValueCopy)) {
                this.graphApiService.getUserDisplayNameByMail(groupIDValueCopy.toString()).subscribe(
                    response => {
                        if (response) {
                            this.groupID = groupIDValueCopy.toString();
                            this.groupIDDisplayName = response;
                            this.groupIDDisplayNameError = '';
                        }
                        else {
                            this.groupIDDisplayNameError = groupIDValueCopy + ' not found on AzureAD!';
                            this.groupIDDisplayName = '';
                        }
                    },
                    error => {
                        if (this.acceptedTypes.indexOf('upn') !== -1) {
                            this.graphApiService.getUserDisplayNameByUPN(groupIDValueCopy.toString()).subscribe(
                                response => {
                                    if (response) {
                                        this.groupID = groupIDValueCopy.toString();
                                        this.groupIDDisplayName = response;
                                        this.groupIDDisplayNameError = '';
                                    }
                                    else {
                                        this.groupIDDisplayNameError = groupIDValueCopy + ' not found on AzureAD!';
                                        this.groupIDDisplayName = '';
                                    }
                                },
                                error => {
                                    this.groupIDDisplayNameError = 'Error when trying to check the email address!';
                                    this.groupIDDisplayName = '';
                                }
                            )
                        } else {
                            this.groupIDDisplayNameError = 'Error when trying to check the email address!';
                            this.groupIDDisplayName = '';
                        }
                    }
                )
            }
        }
        else {
            if (inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)) {
                this.graphApiService.getUserDisplayNameByGroupID(groupIDValueCopy.toString()).subscribe(
                    response => {
                        this.groupID = groupIDValueCopy.toString();
                        this.groupIDDisplayName = response;
                        this.groupIDDisplayNameError = '';
                    },
                    error => {
                        if (error && error.status && error.status == '404') {
                            this.groupIDDisplayNameError = groupIDValueCopy + ' not found on AzureAD!';
                            this.groupIDDisplayName = '';
                        } else {
                            this.groupIDDisplayNameError = 'Error when trying to check GroupID!';
                            this.groupIDDisplayName = '';
                        }
                    }
                )
            }
            else if (inputValidationHelper.checkGuidFormat(groupIDValueCopy)) {
                this.graphApiService.getUserDisplayNameByGuid(groupIDValueCopy.toString()).subscribe(
                    response => {
                        this.groupID = groupIDValueCopy.toString();
                        this.groupIDDisplayName = response;
                        this.groupIDDisplayNameError = '';
                    },
                    error => {
                        if (error && error.status && error.status == '404') {
                            this.groupIDDisplayNameError = groupIDValueCopy + ' not found on AzureAD!';
                            this.groupIDDisplayName = '';
                        } else {
                            this.groupIDDisplayNameError = 'Error when trying to check Guid!';
                            this.groupIDDisplayName = '';
                        }
                    }
                )
            }
        }
    }

}