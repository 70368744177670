import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/DeletedO365Objects";
export const API_VERSION = "/v1";

@Injectable()  
export class DeletedO365ObjectsService{
  constructor(private http: HttpClient) { }

  getDeletedO365ObjectById(id) : Observable<any>{
    let messageInfo = API_BASE_URL + API_NAME + API_VERSION + '/getDeletedO365ObjectById';
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(messageInfo, {params: params}) as Observable<any>;
  }

  getDeletedO365ObjectDisplayNameById(id) : Observable<string>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getDeletedO365ObjectDisplayNameById';
    let params = new HttpParams();
    params = params.append('id', id.toUpperCase( ));
    return this.http.get(request, {params: params}) as Observable<string>;
  }
}