export class AddIntuneUserDetails{
    User: string = '';
    License: string = '';
    Applications: Array<string> = new Array<string>();

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.User = requestItemOutput.User ? requestItemOutput.User : '';
            this.License = requestItemOutput.License ? requestItemOutput.License : '';

            if(Array.isArray(requestItemOutput.Applications)){
                this.Applications = requestItemOutput.Applications;
            }else if (requestItemOutput.Applications){
                this.Applications.push(requestItemOutput.Applications);
            }
        }
    }
}