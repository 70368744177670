import { RequestItem } from "app/Models/RequestItem";
import { booleanToStringConverter, dateToFormatedLocaleString } from "../../Helpers/utils";

export class GetDeletedUserInfoDetails{
    GroupId: string;   
    NewOwner: string;   
    PreventMailboxReconnect: string;   
    PreventResubscribeDl: string;
    AADApplicationsMemberships: ResourceDisplayNameInfo;
    AADApplicationsOwnerships: DisplayNameInfo;
    AADGuestSponsorships: GuestMailInfo;
    AADSecurityGroupMemberships: DisplayNameInfo;  
    AADSecurityGroupOwnerships: DisplayNameInfo;
    AADServicePrincipalsOwnerships: DisplayNameInfo;
    AADSynchronizedSecurityGroupMemberships : DisplayNameInfo;
    AADSynchronizedSecurityGroupsOwnerships: DisplayNameInfo;
    AzureADLicenses: AzureADLicenses;
    AzureADObjectID: string;
    AzureADUsageLocation: string;
    ENTERPRISEPACKAssigned: string;
    ExoAlias: string;
    ExoArchiveGuid: string;
    ExoArchiveName: string;
    ExoArchiveStatus: string;
    ExoCompany: string;
    ExoCustomAttribute11: string;
    ExoCustomAttribute9: string;
    ExoDepartment: string;
    ExoDisplayName: string;
    ExoEmailAdresses: string;
    ExoIMAPEnabled: string;
    ExoLegacyExchangeDN: string;
    ExoLitigationHoldDate: string;
    ExoLitigationHoldDuration: string;
    ExoLitigationHoldEnabled: string;
    ExoLitigationHoldOwner: string;
    ExoPersonalSendAsDistributionGroups: DisplayNameInfo;
    ExoPOPEnabled: string;
    ExoRecipientType: string;
    ExoRecipientTypeDetails: string;
    ExoRetentionComment: string;
    LicenseToActivate: string;
    LicenseToAssign: string;
    MailboxLinks: MailboxLinks;
    MelADCloudUPN: string;
    MelADmsEA37: string;
    MelADmsEA38: string;
    MelRecipientAlias: string;
    MelRecipientCompany: string;
    MelRecipientCustomAttribute11: string;
    MelRecipientCustomAttribute9: string;
    MelRecipientDepartment: string;
    MelRecipientDisplayName: string;
    MelRecipientEmailAdresses: MelRecipientEmailAdresses;
    MelRecipientGuid: string;
    MelRecipientName: string;
    MelRecipientNotes: string;
    MelRecipientType: string;
    MelRecipientTypeDetails: string;
    MelUserDistinguishedName: string;
    MelUserFirstName: string;
    MelUserInitials: string;
    MelUserLastName: string;
    MelUserLinkedMasterAccount: string;
    MelUserPrincipalName: string;
    MelUserSamAccountName: string;
    NewOwnerAlias: string;
    OktaCompany: string;
    OktaProvider: string;
    OktaStatus: string;
    OktacountryCode: string;
    OktadisplayName: string;
    OktauserType: string;
    WhenExecuted: string;
    WhoExecuted: string;

    constructor(requestItem?: RequestItem){      
        if(requestItem && requestItem.Output){
            var data = requestItem.Output;

            this.GroupId = data.GROUPID ? data.GROUPID : '';
            this.NewOwner = data.NEWOWNER ? data.NEWOWNER : '';
            this.PreventMailboxReconnect = data.PREVENTMAILBOXRECONNECT ? "True" : "False";
            this.PreventResubscribeDl = data.PREVENTRESUBSCRIBEDL ? "True" : "False";
            this.AADApplicationsMemberships = data._AADApplicationRoleAssignment ? new ResourceDisplayNameInfo(data._AADApplicationRoleAssignment) : new ResourceDisplayNameInfo();
            this.AADApplicationsOwnerships = data._AADApplications ? new DisplayNameInfo(data._AADApplications) : new DisplayNameInfo();
            this.AADGuestSponsorships = data._AADGuestUsers ? new GuestMailInfo(data._AADGuestUsers) : new GuestMailInfo();
            this.AADSecurityGroupMemberships = data._AADSecurityGroupsMembership ? new DisplayNameInfo(data._AADSecurityGroupsMembership) : new DisplayNameInfo();
            this.AADSecurityGroupOwnerships = data._AADSecurityGroups ? new DisplayNameInfo(data._AADSecurityGroups) : new DisplayNameInfo();
            this.AADServicePrincipalsOwnerships = data._AADServicePrincipals ? new DisplayNameInfo(data._AADServicePrincipals) : new DisplayNameInfo();
            this.AADSynchronizedSecurityGroupMemberships = data._AADSynchronizedSecurityGroupMemberships ? new DisplayNameInfo(data._AADSynchronizedSecurityGroupMemberships) : new DisplayNameInfo();
            this.AADSynchronizedSecurityGroupsOwnerships = data._AADSynchronizedSecurityGroups ? new DisplayNameInfo(data._AADSynchronizedSecurityGroups) : new DisplayNameInfo();
            this.AzureADLicenses = data._AzureADLicenses ? new AzureADLicenses(data._AzureADLicenses) : new AzureADLicenses();
            this.AzureADObjectID = data._AzureADObjectID ? data._AzureADObjectID : '';
            this.AzureADUsageLocation = data._AzureADUsageLocation ? data._AzureADUsageLocation : '';
            this.ENTERPRISEPACKAssigned = data._ENTERPRISEPACKAssigned ? "True" : "False";
            this.ExoAlias = data._ExoAlias ? data._ExoAlias : '';
            this.ExoArchiveGuid = data._ExoArchiveGuid ? data._ExoArchiveGuid : '';
            this.ExoArchiveName = data._ExoArchiveName ? data._ExoArchiveName : '';
            this.ExoArchiveStatus = data._ExoArchiveStatus ? data._ExoArchiveStatus : '';
            this.ExoCompany = data._ExoCompany ? data._ExoCompany : '';
            this.ExoCustomAttribute11 = data._ExoCustomAttribute11 ? data._ExoCustomAttribute11 : '';
            this.ExoCustomAttribute9 = data._ExoCustomAttribute9 ? data._ExoCustomAttribute9 : '';
            this.ExoDepartment = data._ExoDepartment ? data._ExoDepartment : '';
            this.ExoDisplayName = data._ExoDisplayName ? data._ExoDisplayName : '';
            this.ExoEmailAdresses = data._ExoEmailAdresses ? data._ExoEmailAdresses : '';
            this.ExoIMAPEnabled = data._ExoIMAPEnabled ? "True" : "False";
            this.ExoLegacyExchangeDN = data._ExoLegacyExchangeDN ? data._ExoLegacyExchangeDN : '';
            this.ExoLitigationHoldDate = data._ExoLitigationHoldDate ? data._ExoLitigationHoldDate : '';
            this.ExoLitigationHoldDuration = data._ExoLitigationHoldDuration ? data._ExoLitigationHoldDuration : '';
            this.ExoLitigationHoldEnabled = data._ExoLitigationHoldEnabled ? "True" : "False";
            this.ExoLitigationHoldOwner = data._ExoLitigationHoldOwner ? data._ExoLitigationHoldOwner : '';
            this.ExoPersonalSendAsDistributionGroups = data._EXOPersonalSendAsDistributionGroups ? new DisplayNameInfo(data._EXOPersonalSendAsDistributionGroups) : new DisplayNameInfo();
            this.ExoPOPEnabled = data._ExoPOPEnabled ? "True" : "False";
            this.ExoRecipientType = data._ExoRecipientType ? data._ExoRecipientType : '';
            this.ExoRecipientTypeDetails = data._ExoRecipientTypeDetails ? data._ExoRecipientTypeDetails : '';
            this.ExoRetentionComment = data._ExoRetentionComment ? data._ExoRetentionComment : '';
            this.LicenseToActivate = data._LicenseToActivate ? data._LicenseToActivate.SkuPartNumber : '';
            this.LicenseToAssign = data._LicenseToAssign ? data._LicenseToAssign.SkuPartNumber : '';
            this.MailboxLinks = data._MailboxLinks ? new MailboxLinks(data._MailboxLinks) : new MailboxLinks();
            this.MelADCloudUPN = data._MelADCloudUPN ? data._MelADCloudUPN : '';
            this.MelADmsEA37 = data._MelADmsEA37 ? data._MelADmsEA37 : '';
            this.MelADmsEA38 = data._MelADmsEA38 ? data._MelADmsEA38 : '';
            this.MelRecipientAlias = data._MelRecipientAlias ? data._MelRecipientAlias : '';
            this.MelRecipientCompany = data._MelRecipientCompany ? data._MelRecipientCompany : '';
            this.MelRecipientCustomAttribute11 = data._MelRecipientCustomAttribute11 ? data._MelRecipientCustomAttribute11 : '';
            this.MelRecipientCustomAttribute9 = data._MelRecipientCustomAttribute9 ? data._MelRecipientCustomAttribute9 : '';
            this.MelRecipientDepartment = data._MelRecipientDepartment ? data._MelRecipientDepartment : '';
            this.MelRecipientDisplayName = data._MelRecipientDisplayName ? data._MelRecipientDisplayName : '';
            this.MelRecipientEmailAdresses = data._MelRecipientEmailAdresses ? new MelRecipientEmailAdresses(data._MelRecipientEmailAdresses) : new MelRecipientEmailAdresses();
            this.MelRecipientGuid = data._MelRecipientGuid ? data._MelRecipientGuid : '';
            this.MelRecipientName = data._MelRecipientName ? data._MelRecipientName : '';
            this.MelRecipientNotes = data._MelRecipientNotes ? data._MelRecipientNotes : '';
            this.MelRecipientType = data._MelRecipientType ? data._MelRecipientType : '';
            this.MelRecipientTypeDetails = data._MelRecipientTypeDetails ? data._MelRecipientTypeDetails : '';
            this.MelUserDistinguishedName = data._MelUserDistinguishedName ? data._MelUserDistinguishedName : '';
            this.MelUserFirstName = data._MelUserFirstName ? data._MelUserFirstName : '';
            this.MelUserInitials = data._MelUserInitials ? data._MelUserInitials : '';
            this.MelUserLastName = data.MelUserLastName ? data.MelUserLastName : '';
            this.MelUserLinkedMasterAccount = data._MelUserLinkedMasterAccount ? data._MelUserLinkedMasterAccount : '';
            this.MelUserPrincipalName = data._MelUserPrincipalName ? data._MelUserPrincipalName : '';
            this.MelUserSamAccountName = data._MelUserSamAccountName ? data._MelUserSamAccountName : '';
            this.NewOwnerAlias = data._NewOwnerAlias ? data._NewOwnerAlias : '';
            this.OktaCompany = data._OktaCompany ? data._OktaCompany : '';
            this.OktaProvider = data._OktaProvider ? data._OktaProvider : '';
            this.OktaStatus = data._OktaStatus ? data._OktaStatus : '';
            this.OktacountryCode = data._OktacountryCode ? data._OktacountryCode : '';
            this.OktadisplayName = data._OktadisplayName ? data._OktadisplayName : '';
            this.OktauserType = data._OktauserType ? data._OktauserType : '';
            this.WhenExecuted = data._WhenExecuted ? data._WhenExecuted : '';
            this.WhoExecuted = data._WhoExecuted ? data._WhoExecuted : ''; 
        }
    }
}

export class AzureADLicense {
    DisabledPlans: Array<string>;
    SkuId: string;
}

export class AzureADLicenses{
    AzureADLicenses: Array<AzureADLicense>;

    constructor(azureADLicencesData?){
        this.AzureADLicenses = new Array<AzureADLicense>();
        if(azureADLicencesData){
            azureADLicencesData.forEach(licence => {
                var formatedAzureADLicense:AzureADLicense = new AzureADLicense();
                formatedAzureADLicense.DisabledPlans = licence.DisabledPlans ? licence.DisabledPlans : new Array<string>();
                formatedAzureADLicense.SkuId = licence.SkuId ? licence.SkuId : "";
                this.AzureADLicenses.push(formatedAzureADLicense);
            });             
        }  
    }
}


export class MailboxLink {
    ManagedObjectRecipientGuid: string;
    ManagedObjectRecipientTypeDetails: string;
    ManagedOnPremise: Array<string>;
    ManagedObjectAlias: string;
    FilterAttribute: string;
    ManagedObjectDisplayname: string;
}

export class MailboxLinks{
    MailboxLinks: Array<MailboxLink>;

    constructor(mailboxLinksData?){
        this.MailboxLinks = new Array<MailboxLink>();
        if(mailboxLinksData){
            if(Array.isArray(mailboxLinksData)){
                mailboxLinksData.forEach(mailboxLink => {
                    var formatedMailboxLink:MailboxLink = new MailboxLink();
                    formatedMailboxLink.ManagedObjectRecipientGuid = mailboxLink.ManagedObjectRecipientGuid ? mailboxLink.ManagedObjectRecipientGuid : "";
                    formatedMailboxLink.ManagedObjectRecipientTypeDetails = mailboxLink.ManagedObjectRecipientTypeDetails ? mailboxLink.ManagedObjectRecipientTypeDetails : "";
                    formatedMailboxLink.ManagedOnPremise = mailboxLink.ManagedOnPremise ? mailboxLink.ManagedOnPremise : new Array<string>();
                    formatedMailboxLink.ManagedObjectAlias = mailboxLink.ManagedObjectAlias ? mailboxLink.ManagedObjectAlias : "";
                    formatedMailboxLink.FilterAttribute = mailboxLink.FilterAttribute ? mailboxLink.FilterAttribute : "";
                    formatedMailboxLink.ManagedObjectDisplayname = mailboxLink.ManagedObjectDisplayname ? mailboxLink.ManagedObjectDisplayname : "";
                    this.MailboxLinks.push(formatedMailboxLink);
                });
            }
            else {
                var formatedMailboxLink:MailboxLink = new MailboxLink();
                formatedMailboxLink.ManagedObjectRecipientGuid = mailboxLinksData.ManagedObjectRecipientGuid ? mailboxLinksData.ManagedObjectRecipientGuid : "";
                formatedMailboxLink.ManagedObjectRecipientTypeDetails = mailboxLinksData.ManagedObjectRecipientTypeDetails ? mailboxLinksData.ManagedObjectRecipientTypeDetails : "";
                formatedMailboxLink.ManagedOnPremise = mailboxLinksData.ManagedOnPremise ? mailboxLinksData.ManagedOnPremise : new Array<string>();
                formatedMailboxLink.ManagedObjectAlias = mailboxLinksData.ManagedObjectAlias ? mailboxLinksData.ManagedObjectAlias : "";
                formatedMailboxLink.FilterAttribute = mailboxLinksData.FilterAttribute ? mailboxLinksData.FilterAttribute : "";
                formatedMailboxLink.ManagedObjectDisplayname = mailboxLinksData.ManagedObjectDisplayname ? mailboxLinksData.ManagedObjectDisplayname : "";
                this.MailboxLinks.push(formatedMailboxLink);
            }         
        }  
    }
}


export class MelRecipientEmailAdresses{
    MelRecipientEmailAdresses: Array<string>;

    constructor(melRecipientEmail?){
        this.MelRecipientEmailAdresses = new Array<string>();
        if(melRecipientEmail){
            if(Array.isArray(melRecipientEmail)){
                melRecipientEmail.forEach(melRecipient => {
                    this.MelRecipientEmailAdresses.push(melRecipient);
                });
            }
            else {
                this.MelRecipientEmailAdresses.push(melRecipientEmail.DisplayName);
            }                
        }  
    }
}

export class DisplayNameInfo{
    DisplayNameInfos: Array<string>;

    constructor(DisplayNameInfoObjects?){
        this.DisplayNameInfos = new Array<string>();
        if(DisplayNameInfoObjects){
            if(Array.isArray(DisplayNameInfoObjects)){
                DisplayNameInfoObjects.forEach(DisplayNameInfoObject => {
                    this.DisplayNameInfos.push(DisplayNameInfoObject.DisplayName);
                });
            }
            else {
                this.DisplayNameInfos.push(DisplayNameInfoObjects.DisplayName);
            }            
        }  
    }
}

export class ResourceDisplayNameInfo{
    DisplayNameInfos: Array<string>;

    constructor(DisplayNameInfoObjects?){
        this.DisplayNameInfos = new Array<string>();
        if(DisplayNameInfoObjects){
            if(Array.isArray(DisplayNameInfoObjects)){
                DisplayNameInfoObjects.forEach(DisplayNameInfoObject => {
                    this.DisplayNameInfos.push(DisplayNameInfoObject.ResourceDisplayName);
                });
            }
            else {
                this.DisplayNameInfos.push(DisplayNameInfoObjects.DisplayName);
            }            
        }  
    }
}

export class GuestMailInfo{
    GuestMailInfos: Array<string>;

    constructor(GuestMailInfoObjects?){
        this.GuestMailInfos = new Array<string>();
        if(GuestMailInfoObjects){
            if(Array.isArray(GuestMailInfoObjects)){
                GuestMailInfoObjects.forEach(GuestMailInfoObject => {
                    this.GuestMailInfos.push(GuestMailInfoObject.Mail);
                });
            }
            else {
                this.GuestMailInfos.push(GuestMailInfoObjects.DisplayName);
            }            
        }  
    }
}