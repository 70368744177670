export class UserInfo{
    private _localITs: Array<String> = new Array<String>();
    GroupID: String;
    FullName: String;
    FirstName: String;
    LastName: String;
    SelectedLocalIT: String;
    IsAdmin: boolean;
    IsInLocalITCard: boolean;
    IsOrgDirOnly: boolean;

    get LocalITs(): Array<String>{
        if(this._localITs) {
            return this._localITs.sort();
        } else {
            return this._localITs;
        }
    }
    set LocalITs(localITs:Array<String>){
        this._localITs = localITs;
    }

    constructor(GroupID?:String, FullName?:String, FirstName?:String, LastName?:String, LocalITs?:Array<String>, SelectedLocalIT?:String, IsAdmin?:boolean, IsInLocalITCard?:boolean, IsOrgDirOnly?:boolean) {
        this.GroupID = GroupID ? GroupID : "";
        this.FullName = FullName ? FullName : "";
        this.FirstName = FirstName ? FirstName : "";
        this.LastName = LastName ? LastName : "";
        this.LocalITs = LocalITs ? LocalITs : new Array<String>();
        this.SelectedLocalIT = SelectedLocalIT ? SelectedLocalIT : "";
        this.IsAdmin = IsAdmin ? IsAdmin : false;
        this.IsInLocalITCard = IsInLocalITCard ? IsInLocalITCard : false;
        this.IsOrgDirOnly = IsOrgDirOnly ? IsOrgDirOnly : false;
    }
}

export class UserAdmin{
    GroupID: String = '';
    FirstName: String = '';
    LastName: String = '';
    Role: String = 'PEO admin';

    constructor(){}
}

export class UserAdminRequest{
    UserAdminList: Array<UserAdmin> = new Array<UserAdmin>();

    constructor(){}
}