<h4>Remove Okta User From Group:</h4>

<form #fileContentForm="ngForm" (ngSubmit)="fileContentForm.resetForm()" >
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="oktaGroupNameInput">Okta Group Name:</label>
            <input type="text" id="oktaGroupNameInput" name="oktaGroupNameInput" class="form-control"
                    [(ngModel)]="fileContent.OktaGroupName" #oktaGroupNameInput="ngModel" required>

            <div *ngIf="oktaGroupNameInput.invalid && (oktaGroupNameInput.dirty || oktaGroupNameInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="oktaGroupNameInput.errors.required">
                    Okta Group Name is required.
                </div>             
            </div>            
        </div>
        <div class="col-4">
            <!--
            <MultiValueInputFields label="User Group ID" [inputsArray]="fileContent.OktaUserNames" [pattern]="groupIDPattern" isRequired="true"></MultiValueInputFields>
            -->         
            <label class="required" for="groupIDsInput">Users to be removed (GID):</label>
            <div *ngFor="let userGroupID of fileContent.OktaUserNames; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'oktaGroupIDInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(oktaGroupIDInput.invalid && (oktaGroupIDInput.dirty || oktaGroupIDInput.touched || oktaGroupIDInput.value))}"
                            [(ngModel)]="fileContent.OktaUserNames[i]" name="{{'oktaGroupIDInput' + i}}" #oktaGroupIDInput="ngModel"
                            required [pattern]="groupIDPattern">
                    <i *ngIf="isLast" id="{{'groupIDsInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.OktaUserNames)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst && !isLast" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'oktaGroupIDInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(oktaGroupIDInput.invalid && (oktaGroupIDInput.dirty || oktaGroupIDInput.touched || oktaGroupIDInput.value))}"
                            [(ngModel)]="fileContent.OktaUserNames[i]" name="{{'oktaGroupIDInput' + i}}" #oktaGroupIDInput="ngModel"
                            required [pattern]="groupIDPattern">
                    <i id="{{'groupIDsInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.OktaUserNames,i)">remove_circle_outline</i>
                </div>
                <div *ngIf="isLast && !isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'oktaGroupIDInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(oktaGroupIDInput.invalid && (oktaGroupIDInput.dirty || oktaGroupIDInput.touched || oktaGroupIDInput.value))}"
                            [(ngModel)]="fileContent.OktaUserNames[i]" name="{{'oktaGroupIDInput' + i}}" #oktaGroupIDInput="ngModel"
                            required [pattern]="groupIDPattern">
                    <i id="{{'groupIDsInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.OktaUserNames,i)">remove_circle_outline</i>
                    <i id="{{'groupIDsInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.OktaUserNames)">add_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList();fileContentForm.reset();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF REMOVE-OKTA-USER-FROM-GROUP READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.OktaGroupName }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Okta Group Name:</b> {{ listedFileContent.OktaGroupName }}
            </div>
            <div class="col-4">
                <b>Users to be removed:</b>
                <div *ngFor="let userGroupId of listedFileContent.OktaUserNames">
                    {{ userGroupId }}
                </div>            
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div>
        </div>
    </div>
</div>