

import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { ActivateGIDFileContent, ActivateGIDRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

@Component({
    selector: 'BodyContent',
    templateUrl: './activateGID.component.html',
    providers: [RequestContainerService,
                { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
    styleUrls: ['../../../app.component.css']
})

export class ActivateGIDComponent{
    
    public fileContentsList = new Array<ActivateGIDFileContent>();
    public fileContent = new ActivateGIDFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    minDate = new Date().setFullYear(new Date().getFullYear() - 100);
    maxDate = new Date();
    invalidBirthDate = '';

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then(cb => {
                if (cb && this.fileContent.BirthDate) {
                    let doc = document.getElementById("birthDatePicker") as HTMLSelectElement;
                    doc.value = this.fileContent.BirthDate;
                }
            });
        });
    }

    ngOnDestroy(){
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<ActivateGIDFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new ActivateGIDFileContent();
        this.invalidBirthDate = '';
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        var tempObject = new ActivateGIDFileContent(this.fileContent);
        let doc = document.getElementById("birthDatePicker") as HTMLSelectElement;
        doc.value = '';
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    birthDateChanged (newValue) {
        let tempDate = new Date(newValue);
        this.invalidBirthDate = '';
        if (newValue && !isNaN(tempDate.valueOf())) {
            this.fileContent.BirthDate = new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000).toISOString()?.split('T')[0];
        }
        else {
            this.fileContent.BirthDate = '';
            this.invalidBirthDate = 'Please pick a valid date of birth';
        }
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && this.fileContent.BirthDate){
            this.addFileContentToList();
        }
        
        var data = new ActivateGIDRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/activateGID')
            .subscribe( response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );        
    }
}
