import { Component, Input } from '@angular/core';
import { CreatePersonalMailboxDetails } from '../../../../Models/History/CreatePersonalMailboxDetails';

@Component({
    selector: 'CreatePersonalMailboxHistoryDetails',
    templateUrl: './createPersonalMailboxHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class CreatePersonalMailboxHistoryDetailsComponent{
    @Input() createPersonalMailboxDetails: CreatePersonalMailboxDetails;
    personalMailboxInfoCollapsed: boolean = false;
}