<h4>Clear Okta B2B User Session:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true">Please fill the user GroupID in Okta</span> 
    </div>

    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="GroupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="GroupIDInput" name="GroupIDInput" class="form-control"
                        [(ngModel)]="fileContent.GroupID"
                        required [pattern]="groupIDPattern"
                        (keyup)="setBackEmptyForm()" #GroupIDInput="ngModel"/>
                <i *ngIf="!GroupIDInput.invalid" class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="getUserInOktaB2B();">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span>
            <div *ngIf="GroupIDInput.invalid && (GroupIDInput.dirty || GroupIDInput.touched)" class="alert alert-danger">
                <div class="break"></div>
                <div *ngIf="GroupIDInput.errors.required">
                    Group ID is required.
                </div>
                <div *ngIf="GroupIDInput.errors.pattern">
                    Group ID format is invalid.
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="isUserInOkta === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    
    <div class="row form-group" *ngIf="isUserInOkta === true">                
        <div class="col-2" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>