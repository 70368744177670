import { Component, OnInit } from '@angular/core';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LicenseCleanupService } from '../../../Services/licenseCleanup.service';
import { Papa } from 'ngx-papaparse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { checkGroupIdFormat, checkGroupIdNoAdminFormat, checkGuidFormat, checkMailAddressFormat, checkPrimarySMTPDomainFormat, checkLitigationHoldDurationFormat, checkLocalITFormat, checknumberFormat } from '../../../Helpers/inputValidationHelper';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { UserService } from '../../../Services/user.service';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { RequestItemService } from '../../../Services/requestItem.service';
import { RequestItem, RequestItemPreview } from '../../../Models/RequestItem';
import { generateUUID, urlPattern, getStatusColor } from '../../../Helpers/utils';
import { FormControl } from '@angular/forms';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { DeleteO365ObjectFileContent, DeleteO365ObjectRequest } from '../../../Models/FileContents';
import { RequestDetailsDialog } from '../../RequestsHistory/HistoryDetailsViews/RequestDetailsDialog/requestDetailsDialog.component';

@Component({
    selector: 'BodyContent',
    templateUrl: './licenseCleanup.component.html',
    styleUrls: ['../../../app.component.css']
})

export class LicenseCleanupComponent implements OnInit {
    public displayDeleteO365Form = false;
    public displayImportButton = false;
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    public organizationsSelected = new FormControl();
    public selectedUsers = [];
    public userAuthorizedOrganizations = [];
    public userAuthorizedLocalITs = [];
    public deleteO365ObjectProductDetails;
    public fileContentsList = [];
    public csvFileName = "";
    public csvImportErrors = [];
    public csvImportErrorsCollapsed = true;
    public successWarnings = [];
    public disableSendButton = false;
    public authorizedLocalITs = ["IT_999", "IT_EGS", "IT_WSD"];
    public refreshButtonDisabled = false;
    public getResultsButtonDisabled = false;
    public clearFiltersDisabled = true;
    public csvResults = [];
    public organizationsSelectionLabel = 'Organizations';
    mergedWarning = false;

    //CSV Table
    public page = 1;
    public maxSize = 5;
    public numPages = 1;
    public length = 0;

    public ticketID = '';
    public fromDate = new Date();
    public toDate = new Date();
    private data = [];

    
    public index = 0;
    public itemsPerPage = 30;
    public order = 'DateCreated DESC'
    public filters = {
        "LocalIT": null,
        "Status": null,
        "Target": null,
        "Product": 'DELETE-O365OBJECT',
        "Requestor": null,
        "TicketID": null,
        "fromDate": this.fromDate.toISOString(),
        "toDate": this.toDate.toISOString()
    }
    public rows = [];

    public columns: Array<any> = [
        { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: this.filters.LocalIT ? this.filters.LocalIT : "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
        { title: 'Target', name: 'Target', internalName: 'Target', filtering: { filterString: this.filters.Target ? this.filters.Target : "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
        { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: this.filters.Status ? this.filters.Status : "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
        { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'statusColumnWidth' },
        { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'statusColumnWidth' },
        { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: this.filters.Requestor ? this.filters.Requestor : "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
        { title: 'TicketID', name: 'TicketID', internalName: 'TicketID', filtering: { filterString: this.filters.TicketID ? this.filters.TicketID : "", placeholder: 'TicketID Filter' }, sort: '', className: 'dateColumnWidth' }
    ];

    public config: any = {
        paging: true,
        sorting: { columns: this.columns },
        filtering: { filterString: '' },
        className: ['table-striped']
    };
    public inProgress: boolean = false;

    //Handling CSV Import
    private parsingOptions = {
        encoding: "ISO-8859-1",
        delimiter: ";",
        header: true,
        skipEmptyLines: true,
        quoteChar: '"',
        escapeChar: '"',
        beforeFirstChunk: function (chunk) {
            var rows = chunk.split(/\r\n|\r|\n/);
            var headings = rows[0].toLowerCase().trim();
            rows[0] = headings;
            return rows.join("\r\n");
        },
        complete: (results, file) => {
            this.checkImportedData(results.data, file.name);
        }
    };

    constructor(
        private requestContainerService: RequestContainerService,
        private requestItemService: RequestItemService,
        private licenseCleanupService: LicenseCleanupService,
        private userService: UserService,
        private localITsService: LocalITsService,
        private businessRulesService: BusinessRulesService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private csvParser: Papa) { }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.inProgress = true;
        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 4));
        this.fromDateChanged(this.fromDate);
        this.toDate = new Date();
        this.toDateChanged(this.toDate);
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => { this.userAuthorizedLocalITs = updatedUserInfo.LocalITs;});
        this.licenseCleanupService.getUserAuthorizedOrganizations().subscribe(response => { this.userAuthorizedOrganizations = response; this.organizationsSelectionLabel = `${this.userAuthorizedOrganizations.length} Organizations retrieved with users to clean on your scope`; });
        this.getFilters();
        this.requestItemService.getRequestItemsCount('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.length = response ? response : 0; });
        this.requestItemService.getRequestItems('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; });
        this.businessRulesService.getSpecificListByKey('PRODUCTS-DETAILS', 'ProductsDetails').subscribe(response => {
            this.deleteO365ObjectProductDetails =  response.find(obj => obj.ProductName === "DELETE-O365OBJECT");
        });
    }

    
    csvDownload() {        
        let propertiesNames = [];
        propertiesNames.push("product");
        this.deleteO365ObjectProductDetails.Properties.forEach(property => {
            propertiesNames.push(property.Name.toLowerCase());
        });
        propertiesNames.push("ticketid");
        propertiesNames.push("localit");
        let rows = [propertiesNames];
        rows.push(["DELETE-O365OBJECT"]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "LicenseCleanupTemplate.csv");
        document.body.appendChild(link);

        link.click();        
    }

    checkImportedData(data, csvFileName) {
        if (data) {
            let productName = "DELETE-O365OBJECT";
            let productProperties = [...this.deleteO365ObjectProductDetails.Properties];
            console.log(this.deleteO365ObjectProductDetails);
            productProperties.push({
                Name: 'LocalIT',
                Mandatory: true,
                Type: 'localit'
            });

            for (let i = 0; i < data.length; i++) {
                let errorInData = false;
                let errordata = [];                

                if (!(productProperties ? true : false) || errorInData) {
                    errorInData = true;
                }
                else {
                    productProperties.forEach(property => {
                        let value = data[i][property.Name.toLowerCase()] ? data[i][property.Name.toLowerCase()].trim() : data[i][property.Name.toLowerCase()];
                        if (property.Mandatory && !value) {
                            //error mandatory missing 
                            errorInData = true;
                            errordata.push(property.Name.toLowerCase());
                            return;
                        }
                        else if (!property.Mandatory && (!value || value === '')) {
                            let arraytype = new RegExp('array');

                            if (arraytype.test(property.Type)) {
                                data[i][property.Name.toLowerCase()] = [];
                            }
                            return;
                        }
                        switch (property.Type) {
                            case 'gid':
                                if (!checkGroupIdFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidNoAdmin':
                                if (!checkGroupIdNoAdminFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'email':
                                if (!checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'stringarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'boolean':
                                if (value.toLowerCase() !== "true" && value.toLowerCase() !== "false" && value.toLowerCase() !== "yes" && value.toLowerCase() !== "no") {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value.toLowerCase() === "true" || value.toLowerCase() === "yes" ? true : false;
                                }
                                break;
                            case 'integer':
                                if (!checknumberFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = parseInt(value);
                                }
                                break;
                            case 'localit':
                                if (!checkLocalITFormat(value) || this.userAuthorizedLocalITs.findIndex(x => x.toLowerCase() == value.toLowerCase()) < 0) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gid,email':
                                if (!checkGroupIdFormat(value) && !checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidNoAdmin,email':
                                if (!checkGroupIdNoAdminFormat(value) && !checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gid,email,guid':
                                if (!checkGroupIdFormat(value) && !checkMailAddressFormat(value) && !checkGuidFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidarray,emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'gidNoAdminarray,emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        if (array.some(element => !checkGroupIdNoAdminFormat(element) && !checkMailAddressFormat(element))) {
                                            errordata.push(property.Name.toLowerCase());
                                            errorInData = true;
                                        }
                                        else {
                                            data[i][property.Name.toLowerCase()] = array;
                                        }
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'gidarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'domain':
                                if (!checkPrimarySMTPDomainFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'lhduration':
                                try {
                                    if (!checkLitigationHoldDurationFormat(value)) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = parseInt(value);
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'phone':
                                break;
                            default:
                                break;
                        }
                    });
                }

                if (!errorInData) {
                    this.fileContentsList.push({ row: data[i], csvLine: i + 2, fileName: csvFileName, productName: productName, properties: productProperties ? productProperties : null });
                } else {
                    this.csvImportErrors.push({ row: data[i], csvLine: i + 2, error: errordata, fileName: csvFileName, productName: productName, properties: productProperties ? productProperties : null });
                }
            }
        }
    }

    parseFromCSV(event) {
        this.clear();
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            var files = event.target.files;
            for (let index = 0; index < files.length; index++) {
                this.csvParser.parse(files[index], this.parsingOptions);
            }
            event.target.value = '';
        }
        this.displayImportButton = true;
    }

    removeFileContentInErrorFromList(index) {
        this.csvImportErrors.splice(index, 1);
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    clear() {
        this.fileContentsList = [];
        this.csvFileName = "";
        this.csvImportErrors = [];
        this.csvImportErrorsCollapsed = true;
        this.successWarnings = [];
        this.mergedWarning = false;
        this.disableSendButton = false;
        this.displayImportButton = false;
        this.displayDeleteO365Form = false;
    }

    fromDateChanged(newValue) {
        var tempDate = new Date(newValue);
        if (!isNaN(tempDate.valueOf())) {
            tempDate = new Date(tempDate.setHours(0, 0, 0, 0));
        }
        this.fromDate = tempDate;
    }

    toDateChanged(newValue) {
        var tempDate = new Date(newValue);
        if (!isNaN(tempDate.valueOf())) {
            tempDate = new Date(tempDate.setHours(23, 59, 59, 999));
        }
        this.toDate = tempDate;
    }

    //Mappeur
    mapRequestItemToRequestItemPreview(requestItems: RequestItem[]): Array<any> {
        let requestItemPreviewList = new Array<RequestItemPreview>();
        requestItems.forEach(element => {
          let requestItemPreview = new RequestItemPreview();
    
          requestItemPreview._key = element._key ? element._key : '';
          requestItemPreview.GUID = element.GUID ? element.GUID : '';
          requestItemPreview.Requestor = element.Requestor ? element.Requestor : '';
          requestItemPreview.Status = element.Status ? element.Status : '';
          requestItemPreview.Target = element.Parameters.GROUPID;
          requestItemPreview.CreatedOn = element.DateCreated ? new Date(element.DateCreated).toLocaleString(navigator.language) : '';
          requestItemPreview.CreatedOnDate = element.DateCreated;
          requestItemPreview.ModifiedOn = element.DateModified ? new Date(element.DateModified).toLocaleString(navigator.language) : '';
          requestItemPreview.ModifiedOnDate = element.DateModified;
          requestItemPreview.ProductName = element.ProductName ? element.ProductName : '';
          requestItemPreview.LocalIT = element.LocalIT ? element.LocalIT : '';
          requestItemPreview.TicketID = element.Parameters.TICKETID;
    
          requestItemPreviewList.push(requestItemPreview);
        });
        return requestItemPreviewList;
      }

    refreshData() {
        this.getFilters();
        this.inProgress = true;
        this.refreshButtonDisabled = true;
        this.requestItemService.getRequestItemsCount('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.length = response ? response : 0; });
        this.requestItemService.getRequestItems('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; this.refreshButtonDisabled = false; });
    }

    public getFilters() {
        if (this.config) {
            this.filters.LocalIT = this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString;
            this.filters.Status = this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString;
            this.filters.Target = this.config.sorting.columns.find(filter => filter.internalName === "Target").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Target").filtering.filterString;
            this.filters.Requestor = this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString;
            this.filters.TicketID = this.config.sorting.columns.find(filter => filter.internalName === "TicketID").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "TicketID").filtering.filterString;
            this.filters.fromDate = this.fromDate.toISOString();
            this.filters.toDate = this.toDate.toISOString();
        }
        var sorted = this.config.sorting.columns.find(column => column.sort != "");
        this.order = sorted ? sorted.internalName + " " + sorted.sort : this.order;
        if (this.filters.LocalIT == null && this.filters.Product == null && this.filters.Status == null && this.filters.Target == null && this.filters.Requestor == null && this.filters.TicketID == null) {
            this.clearFiltersDisabled = true;
        }
        else {
            this.clearFiltersDisabled = false;
        }
    }
    
    public clearFilters() {
        this.columns = [
          { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
          { title: 'Target', name: 'Target', internalName: 'Target', filtering: { filterString: "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
          { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
          { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'dateColumnWidth' },
          { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'dateColumnWidth' },
          { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
          { title: 'TicketID', name: 'TicketID', internalName: 'TicketID', filtering: { filterString: "", placeholder: 'TicketID Filter' }, sort: '', className: 'statusColumnWidth' }
        ];

        this.filters = {
          "LocalIT": null,
          "Status": null,
          "Target": null,
          "Product": 'DELETE-O365OBJECT',
          "Requestor": null,
          "TicketID": null,
          "fromDate": this.fromDate.toISOString(),
          "toDate": this.toDate.toISOString()
        }

        this.ticketID = null;

        this.config = {
            paging: true,
            sorting: { columns: this.columns },
            filtering: { filterString: '' },
            className: ['table-striped']
        };

        this.refreshData();
    }

    public changePage(event) {
        if (event != null) {
            this.index = (event.page - 1) * this.itemsPerPage;
        }
        this.onChangeTable();
    }

    public onChangeTable(event = null) {
        this.inProgress = true;
        this.getFilters();
        this.requestItemService.getRequestItemsCount('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.length = response ? response : 0; });
        this.requestItemService.getRequestItems('ALLOWED', this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; });
    }

    disableRefreshBtn() {
        this.refreshButtonDisabled = true;
        setTimeout(() => { this.refreshButtonDisabled = false }, 2000);
    }

    //Users Table handling
    public usersTablePage = 1;
    public usersTableMaxSize = 5;
    public usersTableNumPages = 1;
    public usersTableLength = 0;
    
    public usersTableData = [];

    public usersNewOwner: string = '';
    public usersTicketID: string = '';
    public usersPreventMailboxReconnect = false;
    public usersPreventResubscribeDL = false;
    
    public usersTableIndex = 0;
    public usersTableItemsPerPage = 10;
    public usersTableOrder = 'OrgID desc'
    public usersTableFilters = {
        "OrgID": null,
        "GID": null,
        "Firstname": null,
        "Lastname": null,
        "PrimarySMTP": null,
        "Licenses": null
    }
    public usersTableRows = [];

    public usersTableColumns = [
        { title: 'OrgID', name: 'OrgID', internalName: 'OrgID', filtering: { filterString: "", placeholder: 'OrgID' }, sort: 'desc', className: 'groupidColumnWidth' },
        { title: 'GID', name: 'GID', internalName: 'GID', filtering: { filterString: "", placeholder: 'GID filter' }, sort: '', className: 'groupidColumnWidth' },
        { title: 'Firstname', name: 'Firstname', internalName: 'Firstname', filtering: { filterString: "", placeholder: 'Firstname filter' }, sort: '', className: 'shortDateColumnWidth' },
        { title: 'Lastname', name: 'Lastname', internalName: 'Lastname', filtering: { filterString: "", placeholder: 'Lastname filter' }, sort: '', className: 'statusColumnWidth' },
        { title: 'PrimarySMTP', name: 'PrimarySMTP', internalName: 'PrimarySMTP', filtering: { filterString: "", placeholder: 'PrimarySMTP filter' }, sort: '', className: 'statusColumnWidth' },
        { title: 'Licenses', name: 'Licenses', internalName: 'Licenses', filtering: { filterString: "", placeholder: 'Licenses filter' }, sort: '', className: 'dateColumnWidth' },
        { title: 'Lastseen', name: 'Lastseen', internalName: 'Lastseen', filtering: { filterString: "", placeholder: 'Lastseen filter' }, sort: '', className: 'shortDateColumnWidth' }
    ];

    public usersTableConfig: any = {
        paging: true,
        sorting: { columns: this.usersTableColumns },
        filtering: { filterString: '' },
        className: ['table-striped']
    };
    public usersTableRefreshInProgress: boolean = false;
    public usersTableRefreshButtonDisabled = false;

    refreshUsersTableData() {
        this.displayDeleteO365Form = false;
        this.displayImportButton = false;
        this.usersTableRefreshInProgress = true;
        this.usersTableRefreshButtonDisabled = true;
        this.selectedUsers = [];

        this.usersNewOwner = '';
        this.usersTicketID = '';
        this.usersPreventMailboxReconnect = false;
        this.usersPreventResubscribeDL = false;

        this.licenseCleanupService.getUserAuthorizedOrganizations().subscribe(response => { this.userAuthorizedOrganizations = response; this.organizationsSelectionLabel = `${this.userAuthorizedOrganizations.length} Organizations retrieved with users to clean on your scope`; });
        //Get users data
        this.licenseCleanupService.getUsersByOrgs(this.organizationsSelected?.value?.join() || '').subscribe(response => { this.usersTableData = response; this.onChangeUsersTable(this.usersTableConfig); this.usersTableRefreshInProgress = false; this.usersTableRefreshButtonDisabled = false;});      
    }

    public isAllUsersSelected(){
        return this.selectedUsers.length > 0 && (this.selectedUsers.length == this.usersTableData.length);
    }

    public selectAllUsers(){
        if(this.isAllUsersSelected()){
            this.selectedUsers = [];
        }else {
            this.selectedUsers = [...this.usersTableData];
        }
    }

    public selectUser(row){
        if(this.isUserSelected(row)){
            this.selectedUsers.splice(this.selectedUsers.findIndex(x => x.GID == row.GID), 1);
        }else{
            this.selectedUsers.push(this.usersTableData.find(x => x.GID == row.GID));
        }
    }

    public isUserSelected(row){
        return this.selectedUsers.find(x => x.GID == row.GID)
    }

    public applySorting(columnName:string){
        var columnIndex = this.usersTableColumns.findIndex(x => x.name == columnName);
        if(columnIndex > -1){
            this.usersTableColumns[columnIndex].sort = this.usersTableColumns[columnIndex].sort === '' || this.usersTableColumns[columnIndex].sort === 'desc' ? this.usersTableColumns[columnIndex].sort = 'asc' : this.usersTableColumns[columnIndex].sort = 'desc';
            for (let i = 0; i < this.usersTableColumns.length; i++) {
                if(i != columnIndex){
                    this.usersTableColumns[i].sort = '';
                }                
            }
            this.onChangeUsersTable(this.usersTableConfig);
        }
    }

    public usersTableChangePage(page:any, data:Array<any> = this.usersTableData):Array<any> {
        let start = (page.page - 1) * page.itemsPerPage;
        let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
        return data.slice(start, end);
    }

    public onChangeUsersTable(config:any, page:any = {page: this.usersTablePage, itemsPerPage: this.usersTableItemsPerPage}):any {
        if (config.filtering) {
            Object.assign(this.usersTableConfig.filtering, config.filtering);
        }

        if (config.sorting) {
            Object.assign(this.usersTableConfig.sorting, config.sorting);
        }

        let filteredData = this.changeFilter(this.usersTableData, this.usersTableConfig);
        let sortedData = this.changeSort(filteredData, this.usersTableConfig);
        this.usersTableRows = page && config.paging ? this.usersTableChangePage(page, sortedData) : sortedData;
        this.usersTableLength = sortedData.length;
    }
    

    disableUsersTableRefreshBtn() {
        this.usersTableRefreshButtonDisabled = true;
        setTimeout(() => { this.usersTableRefreshButtonDisabled = false }, 2000);
    }

    public changeSort(data:any, config:any):any {
        if (!config.sorting) {
          return data;
        }
    
        let columns = this.usersTableConfig.sorting.columns || [];
        let columnName:string = void 0;
        let sort:string = void 0;
    
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].sort !== '' && columns[i].sort !== false) {
            columnName = columns[i].name;
            sort = columns[i].sort;
          }
        }
    
        if (!columnName) {
          return data;
        }
    
        // simple sorting
        return data.sort((previous:any, current:any) => {
          if (previous[columnName] > current[columnName]) {
            return sort === 'desc' ? -1 : 1;
          } else if (previous[columnName] < current[columnName]) {
            return sort === 'asc' ? -1 : 1;
          }
          return 0;
        });
    }
    
    public changeFilter(data:any, config:any):any {
        let filteredData:Array<any> = data;
        this.usersTableColumns.forEach((column:any) => {
            if (column.filtering) {
                filteredData = filteredData.filter((item:any) => {
                    return item[column.name].match(column.filtering.filterString);
                });
            }
        });

        if (!config.filtering) {
            return filteredData;
        }

        if (config.filtering.columnName) {
            return filteredData.filter((item:any) => item[config.filtering.columnName].match(this.usersTableConfig.filtering.filterString));
        }

        let tempArray:Array<any> = [];
        filteredData.forEach((item:any) => {
            let flag = false;
            this.usersTableColumns.forEach((column:any) => {
                if (item[column.name].toString().match(this.usersTableConfig.filtering.filterString)) {
                    flag = true;
                }
            });

            if (flag) {
                tempArray.push(item);
            }
        });
        filteredData = tempArray;

        return filteredData;
    }

    public sendImportedUsersDeleteRequest(){
        this.inProgress = false;
        this.disableSendButton = true;
        var usersToDelete = [...this.fileContentsList].map(x => x.row);

        let localITs = [...new Set(usersToDelete.map(user => user.localit))];

        var requestErrors = [];
        for (let i = 0; i < localITs.length; i++) {
            let localITUsersToDelete = usersToDelete.filter(user => user.localit == localITs[i]);            
            let deleteO365ObjectFileContents:Array<DeleteO365ObjectFileContent> = [];

            for (let j = 0; j < localITUsersToDelete.length; j++) {
                let deleteO365ObjectFileContent = new DeleteO365ObjectFileContent();
                deleteO365ObjectFileContent.GroupID = localITUsersToDelete[j].groupid;
                deleteO365ObjectFileContent.NewOwner = localITUsersToDelete[j].newowner;
                deleteO365ObjectFileContent.PreventMailboxReconnect = localITUsersToDelete[j].preventmailboxreconnect;
                deleteO365ObjectFileContent.PreventResubscribeDL = localITUsersToDelete[j].preventresubscribedl;
                deleteO365ObjectFileContent.TicketID = localITUsersToDelete[j].ticketid;
                deleteO365ObjectFileContents.push(deleteO365ObjectFileContent);
            }

            let deleteO365ObjectRequest = new DeleteO365ObjectRequest();
            deleteO365ObjectRequest.requests = deleteO365ObjectFileContents;
            deleteO365ObjectRequest.localIT = localITs[i];

            this.requestContainerService.sendProductRequest(deleteO365ObjectRequest, '/deleteO365Object').subscribe( response => {
                console.log("RequestSent with success:", deleteO365ObjectRequest);
            }, 
            error => {
                requestErrors.push(error);
            });
        }

        if(requestErrors.length > 0){
            this.inProgress = false;
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : requestErrors } });
        }else{  
            this.inProgress = false;          
            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold'],                  
            });

            //Redirect to request history page
            successSnackBar.afterDismissed().subscribe(null, null, () => {
                //this.refreshUsersTableData()
            });
        }
        this.fileContentsList = [];
        this.csvImportErrors = [];
    }


    public sendSelectedUsersDeleteRequest(){
        var usersToDelete = [...this.selectedUsers];

        for (let i=0; i < usersToDelete.length; i++) {
            usersToDelete[i].LocalIT = this.userAuthorizedOrganizations.find(x => x.OrgID == usersToDelete[i].OrgID)?.LocalIT;            
        }

        let localITs = [...new Set(usersToDelete.map(user => user.LocalIT))];

        var requestErrors = [];
        for (let i = 0; i < localITs.length; i++) {
            let localITUsersToDelete = usersToDelete.filter(user => user.LocalIT == localITs[i]);            
            let deleteO365ObjectFileContents:Array<DeleteO365ObjectFileContent> = [];

            for (let j = 0; j < localITUsersToDelete.length; j++) {
                let deleteO365ObjectFileContent = new DeleteO365ObjectFileContent();
                deleteO365ObjectFileContent.GroupID = localITUsersToDelete[j].GID;
                deleteO365ObjectFileContent.NewOwner = this.usersNewOwner;
                deleteO365ObjectFileContent.PreventMailboxReconnect = this.usersPreventMailboxReconnect;
                deleteO365ObjectFileContent.PreventResubscribeDL = this.usersPreventResubscribeDL;
                deleteO365ObjectFileContent.TicketID = this.usersTicketID;
                deleteO365ObjectFileContents.push(deleteO365ObjectFileContent);
            }

            let deleteO365ObjectRequest = new DeleteO365ObjectRequest();
            deleteO365ObjectRequest.requests = deleteO365ObjectFileContents;
            deleteO365ObjectRequest.localIT = localITs[i];

            this.requestContainerService.sendProductRequest(deleteO365ObjectRequest, '/deleteO365Object').subscribe( response => {
                console.log("RequestSent with success:", deleteO365ObjectRequest);
            }, 
            error => {
                requestErrors.push(error);
            });
        }

        if(requestErrors.length > 0){
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : requestErrors } });
        }else{            
            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold'],                  
            });

            //Redirect to request history page
            successSnackBar.afterDismissed().subscribe(null, null, () => {
                this.refreshUsersTableData()
            });
        }
    }

    public exportSelectedUsers(withParams){
        this.displayImportButton = true;
        let propertiesNames = [];
        propertiesNames.push("product");
        this.deleteO365ObjectProductDetails.Properties.forEach(property => {
            propertiesNames.push(property.Name.toLowerCase());
        });
        propertiesNames.push("ticketid");
        propertiesNames.push("localit");
        propertiesNames.push("orgid");
        propertiesNames.push("firstname");
        propertiesNames.push("lastname");
        propertiesNames.push("primarysmtp");
        propertiesNames.push("licenses");
        propertiesNames.push("lastseen");
        let rows = [propertiesNames];

        for (let i = 0; i < this.selectedUsers.length; i++) {
            rows.push(
                ["DELETE-O365OBJECT",
                this.selectedUsers[i].GID, 
                withParams ? this.usersPreventMailboxReconnect : false, 
                withParams ? this.usersPreventResubscribeDL : false, 
                withParams ? this.usersNewOwner : "", 
                withParams ? this.usersTicketID : "",
                this.userAuthorizedOrganizations.find(x => x.OrgID == this.selectedUsers[i].OrgID)?.LocalIT,
                this.selectedUsers[i].OrgID,
                this.selectedUsers[i].Firstname,
                this.selectedUsers[i].Lastname,
                this.selectedUsers[i].PrimarySMTP,
                this.selectedUsers[i].Licenses,
                this.selectedUsers[i].Lastseen
            ]);
        }       

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `LicenseCleanupExtract-${(new Date()).toISOString()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will trigger the download        
    }

    public getStatusColor(status){
        return getStatusColor(status);
    }

    public onRowClick(row: any): any {
        if (row && row.GUID && row.LocalIT) {
            this.openRequestDetailsDialog(row.GUID, row.LocalIT);
        }
    }
    
    //Popup Initialization
    openRequestDetailsDialog(requestId, localIT) {
        const dialogRef = this.dialog.open(RequestDetailsDialog, {
                height: '73%',
                width: '80%',
                data: {
                    requestId: requestId,
                    selectedLocalIT: localIT
            }
        });
    }
}