import { RequestItem } from "app/Models/RequestItem";

export class GetDistributionListInfoDetails{
    DisplayName : string;
    Type : string;
    Alias : string;
    Mail : string;
    ProxyAddresses : [];
    AcceptFrom : [];
    RejectFrom : [];
    Moderators : [];
    Trustee : [];
    Description : string;
    Created : string;
    Owner : {};
    AdditionalOwners : [];
    Members : [];
    Hidden : boolean;

    constructor(requestItem?: RequestItem){
        if(requestItem){
            const output = requestItem?.Output || {};
            this.DisplayName = output.displayName || "";
            this.Type = output.type || "";
            this.Hidden = output.isHidden || false;
            this.AcceptFrom = output.acceptMessagesOnlyFrom || [];
            this.RejectFrom = output.rejectMessagesFrom || [];
            this.Moderators = output.moderatedBy || [];
            this.Trustee = output.sendAsPermissionsRecipients || [];
            this.Alias = output.alias || "";
            this.Mail = output.primarySMTPAddress || "";
            this.ProxyAddresses = output.emailAddresses || [];
            this.Description = output.description || "";
            this.Created = output.createdDate || "";
            this.Owner = output.owner || { Alias: "", DisplayName: "", PrimarySmtpAddress: "" };
            this.AdditionalOwners = output.additionalOwners || [];
            this.Members = output.members || [];
        }
    }
}