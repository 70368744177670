<!-- https://stackblitz.com/edit/dynamic-nested-topnav-menu?file=app%2Fsecond%2Fsecond.component.ts -->
<nav class="navbar navbar-expand-lg navbar-light bg-primary">
    <div class="form-inline">
        <button class="navbar-toggler" style="margin-right:20px;" type="button" data-toggle="collapse"
            data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" routerLink="/" routerLinkActive="active" style="color:white">PEO</a>
    </div>
    <!-- LEFT PART OF THE MENU -->
    <div class="collapse navbar-collapse" id="navbarContent">
        <div class="navbar navbar-toggleable-md navbar-light bg-primary nopadding">
            <span *ngFor="let item of localITleftMenu">
                <!-- Handle branch node buttons here -->
                <span *ngIf="item.children && item.children.length > 0">
                    <button class="btn btn-primary" *ngIf="!item.disabled" mat-button
                        [matMenuTriggerFor]="menu.childMenu">
                        {{item.displayName}}
                    </button>
                    <app-menu-item #menu [items]="item.children"></app-menu-item>
                </span>
                <!-- Leaf node buttons here -->
                <span *ngIf="(!item.children || item.children.length === 0) && item.href">
                    <a class="btn btn-primary" mat-button [href]="item.href" target="_blank">
                        {{item.displayName}}
                    </a>
                </span>
                <span *ngIf="(!item.children || item.children.length === 0) && item.route">
                    <button class="btn btn-primary" mat-button [routerLink]="item.route">
                        {{item.displayName}}
                    </button>
                </span>
            </span>
        </div>
    </div>
    <!-- RIGHT PART OF THE MENU -->
    <div id="navbarRight" class="navbar-toggler-right">
        <div class="righttoolbar">
            <span *ngFor="let item of localITrightMenu">
                <!-- Handle branch node buttons here -->
                <span *ngIf="item.children && item.children.length > 0">
                    <button class="btn btn-primary right" *ngIf="!item.disabled" mat-button
                        [matMenuTriggerFor]="menu.childMenu">
                        {{item.displayName}}
                    </button>
                    <app-menu-item #menu [items]="item.children"></app-menu-item>
                </span>
                <!-- Leaf node buttons here -->
                <span *ngIf="(!item.children || item.children.length === 0) && item.href">
                    <a class="btn btn-primary" mat-button [href]="item.href" target="_blank">
                        {{item.displayName}}
                    </a>
                </span>
                <span *ngIf="(!item.children || item.children.length === 0) && item.route">
                    <button class="btn btn-primary" mat-button [routerLink]="item.route">
                        {{item.displayName}}
                    </button>
                </span>
            </span>
        </div>
        <select *ngIf="(localITdetails && localITdetails.Name && localITdetails.Name.length > 0) || selectedLocalIT === 'SHOW ALL'" id="selectCurrentLocalIT" (ngModelChange)="onLocalITChange($event)" class="form-control"
            [(ngModel)]="selectedLocalIT" name="selectCurrentLocalIT">
            <option *ngFor="let item of currentUserInfo.LocalITs" [value]="item">{{item}}</option>
        </select>
        <button *ngIf="!isAuthenticated" (click)="oktaAuth.login()" class="btn btn-primary"><i class="ms-Icon ms-Icon--Signin"
            aria-hidden="true"></i></button>
        <button *ngIf="isAuthenticated" (click)="oktaAuth.logout()" class="btn btn-primary"><i class="ms-Icon ms-Icon--SignOut"
                aria-hidden="true"></i></button>
    </div>

</nav>

<div *ngIf="messageInfo" class="alert {{messageInfo.Level}}" role="alert">
    {{messageInfo.Message}}</div>