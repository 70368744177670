<h4>Create Okta B2B CloudAccount:</h4>

<!-- ADD FileContent FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="oktaUsernameInput">Okta Username:</label>
            <div style="display:flex">
                <input type="text" id="oktaUsernameInput" class="form-control" (keyup)="setBackEmptyForm()"
                    [(ngModel)]="fileContent.OktaUsername" name="oktaUsernameInput" [pattern]="mailPattern" required #oktaUsernameInput="ngModel">
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="checkOktaB2BUsername();">search</i>
            </div>
            <div *ngIf="oktaUsernameInput.invalid && (oktaUsernameInput.dirty || oktaUsernameInput.touched)" class="alert alert-danger">
                <div *ngIf="oktaUsernameInput.errors.required">
                    Okta Username is required.
                </div>
                <div *ngIf="oktaUsernameInput.errors.pattern">
                    Okta Username is not a valid email address.
                </div>
            </div>
            <small *ngIf="fileContent.GroupIDDisplayName && !oktaUsernameInput.invalid" id="groupIDDisplayName" class="form-text text-danger">User already exists! ({{fileContent.GroupIDDisplayName}})</small>
            <small *ngIf="fileContent.GroupIDDisplayNameError && !oktaUsernameInput.invalid" id="groupIDDisplayNameError" class="form-text text-primary">{{ fileContent.GroupIDDisplayNameError }}</small>
            <small *ngIf="!fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError" id="groupIDDisplayNameWarning" class="text-danger">Please check the Okta Username</small>
        </div>
        <div class="col-4" *ngIf="fileContent.GroupIDDisplayNameError && fileContent.GroupIDDisplayNameError.includes('is available')">
            <GroupIDInputFieldOkta label="Sponsor Group ID" nameSuffix="Sponsor" B2B="OktaB2B" groupIDShouldBeActive="true" groupIDShouldNotBeAnOktaCloudAccount="true" (groupIDProfileChange)="fillFormBasedOnSponsorGroupID($event)" (groupIDDisplayNameChange)="fileContent.SponsorDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.SponsorDisplayNameError=$event" [(groupID)]="fileContent.SponsorGroupID" ></GroupIDInputFieldOkta>
            <small *ngIf="!fileContent.SponsorDisplayName && !fileContent.SponsorDisplayNameError" id="sponsorDisplayNameWarning" class="text-danger">Please check the Sponsor GroupID</small>
        </div>
        <div class="col-4" *ngIf="fileContent.SponsorDisplayName">
            <label class="required" for="countryCodeInput">Country Code:</label>
            <input type="text" id="countryCodeInput" class="form-control"
                    [(ngModel)]="fileContent.CountryCode" name="countryCodeInput" [pattern]="countryCodePattern" [maxlength]="2" required #countryCodeInput="ngModel">
            <div *ngIf="countryCodeInput.invalid && (countryCodeInput.dirty || countryCodeInput.touched)" class="alert alert-danger">
                <div *ngIf="countryCodeInput.errors.required">
                    Country Code is required.
                </div>
                <div *ngIf="countryCodeInput.errors.pattern">
                    Country Code should contain 2 characters.
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group" *ngIf="fileContent.SponsorDisplayName">
        <div class="col-4">
            <label class="required" for="lastNameInput">Last Name:</label>
            <input type="text" id="lastNameInput" class="form-control"
                    [(ngModel)]="fileContent.LastName" name="lastNameInput" [pattern]="namePattern" required #lastNameInput="ngModel">
            <div *ngIf="lastNameInput.invalid && (lastNameInput.dirty || lastNameInput.touched)" class="alert alert-danger">
                <div *ngIf="lastNameInput.errors.required">
                    Last Name is required.
                </div>
                <div *ngIf="lastNameInput.errors.pattern">
                    Last Name contains an illegal character.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="firstNameInput">First Name:</label>
            <input type="text" id="firstNameInput" class="form-control"
                    [(ngModel)]="fileContent.FirstName" name="firstNameInput" [pattern]="namePattern" required #firstNameInput="ngModel">
            <div *ngIf="firstNameInput.invalid && (firstNameInput.dirty || firstNameInput.touched)" class="alert alert-danger">
                <div *ngIf="firstNameInput.errors.required">
                    First Name is required.
                </div>
                <div *ngIf="firstNameInput.errors.pattern">
                    First Name contains an illegal character.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="auto">Company:</label>
            <input type="text"
                placeholder="Select or start typing company and select"
                aria-label="Number"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
                class="form-control"
                required
                id="auto">
            <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="updateCompany($event.option)">
                <mat-option *ngFor="let app of filteredOptions | async" [value]="app">
                    {{app}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myControl.hasError('forbiddenNames')">
                <strong>'{{myControl.errors.forbiddenNames.value}}'</strong> is not selected from the list
            </mat-error>
            <mat-error *ngIf="myControl.hasError('required') && (myControl.touched || myControl.dirty)">
                Company is required.
            </mat-error>
            <small *ngIf="fileContent.Company && (fileContent.Company?.toUpperCase() == sponsor.Company?.toUpperCase()) && !myControl.touched" id="companyImportedFromSponsor" class="text-success">Imported from sponsor</small>
        </div>
    </div>
    <div class="row form-group" *ngIf="fileContent.SponsorDisplayName">
        <div class="col-4">
            <label class="required" for="oktaGroupNameInput">Okta Group Name:</label>
            <input type="text" id="oktaGroupNameInput" class="form-control"
                [(ngModel)]="fileContent.OktaGroupName" name="oktaGroupNameInput" required #oktaGroupNameInput="ngModel">
            <div *ngIf="oktaGroupNameInput.invalid && (oktaGroupNameInput.dirty || oktaGroupNameInput.touched)" class="alert alert-danger">
                <div *ngIf="oktaGroupNameInput.errors.required">
                    Okta Group Name is required.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="orgIDDropdownList">OrgID:</label>
            <select id="orgIDDropdownList" class="form-control"
                [(ngModel)]="fileContent.OrgID" name="orgIDDropdownList"
                required #orgIDDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let orgID of OrgIDs | orderby: 'OrgID' : false : true" [value]="orgID.OrgID">{{orgID.OrgID}} - {{orgID.Name}}</option>
            </select>
            <div *ngIf="orgIDDropdownList.invalid && (orgIDDropdownList.dirty || orgIDDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="orgIDDropdownList.errors.required">
                    OrgID is required.
                </div>         
            </div>
            <small *ngIf="fileContent.OrgID && (fileContent.OrgID?.toUpperCase() == sponsor.OrgID?.toUpperCase())" id="orgIDImportedFromSponsor" class="text-success">Imported from sponsor</small>
        </div>
        <div class="col-4">
            <label for="expirationDateInput">Expiration Date:</label>
            <input type="text" id="expirationDateInput" class="form-control"
                [(ngModel)]="fileContent.ExpirationDate" name="expirationDateInput"             
                placeholder="YYYY-MM-DD" pattern="([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))" #expirationDateInput="ngModel">
            <div *ngIf="expirationDateInput.invalid && (expirationDateInput.dirty || expirationDateInput.touched)" class="alert alert-danger">
                <div *ngIf="expirationDateInput.errors.pattern">
                    Wrong Date Format!
                </div>
            </div>
            <small id="maxDateValue" class="text-warning">Maximum {{ maxDate }}</small>
        </div>
    </div>
    <div class="row form-group" *ngIf="fileContent.SponsorDisplayName">
        <div class="col-4">
            <label for="externalCompanyInput">External Company:</label>
            <input type="text" id="externalCompanyInput" class="form-control"
                [(ngModel)]="fileContent.ExternalCompany" name="externalCompanyInput" #externalCompanyInput="ngModel">
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.OktaUsername }}</b> - 
            {{ listedFileContent.SponsorGroupID }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Okta Username:</b> {{ listedFileContent.OktaUsername }}
                </div>
                <div class="col-4">
                    <b>Sponsor Group Id:</b> {{ listedFileContent.SponsorGroupID }}
                </div>
                <div class="col-4">
                    <b>Country Code:</b> {{ listedFileContent.CountryCode }}
                </div>
                <div class="col-4">
                    <b>Last Name:</b> {{ listedFileContent.LastName }}
                </div>
                <div class="col-4">
                    <b>First Name:</b> {{ listedFileContent.FirstName }}
                </div>
                <div class="col-4">
                    <b>Company:</b> {{ listedFileContent.Company }}
                </div>
                <div class="col-4">
                    <b>Okta Group Name:</b> {{ listedFileContent.OktaGroupName }}
                </div>
                <div class="col-4">
                    <b>OrgID:</b> {{ listedFileContent.OrgID }}
                </div>
                <div class="col-4">
                    <b>Expiration Date:</b> {{ listedFileContent.ExpirationDate }}
                </div>
                <div class="col-4">
                    <b>External Company:</b> {{ listedFileContent.ExternalCompany }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>