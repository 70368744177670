<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST MAIL DELIVERY RESTRICTION
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">Accept From</th>
                <td>
                    <div *ngFor="let AcceptFrom of productCustomHistoryDetails.AcceptFrom">
                        {{ AcceptFrom }}<br />
                    </div>
                </td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Reject From</th>
                <td>
                    <div *ngFor="let RejectFrom of productCustomHistoryDetails.RejectFrom">
                        {{ RejectFrom }}<br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>