import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { GraphApiService } from '../../../Services/graphApi.service';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'GroupIDInputField',
    templateUrl: './groupIDInputField.component.html',
    styleUrls: ['../../../app.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class GroupIDInputFieldComponent{
    public groupIDPattern:string = '';
    groupIDDisplayNameValue:string = '';
    groupIDValue:string = '';
    groupIDDisplayNameErrorValue:string = '';
    
    constructor(private graphApiService: GraphApiService){}    

    @Input() label: string;
    @Input() noAdmin: string;
    @Output() groupIDChange = new EventEmitter();
    @Output() groupIDDisplayNameChange = new EventEmitter();
    @Output() groupIDDisplayNameErrorChange = new EventEmitter();

    @Input()
    get groupID() {
        return this.groupIDValue;
    }

    set groupID(val){
        this.groupIDValue = val;
        this.groupIDChange.emit(this.groupIDValue);
        this.groupIDDisplayName = '';
        this.groupIDDisplayNameError = '';
        this.groupIDPattern = this.noAdmin ? inputValidationHelper.groupIdNoAdminFormatRegEx : inputValidationHelper.groupIdFormatRegEx;
    }

    get groupIDDisplayNameError() {
        return this.groupIDDisplayNameErrorValue;
    }

    get groupIDDisplayName() {
        return this.groupIDDisplayNameValue;
    }

    set groupIDDisplayName(val){
        this.groupIDDisplayNameValue = val;
        this.groupIDDisplayNameChange.emit(this.groupIDDisplayNameValue);
    }

    set groupIDDisplayNameError(val){
        this.groupIDDisplayNameErrorValue = val;
        this.groupIDDisplayNameErrorChange.emit(this.groupIDDisplayNameErrorValue);
    }
    
    checkGroupIDValidity(){
        let groupIDValueCopy = new String(this.groupIDValue ? this.groupIDValue : '');
        if(this.noAdmin ? inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy) : inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.graphApiService.getUserDisplayNameByGroupID(groupIDValueCopy.toString()).subscribe(
                response => {
                    this.groupIDDisplayName = response;
                    this.groupIDDisplayNameError = '';                   
                },
                error => {
                    if(error && error.status && error.status == '404'){
                        this.groupIDDisplayNameError = groupIDValueCopy + ' not found on AzureAD!';
                        this.groupIDDisplayName = '';
                    }else{
                        this.groupIDDisplayNameError = 'Error when trying to check GroupID!';
                        this.groupIDDisplayName = '';
                    }
                }
            )
        }
    }

}