import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/dnsAPIs";
export const API_VERSION = "/v1";

@Injectable()
export class DnsApiService {
    constructor(private http: HttpClient) {}

    getDnsRecordsByType(type:string, domain:string, server:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDnsRecordsByType';
        let params = new HttpParams();
        params = params.append('type', type);
        params = params.append('domain', domain);
        params = params.append('server', server);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    getDnsZones():Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDnsZones';
        return this.http.get(request) as Observable<any>;
    }

    getDnsSubzones(zone:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDnsSubzones';
        let params = new HttpParams();
        params = params.append('zone', zone);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    createDnsRecords(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/createDnsRecords';
        return this.http.post(request,data).pipe(map(response => {return response as Array<any>}));
    }
}