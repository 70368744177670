import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { MailboxDelegationFileContent, MailboxDelegationRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { removeArrayEmptyValues } from '../../../Helpers/utils';


@Component({
    selector: 'BodyContent',
    templateUrl: './addMailboxDelegation.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddMailboxDelegationComponent{
    
    
    
    

    public fileContentsList = new Array<MailboxDelegationFileContent>();
    public fileContent = new MailboxDelegationFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb && this.fileContent.AddSendAs) {
                    this.fileContent.PermissionToAdd = "SendAs";
                }
            });
        });
    }

    ngOnDestroy(){
        
        
        
    }

    clearValues() {
        this.fileContent.SendOnBehalfUsers = new Array<string>('');
        this.fileContent.SendAsUsers = new Array<string>('');
    }

    errorsInForm() {
        return this.fileContent.GroupIDDisplayNameError || (this.fileContent.FullAccessUsers.every(item => item.trim() == '') && this.fileContent.SendAsUsers.every(item => item.trim() == '') && this.fileContent.SendOnBehalfUsers.every(item => item.trim() == '')) || this.fileContent.SendAsUsers.some(item => item.trim() != '' && this.fileContent.SendOnBehalfUsers.includes(item));
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<MailboxDelegationFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new MailboxDelegationFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }
    
    addFileContentToList(){
        var tempObject = new MailboxDelegationFileContent(this.fileContent);
        tempObject.FullAccessUsers = removeArrayEmptyValues(tempObject.FullAccessUsers);
        tempObject.SendAsUsers = removeArrayEmptyValues(tempObject.SendAsUsers);
        tempObject.SendOnBehalfUsers = removeArrayEmptyValues(tempObject.SendOnBehalfUsers);
        if (tempObject.PermissionToAdd == "SendAs") {
            tempObject.SendAsUsers = tempObject.FullAccessUsers;
            tempObject.SendOnBehalfUsers = new Array<string>('');
        }
        else if (tempObject.PermissionToAdd == "SendOnBehalf") {
            tempObject.SendAsUsers = new Array<string>('');
            tempObject.SendOnBehalfUsers = tempObject.FullAccessUsers;
        }
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }
    
    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && !this.errorsInForm()){
            this.addFileContentToList();
        }

        var data = new MailboxDelegationRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/addMailboxDelegation')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }
}