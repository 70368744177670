<h4>Reset User Token:</h4>

<form #getUserInfoForm="ngForm" (ngSubmit)="getUserInfoForm.resetForm()">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event"
                [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList();"
                [disabled]="getUserInfoForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getUserInfoForm.invalid);"
                [disabled]="(getUserInfoForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF GETUSERINFO READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b> <span
                *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            <i id="{{'getUserInfoRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
</div>