import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { RequestContainerService } from '../../../Services/requestContainer.service';

import { OktaApiService } from '../../../Services/oktaApi.service';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './createMerakiNetwork.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class CreateMerakiNetworkComponent {
    public fileContentsList = [];
    public fileContent = {
        SiteName: null,
        OrgID: null,
        ProductTypes: null,
        Notes: null,
        CopyFromNetworkID: null,
        TicketID: null
    };
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public localITNotFound: string = '';
    public isUserInOkta: boolean = false;
    public howToUseInfo: boolean = true;
    public productTypes = [
        "appliance",
        "camera",
        "cellularGateway",
        "secureConnect",
        "sensor",
        "switch",
        "systemsManager",
        "wireless",
        "wirelessController"
    ];

    public groupIDPattern: string = inputValidationHelper.groupIdFormatRegEx;

    constructor(
        private requestContainerService: RequestContainerService,
        private userService: UserService,
        private oktaApiService: OktaApiService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent = {
                    SiteName: null,
                    OrgID: null,
                    ProductTypes: null,
                    Notes: null,
                    CopyFromNetworkID: null,
                    TicketID: null
                };
                this.isUserInOkta = false;
                this.localITNotFound = '';
            });
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = [];
    }

    clearFileContentForms() {
        this.fileContent = {
            SiteName: null,
            OrgID: null,
            ProductTypes: null,
            Notes: null,
            CopyFromNetworkID: null,
            TicketID: null
        };
        this.isUserInOkta = false;
    }

    handleCollapseBtn(id) {
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList() {
        var tempObject = this.fileContent;
        this.fileContentsList = [tempObject];
        this.clearFileContentForms();
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false) {
            this.addFileContentToList();
        }

        var data = {};
        data["requests"] = this.fileContentsList;
        data["localIT"] = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/createMerakiNetwork')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
} 