<h4>Enable Litigation Hold:</h4>

<form #requestForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIdOrMailInputField label="Group ID or email address" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIdOrMailInputField>
        </div>
        <div class="col-4">
            <label class="required" for="litigationHoldDuration">Litigation Hold Duration:</label>
            <select id="litigationHoldDuration" class="form-control"
                [(ngModel)]="fileContent.LitigationHoldDuration" name="litigationHoldDuration">
                <option *ngFor="let duration of litigationHoldDurations | orderby: '' : false : true" [value]="duration">{{duration}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="litigationHoldOwnerInput">Litigation Hold Owner:</label>
            <input type="text" id="litigationHoldOwnerInput" name="litigationHoldOwnerInput" class="form-control"
                [(ngModel)]="fileContent.LitigationHoldOwner" #litigationHoldOwnerInput="ngModel"
                [pattern]="groupIdOrMailPattern">

            <div *ngIf="litigationHoldOwnerInput.invalid && (litigationHoldOwnerInput.dirty || litigationHoldOwnerInput.touched)"
                    class="alert alert-danger">
                <div *ngIf="litigationHoldOwnerInput.errors.pattern">
                        Group Id or email format is invalid.
                </div>               
            </div>            
        </div>
        <div class="col-4">
            <label for="assignOrUpgradeLicense">Assign Or Upgrade License:</label>
            <div id="assignOrUpgradeLicense">
                <div style="display: inline-block;">
                    <label><input type="radio" id="assignOrUpgradeLicenseOpt1" name="assignOrUpgradeLicense" class="form-control" [value]="true" [(ngModel)]="fileContent.AssignOrUpgradeLicense" #assignOrUpgradeLicenseOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="assignOrUpgradeLicenseOpt2" name="assignOrUpgradeLicense" class="form-control" [value]="false" [(ngModel)]="fileContent.AssignOrUpgradeLicense" #assignOrUpgradeLicenseOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div> 
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); requestForm.form.markAsPristine(); requestForm.form.markAsUntouched();" [disabled]="requestForm.invalid || fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(requestForm.invalid); requestForm.reset();" [disabled]="((requestForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            </div>
            <div class="col-4">
                <b>Litigation Hold Duration:</b> {{ listedFileContent.LitigationHoldDuration }}
            </div>
            <div class="col-4">
                <b>Litigation Hold Owner:</b> {{ listedFileContent.LitigationHoldOwner }}
            </div>
            <div class="col-4">
                <b>AssignOrUpgradeLicense:</b> {{ booleanToStringConverterImport(listedFileContent.AssignOrUpgradeLicense, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div>
        </div>
    </div>
</div>