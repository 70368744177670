<div *ngIf="mandatory !== 'false'" class='form-group'>
    <div class='form-group'>
        <label [class]="mandatory !== 'false' ? 'required' : ''" for="mailInputOkta{{nameSuffix}}">{{label}}:</label>
        <div style="display:flex">
            <input type="text" id="mailInputOkta{{nameSuffix}}" name="mailInputOkta{{nameSuffix}}" class="form-control" [mailShouldNotExist]="mailShouldNotExist" [B2B]="B2B"
                [(ngModel)]="mail" #mailInputOkta="ngModel"
                required [pattern]="mailPattern">
            <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="checkMailValidity();">search</i>
        </div>
        <small *ngIf="!mailShouldNotExist && mailDisplayName && !mailInputOkta.invalid" id="mailDisplayName" class="form-text text-primary">{{ mailDisplayName }}</small>
        <small *ngIf="!mailShouldNotExist && mailDisplayNameError && !mailInputOkta.invalid" id="mailDisplayNameError" class="form-text text-danger">{{ mailDisplayNameError }}</small>
        <small *ngIf="mailShouldNotExist && mailDisplayName && !mailInputOkta.invalid" id="mailDisplayName" class="form-text text-danger">User already exists! ({{ mailDisplayName }})</small>
        <small *ngIf="mailShouldNotExist && mailDisplayNameError && !mailInputOkta.invalid" id="mailDisplayNameError" class="form-text text-primary">{{ mailDisplayNameError }}</small>      
        <div *ngIf="mailInputOkta.invalid && (mailInputOkta.dirty || mailInputOkta.touched)" class="alert alert-danger">        
            <div *ngIf="mailInputOkta.errors.required">
                {{label}} is required.
            </div>
            <div *ngIf="mailInputOkta.errors.pattern">
                {{label}} format is invalid.
            </div>
        </div>
    </div>
</div>
<div *ngIf="mandatory == 'false'" class='form-group'>
    <div class='form-group'>
        <label for="mailInputOkta{{nameSuffix}}">{{label}}:</label>
        <div style="display:flex">
            <input type="text" id="mailInputOkta{{nameSuffix}}" name="mailInputOkta{{nameSuffix}}" class="form-control" [mailShouldNotExist]="mailShouldNotExist" [B2B]="B2B"
                [(ngModel)]="mail" #mailInputOkta="ngModel"
                [pattern]="mailPattern">
            <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="checkMailValidity();">search</i>
        </div>
        <small *ngIf="!mailShouldNotExist && mailDisplayName && !mailInputOkta.invalid" id="mailDisplayName" class="form-text text-primary">{{ mailDisplayName }}</small>
        <small *ngIf="!mailShouldNotExist && mailDisplayNameError && !mailInputOkta.invalid" id="mailDisplayNameError" class="form-text text-danger">{{ mailDisplayNameError }}</small>
        <small *ngIf="mailShouldNotExist && mailDisplayName && !mailInputOkta.invalid" id="mailDisplayName" class="form-text text-danger">User already exists! ({{ mailDisplayName }})</small>
        <small *ngIf="mailShouldNotExist && mailDisplayNameError && !mailInputOkta.invalid" id="mailDisplayNameError" class="form-text text-primary">{{ mailDisplayNameError }}</small>
        <div *ngIf="mailInputOkta.invalid && (mailInputOkta.dirty || mailInputOkta.touched)" class="alert alert-danger">
            <div *ngIf="mailInputOkta.errors.pattern">
                {{label}} format is invalid.
            </div>
        </div>
    </div>
</div>