import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RequestHistoryDetails } from '../../../../Models/History/RequestHistoryDetails';
import { RequestItem } from '../../../../Models/RequestItem';
import { GetDeletedUserInfoDetails } from '../../../../Models/History/GetDeletedUserInfoDetails';
import { LocalITsService } from '../../../../Services/localITs.service';
import { RequestItemService } from '../../../../Services/requestItem.service';
import { UserService } from '../../../../Services/user.service';
import { DeletedO365ObjectsService } from '../../../../Services/deletedO365Objects.service';
import { getStatusColor } from '../../../../Helpers/utils';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';


@Component({
    selector: 'requestDetailsDialog',
    templateUrl: './requestDetailsDialog.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class RequestDetailsDialog {
    public requestItem: RequestItem;
    public getDeletedUserInfoDetails: GetDeletedUserInfoDetails;
    public requestHistoryDetails: RequestHistoryDetails = new RequestHistoryDetails();
    public requestHistoryCollapsed: boolean = true;
    public isUpdateCustomerProfileDetailsCollapsed: boolean = false;
    public selectedLocalIT: string;

    constructor(public dialogRef: MatDialogRef<RequestDetailsDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar, public dialog: MatDialog, private requestItemService: RequestItemService, private deletedO365ObjectsService: DeletedO365ObjectsService, private userService: UserService, private router: Router, private localITsService: LocalITsService) {
        if (data && data.requestId && data.selectedLocalIT) {
            this.selectedLocalIT = data.selectedLocalIT;
            if (data.requestId.startsWith("APIM-") && data.status.match(/InProgress|Preparing|Queued|Running|^Wait/i)) {
                this.requestItemService.updateSquidRequest({RequestId : data.requestId}).subscribe({
                    next: (response) => {
                        this.requestItem = response;
                        this.handleRequestItem();
                    },
                    error: (error) => {
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                    }
                });
            }
            else {
                this.requestItemService.getRequestItemByGuid(data.requestId, data.selectedLocalIT).subscribe(response => {
                    this.requestItem = response;
                    this.handleRequestItem();
                });
            }
        }
    }

    handleRequestItem() {
        if (this.requestItem.Status == 'Failure' || this.requestItem.Status == 'Invalid') {
            this.requestHistoryCollapsed = false;
        }
        if (!this.requestItem.Parameters._DeletedUser) {
            this.requestHistoryDetails = new RequestHistoryDetails(this.requestItem);
        } else {
            this.deletedO365ObjectsService.getDeletedO365ObjectById(this.requestItem.Parameters.GROUPID).subscribe(responseDeletedObject => {
                this.requestItem.Output = responseDeletedObject;
                this.requestItem.ProductName = 'Get-Deleted-User-Info';
                this.requestHistoryDetails = new RequestHistoryDetails(this.requestItem);
            });
        }
    }

    getStatusColorImport(status: string) {
        return getStatusColor(status);
    }

    exportToPdfImport() {
        return exportToPdf();
    }

    closeDialog(bool?): void {
        this.dialogRef.close();
    }

    retry() {
        this.requestItemService.retryRequest(this.requestItem.GUID).subscribe(response => {
            let successSnackBar = this.snackBar.open("RETRY SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold']
            });
            this.requestHistoryDetails.Status = "Wait"

        },
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
            }
        );
    }

    modifyRecord() {
        if (this.requestItem) {
            this.closeDialog();
            this.localITsService.getLocalITsWithShowAllPermission().subscribe(async response => {
                var showAllLocalITs = response;
                let doc = document.getElementById("selectCurrentLocalIT") as HTMLSelectElement;
                let localITSelected = this.requestItem.LocalIT;
                if (doc.value == 'SHOW ALL') {
                    for (var i = 0; i < doc.options.length; i++) {
                        if (doc.options[i].value === this.requestItem.LocalIT) {
                            doc.selectedIndex = i;
                            break;
                        }
                    }
                    if (doc.value == 'SHOW ALL') {
                        for (var i = 0; i < doc.options.length; i++) {
                            if (showAllLocalITs.includes(doc.options[i].value)) {
                                doc.selectedIndex = i;
                                localITSelected = doc.options[i].value;
                                break;
                            }
                        }
                    }
                }
                this.userService.updateSelectedUserLocalIT(localITSelected);
                this.userService.selectedUserLocalITCardObs.subscribe(updatedLocalITDetails => {
                    if (Object.keys(updatedLocalITDetails).length > 0 && this.requestItem) {
                        let product = this.requestItem.ProductName[0].toLowerCase() + this.requestItem.ProductName.substring(1);
                        this.router.navigate(['/products/' + product.replace(/-/g, '')], { queryParams: { requestGUID: this.requestItem.GUID } });
                        this.requestItem = null;
                        
                    }
                });
            });
        }
    }

    canBeRetryed() {
        return ((this.requestHistoryDetails.Status == 'MaxWaitReached' || (this.requestHistoryDetails.Status == 'Wait' && this.requestItem.Parameters._LICENSE_NOT_AVAILABLE)) && Math.ceil((new Date().getTime() - new Date(this.requestItem.DateCreated).getTime()) / (1000 * 3600 * 24)) <= 30);
    }

    showModifyButton() {
        if ((this.requestItem && (Math.ceil((new Date().getTime() - new Date(this.requestItem.DateCreated).getTime()) / (1000 * 3600 * 24)) <= 30) && (this.selectedLocalIT == this.requestItem.LocalIT || this.selectedLocalIT == 'SHOW ALL'))) {
            return this.requestItem.ProductName != 'Public-Dns' && this.requestItem.ProductName != 'Manage-LocalITCard' && ((this.requestItem.Status == 'Failure' && this.requestItem.History[this.requestItem.History.length - 1]?.Message?.includes('HealthCheck')) || this.requestItem.Status == 'Invalid');
        }
        return false;
    }
}

export function exportToPdf() {
    var tableID = [];
    var hiddenTable = document.querySelector('#hiddenTable');
    for (var i = 0; i < hiddenTable.children.length; i++) {
        var tableElement = <HTMLElement>hiddenTable.children[i];

        var newTable = { id: tableElement.id, title: tableElement.title };
        tableID.push(newTable);
    }

    var pdfName = "";
    var doc = new jsPDF();
    // doc.setFont('','bold');
    tableID.forEach((value, index) => {
        if (index === 0) {
            var splitTitle = doc.splitTextToSize(value.title, 180);
            doc.text(splitTitle, 14, 20);
            doc.setFontSize(12);
            pdfName = value.title;
        }
        else {
            doc.text(value.title, 20, doc?.["lastAutoTable"]?.finalY + 10 || 20 + 10);

            autoTable(doc, {
                html: '#' + value.id, bodyStyles: { valign: 'top', overflow: 'linebreak', cellWidth: 'wrap' },
                startY: doc?.["lastAutoTable"]?.finalY + 20 || 20 + 20,
                headStyles: {
                    overflow: 'linebreak',
                    halign: 'center'
                },
                styles: { overflow: 'linebreak', cellWidth: 'wrap', fontSize: 9 },
                columnStyles: {
                    0: { cellWidth: 'auto' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 'auto' }, 3: { cellWidth: 'auto' }, 4: { cellWidth: 'auto' }, 5: { cellWidth: 'auto' }, 6: { cellWidth: 'auto' }, 7: { cellWidth: 'auto' }, 8: { cellWidth: 'auto' }
                }
            });

        }
    });

    doc.save(`${pdfName}.pdf`);
}
