import { booleanToStringConverter } from "../../Helpers/utils";

export class DistributionListOwnerDetails{
    DistributionList: string = '';
    DLemailAddress: string = '';
    PrimaryOwner: string = '';
    AllOwners: Array<string> = new Array<string>();
    AddedOwners: Array<string> = new Array<string>();
    SendAsApplied: string = '';
    IsRequestStatusSuccess: boolean = false;
    ProductName: string = '';

    constructor(requestItemOutput?, requestStatus?, productName?) {
        if(requestItemOutput){
            this.DistributionList = requestItemOutput.DistributionList;
            this.DLemailAddress = requestItemOutput.DLemailAddress;
            this.PrimaryOwner = requestItemOutput.PrimaryOwner;
            this.ProductName = productName;

            if(Array.isArray(requestItemOutput.AllOwners)){
                this.AllOwners = requestItemOutput.AllOwners;
            }else if (requestItemOutput.AllOwners){
                this.AllOwners.push(requestItemOutput.AllOwners);
            }
            
            if(Array.isArray(requestItemOutput.AddedOwners)){
                this.AddedOwners = requestItemOutput.AddedOwners;
            }else if (requestItemOutput.AllOwners){
                this.AddedOwners.push(requestItemOutput.AddedOwners);
            }

            this.SendAsApplied = booleanToStringConverter(requestItemOutput.SendAsApplied);

            if(requestStatus && requestStatus.toUpperCase().includes('SUCCESS')){
                this.IsRequestStatusSuccess = true;
            }
        }
    }
}

export class RemoveDistributionListOwnerDetails{
    DistributionList: string = '';
    DLemailAddress: string = '';
    PrimaryOwner: string = '';
    AllOwners: Array<string> = new Array<string>();
    OwnersToRemove: Array<string> = new Array<string>();
    RemoveOwnersSendAs: string = '';
    IsRequestStatusSuccess: boolean = false;
    ProductName: string = '';

    constructor(requestItemOutput?, requestStatus?, productName?) {
        if(requestItemOutput){
            this.DistributionList = requestItemOutput.DistributionList;
            this.DLemailAddress = requestItemOutput.DLemailAddress;
            this.PrimaryOwner = requestItemOutput.PrimaryOwner;
            this.ProductName = productName;

            if(Array.isArray(requestItemOutput.AllOwners)){
                this.AllOwners = requestItemOutput.AllOwners;
            }else if (requestItemOutput.AllOwners){
                this.AllOwners.push(requestItemOutput.AllOwners);
            }
            
            if(Array.isArray(requestItemOutput.AddedOwners)){
                this.OwnersToRemove = requestItemOutput.AddedOwners;
            }else if (requestItemOutput.AllOwners){
                this.OwnersToRemove.push(requestItemOutput.AddedOwners);
            }

            this.RemoveOwnersSendAs = booleanToStringConverter(requestItemOutput.SendAsApplied);

            if(requestStatus && requestStatus.toUpperCase().includes('SUCCESS')){
                this.IsRequestStatusSuccess = true;
            }
        }
    }
}

export class DistributionListMembersDetails{
    ProductName: string = '';
    DistributionGroup: string = '';
    Member: Array<string> = new Array<string>();
    RecipientTypes: Array<string> = new Array<string>();
    Members: Array<{DisplayName: String, RecipientType: string}> = new Array<{DisplayName: String, RecipientType: string}>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName;
            this.DistributionGroup = requestItemOutput.DistributionGroup;

            if(Array.isArray(requestItemOutput.Members)){
                this.Members = requestItemOutput.Members;
            }else if (requestItemOutput.Members){
                this.Members.push(requestItemOutput.Members);
            }

            if(Array.isArray(requestItemOutput.Member)){
                this.Member = requestItemOutput.Member;
            }else if (requestItemOutput.Member){
                this.Member.push(requestItemOutput.Member);
            }

            if(Array.isArray(requestItemOutput.RecipientType)){
                this.RecipientTypes = requestItemOutput.RecipientType;
            }else if (requestItemOutput.RecipientType){
                this.RecipientTypes.push(requestItemOutput.RecipientType);
            }
        }
    }
}


export class DistributionListMailAddressesDetails{
    ProductName: string = '';
    DistributionList: string = '';
    NewSMTPAddress: string = '';
    EmailAddresses: Array<string> = new Array<string>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName;
            this.DistributionList = requestItemOutput.DistributionList;
            this.NewSMTPAddress = requestItemOutput.NewSMTPAddress;
            
            if(Array.isArray(requestItemOutput.EmailAddresses)){
                this.EmailAddresses = requestItemOutput.EmailAddresses;
            }else if (requestItemOutput.EmailAddresses){
                this.EmailAddresses.push(requestItemOutput.EmailAddresses);
            }
        }
    }
}

export class DistributionListDeliveryRestrictionDetails{
    ProductName: string = '';
    AcceptFrom: Array<string> = new Array<string>();
    RejectFrom: Array<string> = new Array<string>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName;
            
            if(Array.isArray(requestItemOutput.AcceptFrom)){
                this.AcceptFrom = requestItemOutput.AcceptFrom;
            }else if (requestItemOutput.AcceptFrom){
                this.AcceptFrom.push(requestItemOutput.AcceptFrom);
            }

            if(Array.isArray(requestItemOutput.RejectFrom)){
                this.RejectFrom = requestItemOutput.RejectFrom;
            }else if (requestItemOutput.RejectFrom){
                this.RejectFrom.push(requestItemOutput.RejectFrom);
            }
        }
    }
}


export class DistributionListPrimarySMTPDetails{
    ProductName: string = '';
    DistributionList: string = '';
    NewPrimarySMTP: string = '';
    OldPrimarySMTP: string = '';
    EmailAddresses: Array<string> = new Array<string>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName ? productName : '';
            this.DistributionList = requestItemOutput.DistributionList;
            this.NewPrimarySMTP = requestItemOutput.NewPrimarySMTP;
            this.OldPrimarySMTP = requestItemOutput.OldPrimarySMTP;
            
            if(Array.isArray(requestItemOutput.EmailAddresses)){
                this.EmailAddresses = requestItemOutput.EmailAddresses;
            }else if (requestItemOutput.EmailAddresses){
                this.EmailAddresses.push(requestItemOutput.EmailAddresses);
            }
        }
    }
}


export class DistributionListSendAsDetails{
    ProductName: string = '';
    ListID: string = '';
    Trustee: Array<string> = new Array();
    Permission: string = '';

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName ? productName : '';
            this.ListID = requestItemOutput.ListID;
            
            if(Array.isArray(requestItemOutput.Trustee)){
                this.Trustee = requestItemOutput.Trustee;
            }else if (requestItemOutput.Trustee){
                this.Trustee.push(requestItemOutput.Trustee);
            }

            this.Permission = requestItemOutput.Permission;
        }
    }
}

export class GetDistributionListMembersDetails{
    ProductName: string = '';
    Members: Array<any> = new Array<any>();
    ListName: string = '';

    constructor(requestItemOutput?, productName?, listName?) {
        if(requestItemOutput){
            this.ProductName = productName ? productName : '';
            this.ListName = listName;
            if(!Array.isArray(requestItemOutput)){
                requestItemOutput = new Array<any>(requestItemOutput);
            }
            for (var member of requestItemOutput) {
                if (member.MemberCount && member.MemberCount > 0) {
                    member.Expanded = true;
                    this.Members.push(member);
                    member.Members.forEach(itemL1 => {
                        itemL1.Parents = new Array<string>(member.PrimarySmtpAddress);
                        itemL1.Invisible = false;
                        if (itemL1.MemberCount && itemL1.MemberCount > 0) {
                            itemL1.Expanded = true;
                            this.Members.push(itemL1);
                            itemL1.Members.forEach(itemL2 => {
                                itemL2.Parents = new Array<string>(member.PrimarySmtpAddress,itemL1.PrimarySmtpAddress);
                                itemL2.Invisible = false;
                                if (itemL2.MemberCount && itemL2.MemberCount > 0) {
                                    itemL2.Expanded = true;
                                    this.Members.push(itemL2);
                                    this.Members.push(...itemL2.Members.map(itemL3 => ({ ...itemL3, Parents: new Array<string>(member.PrimarySmtpAddress,itemL1.PrimarySmtpAddress,itemL2.PrimarySmtpAddress), Invisible: false})));
                                }
                                else {
                                    this.Members.push(itemL2);
                                }
                            });
                        }
                        else {
                            this.Members.push(itemL1);
                        }
                    });
                }
                else {
                    this.Members.push(member);
                }
            }
            this.Members.filter(item => item.Alias.length == 0 || item.PrimarySmtpAddress.length == 0)?.forEach(item => {
                if (item.Alias.length == 0) {
                    item.Alias = ' ';
                }
                if (item.PrimarySmtpAddress.length == 0) {
                    item.PrimarySmtpAddress = ' ';
                }
            });
            this.Members.filter(item => item.MemberCount && item.MemberCount > 0)?.forEach(item => {
                delete item.Members;
            });
        }
    }
}