import { Component, Input } from '@angular/core';
import { EnableLitigationHoldDetails } from '../../../../Models/History/EnableLitigationHoldDetails';

@Component({
    selector: 'EnableLitigationHoldHistoryDetails',
    templateUrl: './enableLitigationHoldHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class EnableLitigationHoldHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: EnableLitigationHoldDetails;
    productCustomInfoCollapsed: boolean = false;
}