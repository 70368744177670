<h4>Delete O365 Object:</h4>

<!-- ADD NEW FILECONTENT FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label class="required" for="newOwnerInput">New Owner:</label>
            <input type="text" id="newOwnerInput" class="form-control"
                    [(ngModel)]="fileContent.NewOwner" name="newOwnerInput" #newOwnerInput="ngModel"
                    required [pattern]="groupIDPattern">

            <div *ngIf="newOwnerInput.invalid && (newOwnerInput.dirty || newOwnerInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="newOwnerInput.errors.required">
                    New Owner is required.
                </div>
                <div *ngIf="newOwnerInput.errors.pattern">
                    New Owner format is invalid (must be a valid GID format).
                </div>               
            </div>
            <!--
                <GroupIDInputField label="New Owner" (groupIDDisplayNameChange)="fileContent.NewOwnerDisplayName=$event" [(groupID)]="fileContent.NewOwner"></GroupIDInputField>
            -->
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="preventMailboxReconnectRadioButton">Prevent Mailbox Reconnect:</label>
            <div id="preventMailboxReconnectRadioButton">
                <div style="display: inline-block;">
                    <label><input type="radio" id="preventMailboxReconnectRadioButtonOpt1" name="preventMailboxReconnectRadioButton" class="form-control" [value]="true" [(ngModel)]="fileContent.PreventMailboxReconnect" #preventMailboxReconnectRadioButtonOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="preventMailboxReconnectRadioButtonOpt2" name="preventMailboxReconnectRadioButton" class="form-control" [value]="false" [(ngModel)]="fileContent.PreventMailboxReconnect" #preventMailboxReconnectRadioButtonOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="preventResubscribeDLRadioButton">Prevent Resubscribe DL:</label>
            <div id="preventResubscribeDLRadioButton">
                <div style="display: inline-block;">
                    <label><input type="radio" id="preventResubscribeDLRadioButtonOpt1" name="preventResubscribeDLRadioButton" class="form-control" [value]="true" [(ngModel)]="fileContent.PreventResubscribeDL" #preventResubscribeDLRadioButtonOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="preventResubscribeDLRadioButtonOpt2" name="preventResubscribeDLRadioButton" class="form-control" [value]="false" [(ngModel)]="fileContent.PreventResubscribeDL" #preventResubscribeDLRadioButtonOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(false); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid);" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b> - 
            {{ listedFileContent.NewOwner }} <span *ngIf="listedFileContent.NewOwnerDisplayName">({{ listedFileContent.NewOwnerDisplayName }})</span> -
            {{ booleanToStringConverterImport(listedFileContent.PreventMailboxReconnect, 'No', 'Yes') }} -
            {{ booleanToStringConverterImport(listedFileContent.PreventResubscribeDL, 'No', 'Yes') }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>New Owner:</b> {{ listedFileContent.NewOwner }} <span *ngIf="listedFileContent.NewOwnerDisplayName">({{ listedFileContent.NewOwnerDisplayName }})</span>
                </div>    
                <div class="col-4">
                    <b>Prevent Mailbox Reconnect:</b> {{ booleanToStringConverterImport(listedFileContent.PreventMailboxReconnect, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Prevent Resubscribe DL:</b> {{ booleanToStringConverterImport(listedFileContent.PreventResubscribeDL, 'No', 'Yes') }}
                </div>
            </div> 
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>