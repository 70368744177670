import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { GraphApiService } from '../../../Services/graphApi.service';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';

import { CreatePersonalMailboxFileContent, CreatePersonalMailboxRequest } from '../../../Models/FileContents';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { booleanToStringConverter, removeArrayEmptyValues } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';



@Component({
    selector: 'BodyContent',
    templateUrl: './createPersonalMailboxO365.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreatePersonalMailboxO365Component {
    
    
    
    
    

    public languages: Array<any> = new Array<any>();
    public licenseTypes: Array<any> = new Array<any>();
    public litigationHoldDurations: Array<string> = new Array<string>();

    public requestContainer: any = {};
    public fileContentsList = new Array<CreatePersonalMailboxFileContent>();
    public fileContent = new CreatePersonalMailboxFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public subscribedLicenses = [];

    public availableLicensesCounter = null;

    public groupIDPattern: string = inputValidationHelper.groupIdNoAdminFormatRegEx;
    public emailAddressPattern = inputValidationHelper.emailAddressFormatRegEx;

    public customerProfileImported: boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";

    window: any = window;

    constructor(private requestContainerService: RequestContainerService, private graphApiService: GraphApiService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private customerProfilesService: CustomerProfilesService, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
        this.graphApiService.getSubscribedLicenses().subscribe(subscribedLicenses => {
        this.subscribedLicenses = subscribedLicenses,
            this.businessRulesService.getSpecificListByKey('LICENSESKU', 'MailboxLicenses')
                .subscribe(response => {
                this.licenseTypes = response,
                    this.licenseTypes = this.licenseTypes.sort(function (a, b) {
                        var x = a.name.toLowerCase();
                        var y = b.name.toLowerCase();
                        if (x < y) { return -1; }
                        if (x > y) { return 1; }
                        return 0;
                    }),
                    this.getNumberOfAvailableLicences(this.licenseTypes[0].code);
                    this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                        if (cb && this.fileContent.Office365Licenses) {
                            this.getNumberOfAvailableLicences(this.fileContent.Office365Licenses);
                        }
                    });
                });
        });



        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => { this.languages = response; });

        this.businessRulesService.getSpecificListByKey('LITIGATIONHOLDDURATIONS', 'AvailableLitigationHoldDurations')
            .subscribe(response => { this.litigationHoldDurations = response; });



    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdNoAdminFormat(groupID);
    }

    ngOnDestroy() {
        
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreatePersonalMailboxFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new CreatePersonalMailboxFileContent();
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addInputRow(arrayToModify) {
        arrayToModify.push('');
    }

    removeInputRow(arrayToModify, index) {
        arrayToModify.splice(index, 1);
    }

    //Add the current fileContent to the fileContentList and clean the forms
    addFileContentToList() {
        var tempObject = new CreatePersonalMailboxFileContent(this.fileContent);
        tempObject.MailAddresses = removeArrayEmptyValues(tempObject.MailAddresses);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    getNumberOfAvailableLicences(licenseSKU) {
        let currentLicense = this.subscribedLicenses.find(obj => obj.skuPartNumber == licenseSKU);
        this.availableLicensesCounter = currentLicense ? (currentLicense.prepaidUnits.enabled + currentLicense.prepaidUnits.warning - currentLicense.consumedUnits) : 0;
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError) {
            this.addFileContentToList();
        }

        var data = new CreatePersonalMailboxRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/createPersonalMailbox')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    importCustomerProfile() {
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if (inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)) {
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if (response) {
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if (response.PrimarySMTPdomain) {
                            this.fileContent.PrimarySMTPDomain = response.PrimarySMTPdomain;
                            document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }
                        if (response.Office365Licenses) {
                            this.fileContent.Office365Licenses = response.Office365Licenses;
                            document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }
                        if (response.EnableArchive) {
                            this.fileContent.EnableArchive = response.EnableArchive;
                            document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #388e3c";
                        } else {
                            missingVal = true;
                        }
                        if (response.EnableLitigationHold) {
                            this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                            document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #388e3c";
                            if (response.LitigationHoldDuration) {
                                this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                document.getElementById("litigationHoldDuration").style.border = "2px solid #388e3c";
                            } else {
                                missingVal = true;
                            }
                        } else {
                            missingVal = true;
                        }

                        if(response.MailboxLanguage){
                            this.fileContent.MailboxLanguage = response.MailboxLanguage;
                            document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }

                        if (missingVal) {
                            this.importDefaultCustomerProfile();
                        }
                    } else {
                        this.showImportedNoProfileDefaultSuccess = true;
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile() {
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if (inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)) {
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if(response){
                        this.showImportedDefaultSuccess = true;
                        if (!this.fileContent.PrimarySMTPDomain) {
                            if (response.PrimarySMTPdomain) {
                                this.fileContent.PrimarySMTPDomain = response.PrimarySMTPdomain;
                                document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if (!this.fileContent.Office365Licenses) {
                            if (response.Office365Licenses) {
                                this.fileContent.Office365Licenses = response.Office365Licenses;
                                document.getElementById("createMailboxOffice365LicensesInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if (!this.fileContent.EnableArchive) {
                            if (response.EnableArchive) {
                                this.fileContent.EnableArchive = response.EnableArchive;
                                document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if (!this.fileContent.EnableLitigationHold) {
                            if (response.EnableLitigationHold) {
                                this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                                document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #fbc02d";
                                if (!this.fileContent.LitigationHoldDuration) {
                                    if (response.LitigationHoldDuration) {
                                        this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                        document.getElementById("litigationHoldDuration").style.border = "2px solid #fbc02d";
                                    }
                                }
                            }
                        }

                        if(!this.fileContent.MailboxLanguage){
                            if(response.MailboxLanguage){
                                this.fileContent.MailboxLanguage = response.MailboxLanguage;
                                document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #fbc02d";
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                    }
                    
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm() {
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxOffice365LicensesInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxEnableArchiveInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("litigationHoldDuration").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxLanguageDropDown").style.border = "1px solid rgba(0,0,0,.15)";

        if (this.customerProfileImported) {
            var groupId = this.fileContent.GroupID;
            if (!inputValidationHelper.checkGroupIdNoAdminFormat(groupId)) {
                this.fileContent.PrimarySMTPDomain = "";
                this.fileContent.Office365Licenses = "";
                this.fileContent.EnableArchive = false;
                this.fileContent.EnableLitigationHold = false;
                this.fileContent.LitigationHoldDuration = "";
                this.customerProfileImported = false;
                this.fileContent.MailboxLanguage = "";
            }
        }
    }
}