import { Component, Input } from '@angular/core';
import { EnableArchiveOnlineDetails } from '../../../../Models/History/EnableArchiveOnlineDetails';

@Component({
    selector: 'EnableArchiveOnlineHistoryDetails',
    templateUrl: './enableArchiveOnlineHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class EnableArchiveOnlineHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: EnableArchiveOnlineDetails;
    productCustomInfoCollapsed: boolean = false;
}