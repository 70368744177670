<div class="row  d-flex flex-row-reverse mb-4">
    <div class="btn-group">
        <button class="btn btn-primary" (click)="showAll()">Show All</button>
        <button class="btn btn-primary  " (click)="hideAll()">Hide All</button>
    </div>
</div>

<div *ngIf="getDistributionListInfoDetails" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST INFORMATION
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th class="historyTableColWidthLimitation">Display Name</th>
                <td>{{ getDistributionListInfoDetails.DisplayName }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Alias</th>
                <td>{{ getDistributionListInfoDetails.Alias }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Mail</th>
                <td>{{ getDistributionListInfoDetails.Mail }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Owner</th>
                <td [title]="getDistributionListInfoDetails.Owner.PrimarySmtpAddress">
                    {{ getDistributionListInfoDetails.Owner.DisplayName }} - {{ getDistributionListInfoDetails.Owner.Alias }}
                </td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Proxy Addresses</th>
                <td><div *ngFor="let proxyAddress of getDistributionListInfoDetails.ProxyAddresses">{{ proxyAddress }}</div></td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Type</th>
                <td>{{ getDistributionListInfoDetails.Type }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Hidden</th>
                <td *ngIf="getDistributionListInfoDetails.Hidden; else elseBlock">YES</td>
                <ng-template #elseBlock><td>NO</td></ng-template>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Created</th>
                <td>{{ getDistributionListInfoDetails.Created }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- OWNERS Section -->

<div *ngIf="getDistributionListInfoDetails.AdditionalOwners" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="ownersInfoCollapsed=!ownersInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    Additional Owners 
                    <i *ngIf="ownersInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!ownersInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!ownersInfoCollapsed">
                <th> Alias </th>
                <th>Display Name</th>
                <th>Email</th>
            </tr>
        </thead>
        <tbody *ngIf="!ownersInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.AdditionalOwners.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let owner of getDistributionListInfoDetails.AdditionalOwners">
                <td>{{ owner.Alias }}</td>
                <td>{{ owner.DisplayName }}</td>
                <td>{{ owner.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!--  Trustee Section -->

<div *ngIf="getDistributionListInfoDetails.Trustee" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="trusteeInfoCollapsed=!trusteeInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    Trustee 
                    <i *ngIf="trusteeInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!trusteeInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!trusteeInfoCollapsed">
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody *ngIf="!trusteeInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.Trustee.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.Trustee">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!--  MEMBERS Section -->

<div *ngIf="getDistributionListInfoDetails.Members" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="membersInfoCollapsed=!membersInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    MEMBERS 
                    <i *ngIf="membersInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!membersInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!membersInfoCollapsed">
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody *ngIf="!membersInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.Members.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let member of getDistributionListInfoDetails.Members">
                <td>{{ member.Alias }}</td>
                <td>{{ member.DisplayName }}</td>
                <td>{{ member.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!--  AcceptFrom Section -->

<div *ngIf="getDistributionListInfoDetails.AcceptFrom" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="acceptFromInfoCollapsed=!acceptFromInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    Accept From 
                    <i *ngIf="acceptFromInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!acceptFromInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!acceptFromInfoCollapsed">
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody *ngIf="!acceptFromInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.AcceptFrom.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.AcceptFrom">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!--  RejectFrom Section -->

<div *ngIf="getDistributionListInfoDetails.RejectFrom" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="rejectFromInfoCollapsed=!rejectFromInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    Reject From 
                    <i *ngIf="rejectFromInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!rejectFromInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!rejectFromInfoCollapsed">
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody *ngIf="!rejectFromInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.RejectFrom.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.RejectFrom">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!--  Moderators Section -->

<div *ngIf="getDistributionListInfoDetails.Moderators" class="row  mb-4">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="moderatorsInfoCollapsed=!moderatorsInfoCollapsed">
                <th class="text-center text-uppercase" colspan="3">
                    Moderators 
                    <i *ngIf="moderatorsInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!moderatorsInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!moderatorsInfoCollapsed">
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody *ngIf="!moderatorsInfoCollapsed">
            <tr *ngIf="getDistributionListInfoDetails.Moderators.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.Moderators">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Hidden Section For PDF Export Without order by -->

<div id="hiddenTable" style="display: none">
    <table id="table0" title="Get-DistributionList-Info Details {{getDistributionListInfoDetails.DisplayName}}">
    </table>

    <table id="table1" *ngIf="getDistributionListInfoDetails">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="2">
                    DISTRIBUTION LIST ACCOUNT INFORMATION
                </th>
            </tr>
            <tr>
                <th>Properties</th>
                <th>Values</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th class="historyTableColWidthLimitation">Display Name</th>
                <td>{{ getDistributionListInfoDetails.DisplayName }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Alias</th>
                <td>{{ getDistributionListInfoDetails.Alias }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Mail</th>
                <td>{{ getDistributionListInfoDetails.Mail }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Proxy Addresses</th>
                <td>
                    <div *ngFor="let proxyAddress of getDistributionListInfoDetails.ProxyAddresses; index as i">
                        <br *ngIf="i!=0">
                        {{ proxyAddress }}
                    </div>
                </td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Owner</th>
                <td [title]="getDistributionListInfoDetails.Owner.PrimarySmtpAddress">
                    {{ getDistributionListInfoDetails.Owner.DisplayName }} - {{ getDistributionListInfoDetails.Owner.Alias }}
                </td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Type</th>
                <td>{{ getDistributionListInfoDetails.Type }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Hidden</th>
                <td *ngIf="getDistributionListInfoDetails.Hidden; else elseBlock">YES</td>
                <ng-template #elseBlock><td>NO</td></ng-template>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Created</th>
                <td>{{ getDistributionListInfoDetails.Created }}</td>
            </tr>
        </tbody>
    </table>

    <table id="table2" *ngIf="getDistributionListInfoDetails.AdditionalOwners">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Additional Owners 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.AdditionalOwners.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.AdditionalOwners">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table3" *ngIf="getDistributionListInfoDetails.Trustee">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Trustee 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.Trustee.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.Trustee">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table4" *ngIf="getDistributionListInfoDetails.AcceptFrom">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Accept From 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.AcceptFrom.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.AcceptFrom">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table5" *ngIf="getDistributionListInfoDetails.RejectFrom">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Reject From 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.RejectFrom.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.RejectFrom">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table6" *ngIf="getDistributionListInfoDetails.Moderators">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Moderators 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.Moderators.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.Moderators">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table7" *ngIf="getDistributionListInfoDetails.Members">
        <thead class="thead-default">
            <tr>
                <th class="text-center text-uppercase" colspan="3">
                    Members 
                </th>
            </tr>
            <tr>
                <th>Alias </th>
                <th>Name </th>
                <th>Email </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getDistributionListInfoDetails.Members.length === 0">
                <td colspan="3"> EMPTY !</td>
            </tr>
            <tr *ngFor="let item of getDistributionListInfoDetails.Members">
                <td>{{ item.Alias }}</td>
                <td>{{ item.DisplayName }}</td>
                <td>{{ item.PrimarySmtpAddress }}</td>
            </tr>
        </tbody>
    </table>
</div>