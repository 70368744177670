import { Component, Input } from '@angular/core';
import { GetUserInfoDetails } from '../../../../Models/History/GetUserInfoDetails';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'GetUserInfoHistoryDetails',
    templateUrl: './getUserInfoHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetUserInfoHistoryDetailsComponent{
    Array = Array;
    @Input() getUserInfoDetails: GetUserInfoDetails;
    userInfoCollapsed:boolean = true;
    melindaInfoCollapsed:boolean = true;
    additionalAccountsCollapsed:boolean = true;
    oktaInfoCollapsed:boolean = true;
    oktaMFACollapsed:boolean = true;
    office365InfoCollapsed:boolean = true;
    userLicensesCollapsed:boolean = true;
    AADSecurityGroupOwnershipsCollapsed:boolean = true;
    AADSynchronizedSecurityGroupOwnershipsCollapsed:boolean = true;
    AADApplicationOwnershipsCollapsed:boolean = true;
    AADGuestUsersCollapsed:boolean = true;
    exchangeOnlineInfoCollapsed:boolean = true;
    exchangeOnlineUserConfCollapsed:boolean = true;
    distributionListOwnerCollapsed:boolean = true;
    mailboxOwnerCollapsed:boolean = true;
    skypeForBusinessInfoCollapsed:boolean = true;
    skypeConfPolicyCollapsed:boolean = true;
    mobileDeviceInformationsCollapsed: boolean = true;
    pstnInfoCollapsed: boolean = true;

    getStatusColorImport(status:string){
        return getStatusColor(status);
    }

    showAll(){
        this.userInfoCollapsed = false;
        this.melindaInfoCollapsed = false;
        this.additionalAccountsCollapsed = false;
        this.oktaInfoCollapsed = false;
        this.oktaMFACollapsed = false;
        this.office365InfoCollapsed = false;
        this.userLicensesCollapsed = false;
        this.AADSecurityGroupOwnershipsCollapsed = false;
        this.AADSynchronizedSecurityGroupOwnershipsCollapsed = false;
        this.AADApplicationOwnershipsCollapsed = false;
        this.AADGuestUsersCollapsed = false;
        this.exchangeOnlineInfoCollapsed = false;
        this.exchangeOnlineUserConfCollapsed = false;
        this.distributionListOwnerCollapsed = false;
        this.mailboxOwnerCollapsed = false;
        this.skypeForBusinessInfoCollapsed = false;
        this.skypeConfPolicyCollapsed = false;
        this.mobileDeviceInformationsCollapsed = false;
        this.pstnInfoCollapsed = false;
    }
    
    hideAll(){
        this.userInfoCollapsed = true;
        this.melindaInfoCollapsed = true;
        this.additionalAccountsCollapsed = true;
        this.oktaInfoCollapsed = true;
        this.oktaMFACollapsed = true;
        this.office365InfoCollapsed = true;
        this.userLicensesCollapsed = true;
        this.AADSecurityGroupOwnershipsCollapsed = true;
        this.AADSynchronizedSecurityGroupOwnershipsCollapsed = true;
        this.AADApplicationOwnershipsCollapsed = true;
        this.AADGuestUsersCollapsed = true;
        this.exchangeOnlineInfoCollapsed = true;
        this.exchangeOnlineUserConfCollapsed = true;
        this.distributionListOwnerCollapsed = true;
        this.mailboxOwnerCollapsed = true;
        this.skypeForBusinessInfoCollapsed = true;
        this.skypeConfPolicyCollapsed = true;
        this.mobileDeviceInformationsCollapsed = true;
        this.pstnInfoCollapsed = true;
    }
}