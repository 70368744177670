import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { CreateGuestUserFileContent, CreateGuestUserRequest } from '../../../Models/FileContents';


import { booleanToStringConverter } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './createGuestUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateGuestUserComponent{
    
    
    
    

    public fileContentsList = new Array<CreateGuestUserFileContent>();
    public fileContent = new CreateGuestUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    sponsorAcceptedTypes = ['gid', 'email', 'guid'];

    // public testUserProfile: any = null;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private localITsService: LocalITsService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent  = new CreateGuestUserFileContent();
                this.localITNotFound = '';
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        let doc = document.getElementById("groupIdGuidOrMailInput") as HTMLInputElement;
                        if (doc) {
                            doc.value = cb[0].Sponsor;
                            doc.dispatchEvent(new Event('input'));
                            let buttons = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                            buttons[0].dispatchEvent(new Event('click'));
                        }
                    }
                });
            });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateGuestUserFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new CreateGuestUserFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addFileContentToList(){
        var tempObject = new CreateGuestUserFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && this.fileContent.SponsorDisplayName){
            this.addFileContentToList();
        }

        var data = new CreateGuestUserRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
                
        this.requestContainerService.sendProductRequest(data, '/createGuestUser')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }
} 