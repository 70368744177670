<h4>{{ isCreation ? 'Create New' : 'Update' }} Customer Profile:</h4>

<!--CREATE/UPDATE NEW LOCAL IT CARD FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div *ngIf="isCreation" class="col-4">
            <label for="customerProfileCompanyInput">Company:</label>
            <select type="text" id="{{'companieInput'}}" class="form-control" [(ngModel)]="customerProfile.Company"
                name="{{'companieInput'}}" required>
                <option *ngFor="let companie of companies | orderby: 'Name' : false : true" [value]="companie.Name">
                    {{companie.Name}}</option>
            </select>
        </div>
        <div *ngIf="!isCreation" class="col-4">
            <label for="customerProfileCompanyInput">Company:</label>
            <input type="text" id="customerProfileCompanyInput" class="form-control"
                [(ngModel)]="customerProfile.Company" name="customerProfileCompanyInput" [disabled]="true">
        </div>
        <div class="col-4">
            <label for="customerProfileBUInput">BU:</label>
            <input type="text" id="customerProfileBUInput" class="form-control" [(ngModel)]="customerProfile.BU"
                name="customerProfileBUInput">
        </div>
        <div class="col-4">
            <label for="customerProfileDiscriminantInput">Discriminant:</label>
            <input type="text" id="customerProfileDiscriminantInput" class="form-control"
                [(ngModel)]="customerProfile.Discriminant" name="customerProfileDiscriminantInput">
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            <label for="customerProfilePrimarySMTPDomainInput">Primary SMTP Domain:</label>
            <input type="text" id="customerProfilePrimarySMTPDomainInput" class="form-control"
                [(ngModel)]="customerProfile.PrimarySMTPdomain" name="customerProfilePrimarySMTPDomainInput">
        </div>
        <div class="col-4">
            <label for="customerProfilePrimaryExternalSMTPDomainInput">Primary External SMTP Domain:</label>
            <input type="text" id="customerProfilePrimaryExternalSMTPDomainInput" class="form-control"
                [(ngModel)]="customerProfile.PrimaryExternalSMTPdomain"
                name="customerProfilePrimaryExternalSMTPDomainInput">
        </div>
        <div class="col-4">
            <label for="createMailboxOffice365LicensesInput">Office365 License:</label>
            <select id="createMailboxOffice365LicensesInput" class="form-control"
                [(ngModel)]="customerProfile.Office365Licenses" name="createMailboxOffice365LicensesInput">
                <option *ngFor="let license of licenseTypes | orderby: 'name' : false : true" [value]="license.code">{{license.name}}</option>
            </select>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            <label for="customerProfileSecondarySMTPDomainInput">Secondary SMTP Domain:</label>
            <input type="text" id="customerProfileSecondarySMTPDomainInput" class="form-control"
                [(ngModel)]="customerProfile.SecondarySMTPdomain" name="customerProfileSecondarySMTPDomainInput">
        </div>
        <div class="col-4">
            <label for="customerProfileSecondaryExternalSMTPDomainInput">Secondary External SMTP Domain:</label>
            <input type="text" id="customerProfileSecondaryExternalSMTPDomainInput" class="form-control"
                [(ngModel)]="customerProfile.SecondaryEXTERNALSMTPdomain"
                name="customerProfileSecondaryExternalSMTPDomainInput">
        </div>        
        <div class="col-4">
            <label for="createMailboxLanguageInput">Language:</label>
            <select id="createMailboxLanguageInput" class="form-control"
                    [(ngModel)]="customerProfile.MailboxLanguage" name="createMailboxLanguageInput">
                    <option *ngFor="let language of languages | orderby: 'name' : false : true" [value]="language.code">{{language.name}}</option>
            </select>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            <input type="checkbox" [checked]="customerProfile.EnableArchive" (click)="changeEnableArchiveStatus()">
            Enable Archive
        </div>
        <div class="col-4">
            <input type="checkbox" [checked]="customerProfile.EnableLitigationHold"
                (click)="changeEnableLitigationHoldStatus()">
            Enable Litigation Hold
        </div>
        <div class="col-4" *ngIf="customerProfile.EnableLitigationHold">
            <label for="createMailboxLitigationHoldDuration">Litigation Hold Duration:</label>
            <select id="createMailboxLitigationHoldDuration" class="form-control"
                [(ngModel)]="customerProfile.LitigationHoldDuration" name="createMailboxLitigationHoldDuration">
                <option *ngFor="let duration of litigationHoldDurations | orderby: '' : false : true"
                    [value]="duration">{{duration}} years</option>
            </select>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="customerProfileDisclaimerInput">Disclaimer:</label>
            <input type="text" id="customerProfileDisclaimerInput" class="form-control"
                [(ngModel)]="customerProfile.Disclaimer" name="customerProfileDisclaimerInput">
        </div>
        <div class="col-4">
            <label for="customerProfileTimeZoneInput">Time Zone:</label>
            <select type="text" id="{{'timeZoneInput'}}" class="form-control" [(ngModel)]="customerProfile.TimeZone"
                name="{{'timeZoneInput'}}">
                <option *ngFor="let timeZone of timeZones | orderby: 'DisplayName' : false : true"
                    [value]="timeZone.StandardName">{{timeZone.DisplayName}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="deviceTypeDropdownList">Mobile Mail Client:</label>
            <select id="deviceTypeDropdownList" class="form-control" [(ngModel)]="customerProfile.MobileMailClient"
                name="deviceTypeDropdownList">
                <option *ngFor="let deviceType of assignableDeviceTypes | orderby: '' : false : true"
                    [value]="deviceType">{{deviceType}}</option>
            </select>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-9">
            <a class="btn btn-primary" routerLink="/customerProfiles" routerLinkActive="active">Cancel</a>
        </div>
        <div class="col-1 offset-1"> 
            <button type="submit" class="btn btn-primary" (click)="sendRequest();"
                [disabled]="fileContentForm.invalid">Save</button>        
        </div>
    </div> 
</form>