<h4>Block User:</h4>

<form #getUserInfoForm="ngForm" (ngSubmit)="getUserInfoForm.resetForm()">
    <div class="row form-group">
        <div class="col-12">
            <span style="color:grey;font-weight:bold;font-style:italic">
                Before using the Block-User product, please make sure that the user account is disabled in the company’s
                Active Directory, that the user account’s password has been reset and that a password change is required
                at the next logon.
            </span>
            <br/>
            <br/>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event"
                [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="disableMailboxRulesInput">Disable Mailbox Rules:</label>
            <div id="disableMailboxRulesInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="disableMailboxRulesInputOpt1" name="disableMailboxRulesInput"
                        class="form-control" [value]="true" [(ngModel)]="fileContent.DisableMailboxRules"
                        #disableMailboxRulesInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="disableMailboxRulesInputOpt2" name="disableMailboxRulesInput"
                        class="form-control" [value]="false" [(ngModel)]="fileContent.DisableMailboxRules"
                        #disableMailboxRulesInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="removeMailboxForwardingInput">Remove Mailbox Forwarding:</label>
            <div id="removeMailboxForwardingInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeMailboxForwardingInputOpt1"
                        name="removeMailboxForwardingInput" class="form-control" [value]="true"
                        [(ngModel)]="fileContent.RemoveMailboxForwarding"
                        #removeMailboxForwardingInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeMailboxForwardingInputOpt2"
                        name="removeMailboxForwardingInput" class="form-control" [value]="false"
                        [(ngModel)]="fileContent.RemoveMailboxForwarding"
                        #removeMailboxForwardingInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="removeMailboxPermissionsInput">Remove Mailbox Permissions:</label>
            <div id="removeMailboxPermissionsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeMailboxPermissionsInputOpt1"
                        name="removeMailboxPermissionsInput" class="form-control" [value]="true"
                        [(ngModel)]="fileContent.RemoveMailboxPermissions"
                        #removeMailboxPermissionsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeMailboxPermissionsInputOpt2"
                        name="removeMailboxPermissionsInput" class="form-control" [value]="false"
                        [(ngModel)]="fileContent.RemoveMailboxPermissions"
                        #removeMailboxPermissionsInputOpt2="ngModel">No</label>
                </div> 
            </div>
        </div>
        <div class="col-4">
            <label for="removeApplicationConsentsInput">Remove Application Consents:</label>
            <div id="removeApplicationConsentsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeApplicationConsentsInputOpt1"
                        name="removeApplicationConsentsInput" class="form-control" [value]="true"
                        [(ngModel)]="fileContent.RemoveApplicationConsents"
                        #removeApplicationConsentsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="removeApplicationConsentsInputOpt2"
                        name="removeApplicationConsentsInput" class="form-control" [value]="false"
                        [(ngModel)]="fileContent.RemoveApplicationConsents"
                        #removeApplicationConsentsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="disableOneDriveExternalSharingInput">Disable OneDrive External Sharing:</label>
            <div id="disableOneDriveExternalSharingInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="disableOneDriveExternalSharingInputOpt1"
                        name="disableOneDriveExternalSharingInput" class="form-control" [value]="true"
                        [(ngModel)]="fileContent.DisableOneDriveExternalSharing"
                        #disableOneDriveExternalSharingInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="disableOneDriveExternalSharingInputOpt2"
                        name="disableOneDriveExternalSharingInput" class="form-control" [value]="false"
                        [(ngModel)]="fileContent.DisableOneDriveExternalSharing"
                        #disableOneDriveExternalSharingInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="retireIntuneDevicesInput">Retire Intune Devices:</label>
            <div id="retireIntuneDevicesInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="retireIntuneDevicesInputOpt1" name="retireIntuneDevicesInput"
                        class="form-control" [value]="true" [(ngModel)]="fileContent.RetireIntuneDevices"
                        #retireIntuneDevicesInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="retireIntuneDevicesInputOpt2" name="retireIntuneDevicesInput"
                        class="form-control" [value]="false" [(ngModel)]="fileContent.RetireIntuneDevices"
                        #retireIntuneDevicesInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div> 
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList();"
                [disabled]="getUserInfoForm.invalid || fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getUserInfoForm.invalid);"
                [disabled]="((getUserInfoForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF REQUESTS READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b> <span
                *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'getUserInfoRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Disable Mailbox Rules:</b>
                    {{ booleanToStringConverterImport(listedFileContent.DisableMailboxRules, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Remove Mailbox Forwarding:</b>
                    {{ booleanToStringConverterImport(listedFileContent.RemoveMailboxForwarding, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Remove Mailbox Permissions:</b>
                    {{ booleanToStringConverterImport(listedFileContent.RemoveMailboxPermissions, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Remove Application Consents:</b>
                    {{ booleanToStringConverterImport(listedFileContent.RemoveApplicationConsents, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Disable One Drive External Sharing:</b>
                    {{ booleanToStringConverterImport(listedFileContent.DisableOneDriveExternalSharing, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Retire Intune Devices:</b>
                    {{ booleanToStringConverterImport(listedFileContent.RetireIntuneDevices, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
        </div>
    </div>
</div>