export class ChangeMailboxPrimarySMTPDetails{
    Mailbox: string = '';
    NewPrimarySMTP: string = '';
    NewSIPAddress: string = '';
    OldPrimarySMTP: string = '';
    OldSIPAddress: string = '';
    ProxyAddresses: Array<string> = new Array<string>();
    
    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.NewPrimarySMTP = requestItemOutput.NewPrimarySMTP ? requestItemOutput.NewPrimarySMTP : '';
            this.NewSIPAddress = requestItemOutput.NewSIPAddress ? requestItemOutput.NewSIPAddress : '';
            this.OldPrimarySMTP = requestItemOutput.OldPrimarySMTP ? requestItemOutput.OldPrimarySMTP : '';
            this.OldSIPAddress = requestItemOutput.OldSIPAddress ? requestItemOutput.OldSIPAddress : '';
        }
    }
}