<div class="row ">
    <h4>Parameters:</h4>
</div>

<div [ngSwitch]="productName" style="margin-bottom:10px;" class="">
    <!-- Add-Okta-User-To-Group && Remove-Okta-User-From-Group

            OktaUsernames
            OktaGroupName
    -->
    <ng-container *ngSwitchCase="productName === 'Add-Okta-User-To-Group' 
                                || productName === 'Add-OktaB2B-User-To-Group'
                                || productName === 'Remove-OktaB2B-User-From-Group'
                                || productName === 'Remove-Okta-User-From-Group' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Okta Group Name:</b> {{ parameters.OKTAGROUPNAME }}
            </div>
            <div class="col-8">
                <div class="">
                    <b>Users:</b>
                </div>
                <div class="d-flex flex-wrap" *ngIf="isParameterArray(parameters.OKTAUSERNAMES)">
                    <span
                        *ngFor="let groupID of parameters.OKTAUSERNAMES; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ groupID }}</span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.OKTAUSERNAMES)">
                    <span>{{ parameters.OKTAUSERNAMES }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Get-Okta-Group-Members

            OktaGroupName
    -->
    <ng-container *ngSwitchCase="productName === 'Get-Okta-Group-Members'
                                || productName === 'Get-OktaB2B-Group-Members' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Okta Group Name:</b> {{ parameters.OKTAGROUPNAME }}
            </div>
        </div>
    </ng-container>


    <ng-container *ngSwitchCase="productName === 'Create-Meraki-Network' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Site Name:</b> {{ parameters.SITENAME }}
            </div>
            <div class="col-4">
                <b>Entity OrgID:</b> {{ parameters.ORGID }}
            </div>
            <div class="col-4">
                <b>Product types:</b> {{ parameters.PRODUCTTYPES }}
            </div>
            <div class="col-4">
                <b>Notes:</b> {{ parameters.NOTES }}
            </div>
            <div class="col-4">
                <b>ID of network to copy:</b> {{ parameters.COPYFROMNETWORKID }}
            </div>
        </div>
    </ng-container>
    <!-- Reset-Okta-CloudAccount-Password

        GroupID
    -->
    <ng-container *ngSwitchCase="productName === 'Create-AdminMailbox'
                                || productName === 'Reset-Okta-CloudAccount-Password'
                                || productName === 'Reset-Okta-TechnicalAccount-Password'
                                || productName === 'Exclude-Okta-User-MFA'
                                || productName === 'Undelete-O365Object' ? productName : 'error'">
        <div class="row">
            <div class="">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Reset-OktaB2B-CloudAccount-Password

        OktaUsername
    -->
    <ng-container *ngSwitchCase="productName === 'Reset-OktaB2B-CloudAccount-Password'
                                || productName === 'Reactivate-OktaB2B-CloudAccount' ? productName : 'error'">
        <div class="row">
            <div class="">
                <b>Okta Username:</b> {{ parameters.OKTAUSERNAME }}
            </div>
        </div>
    </ng-container>

    <!-- Reset-Okta-User-Mfa

            OktaUsername
            OktaMfaProvider
    -->
    <ng-container *ngSwitchCase="'Reset-Okta-User-MFA'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID || parameters.GroupID }}
            </div>
            <div *ngIf="parameters.PLATFORM || parameters.Platform" class="col-5">
                <b>Platform:</b> {{ parameters.PLATFORM || parameters.Platform }}
            </div>
            <div *ngIf="parameters.OKTAMFAPROVIDER || parameters.OktaMfaProvider" class="col-5">
                <b>Okta Mfa Provider:</b> {{ parameters.OKTAMFAPROVIDER || parameters.OktaMfaProvider }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Reset-OktaB2B-User-MFA'">
        <div class="row">
            <div class="col-3">
                <b>OktaUsername:</b> {{ parameters.GROUPID || parameters.GroupID || parameters.OKTAUSERNAME ||
                parameters.OktaUsername}}
            </div>
            <div *ngIf="parameters.OKTAMFAPROVIDER || parameters.OktaMfaProvider" class="col-5">
                <b>Okta Mfa Provider:</b> {{ parameters.OKTAMFAPROVIDER || parameters.OktaMfaProvider }}
            </div>
        </div>
    </ng-container>

    <!-- Reset-Guest-MFA

            Guest
    -->
    <ng-container *ngSwitchCase="'Reset-Guest-MFA'">
        <div class="row">
            <div class="col-8">
                <b>Guest:</b> {{ parameters.GUEST }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Bypass-Okta-MFA

            GroupID
            OktaMfaProvider
    -->
    <ng-container *ngSwitchCase="'Bypass-Okta-MFA'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Create-DistributionList

        Name
        Owner
        OwnerSendAs
        Members
        AdditionalOwners
        AdditionalOwnersSendAs
        Type
        PrimarySMTP
        PrimarySMTPDomain
        MailAddresses
        AcceptFrom
        RejectFrom
        Discriminant
        Moderators
        Hidden
    -->

    <ng-container *ngSwitchCase="'Create-DistributionList'">
        <div class="row">
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Owner:</b> {{ parameters.OWNER }}
            </div>
            <div class="col-4">
                <b>Owner SendAs:</b> {{ booleanToStringConverterImport(parameters.OWNERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Members:</b>
                <div class="" *ngIf="isParameterArray(parameters.MEMBERS)">
                    <span *ngFor="let member of parameters.MEMBERS">
                        {{ member }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MEMBERS)">
                    <span>{{ parameters.MEMBERS }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Owners:</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALOWNERS)">
                    <span *ngFor="let additionalOwner of parameters.ADDITIONALOWNERS">
                        {{ additionalOwner }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALOWNERS)">
                    <span>{{ parameters.ADDITIONALOWNERS }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Owners SendAs:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALOWNERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Type:</b> {{ parameters.DLTYPE }}
            </div>
            <div class="col-4">
                <b>Primary SMTP:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>Primary SMTP Domain:</b> {{ parameters.PRIMARYSMTPDOMAIN }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mail of parameters.MAILADDRESSES">
                        {{ mail }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Accept From:</b>
                <div class="" *ngIf="isParameterArray(parameters.ACCEPTFROM)">
                    <span *ngFor="let memberAccepted of parameters.ACCEPTFROM">
                        {{ memberAccepted }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ACCEPTFROM)">
                    <span>{{ parameters.ACCEPTFROM }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Reject From:</b>
                <div class="" *ngIf="isParameterArray(parameters.REJECTFROM)">
                    <span *ngFor="let memberRejected of parameters.REJECTFROM">
                        {{ memberRejected }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.REJECTFROM)">
                    <span>{{ parameters.REJECTFROM }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Discriminant:</b> {{ parameters.DISCRIMINANT }}
            </div>
            <div class="col-4">
                <b>Moderators:</b>
                <div class="" *ngIf="isParameterArray(parameters.MODERATORS)">
                    <span *ngFor="let moderator of parameters.MODERATORS">
                        {{ moderator }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MODERATORS)">
                    <span>{{ parameters.MODERATORS }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Add-DistributionList-Members && Set-DistributionList-Members && Remove-DistributionList-Members

            Owner
            ListID
            Members
    -->
    <ng-container *ngSwitchCase="productName === 'Add-DistributionList-Members' 
                                || productName === 'Set-DistributionList-Members'
                                || productName === 'Remove-DistributionList-Members' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-8">
                <div class="">
                    <b>Members:</b>
                </div>
                <div class="d-flex flex-wrap" *ngIf="isParameterArray(parameters.MEMBERS)">
                    <span
                        *ngFor="let member of parameters.MEMBERS; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ member }}</span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MEMBERS)">
                    <span>{{ parameters.MEMBERS }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Get-DistributionList-Members

            ListID
    -->
    <ng-container *ngSwitchCase="'Get-DistributionList-Members'">
        <div class="row">
            <div class="col-3">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-3">
                <b>Recursive:</b> {{ booleanToStringConverterImport(parameters.RECURSIVE, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Add-DistributionList-Owner

            Owner
            ListID
            AdditionalOwnersSendAs
    -->
    <ng-container *ngSwitchCase="'Add-DistributionList-Owner'">
        <div class="row">
            <div class="col-3">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-5">
                <div class="">
                    <b>Additional Owners:</b>
                </div>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALOWNERS)">
                    <span *ngFor="let additionalOwner of parameters.ADDITIONALOWNERS">{{ additionalOwner }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALOWNERS)">
                    <span>{{ parameters.ADDITIONALOWNERS }}</span>
                </div>
            </div>
            <div class="col-3">
                <b>Apply Send As:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALOWNERSSENDAS, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Change-DistributionList-Owner

            Owner
            ListID
            OwnerSendAs
    -->
    <ng-container *ngSwitchCase="'Change-DistributionList-Owner'">
        <div class="row">
            <div class="col-3">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-5">
                <b>Owner:</b> {{ parameters.OWNER }}
            </div>
            <div class="col-3">
                <b>Apply Send As:</b> {{ booleanToStringConverterImport(parameters.OWNERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Remove-DistributionList-Owner

            Owner
            ListID
            RemoveOwnersSendAs
    -->
    <ng-container *ngSwitchCase="'Remove-DistributionList-Owner'">
        <div class="row">
            <div class="col-3">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-5">
                <b>Owners to remove:</b> {{ parameters.OWNERSTOREMOVE }}
            </div>
            <div class="col-3">
                <b>Remove owner Send As:</b>
                {{ booleanToStringConverterImport(parameters.REMOVEOWNERSSENDAS, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Block-User

            GroupID
            DisableMailboxRules
            RemoveMailboxForwarding
            RemoveMailboxPermissions
            RemoveApplicationConsents
            DisableOneDriveExternalSharing
            RetireIntuneDevices
            TicketID
    -->
    <ng-container *ngSwitchCase="'Block-User'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Disable Mailbox Rules:</b>
                {{ booleanToStringConverterImport(parameters.DISABLEMAILBOXRULES, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Remove Mailbox Forwarding:</b>
                {{ booleanToStringConverterImport(parameters.REMOVEMAILBOXFORWARDING, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Remove Mailbox Permissions:</b>
                {{ booleanToStringConverterImport(parameters.REMOVEMAILBOXPERMISSIONS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Remove Application Consents:</b>
                {{ booleanToStringConverterImport(parameters.REMOVEAPPLICATIONCONSENTS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Disable One Drive External Sharing:</b>
                {{ booleanToStringConverterImport(parameters.DISABLEONEDRIVEEXTERNALSHARING, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Retire Intune Devices:</b>
                {{ booleanToStringConverterImport(parameters.RETIREINTUNEDEVICES, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Get-Mailbox-Info

        GroupID or email address
            
    -->
    <ng-container *ngSwitchCase="'Get-Mailbox-Info'">
        <div class="row">
            <div class="col-3">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Add-Mailbox-Delegation
         Remove-Mailbox-Delegation
         
         For backward compatibility:
         Add-Mailbox-FullAccess
         Add-Mailbox-SendAs
         Add-Mailbox-SendOnBehalf
         Remove-Mailbox-FullAccess
         Remove-Mailbox-SendAs
         Remove-Mailbox-SendOnBehalf

            GroupID
            FullAccessUsers
            SendAsUsers
            SendOnBehalfUsers
    -->
    <ng-container *ngSwitchCase="productName === 'Add-Mailbox-FullAccess'
                                || productName === 'Add-Mailbox-SendAs'
                                || productName === 'Add-Mailbox-SendOnBehalf'
                                || productName === 'Remove-Mailbox-FullAccess'
                                || productName === 'Remove-Mailbox-SendAs'
                                || productName === 'Remove-Mailbox-SendOnBehalf'
                                || productName === 'Add-Mailbox-Delegation'
                                || productName === 'Remove-Mailbox-Delegation' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4" *ngIf="parameters.FULLACCESSUSERS">
                <b>Full Access Members:</b>
                <div class="" *ngIf="isParameterArray(parameters.FULLACCESSUSERS)">
                    <span *ngFor="let member of parameters.FULLACCESSUSERS">{{ member }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.FULLACCESSUSERS)">
                    <span>{{ parameters.FULLACCESSUSERS }}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="parameters.ADDSENDAS || parameters.REMOVESENDAS">
                <b>Send As Members:</b>
                <div class="" *ngIf="isParameterArray(parameters.FULLACCESSUSERS)">
                    <span *ngFor="let member of parameters.FULLACCESSUSERS">{{ member }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.FULLACCESSUSERS)">
                    <span>{{ parameters.FULLACCESSUSERS }}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="parameters.SENDASUSERS">
                <b>Send As Members:</b>
                <div class="" *ngIf="isParameterArray(parameters.SENDASUSERS)">
                    <span *ngFor="let member of parameters.SENDASUSERS">{{ member }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.SENDASUSERS)">
                    <span>{{ parameters.SENDASUSERS }}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="parameters.SENDONBEHALFUSERS">
                <b>Send On Behalf Members:</b>
                <div class="" *ngIf="isParameterArray(parameters.SENDONBEHALFUSERS)">
                    <span *ngFor="let member of parameters.SENDONBEHALFUSERS">{{ member }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.SENDONBEHALFUSERS)">
                    <span>{{ parameters.SENDONBEHALFUSERS }}</span>
                </div>
            </div>
        </div>
    </ng-container>


    <!-- Create-PersonalMailbox
    
        GroupId
        O365 License
        Language
        PrimarySMTP
        PrimarySMTPDomain
        MailAddresses
        Initials
        Brand
        Merge
        External
        Hidden
        EnableArchive
        EnableLitigationHold
        LitigationHoldDuration

    -->
    <ng-container *ngSwitchCase="'Create-PersonalMailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>License:</b> {{ parameters.OFFICE365LICENSES }}
            </div>
            <div class="col-4">
                <b>Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Primary SMTP:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>Primary SMTP Domain:</b> {{ parameters.PRIMARYSMTPDOMAIN }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Initials:</b> {{ parameters.INITIALS }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Enable Archive:</b> {{ booleanToStringConverterImport(parameters.ENABLEARCHIVE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>External:</b> {{ booleanToStringConverterImport(parameters.EXTERNAL, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Enable Litigation Hold:</b>
                {{ booleanToStringConverterImport(parameters.ENABLELITIGATIONHOLD, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.ENABLELITIGATIONHOLD">
                <b>LitigationHold Duration:</b> {{ parameters.LITIGATIONHOLDDURATION }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Merge:</b> {{ booleanToStringConverterImport(parameters.MERGE, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.MERGE">
                <b>MEU External Email Address:</b> {{ parameters.MEUEXTERNALEMAILADDRESS }}
            </div>
        </div>


    </ng-container>

    <!-- Get-MobileDevice-Info

        MailboxID
        CheckonPremise
    -->
    <ng-container *ngSwitchCase="'Get-MobileDevice-Info'">
        <div class="row">
            <div class="col-3">
                <b>Mailbox ID:</b> {{ parameters.MAILBOXID }}
            </div>
            <div class="col-5">
                <b>Check On Premise:</b> {{ parameters.CHECKONPREMISE }}
            </div>
        </div>
    </ng-container>

    <!-- Get-User-Info

            GroupID
    -->
    <ng-container *ngSwitchCase="'Get-User-Info'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-6">
                <b>Information to show:</b>
                <div class="" *ngIf="isParameterArray(parameters.INFORMATIONTOSHOW)">
                    <span *ngFor="let info of parameters.INFORMATIONTOSHOW">
                        {{ info }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.INFORMATIONTOSHOW)">
                    <span>{{ parameters.INFORMATIONTOSHOW }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Assign-License && Remove-License

            GroupID
            License
    -->
    <ng-container *ngSwitchCase="productName === 'Assign-License'
                                || productName === 'Remove-License' ? productName : 'error'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-5">
                <b>License:</b> {{ parameters.LICENSESKU }}
            </div>
        </div>
    </ng-container>

    <!-- Modify-License-Option

            GroupID
            License
            EnableProducts
            DisableProducts
    -->
    <ng-container *ngSwitchCase="'Modify-License-Option'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-5">
                <b>License:</b> {{ parameters.LICENSE }}
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <b>Enable Products:</b> {{ parameters.ENABLEPRODUCTS }}
            </div>
            <div class="col-5">
                <b>Disable Products:</b> {{ parameters.DISABLEPRODUCTS }}
            </div>
        </div>
    </ng-container>

    <!-- Enable-S4B-IntercallDialinConferencing

            GroupID
            IntConfID
            TollNumber
            TollFreeNumber
            OverWrite
    -->
    <ng-container *ngSwitchCase="'Enable-S4B-IntercallDialinConferencing'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-5">
                <b>IntConfID:</b> {{ parameters.INTCONFID }}
            </div>
            <div class="col-4">
                <b>Toll Number:</b> {{ parameters.TOLLNUMBER }}
            </div>
        </div>
        <div class="row">
            <div class="col-5 offset-3">
                <b>Overwrite:</b> {{ parameters.OVERWRITE }}
            </div>
            <div class="col-4">
                <b>Toll Free Number:</b> {{ parameters.TOLLFREENUMBER }}
            </div>
        </div>
    </ng-container>

    <!-- Modify-S4B-ConferencingPolicy

            GroupID
            AudioVideoSetting
            EnableFileTransfer
    -->
    <ng-container *ngSwitchCase="'Modify-S4B-ConferencingPolicy'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-5">
                <b>Audio Video Setting:</b> {{ parameters.AUDIOVIDEOSETTING }}
            </div>
            <div class="col-4">
                <b>Enable File Transfer:</b> {{ parameters.ENABLEFILETRANSFER }}
            </div>
        </div>
    </ng-container>

    <!-- Remove-S4B-PhoneSystem

            GroupID
            
    -->
    <ng-container *ngSwitchCase="'Remove-S4B-PhoneSystem'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Enable-S4B-PhoneSystem

            GroupID
            PhoneNumber
            InternationalCalls
            VoiceMail         
    -->
    <ng-container *ngSwitchCase="'Enable-S4B-PhoneSystem'">
        <div class="row">
            <div class="col-2">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-3">
                <b>Phone Number:</b> {{ parameters.USERTEL }}
            </div>
            <div class="col-3">
                <b>International Calls:</b>
                {{ booleanToStringConverterImport(parameters.INTERNATIONALCALLS, 'No', 'Yes') }}
            </div>
            <div class="col-3">
                <b>Voice Mail:</b> {{ booleanToStringConverterImport(parameters.HOSTEDVOICEMAIL, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Enable-MobileDevice

        MailboxID
        DeviceIDs
    -->
    <ng-container *ngSwitchCase="'Enable-MobileDevice'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Device IDs:</b>
                <div class="" *ngIf="isParameterArray(parameters.DEVICEIDS)">
                    <span *ngFor="let deviceId of parameters.DEVICEIDS">{{ deviceId }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.DEVICEIDS)">
                    <span>{{ parameters.DEVICEIDS }}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="parameterContains(parameters.DEVICEIDS, 'All')">
                <b>Device IDs To Enable:</b>
                <div class="" *ngIf="isParameterArray(parameters.DeviceIDsToEnable)">
                    <span *ngFor="let deviceIdEnable of parameters.DeviceIDsToEnable">{{ deviceIdEnable }}<br /></span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.DeviceIDsToEnable)">
                    <span>{{ parameters.DeviceIDsToEnable }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Enable-LitigationHold

            GroupID
            LitigationHoldDuration
            LitigationHoldOwner
            AssignOrUpgradeLicense         
    -->
    <ng-container *ngSwitchCase="'Enable-LitigationHold'">
        <div class="row">
            <div class="col-2">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-3">
                <b>Litigation Hold Duration:</b>
                {{ parameters.LITIGATIONHOLDDURATION ? parameters.LITIGATIONHOLDDURATION + ' Years' : '' }}
            </div>
            <div class="col-3">
                <b>Litigation Hold Owner:</b> {{ parameters.LITIGATIONHOLDOWNER }}
            </div>
            <div class="col-3">
                <b>Assign Or Upgrade License:</b>
                {{ booleanToStringConverterImport(parameters.ASSIGNORUPGRADELICENSE, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Enable-ArchiveOnline

            GroupID
            AssignOrUpgradeLicense         
    -->
    <ng-container *ngSwitchCase="'Enable-ArchiveOnline'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <!--
            <div class="col-4">
                <b>Assign Or Upgrade License:</b> {{ booleanToStringConverterImport(parameters.ASSIGNORUPGRADELICENSE, 'No', 'Yes') }}
            </div>
            -->
        </div>
    </ng-container>

    <!-- Change-Mailbox-DisplayName

            GroupID
            Discriminant
            Name
            Brand         
    -->
    <ng-container *ngSwitchCase="'Change-Mailbox-DisplayName'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div *ngIf="parameters.DISCRIMINANT" class="col-4">
                <b>Discriminant:</b> {{ parameters.DISCRIMINANT }}
            </div>
            <div *ngIf="parameters.COUNTRYCODE" class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div *ngIf="parameters.SITE" class="col-4">
                <b>Site:</b> {{ parameters.SITE }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
        </div>
    </ng-container>

    <!-- Change-Mailbox-Manager

            GroupID
            Manager
            ManagerSendAs         
    -->
    <ng-container *ngSwitchCase="'Change-Mailbox-Manager'">
        <div class="col-4">
            <b>Group ID or email address:</b> {{ parameters.GROUPID }}
        </div>
        <div class="col-4">
            <b>New Manager:</b> {{ parameters.MANAGER }}
        </div>
        <div class="col-4">
            <b>Manager with Send As Permission:</b>
            {{ booleanToStringConverterImport(parameters.MANAGERSENDAS, 'No', 'Yes') }}
        </div>
    </ng-container>

    <!-- Change-Mailbox-Language

            GroupID
            Language
    -->
    <ng-container *ngSwitchCase="'Change-Mailbox-Language'">
        <div class="col-4">
            <b>Mailbox Group ID:</b> {{ parameters.GROUPID }}
        </div>
        <div class="col-4">
            <b>Language:</b> {{ parameters.LANGUAGE }}
        </div>
    </ng-container>

    <!-- Change-Mailbox-PrimarySMTP

            GroupID
            PrimarySmtp
            SIPAddress
    -->
    <ng-container *ngSwitchCase="'Change-Mailbox-PrimarySMTP'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Primary Smtp Address:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>SIP Address:</b> {{ parameters.SIPADDRESS }}
            </div>
        </div>
    </ng-container>

    <!-- Disable-LitigationHold

            GroupID
    -->
    <ng-container *ngSwitchCase="'Disable-LitigationHold'">
        <div class="col-4">
            <b>Group ID or email address:</b> {{ parameters.GROUPID }}
        </div>
    </ng-container>

    <!-- Create-AnonymousMailbox
        GroupID
        Name
        Manager
        ManagerSendAs
        PrimarySMTP
        Discriminant
        Office365Licenses
        Brand
        Language
        MailAddresses
        AdditionalManager
        AdditionalManagerSendAs
        EnableArchive
        Hidden       
        EnableLitigationHold
        LitigationHoldDuration
    -->
    <ng-container *ngSwitchCase="'Create-AnonymousMailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
            <div class="col-4">
                <b>Manager SendAs:</b> {{ booleanToStringConverterImport(parameters.MANAGERSENDAS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Primary SMTP:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>Discriminant:</b> {{ parameters.DISCRIMINANT }}
            </div>
            <div class="col-4">
                <b>Office365 License:</b> {{ parameters.OFFICE365LICENSES }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span *ngFor="let manager of parameters.ADDITIONALMANAGER">
                        {{ manager }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span>{{ parameters.ADDITIONALMANAGER }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager Send As:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALMANAGERSENDAS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Enable Archive:</b> {{ booleanToStringConverterImport(parameters.ENABLEARCHIVE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Enable Litigation Hold:</b>
                {{ booleanToStringConverterImport(parameters.ENABLELITIGATIONHOLD, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.ENABLELITIGATIONHOLD">
                <b>LitigationHold Duration:</b> {{ parameters.LITIGATIONHOLDDURATION }}
            </div>
        </div>
    </ng-container>

    <!-- Create-ApplicationMailbox
        GroupID
        Name
        Manager
        ManagerSendAs
        PrimarySMTP
        Discriminant
        Office365Licenses
        Brand
        Language
        MailAddresses
        AdditionalManager
        AdditionalManagerSendAs
        EnableArchive
        Hidden
        ExternalSend        
        EnableLitigationHold
        LitigationHoldDuration
    -->
    <ng-container *ngSwitchCase="'Create-ApplicationMailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Manager SendAs:</b> {{ booleanToStringConverterImport(parameters.MANAGERSENDAS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Primary SMTP:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>Discriminant:</b> {{ parameters.DISCRIMINANT }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Office365 License:</b> {{ parameters.OFFICE365LICENSES }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span *ngFor="let manager of parameters.ADDITIONALMANAGER">
                        {{ manager }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span>{{ parameters.ADDITIONALMANAGER }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager Send As:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALMANAGERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Enable Archive:</b> {{ booleanToStringConverterImport(parameters.ENABLEARCHIVE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>External send:</b> {{ booleanToStringConverterImport(parameters.EXTERNALSEND, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Enable Litigation Hold:</b>
                {{ booleanToStringConverterImport(parameters.ENABLELITIGATIONHOLD, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.ENABLELITIGATIONHOLD">
                <b>LitigationHold Duration:</b> {{ parameters.LITIGATIONHOLDDURATION }}
            </div>
        </div>
    </ng-container>

    <!-- Create-RoomMailbox
        
        GroupID
        ResourceType
        Name
        CountryCode
        Site
        Manager
        ManagerSendAs
        MailAddresses
        Language
        Hidden
        AdditionalManager
        AdditionalManagerSendAs
        ResourceDelegates
        Capacity
        RoomLocation
        ScheduleOnlyDuringWorkHours
        TimeZone
        WorkingHoursStartTime
        WorkingHoursEndTime
        Brand
        Sensitive
        BookingProfile
        TicketID
    -->
    <ng-container *ngSwitchCase="productName === 'Create-RoomMailbox'
                              || productName === 'Create-ResourceMailbox' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Resource Type:</b> {{ parameters.RESOURCETYPE }}
            </div>
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>Site:</b> {{ parameters.SITE }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
            <div class="col-4">
                <b>Manager SendAs:</b> {{ booleanToStringConverterImport(parameters.MANAGERSENDAS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Additional Manager(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span *ngFor="let manager of parameters.ADDITIONALMANAGER">
                        {{ manager }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span>{{ parameters.ADDITIONALMANAGER }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager Send As:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALMANAGERSENDAS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Resource Delegate(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.RESOURCEDELEGATES)">
                    <span *ngFor="let delegate of parameters.RESOURCEDELEGATES">
                        {{ delegate }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.RESOURCEDELEGATES)">
                    <span>{{ parameters.RESOURCEDELEGATES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Sensitive:</b>
                {{ booleanToStringConverterImport(parameters.SENSITIVE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Schedule only during working hours:</b>
                {{ booleanToStringConverterImport(parameters.SCHEDULEONLYDURINGWORKHOURS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Time Zone:</b> {{ parameters.TIMEZONE }}
            </div>
            <div class="col-4">
                <b>Working Hours Start Time:</b> {{ parameters.WORKINGHOURSSTARTTIME }}
            </div>
            <div class="col-4">
                <b>Working Hours End Time:</b> {{ parameters.WORKINGHOURSENDTIME }}
            </div>
            <div class="col-4" *ngIf="parameters.VCTYPE">
                <b>VCType:</b> {{ parameters.VCTYPE }}
            </div>
            <div class="col-4" *ngIf="parameters.SIPURI">
                <b>SIP URI:</b> {{ parameters.SIPURI }}
            </div>
            <div class="col-4" *ngIf="!parameters.TECHNICALACCOUNT && parameters.MANAGEMENTSCOPE">
                <b>Management Scope:</b> {{ parameters.MANAGEMENTSCOPE }}
            </div>
            <div class="col-4" *ngIf="parameters.TECHNICALACCOUNT">
                <b>Technical Account:</b> {{ parameters.TECHNICALACCOUNT }}
            </div>
            <div *ngIf="parameters.RESOURCETYPE != 'EquipmentMailbox'" class="col-4">
                <b>Capacity:</b> {{ parameters.CAPACITY }}
            </div>
            <div *ngIf="parameters.RESOURCETYPE != 'EquipmentMailbox'" class="col-4">
                <b>Room Location:</b> {{ parameters.ROOMLOCATION }}
            </div>
            <div *ngIf="parameters.BOOKINGPROFILE" class="col-4">
                <b>Booking Profile:</b>
                {{ parameters.BOOKINGPROFILE }}
            </div>
        </div>
    </ng-container>

    <!--Enable-Teams-RoomMailbox-->
    <ng-container *ngSwitchCase="'Enable-Teams-RoomMailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>Site:</b> {{ parameters.SITE }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
            <div class="col-4">
                <b>Mailbox Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
            <div class="col-4">
                <b>Additional Manager(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span *ngFor="let manager of parameters.ADDITIONALMANAGER">
                        {{ manager }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span>{{ parameters.ADDITIONALMANAGER }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Resource Delegate(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.RESOURCEDELEGATES)">
                    <span *ngFor="let delegate of parameters.RESOURCEDELEGATES">
                        {{ delegate }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.RESOURCEDELEGATES)">
                    <span>{{ parameters.RESOURCEDELEGATES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Capacity:</b> {{ parameters.CAPACITY }}
            </div>
            <div class="col-4">
                <b>Time Zone:</b> {{ parameters.TIMEZONE }}
            </div>
            <div class="col-4">
                <b>Working Hours Start Time:</b> {{ parameters.WORKINGHOURSSTARTTIME }}
            </div>
            <div class="col-4">
                <b>Working Hours End Time:</b> {{ parameters.WORKINGHOURSENDTIME }}
            </div>
            <div *ngIf="parameters.LICENSE" class="col-4">
                <b>License:</b>
                {{ parameters.LICENSE }}
            </div>
            <div class="col-4">
                <b>Brand:</b>
                {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Schedule only during working hours:</b>
                {{ booleanToStringConverterImport(parameters.SCHEDULEONLYDURINGWORKHOURS, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Enable Teams Telephony:</b>
                {{ booleanToStringConverterImport(parameters.ENABLETEAMSTELEPHONY, 'No', 'Yes') }}
            </div>
            <div *ngIf="parameters.PHONENUMBER" class="col-4">
                <b>Phone Number:</b>
                {{ parameters.PHONENUMBER }}
            </div>
            <div *ngIf="parameters.VRPOLICY" class="col-4">
                <b>VR Policy:</b>
                {{ parameters.VRPOLICY }}
            </div>
            <div class="col-4">
                <b>Sensitive:</b>
                {{ booleanToStringConverterImport(parameters.SENSITIVE, 'No', 'Yes') }}
            </div>
            <div *ngIf="parameters.BOOKINGPROFILE" class="col-4">
                <b>Booking Profile:</b>
                {{ parameters.BOOKINGPROFILE }}
            </div>
        </div>
    </ng-container>

    <!-- Create-SharedMailbox

        Name
        Manager
        ManagerSendAs
        PrimarySMTP
        PrimarySMTPDomain
        Discriminant
        MailAddresses
        AdditionalManager
        AdditionalManagerSendAs
        EnableArchive
        Hidden
        Language
        EnableLitigationHold
        LitigationHoldDuration
    -->
    <ng-container *ngSwitchCase="'Create-SharedMailbox'">
        <div class="row">
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
            <div class="col-4">
                <b>Manager SendAs:</b> {{ booleanToStringConverterImport(parameters.MANAGERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Primary SMTP:</b> {{ parameters.PRIMARYSMTP }}
            </div>
            <div class="col-4">
                <b>Primary SMTP Domain:</b> {{ parameters.PRIMARYSMTPDOMAIN }}
            </div>
            <div class="col-4">
                <b>Discriminant:</b> {{ parameters.DISCRIMINANT }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager(s):</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span *ngFor="let manager of parameters.ADDITIONALMANAGER">
                        {{ manager }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDITIONALMANAGER)">
                    <span>{{ parameters.ADDITIONALMANAGER }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Additional Manager Send As:</b>
                {{ booleanToStringConverterImport(parameters.ADDITIONALMANAGERSENDAS, 'No', 'Yes') }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Enable Archive:</b> {{ booleanToStringConverterImport(parameters.ENABLEARCHIVE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Language:</b> {{ parameters.MAILBOXLANGUAGE }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Enable Litigation Hold:</b>
                {{ booleanToStringConverterImport(parameters.ENABLELITIGATIONHOLD, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.ENABLELITIGATIONHOLD">
                <b>LitigationHold Duration:</b> {{ parameters.LITIGATIONHOLDDURATION }}
            </div>
        </div>
    </ng-container>

    <!-- Create-Okta-CloudAccount
         Update-Okta-CloudAccount
    
        GROUPID
        SPONSORGROUPID
        USAGE
        PROFILETYPE
        LASTNAME
        FIRSTNAME
        EMAILADDRESS
        COMPANY
        EXTERNALCOMPANY
        BUSINESSUNIT
        COUNTRYCODE
        EXPIRATIONDATE
    -->
    <ng-container *ngSwitchCase="productName === 'Create-Okta-CloudAccount'
                                || productName === 'Update-Okta-CloudAccount' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group Id:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Sponsor Group Id:</b> {{ parameters.SPONSORGROUPID }}
            </div>
            <div class="col-4">
                <b>Usage:</b> {{ parameters.USAGE }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Profile Type:</b> {{ parameters.PROFILETYPE }}
            </div>
            <div class="col-4">
                <b>Last Name:</b> {{ parameters.LASTNAME }}
            </div>
            <div class="col-4">
                <b>First Name:</b> {{ parameters.FIRSTNAME }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Email Address:</b> {{ parameters.EMAILADDRESS }}
            </div>
            <div class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>Company:</b> {{ parameters.COMPANY }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>GBU:</b> {{ parameters.GBU }}
            </div>
            <div class="col-4" *ngIf="parameters.BUSINESSUNIT">
                <b>Business Unit:</b> {{ parameters.BUSINESSUNIT }}
            </div>
            <div class="col-4">
                <b>OrgID:</b> {{ parameters.ORGID }}
            </div>
            <div class="col-4">
                <b>Expiration Date:</b> {{ parameters.EXPIRATIONDATE }}
            </div>
            <div class="col-4" *ngIf="parameters.EXTERNALCOMPANY">
                <b>External Company:</b> {{ parameters.EXTERNALCOMPANY }}
            </div>
        </div>
        <div class="row" *ngIf="parameters.SITE || parameters.DEPARTMENT">
            <div class="col-4">
                <b>Site:</b> {{ parameters.SITE }}
            </div>
            <div class="col-4">
                <b>Department:</b> {{ parameters.DEPARTMENT }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="productName === 'Create-Okta-TechnicalAccount' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group Id:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Owner Group Id:</b> {{ parameters.OWNERGROUPID }}
            </div>
            <div class="col-4">
                <b>Usage:</b> {{ parameters.USAGE }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="productName === 'Update-Okta-TechnicalAccount' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group Id:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Owner Group Id:</b> {{ parameters.OWNERGROUPID }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="productName === 'Deactivate-Okta-TechnicalAccount' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group Id:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Create-OktaB2B-CloudAccount
         Update-OktaB2B-CloudAccount
    
        OKTAUSERNAME
        SPONSORGROUPID
        COUNTRYCODE
        LASTNAME
        FIRSTNAME
        COMPANY
        OKTAGROUPNAME
        EXTERNALCOMPANY
        ORGID
        EXPIRATIONDATE
    -->
    <ng-container *ngSwitchCase="productName === 'Create-OktaB2B-CloudAccount'
                                || productName === 'Update-OktaB2B-CloudAccount' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Okta Username:</b> {{ parameters.OKTAUSERNAME }}
            </div>
            <div class="col-4" *ngIf="productName === 'Update-OktaB2B-CloudAccount'">
                <b>New Okta Username:</b> {{ parameters.NEWOKTAUSERNAME }}
            </div>
            <div class="col-4">
                <b>Sponsor Group Id:</b> {{ parameters.SPONSORGROUPID }}
            </div>
            <div class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>Last Name:</b> {{ parameters.LASTNAME }}
            </div>
            <div class="col-4">
                <b>First Name:</b> {{ parameters.FIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Company:</b> {{ parameters.COMPANY }}
            </div>
            <div class="col-4" *ngIf="productName === 'Create-OktaB2B-CloudAccount'">
                <b>Okta Group Name:</b> {{ parameters.OKTAGROUPNAME }}
            </div>
            <div class="col-4">
                <b>External Company:</b> {{ parameters.EXTERNALCOMPANY }}
            </div>
            <div class="col-4">
                <b>OrgID:</b> {{ parameters.ORGID }}
            </div>
            <div class="col-4">
                <b>Expiration Date:</b> {{ parameters.EXPIRATIONDATE }}
            </div>
        </div>
    </ng-container>

    <!-- Delete-O365Object

            GroupID
            NewOwner
            PreventMailboxReconnect
            PreventResubscribeDL
    -->
    <ng-container *ngSwitchCase="'Delete-O365Object'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>New Owner:</b> {{ parameters.NEWOWNER }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Prevent Mailbox Reconnect:</b>
                {{ booleanToStringConverterImport(parameters.PREVENTMAILBOXRECONNECT, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Prevent Resubscribe DL:</b>
                {{ booleanToStringConverterImport(parameters.PREVENTRESUBSCRIBEDL, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Create-MEU

            GroupID
            PrimarySmtpAddress
            MailAddresses
            Company
            Brand
    -->
    <ng-container *ngSwitchCase="'Create-MEU'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Primary SMTP Address:</b> {{ parameters.PRIMARYSMTPADDRESS }}
            </div>
            <div class="col-4">
                <b>Primary SMTP Domain:</b> {{ parameters.PRIMARYSMTPDOMAIN }}
            </div>
            <div class="col-4">
                <b>Initials:</b> {{ parameters.INITIALS }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <b>Merge:</b> {{ booleanToStringConverterImport(parameters.MERGE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>External:</b> {{ booleanToStringConverterImport(parameters.EXTERNAL, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Hidden:</b> {{ booleanToStringConverterImport(parameters.HIDDEN, 'No', 'Yes') }}
            </div>
        </div>
    </ng-container>

    <!-- Add-DistributionList-MailAddresses
            Remove-DistributionList-MailAddresses

            ListID
            MailAddresses
    -->
    <ng-container *ngSwitchCase="productName ===  'Add-DistributionList-MailAddresses'
                                || productName === 'Remove-DistributionList-MailAddresses' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>DistributionList ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Add-DistributionList-DeliveryRestriction 
            Remove-DistributionList-DeliveryRestriction 

            ListID
            MailAddresses
    -->
    <ng-container
        *ngSwitchCase="productName ===  'Add-DistributionList-DeliveryRestriction'
                                || productName === 'Remove-DistributionList-DeliveryRestriction' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>DistributionList ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-4">
                <b>Accept From:</b>
                <div class="" *ngIf="isParameterArray(parameters.ACCEPTFROM)">
                    <span *ngFor="let memberAccepted of parameters.ACCEPTFROM">
                        {{ memberAccepted }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ACCEPTFROM)">
                    <span>{{ parameters.ACCEPTFROM }}</span>
                </div>
            </div>
            <div class="col-4">
                <b>Reject From:</b>
                <div class="" *ngIf="isParameterArray(parameters.REJECTFROM)">
                    <span *ngFor="let memberRejected of parameters.REJECTFROM">
                        {{ memberRejected }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.REJECTFROM)">
                    <span>{{ parameters.REJECTFROM }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Add-Mailbox-MailAddresses
            Remove-Mailbox-MailAddresses

            ListID
            MailAddresses
    -->
    <ng-container *ngSwitchCase="productName ===  'Add-Mailbox-MailAddresses'
                                || productName === 'Remove-Mailbox-MailAddresses' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Mail Addresses:</b>
                <div class="" *ngIf="isParameterArray(parameters.MAILADDRESSES)">
                    <span *ngFor="let mailAddress of parameters.MAILADDRESSES">
                        {{ mailAddress }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MAILADDRESSES)">
                    <span>{{ parameters.MAILADDRESSES }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Change-DistributionList-PrimarySMTP

            ListID
            PrimarySmtpAddress
    -->
    <ng-container *ngSwitchCase="'Change-DistributionList-PrimarySMTP'">
        <div class="row">
            <div class="col-4">
                <b>Distribution List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-4">
                <b>Primary SMTP Address:</b> {{ parameters.PRIMARYSMTPADDRESS }}
            </div>
        </div>
    </ng-container>

    <!-- Remove-DistributionList-SendAs

            ListID
            SendAsUsers
    -->
    <ng-container *ngSwitchCase="'Remove-DistributionList-SendAs'">
        <div class="row">
            <div class="col-4">
                <b>Distribution List ID:</b> {{ parameters.LISTID }}
            </div>

            <div class="col-4">
                <b>Send As:</b>
                <div class="" *ngIf="isParameterArray(parameters.SENDASUSERS)">
                    <span *ngFor="let sendAs of parameters.SENDASUSERS">
                        {{ sendAs }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.SENDASUSERS)">
                    <span>{{ parameters.SENDASUSERS }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Hide/UnHide-DistributionList

            ListID
            Reason
    -->
    <ng-container *ngSwitchCase="productName ===  'Hide-DistributionList'
                                || productName === 'UnHide-DistributionList' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Distribution List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-8">
                <b>Reason:</b> {{ parameters.REASON }}
            </div>
        </div>
    </ng-container>

    <!-- Hide-Mailbox

        GroupID
        Reason
    -->
    <ng-container *ngSwitchCase="'Hide-Mailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-8">
                <b>Reason:</b> {{ parameters.REASON }}
            </div>
        </div>
    </ng-container>

    <!-- UnHide-Mailbox

        GroupID
    -->
    <ng-container *ngSwitchCase="'UnHide-Mailbox'">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Check Local IT Card

            Name
            Manager Group ID
            Quota High
            Local IT Email
            Manager Contact Phone Number
            Quota Low
            Companies
            Members Group ID
    -->
    <ng-container *ngSwitchCase="'Manage-LocalITCard'">
        <div class="row">
            <div class="col-4">
                <b>Local IT Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Local IT Display Name:</b> {{ parameters.DISPLAYNAME }}
            </div>
            <div class="col-4">
                <b>Manager Group ID:</b> {{ parameters.MANAGER | uppercase }}
            </div>
            <div class="col-4" style="display: none">
                <b>Quota High:</b> {{ parameters.QUOTAHIGH }}
            </div>
            <div class="col-4">
                <b>Local IT Email:</b> {{ parameters.LOCALITCONTACTEMAIL }}
            </div>
            <div class="col-4">
                <b>Manager Contact Phone Number:</b> {{ parameters.CONTACTPHONENUMBER }}
            </div>
            <div class="col-4">
                <b>Show All:</b> {{ parameters.SHOWALL }}
            </div>
            <div class="col-4">
                <b>Manage All:</b> {{ parameters.MANAGEALL }}
            </div>
            <div class="col-4" style="display: none">
                <b>Quota Low:</b> {{ parameters.QUOTALOW }}
            </div>
            <div class="col-8">
                <b>Members GroupID:</b>
                <div class="d-flex flex-wrap" *ngIf="isParameterArray(parameters.MEMBERS)">
                    <span
                        *ngFor="let member of parameters.MEMBERS; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ member | uppercase }}</span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.MEMBERS)">
                    <span>{{ parameters.MEMBERS | uppercase }}</span>
                </div>
            </div>
        </div>

    </ng-container>


    <!-- Create-AAD-Group

        GroupDisplayName
        GroupDescription
        GroupPurpose
        GroupOwners
        WorkplaceType
        OrgID
    -->
    <ng-container *ngSwitchCase="'Create-AAD-Group'">
        <div class="row">
            <div class="col-4">
                <b>Group Display Name:</b> {{ parameters.GROUPDISPLAYNAME }}
            </div>
            <div class="col-4">
                <b>Group Description:</b> {{ parameters.GROUPDESCRIPTION }}
            </div>
            <div class="col-4">
                <b>Group Purpose:</b> {{ parameters.GROUPPURPOSE }}
            </div>
            <div class="col-4" *ngIf="parameters.WORKPLACETYPE">
                <b>Workplace Type:</b> {{ parameters.WORKPLACETYPE }}
            </div>
            <div class="col-4" *ngIf="parameters.ORGID">
                <b>OrgID:</b> {{ parameters.ORGID }}
            </div>
            <div class="col-4">
                <b>Group Owners:</b>
                <div class="d-flex flex-wrap" *ngIf="isParameterArray(parameters.GROUPOWNERS)">
                    <span
                        *ngFor="let owner of parameters.GROUPOWNERS; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">
                        {{ owner | uppercase }}
                    </span>
                </div>
                <div *ngIf="!isParameterArray(parameters.GROUPOWNERS)">
                    <span>{{ parameters.GROUPOWNERS | uppercase }}</span>
                </div>
            </div>
        </div>
        <!--<div>
            <div class="col-4">
                <b>Group Owners GroupID:</b>
                <div class="" *ngIf="isParameterArray(parameters.GROUPOWNERS)">
                    <span *ngFor="let owner of parameters.GROUPOWNERS">
                        {{ owner | uppercase }}<br/>
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.GROUPOWNERS)">
                    <span>{{ parameters.GROUPOWNERS | uppercase }}</span>
                </div>
            </div>
        </div>-->
    </ng-container>

    <!-- Create-AAD-DynamicGroup
        OrgID
        GroupDisplayName
        GroupDescription
        GroupOwners
        FilterRules
    -->
    <ng-container *ngSwitchCase="'Create-AAD-DynamicGroup'">
        <div class="row">
            <div class="col-4" *ngIf="parameters.ORGID">
                <b>OrgID:</b> {{ parameters.ORGID }}
            </div>
            <div class="col-4">
                <b>Group Display Name:</b> {{ parameters.GROUPDISPLAYNAME }}
            </div>
            <div class="col-4">
                <b>Group Description:</b> {{ parameters.GROUPDESCRIPTION }}
            </div>
            <div class="col-4">
                <b>Group Owners:</b>
                <div class="col-12 d-flex flex-wrap" *ngIf="isParameterArray(parameters.GROUPOWNERS)">
                    <span
                        *ngFor="let owner of parameters.GROUPOWNERS; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ owner }}</span>
                </div>
                <div class="col-12" *ngIf="!isParameterArray(parameters.GROUPOWNERS)">
                    <span>{{ parameters.GROUPOWNERS }}</span>
                </div>
            </div>
            <div class="col-8">
                <b>Filter Rules:</b> {{ parameters.FILTERRULES }}
            </div>
        </div>
    </ng-container>

    <!-- Get-Okta-AssignedApp

            GroupID
            
    -->
    <ng-container *ngSwitchCase="'Get-Okta-AssignedApp'">
        <div class="row">
            <div class="col-3">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Add-Intune-User'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Mobile Mail Client:</b> {{ parameters.MOBILEMAILCLIENT }}
            </div>
            <div class="col-4">
                <b>Applications:</b>
                <div class="" *ngIf="isParameterArray(parameters.APPLICATIONS)">
                    <span *ngFor="let app of parameters.APPLICATIONS">
                        {{ app }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.APPLICATIONS)">
                    <span>{{ parameters.APPLICATIONS }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4" *ngIf="parameters.MANAGEDLOCALIT">
                <b>Managed Local IT:</b> {{ parameters.MANAGEDLOCALIT }}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Modify-Intune-User'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Mobile Mail Client:</b> {{ parameters.MOBILEMAILCLIENT }}
            </div>
            <div class="col-4">
                <b>OKTA MFA Bypass:</b> {{ booleanToStringConverterImport(parameters.OKTAMFABYPASS, 'No', 'Yes') }}
            </div>
            <div class="col-4" *ngIf="parameters.MANAGEDLOCALIT">
                <b>Managed Local IT:</b> {{ parameters.MANAGEDLOCALIT }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <b>Add Applications:</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDAPPLICATIONS)">
                    <span *ngFor="let app of parameters.ADDAPPLICATIONS">
                        {{ app }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDAPPLICATIONS)">
                    <span>{{ parameters.ADDAPPLICATIONS }}</span>
                </div>
            </div>
            <div class="col-6">
                <b>Remove Applications:</b>
                <div class="" *ngIf="isParameterArray(parameters.REMOVEAPPLICATIONS)">
                    <span *ngFor="let app of parameters.REMOVEAPPLICATIONS">
                        {{ app }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.REMOVEAPPLICATIONS)">
                    <span>{{ parameters.REMOVEAPPLICATIONS }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Remove-Intune-User'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="productName === 'Add-AAD-GroupMember'
                              || productName === 'Remove-AAD-GroupMember' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Azure AD Group:</b> {{ parameters.AADGROUPDISPLAYNAME }}
            </div>
            <div class="col-8">
                <b>Members:</b>
                <div class="d-flex flex-wrap" *ngIf="isParameterArray(parameters.USERSGID)">
                    <span
                        *ngFor="let member of parameters.USERSGID; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ member }}</span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.USERSGID)">
                    <span>{{ parameters.USERSGID }}</span>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngSwitchCase="'Delete-AzureADGroup'">
        <div class="row">
            <div class="col-8">
                <b>Azure AD Group:</b> {{ parameters.IDENTITY }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Undelete-AzureADGroup'">
        <div class="row">
            <div class="col-8">
                <b>Azure AD Group:</b> {{ parameters.IDENTITY }}
            </div>
            <div class="col-4">
                <b>Owner(s):</b>
                <div class="d-flex flex-wrap">
                    <span
                        *ngFor="let owner of parameters.GROUPOWNERS; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex"
                        [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ owner }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Enable-Teams-PhoneSystem'">
        <div class="row">
            <div class="col-2">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-3">
                <b>Phone Number:</b> {{ parameters.USERTEL }}
            </div>
            <div class="col-3">
                <b>Voice Routing Policy:</b> {{ parameters.VRPOLICY}}
            </div>
            <div class="col-3">
                <b>Calling Policy:</b> {{ parameters.CALLINGPOLICY}}
            </div>
            <div class="col-3">
                <b>Ticket ID:</b> {{ parameters.TICKETID}}
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="productName === 'Clear-Okta-User-Session'
                              || productName === 'Clear-OktaB2B-User-Session'
                              || productName === 'Suspend-Okta-User'
                              || productName === 'Suspend-OktaB2B-User'
                              || productName === 'Unlock-Okta-User'
                              || productName === 'Unlock-OktaB2B-User'
                              || productName === 'Unsuspend-Okta-User'
                              || productName === 'Unsuspend-OktaB2B-User'
                              || productName === 'Remove-Teams-PhoneSystem' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Transfer-Employee

        GroupID
        ADSrc
        ADDst
        TicketID
    -->
    <ng-container *ngSwitchCase="'Transfer-Employee'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Source AD:</b> {{ parameters.ADSRC }}
            </div>
            <div class="col-4">
                <b>Destination AD:</b> {{ parameters.ADDST }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Get-IdentityPersonalData

        GroupID
        TicketID
    -->
    <ng-container *ngSwitchCase="productName === 'Get-IdentityPersonalData'
                              || productName === 'Deactivate-GID'
                              || productName === 'Activate-Okta-TechnicalAccount'
                              || productName === 'Deactivate-Okta-TechnicalAccount'
                              || productName === 'Revoke-AllocID-Permission' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
        </div>
    </ng-container>

    <!-- Reset-UserToken

        GroupID
        TicketID
    -->
    <ng-container *ngSwitchCase="'Reset-UserToken'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Self-Service

        GroupID
        TicketID
    -->
    <ng-container *ngSwitchCase="'Self-Service'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Target TES:</b> {{ parameters.TARGETTES }}
            </div>
            <div class="col-4">
                <b>city:</b> {{ parameters.CITY }}
            </div>
            <div class="" *ngIf="parameters.C_ASSISTANT && parameters.C_ASSISTANT.length > 0">
                <b>C_assistant:</b> {{ parameters.C_ASSISTANT }}
            </div>
            <div class="col-4" *ngIf="!parameters.C_ASSISTANT || parameters.C_ASSISTANT.length == 0">
                <b>C_assistant:</b> {{ parameters.C_ASSISTANT }}
            </div>
            <div class="col-4">
                <b>countryCode:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>C_countryName:</b> {{ parameters.C_COUNTRYNAME }}
            </div>
            <div class="col-4">
                <b>C_facsimileTelephoneNumber:</b> {{ parameters.C_FACSIMILETELEPHONENUMBER }}
            </div>
            <div class="col-4">
                <b>C_houseIdentifier:</b> {{ parameters.C_HOUSEIDENTIFIER }}
            </div>
            <div class="col-4">
                <b>C_ipPhone:</b> {{ parameters.C_IPPHONE }}
            </div>
            <div class="col-4">
                <b>C_physicalDeliveryOfficeName:</b> {{ parameters.C_PHYSICALDELIVERYOFFICENAME }}
            </div>
            <div class="col-4">
                <b>mobilePhone:</b> {{ parameters.MOBILEPHONE }}
            </div>
            <div class="col-4">
                <b>preferredLanguage:</b> {{ parameters.PREFERREDLANGUAGE }}
            </div>
            <div class="col-4">
                <b>primaryPhone:</b> {{ parameters.PRIMARYPHONE }}
            </div>
            <div class="col-4">
                <b>state:</b> {{ parameters.STATE }}
            </div>
            <div class="col-4">
                <b>streetAddress:</b> {{ parameters.STREETADDRESS }}
            </div>
            <div class="col-4">
                <b>zipCode:</b> {{ parameters.ZIPCODE }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Create-Guest-User

        Sponsor
        GuestFirstName
        GuestLastName
        GuestEmailAddress
        ExternalCompany
        SendInvitationMessage
        TicketID
    -->
    <ng-container *ngSwitchCase="'Create-Guest-User'">
        <div class="row">
            <div class="col-4">
                <b>Sponsor:</b> {{ parameters.SPONSOR }}
            </div>
            <div class="col-4">
                <b>Guest First Name:</b> {{ parameters.GUESTFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Guest Last Name:</b> {{ parameters.GUESTLASTNAME }}
            </div>
            <div class="col-4">
                <b>Guest Email Address:</b> {{ parameters.GUESTEMAILADDRESS }}
            </div>
            <div class="col-4">
                <b>External Company:</b> {{ parameters.EXTERNALCOMPANY }}
            </div>
            <div class="col-4">
                <b>Send Invitation Message:</b>
                {{ booleanToStringConverterImport(parameters.SENDINVITATIONMESSAGE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Update-Guest-User'">
        <div class="row">
            <div class="col-4">
                <b>Guest:</b> {{ parameters.GUEST }}
            </div>
            <div class="col-4">
                <b>Sponsor:</b> {{ parameters.SPONSOR }}
            </div>
            <div class="col-4">
                <b>Guest First Name:</b> {{ parameters.GUESTFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Guest Last Name:</b> {{ parameters.GUESTLASTNAME }}
            </div>
            <div class="col-4">
                <b>External Company:</b> {{ parameters.EXTERNALCOMPANY }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Delete-Guest-User

        Guest
        TicketID
    -->
    <ng-container *ngSwitchCase="'Delete-Guest-User'">
        <div class="row">
            <div class="col-4">
                <b>Guest:</b> {{ parameters.GUEST }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!-- Create-Customer-Profile && Update-Customer-Profile && Delete-Customer-Profile  -->
    <ng-container *ngSwitchCase="productName === 'Create-Customer-Profile' 
                                || productName === 'Update-Customer-Profile'
                                || productName === 'Delete-Customer-Profile' ? productName : 'error'">
        <div class="row">
            <div *ngFor="let param of parameters | keyvalue" class="col-4">
                <b>{{ param.key }}:</b> {{ param.value }}
            </div>
        </div>
    </ng-container>

    <!-- Public-Dns

        BusinessUnit
		Entity
        TicketID
        ChangeType
        RecordType
        Environment
        RecordSubtype
        DnsZone
        DnsSubzone
        RecordValue
        Priority
        Ttl
        Comments
        ErrorMessage
    -->
    <ng-container *ngSwitchCase="'Public-Dns'">
        <div class="row">
            <div class="col-4">
                <b>Business Unit:</b> {{ parameters.BUSINESSUNIT }}
            </div>
            <div class="col-4">
                <b>Entity:</b> {{ parameters.ENTITY }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
        <div class="row" *ngFor="let listedFileContent of parameters.DNSRECORDS; let i = index">
            <a class=""
                [ngStyle]="{'word-wrap': 'break-word', 'color': listedFileContent.Status=='Failure'? 'red' : (listedFileContent.Status=='Success'? 'lightgreen' : (listedFileContent.Status=='Running'? 'grey': '#0275d8'))}"
                (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
                [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
                <span style="font-size:1.1em;">
                    {{ listedFileContent.ChangeType }} -
                    {{ listedFileContent.RecordType }} -
                    {{ listedFileContent.Environment }} -
                    <span *ngIf="listedFileContent.RecordSubtype"> {{ listedFileContent.RecordSubtype }} - </span>
                    <span *ngIf="listedFileContent.RecordName"> <b>{{ listedFileContent.RecordName }}</b>.</span>
                    <span *ngIf="listedFileContent.DnSubzone"> <b>{{ listedFileContent.DnsSubzone }}</b>.</span>
                    <b>{{ listedFileContent.DnsZone }}</b> -
                    <span *ngIf="listedFileContent.Priority"> {{ listedFileContent.Priority }} -</span>
                    <span *ngFor="let recordValue of listedFileContent.RecordValue"> [{{ recordValue.Value }}] </span>
                    <i *ngIf="listedFileContent.Status!='Failure'" [attr.id]="'listedFileContent' + i + 'expandBtn'"
                        style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i><i
                        *ngIf="listedFileContent.Status=='Failure'" [attr.id]="'listedFileContent' + i + 'expandBtn'"
                        style="vertical-align: bottom" class="material-icons md-36 pointer">expand_more</i>
                </span>
            </a>
            <div class="{{listedFileContent.Status=='Failure' ? 'collapse show ' : 'collapse '}}"
                [attr.id]="'listedFileContent' + i">
                <div class="card card-block">
                    <div class="row">
                        <div class="col-4">
                            <b>Change type:</b> {{ listedFileContent.ChangeType }}
                        </div>
                        <div class="col-4">
                            <b>Record Type:</b> {{ listedFileContent.RecordType }}
                        </div>
                        <div class="col-4">
                            <b>Environment:</b> {{ listedFileContent.Environment }}
                        </div>
                        <div *ngIf="listedFileContent.RecordType == 'TXT'" class="col-4">
                            <b>Record Subtype:</b> {{ listedFileContent.RecordSubtype }}
                        </div>
                        <div class="col-4">
                            <b>Dns Zone:</b> {{ listedFileContent.DnsZone }}
                        </div>
                        <div class="col-4">
                            <b>Dns Subzone:</b> {{ listedFileContent.DnsSubzone }}
                        </div>
                        <div class="col-4">
                            <b>Record Name:</b> {{ listedFileContent.RecordName }}
                        </div>
                        <div *ngIf="listedFileContent.RecordType == 'MX'" class="col-4">
                            <b>Record Value:</b> {{ listedFileContent.RecordValue[0].Value }}
                        </div>
                        <div *ngIf="listedFileContent.RecordType == 'TXT'" style="word-wrap: break-word" class="">
                            <b>Record Value:</b>
                            <div *ngFor="let recordValue of listedFileContent.RecordValue">
                                {{ recordValue.Value }}
                            </div>
                        </div>
                        <div *ngIf="listedFileContent.RecordType == 'MX'" class="col-4">
                            <b>Priority:</b> {{ listedFileContent.Priority }}
                        </div>
                        <div class="col-4">
                            <b>TTL:</b> {{ listedFileContent.Ttl }}
                        </div>
                        <div class="col-4">
                            <b>Comments:</b> {{ listedFileContent.Comments }}
                        </div>
                        <div *ngIf="listedFileContent.Status == 'Failure'" class="">
                            <div class="text-danger">
                                {{ listedFileContent.ErrorMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngSwitchCase="productName === 'Zscaler-Bypass' || productName === 'Add-Zscaler-Bypass' ? productName : 'error'">
        <div class="row">
            <div class="col-2">
                <b>Action:</b> {{ parameters.ACTION }}
            </div>
            <div class="col-3">
                <b>ByPass:</b> {{ parameters.BYPASSTYPE }}
            </div>
            <div class="col-4">
                <b>Url:</b> {{ parameters.URL }}
            </div>
            <div *ngIf="parameters.ACTION == 'Add'" class="col-3">
                <b>Validated with CISO:</b> {{booleanToStringConverterImport(parameters.VALIDATEDCISO, 'No', 'Yes')}}
            </div>

        </div>
        <div class="row">
            <div *ngIf="parameters.ACTION == 'Add'" class="col-2">
                <b>Bypass Test:</b> {{booleanToStringConverterImport(parameters.TEST, 'No', 'Yes')}}
            </div>
            <div *ngIf="parameters.ACTION == 'Add'" class="col-3">
                <b>Period:</b> {{ parameters.PERIOD }}
            </div>
            <div *ngIf="parameters.ACTION == 'Add'" class="col-4">
                <b>Description:</b> {{ parameters.DESCRIPTION }}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Delete-Zscaler-Bypass'">
        <div class="row">
            <div class="col-3">
                <b>ByPass:</b> {{ parameters.BYPASSTYPE }}
            </div>
            <div class="col-4">
                <b>Urls:</b> {{ parameters.URL }}
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngSwitchCase="productName === 'Zscaler-FireWall' || productName === 'Add-Zscaler-FireWall' || productName === 'Delete-Zscaler-FireWall' ? productName : 'error'">
        <div class="row">
            <div class="col-2">
                <b>Action:</b> {{ parameters.ACTION }}
            </div>
            <div class="col-3">
                <b>Destination type:</b> {{ parameters.DESTINATIONTYPE }}
            </div>
            <div class="col-3">
                <b>Protocol:</b> {{ parameters.PROTOCOL }}
            </div>
            <div class="col-4">
                <b>Destination:</b> {{ parameters.DESTINATION }}
            </div>
        </div>
        <div class="row" *ngIf="parameters.ACTION == 'Add'">
            <div class="col-2">
                <b>Validated with CISO:</b> {{booleanToStringConverterImport(parameters.VALIDATEDCISO, 'No', 'Yes')}}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
            <div class="col-7">
                <b>Description:</b> {{ parameters.DESCRIPTION }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Zscaler-Tunneling'">
        <div class="row">
            <div class="col-3">
                <b>Action:</b> {{ parameters.ACTION }}
            </div>
            <div class="col-3">
                <b>Destination:</b> {{ parameters.DESTINATION }}
            </div>
            <div *ngIf="parameters.ACTION == 'Add'" class="col-4">
                <b>Description:</b> {{ parameters.DESCRIPTION }}
            </div>
        </div>
        <div class="row">
            <div *ngIf="parameters.ACTION == 'Add'" class="col-3">
                <b>Validated with CISO:</b> {{booleanToStringConverterImport(parameters.VALIDATEDCISO, 'No', 'Yes')}}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Add-Zscaler-Tunneling'">
        <div class="row">
            <div class="col-5">
                <b>Destination:</b> {{ parameters.DESTINATION }}
            </div>
            <div class="col-7">
                <b>Description:</b> {{ parameters.DESCRIPTION }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <b>Validated with CISO:</b> {{booleanToStringConverterImport(parameters.VALIDATEDCISO, 'No', 'Yes')}}
            </div>
            <div class="col-4">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Delete-Zscaler-Tunneling'">
        <div class="row">
            <div class="col-3">
                <b>Destination:</b> {{ parameters.DESTINATION }}
            </div>
        </div>
    </ng-container>

    <!-- Enable-Teams-CAPPhone

    GroupID
    Name
    Site
    Location
    Manager
    Brand
    IPPhonePolicy
    TicketID
    -->
    <ng-container *ngSwitchCase="'Enable-Teams-CAPPhone'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div *ngIf="parameters.COUNTRYCODE" class="col-4">
                <b>Country Code:</b> {{ parameters.COUNTRYCODE }}
            </div>
            <div class="col-4">
                <b>Name:</b> {{ parameters.NAME }}
            </div>
            <div class="col-4">
                <b>Site:</b> {{ parameters.SITE }}
            </div>
            <div class="col-4">
                <b>Location:</b> {{ parameters.LOCATION }}
            </div>
            <div class="col-4">
                <b>Manager:</b> {{ parameters.MANAGER }}
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ parameters.BRAND }}
            </div>
            <div class="col-4">
                <b>IP Phone Policy:</b> {{ parameters.IPPHONEPOLICY }}
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <!--generique-->
    <ng-container *ngSwitchCase="'Set-Outlook-Places'">
        <div class="row">
            <ng-container *ngFor="let item of parameters | keyvalue">
                <div *ngIf="item.key[0] != '_' && item.key != 'PRODUCT'" class="col-4">
                    <b>{{item.key}}:</b> {{item.value}}
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- Add-MW-User

    GroupID
    Skynote
    Subdelegation
    ManagedLocalIT
    LocalAdmin
    ReleaseRing
    AddApplications
    RemoveApplications
    TicketID
    -->
    <ng-container *ngSwitchCase="'Add-MW-User'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Skynote:</b> {{ booleanToStringConverterImport(parameters.SKYNOTE, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Subdelegation:</b> {{ booleanToStringConverterImport(parameters.SUBDELEGATION, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Local IT:</b> {{ parameters.MANAGEDLOCALIT }}
            </div>
            <div class="col-4">
                <b>Local Admin:</b> {{ booleanToStringConverterImport(parameters.LOCALADMIN, 'No', 'Yes') }}
            </div>
            <div class="col-4">
                <b>Release Ring:</b> {{ parameters.RELEASERING }}
            </div>
            <div class="col-4">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
            <div class="">
                <b>Add Applications:</b>
                <div class="" *ngIf="isParameterArray(parameters.ADDAPPLICATIONS)">
                    <span *ngFor="let app of parameters.ADDAPPLICATIONS">
                        {{ app }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.ADDAPPLICATIONS)">
                    <span>{{ parameters.ADDAPPLICATIONS }}</span>
                </div>
            </div>
            <div class="">
                <b>Remove Applications:</b>
                <div class="" *ngIf="isParameterArray(parameters.REMOVEAPPLICATIONS)">
                    <span *ngFor="let app of parameters.REMOVEAPPLICATIONS">
                        {{ app }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.REMOVEAPPLICATIONS)">
                    <span>{{ parameters.REMOVEAPPLICATIONS }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Remove-AzureADDevice

    GroupID
    Devices
    -->
    <ng-container *ngSwitchCase="'Remove-AzureADDevice'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="">
                <b>Devices:</b>
                <div class="" *ngIf="isParameterArray(parameters.DEVICES)">
                    <span *ngFor="let device of parameters.DEVICES">
                        {{ device }}<br />
                    </span>
                </div>
                <div class="" *ngIf="!isParameterArray(parameters.DEVICES)">
                    <span>{{ parameters.DEVICES }}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Get-BitlockerKey

    Device
    -->
    <ng-container *ngSwitchCase="productName === 'Get-BitlockerKey' 
                                || productName === 'Get-LocalAdminPassword' ? productName : 'error'">
        <div class="row">
            <div class="col-4">
                <b>Device serial number:</b> {{ parameters.DEVICE || parameters.SERIAL }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Delete-DistributionList'">
        <div class="row">
            <div class="col-5">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'UnDelete-DistributionList'">
        <div class="row">
            <div class="col-5">
                <b>List ID:</b> {{ parameters.LISTID }}
            </div>
            <div class="col-3">
                <b>TicketID:</b> {{ parameters.TICKETID }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Get-DistributionList-Info'">
        <div class="row">
            <div class="">
                <b>Email or display name:</b> {{ parameters.LISTID }}
            </div>
        </div>
    </ng-container>

    <!-- Set-UserRing

    GroupID
    ReleaseRing
    -->
    <ng-container *ngSwitchCase="'Set-UserRing'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Ring:</b> {{ parameters.RELEASERING }}
            </div>
        </div>
    </ng-container>

    <!-- Add-AllocID-Permission

    GroupID
    Role
    Profile
    Scope
    -->
    <ng-container *ngSwitchCase="'Add-AllocID-Permission'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Role:</b> {{ parameters.ROLE }}
            </div>
            <div class="col-4">
                <b>Profile:</b> {{ parameters.PROFILE }}
            </div>
            <div class="col-4">
                <b>Scope:</b> {{ parameters.SCOPE }}
            </div>
        </div>
    </ng-container>

    <!-- Create-GID

    GroupID
    BirthDate
    LegalName
    LegalFirstName
    BirthName
    PreferredName
    PreferredFirstName
    EmailAddress
    -->
    <ng-container *ngSwitchCase="'Create-GID'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Date of Birth:</b> {{ parameters.BIRTHDATE }}
            </div>
            <div class="col-4">
                <b>Legal Name:</b> {{ parameters.LEGALNAME }}
            </div>
            <div class="col-4">
                <b>Legal First Name:</b> {{ parameters.LEGALFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Birth Name:</b> {{ parameters.BIRTHNAME }}
            </div>
            <div class="col-4">
                <b>Preferred Name:</b> {{ parameters.PREFERREDNAME }}
            </div>
            <div class="col-4">
                <b>Preferred First Name:</b> {{ parameters.PREFERREDFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Email:</b> {{ parameters.EMAILADDRESS }}
            </div>
        </div>
    </ng-container>

    <!-- Activate-GID

    GroupID
    BirthDate
    LastName
    FirstName
    -->
    <ng-container *ngSwitchCase="'Activate-GID'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Date of Birth:</b> {{ parameters.BIRTHDATE }}
            </div>
            <div class="col-4">
                <b>Last Name:</b> {{ parameters.LASTNAME }}
            </div>
            <div class="col-4">
                <b>First Name:</b> {{ parameters.FIRSTNAME }}
            </div>
        </div>
    </ng-container>

    <!-- Update-AllocID-Data

    GroupID
    BirthDate
    LegalName
    LegalFirstName
    BirthName
    PreferredName
    PreferredFirstName
    EmailAddress
    DataToRemove
    -->
    <ng-container *ngSwitchCase="'Update-AllocID-Data'">
        <div class="row">
            <div class="col-4">
                <b>Group ID:</b> {{ parameters.GROUPID }}
            </div>
            <div class="col-4">
                <b>Date of Birth:</b> {{ parameters.BIRTHDATE }}
            </div>
            <div class="col-4">
                <b>Legal Name:</b> {{ parameters.LEGALNAME }}
            </div>
            <div class="col-4">
                <b>Legal First Name:</b> {{ parameters.LEGALFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Birth Name:</b> {{ parameters.BIRTHNAME }}
            </div>
            <div class="col-4">
                <b>Preferred Name:</b> {{ parameters.PREFERREDNAME }}
            </div>
            <div class="col-4">
                <b>Preferred First Name:</b> {{ parameters.PREFERREDFIRSTNAME }}
            </div>
            <div class="col-4">
                <b>Email:</b> {{ parameters.EMAILADDRESS }}
            </div>
            <div class="col-4">
                <b>Erase AllocID content:</b>
                <div class="">
                    <span *ngFor="let field of parameters.DATATOREMOVE">{{ field }}<br /></span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Set-Office365RetentionPolicy

    SiteUrl
    RetentionPeriod
    -->
    <ng-container *ngSwitchCase="'Set-Office365RetentionPolicy'">
        <div class="row">
            <div class="col-4">
                <b>Site Url:</b> {{ parameters.SITEURL }}
            </div>
            <div class="col-4" *ngIf="parameters.RETENTIONPERIOD">
                <b>Retention Period:</b> {{ parameters.RETENTIONPERIOD }} years
            </div>
        </div>
    </ng-container>
</div>