import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
    selector: 'BodyContent',
    templateUrl: './deleteZScalerFirewall.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class DeleteZScalerFirewallComponent {
    
    public selectedLocalIT: String = '';
    filteredUrls: Observable<string[]>;
    isWait = false;
    destinationTypes = [];
    protocols = [];
    dbCategorizedUrls = [];
    selectedUrls = [];
    deleteZscalerFirewallRequestForm = new FormGroup({
        DestinationType: new FormControl(''),
        Protocol: new FormControl(''),
        Destinations: new FormControl([]),
        TicketID: new FormControl(''),
        urlFilter: new FormControl(''),
    });

    constructor(
        private userService: UserService,
        private businessRulesService: BusinessRulesService,
        private zscalerApiService: ZscalerApiService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router

    ) {
    }

    @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;

    async ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
            });
        this.destinationTypes = await this.businessRulesService.getSpecificListByKey('DLTYPES', 'DestinationTypes').toPromise();
        this.protocols = await this.businessRulesService.getSpecificListByKey('DLTYPES', 'Protocol').toPromise();
    }

    ngOnDestroy() {
        
    }

    async typeChanged() {
        try {
            this.isWait = true;
            let dType = this.deleteZscalerFirewallRequestForm.value.DestinationType.toString();
            let protocol = this.deleteZscalerFirewallRequestForm.value.Protocol.toString();
            if (dType && protocol) {
                let res: any = await this.zscalerApiService.getZscalerDestinationGroupList(dType, protocol).toPromise();
                this.dbCategorizedUrls = res.addresses;
                this.dbCategorizedUrls.sort(function (a, b) {
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return -1;
                    }
                    return 0;
                });
            }

            this.filteredUrls = this.deleteZscalerFirewallRequestForm.get("urlFilter").valueChanges.pipe(
                startWith(null),
                map((urlstr: string | null) => (urlstr ? this._filter(urlstr) : this.dbCategorizedUrls.slice())),
            );
        } catch (error) {
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        } finally {
            this.isWait = false;
        }
    }

    async sendRequest() {
        try {
            this.isWait = true;
            let data = {};
            data = this.deleteZscalerFirewallRequestForm.value;
            data["localIT"] = this.selectedLocalIT;
            await this.zscalerApiService.deleteZscalerFirewall(data).toPromise();
            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold'],
            });
            await successSnackBar.afterDismissed().toPromise();
            this.router.navigate(['/history']);
        } catch (error) {
            console.log(error);
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        } finally {
            this.isWait = false;
        }
    }

    //#region url chips list
    addurl(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.selectedUrls.push(value);
            this.deleteZscalerFirewallRequestForm.patchValue({ Destinations: this.selectedUrls });
        }
        this.deleteZscalerFirewallRequestForm.patchValue({ urlFilter: "" });
    }

    removeurl(url: string): void {
        const index = this.selectedUrls.indexOf(url);
        if (index >= 0) {
            this.selectedUrls.splice(index, 1);
        }
    }

    selectedUrl(event: MatAutocompleteSelectedEvent): void {
        this.selectedUrls.push(event.option.viewValue);
        this.deleteZscalerFirewallRequestForm.patchValue({ Destinations: this.selectedUrls });
        this.deleteZscalerFirewallRequestForm.patchValue({ urlFilter: "" });
        this.chipInput.nativeElement.value = '';
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.dbCategorizedUrls.filter(fruit => fruit.toLowerCase().includes(filterValue));
    }

    //#endregion
} 