import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { OktaApiService } from '../../../Services/oktaApi.service';
import { UnlockOktaB2BUserFileContent } from '../../../Models/FileContents';
import { RequestContainerService } from '../../../Services/requestContainer.service';


import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './unsuspendOktaUser.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class UnsuspendOktaUserComponent{
    public fileContentsList = new UnlockOktaB2BUserFileContent();
    public fileContent = new UnlockOktaB2BUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public isUserInOkta:boolean = false;
    public howToUseInfo:boolean = true;

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    
    constructor(private userService: UserService, 
        private requestContainerService: RequestContainerService,
        private oktaApiService: OktaApiService, 
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent  = new UnlockOktaB2BUserFileContent();
                this.isUserInOkta = false;
                this.localITNotFound = '';
            });
    }

    ngOnDestroy(){        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new UnlockOktaB2BUserFileContent();
    }

    clearFileContentForms(){
        this.fileContent = new UnlockOktaB2BUserFileContent();
        this.isUserInOkta = false;
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        var tempObject = new UnlockOktaB2BUserFileContent(this.fileContent);
        this.fileContentsList =  tempObject;
        this.clearFileContentForms();
    }
    
    differentiator1(index){
        //this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false) {
            this.addFileContentToList();
        }

        var data = {};
        data["requests"] = [this.fileContentsList];
        data["localIT"] = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/unsuspendSquidOktaUser')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    getUserInOkta(){
        let gID = this.fileContent.GroupID.toString() ;
        if(gID){
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        if(response.status?.toUpperCase() === "SUSPENDED"){
                            this.isUserInOkta = true;
                            this.fileContent.OktaUserID = response.id
                            this.localITNotFound = `User ${response.displayName} is suspended in Okta`
                        }
                        else{
                            this.localITNotFound  = `The user ${gID} is not suspended. Current status: ${response.status}`;
                        }
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.localITNotFound  = `${gID} not found in Okta!`;
                        }else{
                            this.localITNotFound = `Error when trying to search ${gID} in Okta!`;
                        }
                    }
                );
        }
    }

    public setBackEmptyForm(){
        this.fileContent.OktaUserID = '';
        this.isUserInOkta = false;
        this.localITNotFound = '';
    }
} 