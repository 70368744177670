import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { CreateDistributionListFileContent, CreateDistributionListRequest } from '../../../Models/FileContents';
import { UserService } from '../../../Services/user.service';

import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { booleanToStringConverter } from '../../../Helpers/utils';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { UserInfo } from '../../../Models/UserInfo';



@Component({
    selector: 'BodyContent',
    templateUrl: './createDistributionList.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateDistributionListComponent{
    
    
    
    

    public assignableDLTypes:Array<string> = new Array<string>();
    public requestContainer:any = {};
    public fileContentsList = new Array<CreateDistributionListFileContent>();
    public fileContent = new CreateDistributionListFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public customerProfileImported:boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private customerProfilesService: CustomerProfilesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });

        this.businessRulesService.getSpecificListByKey('DLTYPES', 'AvailableDlTypes')
            .subscribe(response => {this.assignableDLTypes = response;});
    }

    ngOnDestroy(){
        
        
        
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateDistributionListFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new CreateDistributionListFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }
    
    addFileContentToList(){
        var tempObject = new CreateDistributionListFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }

        var data = new CreateDistributionListRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/createDistributionList')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    importCustomerProfile(){
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.Owner ? this.fileContent.Owner : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if(response){
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if(response.PrimarySMTPdomain){
                            this.fileContent.PrimarySmtpDomain = response.PrimarySMTPdomain;
                            document.getElementById("createDLPrimarySmtpDomainInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(response.Discriminant){
                            this.fileContent.Discriminant = response.Discriminant;
                            document.getElementById("createDLDiscriminantInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(missingVal){
                            this.importDefaultCustomerProfile();
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile(){
        let groupIDValueCopy = new String(this.fileContent.Owner ? this.fileContent.Owner : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if(response){
                        if(!this.fileContent.PrimarySmtpDomain){
                            if(response.PrimarySMTPdomain){
                                this.fileContent.PrimarySmtpDomain = response.PrimarySMTPdomain;
                                document.getElementById("createDLPrimarySmtpDomainInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if(!this.fileContent.Discriminant){
                            if(response.Discriminant){
                                this.fileContent.Discriminant = response.Discriminant;
                                document.getElementById("createDLDiscriminantInput").style.border = "2px solid #fbc02d";
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;                        
                    }                    
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm(){
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        document.getElementById("createDLPrimarySmtpDomainInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createDLDiscriminantInput").style.border = "1px solid rgba(0,0,0,.15)";

        if(this.customerProfileImported){
            var groupId = this.fileContent.Owner;
            if(!inputValidationHelper.checkGroupIdFormat(groupId)){
                this.fileContent.PrimarySmtpDomain = "";
                this.fileContent.Discriminant = "";
                this.customerProfileImported = false;
            }
        }
    }
}