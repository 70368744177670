<h4>Enable Teams Phone System:</h4>

<form #requestForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputField>            
        </div>
        <div class="col-4">
            <label class="required" for="phoneNumberInput">Phone Number:</label>
            <input type="text" id="phoneNumberInput" name="phoneNumberInput" class="form-control"
                    [(ngModel)]="fileContent.UserTel" #phoneNumberInput="ngModel"
                    required pattern="^\+?[1-9]\d{7,14}$" maxlength="15">
                    
            <div *ngIf="phoneNumberInput.invalid && (phoneNumberInput.dirty || phoneNumberInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="phoneNumberInput.errors.required">
                    Phone Number is required.
                </div>
                <div *ngIf="phoneNumberInput.errors.pattern">
                    Phone number should have the international format (starting with the countrycode +xx)
                </div>
            </div>      
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="vRPolicyDropdownList">Voice Routing Policy:</label>
            <select id="vRPolicyDropdownList" class="form-control"
                [(ngModel)]="fileContent.VRPolicy" name="vRPolicyDropdownList">
                <option *ngFor="let policy of vRPolicies | orderby: '' : false : true" [value]="policy">{{policy}}</option>
            </select>
        </div>  
        <div class="col-4">
            <label class="required" for="CallingPoliciesDropdownList">Calling Policy:</label>
            <select id="CallingPoliciesDropdownList" class="form-control"
                [(ngModel)]="fileContent.CallingPolicy" name="CallingPoliciesDropdownList">
                <option *ngFor="let CallingPolicy of CallingPolicies" [value]="CallingPolicy">{{CallingPolicy}}</option>
            </select>
        </div>
    </div>
    
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); requestForm.form.markAsPristine(); requestForm.form.markAsUntouched();" [disabled]="requestForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(requestForm.invalid); requestForm.reset();" [disabled]="(requestForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span> -</b>
            <b style="color: #0275d8">{{ listedFileContent.UserTel }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-2">
                <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            </div>
            <div class="col-3">
                <b>Phone Number:</b> {{ listedFileContent.UserTel }}
            </div>
            <div class="col-3">
                <b>Voice Routing Policy:</b> {{ listedFileContent.VRPolicy }}
            </div>
            <div class="col-3">
                <b>Calling Policy:</b> {{ listedFileContent.CallingPolicy }}
            </div>
        </div>
    </div>
</div>