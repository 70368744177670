<br />
<div class="row">
    <div class="col-12">
        <h2>License Cleanup:</h2>
        <h5 class="text-primary">Delete inactive Okta users in O365 and free attached licenses.</h5>      
    </div>
</div>
<br />
<div class="row">
    <div class="col-12">
        <h2>Select organizations you want to clean:</h2>
    </div>
</div>
<br />
<div class="row">
    <div class="col-10">        
        <mat-form-field appearance="fill">
            <mat-label>{{organizationsSelectionLabel}}</mat-label>
            <mat-select [formControl]="organizationsSelected" multiple>
                <mat-select-trigger>
                    {{organizationsSelected?.value?.join() || ''}}
                </mat-select-trigger>
                <mat-option *ngFor="let organization of userAuthorizedOrganizations" [value]="organization.OrgID">{{organization.OrgID}} - {{organization.OrgName}} ({{organization.UsersToDeleteCount}} Users to delete)</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2">
        <button type="button" class="mr-1 btn btn-primary form-control" style="margin-top:7px" (click)="refreshUsersTableData(); deleteO365ParametersForm.form.markAsPristine(); deleteO365ParametersForm.form.markAsUntouched();"  [disabled]="organizationsSelected ? !(organizationsSelected.value?.length > 0) : true">Get orgs users</button>
    </div>
</div>

<br />

<!-- SELECTED ORGS USERS TO DELETE TABLE -->

<div class="row" *ngIf="usersTableData && usersTableData.length > 0">
    <div class="col-12">
        <h2>Select the users you want to delete:</h2>
        <div class="csvtable" style="width: 100%;">
            <mat-progress-bar *ngIf="usersTableRefreshInProgress" mode="indeterminate"></mat-progress-bar>
        </div>
        <br />
        <table class="table dataTable csvtable" ngClass="{{usersTableConfig.className || ''}}" role="grid" style="width: 100%;">
            <thead>
                <tr role="row"> 
                    <th style="width:75px;">
                        Select
                    </th>
                    <th *ngFor="let usersTableColumn of usersTableColumns" (click)="applySorting(usersTableColumn.name)" ngClass="{{usersTableColumn.className || ''}}">
                        {{usersTableColumn.title}}
                        <i *ngIf="usersTableConfig && usersTableColumn.sort && usersTableColumn.sort === 'asc'"
                            class="pull-right fa material-icons md-24">expand_less</i>
                        <i *ngIf="usersTableConfig && usersTableColumn.sort && usersTableColumn.sort === 'desc'"
                            class="pull-right fa material-icons md-24">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="text-align: center;vertical-align: middle;">
                        <mat-checkbox   (change)="$event ? selectAllUsers() : null"
                                        [checked]="isAllUsersSelected()"
                                        [indeterminate]="selectedUsers.length > 0 && !isAllUsersSelected()"
                                        color="primary">
                        </mat-checkbox>
                    </td>  
                    <td *ngFor="let usersTableColumn of usersTableColumns">
                        <input *ngIf="usersTableColumn.filtering" placeholder="{{usersTableColumn.filtering.placeholder}}"
                            [ngTableFiltering]="usersTableColumn.filtering" class="form-control" style="width: 100%;" (tableChanged)="onChangeUsersTable(usersTableConfig)" />
                    </td>
                    <td></td>
                </tr>
                <tr *ngFor="let row of usersTableRows">
                    <td style="text-align: center;vertical-align: middle;">
                        <mat-checkbox   (click)="$event.stopPropagation()"
                                        (change)="$event ? selectUser(row) : null"
                                        [checked]="isUserSelected(row)"
                                        color="primary">
                        </mat-checkbox>
                    </td>
                    <td class="truncatetablecell" *ngFor="let usersTableColumn of usersTableColumns">
                        {{row[usersTableColumn.name]}}
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="usersTableConfig.paging" class="pagination-sm" [(ngModel)]="usersTablePage" [totalItems]="usersTableLength"
            [itemsPerPage]="usersTableItemsPerPage" [maxSize]="usersTableMaxSize" [boundaryLinks]="true" [rotate]="false"
            (pageChanged)="onChangeUsersTable(usersTableConfig, $event)" (numPages)="usersTableNumPages = $event">
        </pagination>
        <pre *ngIf="usersTableConfig.paging" class="card card-block card-header justify-content-between" style="flex-direction: initial;"><div>Page: {{usersTablePage}} / {{usersTableNumPages}}</div> <div>Total users: {{usersTableData.length}}  Filtered: {{usersTableLength}} Selected: {{selectedUsers.length}}</div></pre>
    </div>    
</div>

<br *ngIf="usersTableData && usersTableData.length > 0" />

<div class="row" *ngIf="usersTableData && usersTableData.length > 0">
    
    <div class="offset-5 col-3">
        <button type="button" id="deleteUsersButton" name="deleteUsersButton" class="mr-1 btn btn-primary form-control" (click)="displayDeleteO365Form = true;"  [disabled]="!selectedUsers || (selectedUsers && selectedUsers.length < 1)">Fill delete requests</button>
    </div>
    <div class="col-1" style="text-align: center">
        <h3>OR</h3>
    </div>        
    <div class="col-3">
        <button type="button" id="exportUsersLightButton" name="exportUsersLightButton" class="mr-1 btn btn-primary form-control" (click)="exportSelectedUsers(false)" [disabled]="!selectedUsers || (selectedUsers && selectedUsers.length < 1)">Export selected users ({{selectedUsers.length}})</button>
    </div>
</div>

<br *ngIf="selectedUsers && selectedUsers.length > 0" />

<!-- DELETE O365 PARAMETERS FORM -->

<form #deleteO365ParametersForm="ngForm">
    <div *ngIf="selectedUsers && selectedUsers.length > 0 && displayDeleteO365Form">
        <div class="row">
            <div class="col-6">
                <h2>Fill DeleteO365Object parameters:</h2>                
            </div>
            <div class="col-6">
                <app-product-help-link productName="DELETE-O365OBJECT"></app-product-help-link>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-3">
                <label class="required" for="newOwnerInput">New Owner:</label>
                <input type="text" id="newOwnerInput" class="form-control"
                        [(ngModel)]="usersNewOwner" name="newOwnerInput" #newOwnerInput="ngModel"
                        required [pattern]="groupIDPattern">

                <div *ngIf="newOwnerInput.invalid && (newOwnerInput.dirty || newOwnerInput.touched)"
                        class="alert alert-danger">
                
                    <div *ngIf="newOwnerInput.errors.required">
                        New Owner is required.
                    </div>
                    <div *ngIf="newOwnerInput.errors.pattern">
                        New Owner format is invalid (must be a valid GID format).
                    </div>               
                </div>
            </div>
            <div class="col-3">
                <label for="preventMailboxReconnectRadioButton">Prevent Mailbox Reconnect:</label>
                <div id="preventMailboxReconnectRadioButton">
                    <div style="display: inline-block;margin-right:10px;">
                        <label><input type="radio" id="preventMailboxReconnectRadioButtonOpt1" name="preventMailboxReconnectRadioButton" class="form-control" [value]="true" [(ngModel)]="usersPreventMailboxReconnect" #preventMailboxReconnectRadioButtonOpt1="ngModel">Yes</label>
                    </div>
                    <div style="display: inline-block;">
                        <label><input type="radio" id="preventMailboxReconnectRadioButtonOpt2" name="preventMailboxReconnectRadioButton" class="form-control" [value]="false" [(ngModel)]="usersPreventMailboxReconnect" #preventMailboxReconnectRadioButtonOpt2="ngModel">No</label>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <label for="preventResubscribeDLRadioButton">Prevent Resubscribe DL:</label>
                <div id="preventResubscribeDLRadioButton">
                    <div style="display: inline-block;margin-right:10px">
                        <label><input type="radio" id="preventResubscribeDLRadioButtonOpt1" name="preventResubscribeDLRadioButton" class="form-control" [value]="true" [(ngModel)]="usersPreventResubscribeDL" #preventResubscribeDLRadioButtonOpt1="ngModel">Yes</label>
                    </div>
                    <div style="display: inline-block;">
                        <label><input type="radio" id="preventResubscribeDLRadioButtonOpt2" name="preventResubscribeDLRadioButton" class="form-control" [value]="false" [(ngModel)]="usersPreventResubscribeDL" #preventResubscribeDLRadioButtonOpt2="ngModel">No</label>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <label for="ticketIDInput">Ticket ID:</label>
                <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                        [(ngModel)]="usersTicketID" #ticketIDInput="ngModel">
            </div>
        </div>
        <br />
        <div class="row">
            <div class="offset-9 col-3">
                <button type="button" id="sendUsersRequestButton" name="sendUsersRequestButton" class="mr-1 btn btn-primary form-control" (click)="sendSelectedUsersDeleteRequest();refreshUsersTableData(); deleteO365ParametersForm.form.markAsPristine(); deleteO365ParametersForm.form.markAsUntouched();"  [disabled]="deleteO365ParametersForm.invalid">Delete selected users ({{selectedUsers.length}})</button>
            </div>
        </div>
    </div>
</form>

<div *ngIf="!usersTableData || (usersTableData && usersTableData.length < 1)" class="row">
    <div class="col-12">
        <h3 class="text-primary">OR</h3>
    </div>
</div>

<!-- IMPORT CSV -->
<br />
<div *ngIf="displayImportButton || (usersTableData && usersTableData.length < 1)" class="row">
    <div class="col-12">
        <h2>Import your LicenseCleanup CSV:</h2>
        <div class="row">
            <div class="col-2">
                <button type="button" class="btn btn-primary" (click)="importCsvInput.click()">Import csv</button>
                <input hidden type="file" multiple accept=".csv" #importCsvInput (change)="parseFromCSV($event)" />
            </div>       
        </div>
    </div>
</div>

<br />
<br />

<div class="row">
    <div class="col-2">
        <button *ngIf="fileContentsList.length > 0 || csvImportErrors.length > 0" type="button" class="btn btn-primary"
            (click)="clear()">Cancel</button>
    </div>
    <div class="col-2">
        <button
            *ngIf="(fileContentsList.length > 0 && !disableSendButton)"
            type="button" class="ml-1 btn btn-primary" (click)="sendImportedUsersDeleteRequest()">Send
            request</button>
    </div>
</div>

<div *ngIf="successWarnings.length > 0 || mergedWarning">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="successWarnings.length > 0">
    <span *ngFor="let message of successWarnings; let i = index" class="text-success font-weight-bold"
        style="font-size:1.5em;">
        {{ message }} <br />
    </span>
</div>

<div *ngIf="successWarnings.length > 0 || mergedWarning">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="(csvImportErrors && csvImportErrors.length > 0)">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="csvImportErrors && csvImportErrors.length > 0">
    <span class="text-danger font-weight-bold" style="font-size:1.5em;">
        REQUESTS IMPORTED WITH ERRORS
    </span>

    <div class="row" *ngFor="let fileContentInError of csvImportErrors; let i = index">
        <a class="col-10" (click)="handleCollapseBtn('fileContentInError' + i + 'expandBtn')" data-toggle="collapse"
            [attr.href]="'#fileContentInError' + i">
            <span style="font-size:1.5em;" class="text-danger">
                <b>{{ fileContentInError.fileName }} Line {{ fileContentInError.csvLine }} </b>
                <i [attr.id]="'fileContentInError' + i + 'expandBtn'" style="vertical-align: bottom"
                    class="material-icons md-36 pointer">chevron_right</i>
            </span>
        </a>
        <i id="{{'removeFileContentInErrorBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
            style="margin-left: 5px;vertical-align: bottom;"
            (click)="removeFileContentInErrorFromList(i)">remove_circle_outline</i>
        <div class=" collapse" [attr.id]="'fileContentInError' + i">
            <div *ngFor="let property of fileContentInError.properties; let i = index" class="row">
                <div class="col-10">
                    <span
                        [class]="fileContentInError.error.indexOf(property.Name.toLowerCase()) !== -1 ? 'text-danger' : ''"><b>{{property.Name}}
                            :</b> {{ fileContentInError.row[property.Name.toLowerCase()] }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="(csvImportErrors && csvImportErrors.length > 0)">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.fileName }} Line {{ listedFileContent.csvLine }} </b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div *ngFor="let property of listedFileContent.properties; let i = index" class="row">
            <div class="col-10">
                <span><b>{{property.Name}} :</b> {{ listedFileContent.row[property.Name.toLowerCase()] }}</span>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<mat-divider></mat-divider>
<br />
<br />

<!-- DELETE O365 OBJECT HISTORY TABLE -->

<h2>Delete O365Object Requests History ({{ length }})</h2>
<div class="row">
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [matDatepicker]="fromDatePicker"
            placeholder="From" [value]="fromDate" (dateChange)="fromDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
    </div>
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [matDatepicker]="toDatePicker"
            placeholder="To" [value]="toDate" (dateChange)="toDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
    </div>
    <div>
        <button id='ClearFilters' class="btn btn-cancel" (click)="clearFilters()" [disabled]="clearFiltersDisabled"
            style="display:inline;margin-right:20px;margin-left: 20px;">Clear filters</button>
        <button id='refreshDataButton' class="btn btn-primary" (click)="refreshData()"
            [disabled]="refreshButtonDisabled" style="display:inline;">Refresh</button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="csvtable" style="width: 100%;">
            <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
        </div>
        <br />
        <!-- <ng-table _ngcontent-c1 [ngClass]="config.className" [rows]="rows" [columns]="columns"></ng-table> -->
        <table class="table dataTable csvtable" ngClass="{{config.className || ''}}" role="grid" style="width: 100%;">
            <thead>
                <tr role="row">
                    <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
                        (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                        {{column.title}}
                        <i *ngIf="config && column.sort && column.sort === 'asc'"
                            class="pull-right fa material-icons md-24">expand_less</i>
                        <i *ngIf="config && column.sort && column.sort === 'desc'"
                            class="pull-right fa material-icons md-24">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngFor="let column of columns">
                        <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                            [ngTableFiltering]="column.filtering" class="form-control" style="width: 100%;" />
                    </td>
                </tr>
                <tr *ngFor="let row of rows"  (click)="onRowClick(row)" style="cursor: pointer;">
                    <td *ngFor="let column of columns"
                        class="truncatetablecell"
                        [ngStyle]="{'color':column.name === 'Status' ? getStatusColor(row[column.name]) : '', 'font-weight':column.name === 'Status' ? 'bold': ''}">
                        {{row[column.name]}}
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length"
            [itemsPerPage]="itemsPerPage" [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false"
            (pageChanged)="changePage($event)" (numPages)="numPages = $event">
        </pagination>
        <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
    </div>
</div>
