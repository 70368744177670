import { Component, Input } from '@angular/core';
import { GetDeletedUserInfoDetails } from '../../../../Models/History/GetDeletedUserInfoDetails';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'GetDeletedUserInfoHistoryDetails',
    templateUrl: './getDeletedUserInfoHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetDeletedUserInfoHistoryDetailsComponent{
    @Input() getDeletedUserInfoDetails: GetDeletedUserInfoDetails;
    userInfoCollapsed:boolean = true;
    deleteInfoConfCollapsed:boolean = true;
    melindaInfoCollapsed:boolean = true;
    oktaInfoCollapsed:boolean = true;
    exchangeOnlineUserConfCollapsed:boolean = true;
    azureInfoCollapsed: boolean = true;

    getStatusColorImport(status:string){
        return getStatusColor(status);
    }

    showAll(){
        this.userInfoCollapsed = false;
        this.deleteInfoConfCollapsed = false;
        this.melindaInfoCollapsed = false;
        this.oktaInfoCollapsed = false;
        this.exchangeOnlineUserConfCollapsed = false;
        this.azureInfoCollapsed = false;
    }
    
    hideAll(){
        this.userInfoCollapsed = true;
        this.deleteInfoConfCollapsed = false;
        this.melindaInfoCollapsed = true;
        this.oktaInfoCollapsed = true;
        this.exchangeOnlineUserConfCollapsed = true;
        this.azureInfoCollapsed = true;
    }
}