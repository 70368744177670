<h4>Create new Resource mailbox:</h4>

<!-- ADD NEW MAILBOX FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.Manager)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.Manager)">Please fill a valid Manager Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.Manager)">Based on Manager Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-12" style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="RecourceTypeDropdownList">ResourceType:</label>
            <select id="RecourceTypeDropdownList" class="form-control"
                [(ngModel)]="fileContent.ResourceType" name="RecourceTypeDropdownList" (change)="changeMaxCharacters($event.target.value)">
                <option *ngFor="let ResourceType of resourceTypes" [value]="ResourceType">{{ResourceType}}</option>
            </select>
        </div>
        <div class="col-4">
            <label class="required" for="createMailboxSiteInput">Site:</label>
            <input type="text" id="createMailboxSiteInput" class="form-control" maxlength="10" minlength="2" (change)="calculateCharacters();" (keyup)="calculateCharacters();"
                [(ngModel)]="fileContent.Site" required name="createMailboxSiteInput" #createMailboxSiteInput="ngModel">
            <div *ngIf="createMailboxSiteInput.invalid && (createMailboxSiteInput.dirty || createMailboxSiteInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxSiteInput.errors.required">
                    Site is required.
                </div>
                <div *ngIf="createMailboxSiteInput.errors?.minlength">
                    Site must be between 2 and 10 characters.
                </div>
            </div>
            <span [class]="errorsInForm() ? 'text-danger' : 'text-primary'" style="white-space: nowrap">{{message}}</span>
        </div>
        <div class="col-4">
            <label class="required" for="createMailboxNameInput">Name:</label>
            <input type="text" id="createMailboxNameInput" class="form-control" minlength="3" maxlength="42" (change)="calculateCharacters();" (keyup)="calculateCharacters();" [pattern]="namePattern"
                [(ngModel)]="fileContent.Name" required name="createMailboxNameInput" #createMailboxNameInput="ngModel">
            <div *ngIf="createMailboxNameInput.invalid && (createMailboxNameInput.dirty || createMailboxNameInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxNameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="createMailboxNameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
                <div *ngIf="createMailboxNameInput.errors?.minlength">
                    Name must be between 3 and 42 characters.
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="createMailboxCountryCodeInput">Country Code:</label>
            <input type="text" id="createMailboxCountryCodeInput" class="form-control"
                [(ngModel)]="fileContent.CountryCode" required name="createMailboxCountryCodeInput" [maxlength]="2" #createMailboxCountryCodeInput="ngModel">
            <div *ngIf="createMailboxCountryCodeInput.invalid && (createMailboxCountryCodeInput.dirty || createMailboxCountryCodeInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxCountryCodeInput.errors.required">
                    Country Code is required.
                </div>
            </div>
        </div>
        <div class="col-4">
            <GroupIDInputField label="Manager Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.ManagerDisplayName=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.Manager"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="createMailboxManagerSendAsInputOpt1">Manager Send As:</label>
            <div id="createMailboxManagerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxManagerSendAsInputOpt1" name="createMailboxManagerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ManagerSendAs" #createMailboxManagerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxManagerSendAsInputOpt2" name="createMailboxManagerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ManagerSendAs" #createMailboxManagerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">            
            <label for="createMailboxMailAdressesInput0">Mail Addresses:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxMailAdressesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createMailboxMailAdressesInput' + i}}">
                    <i *ngIf="isFirst" id="createMailboxMailAdressesAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxMailAdressesRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">            
            <label for="createMailboxAdditionalManagerInput0">Additional Manager:</label>
            <div *ngFor="let manager of fileContent.AdditionalManager; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxAdditionalManagerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.AdditionalManager[i]" name="{{'createMailboxAdditionalManagerInput' + i}}"
                        [ngClass]="{'has-danger-forced':(createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched || createMailboxAdditionalManagerInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #createMailboxAdditionalManagerInput="ngModel">
                    <i *ngIf="isFirst" id="createMailboxAdditionalManagerInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.AdditionalManager)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxAdditionalManagerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.AdditionalManager, i)">remove_circle_outline</i>
                    <div *ngIf="createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="createMailboxAdditionalManagerInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxAdditionalManagerSendAsInputOpt1">Additional Manager Send As:</label>
            <div id="createMailboxAdditionalManagerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxAdditionalManagerSendAsInputOpt1" name="createMailboxAdditionalManagerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.AdditionalManagerSendAs" #createMailboxAdditionalManagerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxAdditionalManagerSendAsInputOpt2" name="createMailboxAdditionalManagerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.AdditionalManagerSendAs" #createMailboxAdditionalManagerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxLanguageDropDown">Language:</label>
            <select id="createMailboxLanguageDropDown" class="form-control"
                [(ngModel)]="fileContent.MailboxLanguage" name="createMailboxLanguageDropDown">
                <option *ngFor="let language of languages" [value]="language.code">{{language.name}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="createMailboxHiddenInputOpt1">Hidden:</label>
            <div id="createMailboxHiddenInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt1" name="createMailboxHiddenInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt2" name="createMailboxHiddenInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">            
            <label for="createMailboxResourceDelegatesInput0">Resource Delegates:</label>
            <div *ngFor="let delegate of fileContent.ResourceDelegates; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxResourceDelegatesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.ResourceDelegates[i]" name="{{'createMailboxResourceDelegatesInput' + i}}"
                        [ngClass]="{'has-danger-forced':(createMailboxResourceDelegatesInput.invalid && (createMailboxResourceDelegatesInput.dirty || createMailboxResourceDelegatesInput.touched || createMailboxResourceDelegatesInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #createMailboxResourceDelegatesInput="ngModel">
                    <i *ngIf="isFirst" id="createMailboxResourceDelegatesInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.ResourceDelegates)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxResourceDelegatesInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.ResourceDelegates, i)">remove_circle_outline</i>
                    <div *ngIf="createMailboxResourceDelegatesInput.invalid && (createMailboxResourceDelegatesInput.dirty || createMailboxResourceDelegatesInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="createMailboxResourceDelegatesInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxBrandInput">Brand:</label>
            <input type="text" id="createMailboxBrandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="createMailboxBrandInput">
        </div>
        <div class="col-4">
            <label for="sensitiveInputOpt1">Sensitive:</label>
            <div id="sensitiveInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="sensitiveInputOpt1" name="sensitiveInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Sensitive" #sensitiveInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="sensitiveInputOpt2" name="sensitiveInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Sensitive" #sensitiveInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxScheduleOnlyDuringWorkHoursInputOpt1">Schedule only during working hours:</label>
            <div id="createMailboxScheduleOnlyDuringWorkHoursInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxScheduleOnlyDuringWorkHoursInputOpt1" name="createMailboxScheduleOnlyDuringWorkHoursInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ScheduleOnlyDuringWorkHours" #createMailboxScheduleOnlyDuringWorkHoursInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxScheduleOnlyDuringWorkHoursInputOpt2" name="createMailboxScheduleOnlyDuringWorkHoursInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ScheduleOnlyDuringWorkHours" #createMailboxScheduleOnlyDuringWorkHoursInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="TimeZoneDropdownList">Time Zone:</label>
            <select id="TimeZoneDropdownList" class="form-control"
                [(ngModel)]="fileContent.TimeZone" name="TimeZoneDropdownList">
                <option *ngFor="let timeZone of timeZones" [value]="timeZone.StandardName">{{timeZone.DisplayName}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="WorkingHoursStartTimeDropdownList">Working hours start time:</label>
            <select id="WorkingHoursStartTimeDropdownList" class="form-control"
                [(ngModel)]="fileContent.WorkingHoursStartTime" name="WorkingHoursStartTimeDropdownList">
                <option *ngFor="let WorkingHour of workingHours | slice:0:workingHours.length-1" [value]="WorkingHour">{{WorkingHour}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="WorkingHoursEndTimeDropdownList">Working hours end time:</label>
            <select id="WorkingHoursEndTimeDropdownList" class="form-control"
                [(ngModel)]="fileContent.WorkingHoursEndTime" name="WorkingHoursEndTimeDropdownList">
                <option *ngFor="let WorkingHour of workingHours | slice:1:workingHours.length" [value]="WorkingHour">{{WorkingHour}}</option>
            </select>
        </div>
    </div>
    <div class="row form-group">
        <div *ngIf="fileContent.ResourceType != 'EquipmentMailbox'" class="col-4">
            <label for="createMailboxCapacityInput">Capacity:</label>
            <input type="number" id="createMailboxCapacityInput" class="form-control"
                [(ngModel)]="fileContent.Capacity" name="createMailboxCapacityInput">
        </div>
        <div *ngIf="fileContent.ResourceType != 'EquipmentMailbox'" class="col-4">
            <label for="createMailboxRoomLocationInput">Room Location:</label>
            <input type="text" id="createMailboxRoomLocationInput" class="form-control"
                [(ngModel)]="fileContent.RoomLocation" name="createMailboxRoomLocationInput">
        </div>
        <div *ngIf="!fileContent.Sensitive" class="col-4">
            <label for="BookingProfilesDropdownList">Booking Profile:</label>
            <select id="BookingProfilesDropdownList" class="form-control"
                [(ngModel)]="fileContent.BookingProfile" name="BookingProfilesDropdownList">
                <option *ngFor="let profile of bookingProfiles" [value]="profile.option" title="{{profile.tooltip}}">{{profile.option}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOXES READY TO BE SENT  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            {{ listedFileContent.ResourceType.replace("Mailbox","") }}-{{ listedFileContent.CountryCode }}-{{ listedFileContent.Site }}-<b>{{ listedFileContent.Name }}</b> - 
            {{ listedFileContent.Manager }}  <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Resource Type:</b> {{ listedFileContent.ResourceType }}
                </div>
                <div class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div class="col-4">
                    <b>Country Code:</b> {{ listedFileContent.CountryCode }}
                </div>
                <div class="col-4">
                    <b>Site:</b> {{ listedFileContent.Site }}
                </div>
                <div class="col-4">
                    <b>Manager:</b> {{ listedFileContent.Manager }}  <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Manager SendAs:</b> {{ booleanToStringConverterImport(listedFileContent.ManagerSendAs, 'No', 'Yes') }}
                </div>                
                <div class="col-4">
                    <b>Language:</b> {{ listedFileContent.MailboxLanguage }}
                </div>
                <div class="col-4">
                    <b>Mail Addresses:</b>
                    <div *ngFor="let mailAddress of listedFileContent.MailAddresses">
                        {{ mailAddress }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Manager(s):</b>
                    <div *ngFor="let manager of listedFileContent.AdditionalManager">
                        {{ manager }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Additional Manager Send As:</b> {{ booleanToStringConverterImport(listedFileContent.AdditionalManagerSendAs, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Hidden:</b> {{ booleanToStringConverterImport(listedFileContent.Hidden, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Resource Delegate(s):</b>
                    <div *ngFor="let delegate of listedFileContent.ResourceDelegates">
                        {{ delegate }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>
                <div class="col-4">
                    <b>Sensitive:</b> {{ booleanToStringConverterImport(listedFileContent.Sensitive, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Schedule only during working hours:</b> {{ booleanToStringConverterImport(listedFileContent.ScheduleOnlyDuringWorkHours, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Time Zone:</b> {{ listedFileContent.TimeZone }}
                </div>
                <div class="col-4">
                    <b>Working Hours Start Time:</b> {{ listedFileContent.WorkingHoursStartTime }}
                </div>
                <div class="col-4">
                    <b>Working Hours End Time:</b> {{ listedFileContent.WorkingHoursEndTime }}
                </div>
                <div *ngIf="fileContent.ResourceType != 'EquipmentMailbox'" class="col-4">
                    <b>Capacity:</b> {{ listedFileContent.Capacity }}
                </div>
                <div *ngIf="fileContent.ResourceType != 'EquipmentMailbox'" class="col-4">
                    <b>Room Location:</b> {{ listedFileContent.RoomLocation }}
                </div>
                <div *ngIf="listedFileContent.BookingProfile && !listedFileContent.Sensitive" class="col-4">
                    <b>Booking Profile:</b> {{ listedFileContent.BookingProfile }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                        
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>