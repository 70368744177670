import { Component, NgModule, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { LocalITsService } from '../../../Services/localITs.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';

import { CreateOktaB2BCloudAccountFileContent, CreateOktaB2BCloudAccountRequest } from '../../../Models/FileContents';

import { booleanToStringConverter } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';

@Component({
    selector: 'BodyContent',
    templateUrl: './createOktaB2BCloudAccount.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateOktaB2BCloudAccountComponent {
    public fileContentsList = new Array<CreateOktaB2BCloudAccountFileContent>();
    public fileContent = new CreateOktaB2BCloudAccountFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public OrgIDs = new Array();
    public companies: Array<any> = new Array<any>();
    public maxDate: string = '1 year: ' + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0]
    myControl = new UntypedFormControl();
    filteredOptions: Observable<string[]>;

    public countryCodePattern: string = inputValidationHelper.countryCodeRegEx;
    public mailPattern: string = inputValidationHelper.mailFormatRegEx;
    public namePattern: string = inputValidationHelper.nameRegEx;
    public requestParameters;
    public sponsor = {Company: '', OrgID: ''};

    constructor(private requestContainerService: RequestContainerService, private webAdministrationService: WebAdministrationService, private userService: UserService, private localITsService: LocalITsService, private oktaApiService: OktaApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest, private cdr: ChangeDetectorRef) {
        this.clearFileContentForms();
        this.myControl.setValidators(this.forbiddenNamesValidator());
    }

    forbiddenNamesValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (typeof control.value === "string") {
                if (control.value) {
                    if (!(this.companies.some(item => item == control.value))) {
                        return { 'forbiddenNames': { value: control.value } };
                    }
                    else {
                        this.fileContent.Company = control.value;
                    }
                }
            }
            return null;
        };
    }

    private _filter(value: any): string[] {
        if (value) {
            if (typeof value === "string") {
                const filterValue = value.toLowerCase();
                return this.companies.filter(option => option.toLowerCase().includes(filterValue));
            }
        }
        else {
            return this.companies;
        }
    }

    private _filterExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.companies.filter(option => option.toLowerCase() == filterValue).length > 0) {
                return this.companies.filter(option => option.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private _filterOrgIdExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue).length > 0) {
                return this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);        
        this.webAdministrationService.getEntities({ Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
            this.OrgIDs = entity?.body;
            this.OrgIDs = this.OrgIDs.filter(item => item["OrgID"].match(/ORG\d{4}/i));
        },
        (error) =>  {
            this.OrgIDs = [];
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        });
        this.webAdministrationService.getCompanies({ Status: "Valid", itemPerPage: 5000 })
            .subscribe(response => {
                this.companies = response.body.map(item => item.Name).sort();
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value))
                    );
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        this.requestParameters = new CreateOktaB2BCloudAccountFileContent(cb[0]);
                        let doc = document.getElementById("oktaUsernameInput") as HTMLInputElement;
                        if (doc) {
                            doc.value = cb[0].OktaUsername;
                            doc.dispatchEvent(new Event('input'));
                            let button = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                            button[0].dispatchEvent(new Event('click'));
                        }
                    }
                });
            });
    }

    clickSponsor() {
        this.cdr.detectChanges();
        if (this.fileContent.GroupIDDisplayNameError && this.requestParameters && this.requestParameters.SponsorGroupID) {
            let doc = document.getElementById("groupIDInputOktaSponsor") as HTMLInputElement;
            if (doc) {
                let button = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                button[1].dispatchEvent(new Event('click'));
            }
        }
    }

    checkOktaB2BUsername() {
        let OktaUsername = this.fileContent.OktaUsername.toString();
        this.fileContent.GroupIDDisplayName = '';
        this.fileContent.GroupIDDisplayNameError = '';
        if (OktaUsername) {
            this.oktaApiService.getOktaB2BUserByMail(OktaUsername)
                .subscribe({
                    next: (response) => {
                        if (response) {
                            if (response.displayName) {
                                this.fileContent.GroupIDDisplayName = response.displayName;
                            }
                            else {
                                this.fileContent.GroupIDDisplayName = response.lastName + ' ' + response.firstName;
                            }
                            this.fileContent.GroupIDDisplayName = `${this.fileContent.GroupIDDisplayName}`
                        }
                        else {
                            this.fileContent.GroupIDDisplayNameError  = `${OktaUsername} is available!`;
                            this.clickSponsor();
                        }
                    },
                    error: (error) => {
                        this.fileContent.GroupIDDisplayNameError = `Error when trying to search ${OktaUsername} in Okta!`;
                    }
        });
        }
    }

    updateCompany(option: MatOption) {
        this.fileContent.Company = option.value;
    }

    fillFormBasedOnSponsorGroupID(sponsorUserProfile) {
        if (this.requestParameters) {
            this.requestParameters = null;
            if (this.fileContent.Company && Array.isArray(this._filterExactMatch(this.fileContent.Company))) {
                this.myControl.setValue(this._filterExactMatch(this.fileContent.Company)[0]);
            }
            if (this.fileContent.OrgID && Array.isArray(this._filterOrgIdExactMatch(this.fileContent.OrgID))) {
                this.fileContent.OrgID = (this._filterOrgIdExactMatch(this.fileContent.OrgID)[0] as any).OrgID;
            }
        }
        else if (sponsorUserProfile) {
            if (sponsorUserProfile.organization && Array.isArray(this._filterExactMatch(sponsorUserProfile.organization))) {
                this.myControl.setValue(this._filterExactMatch(sponsorUserProfile.organization)[0]);
                this.fileContent.Company = this.sponsor.Company = sponsorUserProfile.organization;
                this.fileContent.CompanyImportedFromSponsor = true;
            } else {
                this.fileContent.Company = '';
                this.myControl.reset('');
            }
            if (sponsorUserProfile.orgid && Array.isArray(this._filterOrgIdExactMatch(sponsorUserProfile.orgid))) {
                this.fileContent.OrgID = this.sponsor.OrgID = sponsorUserProfile.orgid;
            } else {
                this.fileContent.OrgID = '';
            }
        }
        //To fix ExpressionChangedAfterItHasBeenChecked error
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateOktaB2BCloudAccountFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new CreateOktaB2BCloudAccountFileContent();
        this.myControl.reset('');
        this.sponsor = {Company: '', OrgID: ''};
    }

    public setBackEmptyForm() {
        this.fileContent.GroupIDDisplayName = '';
        this.fileContent.GroupIDDisplayNameError = '';
        this.fileContent.SponsorDisplayName = '';
        this.fileContent.SponsorDisplayNameError = '';
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentList and clean the forms (Need reflexion for the Edit)
    addFileContentToList() {
        var tempObject = new CreateOktaB2BCloudAccountFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    errorsInForm() {
        return (this.fileContent.GroupIDDisplayName || !this.fileContent.SponsorDisplayName || this.myControl.hasError('forbiddenNames') || this.myControl.hasError('required'))
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.errorsInForm()) {
            this.addFileContentToList();
        }

        var data = new CreateOktaB2BCloudAccountRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/createOktaB2BCloudAccount')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}