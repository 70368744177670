import { Component, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { WebAdministrationService } from '../../../../Services/webAdministration.service';
import { LocalITsService } from '../../../../Services/localITs.service';
import { BusinessRulesService } from '../../../../Services/businessRules.service';
import { UserService } from '../../../../Services/user.service';

import { LocalItCard, LocalItCardMember, ManageLocalITCardRequest, LocalItCardFormatedForSending, LocalItCardProduct } from '../../../../Models/LocalItCard';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../../Models/UserInfo';

@Component({
    selector: 'manageLocalITCard',
    templateUrl: './ManageLocalITCard.component.html',
    providers: [BusinessRulesService],
    styleUrls: ['../../../../app.component.css']
})

export class ManageLocalITCardComponent {
    public fileContentsList = new Array<LocalItCardFormatedForSending>();
    public localItCard = new LocalItCard();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public localItInfoCollapsed: boolean = false;
    public scopeCollapsed: boolean = false;
    public membershipCollapsed: boolean = false;
    public productsCollapsed: boolean = false;
    public isCreation: boolean = true;
    public localITName: string = null;
    public selectAllProducts: boolean = false;

    constructor(private webAdministrationService: WebAdministrationService, private localITsService: LocalITsService, private userService: UserService, private BusinessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {

    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.route.queryParams.subscribe(params => {
            this.localITName = params['IT'] || null;

            if (this.localITName) {
                this.isCreation = false;
                this.localITsService.getLocalITByName(this.localITName).subscribe(response => {
                    this.localItCard = new LocalItCard(response);
                });
            } else {
                this.BusinessRulesService.getAllProducts().subscribe(response => {
                    Object.entries(response).forEach(element => {
                        if (element[0] != "id" && !element[0].match(/^_/) && element[0] != "Server" && element[0] != "LastOperationTime") {
                            if (element[1] != "PILOT") {
                                this.localItCard.Products.push(new LocalItCardProduct(element[0], element[1]));
                            }
                            this.localItCard.PilotProducts.push(new LocalItCardProduct(element[0], element[1]));
                        }
                    });
                });
            }
        });
    }

    ngOnDestroy() {
    }

    sendRequest() {
        if (this.localItCard.Pilot) {
            this.localItCard.Products = this.localItCard.PilotProducts;
        } else {
            this.localItCard.PilotProducts.forEach(pilotProduct => {
                var found = false;
                this.localItCard.Products.forEach(product => {
                    if (pilotProduct.Name == product.Name) {
                        found = true;
                    }
                });
                if (!found) {
                    this.localItCard.Products.push(pilotProduct);
                }
            });
            this.localItCard.Products.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
        }
        this.fileContentsList.push(new LocalItCardFormatedForSending(this.localItCard));

        var data = { ...this.fileContentsList[0] };
        data["localIT"] = this.selectedLocalIT;
        data["isCreation"] = this.isCreation;

        this.localITsService.manageLocalITCard(data)
            .subscribe(response => {
                let successSnackBar = this.snackBar.open("REQUEST VALIDATING LOCAL IT CARD SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    addAdditionalMember() {
        this.localItCard.Members.push(new LocalItCardMember());
    }

    removeAdditionalMember(index) {
        this.localItCard.Members.splice(index, 1);
    }

    removeOrResetMember(index) {
        if (this.localItCard.Members.length == 1) {
            this.localItCard.Members[index] = new LocalItCardMember();
        } else {
            this.localItCard.Members.splice(index, 1);
        }
    }

    isMemberEditDisabled(index) {
        if ((this.localItCard.Members[index].Name && this.localItCard.Members[index].Name != '') || (this.localItCard.Members[index].Email && this.localItCard.Members[index].Email != '')) {
            return true;
        } else {
            return false;
        }
    }

    changeProductStatus(index) {
        this.localItCard.Products[index].Enabled = !this.localItCard.Products[index].Enabled;
        if (this.localItCard.Pilot) {
            this.changePilotProductStatus(index);
        }
    }

    changePilotProductStatus(index) {
        this.localItCard.PilotProducts[index].Enabled = !this.localItCard.PilotProducts[index].Enabled;
    }

    changeManageAllStatus() {
        this.localItCard.ManageAll = !this.localItCard.ManageAll;
    }

    changeShowAllStatus() {
        this.localItCard.ShowAll = !this.localItCard.ShowAll;
    }

    changePilotStatus() {
        this.localItCard.Pilot = !this.localItCard.Pilot;
    }

    changeSelectAllproducts() {
        this.selectAllProducts = !this.selectAllProducts;
        if (this.selectAllProducts) {
            this.localItCard.Products.forEach(function (element) {
                element.Enabled = true;
            });
            this.localItCard.PilotProducts.forEach(function (element) {
                element.Enabled = true;
            });
        } else {
            this.localItCard.Products.forEach(function (element) {
                element.Enabled = false;
            });
            this.localItCard.PilotProducts.forEach(function (element) {
                element.Enabled = false;
            });
        }
    }
}