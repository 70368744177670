import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { MatOption } from '@angular/material/core';
import { UntypedFormControl, NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { LocalITsService } from '../../../Services/localITs.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';

import { UpdateOktaB2BCloudAccountFileContent, UpdateOktaB2BCloudAccountRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';

@Component({
    selector: 'BodyContent',
    templateUrl: './updateOktaB2BCloudAccount.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UpdateOktaB2BCloudAccountComponent {
    public requestContainer: any = {};
    public fileContentsList = new Array<UpdateOktaB2BCloudAccountFileContent>();
    public fileContent = new UpdateOktaB2BCloudAccountFileContent();
    public OrgIDs = new Array();
    public companies: Array<any> = new Array<any>();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public maxDate: string = '1 year: ' + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0];
    myControl = new UntypedFormControl();
    filteredOptions: Observable<string[]>;

    public countryCodePattern: string = inputValidationHelper.countryCodeRegEx;
    public mailPattern: string = inputValidationHelper.mailFormatRegEx;
    public namePattern: string = inputValidationHelper.nameRegEx;
    public requestParameters;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private localITsService: LocalITsService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private webAdministrationService: WebAdministrationService, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
        this.myControl.setValidators(this.forbiddenNamesValidator());
    }

    forbiddenNamesValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (typeof control.value === "string") {
                if (control.value) {
                    if (!(this.companies.some(item => item == control.value))) {
                        return { 'forbiddenNames': { value: control.value } };
                    }
                    else {
                        this.fileContent.Company = control.value;
                    }
                }
            }
            return null;
        };
    }

    private _filter(value: any): string[] {
        if (value) {
            if (typeof value === "string") {
                const filterValue = value.toLowerCase();
                return this.companies.filter(option => option.toLowerCase().includes(filterValue));
            }
        }
        else {
            return this.companies;
        }
    }

    private _filterExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.companies.filter(option => option.toLowerCase() == filterValue).length > 0) {
                return this.companies.filter(option => option.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private _filterOrgIdExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue).length > 0) {
                return this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    fillFormBasedOnGroupID(groupIDUserProfile) {
        if (this.requestParameters) {
            this.requestParameters = null;
            if (this.fileContent.Company && Array.isArray(this._filterExactMatch(this.fileContent.Company))) {
                this.myControl.setValue(this._filterExactMatch(this.fileContent.Company)[0]);
            }

            if (this.fileContent.OrgID && Array.isArray(this._filterOrgIdExactMatch(this.fileContent.OrgID))) {
                this.fileContent.OrgID = (this._filterOrgIdExactMatch(this.fileContent.OrgID)[0] as any).OrgID;
            }
        }
        else if (groupIDUserProfile) {
            this.fileContent.SponsorGroupID = groupIDUserProfile.C_sponsorGID ? groupIDUserProfile.C_sponsorGID : '';
            this.fileContent.LastName = groupIDUserProfile.lastName ? groupIDUserProfile.lastName : '';
            this.fileContent.FirstName = groupIDUserProfile.firstName ? groupIDUserProfile.firstName : '';
            this.fileContent.ExternalCompany = groupIDUserProfile.C_comment ? groupIDUserProfile.C_comment : groupIDUserProfile.C_externalCompany ? groupIDUserProfile.C_externalCompany : '';
            let company = groupIDUserProfile.organization ? groupIDUserProfile.organization : '';
            if (company && Array.isArray(this._filterExactMatch(company))) {
                this.myControl.setValue(this._filterExactMatch(company)[0]);
                this.fileContent.Company = company
            } else {
                this.myControl.reset('');
                this.fileContent.Company = '';
            }
            if (groupIDUserProfile.orgid && Array.isArray(this._filterOrgIdExactMatch(groupIDUserProfile.orgid))) {
                this.fileContent.OrgID = groupIDUserProfile.orgid;
            } else {
                this.fileContent.OrgID = '';
            }

            this.fileContent.CountryCode = groupIDUserProfile.countryCode ? groupIDUserProfile.countryCode : '';
            this.fileContent.ExpirationDate = groupIDUserProfile.C_accountExpires ? groupIDUserProfile.C_accountExpires : '';
        }
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
            this.webAdministrationService.getEntities({ Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
            this.OrgIDs = entity?.body;
            this.OrgIDs = this.OrgIDs.filter(item => item["OrgID"].match(/ORG\d{4}/i));
        },
        (error) =>  {
            this.OrgIDs = [];
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        });
        this.webAdministrationService.getCompanies({ Status: "Valid", itemPerPage: 5000 })
            .subscribe(response => {
                this.companies = response.body.map(item => item.Name).sort();
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value))
                    );
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        this.requestParameters = new UpdateOktaB2BCloudAccountFileContent(cb[0]);
                        let doc = document.getElementById("mailInputOkta") as HTMLInputElement;
                        if (doc) {
                            doc.value = cb[0].OktaUsername;
                            doc.dispatchEvent(new Event('input'));
                            let button = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                            button[0].dispatchEvent(new Event('click'));
                        }
                    }
                });
            });
    }

    updateCompany(option: MatOption) {
        this.fileContent.Company = option.value;
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<UpdateOktaB2BCloudAccountFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new UpdateOktaB2BCloudAccountFileContent();
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    //Add the current fileContent to the fileContentList and clean the forms (Need reflexion for the Edit)
    addFileContentToList() {
        var tempObject = new UpdateOktaB2BCloudAccountFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    errorsInForm() {
        return (!this.fileContent.GroupIDDisplayName || this.fileContent.GroupIDDisplayNameError || this.fileContent.NewOktaUsernameDisplayName || this.fileContent.SponsorDisplayNameError || this.myControl.hasError('forbiddenNames'))
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.errorsInForm()) {
            this.addFileContentToList();
        }

        var data = new UpdateOktaB2BCloudAccountRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/updateSquidOktaB2BCloudAccount')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}