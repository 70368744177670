export class AddAADGroupMemberDetails{
    AzureADGroup: string = '';
    Members: Array<string> = new Array<string>();

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.AzureADGroup = requestItemOutput.AzureADGroup ? requestItemOutput.AzureADGroup : '';

            if(Array.isArray(requestItemOutput.Members)){
                this.Members = requestItemOutput.Members;
            }else if (requestItemOutput.Members){
                this.Members.push(requestItemOutput.Members);
            }
        }
    }
}