import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ChangeMailboxManagerDetails } from '../../../../Models/History/ChangeMailboxManagerDetails';
import { booleanToStringConverter } from '../../../../Helpers/utils';
import { Router } from '../../../../../../node_modules/@angular/router';

@Component({
    selector: 'ChangeMailboxManagerHistoryDetails',
    templateUrl: './changeMailboxManagerHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class ChangeMailboxManagerHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: ChangeMailboxManagerDetails;
    @Output() CloseDialog = new EventEmitter<Boolean>();

    productCustomInfoCollapsed: boolean = false;

    constructor(private router: Router){}

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    closePopUp(product?){
        this.CloseDialog.emit(true);
    }
}