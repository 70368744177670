<h4>Remove Azure AD Device:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true">Please fill the field below and
            click the search button</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID"
                    name="groupIDInput"
                    (keyup)="setBackEmptyForm()"
                    required 
                    [pattern]="groupIDPattern" #groupIDInput="ngModel"/>
                <i *ngIf="!groupIDInput.invalid" class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="getOwnedDevices(null);">search</i>
            </div>
            <div *ngIf="groupIDInput.invalid && (groupIDInput.dirty || groupIDInput.touched)"
                class="alert alert-danger">
                <div *ngIf="groupIDInput.errors.pattern">
                    GroupID is invalid.
                </div>  
                <div *ngIf="groupIDInput.errors.required">
                    GroupID is required.
                </div>          
            </div>
            <small class="form-text text-danger">{{localITNotFound}}</small>
        </div>
        <div class="col-4" *ngIf="!errorMessage && isUserInAAD === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group" *ngIf="!errorMessage && isUserInAAD === true">
        <div class="col-12" *ngIf="devices.length > 0">
            <input type="checkbox" [checked]="selectAll" (click)="changeSelectAll()">
            <span style="font-weight:bold;color:#0074D8">Select All</span>
        </div>
    
        <br />
        <br />
        <div *ngFor="let device of devices; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex" class="col-6">
            <label for="teamgroup{{device.id}}" style="vertical-align: text-top">
                <input type="checkbox" id="teamgroup{{device.id}}"
                    (change)="changeStatus(i)"
                    [disabled]="device.isManaged || device.hasOwnProperty('serialNumber')"
                    [checked]="devices[i].Enabled" style="vertical-align: text-top"><table [ngStyle]="{'color': device.isManaged? 'orange' : device.hasOwnProperty('serialNumber') ? 'brown' : 'black','display':'inline-table'}">
                        <tbody>
                            <tr>
                                <th>Display name:</th><td>{{device.displayName}}</td>
                            </tr>
                            <tr>
                                <th>Serial number:</th><td>{{device.serialNumber}}</td>
                            </tr>
                            <tr>
                                <th>Registration date:</th><td>{{device.registrationDateTime}}</td>
                            </tr>
                            <tr>
                                <th>Last activity:</th><td>{{device.approximateLastSignInDateTime}}</td>
                            </tr>
                        </tbody>
                    </table>
            </label>
        </div>
        <br>
    </div>
    <div class="col-12">
        <h5 style="white-space: pre-line;color:brown">{{message}}</h5>
        <h5 style="white-space: pre-line;color:orange">{{warningMessage}}</h5>
        <h5 class="text-danger" style="white-space: pre-line">{{errorMessage}}</h5>
    </div>
    <div class="row form-group" *ngIf="!errorMessage && isUserInAAD === true  || (fileContentsList && fileContentsList.length > 0)">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" id="sendButton" name="sendButton"
                [disabled]="(fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0))" #sendButton>Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
                <div class="col-12">
                    <b>Devices:</b>
                    <div class="col-12">
                        <span *ngFor="let app of listedFileContent.Devices">{{ app }}<br /></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>