import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UndeleteO365ObjectFileContent, UndeleteO365ObjectRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { DeleteO365ObjectConfirmationDialog } from '../../Template/Dialog/deleteO365ObjectConfirmationDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { DeletedO365ObjectsService } from '../../../Services/deletedO365Objects.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './undeleteO365Object.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UndeleteO365ObjectComponent{
    
    
    
    

    public fileContentsList = new Array<UndeleteO365ObjectFileContent>();
    public fileContent = new UndeleteO365ObjectFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public deletedO365ObjectsService : DeletedO365ObjectsService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<UndeleteO365ObjectFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new UndeleteO365ObjectFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }
    
    async addFileContentToList(isSendingRequest:Boolean){
        var tempObject = new UndeleteO365ObjectFileContent(this.fileContent);
        await this.checkGroupIDValidity(tempObject, true, isSendingRequest);
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    checkBeforeSendingRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false){
            this.addFileContentToList(true);
        }else{
            this.sendRequest();
        }
    }
    
    sendRequest(){
        var data = new UndeleteO365ObjectRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/undeleteO365Object')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    async checkGroupIDValidity(undeleteO365ObjectFileContent:UndeleteO365ObjectFileContent, addObjectToList:Boolean, isSendingRequest:Boolean){
        if(inputValidationHelper.checkGroupIdFormat(undeleteO365ObjectFileContent.GroupID)){
            this.deletedO365ObjectsService.getDeletedO365ObjectDisplayNameById(undeleteO365ObjectFileContent.GroupID).subscribe(
                response => {
                    undeleteO365ObjectFileContent.GroupIDDisplayName = response;
                    if(undeleteO365ObjectFileContent.GroupIDDisplayName){
                        if(addObjectToList){
                            this.fileContentsList.push(undeleteO365ObjectFileContent);
                            this.clearFileContentForms();
                            if(isSendingRequest){
                                this.sendRequest();
                            }
                        }
                    }else{
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : { statusText: 'Object ' + undeleteO365ObjectFileContent.GroupID + ' does not exist in deleted objects!'} } });
                    }
                },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            )
        }
    }

    updateCurrentFileContentGroupID(event){
        this.fileContent.GroupID = event;
        this.fileContent.GroupIDDisplayName = '';
    }
}