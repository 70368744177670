import { Component, Input } from '@angular/core';
import { DeleteO365ObjectDetails } from '../../../../Models/History/DeleteO365ObjectDetails';

@Component({
    selector: 'DeleteO365ObjectHistoryDetails',
    templateUrl: './deleteO365ObjectHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DeleteO365ObjectHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: DeleteO365ObjectDetails;
    productCustomInfoCollapsed: boolean = false;
}