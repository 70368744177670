<h4>Modify Intune User:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true">Please fill the user GroupID below and
            click the search button</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID" required [pattern]="groupIDPattern" (keyup)="setBackEmptyForm()">
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;"
                    (click)="getLocalITsFromOkta();">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span>
        </div>
        <div class="col-4" *ngIf="isUserInOkta === true">
            <label for="deviceTypeDropdownList">Mobile Mail Client:</label>
            <select id="deviceTypeDropdownList" class="form-control" [(ngModel)]="fileContent.MobileMailClient"
                name="deviceTypeDropdownList">
                <option *ngFor="let deviceType of assignableDeviceTypes | orderby: '' : false : true"
                    [value]="deviceType">{{deviceType}}</option>
            </select>
        </div>
    </div>

    <div class='row form-group'>
        <div class="col-4" *ngIf="isUserInOkta === true">
            <label for="intuneUserOktaMfaBypass">Okta MFA Bypass:</label>
            <div id="intuneUserOktaMfaBypass">
                <div style="display: inline-block;">
                    <label><input type="radio" id="intuneUserOktaMfaBypassOpt1" name="intuneUserOktaMfaBypass"
                            class="form-control" [value]="true" [(ngModel)]="fileContent.OktaMfaBypass"
                            #intuneUserOktaMfaBypassOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="intuneUserOktaMfaBypassOpt2" name="intuneUserOktaMfaBypass"
                            class="form-control" [value]="false" [(ngModel)]="fileContent.OktaMfaBypass"
                            #intuneUserOktaMfaBypassOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="(isTopLocalIT === true && isUserInOkta === true) || isAnyLocalITFromOkta === true">
            <label for="deviceTypeDropdownList">{{groupIDLabel}}</label>
            <select id="localITDropdownList" class="form-control" [(ngModel)]="fileContent.ManagedLocalIT"
                name="localITDropdownList" (change)="getLocalItTeamsGroup($event.target.value);">
                <option hidden disabled selected value></option> -->
                <option *ngFor="let localIT of listLocalIT | orderby: '' : false : true" [value]="localIT">{{localIT}}
                </option>
            </select>
        </div>
        <div class="col-4" *ngIf="isUserInOkta === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <div class="row form-group" *ngIf="isUserInOkta === true">
        <div *ngFor="let app of localITTeamsGroup" class="checkbox; col-6">
            <label for="teamgroup{{app.id}}" data-toggle="tooltip" title="{{app.description}}">
                <input type="checkbox" id="teamgroup{{app.id}}"
                    (change)="onTeamGroupToggle({id : app.id, displayName : app.displayName}, $event)"
                    [checked]="applications.includes(app.id)"> {{app.displayName}}
            </label>
        </div>
        <br>
    </div>
    <div class="row form-group" *ngIf="isUserInOkta === true || (fileContentsList && fileContentsList.length > 0)">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Mobile Mail Client:</b> {{ listedFileContent.MobileMailClient }}
                </div>
                <div class="col-4">
                    <b>Okta Mfa Bypass:</b> {{ listedFileContent.OktaMfaBypass }}
                </div>
                <div class="col-4">
                    <b>Select Local IT:</b> {{ listedFileContent.ManagedLocalIT }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
                <div class="col-6">
                    <b>Add Applications:</b>
                    <div class="col-12">
                        <span *ngFor="let app of listedFileContent.AddApplications">{{ app }}<br /></span>
                    </div>
                </div>
                <div class="col-6">
                    <b>Remove Applications:</b>
                    <div class="col-12">
                        <span *ngFor="let app of listedFileContent.RemoveApplications">{{ app }}<br /></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>