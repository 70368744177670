import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { GraphApiService } from '../../../Services/graphApi.service';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'ContactFieldCheck',
    templateUrl: './contactFieldCheck.component.html',
    styleUrls: ['../../../app.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class ContactFieldCheckComponent{
    contactCheckNotification:string = '';

    public visible = true;
    public selectable = true;
    public removable = true;
    public addOnBlur = true;
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    
    constructor(private graphApiService: GraphApiService){}    

    @Input() label: string;
    @Input() contactList:Array<any>;
    @Output() newContactList = new EventEmitter();

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        let userProfile = null;

        if ((value || '').trim()) {
            if(value.length <= 6){
              if(inputValidationHelper.checkGroupIdFormat(value)){
                this.graphApiService.getUserByGroupID(value).subscribe(
                    response => {
                        userProfile = response;
                        let emailContact:string = userProfile.mail;
                        this.contactList.push(emailContact);
                        this.contactCheckNotification = `The user ${value} is on AzureAD`;
                        this.newContactList.emit(this.contactList);                   
                    },
                    error => {
                        if(error && error.status && error.status == '404'){
                            this.contactCheckNotification = value + ' not found on AzureAD!';
                        }else{
                            this.contactCheckNotification = `Error when trying to check ${value} on AzureAD!`;
                        }
                    }
                )
              }
              else{
                this.contactCheckNotification = `The input ${value} is not a valid GID format`;
              }
            }else{
                if(inputValidationHelper.checkMailAddressFormat(value)){
                    this.graphApiService.getUserDisplayNameByMail(value).subscribe(
                        response => {
                            userProfile = response;
                            let emailContact:string = userProfile.mail;
                            this.contactList.push(value);
                            this.contactCheckNotification = `${value} is on AzureAD`;
                            this.newContactList.emit(this.contactList);                   
                        },
                        error => {
                            if(error && error.status && error.status == '404'){
                                this.contactCheckNotification = value + 'added but not found on AzureAD!';
                                this.contactList.push(value);
                            }else{
                                this.contactCheckNotification = `Error when trying to check ${value} on AzureAD!`;
                            }
                        }
                    )
                }
                else{
                    this.contactCheckNotification = `The input ${value} is not a valid email format`;
                }
            }
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
    }
    
    remove(contact): void {
        const index = this.contactList.indexOf(contact);
        if (index >= 0) {
            this.contactList.splice(index, 1);
            this.newContactList.emit(this.contactList);  
        }
    }

}