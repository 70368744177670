import { Component, ElementRef, NgModule, QueryList, Renderer2, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { AddMWUserFileContent, AddMWUserRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { forkJoin } from 'rxjs';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { booleanToStringConverter } from '../../../Helpers/utils';

@Component({
    selector: 'BodyContent',
    templateUrl: './addMWUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddMWUserComponent{
    
    
    

    public releaseRings:Array<string> = new Array<string>();
    public listLocalIT:Array<string> = new Array<string>();
    public applications;
    public allLocalITs = [];
    public localITTeamsGroup;
    public fileContentsList = new Array<AddMWUserFileContent>();
    public fileContent = new AddMWUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public topLocalIT:Array<string> =  new Array<string>();
    public isTopLocalIT:boolean = false;
    public isAnyLocalITFromOkta:boolean = false;
    public isUserInOkta:boolean = false;
    public howToUseInfo:boolean = true;
    public i = 0;
    public errorMessage = '';
    public message = '';
    public warningMessage = '';
    public adminWarningMessage = `The attribution of privileged rights on the workstation must be the subject of a formal request and an explicit validation by the line manager and the CISO within the framework of the procedure in force within each of the entities of ENGIE SA.`;
    public groupIDPattern:string = inputValidationHelper.groupIdNoAdminFormatRegEx;
    public orgIDPattern = inputValidationHelper.orgIDRegex;
    public groupMembership;
    public hiddenGroups = true;
    private orgId = '';

    @ViewChildren('group') groups : QueryList<ElementRef<HTMLElement>>;
   
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private graphApiService: GraphApiService, 
        private oktaApiService: OktaApiService, 
        private localITsService: LocalITsService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private renderer: Renderer2){
            this.clearFileContentForms();
    }
    
    ngOnInit() {
        this.localITsService.getLocalITsWithManageAllPermission().subscribe( response => {
            this.topLocalIT = response;
            this.userService.selectedUserLocalITObs.subscribe( updatedLocalIT => {
                this.clearPage();
                this.fileContentsList = new Array<AddMWUserFileContent>();
                this.selectedLocalIT = updatedLocalIT;
                this.isTopLocalIT = this.topLocalIT.some((value) => value === this.selectedLocalIT) 
                if (this.isTopLocalIT) {
                    this.localITsService.getAllLocalITNames()
                        .subscribe(response => {
                            let localITs = <any> response;
                            this.allLocalITs = localITs.filter(item => item.Name != 'IT_200').map(({Name}) => Name);
                        });
                }
            });
        });
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.businessRulesService.getSpecificListByKey('RELEASE-RINGS', 'ReleaseRings')
            .subscribe(response => {this.releaseRings = response;});
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.localITNotFound = '';
        this.howToUseInfo = true;
    }

    clearFileContentForms() {
        this.fileContent = new AddMWUserFileContent();
        this.applications = [];
        this.isAnyLocalITFromOkta = false;
        this.isUserInOkta = false;
        this.listLocalIT =  new Array<string>();
        this.errorMessage = '';
        this.message = '';
        this.warningMessage = '';
        this.orgId = '';
    }

    public setBackEmptyForm() {
        this.localITNotFound = '';
        this.applications = [];
        this.isAnyLocalITFromOkta = false;
        this.isUserInOkta = false;
        this.listLocalIT =  new Array<string>();
        this.errorMessage = '';
        this.message = '';
        this.warningMessage = '';
        this.orgId = '';
    }

    filterGroupsAvailable(value: string) {
        this.groups.forEach(group => {
            let display = group.nativeElement.innerText.toLocaleLowerCase().match(value.toLocaleLowerCase()) ? "block":"none" ;
            group.nativeElement.setAttribute('style',`display:${display}`);
        });
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addFileContentToList() {
        if (this.fileContent.Skynote) {
            this.fileContent.Subdelegation = true;
        }
        var tempObject = new AddMWUserFileContent(this.fileContent);
        delete tempObject.GroupIDDisplayName;
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
        this.setBackEmptyForm();
    }
    
    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.errorMessage && this.isUserInOkta === true) {
            this.addFileContentToList();
        }
        var fileContentsList = [...this.fileContentsList];
        this.fileContentsList = new Array<AddMWUserFileContent>();
        for (let i = 0; i < fileContentsList.length; i++)  {
            let data = { ...fileContentsList[i] };
            data["localIT"] = this.selectedLocalIT;
            
            this.graphApiService.addMWUser(data)
                .subscribe( response => {
                    if (i == 0) {
                        this.clearPage();

                        let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                            duration: 2000,
                            verticalPosition: "bottom",
                            horizontalPosition: "right",
                            panelClass: ['bg-success', 'font-weight-bold'],                  
                        });

                        successSnackBar.afterDismissed().subscribe(null, null, () => {
                            this.router.navigate(['/history']);
                        })
                    }}, 
                    error => {
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                    }
                );
        }
    }

    onTeamGroupToggle(tGroup:any, $event:any) {
        var index;
        if ($event.target.checked) {
            this.applications.push(tGroup.displayName);
            this.fileContent.AddApplications.push(tGroup.displayName);
            index = this.fileContent.RemoveApplications.indexOf(tGroup.displayName);
            if(index >= 0){
                this.fileContent.RemoveApplications.splice(index, 1);
            }
        }
        else {
            index = this.applications.indexOf(tGroup.id);
            if(index >= 0){
                this.applications.splice(index, 1);
            }
            this.fileContent.RemoveApplications.push(tGroup.displayName);
            index = this.fileContent.AddApplications.indexOf(tGroup.displayName);
            if(index >= 0){
                this.fileContent.AddApplications.splice(index, 1);
            }
        }
    }

    public fillCheckboxes() {
        this.applications = [];
        this.fileContent.AddApplications = new Array<any>();
        this.fileContent.RemoveApplications = new Array<any>();
        if (this.groupMembership.length > 0) {
            var checkedFields = (this.localITTeamsGroup.filter(value => this.groupMembership.some(item => item.id == value.id)));
            if (checkedFields.length > 0) {
                this.applications = checkedFields.map((element) => element.id);
            }
        }
    }

    public getLocalItTeamsGroup(localIT:string) {
        if (localIT) {
            this.message = '';
            let groupPattern = `${localIT}-SG-WIN-Local-`;
            let localGroups = this.graphApiService.getGroupByDisplayName(groupPattern);
            if (this.fileContent.Skynote || this.fileContent.Subdelegation) {
                let groupOrigIdPattern = `${localIT}-SG-WIN-${this.orgId}-Local-`;
                var localOrgIdGroups = this.graphApiService.getGroupByDisplayName(groupOrigIdPattern);
            }
            const allGroups$ = (...args) => {
                return forkJoin(args.map(e => e));
            };
            if (this.fileContent.Skynote || this.fileContent.Subdelegation) {
                allGroups$(localGroups,localOrgIdGroups).subscribe((response: any) => {
                    this.localITTeamsGroup = (<[]>response[0]).concat(<[]>response[1]);
                    if (this.localITTeamsGroup.length === 0) {
                        this.message = `No Local groups found`;
                    }
                    else {
                        this.fillCheckboxes();
                    }
                });
            }
            else {
                allGroups$(localGroups).subscribe(response => {
                    this.localITTeamsGroup = response[0];
                    if (this.localITTeamsGroup.length === 0) {
                        this.message = `No Local groups found`;
                    }
                    else {
                        this.fillCheckboxes();
                    }
                });
            }
        }
    }

    public getLocalITGroups() {
        if (this.isUserInOkta && this.fileContent.GroupID) {
            this.errorMessage = '';
            this.localITTeamsGroup = [];
            if ((this.fileContent.Skynote || this.fileContent.Subdelegation) && !this.orgId) {
                this.errorMessage = `The OrgID value is not set in OKTA for ${this.fileContent.GroupID}, make sure it is correctly assigned on the AD user object`
                return;
            }
            if (this.fileContent.Skynote) {
                this.fileContent.ManagedLocalIT = 'IT_200';
            }
            else {
                if (this.listLocalIT) {
                    if (this.fileContent.ManagedLocalIT == 'IT_200') {
                        this.fileContent.ManagedLocalIT = '';
                    }
                    if (!this.fileContent.ManagedLocalIT) {
                        if (this.isAnyLocalITFromOkta) {
                            if (this.listLocalIT.includes(this.selectedLocalIT.toString())) {
                                this.fileContent.ManagedLocalIT = this.selectedLocalIT.toString();
                            }
                            else if (this.listLocalIT.length >= 1) {
                                this.fileContent.ManagedLocalIT = this.listLocalIT[0];
                            }
                        }
                        else {
                            if (!this.isTopLocalIT) {
                                this.fileContent.ManagedLocalIT = this.selectedLocalIT.toString();
                            }
                        }
                    }
                }
            }
            if (this.fileContent.ManagedLocalIT) {
                this.getLocalItTeamsGroup(this.fileContent.ManagedLocalIT);
            }
        }
    }

    // Get the LocalITs of the user from okta
    getLocalITsFromOkta() {
        let gID = this.fileContent.GroupID.toString();
        this.fileContent.ManagedLocalIT = '';
        this.fileContent.OrgID = '';
        this.isAnyLocalITFromOkta = false;
        this.localITNotFound = '';
        this.errorMessage = '';
        this.message = '';
        this.warningMessage = '';
        this.isUserInOkta = false;
        this.howToUseInfo = false;

        if (gID) {
            this.isTopLocalIT = this.topLocalIT.some((value) => value === this.selectedLocalIT) 
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        let localIT = response.c_msDSCloudExtensionAttribute1;
                        this.orgId = response.C_msDSCloudExtensionAttribute2;
                        this.fileContent.OrgID = this.orgId;
                        if (localIT) {
                            this.isAnyLocalITFromOkta = true;
                            this.listLocalIT = localIT.split(";");
                        }
                        else {
                            this.localITNotFound = `Local IT attribute not set in OKTA for ${gID}`;
                            if (this.isTopLocalIT) {
                                this.listLocalIT = this.allLocalITs;
                            }
                        }
                        //call graph api to check for group memberships
                        this.graphApiService.getAADUserMembershipWithFilter(gID,'-SG-WIN-')
                        .subscribe(
                            resp => {
                                this.groupMembership = resp;
                                this.isUserInOkta = true;
                                this.setInitalMWGroupMembership();
                            }, 
                            error => {
                                if (error.error) {
                                    if (error.error.match(/(?:"message":").*?(?=")/)) {
                                        this.localITNotFound = error.error.match(/(?:"message":").*?(?=")/)[0];
                                    }
                                    else {
                                        this.localITNotFound = error.error;
                                    }
                                }
                                else {
                                    this.localITNotFound = error.message;
                                }
                                this.clearFileContentForms();
                            }
                        );
                    },
                    (error) => {
                        if (error && error.status && error.status == '404') {
                            this.localITNotFound  = `${gID} does not exist in OKTA`;
                        } else {
                            this.localITNotFound = 'Error when trying to search GroupID in Okta!';
                        }
                        this.applications = [];
                        this.localITTeamsGroup = [];
                        this.fileContent.GroupID = '';
                    }
                );
        }
    }

    setInitalMWGroupMembership() {
        if (this.groupMembership.length > 0) {
            var orgIds = [];
            var rings = [];
            let localITsUsed = [...new Set(this.groupMembership.map(item => item.displayName.substring(0,6).toUpperCase()))];
            localITsUsed = localITsUsed.filter(item => item != 'IT_000');
            let index = 0;
            if (localITsUsed.length > 1) {
                this.warningMessage = `The user belongs to multiple Local IT groups [${localITsUsed.join('-SG-WIN-*, ')}-SG-WIN-*] and will be removed from any *-SG-WIN-* group that is not chosen by the selected value.`;
                if (localITsUsed.includes('IT_200')) {
                    index = localITsUsed.indexOf('IT_200');
                }
                else if (localITsUsed.includes(this.selectedLocalIT.toString()) && this.listLocalIT.includes(this.selectedLocalIT.toString())) {
                    index = localITsUsed.indexOf(this.selectedLocalIT.toString());
                }
            }
            if (localITsUsed[index].toString() == 'IT_200') {
                this.fileContent.Skynote = true;
            }
            else {
                this.fileContent.Skynote = false;
            }
            if (this.listLocalIT.includes(localITsUsed[index].toString()) || this.selectedLocalIT.toString() == localITsUsed[index].toString() || localITsUsed[index].toString() == 'IT_200') {
                this.fileContent.ManagedLocalIT = localITsUsed[index].toString().toUpperCase();
                if (this.listLocalIT.length > 0 && !this.listLocalIT.includes(localITsUsed[index].toString()) && localITsUsed[index].toString() != 'IT_200') {
                    this.fileContent.ManagedLocalIT = '';
                    this.warningMessage += (this.warningMessage ? `\n` : '') + `The user belongs to Local IT groups [${localITsUsed.join('-SG-WIN-*, ')}] that are not in [${this.listLocalIT.join(', ')}] and will be removed from any *-SG-WIN-* group that is not chosen by the selected value.`;
                }
            }
            else {
                this.warningMessage += (this.warningMessage ? `\n` : '') +  `The user belongs to Local IT groups [${localITsUsed.join('-SG-WIN-*, ')}] that are not manageable by ${this.selectedLocalIT.toString()} and will be removed from any *-SG-WIN-* group that is not chosen by the selected value.`;
            }
            var regex = /IT_...-SG-WIN-.*ORG\d{4}.*/i
            orgIds = (this.groupMembership).filter(item => item.displayName.match(regex));
            if (orgIds.length > 0) {
                orgIds = [...new Set(orgIds.map((element) => (element.displayName.match(/ORG\d{4}/i))[0]))];
                if (orgIds.length > 1 || !this.orgId || (orgIds[0] != this.orgId)) {
                    this.warningMessage += (this.warningMessage ? `\n` : '') + `The user has ORGID [${this.orgId}], but belongs to ORGID [${orgIds.filter(item => item != this.orgId).join(', ')}] groups that are not allowed. The user will be removed from any group that is not allowed.`;
                }
            }
            regex = /IT_...-SG-WIN-R[2-4]-.*/i
            rings = (this.groupMembership).filter(item => item.displayName.match(regex));
            if (rings.length > 0) {
                rings = rings.map((element) => (element.displayName.match(/R[2-4]/i))[0]);
                if (rings.length > 1) {
                    this.warningMessage += (this.warningMessage ? `\n` : '') + `The user belongs to several release rings [${rings.join(', ')}] and will be removed from any group that is not chosen by the selected value.`;
                }
                else {
                    this.fileContent.ReleaseRing = rings[0];
                }
            }
            if (orgIds.length > 0 || this.fileContent.Skynote) {
                this.fileContent.Subdelegation = true;
            }
            else {
                this.fileContent.Subdelegation = false;
            }
            if (rings.length > 0) {
                this.fileContent.ReleaseRing = rings[0];
            }
            if (this.groupMembership.some(item => item.displayName.match(/IT_...-SG-WIN-.*IsLocalAdmin$/i))) {
                this.fileContent.LocalAdmin = true;
            }
            else {
                this.fileContent.LocalAdmin = false;
            }
        }
        this.getLocalITGroups();
    }
} 