import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderby'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], propertyName: string) {
    if (value) {
      return value.sort((a, b) => {      
          if (propertyName) {
            if (typeof a[propertyName] === "number") {
              return a[propertyName] - (b[propertyName]);
            }
            return a[propertyName].localeCompare(b[propertyName]);
          }
          if (typeof a === "number") {
            return a - b;
          }
          return a.localeCompare(b);
      });
    }
    return 0;
  }

}
