export class TopBarMessage {
    _key: string = '';
    id: string = '';
    Title: string = '';
    Type: string = '';
    Message: string = '';
    StartDateTime: string = '';
    EndDateTime: string = '';
    Level: string = '';
    Show: boolean = false;

    constructor(topBarMessage?: TopBarMessage) {
        if (topBarMessage) {
            this.id = topBarMessage.id || topBarMessage._key || '';
            this.Title = topBarMessage.Title ? topBarMessage.Title : '';
            this.Type = topBarMessage.Type ? topBarMessage.Type : '';
            this.Message = topBarMessage.Message ? topBarMessage.Message : '';
            this.StartDateTime = topBarMessage.StartDateTime ? topBarMessage.StartDateTime : '';
            this.EndDateTime = topBarMessage.EndDateTime ? topBarMessage.EndDateTime : '';
            this.Level = topBarMessage.Level ? topBarMessage.Level : '';
            this.Show = topBarMessage.Show ? topBarMessage.Show : false;
        }
    }
}

export class TopBarMessagePreview {
    id: string = '';
    Title: string = '';
    Message: string = '';
    StartDateTime: string = '';
    EndDateTime: string = '';
    Show: string = 'No';


    constructor(topBarMessage?) {
        if (topBarMessage) {
            this.id = topBarMessage.id || topBarMessage._key || '';
            this.Title = topBarMessage.Title ? topBarMessage.Title : '';
            this.Message = topBarMessage.Message ? topBarMessage.Message : '';
            this.StartDateTime = topBarMessage.StartDateTime ? topBarMessage.StartDateTime.split('T')[0] + "  " + topBarMessage.StartDateTime.split('T')[1].split(':')[0] + ':' + topBarMessage.StartDateTime.split('T')[1].split(':')[1] : 'No Start Date';
            this.EndDateTime = topBarMessage.EndDateTime ? topBarMessage.EndDateTime.split('T')[0] + "  " + topBarMessage.EndDateTime.split('T')[1].split(':')[0] + ':' + topBarMessage.EndDateTime.split('T')[1].split(':')[1] : 'No End Date';
            this.Show = topBarMessage.Show ? 'Yes' : 'No';
        }
    }
}

export class TopBarMessageRequest {
    requestParams: TopBarMessage;
    localIT: String;

    constructor() { }
}

