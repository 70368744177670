export class GetOktaAssignedAppDetails{
    ProductName: string = '';
    Apps: Array<{Label: String, Statut: String}> = new Array<{Label: String, Statut: String}>();

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.ProductName = productName ? productName : '';
            if(Array.isArray(requestItemOutput)){
                this.Apps = requestItemOutput;
            }else if (requestItemOutput){
                this.Apps.push(requestItemOutput);
            }
            this.Apps.forEach((app: any) => {
                app.Label = app.Label ? app.Label : ' ';
            })
        }
    }
}