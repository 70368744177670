<h4>Get mailbox info:</h4>

<form #getMailboxInfoForm="ngForm" (ngSubmit)="getMailboxInfoForm.resetForm()" >
    <div class="row form-group">
        <div class="col-4">
            <GroupIdOrMailInputField label="Group ID or email address" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIdOrMailInputField>          
        </div>
        <div class="col-1">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); getMailboxInfoForm.reset();" [disabled]="fileContent.GroupIDDisplayNameError || getMailboxInfoForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-5" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getMailboxInfoForm.invalid); getMailboxInfoForm.reset();" [disabled]="fileContent.GroupIDDisplayNameError || (getMailboxInfoForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF GETMAILBOXINFO READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i id="{{'getMailboxInfoRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>            
        </span>
    </a>
</div>