import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'requestErrorDialog',
    templateUrl: './requestErrorDialog.component.html'
})

export class RequestErrorDialog {
    public dialogData: any;
    public errorMessage = '';

    constructor(public dialogRef: MatDialogRef<RequestErrorDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogData = data.errorData;
        if (typeof this.dialogData.error == 'string') {
            if (this.dialogData.error.match(/(?:"message":").*?(?=")/)) {
                this.errorMessage = this.dialogData.error.match(/(?:"message":").*?(?=")/)[0];
            }
            else {
                this.errorMessage = this.dialogData.error;
            }

        }
        else if (this.dialogData.error && this.dialogData.error.message ) {
            this.errorMessage = this.dialogData.error.message;
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}