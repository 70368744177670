<h4>Manage User Admin List:</h4>

<form #manageUserAdminListForm="ngForm">
    <div class="row form-group" *ngFor="let userAdmin of userAdminList; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
        <div class="col-2">
            <label class="required" for="manageUserAdminListGroupIDInput">Group ID:</label>
            <input type="text" id="{{'manageUserAdminListGroupIDInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(manageUserAdminListGroupIDInput.invalid && (manageUserAdminListGroupIDInput.dirty || manageUserAdminListGroupIDInput.touched || manageUserAdminListGroupIDInput.value))}"
                            [(ngModel)]="userAdminList[i].GroupID" name="{{'manageUserAdminListGroupID' + i}}" #manageUserAdminListGroupIDInput="ngModel"
                            required [pattern]="groupIDPattern">         
        </div>
        <div class="col-3">
            <label class="required" for="manageUserAdminListLastNameInput">Last Name:</label>
            <input type="text" id="{{'manageUserAdminListLastNameInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(manageUserAdminListLastNameInput.invalid && (manageUserAdminListLastNameInput.dirty || manageUserAdminListLastNameInput.touched || manageUserAdminListLastNameInput.value))}"
                            [(ngModel)]="userAdminList[i].LastName" name="{{'manageUserAdminListLastNameInput' + i}}" #manageUserAdminListLastNameInput="ngModel" required>         
        </div>
        <div class="col-3">
            <label class="required" for="manageUserAdminListFirstNameInput">First Name:</label>
            <input type="text" id="{{'manageUserAdminListFirstNameInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(manageUserAdminListFirstNameInput.invalid && (manageUserAdminListFirstNameInput.dirty || manageUserAdminListFirstNameInput.touched || manageUserAdminListFirstNameInput.value))}"
                            [(ngModel)]="userAdminList[i].FirstName" name="{{'manageUserAdminListFirstNameInput' + i}}" #manageUserAdminListFirstNameInput="ngModel" required>
        </div>
        <div class="col-3">
            <label for="roleDDL" class="required">Role:</label>
            <select id="{{'roleDDL' + i}}" class="form-control"
                [(ngModel)]="userAdminList[i].Role" name="{{'roleDDL' + i}}" #roleDDL="ngModel">
                <option *ngFor="let role of roleTypes" [value]="role">{{role}}</option>
            </select>
        </div>

        <div class="col-1">
            <i *ngIf="!isFirst" id="{{'manageUserAdminListRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;margin-top: 33px;" (click)="removeUserAdmin(i)">remove_circle_outline</i>
            <i *ngIf="isLast" id="{{'manageUserAdminListAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;margin-top: 33px;" (click)="addUserAdmin()">add_circle_outline</i>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2 offset-10" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest()" [disabled]="manageUserAdminListForm.invalid">Send Update</button>
        </div>
    </div>
</form>