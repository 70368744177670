import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';

import { EnableTeamsCAPPhoneFileContent, EnableTeamsCAPPhoneRequest } from '../../../Models/FileContents';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { UserInfo } from '../../../Models/UserInfo';



@Component({
    selector: 'BodyContent',
    templateUrl: './enableTeamsCAPPhone.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class EnableTeamsCAPPhoneComponent{
    //Hardcoded List Values
    public IPPhonePolicies:Array<any> = new Array<any>();
    
    public requestContainer:any = {};
    public fileContentsList = new Array<EnableTeamsCAPPhoneFileContent>();
    public fileContent = new EnableTeamsCAPPhoneFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public countryCodePattern:string = inputValidationHelper.countryCodeRegEx;
    public groupIdNoAdminPattern: string = inputValidationHelper.groupIdNoAdminFormatRegEx;
    public message:string = '';
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private customerProfilesService: CustomerProfilesService, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb) {
                    this.fileContent = cb[0];
                    this.getUserInAAD();
                }
            });
        });
        
        this.businessRulesService.getSpecificListByKey('IPPHONE-POLICIES', 'IPPhonePolicies')
            .subscribe(response => { this.IPPhonePolicies = response; });
    }

    ngOnDestroy(){
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdNoAdminFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<EnableTeamsCAPPhoneFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new EnableTeamsCAPPhoneFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new EnableTeamsCAPPhoneFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    public setBackEmptyForm(){
        this.message = '';
        this.fileContent.GroupIDDisplayName = '';
    }

    errorsInForm() {
        return (this.fileContent.ManagerDisplayNameError || this.message);
    }

    getUserInAAD(){
        let gID = this.fileContent.GroupID.toString() ;
        let userProfile = null;
        if (gID) {
            this.graphApiService.getUser(gID)
                .subscribe({
                    next: (response) => {
                        userProfile = response;
                        if (!userProfile) {
                            this.message  = `${gID} not found in EntraID!`;
                            return;
                        }
                        this.fileContent.GroupIDDisplayName = userProfile.displayName;
                        this.message = '';
                        if (!userProfile.onPremisesExtensionAttributes.extensionAttribute11?.match(/TeamsCap/gi)) {
                            this.message = `[${userProfile.onPremisesExtensionAttributes.extensionAttribute11}] mailbox instead of TeamsCAP`;
                            return;
                        }
                        else if (userProfile.onPremisesSyncEnabled) {
                            this.message =  `${gID} is not cloud only`;
                            return;
                        }
                        if (!this.fileContent.Manager) {
                            this.fileContent.Manager = userProfile.manager.mailNickname;
                        }
                        let fields = userProfile.displayName.split(" \(");
                        fields = fields.slice(0,fields.length - 1).join(" (");
                        fields = fields.split("-");
                        if (fields.length >= 5) {
                            if (fields[1].length == 2 && !this.fileContent.CountryCode) {
                                this.fileContent.CountryCode = fields[1];
                            }
                            if (fields[2].length >= 2 && fields[2].length <= 5 && !this.fileContent.Site) {
                                this.fileContent.Site = fields[2];
                            }
                            if (fields[3].length >= 3 && fields[3].length <= 10 && !this.fileContent.Location) {
                                this.fileContent.Location = fields[3];
                            }
                            if (!this.fileContent.Name) {
                                this.fileContent.Name = fields.slice(4,fields.length).join("-");
                            }
                        }
                    },
                    error: (error) => {
                        if(error && error.status && error.status == '404'){
                            this.message  = `${gID} not found in EntraID!`;
                        }else{
                            this.message = `Error when trying to search ${gID} in EntraID!`;
                        }
                    }
            });
        }
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false  && !this.errorsInForm()){
            this.addFileContentToList();
        }
        
        //Initiatiate the request data
        var data = new EnableTeamsCAPPhoneRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/enableTeamsCAPPhone')
            .subscribe( response => {
                    this.clearPage();

                    //Display a success notification for 2sec
                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    //Redirect to request history page
                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    //In case of API call error display a popup with information about the error
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );
    }
}