import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { GetUserInfoFileContent, GetUserInfoRequest } from '../../../Models/FileContents';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { environment } from "../../../../environments/environment";
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { booleanToStringConverter,getStatusColor,dateToFormatedLocaleString } from '../../../Helpers/utils';
import { exportToPdf } from '../../RequestsHistory/HistoryDetailsViews/RequestDetailsDialog/requestDetailsDialog.component';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './getUserInfo.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class GetUserInfoComponent{
    
    
    
    

    public fileContentsList = new Array<GetUserInfoFileContent>();
    public informationToShow:Array<InformationToShowSettings> = new Array<InformationToShowSettings>();
    public fileContent = new GetUserInfoFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;

    public selectAll: boolean = false;
    public showResults: boolean = false;

    public user = "";
    public azureData = null;
    public userLicenses = null;
    public oktaData = null;
    public oktaMfaData = null;
    public AdditionalAccountsOkta = {"okta" : [],
                                     "oktaB2B" : []};
    public AdditionalAccountsAzure = [];
    public AdditionalAccountsChecked = 0;
    private AADUserOwnedObjects = null;
    public AADSecurityGroupOwnerships = null;
    public AADSynchronizedSecurityGroupOwnerships = null;
    public AADApplicationOwnerships = null;
    public AADGuestUsers = null;
    public errorOkta = "";
    public errorOktaMfa = "";
    public errorAzureLicenses = "";
    public errorAzure = "";
    public errorAADUserOwnedObjects = "";
    public errorAADGuestUsers = "";
    public errorAdditionalAccountsOkta = "";
    public errorAdditionalAccountsAzure = "";
    public immediateShowSections = ["OKTA USER INFORMATION", "OKTA MFA", "ADDITIONAL ACCOUNTS INFORMATION", "OFFICE365 USER INFORMATION", "USER LICENSES", "AAD SECURITY GROUPS OWNED", "AAD SYNCHRONIZED SECURITY GROUPS OWNED", "AAD APPLICATIONS OWNED", "AAD GUEST USER SPONSORSHIPS"];
    public oktaInfoCollapsed = false;
    public oktaMFACollapsed = false;
    public office365InfoCollapsed = false;
    public additionalAccountsCollapsed = false;
    public AADSecurityGroupOwnershipsCollapsed = false;
    public AADSynchronizedSecurityGroupOwnershipsCollapsed = false;
    public AADApplicationOwnershipsCollapsed = false;
    public AADGuestUsersCollapsed = false;
    public userLicensesCollapsed = false;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, private oktaApiService: OktaApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog){
        this.clearFileContentForms();
    }
      

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
        this.businessRulesService.getSpecificListByKey('INFORMATION-TO-SHOW', 'InformationToShow')
            .subscribe(response => {Object.entries(response).forEach(element => {
                this.informationToShow.push(new InformationToShowSettings(element[1],false));
            });
        });
    }

    changeStatus(index){
        this.informationToShow[index].Enabled = !this.informationToShow[index].Enabled;
    }

    changeSelectAll(){
        this.selectAll = !this.selectAll;
        if(this.selectAll){
            this.informationToShow.forEach(function(element){
                element.Enabled = true;
            });
        }else{
            this.informationToShow.forEach(function(element){
                element.Enabled = false;
            });
        }     
    }

    async getUserLicenses() {
        this.user = this.fileContent.GroupID + "@" + environment.domain;
        await this.graphApiService.getUserLicenses(this.user).subscribe(userLicenses => {
            this.userLicenses = userLicenses;
        },
        error => { 
            if(error && error.status && error.status == '404'){
                this.errorAzureLicenses = `${this.fileContent.GroupID} not found on AzureAD`;
            }
            else {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error }, });
                this.errorAzureLicenses = error.message;
            }
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<GetUserInfoFileContent>();
        this.setBackEmptyForm();
        this.oktaApiService = null;
    }

    public setBackEmptyForm(){
        /*this.informationToShow.forEach(function(element){
            element.Enabled = false;
        });*/
        this.showResults = false;
        this.user = "";
        this.azureData = null;
        this.userLicenses = null;
        this.oktaData = null;
        this.oktaMfaData = null;
        this.AdditionalAccountsOkta = {"okta" : [],
                                       "oktaB2B" : []};
        this.AdditionalAccountsAzure = [];
        this.AdditionalAccountsChecked = 0;
        this.AADUserOwnedObjects = null;
        this.AADSecurityGroupOwnerships = null;
        this.AADSynchronizedSecurityGroupOwnerships = null;
        this.AADApplicationOwnerships = null;
        this.AADGuestUsers = null;
        this.errorOkta = "";
        this.errorOktaMfa = "";
        this.errorAdditionalAccountsOkta = "";
        this.errorAdditionalAccountsAzure = "";
        this.errorAzure = "";
        this.errorAzureLicenses = "";
        this.errorAADUserOwnedObjects = "";
        this.errorAADGuestUsers = "";
        this.oktaInfoCollapsed = false;
        this.oktaMFACollapsed = false;
        this.additionalAccountsCollapsed = false;
        this.office365InfoCollapsed = false;
        this.AADSecurityGroupOwnershipsCollapsed = false;
        this.AADSynchronizedSecurityGroupOwnershipsCollapsed = false;
        this.AADApplicationOwnershipsCollapsed = false;
        this.AADGuestUsersCollapsed = false;
        this.userLicensesCollapsed = false;
    }

    clearFileContentForms(){
        this.fileContent = new GetUserInfoFileContent();
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        this.fileContent.InformationToShow = this.informationToShow.filter(item => item.Enabled).map(item => item.Option);
        var tempObject = new GetUserInfoFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    minSelectedCheckboxes() {
        return this.informationToShow.some(item => item.Enabled)
    }

    showresultsButton(isFormInvalid?) {
        if(isFormInvalid == true){
            return false;
        }
        if (this.informationToShow.some(item => this.immediateShowSections.includes(item.Option) && item.Enabled) && !this.informationToShow.some(item => !this.immediateShowSections.includes(item.Option) && item.Enabled)) {
            return true;
        }
        else {
            return false;
        }
    }

    showAll(){
        this.oktaInfoCollapsed = false;
        this.oktaMFACollapsed = false;
        this.additionalAccountsCollapsed = false;
        this.office365InfoCollapsed = false;
        this.AADSecurityGroupOwnershipsCollapsed = false;
        this.AADSynchronizedSecurityGroupOwnershipsCollapsed = false;
        this.AADApplicationOwnershipsCollapsed = false;
        this.AADGuestUsersCollapsed = false;
        this.userLicensesCollapsed = false;
    }
    
    hideAll(){
        this.oktaInfoCollapsed = true;
        this.oktaMFACollapsed = true;
        this.additionalAccountsCollapsed = true;
        this.office365InfoCollapsed = true;
        this.AADSecurityGroupOwnershipsCollapsed = true;
        this.AADSynchronizedSecurityGroupOwnershipsCollapsed = true;
        this.AADApplicationOwnershipsCollapsed = true;
        this.AADGuestUsersCollapsed = true;
        this.userLicensesCollapsed = true;
    }

    getStatusColorImport(status:string){
        return getStatusColor(status);
    }

    exportToPdfImport(){
        return exportToPdf();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    showRequestResults(){
        this.fileContent.InformationToShow = this.informationToShow.filter(item => item.Enabled).map(item => item.Option)
        let gID = this.fileContent.GroupID.toString() ;
        if (this.fileContent.InformationToShow.includes("ADDITIONAL ACCOUNTS INFORMATION")) {
            this.oktaApiService.getAdditionalAccountsOkta(gID)
                .subscribe(
                    response => {
                        this.AdditionalAccountsOkta = response;
                        this.AdditionalAccountsChecked++;
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.errorAdditionalAccountsOkta = `${gID} not found in Okta!`;
                        }else{
                            this.errorAdditionalAccountsOkta = 'Error when trying to search GroupID in Okta!';
                        }
                    }
            );
            this.graphApiService.getAdditionalAccounts(gID)
                .subscribe(
                    response => {
                        this.AdditionalAccountsAzure = <any>response;
                        this.AdditionalAccountsAzure.map(item => {item; item.accountEnabled = booleanToStringConverter(item.accountEnabled,'No', 'Yes')});
                        this.AdditionalAccountsChecked++;
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.errorAdditionalAccountsAzure = `${this.fileContent.GroupID} not found on AzureAD!`;
                        }else{
                            this.errorAdditionalAccountsAzure= 'Error when trying to search GroupID in AzureAD!';
                        }
                    }
            );
        }
        if (this.fileContent.InformationToShow.includes("OFFICE365 USER INFORMATION") || this.fileContent.InformationToShow.includes("AAD GUEST USER SPONSORSHIPS")) {
            this.graphApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        this.azureData = response;
                        this.azureData.accountEnabled = booleanToStringConverter(this.azureData.accountEnabled,'No', 'Yes');
                        this.azureData.assignedLicenses = booleanToStringConverter(this.azureData.assignedLicenses.length > 0,'No', 'Yes');
                        if (this.fileContent.InformationToShow.includes("AAD GUEST USER SPONSORSHIPS")) {
                            this.graphApiService.getAADGuestUserSponsorships(this.azureData.id)
                                .subscribe(
                                    response => {
                                        this.AADGuestUsers = response;
                                    },
                                    (error) => {
                                        if(error && error.message){
                                            this.errorAADGuestUsers= `Error when trying to search guest user sponsorships in AzureAD: ${error.message}`;
                                        }
                                    }
                            );
                        };
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.errorAzure = `${this.fileContent.GroupID} not found on AzureAD!`;
                        }else{
                            this.errorAzure= 'Error when trying to search GroupID in AzureAD!';
                        }
                    }
            );
        }
        if (this.fileContent.InformationToShow.some(item => item.includes("OWNED"))) {
            this.graphApiService.getAADUserOwnedObjects(gID)
                .subscribe(
                    response => {
                        this.AADUserOwnedObjects = response;
                        if (this.fileContent.InformationToShow.includes("AAD SECURITY GROUPS OWNED")) {
                            this.AADSecurityGroupOwnerships = this.AADUserOwnedObjects.filter(item => item["@odata.type"].includes('group') && item.securityEnabled && !item.onPremisesSyncEnabled);
                        }
                        if (this.fileContent.InformationToShow.includes("AAD SYNCHRONIZED SECURITY GROUPS OWNED")) {
                            this.AADSynchronizedSecurityGroupOwnerships = this.AADUserOwnedObjects.filter(item => item["@odata.type"].includes('group') && item.securityEnabled && item.onPremisesSyncEnabled && !item.mailEnabled);
                        }
                        if (this.fileContent.InformationToShow.includes("AAD APPLICATIONS OWNED")) {
                            this.AADApplicationOwnerships = this.AADUserOwnedObjects.filter(item => item["@odata.type"].includes('application'));
                        }
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.errorAADUserOwnedObjects = `${this.fileContent.GroupID} not found on AzureAD!`;
                        }else{
                            this.errorAADUserOwnedObjects= 'Error when trying to search user owned objects in AzureAD!';
                        }
                    }
            );
        }
        if (this.fileContent.InformationToShow.includes("USER LICENSES")) {
            this.getUserLicenses();
        }
        if (this.fileContent.InformationToShow.includes("OKTA USER INFORMATION") || this.fileContent.InformationToShow.includes("OKTA MFA")) {
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        this.oktaData = response;
                        if (this.fileContent.InformationToShow.includes("OKTA MFA")) {
                            this.oktaApiService.getUserEnrolledFactors(this.oktaData.id)
                                .subscribe(
                                    responseMfa => {
                                        this.oktaMfaData = responseMfa;
                                        this.oktaMfaData.map(item => item.created = dateToFormatedLocaleString(item.created));
                                    },
                                    (error) => {
                                        if(error && error.message){
                                            this.errorOktaMfa = error.message;
                                        }
                                    }
                            );
                        }
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.errorOkta = this.errorOktaMfa = `${gID} not found in Okta!`;
                        }else{
                            this.errorOkta = this.errorOktaMfa = 'Error when trying to search GroupID in Okta!';
                        }
                    }
            );
        }
        this.showResults = true;
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var data = new GetUserInfoRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/getUserInfo')
            .subscribe( response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );        
    }
}
class InformationToShowSettings {
    Option:string = '';
    Tooltip:string= '';
    Enabled:any = false;

    constructor(dbInfo?:any, enabled?: any){
        this.Option = dbInfo ? dbInfo.option : '';
        this.Tooltip = dbInfo ? dbInfo.tooltip : '';
        this.Enabled = enabled ? enabled : false;
    }
}