import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';

import { environment } from "../../environments/environment";

import { RequestItem } from "../Models/RequestItem";
import { RequestContainer } from "../Models/RequestContainer";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/requests";
export const API_VERSION = "/v1";

@Injectable()  
export class RequestContainerService {  
  constructor(private http: HttpClient) { }

  createRequestContainer(data){
    let request = API_BASE_URL + API_NAME + API_VERSION + "/createRequestContainer";
    
    return this.http.post(request, data)
      .pipe(map(response => { return response }));
  }

  sendProductRequest(data, productsEndpoint){
    let request = API_BASE_URL + API_NAME + API_VERSION + productsEndpoint;

    return this.http.post(request, data)
      .pipe(map(response => { return response }));
  }
}