<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST INFORMATION 
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">Display Name</th>
                <td>{{ productCustomHistoryDetails.DisplayName }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Alias</th>
                <td>{{ productCustomHistoryDetails.Alias }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Primary SMTP</th>
                <td>{{ productCustomHistoryDetails.PrimarySMTP }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">MOERA</th>
                <td>{{ productCustomHistoryDetails.MOERA }}</td>
            </tr>
        </tbody>
    </table>
</div>