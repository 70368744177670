<h4>Add Modern Workplace User:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true">Please fill the fields below and
            click the search button</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID" required [pattern]="groupIDPattern" (keyup)="setBackEmptyForm()">
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;"
                    (click)="getLocalITsFromOkta();">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span>
        </div>
        <div class="col-4">
            <label for="skynote">Skynote:</label>
            <div id="skynote">
                <div style="display: inline-block;">
                    <label><input type="radio" id="skynoteOpt1" name="skynote"
                        class="form-control" [value]="true" [(ngModel)]="fileContent.Skynote" (change)="getLocalITGroups();"
                        #skynoteOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="skynoteOpt2" name="skynote"
                        class="form-control" [value]="false" [(ngModel)]="fileContent.Skynote" (change)="getLocalITGroups();"
                        #skynoteOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div  *ngIf="fileContent.Skynote === false" class="col-4">
            <label for="subdelegation">Subdelegation:</label>
            <div id="subdelegation">
                <div style="display: inline-block;">
                    <label><input type="radio" id="subdelegationOpt1" name="subdelegation"
                        class="form-control" [value]="true" [(ngModel)]="fileContent.Subdelegation" (change)="getLocalITGroups();"
                        #subdelegationOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="subdelegationOpt2" name="subdelegation"
                        class="form-control" [value]="false" [(ngModel)]="fileContent.Subdelegation" (change)="getLocalITGroups();"
                        #subdelegationOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="!errorMessage && fileContent.Skynote === false && ((isTopLocalIT === true && isUserInOkta === true) || isAnyLocalITFromOkta === true)">
            <label class="required" for="localITDropdownList">Local IT</label>
            <select id="localITDropdownList" class="form-control" required [(ngModel)]="fileContent.ManagedLocalIT"
                name="localITDropdownList" (change)="getLocalItTeamsGroup($event.target.value);" required #localITDropdownList="ngModel">
                <option hidden disabled selected value></option> -->
                <option *ngFor="let localIT of listLocalIT | orderby: '' : false : true" [value]="localIT">{{localIT}}
                </option>
            </select>
            <div *ngIf="localITDropdownList.invalid && (localITDropdownList.dirty || localITDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="localITDropdownList.errors.required">
                    Local IT is required.
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="!errorMessage && isUserInOkta === true">
            <label for="localAdmin">Local Admin:</label>
            <div id="localAdmin">
                <div style="display: inline-block;">
                    <label><input type="radio" id="localAdminOpt1" name="localAdmin"
                        class="form-control" [value]="true" [(ngModel)]="fileContent.LocalAdmin"
                        #localAdminOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="localAdminOpt2" name="localAdmin"
                        class="form-control" [value]="false" [(ngModel)]="fileContent.LocalAdmin"
                        #localAdminOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="!errorMessage && isUserInOkta === true">
            <label for="releaseRingDropdownList">Release Ring:</label>
            <select id="releaseRingDropdownList" class="form-control" [(ngModel)]="fileContent.ReleaseRing"
                name="releaseRingDropdownList">
                <option *ngFor="let releaseRing of releaseRings | orderby: 'name' : false : true"
                    [value]="releaseRing.code">{{releaseRing.name}}</option>
            </select>
        </div>
        <div class="col-4" *ngIf="!errorMessage && isUserInOkta === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
        <div class="col-4" *ngIf="!errorMessage && isUserInOkta === true">
            <label for="OrgID" class="required">OrgID:</label>
            <input type="text" id="OrgID" name="OrgID" class="form-control"
                [(ngModel)]="fileContent.OrgID" 
                [ngClass]="OrgID.errors?.pattern || OrgID.errors?.required ? 'is-invalid': 'is-valid'" 
                [pattern]="orgIDPattern" [required]="true" #OrgID="ngModel">
            <div *ngIf="OrgID.errors?.pattern">
                <span class="badge badge-danger">OrgID format should be ORGXXXX</span>
            </div>
            <div *ngIf="OrgID.errors?.required">
                <span class="badge badge-danger">This field is required</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h5 class="text-primary" style="white-space: pre-line">{{message}}</h5>
            <h5 *ngIf="!fileContent.Skynote" class="text-warning" style="white-space: pre-line">{{warningMessage}}</h5>
            <h5 *ngIf="fileContent.LocalAdmin" class="text-warning" style="white-space: pre-line">{{adminWarningMessage}}</h5>
            <h5 class="text-danger" style="white-space: pre-line">{{errorMessage}}</h5>
        </div>
    </div>
    <div  *ngIf="!errorMessage && isUserInOkta === true || (fileContentsList && fileContentsList.length > 0)" class="row my-3">
        <div class="col-6">
            <button *ngIf="!errorMessage && isUserInOkta === true" class="btn btn-primary" (click)="hiddenGroups = hiddenGroups ? false : true">Advanced</button>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-end" 
        *ngIf="!errorMessage && isUserInOkta === true  || (fileContentsList && fileContentsList.length > 0)">
                <button type="submit" class="btn btn-primary mr-3"
                    (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                    [disabled]="errorMessage || fileContentForm.invalid || isUserInOkta === false">Add to list</button>
                <button type="submit" class="btn btn-primary"
                    (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                    [disabled]="(errorMessage || fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                    Request</button>
        </div>
    </div>
    <div [hidden]="hiddenGroups" class="row form-group" *ngIf="!errorMessage && isUserInOkta === true && localITTeamsGroup.length > 0">
        <div class="col-12 my-3">
            <label for="groupAvailableFilter">Groups available filter:</label>
            <input type="text" name="groupAvailableFilter" class="form-control w-50" (keyup)="filterGroupsAvailable($event.target.value)"/>
        </div>
        <div class="col-12 col-md-6 flex-column" style="padding-top: 20px;">
            <span class="font-weight-bold">Groups available</span>
            <hr>
            <div *ngFor="let app of localITTeamsGroup">
                <div *ngIf="!applications.includes(app.id)">
                    <label #group for="teamgroup{{app.id}}" data-toggle="tooltip" title="{{app.description}}">
                        <input type="checkbox" id="teamgroup{{app.id}}"
                        (change)="onTeamGroupToggle({id : app.id, displayName : app.displayName}, $event)"
                        [checked]="applications.includes(app.id)"> {{app.displayName}}
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 flex-column mt-4 mt-md-0" style="background: #EAEAEA;padding-top: 20px;">
            <span class="font-weight-bold">Groups added</span>
            <hr>
            <div *ngFor="let app of localITTeamsGroup">
                <div *ngIf="applications.includes(app.id)">
                    <label for="teamgroup{{app.id}}" data-toggle="tooltip" title="{{app.description}}">
                        <!-- <input type="checkbox" id="teamgroup{{app.id}}"
                        (change)="onTeamGroupToggle({id : app.id, displayName : app.displayName}, $event)"
                        [checked]="applications.includes(app.id)">  -->
                        {{app.displayName}}
                    </label>
                </div>
            </div>
        </div>
        <!-- <label for="teamgroup{{app.id}}" data-toggle="tooltip" title="{{app.description}}">
            <input type="checkbox" id="teamgroup{{app.id}}"
                (change)="onTeamGroupToggle({id : app.id, displayName : app.displayName}, $event)"
                [checked]="applications.includes(app.id)"> {{app.displayName}}
        </label> -->
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Skynote:</b> {{ booleanToStringConverterImport(listedFileContent.Skynote, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Subdelegation:</b> {{ booleanToStringConverterImport(listedFileContent.Subdelegation, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Local IT:</b> {{ listedFileContent.ManagedLocalIT }}
                </div>
                <div class="col-4">
                    <b>Local Admin:</b> {{ booleanToStringConverterImport(listedFileContent.LocalAdmin, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Release Ring:</b> {{ listedFileContent.ReleaseRing }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
                <div class="col-4">
                    <b>OrgID:</b> {{ listedFileContent.OrgID }}
                </div>
                <div class="col-12">
                    <b>Add Applications:</b>
                    <div class="col-12">
                        <span *ngFor="let app of listedFileContent.AddApplications">{{ app }}<br /></span>
                    </div>
                </div>
                <div class="col-12">
                    <b>Remove Applications:</b>
                    <div class="col-12">
                        <span *ngFor="let app of listedFileContent.RemoveApplications">{{ app }}<br /></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>