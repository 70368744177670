<br />
<div class="row">
    <div class="col-6">
        <h2>Csv import</h2>
        <div class="row">
            <div class="col-8" *ngIf="_selectedLocalIT !== ''">
                <button type="button" class="btn btn-primary" (click)="importCsvInput.click()">Import csv</button>
            </div>
            <input hidden type="file" multiple accept=".csv" #importCsvInput (change)="parseFromCSV($event)" />
        </div>
    </div>
    <div class="col-6">
        <h2>Csv template</h2>
        <form *ngIf="localITProductsDetails.length > 0 && _selectedLocalIT !== ''" #fileContentForm="ngForm">
            <div class="row form-group">
                <select id="ProductSelect" class="form-control" (change)="productSelected($event.currentTarget.value)"
                    name="productSelect" required>
                    <option option value="" hidden>
                        SELECT A PRODUCT
                    </option>
                    <option *ngFor="let product of localITProductsDetails | orderby: 'ProductName' : false : true"
                        [value]="product.ProductName">{{product.ProductName}}</option>
                </select>
            </div>
        </form>
        <div class="row">
            <div class="col-8"
                *ngIf="(selectedProduct ? selectedProduct.Properties.length !== 0 : false) && _selectedLocalIT !== ''">
                <button type="button" class="mr-1 btn btn-primary" (click)="csvDownload()">Download csv template</button>
                <app-product-help-link [productName]="selectedProduct.ProductName"></app-product-help-link>
            </div>
        </div>
        <div *ngIf="selectedProduct ? selectedProduct.Properties.length < 1 : false">
            This product can't be used with csv import.
        </div>
        <div *ngIf="!(productsDetails.length > 0)">
            Impossible to get product list from DB.
        </div>
        <div *ngIf="_selectedLocalIT === ''">
            Please select a correct LocalIT.
        </div>

    </div>
</div>
<div class="row">
    <div class="col-12">
        <button *ngIf="fileContentsList.length > 0 || csvImportErrors.length > 0" type="button" class="btn btn-primary"
            (click)="clear()">Cancel</button>
        <button
            *ngIf="(fileContentsList.length > 0 && !disableSendButton) && !(quotaExceeded.High > 0 || quotaExceeded.Low > 0)"
            type="button" class="ml-1 btn btn-primary" (click)="sendRequest()">Send
            request</button>

    </div>
</div>

<div *ngIf="successWarnings.length > 0 || mergedWarning">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="successWarnings.length > 0">
    <span *ngFor="let message of successWarnings; let i = index" class="text-success font-weight-bold"
        style="font-size:1.5em;">
        {{ message }} <br />
    </span>
</div>

<div *ngIf="mergedWarning">
    <span class="text-warning font-weight-bold" style="font-size:1.5em;">
        Your requests have been merged for better performance.<br />
    </span>
</div>

<div *ngIf="successWarnings.length > 0 || mergedWarning">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="(csvImportErrors && csvImportErrors.length > 0) || quotaExceeded.Low > 0 || quotaExceeded.High > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div *ngIf="quotaExceeded.Low > 0">
    <span class="text-danger font-weight-bold" style="font-size:1.5em;">
        Your requests exceeds your low quota by {{quotaExceeded.Low}} item(s).<br />
    </span>
</div>

<div *ngIf="quotaExceeded.High > 0">
    <span class="text-danger font-weight-bold" style="font-size:1.5em;">
        Your requests exceeds your high quota by {{quotaExceeded.High}} item(s).<br />
    </span>
</div>

<div *ngIf="quotaExceeded.High > 0 || quotaExceeded.Low > 0">
    <span class="text-danger font-weight-bold" style="font-size:1.5em;">
        Please reduce the number of items in your csv file(s) and import it again.<br />
    </span>
</div>

<div *ngIf="csvImportErrors && csvImportErrors.length > 0">
    <span class="text-danger font-weight-bold" style="font-size:1.5em;">
        REQUESTS IMPORTED WITH ERRORS
    </span>

    <div class="row" *ngFor="let fileContentInError of csvImportErrors; let i = index">
        <a class="col-10" (click)="handleCollapseBtn('fileContentInError' + i + 'expandBtn')" data-toggle="collapse"
            [attr.href]="'#fileContentInError' + i">
            <span style="font-size:1.5em;" class="text-danger">
                <b>{{ fileContentInError.fileName }} Line {{ fileContentInError.csvLine }} </b>
                <i [attr.id]="'fileContentInError' + i + 'expandBtn'" style="vertical-align: bottom"
                    class="material-icons md-36 pointer">chevron_right</i>
            </span>
        </a>
        <i id="{{'removeFileContentInErrorBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
            style="margin-left: 5px;vertical-align: bottom;"
            (click)="removeFileContentInErrorFromList(i)">remove_circle_outline</i>
        <div class=" collapse" [attr.id]="'fileContentInError' + i">
            <div class="row">
                <div class="col-10">
                    <span><b>Product :</b> {{ fileContentInError.productName }}</span>
                </div>
            </div>
            <div *ngFor="let property of fileContentInError.properties; let i = index" class="row">
                <div class="col-10">
                    <span
                        [class]="fileContentInError.error.indexOf(property.Name.toLowerCase()) !== -1 ? 'text-danger' : ''"><b>{{property.Name}}
                            :</b> {{ fileContentInError.row[property.Name.toLowerCase()] }}</span>
                </div>
            </div>
            <div *ngIf="!fileContentInError.properties" class="row">
                <div class="col-10">
                    <span class="text-danger"><b>Error
                            :</b>Unknown product</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="(csvImportErrors && csvImportErrors.length > 0) || quotaExceeded.Low > 0 || quotaExceeded.High > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.fileName }} Line {{ listedFileContent.csvLine }} </b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-10">
                <span><b>Product :</b> {{ listedFileContent.productName }}</span>
            </div>
        </div>
        <div *ngFor="let property of listedFileContent.properties; let i = index" class="row">
            <div class="col-10">
                <span><b>{{property.Name}} :</b> {{ listedFileContent.row[property.Name.toLowerCase()] }}</span>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<br />
<h2>Imported csv results ({{ length }})</h2>
<div class="row">
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [matDatepicker]="fromDatePicker"
            placeholder="From" [value]="fromDate" (dateChange)="fromDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
    </div>
    <div class="col-4" style="display: inline;">
        <input class="form-control" style="width: 70%; float: left;" matInput [matDatepicker]="toDatePicker"
            placeholder="To" [value]="toDate" (dateChange)="toDateChanged($event.target.value)">
        <mat-datepicker-toggle style="float: left;" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
    </div>
    <div>
        <button id='ClearFilters' class="btn btn-cancel" (click)="clearFilters()" [disabled]="clearFiltersDisabled"
            style="display:inline;margin-right:20px;margin-left: 20px;">Clear filters</button>
        <button id='refreshDataButton' class="btn btn-primary" (click)="refreshData(true)"
            [disabled]="refreshButtonDisabled" style="display:inline;">Refresh</button>
    </div>
</div>
<div class="csvtable" style="width: 111%;">
    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
</div>
<br />
<!-- <ng-table _ngcontent-c1 [ngClass]="config.className" [rows]="rows" [columns]="columns"></ng-table> -->
<table class="table dataTable csvtable" ngClass="{{config.className || ''}}" role="grid" style="width: 100%;">
    <thead>
        <tr role="row">
            <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
                (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                {{column.title}}
                <i *ngIf="config && column.sort && column.sort === 'asc'"
                    class="pull-right fa material-icons md-24">expand_less</i>
                <i *ngIf="config && column.sort && column.sort === 'desc'"
                    class="pull-right fa material-icons md-24">expand_more</i>
            </th>
            <th style="width: 130px;">
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td *ngFor="let column of columns">
                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                    [ngTableFiltering]="column.filtering" class="form-control" style="width: 100%;" />
            </td>
            <td></td>
        </tr>
        <tr *ngFor="let row of rows">
            <td *ngFor="let column of columns"
                [ngStyle]="{'color':column.name === 'Status' ? row[column.name] === 'ValidFile' ? '#fbc02d' : row[column.name] === 'InProcess' ? '#1976d2' : row[column.name] === 'Processed' ? '#388e3c' : '#d32f2f' : '', 'font-weight':column.name === 'Status' ? 'bold': ''}">
                {{row[column.name]}}</td>
            <td>
                <button [disabled]="getResultsButtonDisabled || row['Status'] !== 'Processed'" type="button"
                    class="btn btn-primary" (click)="getResults(row['GUID'])">Get results</button>
            </td>
        </tr>
    </tbody>
</table>
<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length"
    [itemsPerPage]="itemsPerPage" [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false"
    (pageChanged)="changePage($event)" (numPages)="numPages = $event">
</pagination>
<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>