import { Component, Input } from '@angular/core';
import { RemoveDistributionListOwnerDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'RemoveDistributionListOwnerHistoryDetails',
    templateUrl: './removeDistributionListOwnerHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class RemoveDistributionListOwnerHistoryDetailsComponent{
    @Input() distributionListDetails: RemoveDistributionListOwnerDetails;
    distributionListInfoCollapsed: boolean = false;
}