import { Component, OnInit } from '@angular/core';
import { HomeService } from '../Services/home.service';
import { OktaAuthService } from '../../app/app.service';
import { environment } from 'environments/environment';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['../app.component.css']
})

export class HomeComponent implements OnInit {
    public serviceState;
    public dateNow;
    public serviceUpdatedDate;
    public requestsQueue;
    public releases;
    public formatedrequestsQueue = {};
    public productAgents = {};
    public formatedserviceState = {
        "services": {},
        "updated": null
    };
    public formatedreleases;
    public totalQueue = 0;
    public serviceStateProperties: Array<string>;
    public itemsProducts: Array<string>;
    public agents: Array<string>;


    constructor(private oauthService: OktaAuthService, private homeService: HomeService) {
        this.homeService.getServiceState().subscribe(response => { this.serviceState = response, this.FormatServiceState(); });
        this.homeService.getRequestsQueue().subscribe(response => { this.requestsQueue = response, this.FormatItemsQueue(); });
        this.homeService.getReleases().subscribe(response => { this.releases = response });
    }

    async ngOnInit() {

    }

    FormatServiceState() {
        let properties = Object.keys(this.serviceState);
        properties.forEach(property => {
            if (property != "LastOperationTime" && property != "Server" && property != "id") {
                this.formatedserviceState.services[property] = this.serviceState[property];
            }
        });
        this.formatedserviceState.updated = (new Date(this.serviceState.LastOperationTime)).toLocaleString(navigator.language);
        this.serviceStateProperties = Object.keys(this.formatedserviceState.services);
    }

    FormatItemsQueue() {
        let items = this.requestsQueue;
        items.sort(function (a, b) {
            var x = a.ProductName.toLowerCase();
            var y = b.ProductName.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        this.totalQueue = items.length;
        items.forEach(item => {
            if (this.formatedrequestsQueue[item["ProductAgent"]]) {
                this.formatedrequestsQueue[item["ProductAgent"]].count++;
                if (this.formatedrequestsQueue[item["ProductAgent"]][item["ProductName"]]) {
                    this.formatedrequestsQueue[item["ProductAgent"]][item["ProductName"]].count++;
                }
                else {
                    this.formatedrequestsQueue[item["ProductAgent"]][item["ProductName"]] = { count: 1 };
                }
            }
            else {
                this.formatedrequestsQueue[item["ProductAgent"]] = { count: 1 };
                this.formatedrequestsQueue[item["ProductAgent"]][item["ProductName"]] = { count: 1 };
            }
        });
        this.agents = Object.keys(this.formatedrequestsQueue);
    }

    GetItemsQueueProducts(agent) {
        if (!agent) {
            return [];
        }
        return Object.keys(this.formatedrequestsQueue[agent]);
    }

    serviceStateUpdated(): boolean {
        if (this.serviceState == null) {
            return false;
        }
        this.serviceUpdatedDate = new Date(this.serviceState.LastOperationTime);
        this.dateNow = new Date();
        return (((this.dateNow - this.serviceUpdatedDate) / 1000) < 600);
    }

    dateToFormatedLocaleString(date) {
        return new Date(date).toLocaleString(navigator.language);
    }
}