<h4>Unlock Okta User:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true">Please fill the user GroupID to unlock in Okta</span> 
    </div>

    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                        [(ngModel)]="fileContent.GroupID"
                        required [pattern]="groupIDPattern"
                        (keyup)="setBackEmptyForm()"/>
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="getUserInOkta();">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span> 
        </div>
        <div class="col-4" *ngIf="isUserInOkta === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    
    <div class="row form-group" *ngIf="isUserInOkta === true">                
        <div class="col-2" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>