import { Component } from '@angular/core';
import { interval, lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-error',
    templateUrl: './errorPage.component.html',
    styleUrls: ['../app.component.css']
})

export class errorPageComponent {
    public num_rows = 5;
    public num_columns = 9;
    public TOTAL_ROWS = 20;
    public TOTAL_COLUMNS = 20;
    public game_ended = false;
    public lose = '';
    public score = 0;
    public nextlevel = false;
    public round = 1;
    public timeout = 100;
    public spaceInvaders = [];
    public tank = {
        column: Math.floor(this.TOTAL_COLUMNS / 2),
        row: this.TOTAL_ROWS - 1
    };
    public direction = 1;
    public tick = 0;
    public bullets = [];
    public error_description = '';

    ngOnInit() {
        this.generateSpaceInvaders();
    }

    getRightmostPosition() { // returns column of rightmost invader
        var rightmostInvaderPosition = 0;
        for (var i = 0; i < this.spaceInvaders.length; i++) {
            if (this.spaceInvaders[i].show && this.spaceInvaders[i].column > rightmostInvaderPosition) {
                rightmostInvaderPosition = this.spaceInvaders[i].column;
            }
        }
        return rightmostInvaderPosition;
    }

    getLeftmostPosition() { // returns column of rightmost invader
        var leftmostInvaderPosition = this.TOTAL_COLUMNS - 1;
        for (var i = 0; i < this.spaceInvaders.length; i++) {
            if (this.spaceInvaders[i].show && this.spaceInvaders[i].column < leftmostInvaderPosition) {
                leftmostInvaderPosition = this.spaceInvaders[i].column;
            }
        }
        return leftmostInvaderPosition;
    }

    getBottommostPosition() { // returns row of the bottommost invader
        var bottommostInvaderPosition = 0;
        for (var i = 0; i < this.spaceInvaders.length; i++) {
            if (this.spaceInvaders[i].show && this.spaceInvaders[i].row > bottommostInvaderPosition) {
                bottommostInvaderPosition = this.spaceInvaders[i].row;
            }
        }
        return bottommostInvaderPosition;
    }


    tankAction(e) {
        //console.log(e);
        if (e.keyCode == 37) {
            console.log('asdf');
            if (this.tank.column > 0) {
                this.tank.column--;
            }
        }
        else if (e.keyCode == 39) {
            if (this.tank.column < this.TOTAL_COLUMNS - 1) {
                this.tank.column++;
            }
        }
        else if (e.keyCode == 32) {
            this.bullets.push({ row: this.tank.row - 1, column: this.tank.column, show: true });
        }
    };

    generateSpaceInvaders() {
        this.nextlevel = false;
        for (var i = 1; i < this.num_rows; i++) {
            for (var j = 1; j < this.num_columns; j++) {
                this.spaceInvaders.push({ row: i, column: j, show: true });
            }
        }

        this.nextlevel = false;
        interval(this.timeout)
            .subscribe(() => !this.nextlevel && !this.game_ended);
    }



    $interval() {
        var i;
        if (this.tick % 4 == 0) {
            if (this.getBottommostPosition() >= this.TOTAL_ROWS - 1) {
                this.lose = "You lost!";
                this.game_ended = true;
            }
            if (this.direction == 1 && this.getRightmostPosition() >= this.TOTAL_COLUMNS - 1) {
                for (i = 0; i < this.spaceInvaders.length; i++) {
                    this.spaceInvaders[i].row++;
                }
                this.direction = -1;
            }
            else if (this.direction == -1 && this.getLeftmostPosition() <= 0) {
                for (i = 0; i < this.spaceInvaders.length; i++) {
                    this.spaceInvaders[i].row++;
                }
                this.direction = 1;
            }
            else {
                for (i = 0; i < this.spaceInvaders.length; i++) {
                    this.spaceInvaders[i].column += this.direction;
                }
            }
        }
        for (i = this.bullets.length - 1; i >= 0; i--) {
            if (this.bullets[i].row < 0) {
                this.bullets.splice(i, 1);
            } else {
                this.bullets[i].row--;
            }
            for (var j = 0; j < this.spaceInvaders.length; j++) {
                if (this.bullets[i]) {
                    if (this.spaceInvaders[j].show && this.bullets[i].row == this.spaceInvaders[j].row &&
                        this.bullets[i].column == this.spaceInvaders[j].column) { // we hit something with a bullet
                        this.bullets[i].show = false;
                        this.spaceInvaders[j].show = false;
                        this.score++;
                        if (this.score / this.round == (this.num_columns - 1) * (this.num_rows - 1)) {
                            this.nextlevel = true;
                            this.timeout = Math.floor(this.timeout / 2);
                            this.round++;
                            this.generateSpaceInvaders();
                        }
                        this.bullets.splice(i, 1);
                    }
                }
            }
        }
        this.tick++;
    };

}

