export class CreateDistributionListDetails{
    DisplayName: string = '';
    Alias: string = '';
    PrimarySMTP: string = '';
    MOERA: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.DisplayName = requestItemOutput.DisplayName ? requestItemOutput.DisplayName : '';
            this.Alias = requestItemOutput.Alias ? requestItemOutput.Alias : '';
            this.PrimarySMTP = requestItemOutput.PrimarySMTP ? requestItemOutput.PrimarySMTP : '';
            this.MOERA = requestItemOutput.MOERA ? requestItemOutput.MOERA : '';
        }
    }
}