import { Component, Input } from '@angular/core';
import { DistributionListMembersDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'DistributionListMembersHistoryDetails',
    templateUrl: './distributionListMembersHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DistributionListMembersHistoryDetailsComponent{
    @Input() distributionListDetails: DistributionListMembersDetails;
    distributionListInfoCollapsed: boolean = false;
}