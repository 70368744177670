<br /><br />
<h4>Get licenses details:</h4>
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <input label="GroupID" type="text" class="form-control" id="groupId" (ngModelChange)="clear();" placeholder="GroupID"
                [(ngModel)]="groupId" name="groupIdInput" required [pattern]="groupIDPattern">
                <small *ngIf="groupIDDisplayName" id="groupIDDisplayName"
                class="form-text text-primary">{{ groupIDDisplayName }}</small>
        </div>
        <div>
            <button type="submit" class="btn btn-primary" (click)="getUserLicenses();"
                [disabled]="(fileContentForm.invalid || getButtonDisabled)">Get
                licenses details</button>
        </div>
    </div>
    <div *ngIf="userLicenses.length > 0" class="licenses-info">
        <span class="license-info-title">Licenses</span>
        <div *ngFor="let license of formatedLicenses; let k = index" class="col-12">
            <div class="col-12">
                <input type="checkbox" ng-model="string" [checked]="license.assigned" disabled="true">
                <span>{{license.displayName}}</span>
                <!-- <button type="button" class="btn btn-primary" data-toggle="button" aria-pressed="false" autocomplete="off">
                    {{license.assigned}}
                  </button> -->
            </div>
        </div>
        <span class="license-info-title">Applications</span>
        <div *ngFor="let servicePlan of formatedApps; let k = index" class="col-12">
            <div class="col-12">
                <input type="checkbox" ng-model="string" [checked]="servicePlan.assigned" disabled="true">
                <span>{{servicePlan.displayName}}</span>
                <span class="license-info">({{servicePlan.container}})</span>
            </div>
        </div>
    </div>
</form>