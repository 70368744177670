import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalITsService } from '../Services/localITs.service';
import { RequestItemService } from '../Services/requestItem.service';

@Injectable()
export class ModifyRequest {
    private requestGuid;
    private requestItem;

    constructor(private route: ActivatedRoute, private requestItemService: RequestItemService, private localITsService: LocalITsService) {
    }

    private getRequestParams(fileContent, localIT) {
        return new Promise((async function (resolve, reject) {
            try {
                this.route.queryParams.subscribe(params => { 
                    this.requestGuid = params['requestGUID'];
                    if (this.requestGuid && localIT != 'SHOW ALL') {
                        this.localITsService.getLocalITsWithShowAllPermission().subscribe(async response => {
                            var showAllLocalITs = response;
                            this.requestItemService.getRequestItemByGuid(this.requestGuid, showAllLocalITs.includes(localIT) ? 'SHOW ALL' : localIT).subscribe(response => {
                                if (response) {
                                    this.requestItem = response;
                                    Object.keys(fileContent).forEach(key=>{
                                        if (this.requestItem.Parameters[key.toUpperCase()] || this.requestItem.Parameters[key.toUpperCase()] == false) {
                                            if (Array.isArray(fileContent[key]) && !Array.isArray(this.requestItem.Parameters[key.toUpperCase()])) {
                                                if (fileContent[key].length > 0) {
                                                    fileContent[key][0] = this.requestItem.Parameters[key.toUpperCase()];
                                                }
                                                else {
                                                    fileContent[key].push(this.requestItem.Parameters[key.toUpperCase()]);
                                                }
                                            }
                                            else {
                                                fileContent[key]=this.requestItem.Parameters[key.toUpperCase()];
                                            }
                                        }
                                    });
                                    
                                    return resolve([fileContent,response.LocalIT]);
                                }
                            });
                        });
                    }
                    else {
                        
                        return resolve(null);
                    }
                });
            } 
            catch (err) {
                return reject(err);
            }
        }).bind(this));
    }

    getRequestParameters(fileContent, localIT) {
        return this.getRequestParams(fileContent, localIT);
    }

}