<h4>Create Azure AD Dynamic Group:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="orgIDDropdownList">OrgID:</label>
            <select id="orgIDDropdownList" class="form-control"
                [(ngModel)]="fileContent.OrgID" name="orgIDDropdownList"
                required #orgIDDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let entity of Entities | orderby: 'OrgID' : false : true" [value]="entity.OrgID" title="{{entity.Name}}">{{entity.OrgID}}</option>
            </select>
            <div *ngIf="orgIDDropdownList.invalid && (orgIDDropdownList.dirty || orgIDDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="orgIDDropdownList.errors.required">
                    OrgID is required.
                </div>
            </div>
            <div *ngIf="entitiesError" class="alert alert-danger">
                {{entitiesError}}
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="groupDisplayNameInput">Group Display Name:</label>
            <input type="text" id="groupDisplayNameInput" class="form-control" [pattern]="groupDisplayNamePattern"
                [(ngModel)]="fileContent.GroupDisplayName" name="groupDisplayNameInput" required #groupDisplayNameInput="ngModel">
            <div *ngIf="groupDisplayNameInput.invalid && (groupDisplayNameInput.dirty || groupDisplayNameInput.touched)" class="alert alert-danger">
                <div *ngIf="groupDisplayNameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="groupDisplayNameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
            </div>
        </div>
        <div class="col-4">
            <span style="color:grey;font-weight:bold;font-style:italic">
                The naming convention is &lt;ORGID&gt;-DG-&lt;Group Display Name&gt;, just enter the &lt;Group Display Name&gt; part. The prefix {{fileContent.OrgID}}-DG- will be automatically added.
            </span>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupDescriptionInput">Group Description:</label>
            <input type="text" id="groupDescriptionInput" class="form-control" [pattern]="groupDescriptionPattern"
                [(ngModel)]="fileContent.GroupDescription" name="groupDescriptionInput" required #groupDescriptionInput="ngModel">
            <div *ngIf="groupDescriptionInput.invalid && (groupDescriptionInput.dirty || groupDescriptionInput.touched)" class="alert alert-danger">
                <div *ngIf="groupDescriptionInput.errors.required">
                    Description is required.
                </div>
                <div *ngIf="groupDescriptionInput.errors.pattern">
                    Description contains an invalid character.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="additionalGroupOwnerInput0">Group Owners:</label>
            <div *ngFor="let additionalGroupOwner of fileContent.GroupOwners; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'additionalGroupOwnerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.GroupOwners[i]" name="{{'additionalGroupOwnerInput' + i}}">
                    <i *ngIf="isFirst" id="additionalGroupOwnerAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalGroupOwner()">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'additionalGroupOwnerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalGroupOwner(i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-12">
            <label class="required">Filter Rules:</label>
            <div *ngFor="let rule of rules; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <div class="col-2" [ngStyle]="{'padding-left':'0px'}">
                        <label *ngIf="isFirst" for="ruleAndOrDropdownList0">And/Or</label>
                        <select id="{{'ruleAndOrDropdownList' + i}}" class="form-control"
                            [(ngModel)]="rules[i].AndOr" name="{{'ruleAndOrDropdownList' + i}}" [disabled]="isFirst"
                            required>
                            <option *ngFor="let op of andOr | orderby: '' : false : true" [value]="op" >{{op}}</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <label *ngIf="isFirst" for="rulePropertyDropdownList0">Property</label>
                        <select id="{{'rulePropertyDropdownList' + i}}" class="form-control" [(ngModel)]="rules[i].Property"
                            name="{{'rulePropertyDropdownList' + i}}" required (change) = "constructFilterRules()">
                            <option *ngFor="let property of ruleProperties | orderby: 'name' : false : true" 
                                [value]="property.value">{{property.name}}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <label *ngIf="isFirst" for="ruleOperatorDropdownList0">Operator</label>
                        <select id="{{'ruleOperatorDropdownList' + i}}" class="form-control" [(ngModel)]="rules[i].Operator"
                            name="{{'ruleOperatorDropdownList' + i}}" required (change) = "constructFilterRules()">
                            <option *ngFor="let operator of ruleOperators | orderby: '' : false : true" 
                                [value]="operator">{{operator}}</option>
                        </select>
                    </div>
                    <div class="col-4" [ngStyle]="{'padding-right':'0px'}">
                        <label *ngIf="isFirst" for="ruleValueInput0">Value</label>
                        <input type="text" id="{{'ruleValueInput' + i}}" class="form-control"
                            [(ngModel)]="rules[i].Value" name="{{'ruleValueInput' + i}}" required (change) = "constructFilterRules()">
                    </div>
                    <i *ngIf="isFirst && rules.length < 5" id="ruleAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px; margin-top: 35px;" (click)="addRule()">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'ruleRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeRule(i)">remove_circle_outline</i>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-11">
            <textarea rows="1" id="filterRulesInput" name="filterRulesInput" class="form-control"
                placeholder="Click here to see the Filter Rules or Edit if the Filter Rules constructed with the rule builder don't meet your needs."
                [(ngModel)]="fileContent.FilterRules" required #filterRulesInput="ngModel" [disabled]="rules">
            </textarea>
        </div>
        <div class="col-1">
            <button type="submit" class="btn btn-primary"
                (click)="editRule();">Edit
            </button>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-11" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupDisplayName }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>OrgID:</b> {{ listedFileContent.OrgID }}
                </div>
                <div class="col-4">
                    <b>Group Display Name:</b> {{ listedFileContent.GroupDisplayName }}
                </div>
                <div class="col-4">
                    <b>Group Description:</b> {{ listedFileContent.GroupDescription }}
                </div>
                <div class="col-4">
                    <b>Group Owners:</b>
                    <div *ngFor="let owner of listedFileContent.GroupOwners">
                        {{ owner }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
                <div class="col-8">
                    <b>Filter Rules:</b> {{ listedFileContent.FilterRules }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>