import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';
import { CreateAADDynamicGroupFileContent, CreateAADDynamicGroupRequest, CreateAADDynamicGroupExpression } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { removeArrayEmptyValues } from 'app/Helpers/utils';

@Component({
    selector: 'BodyContent',
    templateUrl: './createAADDynamicGroup.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateAADDynamicGroupComponent {
    
    
    

    public fileContentsList = new Array<CreateAADDynamicGroupFileContent>();
    public fileContent = new CreateAADDynamicGroupFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public andOr = [ "and", "or"];
    public ruleOperators;
    public ruleProperties;
    public rules = new Array<CreateAADDynamicGroupExpression>(new CreateAADDynamicGroupExpression);
    public topLocalIT:Array<string> =  new Array<string>();
    public Entities:Array<object> = new Array<object>();
    public entitiesError: String = '';
    public groupDisplayNamePattern: string = inputValidationHelper.groupDisplayNameRegEx;
    public groupDescriptionPattern: string = inputValidationHelper.groupDescriptionRegEx;

    public testUserProfile: any = null;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, private webAdministrationService : WebAdministrationService, private localITsService: LocalITsService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.localITsService.getLocalITsWithManageAllPermission().subscribe( response => {
            this.topLocalIT = response;
            this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.entitiesError = '';
                this.fileContent.OrgID = '';
                if (this.topLocalIT.indexOf(this.selectedLocalIT as string) !== -1) {
                    this.webAdministrationService.getEntities({ Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                }
                else {
                    this.webAdministrationService.getEntities({ ITCard: this.selectedLocalIT, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                        if (this.Entities.length == 0) {
                            this.entitiesError = `Make sure ITCard ${this.selectedLocalIT} is added in Org-IT for the entities ${this.selectedLocalIT} is allowed to manage.`
                        }
                        else {
                            this.fileContent.OrgID = this.Entities[0]["OrgID"]
                        }
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                }
            });
        });
        this.businessRulesService.getSpecificListByKey('DYNAMIC-GROUP-RULES', 'Properties')
            .subscribe(response => {this.ruleProperties = response;});
        this.businessRulesService.getSpecificListByKey('DYNAMIC-GROUP-RULES', 'Operators')
            .subscribe(response => {this.ruleOperators = response;});
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateAADDynamicGroupFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new CreateAADDynamicGroupFileContent();
        this.rules = new Array<CreateAADDynamicGroupExpression>(new CreateAADDynamicGroupExpression);
    }

    handleCollapseBtn(id) {
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addAdditionalGroupOwner() {
        this.fileContent.GroupOwners.push('');
    }

    removeAdditionalGroupOwner(index) {
        this.fileContent.GroupOwners.splice(index, 1);
    }

    constructFilterRules() {
        this.fileContent.FilterRules = '';
        this.rules.forEach (rule => {
            if (rule.Property && rule.Operator && rule.Value) {
                rule.Value = rule.Value.replace(/"/g,'');
                if (["-in","-notIn"].includes(rule.Operator)) {
                    rule.Value = rule.Value.replace(/"/g,'');
                    rule.Value = rule.Value.replace(/^\[/,'');
                    rule.Value = rule.Value.replace(/\]/,'');
                    let values = rule.Value.split(',').map(val => '"' + val.trim() + '"');
                    rule.Value = '[' + values.join(',') + ']';
                }
                else {
                    rule.Value = '"' + rule.Value.trim() + '"';
                }
                this.fileContent.FilterRules += ' ' + (rule.AndOr ? rule.AndOr : '') + ' (' + rule.Property + ' ' + rule.Operator + ' ' + rule.Value + ')';
                this.fileContent.FilterRules = this.fileContent.FilterRules.trim();
            }
        });
    }

    editRule() {
        this.rules = null;
    }

    addRule() {
        this.rules.push(new CreateAADDynamicGroupExpression("and"));
    }

    removeRule(index) {
        this.rules.splice(index, 1);
    }

    addFileContentToList() {
        var tempObject = new CreateAADDynamicGroupFileContent(this.fileContent);
        tempObject.GroupOwners = removeArrayEmptyValues(tempObject.GroupOwners);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var fileContentsList = [...this.fileContentsList];
        this.clearPage();
        for (let i = 0; i < fileContentsList.length; i++)  {
            let data = { ...fileContentsList[i] };
            data["localIT"] = this.selectedLocalIT;
        
            this.graphApiService.createAADDynamicGroup(data)
                .subscribe( response => {
                    if (i == 0) {
                        let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                            duration: 2000,
                            verticalPosition: "bottom",
                            horizontalPosition: "right",
                            panelClass: ['bg-success', 'font-weight-bold'],                  
                        });

                        successSnackBar.afterDismissed().subscribe(null, null, () => {
                            this.router.navigate(['/history']);
                        });
                    }
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );
        }
    }
}