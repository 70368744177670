<div *ngIf="error_description">
    {{error_description}}
</div>
<div *ngIf="!error_description" ng-app="SIApp">
    <div (keyup)="tankAction($event)" tabindex="-1" ng-controller="SICtrl">
        <div id="game-area">
            Score: {{score}}
            <img class="space-invader" *ngFor="let si of spaceInvaders" [hidden]="!si.show"
                [ngStyle]="{'left.%': si.column*5, 'top.%': si.row*5}" src="assets/images/space_invader.png">
            <img class="tank" src="assets/images/tank.png" [ngStyle]="{'left.%': tank.column*5, 'top.%': tank.row*5}">
            <img class="bullet" *ngFor="let bullet of bullets" [hidden]="!bullet.show" src="assets/images/bullet.png"
                [ngStyle]="{'left.%': bullet.column*5, 'top.%': bullet.row*5}">
            <div class="game-ended" [hidden]="!game_ended">{{lose}}</div>
            <div class="error-message">Sorry, the PEO is unavailable, it will be back shortly. In the meantime press the
                space key and have fun.</div>
        </div>
    </div>
</div>