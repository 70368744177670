import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { LocalItCard, LocalItCardQuota } from "../Models/LocalItCard";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/localITs";
export const API_VERSION = "/v1";

@Injectable()  
export class LocalITsService {  
  constructor(private http: HttpClient) { }

  getLocalITPilots() : Observable<string[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITPilots';
    return this.http.get(request) as Observable<string[]>;
  }

  getLocalITsWithShowAllPermission() : Observable<string[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITsWithShowAllPermission';
    return this.http.get(request) as Observable<string[]>;
  }

  getLocalITsWithManageAllPermission() : Observable<string[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITsWithManageAllPermission';
    return this.http.get(request) as Observable<string[]>;
}

  getAllLocalIT() : Observable<LocalItCard[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getAllLocalIT';
    return this.http.get(request) as Observable<LocalItCard[]>;
  }

  getAllLocalITNames() : Observable<LocalItCard[]>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getAllLocalITNames';
    return this.http.get(request) as Observable<LocalItCard[]>;
  }

  getLocalITByName(Name) : Observable<LocalItCard>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITByName';
    let params = new HttpParams();
    params = params.append('Name', Name);
    return this.http.get(request,  {params: params}) as Observable<LocalItCard>;
  }

  getLocalITProducts() : Observable<any>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITProducts';
    return this.http.get(request) as Observable<any>;
  }

  getLocalITQuotaByName(Name) : Observable<LocalItCardQuota>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalITQuotaByName';
    let params = new HttpParams();
    params = params.append('Name', Name);
    return this.http.get(request,  {params: params}) as Observable<LocalItCardQuota>;
  }

  manageLocalITCardMembers(data):Observable<Object> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/manageLocalITCardMembers';
    return this.http.post(request,data)
      .pipe(map(response => { return response }));
  }

  manageLocalITCard(data):Observable<Object>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/manageLocalITCard';
    return this.http.post(request,data)
      .pipe(map(response => { return response }));
  }

}