import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { SetUserRingFileContent, SetUserRingRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { GraphApiService } from '../../../Services/graphApi.service';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './setUserRing.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class SetUserRingComponent{
    public fileContentsList = new Array<SetUserRingFileContent>();
    public fileContent = new SetUserRingFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public releaseRings:Array<string> = new Array<string>();
    public groupIdNoAdminPattern: string = inputValidationHelper.groupIdNoAdminFormatRegEx;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb) {
                    this.fileContent = cb[0];
                    this.getAADUser();
                }
            });
        });
        this.businessRulesService.getSpecificListByKey('RELEASE-RINGS', 'ReleaseRings')
            .subscribe(response => {this.releaseRings = response;});
    }

    ngOnDestroy(){
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<SetUserRingFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new SetUserRingFileContent();
    }

    public setBackEmptyForm() {
        this.fileContent.GroupIDDisplayName = '';
        this.fileContent.GroupIDDisplayNameError = '';
        this.fileContent.ExtensionAttribute11 = '';
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    addFileContentToList(){
        var tempObject = new SetUserRingFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }
    
    getAADUser() {
        if (this.fileContent.GroupID) {
            let user = null;
            this.graphApiService.getUser(this.fileContent.GroupID.toString()).subscribe(
                response => {
                    user = response;
                    if (user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase() != "PERSONAL") {
                        this.fileContent.GroupIDDisplayNameError = `The mailbox type is not supported, it's a [${user.onPremisesExtensionAttributes.extensionAttribute11}] mailbox`;
                    }
                    else {
                        this.fileContent.GroupIDDisplayName = user.displayName;
                        this.fileContent.GroupIDDisplayNameError = '';
                        this.fileContent.ExtensionAttribute11 = user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase();
                    }
                },
                error => {
                    if (error && error.status && error.status == '404') {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on AzureAD!';
                    }
                    else {
                        this.fileContent.GroupIDDisplayNameError = 'Error when trying to check the user!';
                    }
                    this.fileContent.GroupIDDisplayName = '';
                }
            )
        }
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError){
            this.addFileContentToList();
        }
        var fileContentList = this.fileContentsList.map(({ GroupIDDisplayName,GroupIDDisplayNameError,ExtensionAttribute11, ...item }) => item)
        this.clearPage();
        for (let i = 0; i < fileContentList.length; i++)  {
            let data = { ...fileContentList[i] };
            data["localIT"] = this.selectedLocalIT;

            this.graphApiService.setUserRing(data)
                .subscribe( response => {
                    if (i == 0) {
                        let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                            duration: 2000,
                            verticalPosition: "bottom",
                            horizontalPosition: "right",
                            panelClass: ['bg-success', 'font-weight-bold'],                  
                        });

                        successSnackBar.afterDismissed().subscribe(null, null, () => {
                            this.router.navigate(['/history']);
                        })
                    }
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );
        }
    }
}