<h4>Remove Teams Phone System:</h4>

<form #requestForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event"
                [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <div class="row form-group">
        <div class="col-2" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(requestForm.invalid); requestForm.reset();"
                [disabled]="(requestForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>