import { Component, Input } from '@angular/core';
import { DistributionListMailAddressesDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'DistributionListMailAddressesHistoryDetails',
    templateUrl: './distributionListMailAddressesHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DistributionListMailAddressesHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: DistributionListMailAddressesDetails;
    productCustomInfoCollapsed: boolean = false;
}