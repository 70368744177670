<h4>Remove Mailbox Delegation from Members:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIdOrMailInputField label="Group ID or email address" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIdOrMailInputField>
        </div>
        <div class="col-4">
            <label for="fullAccessMembersInput">Remove Full Access for:</label>
            <div *ngFor="let additionalMember of fileContent.FullAccessUsers; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'fullAccessMembersInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.FullAccessUsers[i]" name="{{'fullAccessMembersInput' + i}}"
                        [ngClass]="{'has-danger-forced':(fullAccessMembersInput.invalid && (fullAccessMembersInput.dirty || fullAccessMembersInput.touched || fullAccessMembersInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #fullAccessMembersInput="ngModel">
                    <i *ngIf="isFirst" id="fullAccessMembersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.FullAccessUsers)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'fullAccessMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.FullAccessUsers, i)">remove_circle_outline</i>
                    <div *ngIf="fullAccessMembersInput.invalid && (fullAccessMembersInput.dirty || fullAccessMembersInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="fullAccessMembersInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="permissionToAddInput">Remove permission for Full Access users:</label>
            <div id="permissionToAddInput">
                <div class="col-6" style="display: inline-block;">
                    <label><input type="radio" id="permissionToAddRadioButtonOpt1" name="permissionToAddRadioButton" (change)="clearValues();"
                        class="form-control" value="SendAs" [(ngModel)]="fileContent.PermissionToAdd"
                        #permissionToAddRadioButtonOpt1="ngModel">Send As</label>
                </div>
                <div class="col-6" style="display: inline-block;">
                    <label><input type="radio" id="permissionToAddRadioButtonOpt2" name="permissionToAddRadioButton" (change)="clearValues();"
                        class="form-control" value="SendOnBehalf" [(ngModel)]="fileContent.PermissionToAdd"
                        #permissionToAddRadioButtonOpt2="ngModel">Send On Behalf</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div *ngIf="fileContent.PermissionToAdd == ''" class="col-4">
            <label for="sendAsMembersInput">Remove Send As for:</label>
            <div *ngFor="let additionalMember of fileContent.SendAsUsers; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'sendAsMembersInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.SendAsUsers[i]" name="{{'sendAsMembersInput' + i}}"
                        [ngClass]="{'has-danger-forced':(sendAsMembersInput.invalid && (sendAsMembersInput.dirty || sendAsMembersInput.touched || sendAsMembersInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #sendAsMembersInput="ngModel">
                    <i *ngIf="isFirst" id="sendAsMembersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.SendAsUsers)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'sendAsMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.SendAsUsers, i)">remove_circle_outline</i>
                    <div *ngIf="sendAsMembersInput.invalid && (sendAsMembersInput.dirty || sendAsMembersInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="sendAsMembersInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>           
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="fileContent.PermissionToAdd == ''" class="col-4">
            <label for="sendOnBehalfMembersInput">Remove Send On Behalf for:</label>
            <div *ngFor="let additionalMember of fileContent.SendOnBehalfUsers; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'sendOnBehalfMembersInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.SendOnBehalfUsers[i]" name="{{'sendOnBehalfMembersInput' + i}}"
                        [ngClass]="{'has-danger-forced':(sendOnBehalfMembersInput.invalid && (sendOnBehalfMembersInput.dirty || sendOnBehalfMembersInput.touched || sendOnBehalfMembersInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #sendOnBehalfMembersInput="ngModel">
                    <i *ngIf="isFirst" id="sendOnBehalfMembersInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.SendOnBehalfUsers)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'sendOnBehalfMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.SendOnBehalfUsers, i)">remove_circle_outline</i>
                    <div *ngIf="sendOnBehalfMembersInput.invalid && (sendOnBehalfMembersInput.dirty || sendOnBehalfMembersInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="sendOnBehalfMembersInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>            
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="((fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b> <span *ngIf="listedFileContent.GroupIDDisplayName"> ({{ listedFileContent.GroupIDDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ listedFileContent.GroupID }}
            </div>
            <div class="col-4">
                <b>Full Access Members:</b>
                <div *ngFor="let additionalMember of listedFileContent.FullAccessUsers">
                    {{ additionalMember }}
                </div>
            </div>
            <div class="col-4">
                <b>Send As Members:</b>
                <div *ngFor="let additionalMember of listedFileContent.SendAsUsers">
                    {{ additionalMember }}
                </div>
            </div>
            <div class="col-4">
                <b>Send On Behalf Members:</b>
                <div *ngFor="let additionalMember of listedFileContent.SendOnBehalfUsers">
                    {{ additionalMember }}
                </div>
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div>
        </div>
    </div>
</div>