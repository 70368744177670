import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { UnlockOktaUserFileContent, UnlockOktaUserRequest } from '../../../Models/FileContents';


import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './unlockOktaUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UnlockOktaUserComponent{
    
    
    

    public fileContentsList = new UnlockOktaUserFileContent();
    public fileContent = new UnlockOktaUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public isUserInOkta:boolean = false;
    public howToUseInfo:boolean = true;

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;

    // public testUserProfile: any = null;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private graphApiService: GraphApiService, 
        private localITsService: LocalITsService,
        private oktaApiService: OktaApiService, 
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent  = new UnlockOktaUserFileContent();
                this.isUserInOkta = false;
                this.localITNotFound = '';
            });
    }

    ngOnDestroy(){
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new UnlockOktaUserFileContent();
    }

    clearFileContentForms(){
        this.fileContent = new UnlockOktaUserFileContent();
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        var tempObject = new UnlockOktaUserFileContent(this.fileContent);
        this.fileContentsList =  tempObject;
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        //this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        //var data = new UnlockOktaUserRequest();
        var data = this.fileContentsList;
        //data = this.fileContentsList;
        data["localIT"] = this.selectedLocalIT;
        
        //call the post graph api
        this.oktaApiService.unlockOktaUser(data)
            .subscribe(
                response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    getUserInOkta(){
        let gID = this.fileContent.GroupID.toString() ;
        if(gID){
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        if(response.status === "LOCKED_OUT"){
                            this.isUserInOkta = true;
                            this.fileContent.OktaUserID = response.id
                            this.localITNotFound = `User ${response.displayName} is locked in Okta`
                        }
                        else{
                            this.localITNotFound  = `The user ${gID} is not locked out. Current status: ${response.status}`;
                        }
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.localITNotFound  = `${gID} not found in Okta!`;
                        }else{
                            this.localITNotFound = `Error when trying to search ${gID} in Okta!`;
                        }
                    }
                );
        }
    }

    public setBackEmptyForm(){
        this.fileContent.OktaUserID = '';
        this.isUserInOkta = false;
        this.localITNotFound = '';
    }
} 