import { Component, NgModule} from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { RemoveAzureADDeviceFileContent,  RemoveAzureADDeviceRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './removeAzureADDevice.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class RemoveAzureADDeviceComponent{
    
    
    
    

    public devices;
    public fileContentsList = new Array<RemoveAzureADDeviceFileContent>();
    public fileContent = new RemoveAzureADDeviceFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public isUserInAAD:boolean = false;
    public howToUseInfo:boolean = true;
    public i = 0;
    public selectAll: boolean = false;
    public errorMessage = '';
    public message = '';
    public warningMessage = '';
    public groupIDPattern:string = inputValidationHelper.groupIdNoAdminFormatRegEx
   
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private graphApiService: GraphApiService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog,
        private modifyRequest: ModifyRequest){
            this.clearFileContentForms();
    }
    
    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe( updatedLocalIT => {
            this.clearFileContentForms();
            this.fileContentsList = new Array<RemoveAzureADDeviceFileContent>();
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb) {
                    this.getOwnedDevices((function(cb1) {
                        this.devices.map(device => {
                            if (this.fileContent.Devices.map(item => item.toUpperCase()).includes(device.displayName.toUpperCase())) {
                                device.Enabled=true
                            } 
                            else {
                                device
                            }
                        });
                    }).bind(this));
                }
            });
        });
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
    }

    ngOnDestroy() {
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearFileContentForms() {
        this.fileContent = new RemoveAzureADDeviceFileContent();
        this.setBackEmptyForm();
    }

    public setBackEmptyForm() {
        this.localITNotFound = '';
        this.isUserInAAD = false;
        this.devices = [];
        this.errorMessage = '';
        this.message = '';
        this.warningMessage = '';
        this.selectAll = false;
        this.fileContent.Devices = [];
        this.howToUseInfo = true;
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    addFileContentToList() {
        var tempObject = new RemoveAzureADDeviceFileContent(this.fileContent);
        delete tempObject.GroupIDDisplayName;
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && !this.errorsInForm()) {
            this.addFileContentToList();
        }
        let button = document.getElementById("sendButton") as HTMLButtonElement;
        button.disabled = true;
        for (let i = 0; i < this.fileContentsList.length; i++)  {
            let data = { ...this.fileContentsList[i] };
            data["localIT"] = this.selectedLocalIT;
            
            this.graphApiService.removeAzureADDevice(data)
                .subscribe( response => {
                    }, 
                    error => {
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                    }
                );
        }
        let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
            duration: 2000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: ['bg-success', 'font-weight-bold'],                  
        });

        successSnackBar.afterDismissed().subscribe(null, null, () => {
            this.router.navigate(['/history']);
        })
    }

    changeSelectAll(){
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            for (let i = 0; i < this.devices.length; i++) {
                if (!(this.devices[i].isManaged || this.devices[i].hasOwnProperty('serialNumber') || this.devices[i].Enabled)) {
                    this.changeStatus(i);
                }
            }
        }
        else {
            this.devices.forEach(function(element) {
                element.Enabled = false;
            });
            this.fileContent.Devices = [];
        }
           
    }

    errorsInForm() {
        return (this.errorMessage || !this.isUserInAAD || this.fileContent.Devices.length == 0 || this.devices.filter(item => !(item.isManaged || item.hasOwnProperty('serialNumber'))).every(device => device.Enabled == false));
    }

    changeStatus(i) {
        this.devices[i].Enabled = !this.devices[i].Enabled;
        if (this.devices[i].Enabled)
            this.fileContent.Devices.push(this.devices[i].displayName);
        else {
            var index = this.fileContent.Devices.indexOf(this.devices[i].displayName);
            this.fileContent.Devices.splice(index, 1);
        }
    }
    
    // Get the owned devices
    getOwnedDevices(callback) {
        let gID = this.fileContent.GroupID.toString();
        this.howToUseInfo = false;
        if (gID) {
            this.graphApiService.getAADUserOwnedDevices(gID)
                .subscribe(
                    response => {
                        this.devices = response;
                        this.isUserInAAD = true;
                        if (this.devices.length == 0) {
                            this.errorMessage = 'No Local devices found'
                        }
                        if (this.devices.some(item => item.isManaged)) {
                            this.warningMessage = "Devices marked in orange are still Intune managed and have to be removed from intune first."
                        }
                        if (this.devices.some(item => item.hasOwnProperty('serialNumber') && !item.isManaged)) {
                            this.message = "Devices marked in brown are still Intune enrolled or in an enrollment process and have to be removed from intune autopilot first."
                        }
                        if (callback) callback(this.devices);
                    },
                    (error) => {
                        if (error && error.status && error.status == '404') {
                            this.localITNotFound  = `${gID} does not exist in Azure AD`;
                        } else {
                            this.localITNotFound = error.error;
                        }
                        this.devices = [];
                        this.fileContent.GroupID = '';
                        if (callback) callback(this.devices);
                    }
                );
        }
    }
} 