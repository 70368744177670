import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { GraphApiService } from '../../../Services/graphApi.service';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

import { ChangeMailboxPrimarySMTPFileContent, ChangeMailboxPrimarySMTPRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';

import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './changeMailboxPrimarySMTP.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class ChangeMailboxPrimarySMTPComponent{
    
    
    
    
    
    public requestContainer:any = {};
    public fileContentsList = new Array<ChangeMailboxPrimarySMTPFileContent>();
    public fileContent = new ChangeMailboxPrimarySMTPFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';

    public isUserInAAD:boolean = false;
    public isRoomOrFunctional:boolean = false;
    public userNotFound:string = '';
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private graphApiService: GraphApiService, 
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog,
        private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb) {
                    this.fileContent = cb[0];
                    this.getUserInAAD();
                }
            });
        });
    }

    ngOnDestroy(){
        
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<ChangeMailboxPrimarySMTPFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new ChangeMailboxPrimarySMTPFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new ChangeMailboxPrimarySMTPFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError){
            this.addFileContentToList();
        }

        //Initiatiate the request data
        var data = new ChangeMailboxPrimarySMTPRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/changeMailboxPrimarySMTP')
            .subscribe( response => {
                            this.clearPage();

                            //Display a success notification for 2sec
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            //Redirect to request history page
                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            //In case of API call error display a popup with information about the error
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    getUserInAAD(){
        let gID = this.fileContent.GroupID.toString() ;
        let userProfile = null;
        if(gID){
            this.graphApiService.getUser(gID)
                .subscribe(
                    response => {
                        userProfile = response;
                        this.fileContent.GroupIDDisplayName = userProfile.displayName;
                        this.userNotFound = '';
                        this.isUserInAAD =  true;
                        
                        if(userProfile.onPremisesExtensionAttributes.extensionAttribute11?.match(/ROOM/gi) || (userProfile.onPremisesExtensionAttributes.extensionAttribute11)?.toUpperCase() === "FUNCTIONAL"){
                            this.isRoomOrFunctional = true;
                        }
                        else{
                            this.isRoomOrFunctional = false;
                        }
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.userNotFound  = `${gID} not found in Azure AD!`;
                        }else{
                            this.userNotFound = `Error when trying to search ${gID} in Azure AD!`;
                        }
                    }
                );
        }
    }

    public setBackEmptyForm(){
        this.isUserInAAD = false;
        this.isRoomOrFunctional = false;
        this.userNotFound = '';
        this.fileContent.GroupIDDisplayName = '';
    }
}