<h4>Change DistributionList Owner:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="listIDInput">DistributionList ID:</label>
            <input type="text" id="listIDInput" name="listIDInput" class="form-control"
                    [(ngModel)]="fileContent.ListID" #listIDInput="ngModel" required>

            <div *ngIf="listIDInput.invalid && (listIDInput.dirty || listIDInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="listIDInput.errors.required">
                    DistributionList Id is required.
                </div>          
            </div>            
        </div>
        <div class="col-4">
            <GroupIDInputField label="Owner GID" (groupIDDisplayNameChange)="fileContent.OwnerDisplayName=$event" [(groupID)]="fileContent.Owner"></GroupIDInputField>    
        </div>
        <div class="col-3">
            <label for="ownerSendAsInput">Apply Send As:</label>
            <div id="ownerSendAsInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="ownerSendAsInputOpt1" name="ownerSendAsInput" class="form-control" [value]="true" [(ngModel)]="fileContent.OwnerSendAs" #ownerSendAsInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="ownerSendAsInputOpt2" name="ownerSendAsInput" class="form-control" [value]="false" [(ngModel)]="fileContent.OwnerSendAs" #ownerSendAsInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
        <div class="col-1 offset-4">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.ListID }}</b> - 
            <b style="color: #0275d8">{{ listedFileContent.Owner }} <span *ngIf="listedFileContent.OwnerDisplayName">({{ listedFileContent.OwnerDisplayName }})</span></b>
            <i id="{{'fileContentRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>            
        </span>
    </a>
</div>