<br /><br />
<h4>Add Zscaler Firewall:</h4>
<div class="center nj-spinner" *ngIf="isWait">
    <mat-spinner diameter=50 mode="indeterminate" color="primary">
    </mat-spinner>
</div>
<br /><br />
<form [formGroup]="addZscalerFirewallRequestForm">
    <div class="row form-group">
        <div class="col-4">
            <label for="DestinationType" class="required">Destination Type:</label>
            <select *ngIf="destinationTypes.length > 0" id="type" formControlName="DestinationType" class="form-control"
                required>
                <option disabled value="null">Select a destination type</option>
                <option *ngFor="let destinationType of destinationTypes" [value]="destinationType.Name">
                    {{destinationType.Name}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="Destination" class="required">Add destination:</label>
            <input id="Destination" type="text" formControlName="Destination" class="form-control"
                placeholder="www.safemarch.com" required />
            <small *ngIf="addZscalerFirewallRequestForm.controls.Destination.errors?.pattern" id="alertUser"
                class="form-text text-danger">{{alertUserInvalidUrl}}</small>
        </div>
        <div class="col-4">
            <label for="ValidatedByCISO" class="required">Validated with my CISO:</label>
            <div>
                <div style="display: inline-block;margin-right: 5px;">
                    <label><input type="radio" id="ValidatedByCISO" formControlName="ValidatedByCISO"
                            class="form-control" [value]="true">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="ValidatedByCISO" formControlName="ValidatedByCISO"
                            class="form-control" [value]="false">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="Protocol" class="required">Protocol:</label>
            <select *ngIf="protocols.length > 0" id="type" formControlName="Protocol" class="form-control" required>
                <option disabled value="null">Select a protocol</option>
                <option *ngFor="let protocol of protocols" [value]="protocol.Name">
                    {{protocol.Name}}</option>
            </select>
        </div>
        <div class="col-4">
            <label class="required" for="Description">Description:</label>
            <div style="display:flex">
                <textarea type="text" id="Description" formControlName="Description" class="form-control" required>
            </textarea>
            </div>
            <small id="urlinfo" class="form-text text-muted">Add a short description to justify this addition and
                explain why
                it’s required.</small>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="ticketID">Ticket ID:</label>
            <input type="text" id="ticketID" formControlName="ticketID" class="form-control">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2 offset-8" style="padding-top:33px;float: left;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest();"
                [disabled]="(addZscalerFirewallRequestForm.invalid)">Send Request</button>
        </div>
    </div>
</form>