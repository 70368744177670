import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function maxValueValidator(max: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value == null) {
      return null;
    }
    const maxVal = control.value > max;
    
    return maxVal ? {'maxValue': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[appMaxValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true}]
})
export class MaxValueValidatorDirective implements Validator {
  @Input('appMaxValue') maxValue: number;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.maxValue ? maxValueValidator(this.maxValue)(control) : null;
  }
}