<div class="row " *ngIf="productCustomHistoryDetails.IsRequestStatusSuccess">
    <span  class="text-warning font-weight-bold">Warning: Old manager may still have permissions on the mailbox. Please consider removing them using <a (click)="closePopUp()" routerLink="/products/removeMailboxDelegation">Remove-Mailbox-Delegation</a>.</span>
</div>

<br /><br />

<div class="row ">
        <table class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                    <th style="text-align: center;" colspan="2">
                        MAILBOX MANAGER INFORMATION
                        <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!productCustomInfoCollapsed">
                <tr>
                    <th class="historyTableColWidthLimitation">Mailbox</th>
                    <td>{{ productCustomHistoryDetails.Mailbox }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Old Manager</th>
                    <td>{{ productCustomHistoryDetails.OldManager }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">New Manager</th>
                    <td>{{ productCustomHistoryDetails.NewManager }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Manager has Send As Permission</th>
                    <td>{{ booleanToStringConverterImport(productCustomHistoryDetails.ManagerSendAs, 'No', 'Yes') }}</td>                    
                </tr>
            </tbody>
        </table>
    </div>