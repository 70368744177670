<h4>Reset guest MFA:</h4>

<form #getUserInfoForm="ngForm" (ngSubmit)="getUserInfoForm.resetForm()" >
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="GuestInput">Guest:</label>
            <input type="text" id="GuestInput" class="form-control"
                [(ngModel)]="fileContent.Guest" name="GuestInput" required>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON --> 
        <div class="col-1 offset-7">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); getUserInfoForm.reset();" [disabled]="getUserInfoForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-1 offset-2" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getUserInfoForm.invalid); getUserInfoForm.reset();" [disabled]="(getUserInfoForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.Guest }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-8">
                    <b>Guest:</b> {{ listedFileContent.Guest }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>