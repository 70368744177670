

import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { GetIdentityPersonalDataFileContent, GetIdentityPersonalDataRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './deactivateGID.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class DeactivateGIDComponent{
    
    
    
    

    public fileContentsList = new Array<GetIdentityPersonalDataFileContent>();
    public fileContent = new GetIdentityPersonalDataFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private graphApiService: GraphApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<GetIdentityPersonalDataFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new GetIdentityPersonalDataFileContent();
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        var tempObject = new GetIdentityPersonalDataFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var data = new GetIdentityPersonalDataRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/deactivateGID')
            .subscribe( response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],                  
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                }, 
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                }
            );        
    }
}
