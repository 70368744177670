import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {UntypedFormControl, NG_VALIDATORS, Validator, AbstractControl, ValidatorFn} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialog } from '../../Template/Dialog/confirmationDialog.component';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';
import { DeleteAzureADGroupFileContent } from '../../../Models/FileContents';


import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './deleteAzureADGroup.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class DeleteAzureADGroupComponent {
    assignableDeviceTypes = [];
    listLocalITADGroup = [];
    localITTeamsGroup = [];
    fileContentsList = new Array<DeleteAzureADGroupFileContent>();
    fileContent = new DeleteAzureADGroupFileContent();
    currentUserInfo = new UserInfo();
    selectedLocalIT: String = '';
    localITNotFound = '';
    groupError = '';
    topLocalIT: Array<string> =  new Array<string>();
    Entities:Array<object> = new Array<object>();
    entitiesError: String = '';
    isTopLocalITSelected = false;
    searchLocalITGroup = '';
    guidPattern = inputValidationHelper.guidFormatRegEx;
    localITNames = [];
    selectedIT = '';
    previousSelectedIT = '';
    selectedOrgID = '';
    previousSelectedOrgID = '';
    myControl = new UntypedFormControl();
    filteredOptions: Observable<string[]>;
    regexGuid = new RegExp(this.guidPattern);
    inProgress = false;

    constructor(private userService: UserService, private localITsService: LocalITsService, private graphApiService: GraphApiService, private webAdministrationService : WebAdministrationService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
        this.myControl.setValidators(this.forbiddenNamesValidator());
    }

    forbiddenNamesValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            this.groupError = "";
            if (typeof control.value === "string") {
                if (control.value) {
                    if (!(this.localITTeamsGroup.some(item => item.displayName == control.value))) {
                        if (this.regexGuid.test(control.value) && !(this.isTopLocalITSelected && !this.selectedIT)) {
                            this.graphApiService.getGroupByObjectId(control.value)
                                .subscribe(response => {
                                    if (!(this.localITTeamsGroup.some(item => item.displayName == response.displayName))) {
                                        this.groupError = `Group [${response.displayName}] is not allowed to be deleted`
                                    }
                                    else {
                                        this.fileContent.GroupObjectID = response.id;
                                        this.fileContent.GroupDisplayName = response.displayName;
                                    }
                                },
                                error => {
                                    this.groupError = error.statusText;
                                    return  { 'forbiddenNames': { value: control.value } };
                                });
                        }
                        else {
                            return  { 'forbiddenNames': { value: control.value } };
                        }
                    }
                    else {
                        this.fileContent.GroupDisplayName = control.value;
                        let option = this.localITTeamsGroup.filter(item => item.displayName == control.value);
                        if (option.length == 1) {
                            this.fileContent.GroupObjectID = option[0].id;
                        }
                        else {
                            return  { 'forbiddenNames': { value: control.value } };
                        }
                    }
                    this.fileContent.Identity = control.value;
                }
            }
            return null;
        };
      }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo); 
        this.localITsService.getLocalITsWithManageAllPermission().subscribe(response => {
            this.topLocalIT = response;
            this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
                if (this.selectedLocalIT && this.selectedLocalIT != updatedLocalIT) {
                    this.router.navigate(['/']);
                }
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent = new DeleteAzureADGroupFileContent();
                this.myControl.reset('');
                this.localITTeamsGroup = [];
                if (this.topLocalIT.indexOf(this.selectedLocalIT as string) !== -1) {
                    this.isTopLocalITSelected = true;
                    this.webAdministrationService.getEntities({ Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                    if (this.localITNames.length === 0) {
                        this.localITsService.getAllLocalITNames()
                            .subscribe(response => {
                                this.localITNames = response.map(item => item.Name);
                            });
                    }
                }
                else {
                    this.isTopLocalITSelected = false;
                    this.getOrgIDsManageableByLocalIT(this.selectedLocalIT.toString());
                }
            });
        });
    }

    private _filter(value: any): string[] {
        if (value) {
            if (typeof value === "string") {
                const filterValue = value.toLowerCase();
                return this.localITTeamsGroup.filter(option => option.displayName.toLowerCase().includes(filterValue));
            }
        }
        else {
            return this.localITTeamsGroup
        }
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<DeleteAzureADGroupFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new DeleteAzureADGroupFileContent();
        this.myControl.reset('');
        this.entitiesError = '';
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    async addFileContentToList(isSendingRequest:Boolean) {
        this.fileContent.Identity = this.fileContent.GroupObjectID;
        var tempObject = new DeleteAzureADGroupFileContent(this.fileContent);
        await this.checkConfirmation(tempObject, true, isSendingRequest);
    }
    
    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    updateDisplayName(option: MatOption) {
        this.fileContent.GroupDisplayName = option.value.displayName;
        this.fileContent.GroupObjectID = option.value.id;
    }

    displayWith(option: any): string {
        if (option) {
            return option.displayName;
        }
    }

    getOrgIDsManageableByLocalIT(localIT: string) {
        this.isTopLocalITSelected = this.topLocalIT.some((value) => value === this.selectedLocalIT);
        if (!this.isTopLocalITSelected) {
            this.selectedIT = '';
            this.selectedOrgID = '';
            this.previousSelectedOrgID = '';
            this.entitiesError = '';
        }
        this.localITTeamsGroup = [];
        this.fileContent.GroupDisplayName = '';
        this.fileContent.GroupObjectID = '';
        this.myControl.reset('');
        if (localIT) {
            if (this.previousSelectedIT && localIT != this.previousSelectedIT && this.isTopLocalITSelected) {
                this.previousSelectedOrgID = this.selectedOrgID = '';
            }
            this.previousSelectedIT = localIT;
            if (!this.selectedOrgID) {
                if (!(this.isTopLocalITSelected && !this.selectedIT)) {
                    this.webAdministrationService.getEntities({ ITCard: localIT, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                        if (this.Entities.length == 0) {
                            this.entitiesError = `Make sure ITCard ${localIT} is added in Org-IT for the entities ${localIT} is allowed to manage.`
                        }
                        else if (this.Entities.length == 1) {
                            this.selectedOrgID = this.Entities[0]["OrgID"]
                        }
                        this.getLocalItTeamsGroup(this.selectedOrgID);
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                }
            }
            else {
                this.getLocalItTeamsGroup(this.selectedOrgID);
            }
        }
        else if (this.previousSelectedIT && this.isTopLocalITSelected && this.selectedOrgID) {
            this.previousSelectedIT = '';
            this.getLocalItTeamsGroup(this.selectedOrgID);
        }
    }

    getLocalItTeamsGroup(orgID: string) {
        this.isTopLocalITSelected = this.topLocalIT.some((value) => value === this.selectedLocalIT);
        if (!this.isTopLocalITSelected) {
            this.selectedIT = '';
        }
        this.localITTeamsGroup = [];
        this.fileContent.GroupDisplayName = '';
        this.fileContent.GroupObjectID = '';
        this.myControl.reset('');
        if (orgID && this.previousSelectedOrgID && orgID != this.previousSelectedOrgID && this.isTopLocalITSelected) {
            this.selectedIT = '';
        }
        this.previousSelectedOrgID = this.selectedOrgID;
        if (this.selectedOrgID || (this.isTopLocalITSelected && this.selectedIT)) { 
            this.inProgress = true;
            let chosenLocalIT = this.selectedLocalIT.toString();
            if (this.isTopLocalITSelected) {
                chosenLocalIT = this.selectedIT ? this.selectedIT.toString() : '';
            }
            this.graphApiService.getGroupsManageableByLocalIT(chosenLocalIT, this.selectedOrgID, "Delete-AzureADGroup")
                .subscribe(response => {
                    this.localITTeamsGroup.push(...response);
                    if (this.localITTeamsGroup.length === 0) {
                        this.localITNotFound = "No AAD group found for this Local IT.";
                    }
                    else {
                        this.localITNotFound = "";
                    }
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => this._filter(value))
                        );
                    this.inProgress = false;
                },
                (error) =>  {
                    this.inProgress = false;
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                });
        }
        if (this.selectedOrgID && this.isTopLocalITSelected && !this.selectedIT) {
            this.localITNames = this.Entities.filter(item => item["OrgID"] == this.selectedOrgID)[0]["ITCard"];
        }
    }

    errorsInForm() {
        return (!(this.fileContent.GroupDisplayName) || this.groupError || this.myControl.hasError('forbiddenNames') || this.myControl.hasError('required'));
    }

    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if (isFormInvalid == false && !this.errorsInForm()) {
            this.addFileContentToList(true);
        } 
        else {
            this.reallySendRequest();
        }
    }

    reallySendRequest() {
        var fileContentList = this.fileContentsList.map(({ GroupDisplayName,GroupObjectID, ...item }) => item)
        this.clearPage();
        for (let i = 0; i < fileContentList.length; i++)  {
            let data = { ...fileContentList[i] };
            data["localIT"] = this.selectedLocalIT;
            //call the post graph api
            this.graphApiService.deleteAzureADGroup(data)
                .subscribe(
                    response => {
                        if (i == 0) {
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }
                    },
                    error => {
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    }
                );
        }
    }

    async checkConfirmation(deleteAzureADGroupFileContent:DeleteAzureADGroupFileContent, addObjectToList:Boolean, isSendingRequest:Boolean){
        
        if (addObjectToList){
            let confirmationDialog = this.dialog.open(ConfirmationDialog, { width: 'auto', height: 'auto', data: { title: 'Azure AD Group Deletion Confirmation', message: 'Are you sure you would like to proceed with deleting<br />[' + deleteAzureADGroupFileContent.GroupDisplayName + ']?' } });
            confirmationDialog.afterClosed().subscribe(result => {
                if (result) {
                    this.fileContentsList.push(deleteAzureADGroupFileContent);
                    this.clearFileContentForms();
                    if (isSendingRequest) {
                        this.reallySendRequest();
                    }
                }
            });
        }
    }
} 