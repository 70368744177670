<br /><br />
<h5 class="text-warning">Warning: the Azure AD Security group's permissions will not be restored!</h5>
<br /><br />
<h4>Undelete Azure AD Group:</h4>

<form #fileContentForm="ngForm">
    <div class='row form-group'>
        <div class="col-2">
            <label [class.required]="!isTopLocalITSelected" for="orgIDDropdownList">OrgID:</label>
            <select id="orgIDDropdownList" class="form-control"
                [(ngModel)]="selectedOrgID" name="orgIDDropdownList"
                (change)="getLocalItTeamsGroup($event.target.value)"
                [required]="!isTopLocalITSelected" #orgIDDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let entity of Entities | orderby: 'OrgID' : false : true" [value]="entity.OrgID" title="{{entity.Name}}">{{entity.OrgID}}</option>
            </select>
            <div *ngIf="orgIDDropdownList.invalid && (orgIDDropdownList.dirty || orgIDDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="orgIDDropdownList.errors.required">
                    OrgID is required.
                </div>
            </div>
            <div *ngIf="entitiesError" class="alert alert-danger">
                {{entitiesError}}
            </div>
        </div>
        <div class="col-2" *ngIf="isTopLocalITSelected">
            <label for="localITDropdownList">Get groups for:</label>
            <select id="localITDropdownList" class="form-control" name="localITDropdownList" [(ngModel)]="selectedIT"
                (change)="getOrgIDsManageableByLocalIT($event.target.value)" #localITDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let localITName of localITNames | orderby: '' : false : true"
                    value="{{localITName}}">
                    {{localITName}}</option>
            </select>
        </div>
        <div class="col-4">
            <label class="required" for="auto">Azure AD Group:</label>
            <span *ngIf="localITNotFound" class="badge badge-info">{{localITNotFound}}</span>
            <div *ngIf="!localITNotFound">
                <input type="text"
                    placeholder="Select or start typing group and select"
                    aria-label="Number"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                    class="form-control"
                    id="auto"
                    required>
                <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayWith" (optionSelected)="updateDisplayName($event.option)">
                    <mat-option *ngFor="let app of filteredOptions | async" [value]="app" matTooltip="{{app.id}}">
                        {{app.displayName}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="myControl.hasError('forbiddenNames')">
                    <strong>'{{myControl.errors.forbiddenNames.value}}'</strong> is not selected from the list
                </mat-error>
                <mat-error *ngIf="myControl.hasError('required') && (myControl.touched || myControl.dirty)">
                    Azure AD Group is required
                </mat-error>
            </div>
            <small *ngIf="groupError" id="groupError" class="form-text text-danger">{{ groupError }}</small>
        </div>
        <div class="col-4">            
            <label for="ownerInput0">Group Owners:</label>
            <div *ngFor="let owner of fileContent.GroupOwners; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'ownerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.GroupOwners[i]" name="{{'ownerInput' + i}}"
                        [ngClass]="{'has-danger-forced':(ownerInput.invalid && (ownerInput.dirty || ownerInput.touched || ownerInput.value))}"
                        [pattern]="groupIDPattern" #ownerInput="ngModel">
                    <i *ngIf="isFirst" id="ownerAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalGroupOwner()">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'ownerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalGroupOwner(i)">remove_circle_outline</i>
                    <div *ngIf="ownerInput.invalid && (ownerInput.dirty || ownerInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="ownerInput.errors.pattern">
                            Group ID format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <div class="row form-group">
         <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(false); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="(fileContentForm.invalid || errorsInForm())  && (!fileContentsList || (fileContentsList && fileContentsList.length == 0))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.Identity }}</b> <span *ngIf="listedFileContent.Identity == listedFileContent.GroupObjectID"> ({{ listedFileContent.GroupDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-8">
                    <b>Azure AD Group:</b> {{ listedFileContent.Identity }}
                </div>
                <div class="col-4">
                    <b>Owner(s):</b>
                    <div *ngFor="let owner of listedFileContent.GroupOwners">
                        {{ owner }}
                    </div>
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>