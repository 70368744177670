<div class="row ">
    <div class="offset-10 col-1 form-group">
        <button class="btn btn-primary"  (click)="showAll()">Show All</button>
    </div>
    <div class="col-1 form-group">
        <button class="btn btn-primary  "  (click)="hideAll()">Hide All</button>
    </div>
</div>

<br />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="mailboxInfoCollapsed=!mailboxInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    MAILBOX INFORMATION 
                    <i *ngIf="mailboxInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!mailboxInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!mailboxInfoCollapsed">
            <tr>
                <th>Display Name</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.DisplayName }}</td>
            </tr>
            <tr>
                <th>Remote Recipient Type</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.RemoteRecipientType }}</td>
            </tr>
            <tr>
                <th>Recipient Type Details</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.RecipientTypeDetails }}</td>
            </tr>
            <tr>
                <th>Alias</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.Alias }}</td>
            </tr>
            <tr>
                <th>Primary SMTP Address</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.PrimarySmtpAddress }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div *ngFor="let mail of getMailboxInfoDetails.MailboxInformation.EmailAddresses | orderby : ''">{{ mail }}<br/></div>
                </td>
            </tr>
            <tr>
                <th>Legacy Exchange DN</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LegacyExchangeDN }}</td>
            </tr>
            <tr>
                <th>Exchange Guid</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ExchangeGuid }}</td>
            </tr>
            <tr>
                <th>Archive Guid</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveGuid }}</td>
            </tr>
            <tr>
                <th>Archive Name</th>
                <td>
                    <div *ngFor="let archiveName of getMailboxInfoDetails.MailboxInformation.ArchiveName | orderby : ''">{{ archiveName }}<br/></div>
                </td>
            </tr>
            <tr>
                <th>Archive Status</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveStatus }}</td>
            </tr>
            <tr>
                <th>Archive State</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveState }}</td>
            </tr>
            <tr>
                <th>Forwarding SMTP Address</th>
                <td>
                    <div *ngFor="let smtpAddress of getMailboxInfoDetails.MailboxInformation.ForwardingSMTPAddress | orderby : ''">{{ smtpAddress }}<br/></div>
                </td>                
            </tr>
            <tr>
                <th>Forwarding Address</th>
                <td>
                    <div *ngFor="let address of getMailboxInfoDetails.MailboxInformation.ForwardingAddress | orderby : ''">{{ address }}<br/></div>
                </td>                
            </tr>
            <tr>
                <th>When Mailbox Created</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WhenMailboxCreated }}</td>
            </tr>
            <tr>
                <th>SIP Address</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.SIPAddress }}</td>
            </tr>
            <tr>
                <th>Windows Live ID</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WindowsLiveID }}</td>
            </tr>
            <tr>
                <th>Usage Location</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.UsageLocation }}</td>
            </tr>
            <tr>
                <th>Hidden</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.Hidden }}</td>
            </tr>
            <tr>
                <th>Mailbox Type</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.MailboxType }}</td>
            </tr>
            <tr>
                <th>Mailbox Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.MailboxQuota }}</td>
            </tr>
            <tr>
                <th>Prohibit Send Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ProhibitSendQuota }}</td>
            </tr>
            <tr>
                <th>Warning Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WarningQuota }}</td>
            </tr>
            <tr>
                <th>Total Item Size</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.TotalItemSize }}</td>
            </tr>
            <tr>
                <th>Item Count</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ItemCount }}</td>
            </tr>
            <tr>
                <th>Forward From Mailbox</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ForwardFromMailbox }}</td>
            </tr>
            <tr>
                <th>Hide Reason</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.HideReason }}</td>
            </tr>
            <tr>
                <th>Litigation Hold Enabled</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LitigationHoldEnabled }}</td>
            </tr>          
            <tr>
                <th>Litigation Hold Duration</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LitigationHoldDuration }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!mailboxInfoCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="recipientInfoCollapsed=!recipientInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    RECIPIENT INFORMATION 
                    <i *ngIf="recipientInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!recipientInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!recipientInfoCollapsed">
            <tr>
                <th>First Name</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.FirstName }}</td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.LastName }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Company }}</td>
            </tr>
            <tr>
                <th>Title</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Title }}</td>
            </tr>
            <tr>
                <th>Manager</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Manager }}</td>
            </tr>
            <tr>
                <th>Location</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Location }}</td>
            </tr>
            <tr>
                <th>Department</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Department }}</td>
            </tr>
            <tr>
                <th>Trigram</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Trigram }}</td>
            </tr>
            <tr>
                <th>Business Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.BusinessPhone }}</td>
            </tr>
            <tr>
                <th>Home Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.HomePhone }}</td>
            </tr>
            <tr>
                <th>Mobile Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Mobile }}</td>
            </tr>
            <tr>
                <th>IP Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.IPPhone }}</td>
            </tr>
            <tr>
                <th>Fax</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Fax }}</td>
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!recipientInfoCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="mobileDeviceInfoCollapsed=!mobileDeviceInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    MOBILE DEVICE INFORMATION 
                    <i *ngIf="mobileDeviceInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!mobileDeviceInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!mobileDeviceInfoCollapsed">
            <tr>
                <th>ActiveSync Enabled</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.ActiveSyncEnabled }}</td>
            </tr>
            <tr>
                <th>EAS Policy</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.EASPolicy }}</td>
            </tr>
            <tr>
                <th>Has Device Partnership</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.HasDevicePartnership }}</td>
            </tr>
            <tr>
                <th>Allowed Devices</th>
                <td>
                    <div *ngFor="let deviceId of getMailboxInfoDetails.MobileDeviceInformation.AllowedDevices | orderby : ''">{{ deviceId }}<br/></div>
                </td>                
            </tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!mobileDeviceInfoCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="fullAccessPermCollapsed=!fullAccessPermCollapsed">
                <th style="text-align: center;" colspan="2">
                    FULL ACCESS PERMISSIONS 
                    <i *ngIf="fullAccessPermCollapsed && getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!fullAccessPermCollapsed && getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!fullAccessPermCollapsed || getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes.length == 0">
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes.length == 0">
                <th>No Full Access Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!fullAccessPermCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="sendAsPermCollapsed=!sendAsPermCollapsed">
                <th style="text-align: center;" colspan="2">
                    SEND AS PERMISSIONS 
                    <i *ngIf="sendAsPermCollapsed && getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!sendAsPermCollapsed && getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!sendAsPermCollapsed || getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes.length == 0">
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes.length == 0">
                <th>No Send As Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!sendAsPermCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="sendOnBehalfPermCollapsed=!sendOnBehalfPermCollapsed">
                <th style="text-align: center;">
                    SEND ON BEHALF PERMISSIONS 
                    <i *ngIf="sendOnBehalfPermCollapsed && getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!sendOnBehalfPermCollapsed && getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes.length > 0" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!sendOnBehalfPermCollapsed || getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes.length == 0">
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes.length == 0">
                <th>No Send On Behalf Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
</div>

<br />
<br *ngIf="!sendOnBehalfPermCollapsed" />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="groupMembershipCollapsed=!groupMembershipCollapsed">
                <th style="text-align: center;" colspan="4">
                    GROUP MEMBERSHIP
                    <i *ngIf="groupMembershipCollapsed && getMailboxInfoDetails.GroupMembership.length > 0" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!groupMembershipCollapsed && getMailboxInfoDetails.GroupMembership.length > 0" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
            <tr *ngIf="!groupMembershipCollapsed && getMailboxInfoDetails.GroupMembership.length > 0">
                <th>
                    Display Name
                </th>
                <th>
                    Group Type
                </th>
                <th>
                    Mail Enabled
                </th>
                <th>
                    Security Enabled
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!groupMembershipCollapsed || getMailboxInfoDetails.GroupMembership.length == 0">
            <tr *ngIf="getMailboxInfoDetails.GroupMembership.length == 0">
                <th>No Group Membership</th>
            </tr>
            <tr *ngFor="let distributionList of getMailboxInfoDetails.GroupMembership">
                <td>{{ distributionList.DisplayName }}</td>
                <td>{{ distributionList.GroupType }}</td>
                <td>{{ distributionList.MailEnabled }}</td>
                <td>{{ distributionList.SecurityEnabled }}</td>
            </tr>
        </tbody>
    </table>
</div>


<!-- Hidden Section Pdf Export -->

<div id="hiddenTable" style="display: none;">
    <table id="table0" title="Get-Mailbox-Info Details {{getMailboxInfoDetails.GroupID}}"></table>
    <table id="table1" title="">
        <thead>
            <tr>
                <th style="text-align: center;" colspan="2">
                    MAILBOX INFORMATION 
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Display Name</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.DisplayName }}</td>
            </tr>
            <tr>
                <th>Remote Recipient Type</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.RemoteRecipientType }}</td>
            </tr>
            <tr>
                <th>Recipient Type Details</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.RecipientTypeDetails }}</td>
            </tr>
            <tr>
                <th>Alias</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.Alias }}</td>
            </tr>
            <tr>
                <th>Primary SMTP Address</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.PrimarySmtpAddress }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div *ngFor="let mail of getMailboxInfoDetails.MailboxInformation.EmailAddresses | orderby : ''">{{ mail + '\r\n'}}</div>
                </td>
            </tr>
            <tr>
                <th>Legacy Exchange DN</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LegacyExchangeDN }}</td>
            </tr>
            <tr>
                <th>Exchange Guid</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ExchangeGuid }}</td>
            </tr>
            <tr>
                <th>Archive Guid</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveGuid }}</td>
            </tr>
            <tr>
                <th>Archive Name</th>
                <td>
                    <div *ngFor="let archiveName of getMailboxInfoDetails.MailboxInformation.ArchiveName | orderby : ''">{{ archiveName + '\r\n'}}</div>
                </td>
            </tr>
            <tr>
                <th>Archive Status</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveStatus }}</td>
            </tr>
            <tr>
                <th>Archive State</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ArchiveState }}</td>
            </tr>
            <tr>
                <th>Forwarding SMTP Address</th>
                <td>
                    <div *ngFor="let smtpAddress of getMailboxInfoDetails.MailboxInformation.ForwardingSMTPAddress | orderby : ''">{{ smtpAddress + '\r\n'}}</div>
                </td>                
            </tr>
            <tr>
                <th>Forwarding Address</th>
                <td>
                    <div *ngFor="let address of getMailboxInfoDetails.MailboxInformation.ForwardingAddress | orderby : ''">{{ address + '\r\n'}}</div>
                </td>                
            </tr>
            <tr>
                <th>When Mailbox Created</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WhenMailboxCreated }}</td>
            </tr>
            <tr>
                <th>SIP Address</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.SIPAddress }}</td>
            </tr>
            <tr>
                <th>Windows Live ID</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WindowsLiveID }}</td>
            </tr>
            <tr>
                <th>Usage Location</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.UsageLocation }}</td>
            </tr>
            <tr>
                <th>Hidden</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.Hidden }}</td>
            </tr>
            <tr>
                <th>Mailbox Type</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.MailboxType }}</td>
            </tr>
            <tr>
                <th>Mailbox Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.MailboxQuota }}</td>
            </tr>
            <tr>
                <th>Prohibit Send Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ProhibitSendQuota }}</td>
            </tr>
            <tr>
                <th>Warning Quota</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.WarningQuota }}</td>
            </tr>
            <tr>
                <th>Total Item Size</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.TotalItemSize }}</td>
            </tr>
            <tr>
                <th>Item Count</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ItemCount }}</td>
            </tr>
            <tr>
                <th>Forward From Mailbox</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.ForwardFromMailbox }}</td>
            </tr>
            <tr>
                <th>Hide Reason</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.HideReason }}</td>
            </tr>
            <tr>
                <th>Litigation Hold Enabled</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LitigationHoldEnabled }}</td>
            </tr>          
            <tr>
                <th>Litigation Hold Duration</th>
                <td>{{ getMailboxInfoDetails.MailboxInformation.LitigationHoldDuration }}</td>
            </tr>        
        </tbody>
    </table>
    
    <table id="table2" title="">
        <thead>
            <tr>
                <th style="text-align: center;" colspan="2">
                    RECIPIENT INFORMATION 
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>First Name</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.FirstName }}</td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.LastName }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Company }}</td>
            </tr>
            <tr>
                <th>Title</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Title }}</td>
            </tr>
            <tr>
                <th>Manager</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Manager }}</td>
            </tr>
            <tr>
                <th>Location</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Location }}</td>
            </tr>
            <tr>
                <th>Department</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Department }}</td>
            </tr>
            <tr>
                <th>Trigram</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Trigram }}</td>
            </tr>
            <tr>
                <th>Business Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.BusinessPhone }}</td>
            </tr>
            <tr>
                <th>Home Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.HomePhone }}</td>
            </tr>
            <tr>
                <th>Mobile Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Mobile }}</td>
            </tr>
            <tr>
                <th>IP Phone</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.IPPhone }}</td>
            </tr>
            <tr>
                <th>Fax</th>
                <td>{{ getMailboxInfoDetails.RecipientInformation.Fax }}</td>
            </tr>
        </tbody>
    </table>
        
    <table id="table3" title="">
        <thead class="thead-default">
            <tr>
                <th style="text-align: center;" colspan="2">
                    MOBILE DEVICE INFORMATION 
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>ActiveSync Enabled</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.ActiveSyncEnabled }}</td>
            </tr>
            <tr>
                <th>EAS Policy</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.EASPolicy }}</td>
            </tr>
            <tr>
                <th>Has Device Partnership</th>
                <td>{{ getMailboxInfoDetails.MobileDeviceInformation.HasDevicePartnership }}</td>
            </tr>
            <tr>
                <th>Allowed Devices</th>
                <td>
                    <div *ngFor="let deviceId of getMailboxInfoDetails.MobileDeviceInformation.AllowedDevices | orderby : ''">{{ deviceId + '\r\n'}}</div>
                </td>                
            </tr>
        </tbody>
    </table>
    
    <table id="table4" title="">
        <thead class="thead-default">
            <tr>
                <th style="text-align: center;" colspan="2">
                    FULL ACCESS PERMISSIONS
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes.length == 0">
                <th>No Full Access Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.FullAccessPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
    
    <table id="table5" title="">
        <thead class="thead-default">
            <tr>
                <th style="text-align: center;" colspan="2">
                    SEND AS PERMISSIONS 
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes.length == 0">
                <th>No Send As Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.SendAsPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
    
    <table id="table6" title="">
        <thead class="thead-default">
            <tr clas>
                <th style="text-align: center;">
                    SEND ON BEHALF PERMISSIONS 
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes.length == 0">
                <th>No Send On Behalf Permissions</th>                
            </tr>
            <tr *ngFor="let mailbox of getMailboxInfoDetails.PermissionsInformation.SendOnBehalfPermissionsMailboxes | orderby : ''"><td>{{ mailbox }}</td></tr>
        </tbody>
    </table>
    
    <table id="table7" title="">
        <thead class="thead-default">
            <tr>
                <th style="text-align: center;" colspan="4">
                    GROUP MEMBERSHIP
                </th>
            </tr>
            <tr>
                <th>
                    Display Name
                </th>
                <th>
                    Group Type
                </th>
                <th>
                    Mail Enabled
                </th>
                <th>
                    Security Enabled
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="getMailboxInfoDetails.GroupMembership.length == 0">
                <th>No Group Membership</th>
            </tr>
            <tr *ngFor="let distributionList of getMailboxInfoDetails.GroupMembership">
                <td>{{ distributionList.DisplayName }}</td>
                <td>{{ distributionList.GroupType }}</td>
                <td>{{ distributionList.MailEnabled }}</td>
                <td>{{ distributionList.SecurityEnabled }}</td>
            </tr>
        </tbody>
    </table>
</div>