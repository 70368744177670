<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    AAD GROUP MEMBER INFORMATION
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">Azure AD Group</th>
                <td>{{ productCustomHistoryDetails.AzureADGroup }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Added User</th>
                <td>
                    <div class=" d-flex flex-wrap" style="padding-left: 0px;">
                        <span *ngFor="let member of productCustomHistoryDetails.Members; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex" [ngClass]="!isLast ? 'pr-1 mr-1 border-right' : ''">{{ member }}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>