<h4>Create new personal mailbox:</h4>

<!-- ADD NEW MAILBOX FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.GroupID)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.GroupID)">Please fill a valid Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.GroupID)">Based on Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <span style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</span>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" noAdmin="noAdmin" groupIDShouldBeActive="true" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.GroupID"></GroupIDInputFieldOkta>
        </div>
        <div class="col-4">
            <label class="required" for="createMailboxOffice365LicensesInput">Office365 License:</label>
            <select id="createMailboxOffice365LicensesInput" class="form-control" (change)="getNumberOfAvailableLicences($event.currentTarget.value)"
                [(ngModel)]="fileContent.Office365Licenses" name="createMailboxOffice365LicensesInput">
                <option *ngFor="let license of licenseTypes | orderby: 'name' : false : true" [value]="license.code">{{license.name}}</option>
            </select>
            <small *ngIf="availableLicensesCounter !== null" id="availableLicensesCounter"
                [class]="availableLicensesCounter < 1 ? 'form-text text-danger' : 'form-text text-primary'">{{ availableLicensesCounter < 1 ? 'Not available, provisioning in process' : availableLicensesCounter + ' license(s) available' }}</small>
            <small *ngIf="fileContent && fileContent.Office365Licenses == 'ENTERPRISEPACK'"
                class="text-warning">Warning : Please make sure that you subscribe to an EMS E3 or a CoreCal Bridge while assigning an Office 365 E3 to a user.</small>
        </div>
        <div class="col-4">
            <label class="required" for="createMailboxLanguageDropDown">Language:</label>
            <select id="createMailboxLanguageDropDown" class="form-control"
                    [(ngModel)]="fileContent.MailboxLanguage" name="createMailboxLanguageDropDown">
                    <option *ngFor="let language of languages" [value]="language.code">{{language.name}}</option>
            </select>
        </div>        
    </div>
    <div class="row form-group">       
        <div class="col-4">
            <label for="createMailboxPrimarySMTPInput">Primary SMTP:</label>
            <input type="text" id="createMailboxPrimarySMTPInput" class="form-control"
                    [(ngModel)]="fileContent.PrimarySMTP" name="createMailboxPrimarySMTPInput">
        </div>
        <div class="col-4">
            <label for="createMailboxPrimarySMTPDomainInput">Primary SMTP Domain:</label>
            <input type="text" id="createMailboxPrimarySMTPDomainInput" class="form-control"
                    [(ngModel)]="fileContent.PrimarySMTPDomain" name="createMailboxPrimarySMTPDomainInput">
        </div>
        <div class="col-4">            
            <label for="createMailboxMailAdressesInput0">Mail Addresses:</label>
            <div *ngFor="let mailAddress of fileContent.MailAddresses; let i = index; first as isFirst; trackBy:trackByIndex">
                <div *ngIf="isFirst" style="display:flex">
                    <input type="text" id="{{'createMailboxMailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createMailboxMailAdressesInput' + i}}">
                    <i id="createMailboxMailAdressesAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.MailAddresses)">add_circle_outline</i>
                </div>
                <div *ngIf="!isFirst" style="display:flex; margin-top:5px">
                    <input type="text" id="{{'createMailboxMailAdressesInput' + i}}" class="form-control col-10"
                            [(ngModel)]="fileContent.MailAddresses[i]" name="{{'createMailboxMailAdressesInput' + i}}">
                    <i id="{{'createMailboxMailAdressesRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.MailAddresses, i)">remove_circle_outline</i>
                </div>
            </div>
        </div> 
    </div>
    <div class="row form-group">        
        <div class="col-4">
            <label for="createInitialsInput">Initials:</label>
            <input type="text" id="createInitialsInput" class="form-control"
                    [(ngModel)]="fileContent.Initials" name="createInitialsInput" [maxlength]="2">
        </div>
        <div class="col-4">
            <label for="createMailboxBrandInput">Brand:</label>
            <input type="text" id="createMailboxBrandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="createMailboxBrandInput">
        </div>                
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxEnableArchiveInput">Enable Archive:</label>
            <div id="createMailboxEnableArchiveInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableArchiveInputOpt1" name="createMailboxEnableArchiveInput" class="form-control" [value]="true" [(ngModel)]="fileContent.EnableArchive" #createMailboxEnableArchiveInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableArchiveInputOpt2" name="createMailboxEnableArchiveInput" class="form-control" [value]="false" [(ngModel)]="fileContent.EnableArchive" #createMailboxEnableArchiveInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxExternalInput">External:</label>
            <div id="createMailboxExternalInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxExternalInputOpt1" name="createMailboxExternalInput" class="form-control" [value]="true" [(ngModel)]="fileContent.External" #createMailboxExternalInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxExternalInputOpt2" name="createMailboxExternalInput" class="form-control" [value]="false" [(ngModel)]="fileContent.External" #createMailboxExternalInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxHiddenInput">Hidden:</label>
            <div id="createMailboxHiddenInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt1" name="createMailboxHiddenInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxHiddenInputOpt2" name="createMailboxHiddenInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Hidden" #createMailboxHiddenInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxEnableLitigationHoldInput">Enable Litigation Hold:</label>
            <div id="createMailboxEnableLitigationHoldInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableLitigationHoldInputOpt1" name="createMailboxEnableLitigationHoldInput" class="form-control" [value]="true" [(ngModel)]="fileContent.EnableLitigationHold" #createMailboxEnableLitigationHoldInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableLitigationHoldInputOpt2" name="createMailboxEnableLitigationHoldInput" class="form-control" [value]="false" [(ngModel)]="fileContent.EnableLitigationHold" #createMailboxEnableLitigationHoldInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>        
        <div class="col-4" [hidden]="!fileContent.EnableLitigationHold">
            <label for="litigationHoldDuration">Litigation Hold Duration:</label>
            <select id="litigationHoldDuration" class="form-control"
                [(ngModel)]="fileContent.LitigationHoldDuration" name="litigationHoldDuration">
                <option *ngFor="let duration of litigationHoldDurations | orderby: '' : false : true" [value]="duration">{{duration}}</option>
            </select>
        </div>
        <div class="col-4" [ngClass]="{'offset-4': !fileContent.EnableLitigationHold}">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>          
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="createMailboxMergeInput">Merge:</label>
            <div id="createMailboxMergeInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxMergeInputOpt1" name="createMailboxMergeInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Merge" #createMailboxMergeInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxMergeInputOpt2" name="createMailboxMergeInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Merge" #createMailboxMergeInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>       
        <div class="col-4" *ngIf="fileContent.Merge">
            <label for="meuExternalEmailAddressInput">MEU External Email Address:</label>
            <input type="text" id="meuExternalEmailAddressInput" class="form-control"
                    [(ngModel)]="fileContent.MEUExternalEmailAddress" name="meuExternalEmailAddressInput">
        </div>               
    </div>
</form>
<div class="col-2 offset-1">
    <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
</div>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }}</b> <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span> -
            {{ listedFileContent.Office365Licenses }} -
            {{ listedFileContent.MailboxLanguage }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
                </div>
                 <div class="col-4">
                    <b>License:</b> {{ listedFileContent.Office365Licenses }}
                </div>
                <div class="col-4">
                    <b>Language:</b> {{ listedFileContent.MailboxLanguage }}
                </div>                
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Primary SMTP:</b> {{ listedFileContent.PrimarySMTP }}
                </div>
                <div class="col-4">
                    <b>Primary SMTP Domain:</b> {{ listedFileContent.PrimarySMTPDomain }}
                </div>
                <div class="col-4">
                    <b>Mail Addresses:</b>
                    <div *ngFor="let mailAddress of listedFileContent.MailAddresses">
                        {{ mailAddress }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b>Initials:</b> {{ listedFileContent.Initials }}
                </div>
                <div class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>                          
            </div>
            <div class="row">                
                <div class="col-4">
                    <b>Enable Archive:</b> {{ booleanToStringConverterImport(listedFileContent.EnableArchive, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>External:</b> {{ booleanToStringConverterImport(listedFileContent.External, 'No', 'Yes') }}
                </div> 
                <div class="col-4">
                    <b>Hidden:</b> {{ booleanToStringConverterImport(listedFileContent.Hidden, 'No', 'Yes') }}
                </div>
            </div>
            <div class="row">                
                <div class="col-4">
                    <b>Enable Litigation Hold:</b> {{ booleanToStringConverterImport(listedFileContent.EnableLitigationHold, 'No', 'Yes') }}
                </div>
                <div class="col-4" *ngIf="listedFileContent.EnableLitigationHold">
                    <b>LitigationHold Duration:</b> {{ listedFileContent.LitigationHoldDuration }}
                </div>         
            </div>
            <div class="row">                
                <div class="col-4">
                    <b>Merge:</b> {{ booleanToStringConverterImport(listedFileContent.Merge, 'No', 'Yes') }}
                </div>
                <div class="col-4" *ngIf="listedFileContent.Merge">
                    <b>MEU External Email Address:</b> {{ listedFileContent.MEUExternalEmailAddress }}
                </div>         
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>