import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../../Services/requestContainer.service';
import { TopBarMessage } from '../../../../Models/TopBarMessage';
import { HomeService } from '../../../../Services/home.service';
import { UserService } from '../../../../Services/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'topBarMessageDialog',
    templateUrl: './topBarMessageDialog.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../../app.component.css']
})

export class TopBarMessageDialog {

    public fileContentsList = new Array<TopBarMessage>();
    public topBarMessage = new TopBarMessage();
    public selectedLocalIT: String = '';
    public isAllowedToEditLocalItCard: boolean = false;
    public startTime: string = '';
    public endTime: string = '';

    constructor(private userService: UserService, private requestContainerService: RequestContainerService, public dialogRef: MatDialogRef<TopBarMessageDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private homeService: HomeService, private router: Router, public dialog: MatDialog, public snackBar: MatSnackBar,) {
        if (data && data.messageID) {
            this.homeService.getMessageInfoById(data.messageID).subscribe(response => {
                this.topBarMessage = new TopBarMessage(response);

                if (this.topBarMessage.StartDateTime != '') {
                    this.startTime = this.topBarMessage.StartDateTime.split('T')[1].split(':')[0] + ":" + this.topBarMessage.StartDateTime.split('T')[1].split(':')[1];
                }

                if (this.topBarMessage.EndDateTime != '') {
                    this.endTime = this.topBarMessage.EndDateTime.split('T')[1].split(':')[0] + ":" + this.topBarMessage.EndDateTime.split('T')[1].split(':')[1];
                }
            });

            this.isAllowedToEditLocalItCard = data.isAdmin ? data.isAdmin : false;
        }
    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    }

    getStatusColorImport(status: string) {
        return getStatusColor(status);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    navigateToLocalITCardEdition() {
        this.dialogRef.close();
        this.router.navigate(['/administration/manageTopBarMessage'], { queryParams: { IT: this.topBarMessage.id } });
    }

    deleteLocalITCard() {
        this.homeService.deleteMessageInfoById(this.topBarMessage.id)
            .subscribe(response => {
                let successSnackBar = this.snackBar.open("ALERT MESSAGE DELETED !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.dialogRef.close();
                    this.router.navigate(['/topBarMessages']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}
