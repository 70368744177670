<!-- Hidden Section For PDF Export Without order by -->

<div class="row" style="white-space: pre-line;">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    INFORMATION 
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr *ngFor="let key of Object.keys(createDetails.Output)">
                <th class="historyTableColWidthLimitation">{{key}}</th>
                <td *ngIf="!Array.isArray(createDetails.Output[key])">{{createDetails.Output[key]}}</td>
                <td *ngIf="Array.isArray(createDetails.Output[key])">
                    <div *ngFor="let val of createDetails.Output[key] | orderby : '':false:true">{{ val + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>