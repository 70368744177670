<div class="row " *ngIf="distributionListDetails.IsRequestStatusSuccess && distributionListDetails.ProductName == 'Change-DistributionList-Owner'">
    <b><span  class="text-warning">Warning: </span>Owner has been changed. <br /> The old owner <span class="text-primary">{{ distributionListDetails.PrimaryOwner }}</span> still has permissions, please use Remove-DistributionList-Owner to remove him completely.</b>
</div>

<br />

<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="distributionListInfoCollapsed=!distributionListInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST INFORMATION 
                    <i *ngIf="distributionListInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!distributionListInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!distributionListInfoCollapsed">
            <tr>
                <th>Distribution List ID</th>
                <td>{{ distributionListDetails.DistributionList }}</td>
            </tr>
            <tr>
                <th>Email Address</th>
                <td>{{ distributionListDetails.DLemailAddress }}</td>
            </tr>
            <tr>
                <th>Primary Owner</th>
                <td>{{ distributionListDetails.PrimaryOwner }}</td>
            </tr>
            <tr>
                <th>All Owners</th>
                <td>
                    <div *ngFor="let owner of distributionListDetails.AllOwners | orderby : owner">
                        {{ owner }}<br />
                    </div>
                </td>
                
            </tr>
            <tr>
                <th>Added Owners</th>
                <td>
                     <div *ngFor="let addedOwner of distributionListDetails.AddedOwners | orderby : addedOwner">
                        {{ addedOwner }}<br />
                     </div>
                </td>               
            </tr>
            <tr>
                <th>Send As Applied</th>
                <td>{{ distributionListDetails.SendAsApplied }}</td>
            </tr>
        </tbody>
    </table>
</div>