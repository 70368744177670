<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    INTUNE USER INFORMATION
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">User</th>
                <td>{{ productCustomHistoryDetails.User }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">License</th>
                <td>{{ productCustomHistoryDetails.License }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Applications</th>
                <td>
                    <div *ngFor="let app of productCustomHistoryDetails.Applications">
                        {{ app }}<br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>