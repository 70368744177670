<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    LITIGATION HOLD INFORMATION 
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">Mailbox ID</th>
                <td>{{ productCustomHistoryDetails.GroupID }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Litigation Hold Duration</th>
                <td>{{ productCustomHistoryDetails.LitigationHoldDuration }} Years</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Litigation Hold Owner</th>
                <td>{{ productCustomHistoryDetails.LitigationHoldOwner }}</td>
            </tr>
        </tbody>
    </table>
</div>