import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { UpdateGuestUserFileContent, UpdateGuestUserRequest } from '../../../Models/FileContents';


import { booleanToStringConverter, removeArrayEmptyValues } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './updateGuestUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UpdateGuestUserComponent {
    
    
    

    public fileContentsList = new Array<UpdateGuestUserFileContent>();
    public fileContent = new UpdateGuestUserFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public localITNotFound: string = '';
    public howToUseInfo: boolean = true;

    public groupIDPattern: string = inputValidationHelper.groupIdFormatRegEx;
    guestAcceptedTypes = ['gid', 'email', 'upn', 'guid'];
    // public testUserProfile: any = null;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService,
        private businessRulesService: BusinessRulesService,
        private graphApiService: GraphApiService,
        private localITsService: LocalITsService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent = new UpdateGuestUserFileContent();
                this.localITNotFound = '';
            });
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<UpdateGuestUserFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new UpdateGuestUserFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    addFileContentToList() {
        var tempObject = new UpdateGuestUserFileContent(this.fileContent);
        this.fileContentsList.push(this.fileContent);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false && this.fileContent.GuestDisplayName) {
            this.addFileContentToList();
        }

        var data = new UpdateGuestUserRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/updateGuestUser')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify) {
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index) {
        arrayToModify.splice(index, 1);
    }
} 