<h4>Create GID</h4>

<form #createGIDForm="ngForm" (ngSubmit)="createGIDForm.resetForm()">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputFieldOkta>
        </div>
        <div class="col-4">
            <label class="required" for="birthDatePicker">Date of Birth:</label>
            <div class="col-12" style="display:inline">
                <input class="form-control" style="width: 70%; float: left;" matInput [min]="minDate" [max]="maxDate" id="birthDatePicker" name="birthDatePicker"
                    [matDatepicker]="birthDatePicker" placeholder="YYYY-MM-DD" (dateChange)="birthDateChanged($event.target.value)">
                <mat-datepicker-toggle style="float: left;" matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #birthDatePicker></mat-datepicker>
            </div>
            <div *ngIf="invalidBirthDate" class="alert alert-danger" style="clear:both">{{invalidBirthDate}}</div>
        </div>
        <div class="col-4">
            <label for="legalNameInput">Legal Name:</label>
            <input type="text" id="legalNameInput" name="legalNameInput" class="form-control"
                [(ngModel)]="fileContent.LegalName" #legalNameInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="legalFirstNameInput">Legal First Name:</label>
            <input type="text" id="legalFirstNameInput" name="legalFirstNameInput" class="form-control"
                [(ngModel)]="fileContent.LegalFirstName" #legalFirstNameInput="ngModel">
        </div>
        <div class="col-4">
            <label for="birthNameInput">Birth Name:</label>
            <input type="text" id="birthNameInput" name="birthNameInput" class="form-control"
                [(ngModel)]="fileContent.BirthName" #birthNameInput="ngModel">
        </div>
        <div class="col-4">
            <label for="preferredNameInput">Preferred Name:</label>
            <input type="text" id="preferredNameInput" name="preferredNameInput" class="form-control"
                [(ngModel)]="fileContent.PreferredName" #preferredNameInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="preferredFirstNameInput">Preferred First Name:</label>
            <input type="text" id="preferredFirstNameInput" name="preferredFirstNameInput" class="form-control"
                [(ngModel)]="fileContent.PreferredFirstName" #preferredFirstNameInput="ngModel">
        </div>
        <div class="col-4">
            <label for="emailInput">Email:</label>
            <input type="text" id="emailInput" name="emailInput" class="form-control"
                [(ngModel)]="fileContent.EmailAddress" #emailInput="ngModel">
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); createGIDForm.reset();" [disabled]="createGIDForm.invalid || !fileContent.BirthDate" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(createGIDForm.invalid); createGIDForm.reset();" [disabled]="((createGIDForm.invalid || !fileContent.BirthDate) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF DATA READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'createGIDRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>            
        </span>
    </a>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Date of Birth:</b> {{ listedFileContent.BirthDate }}
                </div>
                <div class="col-4">
                    <b>Legal Name:</b> {{ listedFileContent.LegalName }}
                </div>
                <div class="col-4">
                    <b>Legal First Name:</b> {{ listedFileContent.LegalFirstName }}
                </div>
                <div class="col-4">
                    <b>Birth Name:</b> {{ listedFileContent.BirthName }}
                </div>
                <div class="col-4">
                    <b>Preferred Name:</b> {{ listedFileContent.PreferredName }}
                </div>
                <div class="col-4">
                    <b>Preferred First Name:</b> {{ listedFileContent.PreferredFirstName }}
                </div>
                <div class="col-4">
                    <b>Email:</b> {{ listedFileContent.EmailAddress }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>