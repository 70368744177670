import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { ZScalerTunnelingContent } from '../../../Models/FileContents';
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';

import { urlPattern } from 'app/Helpers/utils';


@Component({
    selector: 'BodyContent',
    templateUrl: './zScalerTunneling.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class ZScalerTunnelingComponent {
    
    
    fileContentsList = new ZScalerTunnelingContent();
    fileContent = new ZScalerTunnelingContent();
    currentUserInfo: UserInfo = new UserInfo();
    urlPattern = urlPattern;
    alertUserInvalidUrl: string = "This Url is not valid.";
    selectedLocalIT: String = '';
    localITNotFound: string = '';
    today = new Date();
    customList: Array<string> = new Array<string>();
    isWait = false;

    constructor(private zscalerApiService: ZscalerApiService, private userService: UserService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent = new ZScalerTunnelingContent();
                this.localITNotFound = '';
            });
    }

    ngOnDestroy() {
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new ZScalerTunnelingContent();
    }

    clearFileContentForms() {
        this.fileContent = new ZScalerTunnelingContent();
    }

    sendRequest() {
        this.isWait = true;
        let urls = [];
        this.fileContent.Urls.forEach(url => {
            urls.push(`${url}`);
        });
        let data = new ZScalerTunnelingContent(this.fileContent);
        data["Urls"] = urls;
        data["localIT"] = this.selectedLocalIT;

        this.zscalerApiService.addZscalerTunneling(data)
            .subscribe(
                response => {
                    this.isWait = false;
                    this.clearPage();
                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });
                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                },
                error => {
                    this.isWait = false;
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify) {
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index) {
        arrayToModify.splice(index, 1);
    }

} 