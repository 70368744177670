import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { ZScalerConfirmationDialog } from '../../Template/Dialog/zScalerConfirmationDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { ZScalerTunnelingContent } from '../../../Models/FileContents';

import { UserService } from '../../../Services/user.service';


import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
    selector: 'BodyContent',
    templateUrl: './deleteZScalerTunneling.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class DeleteZScalerTunnelingComponent {
    
    

    public fileContentsList = new ZScalerTunnelingContent();
    public fileContent = new ZScalerTunnelingContent();
    public selectedLocalIT: String = '';
    public localITNotFound: string = '';
    public customList: Array<string> = new Array<string>();
    public isWait: boolean = true;
    public alertUser: string = "The Url will be deleted automatically after 7 days.";

    public myControl = new UntypedFormControl();
    public filteredOptions: Observable<string[]>;

    @ViewChild('urlchipList') urlchipList: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(
        private userService: UserService,
        private businessRulesService: BusinessRulesService,
        private zscalerApiService: ZscalerApiService,
        public snackBar: MatSnackBar, 
        private router: Router, 
        public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent = new ZScalerTunnelingContent();
                this.fileContent.Urls = [];
                this.localITNotFound = '';
            });

        this.fileContent.Urls = [];
        this.businessRulesService.getSpecificListByKey('DLTYPES', 'TunnelingByPassTypes')
            .subscribe(response => {
                this.getZscalerCustomList(response[0].Value);
            });
    }

    ngOnDestroy() {
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new ZScalerTunnelingContent();
    }

    clearFileContentForms() {
        this.fileContent = new ZScalerTunnelingContent();
    }

    handleCollapseBtn(id) {
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList() {
        var tempObject = new ZScalerTunnelingContent(this.fileContent);
        this.fileContentsList = tempObject;
    }

    removeFileContentFromList(index) {
        //this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        let urls = [];
        this.fileContent.Urls.forEach(url => {
            urls.push(`${url}`);
        });

        let data = {
            "Urls": this.fileContent.Urls,
            "localIT": this.selectedLocalIT
        }
        console.log(data);
        this.isWait = true;
        this.zscalerApiService.deleteZscalerTunneling(data)
            .subscribe(
                response => {
                    this.clearFileContentForms();
                    this.clearPage();
                    this.isWait = false;
                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });
                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                },
                error => {
                    this.isWait = false;
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify) {
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index) {
        arrayToModify.splice(index, 1);
    }

    public setBackEmptyForm() {
        this.localITNotFound = '';
    }

    getZscalerCustomList(customCategory: string) {
        this.zscalerApiService.getZscalerCustomList(customCategory)
            .subscribe(
                response => {
                    this.customList = response.dbCategorizedUrls;
                    this.customList.sort(function (a, b) {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    this.isWait = false;

                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => this._filter(value))
                        );
                },
                (error) => {
                    if (error && error.status && error.status == '404') {
                        this.isWait = false;
                    } else {
                        this.isWait = false;
                    }
                }
            );
    }

    onUriGroupToggle(url, $event: any) {
        if ($event.target.checked)
            this.fileContent.Urls.push(url);
        else {
            var index = this.fileContent.Urls.indexOf(url);
            this.fileContent.Urls.splice(index, 1);
        }
    }

    openDialog() {
        const dialogRef = this.dialog.open(ZScalerConfirmationDialog, { width: 'auto', height: 'auto', data: this.fileContent.Urls });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.sendRequest();
            }
        });
    }

    toDateChanged(newValue) {
        var tempDate = new Date(newValue);
        if (!isNaN(tempDate.valueOf())) {
            tempDate = new Date(tempDate.setHours(23, 59, 59, 999));
        }
    }

    private _filter(value: any): string[] {
        if (value) {
            if (typeof value === "string") {
                const filterValue = value.toLowerCase();
                return this.customList.filter(option => option.toLowerCase().includes(filterValue));
            }
        }
        else {
            return this.customList
        }
    }

    remove(url) {
        if (url) {
            const index = this.fileContent.Urls.indexOf(url);
            if (index >= 0) {
                this.fileContent.Urls.splice(index, 1);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const index = this.fileContent.Urls.indexOf(event.option.viewValue);
        if (index < 0) {
            this.fileContent.Urls.push(event.option.viewValue);
        }
        this.urlchipList.nativeElement.value = '';
        this.myControl.setValue(null);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add url
        if ((value || '').trim()) {
            this.fileContent.Urls.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.myControl.setValue(null);
    }
} 