import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { SetUserRingFileContent } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { GraphApiService } from '../../../Services/graphApi.service';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest } from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './setUserRing.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class SetUserRingComponent {
    public requestContainer: any = {};
    public fileContentsList = [];
    public fileContent = {
        GroupID: '',
        GroupIDDisplayName: '',
        GroupIDDisplayNameError: '',
        ExtensionAttribute11: '',
        ReleaseRing: 'R4',
        TicketID: ''
    }
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public releaseRings: Array<string> = new Array<string>();
    public groupIdNoAdminPattern: string = inputValidationHelper.groupIdNoAdminFormatRegEx;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then(cb => {
                if (cb) {
                    this.fileContent = cb[0];
                    this.getAADUser();
                }
            });
        });
        this.businessRulesService.getSpecificListByKey('RELEASE-RINGS', 'ReleaseRings')
            .subscribe(response => { this.releaseRings = response; });
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = [];
    }

    clearFileContentForms() {
        this.fileContent = {
            GroupID: '',
            GroupIDDisplayName: '',
            GroupIDDisplayNameError: '',
            ExtensionAttribute11: '',
            ReleaseRing: 'R4',
            TicketID: ''
        }
    }

    public setBackEmptyForm() {
        this.fileContent.GroupIDDisplayName = '';
        this.fileContent.GroupIDDisplayNameError = '';
        this.fileContent.ExtensionAttribute11 = '';
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    addFileContentToList() {
        var tempObject = new Object(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    getAADUser() {
        if (this.fileContent.GroupID) {
            let user = null;
            this.graphApiService.getUser(this.fileContent.GroupID.toString()).subscribe(
                response => {
                    user = response;
                    if (!user) {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on EntraID!';
                        return;
                    }
                    this.fileContent.GroupIDDisplayName = user.displayName;
                    this.fileContent.GroupIDDisplayNameError = '';
                    this.fileContent.ExtensionAttribute11 = user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase();
                },
                error => {
                    if (error && error.status && error.status == '404') {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on AzureAD!';
                    }
                    else {
                        this.fileContent.GroupIDDisplayNameError = 'Error when trying to check the user!';
                    }
                    this.fileContent.GroupIDDisplayName = '';
                }
            )
        }
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false) {
            this.addFileContentToList();
        }

        var data = {
            requests: this.fileContentsList,
            localIT: this.selectedLocalIT
        }

        this.requestContainerService.sendProductRequest(data, '/setUserRing')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}