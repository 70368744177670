export class DeleteO365ObjectDetails{
    GroupID: string = '';
    Admin: string = '';
    NewOwnerAlias: string = '';
    TransferredAADSecurityGroupOwnerships: Array<string> = new Array<string>();
    TransferredAADApplicationOwnerships: Array<string> = new Array<string>();
    TransferredAADServicePrincipalsOwnerships: Array<string> = new Array<string>();
    TransferredGuestSponsorships: Array<string> = new Array<string>();
    DeletedPersonalSendasDistributionGroups: Array<string> = new Array<string>();
    OwnedSynchronizedSecurityGroups: Array<string> = new Array<string>();

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.GroupID = requestItemOutput.GID ? requestItemOutput.GID : '';
            this.Admin = requestItemOutput.Admin ? requestItemOutput.Admin : '';
            this.NewOwnerAlias = requestItemOutput.NewOwnerAlias ? requestItemOutput.NewOwnerAlias : '';
            if(Array.isArray(requestItemOutput.TransferredAADSecurityGroupOwnerships)){
                this.TransferredAADSecurityGroupOwnerships = requestItemOutput.TransferredAADSecurityGroupOwnerships;
            }else if (requestItemOutput.TransferredAADSecurityGroupOwnerships){
                this.TransferredAADSecurityGroupOwnerships.push(requestItemOutput.TransferredAADSecurityGroupOwnerships);
            }
            if(Array.isArray(requestItemOutput.TransferredAADApplicationOwnerships)){
                this.TransferredAADApplicationOwnerships = requestItemOutput.TransferredAADApplicationOwnerships;
            }else if (requestItemOutput.TransferredAADApplicationOwnerships){
                this.TransferredAADApplicationOwnerships.push(requestItemOutput.TransferredAADApplicationOwnerships);
            }
            if(Array.isArray(requestItemOutput.TransferredAADServicePrincipalsOwnerships)){
                this.TransferredAADServicePrincipalsOwnerships = requestItemOutput.TransferredAADServicePrincipalsOwnerships;
            }else if (requestItemOutput.TransferredAADServicePrincipalsOwnerships){
                this.TransferredAADServicePrincipalsOwnerships.push(requestItemOutput.TransferredAADServicePrincipalsOwnerships);
            }
            if(Array.isArray(requestItemOutput.TransferredGuestSponsorships)){
                this.TransferredGuestSponsorships = requestItemOutput.TransferredGuestSponsorships;
            }else if (requestItemOutput.TransferredGuestSponsorships){
                this.TransferredGuestSponsorships.push(requestItemOutput.TransferredGuestSponsorships);
            }
            if(Array.isArray(requestItemOutput.DeletedPersonalSendasDistributionGroups)){
                this.DeletedPersonalSendasDistributionGroups = requestItemOutput.DeletedPersonalSendasDistributionGroups;
            }else if (requestItemOutput.DeletedPersonalSendasDistributionGroups){
                this.DeletedPersonalSendasDistributionGroups.push(requestItemOutput.DeletedPersonalSendasDistributionGroups);
            }
            if(Array.isArray(requestItemOutput.OwnedSynchronizedSecurityGroups)){
                this.OwnedSynchronizedSecurityGroups = requestItemOutput.OwnedSynchronizedSecurityGroups;
            }else if (requestItemOutput.OwnedSynchronizedSecurityGroups){
                this.OwnedSynchronizedSecurityGroups.push(requestItemOutput.OwnedSynchronizedSecurityGroups);
            }
        }
    }
}