import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/customerprofiles";
export const API_VERSION = "/v1";

@Injectable()  
export class CustomerProfilesService {  
  constructor(private http: HttpClient) { }

  getAllCustomerProfiles() : Observable<any>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getAllCustomerProfiles';
    return this.http.get(request) as Observable<any>;
  }

  getCustomerProfileById(id) : Observable<any>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getCustomerProfileById';
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(request, {params: params}) as Observable<any>;
  }

  addOrUpdateCustomerProfile(data){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addOrUpdateCustomerProfile';
    return this.http.post(request, data)
      .pipe(map(response => { return response }));
  }

  deleteCustomerProfileById(id){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteCustomerProfileById';
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(request, {params: params}) as Observable<any>;
  }

  importCustomerProfile(groupID){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/importCustomerProfile';
    let params = new HttpParams();
    params = params.append('groupID', groupID);
    return this.http.get(request, {params: params}) as Observable<any>;
  }

  importDefaultCustomerProfile(){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/importDefaultCustomerProfile';
    return this.http.get(request) as Observable<any>;
  }
}