import { Component, Input } from '@angular/core';
import { AddAADGroupMemberDetails } from '../../../../Models/History/AddAADGroupMemberDetails';

@Component({
    selector: 'AddAADGroupMemberHistoryDetails',
    templateUrl: './addAADGroupMemberHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class AddAADGroupMemberHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: AddAADGroupMemberDetails;
    productCustomInfoCollapsed: boolean = false;
}