import { RequestBase } from './RequestBase';

export class RequestItem extends RequestBase {
    RequestContainerGUID;
    ProductName;
    ProductFamily;
    ScopeValidation;
    Dependencies;
    SanityCheck;
    ExecuteBlock;
    EventReceivers;
    Jobs;
    Parameters;
    History;
    ChildProducts;
    Output;
    RequestContainerDateCreated;
    Items;
    
    constructor(_key, GUID, Type, Status, MaxOldDate, ExpirationDate, DateCreated, DateModified, Feedback, LastOperationTime, Server, Source, Requestor, LocalIT, 
                RequestContainerGUID, ProductName, ProductFamily, ScopeValidation, Dependencies, SanityCheck, ExecuteBlock, EventReceivers, Jobs, Parameters, 
                History, ChildProducts, Output, RequestContainerDateCreated,Items) 
    {
        super(_key, GUID, Type, Status, MaxOldDate, ExpirationDate, DateCreated, DateModified, Feedback, LastOperationTime, Server, Source, Requestor, LocalIT);

        this.RequestContainerGUID = RequestContainerGUID;
        this.ProductName = ProductName;
        this.ProductFamily = ProductFamily;
        this.ScopeValidation = ScopeValidation;
        this.Dependencies = Dependencies;
        this.SanityCheck = SanityCheck;
        this.ExecuteBlock = ExecuteBlock;
        this.EventReceivers = EventReceivers;
        this.Jobs = Jobs;
        this.Parameters = Parameters;
        this.History = History;
        this.ChildProducts = ChildProducts;
        this.Output = Output;
        this.RequestContainerDateCreated = RequestContainerDateCreated;
        this.Items = Items;
    }
}

export class RequestItemPreview {
    _key;
    GUID;
    Requestor;
    Status;
    Target;
    CreatedOn;
    CreatedOnDate;
    ModifiedOn;
    ModifiedOnDate;
    ProductName;
    LocalIT;
    TicketID;

    constructor(_key?, GUID?, Requestor?, Status?, Target?, CreatedOn?, ModifiedOn?, ProductName?, LocalIT?){
        this._key = _key ? _key : '';
        this.GUID = GUID ? GUID : '';
        this.Requestor = Requestor ? Requestor : '';
        this.Status = Status ? Status : '';
        this.Target = Target ? Target : '';
        this.CreatedOnDate = CreatedOn ? CreatedOn : '';
        this.CreatedOn = CreatedOn ? CreatedOn : '';
        this.ModifiedOnDate = ModifiedOn ? ModifiedOn : '';
        this.ModifiedOn = ModifiedOn ? ModifiedOn : '';
        this.ProductName = ProductName ? ProductName : '';
        this.LocalIT = LocalIT ? LocalIT : '';
    }
}