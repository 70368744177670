<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST INFORMATION
                    <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!productCustomInfoCollapsed">
            <tr>
                <th class="historyTableColWidthLimitation">DistributionList ID</th>
                <td>{{ productCustomHistoryDetails.DistributionList }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">New Primary SMTP Address</th>
                <td>{{ productCustomHistoryDetails.NewPrimarySMTP }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Old Primary SMTP Address</th>
                <td>{{ productCustomHistoryDetails.OldPrimarySMTP }}</td>
            </tr>
            <tr>
                <th class="historyTableColWidthLimitation">Mail Addresses</th>
                <td>
                    <div *ngFor="let mailAddress of productCustomHistoryDetails.EmailAddresses">
                        {{ mailAddress }}<br />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>