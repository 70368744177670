import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { environment } from "../../../../environments/environment";

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import { checkGroupIdFormat, groupIdFormatRegEx } from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './GetLicensesDetails.component.html',
    providers: [RequestContainerService, BusinessRulesService],
    styleUrls: ['../../../app.component.css']
})

export class GetLicensesDetailsComponent {
    
    
    
    
    
    
    

    //global variable
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public userLicenses = [];
    public subscribedLicenses = [];
    public groupId = "";
    public formatedLicenses = [];
    public formatedApps = [];
    public assignableLicenses: Array<any> = new Array<any>();
    public detailsPlans: Array<any> = new Array<any>();
    public detailsLicenses: Array<any> = new Array<any>();
    public getButtonDisabled = false;
    public groupIDDisplayName = "";

    public groupIDPattern: string = groupIdFormatRegEx;
    window: any = window;


    constructor(private businessRulesService: BusinessRulesService, private graphApiService: GraphApiService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => { this.currentUserInfo = updatedUserInfo });
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => { this.selectedLocalIT = updatedLocalIT });
        this.graphApiService.getSubscribedLicenses().subscribe(subscribedLicenses => { this.subscribedLicenses = subscribedLicenses });
        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'AvailableLicenses').subscribe(availableLicenses => { this.assignableLicenses = availableLicenses; });
        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'DetailsPlans').subscribe(detailsPlans => { this.detailsPlans = detailsPlans; });
        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'DetailsLicenses').subscribe(detailsLicenses => { this.detailsLicenses = detailsLicenses; });
    }

    ngOnDestroy() {
        
        
        
        
        
        
        
    }

    async getUserLicenses() {
        let user = this.groupId + "@" + environment.domain;
        await this.graphApiService.getUserLicenses(user).subscribe(userLicenses => { this.userLicenses = userLicenses, this.formatLicenses(), this.getButtonDisabled = true; }, error => {this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error }, }); this.clear()});
        let groupIDValueCopy = this.groupId;
        this.graphApiService.getUserDisplayNameByGroupID(groupIDValueCopy.toString()).subscribe(response => {this.groupIDDisplayName = response;});
    }

    clear() {
        this.formatedLicenses = [];
        this.formatedApps = [];
        this.userLicenses = [];
        this.getButtonDisabled = false;
        this.groupIDDisplayName = "";
    }

    formatLicenses() {
        this.formatedLicenses = [];
        this.formatedApps = [];
        this.subscribedLicenses.forEach(license => {
            let assignedLicense = this.userLicenses.find(obj => obj.skuPartNumber == license.skuPartNumber);
                let licenseDetails = this.detailsLicenses.find(obj => obj.SkuId == license.skuId);
                if (assignedLicense) {
                this.formatedLicenses.push({
                    skuId: license.skuId,
                    skuPartNumber: license.skuPartNumber,
                    displayName: licenseDetails ? licenseDetails.LicenseDisplayName : license.skuPartNumber,
                    assigned: assignedLicense ? true : false
                });
            }
            if (assignedLicense) {
                assignedLicense.servicePlans.forEach(plan => {
                    let app = this.formatedApps.find(obj => obj.servicePlanId == plan.servicePlanId);
                    if (app) {
                        let index = this.formatedApps.indexOf(app);
                        if (plan.provisioningStatus != "Disabled" && plan.provisioningStatus != "PendingActivation") {
                            this.formatedApps[index].assigned = true;
                        }
                        this.formatedApps[index].container.push(licenseDetails ? licenseDetails.LicenseDisplayName : license.skuPartNumber);
                    }
                    else {
                        let planDetails = this.detailsPlans.find(obj => obj.servicePlanId == plan.servicePlanId);
                        this.formatedApps.push({
                            servicePlanId: plan.servicePlanId,
                            servicePlanName: plan.servicePlanName,
                            displayName: planDetails ? planDetails.ServiceDisplayName : plan.servicePlanName,
                            container: [licenseDetails ? licenseDetails.LicenseDisplayName : license.skuPartNumber],
                            assigned: plan.provisioningStatus != "Disabled" && plan.provisioningStatus != "PendingActivation" ? true : false
                        })
                    }
                });
            }
        });
        this.formatedLicenses.sort(function (a, b) {
            var x = a.displayName.toLowerCase();
            var y = b.displayName.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        this.formatedApps.sort(function (a, b) {
            var x = a.displayName.toLowerCase();
            var y = b.displayName.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
    }
}