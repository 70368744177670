<h4>Delete Zscaler Tunneling:</h4>
<br /><br />
<form #fileContentForm="ngForm">
    
    <div class="center" *ngIf="isWait">
        <mat-spinner diameter=50 
                              mode="indeterminate"
                              color="primary">
        </mat-spinner> 
    </div>

    <div class="row form-group col-4">
        <mat-form-field>
            <mat-label>Destinations to remove</mat-label>
            <mat-chip-grid #chipList aria-label="Urls selection">
            <mat-chip-row
                *ngFor="let rurl of fileContent.Urls"
                selectable
                removable
                (removed)="remove(rurl)">
                {{rurl}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
                placeholder="Destinations..."
                #urlchipList
                [formControl]="myControl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList">
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let url of filteredOptions | async" [value]="url">
                {{url}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="row form-group">                
        <div class="col-2 offset-8" style="padding-top:33px;float: left;">
            <button [disabled]="fileContent.Urls.length == 0" mat-button (click)="openDialog()" class="btn btn-primary">Send Request</button>  
        </div>
    </div>
</form>