import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../../../Services/home.service';
import { TopBarMessage, TopBarMessagePreview } from '../../../Models/TopBarMessage';

import { TopBarMessageDialog } from './TopBarMessageDialog/topBarMessageDialog.component';
import { UserService } from '../../../Services/user.service';
import { UserInfo } from '../../../Models/UserInfo';

@Component({
  selector: 'BodyContent',
  templateUrl: './opBarMessagesNew.component.html',
  styleUrls: ['../../../app.component.css']
})

export class TopBarMessagesNewComponent implements OnInit {
  public refreshButtonDisabled: boolean = false;
  private requestItems: TopBarMessage[] = new Array<TopBarMessage>();
  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: 'Title', name: 'Title', filtering: { filterString: '', placeholder: 'Title' }, sort: '', className: 'groupidColumnWidth' },
    { title: 'Message', name: 'Message', filtering: { filterString: '', placeholder: 'Message' }, sort: '', className: 'productColumnWidth' },
    { title: 'Start Date', name: 'StartDateTime', filtering: { filterString: '', placeholder: 'Start Date' }, sort: '', className: 'groupidColumnWidth' },
    { title: 'End Date', name: 'EndDateTime', filtering: { filterString: '', placeholder: 'End Date' }, sort: '', className: 'groupidColumnWidth' },
    { title: 'Show Now', name: 'Show', filtering: { filterString: '', placeholder: 'Show Now' }, sort: '', className: 'groupidColumnWidth' }
  ];
  public page: number = 1;
  public itemsPerPage: number = 30;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };

  private data: Array<any> = new Array<any>();
  public currentUserInfo: UserInfo = new UserInfo();



  public constructor(public dialog: MatDialog, private homeService: HomeService, private userService: UserService) {
      this.dialog.afterAllClosed.subscribe(refresh => this.refreshData());
  }

  //Mappeur
  mapRequestItemToRequestItemPreview(TopBarMessages: TopBarMessage[]): Array<any> {
    let firstShow = false;
    let requestItemPreviewList = new Array<TopBarMessagePreview>();
    TopBarMessages.forEach(element => {
      let requestItemPreview = new TopBarMessagePreview(element);
      if(element.Show && !firstShow){
        requestItemPreviewList.unshift(requestItemPreview);
        firstShow = true;
      }else{
        requestItemPreviewList.push(requestItemPreview);
      }  
    });
    return requestItemPreviewList;
  }

  refreshData() {
    this.homeService.getAllMessagesInfo().subscribe(response => {
      this.data = this.mapRequestItemToRequestItemPreview(response);
      this.length = this.data.length;
      this.onChangeTable(this.config);
    });

    this.columns = [
      { title: 'Title', name: 'Title', filtering: { filterString: '', placeholder: 'Title' }, sort: '', className: 'groupidColumnWidth' },
      { title: 'Message', name: 'Message', filtering: { filterString: '', placeholder: 'Message' }, sort: '', className: 'productColumnWidth' },
      { title: 'Start Date', name: 'StartDateTime', filtering: { filterString: '', placeholder: 'Start Date' }, sort: '', className: 'groupidColumnWidth' },
      { title: 'End Date', name: 'EndDateTime', filtering: { filterString: '', placeholder: 'End Date' }, sort: '', className: 'groupidColumnWidth' },
      { title: 'Show Now', name: 'Show', filtering: { filterString: '', placeholder: 'Show Now' }, sort: '', className: 'groupidColumnWidth' }
    ];

    //Refresh the sorting onfiguration with the ne column and update de table with the new config
    if (this.config) {
      this.config.sorting = { columns: this.columns };
    }

    this.onChangeTable(this.config);
  }

  public ngOnInit(): void {
    this.refreshData();
   // this.config.sorting = 
    this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
    this.onChangeTable(this.config);
  }

  public ngOnDestroy() {
  }

  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public changeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name]) {
            return (item[column.name]).toUpperCase().match((column.filtering.filterString.toUpperCase()));
          }
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) => {
        if (item[config.filtering.columnName]) {
          item[config.filtering.columnName].toUpperCase().match(this.config.filtering.filterString.toUpperCase());
        }
      });
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name].toString().toUpperCase().match(this.config.filtering.filterString.toUpperCase())) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }

  public onCellClick(data: any): any {
    if (data && data.row && data.row.id) {
      this.openTopBarMessageDialog(data.row.id);
    }
  }

  //Popup Initialization
  openTopBarMessageDialog(messageID) {
    const dialogRef = this.dialog.open(TopBarMessageDialog, {
      height: '73%',
      width: '80%',
      data: {
        messageID: messageID,
        isAdmin: this.currentUserInfo.IsAdmin
      }
    });
  }

  disableRefreshBtn() {
    this.refreshButtonDisabled = true;
    setTimeout(() => { this.refreshButtonDisabled = false }, 2000);
  }
}