export class EnableArchiveOnlineDetails{
    Mailbox: string = '';
    ArchiveName: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.Mailbox = requestItemOutput.Mailbox;
            this.ArchiveName = requestItemOutput.ArchiveName;   
        }
    }
}