﻿<div class="row">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="distributionListInfoCollapsed=!distributionListInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST MEMBERS INFORMATION ({{ length }})
                    <i *ngIf="distributionListInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!distributionListInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!distributionListInfoCollapsed">
            <tr>
                <br />
                <div class="row">
                    <div class="col-3">
                        <input *ngIf="config.filtering" placeholder="Filter all columns"
                            [ngTableFiltering]="config.filtering" class="form-control"
                            (tableChanged)="onChangeTable(config,{page: 1, itemsPerPage: itemsPerPage})" />
                    </div>
                    <div *ngIf="hiddenColumn.filtering && recursion()" class="col-3">
                        <select id="ParentsDropdownList" class="form-control"
                            [(ngModel)]="hiddenColumn.filtering.filterString" name="ParentsDropdownList" (change)="onChangeTable($event,{page: 1, itemsPerPage: itemsPerPage})">
                            <option value="" selected="selected" disabled hidden>Filter childeren of:</option>
                            <option *ngFor="let parent of parents" [value]="parent">{{parent}}</option>
                        </select>
                    </div>
                    <div *ngIf="recursion()" class="col-4 offset-2">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-primary" (click)="exportDistributionListMembers()">Export Filtered Members</button>
                        </div>
                    </div>
                    <div *ngIf="!recursion()" class="col-4 offset-5">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-primary" (click)="exportDistributionListMembers()">Export Filtered Members</button>
                        </div>
                    </div>
                </div>
                <br />
                <table class="table" ngClass="{{config.className || ''}}" role="grid" style="width: 100%;" >
                    <thead>
                        <tr role="row">
                            <th *ngFor="let column of columns">
                                {{column.title}}
                            </th>
                            <th style="width: 30px;">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngFor="let column of columns">
                                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                                    [ngTableFiltering]="column.filtering" class="form-control" (tableChanged)="onChangeTable($event,{page: 1, itemsPerPage: itemsPerPage})" style="width: 100%;" />
                            </td>
                            <td></td>
                        </tr>
                        <ng-container *ngFor="let row of rows; let i = index; first as isFirst; trackBy:trackByIndex"> 
                            <tr [ngStyle]="{'color':row.Parents ? row.Parents.length == 1 ? 'blue' : row.Parents.length == 2 ? 'fuchsia' : row.Parents.length == 3 ? 'limegreen' : 'red' : 'black'}" title="{{row.Parents ? 'Parents: ' + row.Parents : null}}">
                                <td *ngFor="let column of columns">
                                    {{row[column.name]}}</td>
                                <td *ngIf="row.MemberCount && row.MemberCount > 0">
                                    <span (click)="collapseOrExpand(row.PrimarySmtpAddress)">
                                        <i *ngIf="!row.Expanded" style="float: right;"
                                            class="material-icons md-36 pointer">chevron_right</i>
                                        <i *ngIf="row.Expanded" style="float: right;"
                                            class="material-icons md-36 pointer">expand_more</i>
                                    </span>
                                </td>
                                <td *ngIf="!row.MemberCount"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <pagination *ngIf="config.paging" 
                            class="pagination-sm"
                            [(ngModel)]="page"
                            [totalItems]="length"
                            [itemsPerPage]="itemsPerPage"
                            [maxSize]="maxSize"
                            [boundaryLinks]="true"
                            [rotate]="false"
                            (pageChanged)="onChangeTable(config, $event)"
                            (numPages)="numPages = $event">
                </pagination>
                <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
            </tr>
        </tbody>
    </table>
</div>