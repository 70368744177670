import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { MailboxMailAddressesFileContent, MailboxMailAddressesRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './addMailboxMailAddresses.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddMailboxMailAddressesComponent{
    
    
    
    

    public fileContentsList = new Array<MailboxMailAddressesFileContent>();
    public fileContent = new MailboxMailAddressesFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<MailboxMailAddressesFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new MailboxMailAddressesFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }
    
    addFileContentToList(){
        var tempObject = new MailboxMailAddressesFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }
    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError){
            this.addFileContentToList();
        }

        var data = new MailboxMailAddressesRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/addMailboxMailAddresses')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }
}