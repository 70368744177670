export class CreatePersonalMailboxDetails{
    DisplayName: string = '';
    Alias: string = '';
    PrimarySMTP: string = '';
    MOERA: string = '';
    MailboxType: string = '';
    LicenseReconciliationNeeded: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.DisplayName = requestItemOutput.DisplayName ? requestItemOutput.DisplayName : '';
            this.Alias = requestItemOutput.Alias ? requestItemOutput.Alias : '';
            this.PrimarySMTP = requestItemOutput.PrimarySMTP ? requestItemOutput.PrimarySMTP : '';
            this.MOERA = requestItemOutput.MOERA ? requestItemOutput.MOERA : '';
            this.MailboxType = requestItemOutput.MailboxType ? requestItemOutput.MailboxType : '';
            this.LicenseReconciliationNeeded = requestItemOutput.LicenseReconciliationNeeded ? requestItemOutput.LicenseReconciliationNeeded : '';
        }
    }
}