import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const APIV2_BASE_URL = environment.apiv2_base_url;
export const API_NAME = "/graphAPis";
export const API_VERSION = "/v1";

@Injectable()
export class GraphApiService {
    constructor(private http: HttpClient) {}
    
    getUserDisplayNameByGroupID(groupID:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDisplayNameByGroupID';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<string>;
    }

    getUserDisplayNameByMail(mail:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDisplayNameByMail';
        let params = new HttpParams();
        params = params.append('mail', mail);
        return this.http.get(request, {params: params}) as Observable<string>;
    }

    getUserDisplayNameByGuid(guid:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDisplayNameByGuid';
        let params = new HttpParams();
        params = params.append('guid', guid);
        return this.http.get(request, {params: params}) as Observable<string>;
    }

    getUserDisplayNameByUPN(upn:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDisplayNameByUPN';
        let params = new HttpParams();
        params = params.append('upn', upn);
        return this.http.get(request, {params: params}) as Observable<string>;
    }

    getDeletedAzureADGroups(localIT:string,orgID:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDeletedAzureADGroups';
        let params = new HttpParams();
        params = params.append('localIT', localIT);
        params = params.append('orgID', orgID);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getGroupByDisplayName(displayName:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getGroupByDisplayName';
        let params = new HttpParams();
        params = params.append('displayName', displayName);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getGroupsManageableByLocalIT(localIT:string,orgID:string,product:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getGroupsManageableByLocalIT';
        let params = new HttpParams();
        params = params.append('localIT', localIT);
        params = params.append('orgID', orgID);
        params = params.append('product', product);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getGroupByObjectId(objectId:string):Observable<any>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getGroupByObjectId';
        let params = new HttpParams();
        params = params.append('objectId', objectId);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    getUserByGroupID(groupID:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserByGroupID';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    getUser(GroupID:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUser';
        let params = new HttpParams();
        params = params.append('GroupID', GroupID);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    getUserLicenses(user:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserLicenses';
        let params = new HttpParams();
        params = params.append('user', user);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getSubscribedLicenses():Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getSubscribedLicenses';
        return this.http.get(request) as Observable<any[]>;
    }

    changeLicensePlans(data):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/changeLicensePlans';
        return this.http.post(request,data) as Observable<any[]>;
    }

    addAADGroupMember(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/addAADGroupMember';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    removeAADGroupMember(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/removeAADGroupMember';
        //let params = new HttpParams();
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getAADUserMembership(GroupID:string,StartsWith:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAADUserMembership';
        let params = new HttpParams();
        params = params.append('GroupID', GroupID);
        params = params.append('StartsWith', StartsWith);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getAADUserMembershipWithFilter(GroupID:string,Filter:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAADUserMembershipWithFilter';
        let params = new HttpParams();
        params = params.append('GroupID', GroupID);
        params = params.append('Filter', Filter);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getAADUserOwnedObjects(GroupID:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAADUserOwnedObjects';
        let params = new HttpParams();
        params = params.append('GroupID', GroupID);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getAADGuestUserSponsorships(id:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAADGuestUserSponsorships';
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    createAADGroup(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/createAADGroup';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    createAADDynamicGroup(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/createAADDynamicGroup';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    addMWUser(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/addMWUser';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    deleteAzureADGroup(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteAzureADGroup';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    undeleteAzureADGroup(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/undeleteAzureADGroup';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getAADUserOwnedDevices(groupID:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAADUserOwnedDevices';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<any>;
    }

    removeAzureADDevice(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/removeAzureADDevice';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    setUserRing(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/setUserRing';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getDevicesForLocalIT(localIT:string):Observable<any[]>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDevicesForLocalIT';
        let params = new HttpParams();
        params = params.append('localIT', localIT);
        return this.http.get(request, {params: params}) as Observable<any[]>;
    }

    getBitlockerKey(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getBitlockerKey';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getLocalAdminPassword(data):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getLocalAdminPassword';
        return this.http.post(request,data).pipe(map(response => { return response }));
    }

    getDistributionListInfo(listID:string, localIT:any):Observable<Object>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getDistributionListInfo';
        let params = new HttpParams();
        params = params.append('listID', listID);
        params = params.append('localIT', localIT);
        return this.http.get(request, {params: params}) as Observable<any[]>;
        // return this.http.post(request,data).pipe(map(response => { return response }))
        // .catch((error: any) => { return error });
    }

    getAdditionalAccounts(groupID:string):Observable<string>{
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getAdditionalAccounts';
        let params = new HttpParams();
        params = params.append('GroupID', groupID);
        return this.http.get(request, {params: params}) as Observable<string>;
    }
}