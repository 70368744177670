<h4>Change Mailbox Primary SMTP:</h4>

<!-- ADD NEW FILECONTENT FORM -->
<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID or email address:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID"
                    name="groupIDInput"
                    (keyup)="setBackEmptyForm()"
                    required 
                    [pattern]="groupIdNoAdminOrMailPattern" #groupIDInput="ngModel"/>
                <i *ngIf="!groupIDInput.invalid" class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="getUserInAAD();">search</i>
            </div>
            <div *ngIf="groupIDInput.invalid && (groupIDInput.dirty || groupIDInput.touched)"
                class="alert alert-danger">
                <div *ngIf="groupIDInput.errors.pattern">
                    GroupID is invalid.
                </div>  
                <div *ngIf="groupIDInput.errors.required">
                    GroupID is required.
                </div>          
            </div>
            <small *ngIf="fileContent.GroupIDDisplayName"
                class="form-text text-primary">{{ fileContent.GroupIDDisplayName }}</small>
            <small class="form-text text-danger">{{userNotFound}}</small>
        </div>
        <div class="col-4" *ngIf="isUserInAAD === true">
            <label class="required" for="primarySmtpAddressInput">Primary SMTP Address:</label>
            <input type="text" id="primarySmtpAddressInput" name="primarySmtpAddressInput" class="form-control"
                [(ngModel)]="fileContent.PrimarySmtp" #primarySmtpAddressInput="ngModel" required>
                    
            <div *ngIf="primarySmtpAddressInput.invalid && (primarySmtpAddressInput.dirty || primarySmtpAddressInput.touched)"
                class="alert alert-danger">
            
                <div *ngIf="primarySmtpAddressInput.errors.required">
                    Primary SMTP Address is required.
                </div>          
            </div>
        </div>
        <div class="col-4" *ngIf="isUserInAAD === true && isRoomOrFunctional === true">
            <label for="sipAddressInput">SIP Address:</label>
            <input type="text" id="sipAddressInput" name="sipAddressInput" class="form-control"
                [(ngModel)]="fileContent.SIPAddress" #sipAddressInput="ngModel">
                    
            <div *ngIf="sipAddressInput.invalid && (sipAddressInput.dirty || sipAddressInput.touched)"
                class="alert alert-danger">         
            </div>
        </div>    
        <div class="col-4" *ngIf="isUserInAAD === true">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <div class="row form-group" *ngIf="isUserInAAD === true">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOX READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }}</b> - 
            {{ listedFileContent.PrimarySmtp }}
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID or email address:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Primary SMTP Address:</b> {{ listedFileContent.PrimarySmtp }}
                </div>
                <div class="col-4" *ngIf="isUserInAAD === true && isRoomOrFunctional === true">
                    <b>SIP Address:</b> {{ listedFileContent.SIPAddress }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
        </div>
    </div>
</div>