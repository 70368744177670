import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { MatOption } from '@angular/material/core';
import { UntypedFormControl, NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';

import { CreateOktaCloudAccountFileContent, CreateOktaCloudAccountRequest } from '../../../Models/FileContents';

import { booleanToStringConverter } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';

@Component({
    selector: 'BodyContent',
    templateUrl: './updateOktaCloudAccount.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class UpdateOktaCloudAccountComponent {
    public profileTypes: Array<string> = new Array<string>();
    public usages: Array<string> = new Array<string>();

    public requestContainer: any = {};
    public fileContentsList = new Array<CreateOktaCloudAccountFileContent>();
    public fileContent = new CreateOktaCloudAccountFileContent(null, true);
    public GBUs: Array<any> = new Array<any>();
    public OrgIDs = new Array();
    public companies: Array<any> = new Array<any>();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public maxDate: string = '1 year: ' + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0];
    myControl = new UntypedFormControl();
    filteredOptions: Observable<string[]>;

    public groupIDPattern: string = inputValidationHelper.groupIdFormatRegEx;
    public requestParameters;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private webAdministrationService: WebAdministrationService, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
        this.myControl.setValidators(this.forbiddenNamesValidator());
    }

    forbiddenNamesValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (typeof control.value === "string") {
                if (control.value) {
                    if (this.fileContent.ProfileType == 'Internal' && !(this.companies.some(item => item == control.value))) {
                        return { 'forbiddenNames': { value: control.value } };
                    }
                    else {
                        this.fileContent.Company = control.value;
                    }
                }
            }
            return null;
        };
    }

    private _filter(value: any): string[] {
        if (value) {
            if (typeof value === "string") {
                const filterValue = value.toLowerCase();
                return this.companies.filter(option => option.toLowerCase().includes(filterValue));
            }
        }
        else {
            return this.companies;
        }
    }

    private _filterExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.companies.filter(option => option.toLowerCase() == filterValue).length > 0) {
                return this.companies.filter(option => option.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private _filterBuOrGBUExactMatch(arrayToFilter:Array<any>, value: any): string[] {
        if (arrayToFilter && Array.isArray(arrayToFilter) && value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (arrayToFilter.filter(option => option.Code.toLowerCase() == filterValue).length > 0) {
                return arrayToFilter.filter(option => option.Code.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private _filterOrgIdExactMatch(value: any): string[] {
        if (value && typeof value === "string") {
            const filterValue = value.toLowerCase();
            if (this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue).length > 0) {
                return this.OrgIDs.filter(option => option.OrgID.toLowerCase() == filterValue);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    fillFormBasedOnGroupID(groupIDUserProfile) {
        if (this.requestParameters) {
            this.requestParameters = null;
            if (this.fileContent.Company && Array.isArray(this._filterExactMatch(this.fileContent.Company))) {
                this.myControl.setValue(this._filterExactMatch(this.fileContent.Company)[0]);
            }

            if (this.fileContent.GBU && Array.isArray(this._filterBuOrGBUExactMatch(this.GBUs, this.fileContent.GBU))) {
                this.webAdministrationService.getEntities({ GBU: this.fileContent.GBU.toUpperCase(), Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                    this.OrgIDs = entity?.body;
                    this.fileContent.GBU = (this._filterBuOrGBUExactMatch(this.GBUs, this.fileContent.GBU)[0] as any).Code;
                    if (this.fileContent.OrgID && Array.isArray(this._filterOrgIdExactMatch(this.fileContent.OrgID))) {
                        this.fileContent.OrgID = (this._filterOrgIdExactMatch(this.fileContent.OrgID)[0] as any).OrgID;
                    }
                }, (error) => console.log(error));
            }
        }
        else if (groupIDUserProfile) {
            this.fileContent.Usage = groupIDUserProfile.C_O365Flag && groupIDUserProfile.C_O365Flag == 1 ? 'O365/AZURE' : 'OKTA APPLICATION';
            this.fileContent.SponsorGroupID = groupIDUserProfile.C_sponsorGID ? groupIDUserProfile.C_sponsorGID : '';
            this.fileContent.ProfileType = groupIDUserProfile.userType ? groupIDUserProfile.userType == 'I' ? 'Internal' : 'External' : '';
            this.fileContent.LastName = groupIDUserProfile.lastName ? groupIDUserProfile.lastName : '';
            this.fileContent.FirstName = groupIDUserProfile.firstName ? groupIDUserProfile.firstName : '';
            this.fileContent.EmailAddress = groupIDUserProfile.email ? groupIDUserProfile.email : '';
            this.fileContent.ExternalCompany = groupIDUserProfile.C_comment ? groupIDUserProfile.C_comment : groupIDUserProfile.C_externalCompany ? groupIDUserProfile.C_externalCompany : '';
            let company = groupIDUserProfile.organization ? groupIDUserProfile.organization : '';
            if (company && Array.isArray(this._filterExactMatch(company))) {
                this.myControl.setValue(this._filterExactMatch(company)[0]);
                this.fileContent.Company = company
            } else {
                this.myControl.reset('');
                this.fileContent.Company = '';
            }

            if (groupIDUserProfile.C_msDSCloudExtensionAttribute3 && Array.isArray(this._filterBuOrGBUExactMatch(this.GBUs, groupIDUserProfile.C_msDSCloudExtensionAttribute3))) {
                this.fileContent.GBU = groupIDUserProfile.C_msDSCloudExtensionAttribute3;
                this.webAdministrationService.getEntities({ GBU: groupIDUserProfile.C_msDSCloudExtensionAttribute3, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                    this.OrgIDs = entity?.body;
                    if (groupIDUserProfile.C_msDSCloudExtensionAttribute2 && Array.isArray(this._filterOrgIdExactMatch(groupIDUserProfile.C_msDSCloudExtensionAttribute2))) {
                        this.fileContent.OrgID = groupIDUserProfile.C_msDSCloudExtensionAttribute2;
                    } else {
                        this.fileContent.OrgID = '';
                    }
                }, (error) => console.log(error));
            } else {
                this.fileContent.GBU = '';
            }

            this.fileContent.CountryCode = groupIDUserProfile.countryCode ? groupIDUserProfile.countryCode : '';
            this.fileContent.ExpirationDate = groupIDUserProfile.C_accountExpires ? groupIDUserProfile.C_accountExpires : '';
        }
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.webAdministrationService.getCompanies({ Status: "Valid", itemPerPage: 5000 })
            .subscribe(response => {
                this.companies = response.body.map(item => item.Name).sort();
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value))
                    );
                this.fileContent.ProfileType = 'Internal';
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        this.requestParameters = new CreateOktaCloudAccountFileContent(cb[0],true);
                        let doc = document.getElementById("groupIDInputOkta") as HTMLInputElement;
                        if (doc) {
                            doc.value = cb[0].GroupID;
                            doc.dispatchEvent(new Event('input'));
                            let button = document.getElementsByClassName('material-icons md-36 primaryBlue pointer');
                            button[0].dispatchEvent(new Event('click'));
                        }
                    }
                });
            });

        this.webAdministrationService.getGBUs({ Status: "Valid", itemPerPage: 5000 })
            .subscribe(response => {
                this.GBUs = response.body;
            });
        
        this.businessRulesService.getSpecificListByKey('PROFILETYPES', 'AvailableProfileTypes')
            .subscribe(response => { this.profileTypes = response; });

        this.businessRulesService.getSpecificListByKey('USAGES', 'AvailableUsages')
            .subscribe(response => { this.usages = response; });
    }

    updateCompany(option: MatOption) {
        this.fileContent.Company = option.value;
    }

    getBusinessUnitOrgIDs(businessUnit: string) {
        this.webAdministrationService.getEntities({ GBU: businessUnit, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
            this.OrgIDs = entity?.body;
            this.OrgIDs = this.OrgIDs.filter(item => item["OrgID"].match(/ORG\d{4}/i));
            this.fileContent.OrgID = Array.isArray(this.OrgIDs) && this.OrgIDs.length > 0 ? this.OrgIDs[0].OrgID : '';
        }, (error) => console.log(error));
    }

    changeProfileType(profileType: string) {
        if (profileType) {
            this.myControl.reset(this.fileContent.Company);
            if (profileType == 'Internal') {
                this.maxDate = '1 year: ' + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0];
            }
            else {
                this.maxDate = '180 days: ' + new Date(new Date().setDate(new Date().getDate() + 180)).toISOString().split("T")[0];
            }
        }
    }

    ngOnDestroy() {
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateOktaCloudAccountFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new CreateOktaCloudAccountFileContent(null, true);
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    booleanToStringConverterImport(bool, falseString, trueString) {
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentList and clean the forms (Need reflexion for the Edit)
    addFileContentToList() {
        var tempObject = new CreateOktaCloudAccountFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
        this.fileContent.ProfileType = 'Internal';
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false) {
            this.addFileContentToList();
        }

        var data = new CreateOktaCloudAccountRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/updateOktaCloudAccount')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}