<div class="m-3">
    <div>
        <div class="row">
            <div class="col-7">
                <h2>Customer Profile Details :</h2>
            </div>
            <div class="col-1 offset-1">
                <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary"
                    (click)="confirmDeleteCustomerProfile()">Delete</button>
            </div>
            <div class="col-1 offset-1">
                <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary"
                    (click)="navigateToCustomerProfileEdition()">Edit</button>
            </div>
            <div class="col-1">
                <i style="float: right;" class="material-icons md-36 primaryBlue pointer"
                    (click)="closeDialog()">highlight_off</i>
            </div>
        </div>
    </div>

    <form #fileContentForm="ngForm">
        <div class="row form-group">
            <div class="col-4">
                <label for="customerProfileCompanyInput">Company:</label>
                <input type="text" id="customerProfileCompanyInput" class="form-control"
                    [(ngModel)]="customerProfile.Company" name="customerProfileCompanyInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfileBUInput">BU:</label>
                <input type="text" id="customerProfileBUInput" class="form-control" [(ngModel)]="customerProfile.BU"
                    name="customerProfileBUInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfileDiscriminantInput">Discriminant:</label>
                <input type="text" id="customerProfileDiscriminantInput" class="form-control"
                    [(ngModel)]="customerProfile.Discriminant" name="customerProfileDiscriminantInput"
                    [disabled]="true">
            </div>
        </div>

        <div class="row form-group">
            <div class="col-4">
                <label for="customerProfilePrimarySMTPDomainInput">Primary SMTP Domain:</label>
                <input type="text" id="customerProfilePrimarySMTPDomainInput" class="form-control"
                    [(ngModel)]="customerProfile.PrimarySMTPdomain" name="customerProfilePrimarySMTPDomainInput"
                    [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfilePrimaryExternalSMTPDomainInput">Primary External SMTP Domain:</label>
                <input type="text" id="customerProfilePrimaryExternalSMTPDomainInput" class="form-control"
                    [(ngModel)]="customerProfile.PrimaryExternalSMTPdomain"
                    name="customerProfilePrimaryExternalSMTPDomainInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfileOffice365LicensesInput">Office 365 Licenses:</label>
                <input type="text" id="customerProfileOffice365LicensesInput" class="form-control"
                    [(ngModel)]="customerProfile.Office365Licenses" name="customerProfileOffice365LicensesInput"
                    [disabled]="true">
            </div>
        </div>

        <div class="row form-group">
            <div class="col-4">
                <label for="customerProfileSecondarySMTPDomainInput">Secondary SMTP Domain:</label>
                <input type="text" id="customerProfileSecondarySMTPDomainInput" class="form-control"
                    [(ngModel)]="customerProfile.SecondarySMTPdomain" name="customerProfileSecondarySMTPDomainInput"
                    [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfileSecondaryExternalSMTPDomainInput">Secondary External SMTP Domain:</label>
                <input type="text" id="customerProfileSecondaryExternalSMTPDomainInput" class="form-control"
                    [(ngModel)]="customerProfile.SecondaryEXTERNALSMTPdomain"
                    name="customerProfileSecondaryExternalSMTPDomainInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="createMailboxLanguageInput">Language:</label>
                <input type="text" id="createMailboxLanguageInput" class="form-control"
                    [(ngModel)]="customerProfile.MailboxLanguage" name="createMailboxLanguageInput" [disabled]="true">
            </div>
        </div>

        <div class="row form-group">
            <div class="col-4">
                <input type="checkbox" [checked]="customerProfile.EnableArchive" [disabled]="true">
                Enable Archive
            </div>
            <div class="col-4">
                <input type="checkbox" [checked]="customerProfile.EnableLitigationHold" [disabled]="true">
                Enable Litigation Hold
            </div>
            <div class="col-4" *ngIf="customerProfile.EnableLitigationHold">
                <label for="customerProfileLitigationHoldDurationInput">Litigation Hold Duration:</label>
                <input style="float:right" type="number" id="customerProfileLitigationHoldDurationInput"
                    class="form-control" style="width:25%" [(ngModel)]="customerProfile.LitigationHoldDuration"
                    name="customerProfileLitigationHoldDurationInput" [disabled]="true">
                <span *ngIf="customerProfile.LitigationHoldDuration < 2"
                    style="float:right;position:relative;bottom: 29px;right: 210px;">Year</span>
                <span *ngIf="customerProfile.LitigationHoldDuration > 1"
                    style="float:right;position:relative;bottom: 29px;right: 210px;">Years</span>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-4">
                <label for="customerProfileDisclaimerInput">Disclaimer:</label>
                <input type="text" id="customerProfileDisclaimerInput" class="form-control"
                    [(ngModel)]="customerProfile.Disclaimer" name="customerProfileDisclaimerInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="customerProfileTimeZoneInput">Time Zone:</label>
                <input type="text" id="customerProfileTimeZoneInput" class="form-control"
                    [(ngModel)]="customerProfile.TimeZone" name="customerProfileTimeZoneInput" [disabled]="true">
            </div>
            <div class="col-4">
                <label for="deviceTypeDropdownList">Mobile Mail Client:</label>
                <input type="text" id="customerProfileMobileMailClientInput" class="form-control"
                    [(ngModel)]="customerProfile.MobileMailClient" name="customerProfileMobileMailClientInput"
                    [disabled]="true">
            </div>
        </div>
    </form>
</div>