import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function minValueValidator(min: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value == null) {
      return null;
    }
    const minVal = control.value < min;
    
    return minVal ? {'minValue': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[appMinValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true}]
})
export class MinValueValidatorDirective implements Validator {
  @Input('appMinValue') minValue: number;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.minValue ? minValueValidator(this.minValue)(control) : null;
  }
}