import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { ChangeMailboxDisplayNameFileContent, ChangeMailboxDisplayNameRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { GraphApiService } from '../../../Services/graphApi.service';

import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './changeMailboxDisplayName.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class ChangeMailboxDisplayNameComponent{
    
    
    
    

    public fileContentsList = new Array<ChangeMailboxDisplayNameFileContent>();
    public fileContent = new ChangeMailboxDisplayNameFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public message:String = '';
    public namePattern:string = inputValidationHelper.mailboxNameRegEx;
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';
    private maxCharacters = 42;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private graphApiService: GraphApiService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                if (cb) {
                    this.fileContent = cb[0];
                    this.getAADUser();
                }
            });
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<ChangeMailboxDisplayNameFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new ChangeMailboxDisplayNameFileContent();
        this.message = '';
    }

    public setBackEmptyForm() {
        this.fileContent.GroupIDDisplayName = '';
        this.fileContent.GroupIDDisplayNameError = '';
        this.fileContent.ExtensionAttribute11 = '';
        this.message = '';
        this.maxCharacters = 42;
    }

    calculateCharacters() {
        if (this.fileContent.ExtensionAttribute11.match('ROOM|EQUIMENT')) {
            this.message = `The length of Site (${this.fileContent.Site.length}) + Name (${this.fileContent.Name.length}) should not exceed ${this.maxCharacters} characters. Remaining characters: ${this.maxCharacters - this.fileContent.Site.length - this.fileContent.Name.length}`;
        }
        else {
            this.message = `The length of Name (${this.fileContent.Name.length}) should not exceed ${this.maxCharacters} characters. Remaining characters: ${this.maxCharacters - this.fileContent.Name.length}`;
        }
    }

    errorsInForm() {
        if (this.fileContent.ExtensionAttribute11.match('ROOM|EQUIMENT')) {
            return this.fileContent.Site.length + this.fileContent.Name.length > this.maxCharacters;
        }
        else {
            return this.fileContent.Name.length > this.maxCharacters;
        }
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    addFileContentToList(){
        var tempObject = new ChangeMailboxDisplayNameFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }
    
    getAADUser() {
        if (this.fileContent.GroupID) {
            let user = null;
            this.graphApiService.getUser(this.fileContent.GroupID.toString()).subscribe(
                response => {
                    user = response;
                    if (user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase() == "PERSONAL") {
                        this.fileContent.GroupIDDisplayNameError = `The mailbox type is not supported, it's a Personal Mailbox`;
                    }
                    else {
                        this.fileContent.GroupIDDisplayName = user.displayName;
                        this.fileContent.GroupIDDisplayNameError = '';
                        this.fileContent.ExtensionAttribute11 = user.onPremisesExtensionAttributes.extensionAttribute11.toUpperCase();
                        if (this.fileContent.ExtensionAttribute11.match('ROOM')) {
                            this.maxCharacters = 46;
                        }
                        else if (this.fileContent.ExtensionAttribute11.match('EQUIP')) {
                            this.maxCharacters = 45;
                        }
                        else {
                            this.maxCharacters = 42;
                        }
                        this.calculateCharacters();
                    }
                },
                error => {
                    if (error && error.status && error.status == '404') {
                        this.fileContent.GroupIDDisplayNameError = this.fileContent.GroupID.toString() + ' not found on AzureAD!';
                    }
                    else {
                        this.fileContent.GroupIDDisplayNameError = 'Error when trying to check the user!';
                    }
                    this.fileContent.GroupIDDisplayName = '';
                }
            )
        }
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false && !this.fileContent.GroupIDDisplayNameError && !this.errorsInForm()){
            this.addFileContentToList();
        }

        var data = new ChangeMailboxDisplayNameRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/changeMailboxDisplayName')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }
}