<br /><br />
<h5 class="text-warning">Warning: Adding user in intune with STANDARDPACK or ENTERPRISEPACK license will replace it with Microsoft 365 F3 license (not compatible with office 365 desktop apps). If you want to keep your desktop apps, please use the assign license product to upgrade your license.</h5>
<br /><br />

<h4>Add Intune User:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group" *ngIf="isTopLocalIT === true || isAnyLocalITFromOkta === true">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();"
                [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.GroupID)">Import Customer
                Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.GroupID)">Please fill a
                valid Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.GroupID)">Based on
                Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <span style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from
            {{importedCompanyName}} customer profile.</span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company
            doesn't have a customer profile. </span>
        <span style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer
            profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer
            profile.</span>
        <span style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import
            customer profile because this user does not exist!</span>
    </div>
    <div class="row form-group">
        <span class="alert alert-info" role="alert" *ngIf="howToUseInfo === true && isTopLocalIT === false">Please fill
            the user GroupID below and click the search button</span>
    </div>
    <div class="row form-group">
        <div class="col-4" *ngIf="isTopLocalIT === true">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event"
                (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4" *ngIf="isTopLocalIT === false">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID" required [pattern]="groupIDPattern" (keyup)="setBackEmptyForm()">
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;"
                    (click)="getLocalITManagerFromOkta();">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span>
        </div>
        <div class="col-4" *ngIf="isTopLocalIT === true || isUserInOkta === true">
            <label class="required" for="deviceTypeDropdownList">Mobile Mail Client:</label>
            <select id="deviceTypeDropdownList" class="form-control" [(ngModel)]="fileContent.MobileMailClient"
                name="deviceTypeDropdownList">
                <option *ngFor="let deviceType of assignableDeviceTypes | orderby: '' : false : true"
                    [value]="deviceType">{{deviceType}}</option>
            </select>
        </div>
    </div>

    <div class='row form-group' *ngIf="isTopLocalIT === true || isAnyLocalITFromOkta === true">
        <div class="col-4">
            <label for="deviceTypeDropdownList">{{groupIDLabel}}</label>
            <select id="localITDropdownList" class="form-control" [(ngModel)]="fileContent.ManagedLocalIT"
                name="localITDropdownList" (change)="getLocalItTeamsGroup($event.target.value);">
                <option hidden disabled selected value></option> -->
                <option *ngFor="let localIT of listLocalIT | orderby: '' : false : true" [value]="localIT">{{localIT}}
                </option>
            </select>
            <span class="badge badge-info">{{localITNotFound}}</span>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>

    <!-- <div class='row form-group' *ngIf="isTopLocalIT === true">
        <div class="col-4">
            <label for="localITInput">Manage Other Local IT:</label>
            <div style="display:flex">
                <input type="text" id="localITInput" name="localITInput" class="form-control" [(ngModel)]="searchLocalITGroup">
                <i class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="getLocalItTeamsGroup(searchLocalITGroup);">search</i>
            </div>
            <span class="badge badge-info">{{localITNotFound}}</span> 
        </div>
    </div> -->

    <div class="row form-group" *ngIf="isTopLocalIT === true || isUserInOkta === true">
        <div *ngFor="let app of localITTeamsGroup" class="checkbox; col-6">
            <label for="teamgroup{{app.id}}" data-toggle="tooltip" title="{{app.description}}">
                <input type="checkbox" id="teamgroup{{app.id}}"
                    (change)="onTeamGroupToggle({id : app.id, displayName : app.displayName}, $event)"
                    [checked]="fileContent.Applications.includes(app.displayName)"> {{app.displayName}}
            </label>
        </div>
        <br>
    </div>
    <div class="row form-group" *ngIf="isTopLocalIT === true || isUserInOkta === true">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary"
                (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();"
                [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary"
                (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();"
                [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send
                Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse"
        [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom"
                class="material-icons md-36 pointer">chevron_right</i>
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
        style="margin-left: 5px;vertical-align: bottom;"
        (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <!-- <div class="col-2">
                <b>Group ID:</b> {{ listedFileContent.GroupDisplayName }}
            </div> -->
            <div class="col-2">
                <b>Mobile Mail Client:</b> {{ listedFileContent.MobileMailClient }}
            </div>
            <div class="col-2">
                <b>Select Local IT:</b> {{ listedFileContent.ManagedLocalIT }}
            </div>
            <div class="col-3">
                <div class="col-12">
                    <b>Applications:</b>
                </div>
                <div class="col-12">
                    <span *ngFor="let app of listedFileContent.Applications">{{ app }}<br /></span>
                </div>
            </div>
        </div>
    </div>
</div>