<h4>Enable Teams Common Area Phone:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIDInputFieldOkta>
        </div>
        <div class="col-4">
            <label class="required" for="SiteInput">Site:</label>
            <input type="text" id="SiteInput" class="form-control" minlength="2" maxlength="5"
                [(ngModel)]="fileContent.Site" required name="SiteInput" #SiteInput="ngModel">
            <div *ngIf="SiteInput.invalid && (SiteInput.dirty || SiteInput.touched)"
                class="alert alert-danger">
                <div *ngIf="SiteInput.errors.required">
                    Site is required.
                </div>
                <div *ngIf="SiteInput.errors?.minlength">
                    Site must be at least 2 characters long.
                </div>              
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="LocationInput">Location:</label>
            <input type="text" id="LocationInput" class="form-control" minlength="3" maxlength="10"
                [(ngModel)]="fileContent.Location" required name="LocationInput" #LocationInput="ngModel">
            <div *ngIf="LocationInput.invalid && (LocationInput.dirty || LocationInput.touched)"
                class="alert alert-danger">
                <div *ngIf="LocationInput.errors.required">
                    Location is required.
                </div>
                <div *ngIf="LocationInput.errors?.minlength">
                    Location must be at least 3 characters long.
                </div>         
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="NameInput">Name:</label>
            <input type="text" id="NameInput" class="form-control" minlength="3" maxlength="15"
                    [(ngModel)]="fileContent.Name" required name="NameInput" #NameInput="ngModel">
            <div *ngIf="NameInput.invalid && (NameInput.dirty || NameInput.touched)"
                    class="alert alert-danger">
                <div *ngIf="NameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="NameInput.errors?.minlength">
                    Name must be at least 3 characters long.
                </div>             
            </div>
        </div>
        <div class="col-4">
            <GroupIDInputField label="Manager Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.ManagerDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.ManagerDisplayNameError=$event" [(groupID)]="fileContent.Manager"></GroupIDInputField>
        </div> 
        <div class="col-4">
            <label for="BrandInput">Brand:</label>
            <input type="text" id="BrandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="BrandInput">
        </div>
        <div class="col-4">
            <label for="IPPhonePolicyDropDown">IP Phone Policy:</label>
            <select id="IPPhonePolicyDropDown" class="form-control"
                [(ngModel)]="fileContent.IPPhonePolicy" name="IPPhonePolicyDropDown">
                <option *ngFor="let policy of IPPhonePolicies" [value]="policy">{{policy}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>  
    </div>
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOXES READY TO BE SENT  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b>{{ listedFileContent.GroupID }}</b> <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            - {{ listedFileContent.Site }} - {{ listedFileContent.Location }} - {{ listedFileContent.Name }} - 
            {{ listedFileContent.Manager }}  <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse " [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div class="col-4">
                    <b>Site:</b> {{ listedFileContent.Site }}
                </div>
                <div class="col-4">
                    <b>Location:</b> {{ listedFileContent.Location }}
                </div>
                <div class="col-4">
                    <b>Manager:</b> {{ listedFileContent.Manager }} <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
                </div>
                <div class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>
                <div class="col-4">
                    <b>IP Phone Policy:</b> {{ listedFileContent.IPPhonePolicy }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                                     
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>