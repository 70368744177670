import { Component, Input } from '@angular/core';
import { DistributionListOwnerDetails } from '../../../../Models/History/DistributionListDetails';

@Component({
    selector: 'DistributionListOwnerHistoryDetails',
    templateUrl: './distributionListOwnerHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class DistributionListOwnerHistoryDetailsComponent{
    @Input() distributionListDetails: DistributionListOwnerDetails;
    distributionListInfoCollapsed: boolean = false;
}