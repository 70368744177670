<h4>{{ isCreation ? 'Create new' : 'Update' }} Local IT Card {{ isCreation ? '' : localItCard.Name }}:</h4>

<!--CREATE/UPDATE NEW LOCAL IT CARD FORM -->
<form #fileContentForm="ngForm">
    <div class="row" style="cursor:pointer;" (click)="localItInfoCollapsed=!localItInfoCollapsed">
        <h4>Local IT Info</h4><i *ngIf="localItInfoCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!localItInfoCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
    </div>
    <div *ngIf="!localItInfoCollapsed">
        <div *ngIf="isCreation" class="row form-group">
            <div class="col-4">
                <label class="required" for="localITNameInput">Local IT Name:</label>
                <input type="text" id="localITNameInput" class="form-control"
                    [(ngModel)]="localItCard.Name" name="localITNameInput" required>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-4">
                <label class="required" for="localITDisplayNameInput">Local IT Display Name:</label>
                <input type="text" id="localITDisplayNameInput" class="form-control"
                    [(ngModel)]="localItCard.DisplayName" name="localITDisplayNameInput" required>
            </div>
            <div class="col-4">
                <label class="required" for="managerGroupIDInput">Manager GroupID:</label>
                <input type="text" id="managerGroupIDInput" class="form-control"
                    [(ngModel)]="localItCard.Manager.GroupID" name="managerGroupIDInput"  required>
            </div>
            <div class="col-4">
                <label class="required" for="managerContactPhoneNumberInput">Manager Contact Phone Number:</label>
                <input type="text" id="managerContactPhoneNumberInput" class="form-control"
                    [(ngModel)]="localItCard.ContactPhoneNumber" name="managerContactPhoneNumberInput">
            </div>    
        </div>
        <div class="row form-group">
            <div class="col-4">
                <label class="required" for="localITEmailInput">Local IT Email:</label>
                <input type="text" id="localITEmailInput" class="form-control"
                    [(ngModel)]="localItCard.LocalITContactEmail" name="localITEmailInput">
            </div>
            <div class="col-4" style="display: none">
                <label class="required" for="quotaHighDefaultInput">Default Quota High:</label>
                <input type="number" id="quotaHighDefaultInput" class="form-control"
                    [(ngModel)]="localItCard.Quotas.HighDefault" name="quotaHighDefaultInput">
            </div>   
            <div class="col-4" style="display: none">
                <label class="required" for="quotaLowDefaultInput">Default Quota Low:</label>
                <input type="number" id="quotaLowDefaultInput" class="form-control"
                    [(ngModel)]="localItCard.Quotas.LowDefault" name="quotaLowDefaultInput">
            </div>
        </div>
        <div *ngIf="!isCreation" class="row form-group" style="display: none">
            <div class="col-4 offset-4">
                <label class="required" for="quotaHighInput">Current Quota High:</label>
                <input type="number" id="quotaHighInput" class="form-control"
                    [(ngModel)]="localItCard.Quotas.High" name="quotaHighInput">
            </div>   
            <div class="col-4">
                <label class="required" for="quotaLowInput">Current Quota Low:</label>
                <input type="number" id="quotaLowInput" class="form-control"
                    [(ngModel)]="localItCard.Quotas.Low" name="quotaLowInput">
            </div>
        </div>
        <div class="row form-group">   
            <div class="col-4">
                <input type="checkbox" [checked]="localItCard.ShowAll" (click)="changeShowAllStatus()">
                Show All
            </div>
        </div>
    </div>

    <div class="row" style="cursor:pointer;" (click)="scopeCollapsed=!scopeCollapsed">
        <h4>Scope</h4><i *ngIf="scopeCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!scopeCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
    </div>
    <div *ngIf="!scopeCollapsed">
        <div class="row form-group">
            <div class="col-4">
                <input type="checkbox" [checked]="localItCard.ManageAll" (click)="changeManageAllStatus()">
                Manage All
            </div>
        </div>
    </div>

    <div class="row" style="cursor:pointer;" (click)="membershipCollapsed=!membershipCollapsed">
        <h4>Membership</h4><i *ngIf="membershipCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!membershipCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
    </div>

    <div *ngIf="!membershipCollapsed && isCreation">   
        <div class="row form-group">
            <div class="col-4">   
                <label class="required" for="additionalMembersInput">Members GroupID:</label>
                <div *ngFor="let additionalMember of localItCard.Members; let i = index; first as isFirst; last as isLast;trackBy:trackByIndex">
                    <div *ngIf="isFirst" style="display:flex">
                        <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                            [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required >
                        <i *ngIf="isLast" id="{{'additionalMembersInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalMember()">add_circle_outline</i>
                    </div>
                    <div *ngIf="!isFirst && !isLast" style="display:flex; margin-top:5px">
                        <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                            [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required >
                        <i id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalMember(i)">remove_circle_outline</i>
                    </div>
                    <div *ngIf="isLast && !isFirst" style="display:flex; margin-top:5px">
                        <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                            [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required >
                        <i id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalMember(i)">remove_circle_outline</i>
                        <i id="{{'additionalMembersInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalMember()">add_circle_outline</i>
                    </div>
                </div>
            </div>                     
        </div>
    </div>

    <div *ngIf="!membershipCollapsed && !isCreation">   
        <div *ngFor="let additionalMember of localItCard.Members; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
            <div *ngIf="isFirst">
                <div class="row form-group ">
                    <div class="col-4">  
                        <label class="boldFormLabel">Members GroupID:</label>
                        <br>
                        <div  style="display:flex">
                            <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                                [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required [disabled]="isMemberEditDisabled(i)" >
                            <i *ngIf="isMemberEditDisabled(i)" id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeOrResetMember(i)">remove_circle_outline</i>
                            <i *ngIf="isLast" id="{{'additionalMembersInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalMember()">add_circle_outline</i>                
                        </div>
                    </div> 
                    <div class="col-4">  
                        <label class="boldFormLabel">Members Names:</label>
                        <br>{{ localItCard.Members[i].Name }}
                    </div>
                    <div class="col-4"> 
                        <label class="boldFormLabel">Members Email:</label>
                        <br>{{ localItCard.Members[i].Email }}
                    </div>
                </div>
            </div>
            <div *ngIf="!isFirst && !isLast">
                <div class="row form-group ">
                    <div class="col-4" style="display:flex">
                        <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                            [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required [disabled]="isMemberEditDisabled(i)" >
                        <i id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalMember(i)">remove_circle_outline</i>                
                    </div> 
                    <div class="col-4">
                        {{ localItCard.Members[i].Name }}
                    </div>
                    <div class="col-4">
                        {{ localItCard.Members[i].Email }}
                    </div>
                </div>
            </div>   
            <div *ngIf="isLast && !isFirst" style="margin-top:5px">
                <div class="row form-group ">
                    <div class="col-4"  style="display:flex;">
                        <input type="text" id="{{'additionalMemberGroupIDInput' + i}}" class="form-control col-10"
                            [(ngModel)]="localItCard.Members[i].GroupID" name="{{'additionalMemberGroupIDInput' + i}}" required [disabled]="isMemberEditDisabled(i)" >
                        <i id="{{'additionalMembersInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalMember(i)">remove_circle_outline</i>          
                        <i id="{{'additionalMembersInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalMember()">add_circle_outline</i>
                    </div>
                    <div class="col-4">
                        {{ localItCard.Members[i].Name }}
                    </div>
                    <div class="col-4"> 
                        {{ localItCard.Members[i].Email }}
                    </div>
                </div>
            </div>
        </div>  
    </div>

    <div class="row" style="cursor:pointer;" (click)="productsCollapsed=!productsCollapsed">
        <h4>Products</h4><i *ngIf="productsCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!productsCollapsed" style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
    </div>

    <div class="col-4">
        <input type="checkbox" [checked]="localItCard.ShowAll" (click)="changeSelectAllproducts()">
        <span style="font-weight:bold;color:#0074D8">Select All</span>
    </div>

    <br>

    <div *ngIf="!productsCollapsed"> 
        <div *ngFor="let additionalProduct of localItCard.Products; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
            <div *ngIf="i%3 === 0">
                <div class="row form-group ">  
                    <div class="col-4">
                        <input type="checkbox" [checked]="localItCard.Products[i].Enabled" (click)="changeProductStatus(i)"/>
                        {{ localItCard.Products[i].Name }}
                    </div>
                    <div class="col-4">
                        <div *ngIf="i+1<localItCard.Products.length">
                            <input type="checkbox" [checked]="localItCard.Products[i+1].Enabled" (click)="changeProductStatus(i+1)"/>
                            {{ localItCard.Products[i+1].Name }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div *ngIf="i+2<localItCard.Products.length">
                            <input type="checkbox" [checked]="localItCard.Products[i+2].Enabled" (click)="changeProductStatus(i+2)"/>
                            {{ localItCard.Products[i+2].Name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-2 offset-10" style="padding-top:33px" style="display: inline;">
        <button type="submit" class="btn btn-primary" (click)="sendRequest();" [disabled]="fileContentForm.invalid">Save</button>
        <a class="btn btn-primary" routerLink="/localItCards" routerLinkActive="active">Cancel</a>
    </div>
</form>