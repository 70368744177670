import { Component, Input } from '@angular/core';
import { ChangeMailboxPrimarySMTPDetails } from '../../../../Models/History/ChangeMailboxPrimarySMTPDetails';

@Component({
    selector: 'ChangeMailboxPrimarySMTPHistoryDetails',
    templateUrl: './changeMailboxPrimarySMTPHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class ChangeMailboxPrimarySMTPHistoryDetailsComponent{
    @Input() changeMailboxPrimarySMTPDetails: ChangeMailboxPrimarySMTPDetails;
    productCustomInfoCollapsed: boolean = false;
}