<div class="m-3">
    <div>
        <div class="row">
            <div class="col-8">
                <h2>Local IT Card {{localItCard.Name}} Information:</h2>
            </div>
            <div class="col-1 offset-2">
                <button *ngIf="isAllowedToEditLocalItCard" class="btn btn-primary"
                    (click)="navigateToLocalITCardEdition()">Edit</button>
            </div>
            <div class="col-1">
                <i style="float: right;" class="material-icons md-36 primaryBlue pointer"
                    (click)="closeDialog()">highlight_off</i>
            </div>
        </div>
    </div>

    <!-- DISPLAY LOCAL IT CARD -->
    <form #fileContentForm="ngForm">
        <mat-dialog-content>
            <div class="row" style="cursor:pointer;" (click)="localItInfoCollapsed=!localItInfoCollapsed">
                <h4>Local IT Info</h4><i *ngIf="localItInfoCollapsed" style="float: right;margin-bottom:5px;"
                    class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!localItInfoCollapsed"
                    style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
            </div>
            <div *ngIf="!localItInfoCollapsed">
                <div class="row form-group">
                    <div class="col-4">
                        <label class="boldFormLabel">Local IT Display Name:</label>
                        <br>{{ localItCard.DisplayName }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Local IT Email:</label>
                        <br>{{ localItCard.LocalITContactEmail }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Manager GroupID:</label>
                        <br>{{ localItCard.Manager.GroupID | uppercase }}
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-4">
                        <label class="boldFormLabel">Manager Name:</label>
                        <br>{{ localItCard.Manager.Name }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Manager Email:</label>
                        <br>{{ localItCard.Manager.Email }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Manager Contact Phone Number:</label>
                        <br>{{ localItCard.ContactPhoneNumber }}
                    </div>
                </div>
                <div class="row form-group" style="display: none">
                    <div class="col-4">
                        <label class="boldFormLabel">Default Quota High:</label>
                        <br>{{ localItCard.Quotas.HighDefault }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Default Quota Low:</label>
                        <br>{{ localItCard.Quotas.LowDefault }}
                    </div>
                </div>
                <div class="row form-group" style="display: none">
                    <div class="col-4">
                        <label class="boldFormLabel">Current Quota High:</label>
                        <br>{{ localItCard.Quotas.High }}
                    </div>
                    <div class="col-4">
                        <label class="boldFormLabel">Current Quota Low:</label>
                        <br>{{ localItCard.Quotas.Low }}
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-4">
                        <input type="checkbox" [checked]="localItCard.ShowAll" [disabled]="true">
                        Show All
                    </div>
                </div>
            </div>

            <div class="row" style="cursor:pointer;" (click)="scopeCollapsed=!scopeCollapsed">
                <h4>Scope</h4><i *ngIf="scopeCollapsed" style="float: right;margin-bottom:5px;"
                    class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!scopeCollapsed"
                    style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
            </div>
            <div *ngIf="!scopeCollapsed">
                <div class="row form-group">
                    <div class="col-4">
                        <input type="checkbox" [checked]="localItCard.ManageAll" [disabled]="true">
                        Manage All
                    </div>
                </div>
            </div>

            <div class="row" style="cursor:pointer;" (click)="membershipCollapsed=!membershipCollapsed">
                <h4>Membership</h4><i *ngIf="membershipCollapsed" style="float: right;margin-bottom:5px;"
                    class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!membershipCollapsed"
                    style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
            </div>
            <div class="row" *ngIf="!membershipCollapsed && !membersInEditMode && isCurrentUserLocalITManager">
                <div class="col-3 offset-9">
                    <button class="btn btn-primary" (click)="activeMembersEditMode()">Edit Members</button>
                </div>
            </div>
            <div class="row" *ngIf="!membershipCollapsed && membersInEditMode && isCurrentUserLocalITManager">
                <div class="col-1 offset-9">
                    <button class="btn btn-primary" (click)="cancelMembersEditMode()">Cancel</button>
                </div>
                <div class="col-1">
                    <button class="btn btn-primary" (click)="saveMembers()">Save</button>
                </div>
            </div>
            <div *ngIf="!membershipCollapsed && !membersInEditMode">
                <div *ngFor="let member of localItCard.Members; let j = index; first as isFirst; trackBy:trackByIndex">
                    <div *ngIf="isFirst" style="">
                        <div class="row form-group ">
                            <div class="col-4">
                                <label class="boldFormLabel">Members GroupID:</label>
                                <br>{{ member.GroupID | uppercase }}
                            </div>
                            <div class="col-4">
                                <label class="boldFormLabel">Members Names:</label>
                                <br>{{ member.Name }}
                            </div>
                            <div class="col-4">
                                <label class="boldFormLabel">Members Email:</label>
                                <br>{{ member.Email }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isFirst" style="margin-top:5px">
                        <div class="row form-group ">
                            <div class="col-4">
                                {{ member.GroupID | uppercase }}
                            </div>
                            <div class="col-4">
                                {{ member.Name }}
                            </div>
                            <div class="col-4">
                                {{ member.Email }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!membershipCollapsed && membersInEditMode">
                <div
                    *ngFor="let additionalMember of tempMembers; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                    <div *ngIf="isFirst">
                        <div class="row form-group ">
                            <div class="col-4">
                                <label class="boldFormLabel">Members GroupID:</label>
                                <br>
                                <div style="">
                                    <input type="text" id="{{'additionalMemberGroupIDInput' + i}}"
                                        class="form-control col-10" [(ngModel)]="tempMembers[i].GroupID"
                                        name="{{'additionalMemberGroupIDInput' + i}}" required
                                        [disabled]="isMemberEditDisabled(i)">
                                    <i *ngIf="isMemberEditDisabled(i)" id="{{'additionalMembersInputRemoveBtn' + i}}"
                                        class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                                        (click)="removeOrResetMember(i)">remove_circle_outline</i>
                                    <i *ngIf="isLast" id="{{'additionalMembersInputAddBtn' + i}}"
                                        class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                                        (click)="addAdditionalMember()">add_circle_outline</i>
                                </div>
                            </div>
                            <div class="col-4">
                                <label class="boldFormLabel">Members Names:</label>
                                <br>{{ tempMembers[i].Name }}
                            </div>
                            <div class="col-4">
                                <label class="boldFormLabel">Members Email:</label>
                                <br>{{ tempMembers[i].Email }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isFirst && !isLast">
                        <div class="row form-group ">
                            <div class="col-4" style="">
                                <input type="text" id="{{'additionalMemberGroupIDInput' + i}}"
                                    class="form-control col-10" [(ngModel)]="tempMembers[i].GroupID"
                                    name="{{'additionalMemberGroupIDInput' + i}}" required
                                    [disabled]="isMemberEditDisabled(i)">
                                <i id="{{'additionalMembersInputRemoveBtn' + i}}"
                                    class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                                    (click)="removeAdditionalMember(i)">remove_circle_outline</i>
                            </div>
                            <div class="col-4">
                                {{ tempMembers[i].Name }}
                            </div>
                            <div class="col-4">
                                {{ tempMembers[i].Email }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLast && !isFirst" style="margin-top:5px">
                        <div class="row form-group ">
                            <div class="col-4" style="">
                                <input type="text" id="{{'additionalMemberGroupIDInput' + i}}"
                                    class="form-control col-10" [(ngModel)]="tempMembers[i].GroupID"
                                    name="{{'additionalMemberGroupIDInput' + i}}" required
                                    [disabled]="isMemberEditDisabled(i)">
                                <i id="{{'additionalMembersInputRemoveBtn' + i}}"
                                    class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                                    (click)="removeAdditionalMember(i)">remove_circle_outline</i>
                                <i id="{{'additionalMembersInputAddBtn' + i}}"
                                    class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;"
                                    (click)="addAdditionalMember()">add_circle_outline</i>
                            </div>
                            <div class="col-4">
                                {{ tempMembers[i].Name }}
                            </div>
                            <div class="col-4">
                                {{ tempMembers[i].Email }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row"
                *ngIf="!membershipCollapsed && !membersInEditMode && isCurrentUserLocalITManager && localItCard.Members.length > 5">
                <div class="col-3 offset-9">
                    <button class="btn btn-primary" (click)="activeMembersEditMode()">Edit Members</button>
                </div>
            </div>
            <div class="row"
                *ngIf="!membershipCollapsed && membersInEditMode && isCurrentUserLocalITManager && tempMembers.length > 5">
                <div class="col-1 offset-9">
                    <button class="btn btn-primary" (click)="cancelMembersEditMode()">Cancel</button>
                </div>
                <div class="col-1">
                    <button class="btn btn-primary" (click)="saveMembers()">Save</button>
                </div>
            </div>

            <div class="row" style="cursor:pointer;" (click)="productsCollapsed=!productsCollapsed">
                <h4>Products</h4><i *ngIf="productsCollapsed" style="float: right;margin-bottom:5px;"
                    class="material-icons md-36 pointer">chevron_right</i><i *ngIf="!productsCollapsed"
                    style="float: right;margin-bottom:5px;" class="material-icons md-36 pointer">expand_more</i>
            </div>

            <div *ngIf="!productsCollapsed">
                <div
                    *ngFor="let additionalProduct of localItCard.Products; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                    <div *ngIf="i%3 === 0">
                        <div class="row form-group ">
                            <div class="col-4">
                                <input type="checkbox" [checked]="localItCard.Products[i].Enabled" [disabled]="true" />
                                {{ localItCard.Products[i].Name }}
                            </div>
                            <div class="col-4">
                                <div *ngIf="i+1<localItCard.Products.length">
                                    <input type="checkbox" [checked]="localItCard.Products[i+1].Enabled"
                                        [disabled]="true" />
                                    {{ localItCard.Products[i+1].Name }}
                                </div>
                            </div>
                            <div class="col-4">
                                <div *ngIf="i+2<localItCard.Products.length">
                                    <input type="checkbox" [checked]="localItCard.Products[i+2].Enabled"
                                        [disabled]="true" />
                                    {{ localItCard.Products[i+2].Name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </form>
</div>