<div>
    <label [ngClass]="{'required': isRequired}" for="multiValueInput">{{label}}:</label>
    <div *ngFor="let value of inputsArray; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
        <div *ngIf="isFirst" style="display:flex">
            <input type="text" id="{{'valueInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(valueInput.invalid && (valueInput.dirty || valueInput.touched || valueInput.value))}"
                    [(ngModel)]="inputsArray[i]" name="{{'valueInput' + i}}" #valueInput="ngModel"
                    [required]="isRequired" [pattern]="inputPattern">
            <i *ngIf="isLast" id="{{'valueInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(inputsArray)">add_circle_outline</i>
        </div>
        <div *ngIf="!isFirst && !isLast" style="display:flex; margin-top:5px">
            <input type="text" id="{{'valueInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(valueInput.invalid && (valueInput.dirty || valueInput.touched || valueInput.value))}"
                    [(ngModel)]="inputsArray[i]" name="{{'valueInput' + i}}" #valueInput="ngModel"
                    [required]="isRequired" [pattern]="inputPattern">
            <i id="{{'valueInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(inputsArray,i)">remove_circle_outline</i>
        </div>
        <div *ngIf="isLast && !isFirst" style="display:flex; margin-top:5px">
            <input type="text" id="{{'valueInput' + i}}" class="form-control col-10" [ngClass]="{'has-danger-forced':(valueInput.invalid && (valueInput.dirty || valueInput.touched || valueInput.value))}"
                    [(ngModel)]="inputsArray[i]" name="{{'valueInput' + i}}" #valueInput="ngModel"
                    [required]="isRequired" [pattern]="inputPattern">
            <i id="{{'valueInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(inputsArray,i)">remove_circle_outline</i>
            <i id="{{'valueInputAddBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(inputsArray)">add_circle_outline</i>
        </div>
    </div>
</div>