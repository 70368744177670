<h4>Enable User:</h4>

<form #getUserInfoForm="ngForm" (ngSubmit)="getUserInfoForm.resetForm()">
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputField label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event"
                [(groupID)]="fileContent.GroupID"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList();"
                [disabled]="getUserInfoForm.invalid || fileContent.GroupIDDisplayNameError" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getUserInfoForm.invalid);"
                [disabled]="((getUserInfoForm.invalid || fileContent.GroupIDDisplayNameError) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF GETUSERINFO READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }}</b> <span
                *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'getUserInfoRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer"
                style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>
            </div>
        </div>
    </div>
</div>