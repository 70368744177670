<h4>Enable Teams Room Mailbox:</h4>

<form #fileContentForm="ngForm">
    <div *ngIf="!fileContent.ExtensionAttribute11" class="row form-group">
        <div class="col-4 offset-9">
            <button class="btn btn-primary" (click)="importCustomerProfile();" [disabled]="customerProfileImported || !isGroupIDFormatValid(fileContent.Manager)">Import Customer Profile</button><br />
            <small class="text-danger font-weight-bold" *ngIf="!isGroupIDFormatValid(fileContent.Manager)">Please fill a valid Manager Group ID first</small>
            <small class="text-primary font-weight-bold" *ngIf="isGroupIDFormatValid(fileContent.Manager)">Based on Manager Group ID</small>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-12" style="color: #388e3c;font-weight:bold;" *ngIf="showImportedCompanySuccess">Imported from {{importedCompanyName}} customer profile.</div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedNoProfileDefaultSuccess">User's company doesn't have a customer profile. </div>
        <div class="col-12" style="color: #fbc02d;font-weight:bold;" *ngIf="showImportedDefaultSuccess">Imported from DEFAULT customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyError">Could not import customer profile.</div>
        <div class="col-12" style="color: #d32f2f;font-weight:bold;" *ngIf="showImportedCompanyUserNotFoundError">Could not import customer profile because this user does not exist!</div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label for="gidInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="gidInput" name="gidInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID" [pattern]="groupIdNoAdminPattern" (keyup)="emptyForm()" #gidInput="ngModel">
                <i *ngIf="fileContent.GroupID && !gidInput.invalid" class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;"
                    (click)="getAADUser();">search</i>
            </div>
            <div *ngIf="gidInput.invalid && (gidInput.dirty || gidInput.touched)"
                class="alert alert-danger">
                <div *ngIf="gidInput.errors.pattern">
                    GroupID is invalid.
                </div>
            </div>
            <small *ngIf="fileContent.GroupIDDisplayName" id="groupIDDisplayName" class="form-text text-primary">{{ fileContent.GroupIDDisplayName }}</small>
            <small *ngIf="fileContent.GroupIDDisplayNameError" id="groupIDDisplayNameError" class="form-text text-danger">{{ fileContent.GroupIDDisplayNameError }}</small>
        </div>
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label [class.required]="!fileContent.GroupID" for="createMailboxSiteInput">Site:</label>
            <input type="text" id="createMailboxSiteInput" class="form-control" maxlength="10" minlength="2" (change)="calculateCharacters();" (keyup)="calculateCharacters();"
                [(ngModel)]="fileContent.Site" [required]="!fileContent.GroupID" name="createMailboxSiteInput" #createMailboxSiteInput="ngModel">
            <div *ngIf="createMailboxSiteInput.invalid && (createMailboxSiteInput.dirty || createMailboxSiteInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxSiteInput.errors.required">
                    Site is required.
                </div>
                <div *ngIf="createMailboxSiteInput.errors?.minlength">
                    Site must be between 2 and 10 characters.
                </div>
            </div>
            <span [class]="errorsInForm() ? 'text-danger' : 'text-primary'" style="white-space: nowrap">{{message}}</span>
        </div>
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label [class.required]="!fileContent.GroupID" for="createMailboxNameInput">Name:</label>
            <input type="text" id="createMailboxNameInput" class="form-control" minlength="3" maxlength="42" (change)="calculateCharacters();" (keyup)="calculateCharacters();" [pattern]="namePattern"
                [(ngModel)]="fileContent.Name" [required]="!fileContent.GroupID" name="createMailboxNameInput"  #createMailboxNameInput="ngModel">
            <div *ngIf="createMailboxNameInput.invalid && (createMailboxNameInput.dirty || createMailboxNameInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxNameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="createMailboxNameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
                <div *ngIf="createMailboxNameInput.errors?.minlength">
                    Name must be between 3 and 42 characters.
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="row form-group">
        <div class="col-4">
            <label [class.required]="!fileContent.GroupID" for="createMailboxCountryCodeInput">Country Code:</label>
            <input type="text" id="createMailboxCountryCodeInput" class="form-control"
                [(ngModel)]="fileContent.CountryCode" [required]="!fileContent.GroupID" name="createMailboxCountryCodeInput" [maxlength]="2" #createMailboxCountryCodeInput="ngModel">
            <div *ngIf="createMailboxCountryCodeInput.invalid && (createMailboxCountryCodeInput.dirty || createMailboxCountryCodeInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxCountryCodeInput.errors.required">
                    Country Code is required.
                </div>
            </div>
        </div>
        <div class="col-4">
            <GroupIDInputField label="Manager Group ID" noAdmin="noAdmin" (groupIDDisplayNameChange)="fileContent.ManagerDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.ManagerDisplayNameError=$event" (keyup)="setBackEmptyForm()" [(groupID)]="fileContent.Manager"></GroupIDInputField>
        </div>
        <div class="col-4">
            <label for="createMailboxLanguageDropDown">Mailbox Language:</label>
            <select id="createMailboxLanguageDropDown" class="form-control"
                [(ngModel)]="fileContent.MailboxLanguage" name="createMailboxLanguageDropDown">
                <option *ngFor="let language of languages" [value]="language.code">{{language.name}}</option>
            </select>
        </div>
    </div>
    <div *ngIf="!fileContent.GroupID" class="row form-group">
        <div class="col-4">            
            <label for="createMailboxAdditionalManagerInput0">Additional Manager:</label>
            <div *ngFor="let manager of fileContent.AdditionalManager; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxAdditionalManagerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.AdditionalManager[i]" name="{{'createMailboxAdditionalManagerInput' + i}}"
                        [ngClass]="{'has-danger-forced':(createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched || createMailboxAdditionalManagerInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #createMailboxAdditionalManagerInput="ngModel">
                    <i *ngIf="isFirst" id="createMailboxAdditionalManagerInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.AdditionalManager)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxAdditionalManagerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.AdditionalManager, i)">remove_circle_outline</i>
                    <div *ngIf="createMailboxAdditionalManagerInput.invalid && (createMailboxAdditionalManagerInput.dirty || createMailboxAdditionalManagerInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="createMailboxAdditionalManagerInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">            
            <label for="createMailboxResourceDelegatesInput0">Resource Delegates:</label>
            <div *ngFor="let delegate of fileContent.ResourceDelegates; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'createMailboxResourceDelegatesInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.ResourceDelegates[i]" name="{{'createMailboxResourceDelegatesInput' + i}}"
                        [ngClass]="{'has-danger-forced':(createMailboxResourceDelegatesInput.invalid && (createMailboxResourceDelegatesInput.dirty || createMailboxResourceDelegatesInput.touched || createMailboxResourceDelegatesInput.value))}"
                        [pattern]="groupIdNoAdminOrMailPattern" #createMailboxResourceDelegatesInput="ngModel">
                    <i *ngIf="isFirst" id="createMailboxResourceDelegatesInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addInputRow(fileContent.ResourceDelegates)">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'createMailboxResourceDelegatesInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeInputRow(fileContent.ResourceDelegates, i)">remove_circle_outline</i>
                    <div *ngIf="createMailboxResourceDelegatesInput.invalid && (createMailboxResourceDelegatesInput.dirty || createMailboxResourceDelegatesInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="createMailboxResourceDelegatesInput.errors.pattern">
                            Group ID or email format is invalid.
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="createMailboxCapacityInput">Capacity:</label>
            <input type="number" id="createMailboxCapacityInput" class="form-control"
                    [(ngModel)]="fileContent.Capacity" name="createMailboxCapacityInput">
        </div>
    </div>
    <div *ngIf="!fileContent.GroupID" class="row form-group">
        <div class="col-4">
            <label for="TimeZoneDropdownList">Time Zone:</label>
            <select id="TimeZoneDropdownList" class="form-control"
                [(ngModel)]="fileContent.TimeZone" name="TimeZoneDropdownList">
                <option *ngFor="let timeZone of timeZones" [value]="timeZone.StandardName">{{timeZone.DisplayName}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="WorkingHoursStartTimeDropdownList">Working hours start time:</label>
            <select id="WorkingHoursStartTimeDropdownList" class="form-control"
                [(ngModel)]="fileContent.WorkingHoursStartTime" name="WorkingHoursStartTimeDropdownList">
                <option *ngFor="let WorkingHour of workingHours | slice:0:workingHours.length-1" [value]="WorkingHour">{{WorkingHour}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="WorkingHoursEndTimeDropdownList">Working hours end time:</label>
            <select id="WorkingHoursEndTimeDropdownList" class="form-control"
                [(ngModel)]="fileContent.WorkingHoursEndTime" name="WorkingHoursEndTimeDropdownList">
                <option *ngFor="let WorkingHour of workingHours | slice:1:workingHours.length" [value]="WorkingHour">{{WorkingHour}}</option>
            </select>
        </div>
    </div>
    <div class="row form-group">
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label for="createRoomLicensesInput">License:</label>
            <select id="createRoomLicensesInput" class="form-control" (change)="getNumberOfAvailableLicences($event.target.value)"
                [(ngModel)]="fileContent.License" name="createRoomLicensesInput">
                <option selected="selected"></option>
                <option *ngFor="let license of licenseTypes | orderby: 'name' : false : true" [value]="license.code">{{license.name}}</option>
            </select>
            <small *ngIf="availableLicensesCounter !== null" id="availableLicensesCounter"
                [class]="availableLicensesCounter < 1 ? 'form-text text-danger' : 'form-text text-primary'">{{ availableLicensesCounter < 1 ? 'Not available, provisioning in process' : availableLicensesCounter + ' license(s) available' }}
            </small>
        </div> 
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label for="createMailboxBrandInput">Brand:</label>
            <input type="text" id="createMailboxBrandInput" class="form-control"
                [(ngModel)]="fileContent.Brand" name="createMailboxBrandInput">
        </div>
        <div *ngIf="!fileContent.GroupID" class="col-4">
            <label for="createMailboxScheduleOnlyDuringWorkHoursInput">Schedule only during working hours:</label>
            <div id="createMailboxScheduleOnlyDuringWorkHoursInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxScheduleOnlyDuringWorkHoursInputOpt1" name="createMailboxScheduleOnlyDuringWorkHoursInput" class="form-control" [value]="true" [(ngModel)]="fileContent.ScheduleOnlyDuringWorkHours" #createMailboxScheduleOnlyDuringWorkHoursInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxScheduleOnlyDuringWorkHoursInputOpt2" name="createMailboxScheduleOnlyDuringWorkHoursInput" class="form-control" [value]="false" [(ngModel)]="fileContent.ScheduleOnlyDuringWorkHours" #createMailboxScheduleOnlyDuringWorkHoursInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label for="createMailboxEnableTeamsTelephonyInput">Enable Teams Telephony:</label>
            <div id="createMailboxEnableTeamsTelephonyInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableTeamsTelephonyInputOpt1" name="createEnableTeamsTelephonyInput" class="form-control" [value]="true" [(ngModel)]="fileContent.EnableTeamsTelephony" #createEnableTeamsTelephonyInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="createMailboxEnableTeamsTelephonyInputOpt2" name="createEnableTeamsTelephonyInput" class="form-control" [value]="false" [(ngModel)]="fileContent.EnableTeamsTelephony" #createEnableTeamsTelephonyInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="fileContent.EnableTeamsTelephony === true">
            <label [class.required]="fileContent.EnableTeamsTelephony" for="phoneNumberInput">Phone Number:</label>
            <input type="text" id="phoneNumberInput" name="phoneNumberInput" class="form-control"
                [(ngModel)]="fileContent.PhoneNumber" #phoneNumberInput="ngModel"
                [required]="fileContent.EnableTeamsTelephony" pattern="^\+?[1-9]\d{7,14}$" maxlength="15">
            <div *ngIf="phoneNumberInput.invalid && (phoneNumberInput.dirty || phoneNumberInput.touched)"
                class="alert alert-danger">
                <div *ngIf="phoneNumberInput.errors.required && fileContent.EnableTeamsTelephony">
                    Phone Number is required.
                </div>
                <div *ngIf="phoneNumberInput.errors.pattern">
                    Phone number should have the international format (starting with the countrycode +xx)
                </div>
            </div> 
        </div>   
        <div class="col-4" *ngIf="fileContent.EnableTeamsTelephony === true">
            <label [class.required]="fileContent.EnableTeamsTelephony" class="required" for="vRPolicyDropdownList">Voice Routing Policy:</label>
            <select id="vRPolicyDropdownList" class="form-control"
                [(ngModel)]="fileContent.VRPolicy" name="vRPolicyDropdownList">
                <option *ngFor="let policy of vRPolicies | orderby: '' : false : true" [value]="policy">{{policy}}</option>
            </select>
        </div>
        <div *ngIf="!fileContent.GroupID" class="col-4">
            <label for="sensitiveInput">Sensitive:</label>
            <div id="sensitiveInput">
                <div style="display: inline-block;">
                    <label><input type="radio" id="sensitiveInputOpt1" name="sensitiveInput" class="form-control" [value]="true" [(ngModel)]="fileContent.Sensitive" #sensitiveInputOpt1="ngModel">Yes</label>
                </div>
                <div style="display: inline-block;">
                    <label><input type="radio" id="sensitiveInputOpt2" name="sensitiveInput" class="form-control" [value]="false" [(ngModel)]="fileContent.Sensitive" #sensitiveInputOpt2="ngModel">No</label>
                </div>
            </div>
        </div>
        <div *ngIf="!fileContent.GroupID && !fileContent.Sensitive" class="col-4">
            <label for="BookingProfilesDropdownList">Booking Profile:</label>
            <select id="BookingProfilesDropdownList" class="form-control"
                [(ngModel)]="fileContent.BookingProfile" name="BookingProfilesDropdownList">
                <option *ngFor="let profile of bookingProfiles" [value]="profile.option" title="{{profile.tooltip}}">{{profile.option}}</option>
            </select>
        </div>
        <div *ngIf="!fileContent.GroupID || (fileContent.GroupIDDisplayName && !fileContent.GroupIDDisplayNameError)" class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <!-- ADD TO LIST BUTTON -->
        <div class="col-1 offset-8">
            <button *ngIf="!fileContent.GroupID || fileContent.GroupIDDisplayName" type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError || fileContent.ManagerDisplayNameError || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <!-- SEND REQUEST BUTTON -->
        <div *ngIf="!fileContent.GroupID || fileContent.GroupIDDisplayName || (fileContentsList && fileContentsList.length > 0)" class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError || fileContent.ManagerDisplayNameError || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>
<!-- ADD NEW MAILBOX FORM END -->

<!-- SEPARATOR BETWEEN FORM AND REQUEST LIST -->
<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST OF MAILBOXES READY TO BE SENT  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            {{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Group ID:</b> {{ listedFileContent.GroupID }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Site:</b> {{ listedFileContent.Site }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Name:</b> {{ listedFileContent.Name }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Country Code:</b> {{ listedFileContent.CountryCode }}
                </div>
                <div class="col-4">
                    <b>Manager:</b> {{ listedFileContent.Manager }}  <span *ngIf="listedFileContent.ManagerDisplayName">({{ listedFileContent.ManagerDisplayName }})</span>
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Language:</b> {{ listedFileContent.MailboxLanguage }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Additional Manager(s):</b>
                    <div *ngFor="let manager of listedFileContent.AdditionalManager">
                        {{ manager }}
                    </div>
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Resource Delegate(s):</b>
                    <div *ngFor="let delegate of listedFileContent.ResourceDelegates">
                        {{ delegate }}
                    </div>
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Capacity:</b> {{ listedFileContent.Capacity }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Time Zone:</b> {{ listedFileContent.TimeZone }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Working Hours Start Time:</b> {{ listedFileContent.WorkingHoursStartTime }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Working Hours End Time:</b> {{ listedFileContent.WorkingHoursEndTime }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Brand:</b> {{ listedFileContent.Brand }}
                </div>
                <div *ngIf="!listedFileContent.ExtensionAttribute11" class="col-4">
                    <b>Schedule only during working hours:</b> {{ booleanToStringConverterImport(listedFileContent.ScheduleOnlyDuringWorkHours, 'No', 'Yes') }}
                </div>
                <div class="col-4">
                    <b>Enable Teams Telephony:</b> {{ booleanToStringConverterImport(listedFileContent.EnableTeamsTelephony, 'No', 'Yes') }}
                </div>
                <div *ngIf="listedFileContent.PhoneNumber" class="col-4">
                    <b>Phone Number:</b> {{ listedFileContent.PhoneNumber }}
                </div> 
                <div *ngIf="listedFileContent.VRPolicy" class="col-4">
                    <b>Voice Routing Policy:</b> {{ listedFileContent.VRPolicy }}
                </div>
                <div class="col-4">
                    <b>Sensitive:</b> {{ booleanToStringConverterImport(listedFileContent.Sensitive, 'No', 'Yes') }}
                </div>
                <div *ngIf="listedFileContent.BookingProfile && !listedFileContent.Sensitive" class="col-4">
                    <b>Booking Profile:</b> {{ listedFileContent.BookingProfile }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                        
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>