import { Component, Input } from '@angular/core';
import { CreateMEUDetails } from '../../../../Models/History/CreateMEUDetails';

@Component({
    selector: 'CreateMEUHistoryDetails',
    templateUrl: './createMEUHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class CreateMEUHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: CreateMEUDetails;
    productCustomInfoCollapsed: boolean = false;
}