import { RequestItem } from "app/Models/RequestItem";
import { dateToFormatedLocaleString, getTargetValueByProduct } from "../../Helpers/utils";
import { GetIdentityPersonalDataDetails } from "./GetIdentityPersonalDataDetails";
import { GetUserInfoDetails } from "./GetUserInfoDetails";
import { GetDistributionListInfoDetails } from "./GetDistributionListInfoDetails";
import { GetDeletedUserInfoDetails } from "./GetDeletedUserInfoDetails";
import { GetMailboxInfoDetails } from "./GetMailboxInfoDetails";
import { RemoveDistributionListOwnerDetails, DistributionListOwnerDetails, DistributionListMembersDetails, DistributionListMailAddressesDetails, DistributionListDeliveryRestrictionDetails, DistributionListPrimarySMTPDetails, DistributionListSendAsDetails, GetDistributionListMembersDetails } from "./DistributionListDetails";
import { CreatePersonalMailboxDetails } from "./CreatePersonalMailboxDetails";
import { MailboxPermissionDetails } from "./MailboxPermissionDetails";
import { EnableLitigationHoldDetails } from "./EnableLitigationHoldDetails";
import { CreateDistributionListDetails } from "./CreateDistributionListDetails";
import { EnableArchiveOnlineDetails } from "./EnableArchiveOnlineDetails";
import { ChangeMailboxManagerDetails } from "./ChangeMailboxManagerDetails";
import { CreateOktaCloudAccountDetails } from "./CreateOktaCloudAccountDetails";
import { DeleteO365ObjectDetails } from "./DeleteO365ObjectDetails";
import { CreateMEUDetails } from "./CreateMEUDetails";
import { MailboxMailAddressesDetails } from "./MailboxDetails";
import { GetOktaAssignedAppDetails } from "./GetOktaAssignedAppDetails";
import { GetOktaGroupMembersDetails } from "./GetOktaGroupMembersDetails";
import { ChangeMailboxPrimarySMTPDetails } from "./ChangeMailboxPrimarySMTPDetails";
import { AddIntuneUserDetails } from "./AddIntuneUserDetails";
import { AddAADGroupMemberDetails } from "./AddAADGroupMemberDetails";
import { CreateDetails } from './CreateDetails';


export class RequestHistoryDetails {
    GUID: string;
    Requestor: string;
    Status: string;
    Target: string;
    CreatedOn: string;
    ModifedOn: string;
    Parameters: any;
    Output: any;
    TicketID: string;
    History: Array<RequestItemDetailsHistoryItem>;
    ProductName: string;
    GetIdentityPersonalDataDetails: GetIdentityPersonalDataDetails = new GetIdentityPersonalDataDetails();
    GetUserInfoDetails: GetUserInfoDetails = new GetUserInfoDetails();
    GetDeletedUserInfoDetails: GetDeletedUserInfoDetails = new GetDeletedUserInfoDetails();
    GetDistributionListMembersDetails: GetDistributionListMembersDetails = new GetDistributionListMembersDetails();
    GetMailboxInfoDetails: GetMailboxInfoDetails = new GetMailboxInfoDetails();
    DistributionListOwnerDetails: DistributionListOwnerDetails = new DistributionListOwnerDetails();
    RemoveDistributionListOwnerDetails: RemoveDistributionListOwnerDetails = new RemoveDistributionListOwnerDetails();
    DistributionListMembersDetails: DistributionListMembersDetails = new DistributionListMembersDetails();
    CreatePersonalMailboxDetails: CreatePersonalMailboxDetails = new CreatePersonalMailboxDetails();
    MailboxPermissionDetails: MailboxPermissionDetails = new MailboxPermissionDetails();
    EnableLitigationHoldDetails: EnableLitigationHoldDetails = new EnableLitigationHoldDetails();
    CreateDistributionListDetails: CreateDistributionListDetails = new CreateDistributionListDetails();
    EnableArchiveOnlineDetails: EnableArchiveOnlineDetails = new EnableArchiveOnlineDetails();
    ChangeMailboxManagerDetails: ChangeMailboxManagerDetails = new ChangeMailboxManagerDetails();
    CreateOktaCloudAccountDetails: CreateOktaCloudAccountDetails = new CreateOktaCloudAccountDetails();
    DeleteO365ObjectDetails: DeleteO365ObjectDetails = new DeleteO365ObjectDetails();
    CreateMEUDetails: CreateMEUDetails = new CreateMEUDetails();
    GetOktaAssignedAppDetails: GetOktaAssignedAppDetails = new GetOktaAssignedAppDetails();
    GetOktaGroupMembersDetails: GetOktaGroupMembersDetails = new GetOktaGroupMembersDetails();
    DistributionListMailAddressesDetails: DistributionListMailAddressesDetails = new DistributionListMailAddressesDetails();
    DistributionListDeliveryRestrictionDetails: DistributionListDeliveryRestrictionDetails = new DistributionListDeliveryRestrictionDetails();
    MailboxMailAddressesDetails: MailboxMailAddressesDetails = new MailboxMailAddressesDetails();
    ChangeDistributionListPrimarySMTPDetails: DistributionListPrimarySMTPDetails = new DistributionListPrimarySMTPDetails();
    ChangeMailboxPrimarySMTPDetails: ChangeMailboxPrimarySMTPDetails = new ChangeMailboxPrimarySMTPDetails();
    DistributionListSendAsDetails: DistributionListSendAsDetails = new DistributionListSendAsDetails();
    AddIntuneUserDetails: AddIntuneUserDetails = new AddIntuneUserDetails();
    AddAADGroupMemberDetails: AddAADGroupMemberDetails = new AddAADGroupMemberDetails();
    CreateDetails: CreateDetails = new CreateDetails();
    GetDistributionListInfoDetails: GetDistributionListInfoDetails = new GetDistributionListInfoDetails();


    constructor(requestItem?: RequestItem, GUID?, requestor?, status?, target?, createdOn?, modifiedOn?, parameters?, history?) {
        if (requestItem) {
            this.GUID = requestItem.GUID ? requestItem.GUID : "";
            this.Requestor = requestItem.Requestor ? requestItem.Requestor : "";
            this.Status = requestItem.Status ? requestItem.Status : "";
            this.CreatedOn = requestItem.RequestContainerDateCreated ? dateToFormatedLocaleString(requestItem.RequestContainerDateCreated) : "";
            this.ModifedOn = requestItem.DateModified ? dateToFormatedLocaleString(requestItem.DateModified) : "";
            this.Parameters = requestItem.Parameters ? requestItem.Parameters : new Object();
            this.Output = requestItem.Output ? requestItem.Output : new Object();
            this.History = requestItem.History ? mapRequestItemHistory(requestItem.History) : new Array<RequestItemDetailsHistoryItem>();
            this.ProductName = requestItem.ProductName ? requestItem.ProductName : "";
            this.Target = getTargetValueByProduct(requestItem);
            this.TicketID = requestItem.Parameters && requestItem.Parameters.TICKETID ? requestItem.Parameters.TICKETID : "";

            //INITIALIZE SPECIFIC PRODUCT DISPLAY IN HISTORY DETAILS
            switch (requestItem.ProductName) {
                case 'Get-IdentityPersonalData':
                    this.GetIdentityPersonalDataDetails = new GetIdentityPersonalDataDetails(requestItem.Output, requestItem.ProductName, requestItem.Parameters.GROUPID);
                    break;
                case 'Get-User-Info':
                    this.GetUserInfoDetails = new GetUserInfoDetails(requestItem);
                    break;

                case 'Get-Deleted-User-Info':
                    this.GetDeletedUserInfoDetails = new GetDeletedUserInfoDetails(requestItem);
                    break;

                case 'Get-DistributionList-Members':
                    this.GetDistributionListMembersDetails = new GetDistributionListMembersDetails(requestItem.Output, requestItem.ProductName, this.Target);
                    if (requestItem.Source == 'SharePointOnline') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("----")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Get-Mailbox-Info':
                    this.GetMailboxInfoDetails = new GetMailboxInfoDetails(requestItem.Output);
                    break;

                case 'Change-DistributionList-Owner':
                case 'Add-DistributionList-Owner':
                    this.DistributionListOwnerDetails = new DistributionListOwnerDetails(requestItem.Output, requestItem.Status, requestItem.ProductName);
                    break;
                case 'Remove-DistributionList-Owner':
                    this.RemoveDistributionListOwnerDetails = new RemoveDistributionListOwnerDetails(requestItem.Output, requestItem.Status, requestItem.ProductName);
                    break;

                case 'Create-DistributionList':
                    this.CreateDistributionListDetails = new CreateDistributionListDetails(requestItem.Output);
                    break;

                case 'Add-DistributionList-Members':
                case 'Set-DistributionList-Members':
                case 'Remove-DistributionList-Members':
                    this.DistributionListMembersDetails = new DistributionListMembersDetails(requestItem.Output, requestItem.ProductName);
                    break;

                case 'Add-DistributionList-MailAddresses':
                case 'Remove-DistributionList-MailAddresses':
                    this.DistributionListMailAddressesDetails = new DistributionListMailAddressesDetails(requestItem.Output, requestItem.ProductName);
                    break;

                case 'Add-DistributionList-DeliveryRestriction':
                case 'Remove-DistributionList-DeliveryRestriction':
                    this.DistributionListDeliveryRestrictionDetails = new DistributionListDeliveryRestrictionDetails(requestItem.Output, requestItem.ProductName);
                    break;

                case 'Create-SharedMailbox':
                    this.CreatePersonalMailboxDetails = new CreatePersonalMailboxDetails(requestItem.Output);
                    break;
                case 'Create-AdminMailbox':
                case 'Create-AnonymousMailbox':
                case 'Create-ApplicationMailbox':
                case 'Create-PersonalMailbox':
                case 'Create-RoomMailbox':
                case 'Create-ResourceMailbox':
                case 'Enable-Teams-RoomMailbox':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline' || requestItem.Source == 'CSV') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("DisplayName: ")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Add-Mailbox-FullAccess':
                case 'Remove-Mailbox-FullAccess':
                case 'Add-Mailbox-SendAs':
                case 'Remove-Mailbox-SendAs':
                case 'Add-Mailbox-SendOnBehalf':
                case 'Remove-Mailbox-SendOnBehalf':
                    this.MailboxPermissionDetails = new MailboxPermissionDetails(requestItem.Output, requestItem.ProductName);
                    break;
                case 'Add-Mailbox-Delegation':
                case 'Remove-Mailbox-Delegation':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline' || requestItem.Source == 'CSV') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("Mailbox")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Enable-LitigationHold':
                    this.EnableLitigationHoldDetails = new EnableLitigationHoldDetails(requestItem.Output);
                    break;

                case 'Enable-ArchiveOnline':
                    this.EnableArchiveOnlineDetails = new EnableArchiveOnlineDetails(requestItem.Output);
                    break;

                case 'Change-Mailbox-Manager':
                    this.ChangeMailboxManagerDetails = new ChangeMailboxManagerDetails(requestItem.Output, requestItem.Status);
                    break;

                case 'Create-Okta-CloudAccount':
                    this.CreateOktaCloudAccountDetails = new CreateOktaCloudAccountDetails(requestItem.Output);
                    break;

                case 'Delete-O365Object':
                    this.DeleteO365ObjectDetails = new DeleteO365ObjectDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline') {
                        for (let index = this.History.length - 2; index > this.History.length - 11; index--) {
                            if (requestItem.Output && index > -1 && this.History[index].Message.match("---")) {
                                this.History.splice(index, 1);
                            }
                        }
                    }
                    break;

                case 'Create-MEU':
                    this.CreateMEUDetails = new CreateMEUDetails(requestItem.Output);
                    break;

                case 'Add-Mailbox-MailAddresses':
                case 'Remove-Mailbox-MailAddresses':
                    this.MailboxMailAddressesDetails = new MailboxMailAddressesDetails(requestItem.Output, requestItem.ProductName);
                    break;

                case 'Change-DistributionList-PrimarySMTP':
                    this.ChangeDistributionListPrimarySMTPDetails = new DistributionListPrimarySMTPDetails(requestItem.Output);
                    break;

                case 'Remove-DistributionList-SendAs':
                    this.DistributionListSendAsDetails = new DistributionListSendAsDetails(requestItem.Output);
                    break;

                case 'Create-AAD-Group':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    break;

                case 'Get-Okta-Group-Members':
                case 'Get-OktaB2B-Group-Members':
                    this.GetOktaGroupMembersDetails = new GetOktaGroupMembersDetails(requestItem.Output);
                    break;
                    
                case 'Get-Okta-AssignedApp':
                    this.GetOktaAssignedAppDetails = new GetOktaAssignedAppDetails(requestItem.Output);
                    break;

                case 'Change-Mailbox-PrimarySMTP':
                    this.ChangeMailboxPrimarySMTPDetails = new ChangeMailboxPrimarySMTPDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("OldPrimarySMTP")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;

                case 'Add-Intune-User':
                    this.AddIntuneUserDetails = new AddIntuneUserDetails(requestItem.Output);
                    break;

                case 'Add-AAD-GroupMember':
                    this.AddAADGroupMemberDetails = new AddAADGroupMemberDetails(requestItem.Output);
                    break;

                case 'Block-User':
                    if (requestItem.Output) {
                        this.CreateDetails = new CreateDetails(requestItem.Output);
                        if (requestItem.Source == 'SharePointOnline' || requestItem.Source == 'CSV') {
                            let index = this.History.length - 1
                            if (requestItem.Output && index > -1 && this.History[index].Message.match("Disabled registered devices: ")) {
                                this.History.splice(index, 1);
                            }
                        }
                    }
                    break;
                case 'Create-Guest-User':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline' || requestItem.Source == 'CSV') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("Redemption URL: ")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Update-Guest-User':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    break;
                case 'Enable-Teams-CAPPhone':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'SharePointOnline' || requestItem.Source == 'CSV') {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("DisplayName: ")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Delete-AzureADGroup':
                case 'Undelete-AzureADGroup':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'CSV' && Object.keys(requestItem.Output).length > 0) {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("AZUREAD Group")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Get-BitlockerKey':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'CSV' && Object.keys(requestItem.Output).length > 0) {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("Bitlocker keys:")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Get-LocalAdminPassword':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    if (requestItem.Source == 'CSV' && Object.keys(requestItem.Output).length > 0) {
                        let index = this.History.length - 1
                        if (requestItem.Output && index > -1 && this.History[index].Message.match("Password:")) {
                            this.History.splice(index, 1);
                        }
                    }
                    break;
                case 'Set-Outlook-Places':
                    this.CreateDetails = new CreateDetails(requestItem.Output);
                    break;
                case 'Get-DistributionList-Info':
                    this.GetDistributionListInfoDetails = new GetDistributionListInfoDetails(requestItem);
                    break;
                default:
                    break;
            }


        } else {
            this.GUID = GUID ? GUID : "";
            this.Requestor = requestor ? requestor : "";
            this.Status = status ? status : "";
            this.Target = target ? target : "";
            this.CreatedOn = createdOn ? dateToFormatedLocaleString(createdOn) : "";
            this.ModifedOn = modifiedOn ? dateToFormatedLocaleString(modifiedOn) : "";
            this.Parameters = parameters ? parameters : null;
            this.History = history ? history : null;
        }
    }
}

export class RequestItemDetailsHistoryItem {
    Message: string;
    Status: string;
    Date: string;

    constructor(requestItemDetailsHistoryItem?: any) {
        if (requestItemDetailsHistoryItem) {
            this.Message = requestItemDetailsHistoryItem.Message ? requestItemDetailsHistoryItem.Message : "";
            this.Status = requestItemDetailsHistoryItem.Status ? requestItemDetailsHistoryItem.Status : "";
            this.Date = requestItemDetailsHistoryItem.Date ? dateToFormatedLocaleString(requestItemDetailsHistoryItem.Date) : "";
        } else {
            this.Message = "";
            this.Status = "";
            this.Date = "";
        }
    }
}

export function mapRequestItemHistory(requestItemHistory) {
    var requestItemHistoryFormated: Array<RequestItemDetailsHistoryItem> = new Array<RequestItemDetailsHistoryItem>();

    requestItemHistory.forEach(element => {
        if (element && ((element.Audience && element.Audience.toUpperCase() == 'LOCALIT') || !element.Audience)) {
            requestItemHistoryFormated.push(new RequestItemDetailsHistoryItem(element));
        }
    });
    return requestItemHistoryFormated;
}