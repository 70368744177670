import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/DomainIDs";
export const API_VERSION = "/v1";

@Injectable()  
export class DomainIDsService{
  constructor(private http: HttpClient) { }

  getDomainIDs() : Observable<any>{
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getDomainIDs';
    return this.http.get(request) as Observable<any>;
  }
}