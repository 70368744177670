import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { UserService } from '../../../Services/user.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { urlPattern } from 'app/Helpers/utils';
@Component({
    selector: 'BodyContent',
    templateUrl: './addZscalerFirewall.component.html',
    providers: [],
    styleUrls: ['../../../app.component.css']
})

export class AddZscalerFirewallComponent {
    
    public selectedLocalIT: String = '';
    isWait = false;
    destinationTypes = [];
    protocols = [];
    alertUserInvalidUrl: string = "This Url is not valid.";
    addZscalerFirewallRequestForm = new FormGroup({
        DestinationType: new FormControl(''),
        Protocol: new FormControl(''),
        Destination: new FormControl('', [Validators.required, Validators.pattern(urlPattern)]),
        ValidatedByCISO: new FormControl(false),
        Description: new FormControl(''),
        TicketID: new FormControl(''),
    });

    constructor(
        private zscalerApiService: ZscalerApiService,
        private businessRulesService: BusinessRulesService,
        private userService: UserService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router
    ) { }

    async ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
            });
        this.destinationTypes = await this.businessRulesService.getSpecificListByKey('DLTYPES', 'DestinationTypes').toPromise();
        this.protocols = await this.businessRulesService.getSpecificListByKey('DLTYPES', 'Protocol').toPromise();
    }

    ngOnDestroy() {
        
    }

    async sendRequest() {
        try {
            this.isWait = true;
            let data = {};
            data = this.addZscalerFirewallRequestForm.value;
            data["localIT"] = this.selectedLocalIT;
            await this.zscalerApiService.addZscalerFirewall(data).toPromise();
            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                duration: 2000,
                verticalPosition: "bottom",
                horizontalPosition: "right",
                panelClass: ['bg-success', 'font-weight-bold'],
            });
            await successSnackBar.afterDismissed().toPromise();
            this.router.navigate(['/history']);
        } catch (error) {
            console.log(error);
            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
        } finally {
            this.isWait = false;
        }
    }
} 