<h4>Get user info:</h4>

<form #getUserInfoForm="ngForm" (ngSubmit)="getUserInfoForm.resetForm()" >
    
    <div class="row form-group">
        <div class="col-4">
            <GroupIDInputFieldOkta label="Group ID" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" [(groupID)]="fileContent.GroupID" (keyup)="setBackEmptyForm()"></GroupIDInputFieldOkta>
        </div>
        <div class="col-1 offset-7">
            <button *ngIf="showResults" class="btn btn-primary" style="float: right;" type="button" (click)="exportToPdfImport()">PDF Export</button>
        </div>
    </div>
    <div *ngIf="!showResults" class="row form-group">
        <div class="col-12">
            <input type="checkbox" [checked]="selectAll" (click)="changeSelectAll()">
            <span style="font-weight:bold;color:#0074D8">Select All</span>
        </div>
    
        <br />
        <br />
        <div class="col-12"> 
            <div *ngFor="let info of informationToShow; let i = index; first as isFirst; last as isLast; trackBy:trackByIndex">
                <div class="row form-group">  
                    <div class="col-12">
                        <input type="checkbox" [checked]="informationToShow[i].Enabled" (click)="changeStatus(i)"/>
                        <span title="{{ informationToShow[i].Tooltip }}" [ngStyle]="immediateShowSections.includes(informationToShow[i].Option) && {'color': '#0074D8'}">{{ informationToShow[i].Option }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div *ngIf="showResults" class="row col-12">
        <div class="offset-9 col-2 form-group">
            <button class="btn btn-primary" type="button" (click)="showAll()">Show All</button>
        </div>
        <div class="col-1 form-group">
            <button class="btn btn-primary" type="button" (click)="hideAll()">Hide All</button>
        </div>
        
        <br />
        
        <!-- 
    
        ADDITIONAL ACCOUNTS Section

        -->
        <table *ngIf="((AdditionalAccountsOkta.okta && AdditionalAccountsOkta.okta.length > 0) || (AdditionalAccountsOkta.oktaB2B && AdditionalAccountsOkta.oktaB2B.length > 0) || (AdditionalAccountsAzure && AdditionalAccountsAzure.length > 0)) && !(errorAdditionalAccountsAzure && errorAdditionalAccountsAzure) && fileContent.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="additionalAccountsCollapsed=!additionalAccountsCollapsed">
                    <th style="text-align: center" colspan="7">
                        ADDITIONAL ACCOUNTS INFORMATION
                        <i *ngIf="additionalAccountsCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!additionalAccountsCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!additionalAccountsCollapsed">
                <tr>
                    <th>GroupID</th>
                    <th>Status</th>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>DisplayName</th>
                    <th>Primary SMTP E-Mail Address</th>
                    <th>Company</th>
                </tr>
                <tr *ngFor="let oktaAccount of AdditionalAccountsOkta.okta">
                    <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTA)'}}</td>
                    <td>{{ oktaAccount.status }}</td>
                    <td>{{ oktaAccount.profile.firstName }}</td>
                    <td>{{ oktaAccount.profile.lastName }}</td>
                    <td>{{ oktaAccount.profile.displayName }}</td>
                    <td>{{ oktaAccount.profile.email }}</td>
                    <td>{{ oktaAccount.profile.organization }}</td>
                </tr>
                <tr *ngFor="let oktaAccount of AdditionalAccountsOkta.oktaB2B">
                    <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTAB2B)'}}</td>
                    <td>{{ oktaAccount.status }}</td>
                    <td>{{ oktaAccount.profile.firstName }}</td>
                    <td>{{ oktaAccount.profile.lastName }}</td>
                    <td>{{ oktaAccount.profile.displayName }}</td>
                    <td>{{ oktaAccount.profile.email }}</td>
                    <td>{{ oktaAccount.profile.organization }}</td>
                </tr>
                <tr *ngFor="let aadAccount of AdditionalAccountsAzure">
                    <td>{{ aadAccount.userPrincipalName.split('@')[0] + ' (Azure)'}}</td>
                    <td>{{ 'Account Enabled: ' + aadAccount.accountEnabled }}</td>
                    <td>{{ aadAccount.givenName }}</td>
                    <td>{{ aadAccount.surname }}</td>
                    <td>{{ aadAccount.displayName }}</td>
                    <td>{{ aadAccount.mail }}</td>
                    <td>{{ aadAccount.companyName }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="(errorAdditionalAccountsAzure || errorAdditionalAccountsOkta || (AdditionalAccountsChecked > 0 && AdditionalAccountsOkta.okta && AdditionalAccountsOkta.okta.length == 0 && AdditionalAccountsOkta.oktaB2B && AdditionalAccountsOkta.oktaB2B.length == 0 && AdditionalAccountsAzure && AdditionalAccountsAzure.length == 0)) && fileContent.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center" colspan="7">
                        ADDITIONAL ACCOUNTS INFORMATION
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="errorAdditionalAccountsOkta">
                    <td colspan="7"><span class="badge badge-info">{{errorAdditionalAccountsOkta}}</span> </td>
                </tr>
                <tr  *ngIf="errorAdditionalAccountsAzure">
                    <td colspan="7"><span class="badge badge-info">{{errorAdditionalAccountsAzure}}</span> </td>
                </tr>
                <tr  *ngIf="!(errorAdditionalAccountsAzure || errorAdditionalAccountsOkta) && AdditionalAccountsChecked > 1">
                    <td colspan="7"><span class="badge badge-info">No additional accounts found</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="fileContent.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" />

        <!--

        OKTA Section

        -->
        
        <table *ngIf="oktaData && !errorOkta && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="oktaInfoCollapsed=!oktaInfoCollapsed">
                    <th style="text-align: center" colspan="2">
                        OKTA USER INFORMATION 
                        <i *ngIf="oktaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!oktaInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!oktaInfoCollapsed">
                <tr>
                    <th>Status</th>
                        <td>{{ oktaData.status }}</td>
                    </tr>
                <tr>
                    <th>FirstName</th>
                    <td>{{ oktaData.firstName }}</td>
                </tr>
                <tr>
                    <th>LastName</th>
                    <td>{{ oktaData.lastName }}</td>
                </tr>
                <tr>
                    <th>DisplayName</th>
                    <td>{{ oktaData.displayName }}</td>
                </tr>
                <tr>
                    <th>Primary SMTP E-Mail Address</th>
                    <td>{{ oktaData.email }}</td>
                </tr>
                <tr>
                    <th>Company</th>
                    <td>{{ oktaData.organization }}</td>
                </tr>
                <tr>
                    <th>Business Unit</th>
                    <td>{{ oktaData.division }}</td>
                </tr>
                <tr>
                    <th>GBU</th>
                    <td>{{ oktaData.C_msDSCloudExtensionAttribute3 }}</td>
                </tr>
                <tr>
                    <th>OrgID</th>
                    <td>{{ oktaData.C_msDSCloudExtensionAttribute2 }}</td>
                </tr>
                <tr>
                    <th>Local IT</th>
                    <td>{{ oktaData.c_msDSCloudExtensionAttribute1 }}</td>
                </tr>
                <tr>
                    <th>Cost Center</th>
                    <td>{{ oktaData.c_costCenter }}</td>
                </tr>
                <tr>
                    <th>Site</th>
                    <td>{{ oktaData.C_physicalDeliveryOfficeName }}</td>
                </tr>
                <tr>
                    <th>Department</th>
                    <td>{{ oktaData.department }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorOkta && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center" colspan="2">
                        OKTA USER INFORMATION 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"><span class="badge badge-info">{{errorOkta}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(oktaData || errorOkta) && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" />
        <br *ngIf="!oktaInfoCollapsed && (oktaData || errorOkta) && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" />

        <!--

        OKTA MFA 

        -->
        
        <table *ngIf="oktaMfaData && !errorOktaMfa && fileContent.InformationToShow.includes('OKTA MFA')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="oktaMFACollapsed=!oktaMFACollapsed">
                    <th style="text-align: center" colspan="5">
                        OKTA MFA
                        <i *ngIf="oktaMFACollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!oktaMFACollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>                
                </tr>
                <tr *ngIf="!oktaMFACollapsed">
                    <th>Provider</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Device Type</th>
                    <th>Creation Date</th>
                </tr>
            </thead>
            <tbody *ngIf="!oktaMFACollapsed">
                <tr *ngFor="let oktaMFA of oktaMfaData">
                    <td>{{ oktaMFA.provider }}</td>
                    <td>{{ oktaMFA.factorType }}</td>
                    <td>{{ oktaMFA.status }}</td>
                    <td>{{ oktaMFA.profile.deviceType }} <span *ngIf="oktaMFA.profile.name">({{ oktaMFA.profile.name }})</span></td>
                    <td>{{ oktaMFA.created }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorOktaMfa && fileContent.InformationToShow.includes('OKTA MFA')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center" colspan="2">
                        OKTA MFA 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"><span class="badge badge-info">{{errorOktaMfa}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(oktaMfaData || errorOktaMfa) && fileContent.InformationToShow.includes('OKTA MFA')" />
        <br *ngIf="!oktaMFACollapsed && (oktaMfaData || errorOktaMfa) && fileContent.InformationToShow.includes('OKTA MFA')" />

        <!-- 
    
        OFFICE365 Section

        -->
        <table *ngIf="azureData && !errorAzure && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="office365InfoCollapsed=!office365InfoCollapsed">
                    <th style="text-align: center" colspan="2">
                        OFFICE365 USER INFORMATION
                        <i *ngIf="office365InfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!office365InfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!office365InfoCollapsed">
                <tr>
                    <th>Status</th>
                    <td>
                        {{ 'Account Enabled: ' + azureData.accountEnabled }}<br *ngIf="azureData.accountEnabled && azureData.assignedLicenses" />
                        {{ azureData.assignedLicenses ? 'IsLicensed: ' + azureData.assignedLicenses : '' }}
                    </td>
                </tr>
                <tr>
                    <th>FirstName</th>
                    <td>{{ azureData.givenName }}</td>
                </tr>
                <tr>
                    <th>LastName</th>
                    <td>{{ azureData.surname }}</td>
                </tr>
                <tr>
                    <th>DisplayName</th>
                    <td>{{ azureData.displayName }}</td>
                </tr>
                <tr>
                    <th>Primary SMTP E-Mail Address</th>
                    <td>{{ azureData.mail }}</td>
                </tr>
                <tr>
                    <th>Company</th>
                    <td>{{ azureData.companyName }}</td>
                </tr>
                <tr>
                    <th>Language</th>
                    <td>{{ azureData.preferredLanguage }}</td>
                </tr>
                <tr>
                    <th>E-Mail Addresses</th>
                    <td>
                        <div *ngFor="let mail of azureData.proxyAddresses | orderby : ''">
                            <span >{{ mail }}</span><br />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorAzure && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center" colspan="2">
                        OFFICE365 USER INFORMATION 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"><span class="badge badge-info">{{errorAzure}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(azureData || errorAzure) && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" />
        <br *ngIf="!office365InfoCollapsed && (azureData || errorAzure) && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" />

        <!--

        LICENSE Section

        -->
        <table *ngIf="userLicenses && fileContent.InformationToShow.includes('USER LICENSES')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="userLicensesCollapsed=!userLicensesCollapsed">
                    <th style="text-align: center" colspan="2">
                        USER LICENSES
                        <i *ngIf="userLicensesCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!userLicensesCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>                
                </tr>
                <tr *ngIf="!userLicensesCollapsed">
                    <th>License Name</th>
                    <th>Service Plan (Provisioning Status)</th>
                </tr>
            </thead>
            <tbody *ngIf="!userLicensesCollapsed">
                <tr *ngFor="let license of userLicenses">
                    <th>{{ license.skuPartNumber }}</th>
                    <td>
                        <div *ngFor="let servicePlan of license.servicePlans">
                            <span>{{ servicePlan.servicePlanName }} (<b [ngStyle]="{'color': getStatusColorImport(servicePlan.provisioningStatus)}">{{ servicePlan.provisioningStatus }}</b>)</span><br />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorAzureLicenses && fileContent.InformationToShow.includes('USER LICENSES')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center" colspan="2">
                        USER LICENSES 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"><span class="badge badge-info">{{errorAzureLicenses}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(userLicenses || errorAzureLicenses) && fileContent.InformationToShow.includes('USER LICENSES')" />
        <br *ngIf="!userLicensesCollapsed && (userLicenses || errorAzureLicenses) && fileContent.InformationToShow.includes('USER LICENSES')" />

        <!--

        AAD SECURITY GROUPS OWNED Section

        -->
        <table *ngIf="!errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="AADSecurityGroupOwnershipsCollapsed=!AADSecurityGroupOwnershipsCollapsed">
                    <th style="text-align: center">
                        AAD SECURITY GROUPS OWNED
                        <i *ngIf="AADSecurityGroupOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!AADSecurityGroupOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!AADSecurityGroupOwnershipsCollapsed">
                <tr *ngFor="let ownership of AADSecurityGroupOwnerships | orderby : 'displayName'">
                    <td>{{ ownership.displayName }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center">
                        AAD SECURITY GROUPS OWNED 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="badge badge-info">{{errorAADUserOwnedObjects}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(AADSecurityGroupOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')" />
        <br *ngIf="!AADSecurityGroupOwnershipsCollapsed && (AADSecurityGroupOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')" />

        <!--

        AAD SYNCHRONIZED SECURITY GROUPS OWNED Section

        -->
        <table *ngIf="!errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="AADSynchronizedSecurityGroupOwnershipsCollapsed=!AADSynchronizedSecurityGroupOwnershipsCollapsed">
                    <th style="text-align: center">
                        AAD SYNCHRONIZED SECURITY GROUPS OWNED
                        <i *ngIf="AADSynchronizedSecurityGroupOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed">
                <tr *ngFor="let ownership of AADSynchronizedSecurityGroupOwnerships | orderby : 'displayName'">
                    <td >{{ ownership.displayName }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center">
                        AAD SYNCHRONIZED SECURITY GROUPS OWNED
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="badge badge-info">{{errorAADUserOwnedObjects}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(AADSynchronizedSecurityGroupOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" />
        <br *ngIf="!AADSynchronizedSecurityGroupOwnershipsCollapsed && (AADSynchronizedSecurityGroupOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" />

        <!--

        AAD APPLICATIONS OWNED

        -->
        <table *ngIf="!errorAADUserOwnedObjects && !errorAzure && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="AADApplicationOwnershipsCollapsed=!AADApplicationOwnershipsCollapsed">
                    <th style="text-align: center">
                        AAD APPLICATIONS OWNED
                        <i *ngIf="AADApplicationOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!AADApplicationOwnershipsCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!AADApplicationOwnershipsCollapsed">
                <tr *ngFor="let ownership of AADApplicationOwnerships | orderby : 'displayName'">
                    <td>{{ ownership.displayName }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center">
                        AAD APPLICATIONS OWNED
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="badge badge-info">{{errorAADUserOwnedObjects}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(AADApplicationOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" />
        <br *ngIf="!AADApplicationOwnershipsCollapsed && (AADApplicationOwnerships || errorAADUserOwnedObjects) && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" />

        <!--

        AAD GUEST USER SPONSORSHIPS

        -->
        <table *ngIf="!errorAADGuestUsers && !errorAzure && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="AADGuestUsersCollapsed=!AADGuestUsersCollapsed">
                    <th style="text-align: center">
                        AAD GUEST USER SPONSORSHIPS
                        <i *ngIf="AADGuestUsersCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!AADGuestUsersCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!AADGuestUsersCollapsed">
                <tr *ngFor="let ownership of AADGuestUsers | orderby : 'mail'">
                    <td>{{ ownership.mail }}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="(errorAADGuestUsers || errorAzure) && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" class="table">
            <thead class="thead-default">
                <tr class="historySection">
                    <th style="text-align: center">
                        AAD GUEST USER SPONSORSHIPS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngIf="errorAzure"><span class="badge badge-info">{{errorAzure}}</span> </td>
                    <td *ngIf="errorAADGuestUsers"><span class="badge badge-info">{{errorAADGuestUsers}}</span> </td>
                </tr>
            </tbody>
        </table>
        <br *ngIf="(AADGuestUsers || errorAADGuestUsers) && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" />
        <br *ngIf="!AADGuestUsersCollapsed && (AADGuestUsers || errorAADGuestUsers) && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" />
    </div>
    
    <div *ngIf="showresultsButton(getUserInfoForm.invalid) && !showResults" class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="showRequestResults();" [disabled]="(getUserInfoForm.invalid)">Show Results</button>
        </div>
    </div>
    <div *ngIf="!showResults" class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); getUserInfoForm.reset();" [disabled]="getUserInfoForm.invalid || !minSelectedCheckboxes()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(getUserInfoForm.invalid); getUserInfoForm.reset();" [disabled]="((getUserInfoForm.invalid || !minSelectedCheckboxes()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- Hidden Section For PDF Export Without order by -->

<div id="hiddenTable" style="display: none">
    <table id="table1" title="Get-User-Info Details {{fileContent.GroupID}}"></table>
        <!-- 
    
        ADDITIONAL ACCOUNTS Section

        -->
        <table id="table2" *ngIf="((AdditionalAccountsOkta.okta && AdditionalAccountsOkta.okta.length > 0) || (AdditionalAccountsOkta.oktaB2B && AdditionalAccountsOkta.oktaB2B.length > 0) || (AdditionalAccountsAzure && AdditionalAccountsAzure.length > 0)) && !(errorAdditionalAccountsAzure && errorAdditionalAccountsAzure) && fileContent.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" title="ADDITIONAL ACCOUNTS INFORMATION">
            <thead class="thead-default">
                <tr>
                    <th>GroupID</th>
                    <th>Status</th>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>DisplayName</th>
                    <th>Primary SMTP E-Mail Address</th>
                    <th>Company</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let oktaAccount of AdditionalAccountsOkta.okta">
                    <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTA)'}}</td>
                    <td>{{ oktaAccount.status }}</td>
                    <td>{{ oktaAccount.profile.firstName }}</td>
                    <td>{{ oktaAccount.profile.lastName }}</td>
                    <td>{{ oktaAccount.profile.displayName }}</td>
                    <td>{{ oktaAccount.profile.email }}</td>
                    <td>{{ oktaAccount.profile.organization }}</td>
                </tr>
                <tr *ngFor="let oktaAccount of AdditionalAccountsOkta.oktaB2B">
                    <td>{{ (oktaAccount.profile.C_groupIdentifier ? oktaAccount.profile.C_groupIdentifier : oktaAccount.profile?.login?.split('@')[0]) + ' (OKTAB2B)'}}</td>
                    <td>{{ oktaAccount.status }}</td>
                    <td>{{ oktaAccount.profile.firstName }}</td>
                    <td>{{ oktaAccount.profile.lastName }}</td>
                    <td>{{ oktaAccount.profile.displayName }}</td>
                    <td>{{ oktaAccount.profile.email }}</td>
                    <td>{{ oktaAccount.profile.organization }}</td>
                </tr>
                <tr *ngFor="let aadAccount of AdditionalAccountsAzure">
                    <td>{{ aadAccount.userPrincipalName.split('@')[0] + ' (Azure)'}}</td>
                    <td>{{ 'Account Enabled: ' + aadAccount.accountEnabled }}</td>
                    <td>{{ aadAccount.givenName }}</td>
                    <td>{{ aadAccount.surname }}</td>
                    <td>{{ aadAccount.displayName }}</td>
                    <td>{{ aadAccount.mail }}</td>
                    <td>{{ aadAccount.companyName }}</td>
                </tr>
            </tbody>
        </table>
        <table id="table3" *ngIf="(errorAdditionalAccountsAzure || errorAdditionalAccountsOkta || (AdditionalAccountsOkta.okta && AdditionalAccountsOkta.okta.length == 0 && AdditionalAccountsOkta.oktaB2B && AdditionalAccountsOkta.oktaB2B.length == 0 && AdditionalAccountsAzure && AdditionalAccountsAzure.length == 0) ) && fileContent.InformationToShow.includes('ADDITIONAL ACCOUNTS INFORMATION')" title="">
            <thead>
                <tr>
                    <th colspan="7">
                        ADDITIONAL ACCOUNTS INFORMATION
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="errorAdditionalAccountsOkta">
                    <td colspan="7"><span>{{errorAdditionalAccountsOkta}}</span> </td>
                </tr>
                <tr  *ngIf="errorAdditionalAccountsAzure">
                    <td colspan="7"><span>{{errorAdditionalAccountsAzure}}</span> </td>
                </tr>
                <tr  *ngIf="!(errorAdditionalAccountsAzure || errorAdditionalAccountsOkta)">
                    <td colspan="7"><span>No additional accounts found</span> </td>
                </tr>
            </tbody>
        </table>
    <!--

    OKTA USER INFORMATION 

    -->
    <table id="table4" *ngIf="oktaData && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" title="">
        <thead class="thead-default">
            <tr>
                <th>OKTA USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Status</th>
                <td>{{ oktaData.status }}</td>
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ oktaData.firstName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ oktaData.lastName }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ oktaData.displayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ oktaData.email }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ oktaData.organization }}</td>
            </tr>
            <tr>
                <th>Business Unit</th>
                <td>{{ oktaData.division }}</td>
            </tr>
            <tr>
                <th>OrgID</th>
                <td>{{ oktaData.C_msDSCloudExtensionAttribute2 }}</td>
            </tr>
            <tr>
                <th>Local IT</th>
                <td>{{ oktaData.c_msDSCloudExtensionAttribute1 }}</td>
            </tr>
            <tr>
                <th>Cost Center</th>
                <td>{{ oktaData.c_costCenter }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table5" *ngIf="errorOkta && fileContent.InformationToShow.includes('OKTA USER INFORMATION')" title="">
        <thead>
            <tr>
                <th colspan="2">
                    OKTA USER INFORMATION 
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="2">{{errorOkta}} </td>
            </tr>
        </tbody>
    </table>
    <!--

    OKTA MFA 

    -->
    <table id="table6" *ngIf="oktaMfaData && fileContent.InformationToShow.includes('OKTA MFA')" title="OKTA MFA">
        <thead class="thead-default">
            <tr>
                <th>Provider</th>
                <th>Type</th>
                <th>Status</th>
                <th>Device Type</th>
                <th>Creation Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let oktaMFA of oktaMfaData">
                <td>{{ oktaMFA.provider }}</td>
                <td>{{ oktaMFA.factorType }}</td>
                <td>{{ oktaMFA.status }}</td>
                <td>{{ oktaMFA.profile.deviceType }} <span *ngIf="oktaMFA.profile.name">({{ oktaMFA.profile.name }})</span></td>
                <td>{{ oktaMFA.created }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table7" *ngIf="errorOktaMfa && fileContent.InformationToShow.includes('OKTA MFA')" title="">
        <thead>
            <tr>
                <th colspan="2">
                    OKTA MFA 
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="2"><span>{{errorOktaMfa}}</span> </td>
            </tr>
        </tbody>
    </table>
    <!-- 
    
    OFFICE365 USER INFORMATION

    -->
    <table id="table8" *ngIf="azureData && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" title="">
        <thead class="thead-default">
            <tr>
                <th>OFFICE365 USER INFORMATION</th>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Status</th>
                <td>{{ 'Account Enabled: ' + azureData.accountEnabled + '\r\n'}}
{{ azureData.assignedLicenses ? 'IsLicensed: ' + azureData.assignedLicenses : '' }}</td>                
            </tr>
            <tr>
                <th>FirstName</th>
                <td>{{ azureData.givenName }}</td>
            </tr>
            <tr>
                <th>LastName</th>
                <td>{{ azureData.surname }}</td>
            </tr>
            <tr>
                <th>DisplayName</th>
                <td>{{ azureData.displayName }}</td>
            </tr>
            <tr>
                <th>Primary SMTP E-Mail Address</th>
                <td>{{ azureData.mail }}</td>
            </tr>
            <tr>
                <th>Company</th>
                <td>{{ azureData.companyName }}</td>
            </tr>
            <tr>
                <th>Language</th>
                <td>{{ azureData.preferredLanguage }}</td>
            </tr>
            <tr>
                <th>E-Mail Addresses</th>
                <td>
                    <div *ngFor="let mail of azureData.proxyAddresses | orderby : ''">
{{ mail + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
    </table>
    <table id="table9" *ngIf="errorAzure && fileContent.InformationToShow.includes('OFFICE365 USER INFORMATION')" title="">
        <thead>
            <tr>
                <th colspan="2">
                    OFFICE365 USER INFORMATION 
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="2"><span>{{errorAzure}}</span> </td>
            </tr>
        </tbody>
    </table>

    <!-- 
    
    USER LICENSES

    -->
    <table id="table10" *ngIf="userLicenses && fileContent.InformationToShow.includes('USER LICENSES')" title="USER LICENSES">
        <thead class="thead-default">
            <tr>
                <th>License Name</th>
                <th>Service Plan (Provisioning Status)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let license of userLicenses">
                <td>{{ license.skuPartNumber }}</td>
                <td>
                    <div *ngFor="let servicePlan of license.servicePlans">
<span>{{ servicePlan.servicePlanName }} ({{ servicePlan.provisioningStatus + ")\r\n" }}</span></div>
                </td>
            </tr>
        </tbody>
    </table>
    <table id="table11" *ngIf="errorAzureLicenses && fileContent.InformationToShow.includes('USER LICENSES')" title="">
        <thead>
            <tr>
                <th colspan="2">
                    USER LICENSES 
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="2">{{errorAzureLicenses}} </td>
            </tr>
        </tbody>
    </table>

    <!--

    AAD SECURITY GROUPS OWNED Section

    -->
    <table id="table12" *ngIf="!errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')">
        <thead class="thead-default">
            <tr>
                <th>AAD SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ownership of AADSecurityGroupOwnerships | orderby : 'displayName'">
                <td>{{ ownership.displayName }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table13" *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SECURITY GROUPS OWNED')" title="">
        <thead>
            <tr>
                <th>AAD SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><span>{{errorAADUserOwnedObjects}}</span> </td>
            </tr>
        </tbody>
    </table>
    <!--

    AAD SYNCHRONIZED SECURITY GROUPS OWNED Section

    -->
    <table id="table14" *ngIf="!errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" title="">
        <thead class="thead-default">
            <tr>
                <th>AAD SYNCHRONIZED SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ownership of AADSynchronizedSecurityGroupOwnerships | orderby : 'displayName'">
                <td>{{ ownership.displayName }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table15" *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD SYNCHRONIZED SECURITY GROUPS OWNED')" title="">
        <thead>
            <tr>
                <th>AAD SYNCHRONIZED SECURITY GROUPS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><span>{{errorAADUserOwnedObjects}}</span> </td>
            </tr>
        </tbody>
    </table>
    
    <!--

    AAD APPLICATIONS OWNED

    -->
    <table id="table16" *ngIf="!errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" title="">
        <thead class="thead-default">
            <tr>
                <th>AAD APPLICATIONS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ownership of AADApplicationOwnerships | orderby : 'displayName'">
                <td>{{ ownership.displayName }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table17" *ngIf="errorAADUserOwnedObjects && fileContent.InformationToShow.includes('AAD APPLICATIONS OWNED')" title="">
        <thead>
            <tr>
                <th>AAD APPLICATIONS OWNED</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><span>{{errorAADUserOwnedObjects}}</span> </td>
            </tr>
        </tbody>
    </table>

    <!--

    AAD GUEST USER SPONSORSHIPS

    -->
    <table id="table18" *ngIf="!errorAADGuestUsers && !errorAzure && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" title="">
        <thead class="thead-default">
            <tr>
                <th>AAD GUEST USER SPONSORSHIPS</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ownership of AADGuestUsers | orderby : 'mail'">
                <td>{{ ownership.mail }}</td>
            </tr>
        </tbody>
    </table>
    <table id="table19" *ngIf="(errorAADGuestUsers || errorAzure) && fileContent.InformationToShow.includes('AAD GUEST USER SPONSORSHIPS')" title="">
        <thead>
            <tr>
                <th>AAD GUEST USER SPONSORSHIPS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td *ngIf="errorAADGuestUsers"><span>{{errorAADGuestUsers}}</span> </td>
                <td *ngIf="errorAzure"><span>{{errorAzure}}</span> </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- LIST OF GETUSERINFO READY TO BE SEND  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b>
            <i id="{{'getUserInfoRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>            
        </span>
    </a>
</div>