import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirmationDialog',
    templateUrl: './confirmationDialog.component.html'
})

export class ConfirmationDialog {
    public Data: any;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.Data = data;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}