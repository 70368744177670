<!-- Hidden Section For PDF Export Without order by -->

<div id="hiddenTable"  style="display: none">
    <table id="table0" title="{{getIdentityPersonalDataDetails.ProductName}} Details {{getIdentityPersonalDataDetails.GroupID}}"></table>
    <table *ngFor="let property of Object.keys(getIdentityPersonalDataDetails.Output); let i = index" id="{{'table'}}{{i + 1}}" title="">
        <thead>
            <tr>
                <th>{{property}}</th>
                <td></td>
            </tr>
        </thead>
        <tbody *ngIf="getIdentityPersonalDataDetails.Output[property]">
            <tr *ngFor="let key of Object.keys(getIdentityPersonalDataDetails.Output[property]) |  orderby : '':false:true">
                <th>{{key}}</th>
                <td *ngIf="!Array.isArray(getIdentityPersonalDataDetails.Output[property][key])">{{getIdentityPersonalDataDetails.Output[property][key]}}</td>
                <td *ngIf="Array.isArray(getIdentityPersonalDataDetails.Output[property][key])">
                    <div *ngFor="let val of getIdentityPersonalDataDetails.Output[property][key] | orderby : '':false:true">{{ val + '\r\n'}}</div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!getIdentityPersonalDataDetails.Output[property]">
        </tbody>
    </table>
</div>