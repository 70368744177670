<h4>Set Office 365 Retention Policy:</h4>

<form #fileContentForm="ngForm" (ngSubmit)="fileContentForm.resetForm()">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="siteUrlInput">Site Url:</label>
            <input type="text" id="siteUrlInput" required name="siteUrlInput" class="form-control"
                [(ngModel)]="fileContent.SiteUrl" #siteUrlInput="ngModel">
            <div *ngIf="siteUrlInput.invalid && (siteUrlInput.dirty || siteUrlInput.touched)"
                class="alert alert-danger">
                <div *ngIf="siteUrlInput.errors.required">
                    Site Url is required.
                </div>             
            </div>     
        </div>
        <div class="col-4">
            <label for="retentionPeriodDropDown">Retention Period:</label>
            <select id="retentionPeriodDropDown" class="form-control"
                [(ngModel)]="fileContent.RetentionPeriod" name="retentionPeriodDropDown">
                <option *ngFor="let duration of retentionPeriods | orderby: '' : false : true" [value]="duration">{{duration}} years</option>
            </select>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList();"
                [disabled]="fileContentForm.invalid" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid);"
                [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<!-- LIST READY TO BE SENT  -->
<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-12" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i" aria-expanded="false" aria-controls="collapseExample">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.SiteUrl }}</b>
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>
            <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
        </span>
    </a>
    <!-- COLLAPSIBLE AREA -->
    <div class="collapse col-12" [attr.id]="'listedFileContent' + i">
        <div class="card card-block">
            <div class="row">
                <div class="col-4">
                    <b>Site Url:</b> {{ listedFileContent.SiteUrl }}
                </div>
                <div class="col-4">
                    <b>Retention Period:</b> {{ listedFileContent.RetentionPeriod }}
                </div>
                <div class="col-4">
                    <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
                </div>                        
            </div>
            <div class="row">
                <div class="col-1" style="padding-top:33px">
                    <button class="btn btn-primary" (click)="removeFileContentFromList(i)">Remove</button>
                </div>
            </div>
        </div>
    </div>
</div>