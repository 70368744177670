import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../../Services/requestContainer.service';
import { LocalItCard, LocalItCardMember, ManageLocalITCardMembersRequest, LocalItCardFormatedForSending } from '../../../../Models/LocalItCard';
import { LocalITsService } from '../../../../Services/localITs.service';
import { WebAdministrationService } from '../../../../Services/webAdministration.service';
import { UserService } from '../../../../Services/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';
import { getStatusColor, dateToFormatedLocaleString } from '../../../../Helpers/utils';

@Component({
    selector: 'localItCardsDialog',
    templateUrl: './localItCardsDialog.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../../app.component.css']
})

export class LocalItCardsDialog {
    public fileContentsList = new Array<LocalItCardFormatedForSending>();
    public localItCard = new LocalItCard();
    public selectedLocalIT: String = '';
    public localItInfoCollapsed: boolean = false;
    public scopeCollapsed: boolean = false;
    public membershipCollapsed: boolean = false;
    public productsCollapsed: boolean = false;
    public isAllowedToEditLocalItCard: boolean = false;
    public isCurrentUserLocalITManager: boolean = false;
    public membersInEditMode: boolean = false;
    public tempMembers: Array<LocalItCardMember> = new Array<LocalItCardMember>();

    constructor(private webAdministrationService: WebAdministrationService, private userService: UserService, private requestContainerService: RequestContainerService, public dialogRef: MatDialogRef<LocalItCardsDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private localITsService: LocalITsService, private router: Router, public dialog: MatDialog, public snackBar: MatSnackBar,) {
        if (data && data.localITName) {
            this.localITsService.getLocalITByName(data.localITName).subscribe(response => {
                this.localItCard = new LocalItCard(response);
                this.tempMembers = [...this.localItCard.Members];
                this.isCurrentUserLocalITManager = data.currentUserInfo && this.localItCard && this.localItCard.Manager ? data.currentUserInfo.GroupID == this.localItCard.Manager.GroupID : false;
            });

            this.isAllowedToEditLocalItCard = data.currentUserInfo ? data.currentUserInfo.IsAdmin : false;
        }
    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    }

    getStatusColorImport(status: string) {
        return getStatusColor(status);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    activeMembersEditMode() {
        this.membersInEditMode = true;
    }

    cancelMembersEditMode() {
        this.membersInEditMode = false;
        this.tempMembers = [...this.localItCard.Members];
    }

    saveMembers() {
        this.sendRequest();
    }

    addAdditionalMember() {
        this.tempMembers.push(new LocalItCardMember());
    }

    removeAdditionalMember(index) {
        this.tempMembers.splice(index, 1);
    }

    removeOrResetMember(index) {
        if (this.tempMembers.length == 1) {
            this.tempMembers[index] = new LocalItCardMember();
        } else {
            this.tempMembers.splice(index, 1);
        }
    }

    isMemberEditDisabled(index) {
        if ((this.tempMembers[index].Name && this.tempMembers[index].Name != '') || (this.tempMembers[index].Email && this.tempMembers[index].Email != '')) {
            return true;
        } else {
            return false;
        }
    }

    navigateToLocalITCardEdition() {
        this.dialogRef.close();
        this.router.navigate(['/administration/manageLocalITCard'], { queryParams: { IT: this.localItCard.Name } });
    }

    sendRequest() {
        var data = new ManageLocalITCardMembersRequest();
        data.newMembers = Array.isArray(this.tempMembers) ? this.tempMembers.map(member => member.GroupID) : new Array<string>();
        data.editedLocalIT = this.localItCard.Name;
        data.localIT = this.selectedLocalIT;

       this.localITsService.manageLocalITCardMembers(data)
            .subscribe(response => {
                this.membersInEditMode = false;
                this.localItCard.Members = [...this.tempMembers];
                let successSnackBar = this.snackBar.open("CHANGE LOCAL IT CARD MEMBERS REQUEST SENT!", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.dialogRef.close();
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}
