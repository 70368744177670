export class EnableLitigationHoldDetails{
    GroupID: string = '';
    LitigationHoldDuration: string = '';
    LitigationHoldOwner: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.GroupID = requestItemOutput.Mailbox;
            this.LitigationHoldDuration = requestItemOutput.LitigationHoldDuration;
            this.LitigationHoldOwner = requestItemOutput.LitigationHoldOwner;            
        }
    }
}