export class MailboxMailAddressesDetails{
    Mailbox: string = '';
    NewSMTPAddress: Array<string> = new Array<string>();
    EmailAddresses: Array<string> = new Array<string>();
    ProductName: string = '';

    constructor(requestItemOutput?, productName?) {
        if(requestItemOutput){
            this.Mailbox = requestItemOutput.Mailbox;
            this.ProductName = productName;
            
            if(Array.isArray(requestItemOutput.NewSMTPAddress)){
                this.NewSMTPAddress = requestItemOutput.NewSMTPAddress;
            }else if (requestItemOutput.NewSMTPAddress){
                this.NewSMTPAddress.push(requestItemOutput.NewSMTPAddress);
            }
            
            if(Array.isArray(requestItemOutput.EmailAddresses)){
                this.EmailAddresses = requestItemOutput.EmailAddresses;
            }else if (requestItemOutput.EmailAddresses){
                this.EmailAddresses.push(requestItemOutput.EmailAddresses);
            }
        }
    }
}