export class GetIdentityPersonalDataDetails{
    Output
    GroupID: string = '';
    ProductName: string = '';

    constructor(requestItemOutput?, productName?, groupID?) {
        if(requestItemOutput){
            this.ProductName = productName;
            this.GroupID = groupID;
            this.Output = requestItemOutput;
        }
    }
}