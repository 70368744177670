import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/webAdministrations";
export const API_VERSION = "/v1";

@Injectable()  
export class HomeService{
  constructor(private http: HttpClient) { }

  getMessageInfo() : Observable<any>{
    let messageInfo = API_BASE_URL + API_NAME + API_VERSION + '/getMessageInfo';
    return this.http.get(messageInfo) as Observable<any>;
  }

  getAllMessagesInfo() : Observable<any>{
    let messageInfo = API_BASE_URL + API_NAME + API_VERSION + '/getAllMessagesInfo';
    return this.http.get(messageInfo) as Observable<any>;
  }

  getMessageInfoById(id) : Observable<any>{
    let messageInfo = API_BASE_URL + API_NAME + API_VERSION + '/getMessageInfoById';
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(messageInfo, {params: params}) as Observable<any>;
  }

  addOrUpdateMessageInfo(data){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addOrUpdateMessageInfo';

    return this.http.post(request, data)
      .pipe(map(response => { return response }));
  }

  deleteMessageInfoById(id){
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteMessageInfoById';

    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(request, {params: params}) as Observable<any>;
  }

  getServiceState() : Observable<any>{
    let serviceStateapi = API_BASE_URL + API_NAME + API_VERSION + '/getServiceState';
    return this.http.get(serviceStateapi) as Observable<any>;
  }
  
  getRequestsQueue() : Observable<any>{
    let requestsQueue = API_BASE_URL + "/requests" + API_VERSION + '/getRequestsQueue';
    return this.http.get(requestsQueue) as Observable<any>;
  }

  getReleases() : Observable<any>{
    let releases = API_BASE_URL + API_NAME + API_VERSION + '/getReleases';
    return this.http.get(releases) as Observable<any>;
  }
}