<div class="row">
    <div class="col-xs-12 col-lg-8">
        <h2>Release notes</h2>
        <small>Last release : {{releases ? dateToFormatedLocaleString(releases[0]?.date) : null}}</small>
        <ul *ngFor="let release of releases; let l = index;" class="list-group">
            <div *ngIf="l<4">
                <li class="list-group-item justify-content-between align-items-center" role="button"
                    data-toggle="collapse" [attr.data-target]="'#' + release._key">
                    <strong>{{release.title}}</strong><span
                        class="push-right">{{dateToFormatedLocaleString(release.date)}}</span>
                </li>
                <ul [class]="l == 0 ? 'list-group collapse show' : 'list-group collapse'" id="{{release._key}}">
                    <li class="list-group-item justify-content-between align-items-center" [innerHTML]="release.body">
                    </li>
                </ul>
            </div>
        </ul>
    </div>
    <div class="col-xs-6 col-lg-4">
        <h2>Services status</h2>
        <small>Refreshed {{formatedserviceState.updated}} </small>
        <i *ngIf="!serviceStateUpdated()" class="ms-Icon ms-Icon--Warning text-warning" aria-hidden="true"></i>
        <div class="list-group">
            <div *ngFor="let property of serviceStateProperties; let i = index" class="list-group-item">
                <span class="list-group-item-heading">
                    {{serviceStateProperties[i]}}
                </span>
                <p class="list-group-item-text">
                    <i *ngIf="serviceState[property] == 'Running' && serviceStateUpdated()"
                        class="ms-Icon ms-Icon--StatusCircleCheckmark text-success" aria-hidden="true"
                        style="font-size: 30px;"></i>
                    <i *ngIf="serviceState[property] != 'Running' && serviceStateUpdated()"
                        class="ms-Icon ms-Icon--StatusCircleErrorX text-danger" aria-hidden="true"
                        style="font-size: 30px;"></i>
                </p>
            </div>
        </div>
        <div class="item-queue">
            <h2>Items queue</h2>
            <h3 class="panel-title">
                <small><strong>{{totalQueue}}</strong> item(s) in process</small>
            </h3>
            <ul *ngFor="let agent of agents; let k = index" class="list-group">
                <li class="list-group-item justify-content-between align-items-center" role="button"
                    data-toggle="collapse" [attr.data-target]="'#' + agent">
                    <strong>ProductLauncher-{{agent}}</strong>
                    <span class="badge badge-primary badge-pill">{{formatedrequestsQueue[agent].count}}</span>
                </li>
                <ul class="list-group collapse" id="{{agent}}">
                    <div *ngFor="let product of GetItemsQueueProducts(agent); let j = index">
                        <li *ngIf="product != 'count'"
                            class="list-group-item justify-content-between align-items-center">
                            {{product}}
                            <span
                                class="badge badge-primary badge-pill">{{formatedrequestsQueue[agent][product].count}}</span>
                        </li>
                    </div>
                </ul>
            </ul>
        </div>
    </div>
</div>