import { Component, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { CustomerProfilesService } from '../../../../Services/customerProfiles.service';
import { UserService } from '../../../../Services/user.service';
import { CompaniesService } from '../../../../Services/companies.service';
import { BusinessRulesService } from '../../../../Services/businessRules.service';

import { CustomerProfile, CustomerProfileRequest } from '../../../../Models/CustomerProfile';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';

import { UserInfo } from '../../../../Models/UserInfo';
import { WebAdministrationService } from '../../../../Services/webAdministration.service';


@Component({
    selector: 'manageCustomerProfile',
    templateUrl: './manageCustomerProfile.component.html',
    providers: [CompaniesService, CustomerProfilesService, BusinessRulesService],
    styleUrls: ['../../../../app.component.css']
})

export class ManageCustomerProfileComponent {
    public fileContentsList = new Array<CustomerProfile>();
    public customerProfile = new CustomerProfile();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public companies: Array<any> = new Array<any>();
    public existingCustomerProfiles: Array<any> = new Array<any>();
    public timeZones: Array<any> = new Array<any>();
    public licenseTypes: Array<any> = new Array<any>();
    public languages: Array<any> = new Array<any>();
    public assignableDeviceTypes: Array<string> = new Array<string>();
    public litigationHoldDurations: Array<string> = new Array<string>();
    public isCreation: boolean = true;
    public messageId: string = null;

    constructor(private companiesService: CompaniesService, private webAdministrationService: WebAdministrationService, private customerProfilesService: CustomerProfilesService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {

    }

    ngOnInit() {
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.route.queryParams.subscribe(params => {
            this.messageId = params['IT'] || null;

            if (this.messageId) {
                this.isCreation = false;
                this.customerProfilesService.getCustomerProfileById(this.messageId).subscribe(response => {
                    this.customerProfile = new CustomerProfile(response);
                });
            }
        });

        this.customerProfilesService.getAllCustomerProfiles().subscribe(response => {
            this.existingCustomerProfiles = response;

            this.webAdministrationService.getCompanies({ Status: "Valid", itemPerPage: 5000 })
                .subscribe(response => {
                    let comps = [...response.body];
                    let uniqueCompanies = comps.filter((item, index) => { return comps.findIndex(c => c?.Name?.toLowerCase() === item?.Name?.toLowerCase()) === index });
                    uniqueCompanies.forEach(company => {
                        if (this.existingCustomerProfiles.findIndex(cp => cp?.Company?.toLowerCase() === company?.Name?.toLowerCase()) === -1) {
                            this.companies.push(company);
                        }
                    });
                });
        });

        this.businessRulesService.getSpecificListByKey('TIMEZONES', 'TimeZones')
            .subscribe(response => { this.timeZones = response; });

        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'MailboxLicenses')
            .subscribe(response => { this.licenseTypes = response; });

        this.businessRulesService.getSpecificListByKey('DLTYPES', 'MobileMailClientTypes')
            .subscribe(response => { this.assignableDeviceTypes = response; });

        this.businessRulesService.getSpecificListByKey('LITIGATIONHOLDDURATIONS', 'AvailableLitigationHoldDurations')
            .subscribe(response => { this.litigationHoldDurations = response; });

        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => { this.languages = response; });
    }

    ngOnDestroy() {
    }

    sendRequest() {
        var data = new CustomerProfileRequest();
        data.requestParams = this.customerProfile;
        data.localIT = this.selectedLocalIT;


        this.customerProfilesService.addOrUpdateCustomerProfile(data)
            .subscribe(response => {
                let successSnackBar;
                if (this.isCreation) {
                    successSnackBar = this.snackBar.open("CUSTOMER PROFILE CREATED !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });
                } else {
                    successSnackBar = this.snackBar.open("CUSTOMER PROFILE UPDATED !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });
                }

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/customerProfiles']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    changeEnableArchiveStatus() {
        this.customerProfile.EnableArchive = !this.customerProfile.EnableArchive;
    }

    changeEnableLitigationHoldStatus() {
        this.customerProfile.EnableLitigationHold = !this.customerProfile.EnableLitigationHold;
    }

    changeExternalStatus() {
        this.customerProfile.External = !this.customerProfile.External;
    }
}