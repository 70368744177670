<div class="row ">
        <table class="table">
            <thead class="thead-default">
                <tr class="historySection" (click)="productCustomInfoCollapsed=!productCustomInfoCollapsed">
                    <th style="text-align: center;" colspan="2">
                        MAILBOX INFORMATION 
                        <i *ngIf="productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                        <i *ngIf="!productCustomInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!productCustomInfoCollapsed">
                <tr>
                    <th class="historyTableColWidthLimitation">New Primary SMTP</th>
                    <td>{{ changeMailboxPrimarySMTPDetails.NewPrimarySMTP }}</td>
                </tr>
                <tr  *ngIf="changeMailboxPrimarySMTPDetails.NewSIPAddress">
                    <th class="historyTableColWidthLimitation">New SIP Address</th>
                    <td>{{ changeMailboxPrimarySMTPDetails.NewSIPAddress }}</td>
                </tr>
                <tr>
                    <th class="historyTableColWidthLimitation">Old Primary SMTP</th>
                    <td>{{ changeMailboxPrimarySMTPDetails.OldPrimarySMTP }}</td>
                </tr>
                <tr *ngIf="changeMailboxPrimarySMTPDetails.NewSIPAddress">
                    <th class="historyTableColWidthLimitation">Old SIP Address</th>
                    <td>{{ changeMailboxPrimarySMTPDetails.OldSIPAddress }}</td>
                </tr>
            </tbody>
        </table>
    </div>