import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router'

import { GetDistributionListMembersFileContent, GetDistributionListMembersRequest } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { ModifyRequest} from '../../../Helpers/modifyRequest';
import { booleanToStringConverter } from '../../../Helpers/utils';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';



import { GraphApiService } from '../../../Services/graphApi.service';

@Component({
    selector: 'BodyContent',
    templateUrl: './getDistributionListInfo.component.html',
    styleUrls: ['../../../app.component.css']
})

export class  GetDistributionListInfoComponent{
    
    
    
    

    public fileContentsList = new Array<GetDistributionListMembersFileContent>();
    public fileContent = new GetDistributionListMembersFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';

    public listID:string;
    
    constructor(
        private gapiService: GraphApiService,
        private userService: UserService, 
        public snackBar: MatSnackBar, 
        private router: Router, 
        public dialog: MatDialog, 
        private modifyRequest: ModifyRequest
        ){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });
    }

    ngOnDestroy(){
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<GetDistributionListMembersFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new GetDistributionListMembersFileContent();
    }

    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }
    
    addFileContentToList(){
        var tempObject = new GetDistributionListMembersFileContent();
        tempObject.ListID = this.fileContent.ListID;

        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }
    
    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }

        var data = {
            query: this.listID,
            localIT: this.selectedLocalIT
        };
        
        //data.requests = this.fileContentsList;
        //data.localIT = this.selectedLocalIT;

        this.gapiService.getDistributionListInfo(this.listID, this.selectedLocalIT)
            .subscribe(
                response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
        );
    }
}