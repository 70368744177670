<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="distributionListInfoCollapsed=!distributionListInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    ASSIGNED APPS INFORMATION
                    <i *ngIf="distributionListInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!distributionListInfoCollapsed" style="float: right;"
                        class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!distributionListInfoCollapsed">
            <tr>
                <div class="row">
                    <div class="col-4">
                        <input *ngIf="config.filtering" placeholder="Filter all columns"
                            [ngTableFiltering]="config.filtering" class="form-control"
                            (tableChanged)="onChangeTable(config)" />
                    </div>
                </div>
                <br />

                <ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)"
                    [rows]="rows" [columns]="columns">
                </ng-table>
                <pagination *ngIf="config.paging"
                            class="pagination-sm"
                            [(ngModel)]="page"
                            [totalItems]="length"
                            [itemsPerPage]="itemsPerPage"
                            [maxSize]="maxSize"
                            [boundaryLinks]="true"
                            [rotate]="false"
                            (pageChanged)="onChangeTable(config, $event)"
                            (numPages)="numPages = $event">
                </pagination>
                <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
            </tr>
        </tbody>
    </table>
</div>