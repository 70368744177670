<h4>Get Distribution List Info:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="listIDInput">DistributionList ID (Display name or email):</label>
            <input type="text" id="listIDInput" name="listIDInput" class="form-control"
                    [(ngModel)]="listID" #listIDInput="ngModel"
                    required>

            <div *ngIf="listIDInput.invalid && (listIDInput.dirty || listIDInput.touched)"
                    class="alert alert-danger">
            
                <div *ngIf="listIDInput.errors.required">
                    DistributionList display name or email required.
                </div>              
            </div>            
        </div>           
    </div>
    <div class="row form-group">
        <div class="col-2" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="(fileContentForm.invalid && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>