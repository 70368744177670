import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/zscalerApis";
export const API_VERSION = "/v1";

@Injectable()
export class ZscalerApiService {
    constructor(private http: HttpClient) { }

    getZscalerCustomList(bypasstype): Observable<any> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getBypassSSlAuthenticationList';
        let params = new HttpParams();
        params = params.append('ByPassType', bypasstype);
        return this.http.get(request, { params: params }) as Observable<any>;
    }

    getZscalerDestinationGroupList(destinationtype, protocol): Observable<any> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/getFirewallDestinationGroupList';
        let params = new HttpParams();
        params = params.append('DestinationType', destinationtype);
        params = params.append('Protocol', protocol);
        return this.http.get(request, { params: params }) as Observable<any>;
    }

    addZscalerTunneling(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/addZscalerTunneling';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }

    deleteZscalerTunneling(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteZscalerTunneling';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }

    addZscalerAuthBypass(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/addZscalerAuthBypass';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }

    deleteZscalerAuthBypass(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteZscalerAuthBypass';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }

    addZscalerFirewall(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/addZscalerFirewall';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }

    deleteZscalerFirewall(data): Observable<Object> {
        let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteZscalerFirewall';
        return this.http.post(request, data).pipe(map(response => { return response }));
    }
}