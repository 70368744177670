import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { UserService } from '../../../Services/user.service';

import { CreateSharedMailboxFileContent, CreateSharedMailboxRequest } from '../../../Models/FileContents';

import { booleanToStringConverter, removeArrayEmptyValues } from '../../../Helpers/utils';
import { UserInfo } from '../../../Models/UserInfo';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './createSharedMailbox.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateSharedMailboxComponent{
    
    
    
        //Hardcoded List Values
    public languages:Array<any> = new Array<any>();
    public litigationHoldDurations:Array<string> = new Array<string>();
    
    public requestContainer:any = {};
    public fileContentsList = new Array<CreateSharedMailboxFileContent>();
    public fileContent = new CreateSharedMailboxFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    
    public groupIdNoAdminOrMailPattern: string = '((' + inputValidationHelper.groupIdNoAdminFormatRegEx + ')|(' + inputValidationHelper.mailFormatRegEx + '))';
    public namePattern:string = inputValidationHelper.mailboxNameRegEx;
    public customerProfileImported:boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
        
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private customerProfilesService: CustomerProfilesService, private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        //Subscribe to User Data (UserInfo, selected LocalIT)
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });

        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => {this.languages = response;});
        
        this.businessRulesService.getSpecificListByKey('LITIGATIONHOLDDURATIONS', 'AvailableLitigationHoldDurations')
            .subscribe(response => {this.litigationHoldDurations = response;});
    }

    ngOnDestroy(){
        
        
        
        
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdNoAdminFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    //Clear the whole page (form + fileContentsList)
    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateSharedMailboxFileContent>();
    }

    //Clear the form and set default values
    clearFileContentForms(){
        this.fileContent = new CreateSharedMailboxFileContent();
    }

    //Handle the collapse button display
    handleCollapseBtn(id){
        if(id){
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }        
    }

    //Method used in the html template of the page for displaying user friendly boolean value
    booleanToStringConverterImport(bool, falseString, trueString){
        return booleanToStringConverter(bool, falseString, trueString);
    }

    //Add the current fileContent to the fileContentsList and clean the form
    addFileContentToList(){
        var tempObject = new CreateSharedMailboxFileContent(this.fileContent);
        tempObject.MailAddresses = removeArrayEmptyValues(tempObject.MailAddresses);
        tempObject.AdditionalManager = removeArrayEmptyValues(tempObject.AdditionalManager);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }
    
    //Remove selected fileContent from the fileContentsList list
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    //Add a row to the specified multi value input
    addInputRow(arrayToModify){
        arrayToModify.push('');
    }

    //Remove a row from the specified multi value input
    removeInputRow(arrayToModify, index){
        arrayToModify.splice(index, 1);
    }

    //Send the request to the PEO API
    sendRequest(isFormInvalid?){
        //Add the current fileContent to the fileContentsList if the form is valid
        if(isFormInvalid == false){
            this.addFileContentToList();
        }

        //Initiatiate the request data
        var data = new CreateSharedMailboxRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;

        //Call the API via the service
        this.requestContainerService.sendProductRequest(data, '/createSharedMailbox')
            .subscribe( response => {
                            this.clearPage();

                            //Display a success notification for 2sec
                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            //Redirect to request history page
                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            //In case of API call error display a popup with information about the error
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );
    }

    importCustomerProfile(){
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if(inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)){
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if (response){
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if(response.PrimarySMTPdomain){
                            this.fileContent.PrimarySMTPDomain = response.PrimarySMTPdomain;
                            document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(response.Discriminant){
                            this.fileContent.Discriminant = response.Discriminant;
                            document.getElementById("createMailboxDiscriminantInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(response.EnableArchive){
                            this.fileContent.EnableArchive = response.EnableArchive;
                            document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(response.EnableLitigationHold){
                            this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                            document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #388e3c";
                            if(response.LitigationHoldDuration){
                                this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                document.getElementById("createMailboxLitigationHoldDuration").style.border = "2px solid #388e3c";
                            }else{
                                missingVal = true;
                            }       
                        }else{
                            missingVal = true;
                        }

                        if(response.MailboxLanguage){
                            this.fileContent.MailboxLanguage = response.MailboxLanguage;
                            document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }

                        if(missingVal){
                            this.importDefaultCustomerProfile();
                        }
                    }else{
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile(){
        let groupIDValueCopy = new String(this.fileContent.Manager ? this.fileContent.Manager : '');
        if(inputValidationHelper.checkGroupIdNoAdminFormat(groupIDValueCopy)){
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(                
                response => {
                    if(response){
                        if(!this.fileContent.PrimarySMTPDomain){
                            if(response.PrimarySMTPdomain){
                                this.fileContent.PrimarySMTPDomain = response.PrimarySMTPdomain;
                                document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if(!this.fileContent.Discriminant){
                            if(response.Discriminant){
                                this.fileContent.Discriminant = response.Discriminant;
                                document.getElementById("createMailboxDiscriminantInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if(!this.fileContent.EnableArchive){
                            if(response.EnableArchive){
                                this.fileContent.EnableArchive = response.EnableArchive;
                                document.getElementById("createMailboxEnableArchiveInput").style.border = "2px solid #fbc02d";
                            }
                        }
                        if(!this.fileContent.EnableLitigationHold){
                            if(response.EnableLitigationHold){
                                this.fileContent.EnableLitigationHold = response.EnableLitigationHold;
                                document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "2px solid #fbc02d";
                                if(!this.fileContent.LitigationHoldDuration){
                                    if(response.LitigationHoldDuration){
                                        this.fileContent.LitigationHoldDuration = response.LitigationHoldDuration;
                                        document.getElementById("createMailboxLitigationHoldDuration").style.border = "2px solid #fbc02d";
                                    }
                                }
                            }       
                        }

                        if(!this.fileContent.MailboxLanguage){
                            if(response.MailboxLanguage){
                                this.fileContent.MailboxLanguage = response.MailboxLanguage;
                                document.getElementById("createMailboxLanguageDropDown").style.border = "2px solid #fbc02d";
                                this.showImportedDefaultSuccess = true;
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                    }
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm(){
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";

        document.getElementById("createMailboxPrimarySMTPDomainInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxDiscriminantInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxEnableArchiveInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxEnableLitigationHoldInput").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxLitigationHoldDuration").style.border = "1px solid rgba(0,0,0,.15)";
        document.getElementById("createMailboxLanguageDropDown").style.border = "1px solid rgba(0,0,0,.15)";

        if(this.customerProfileImported){
            var groupId = this.fileContent.Manager;
            if(!inputValidationHelper.checkGroupIdNoAdminFormat(groupId)){
                this.fileContent.PrimarySMTPDomain = "";
                this.fileContent.Discriminant = "";
                this.fileContent.EnableArchive = false;
                this.fileContent.EnableLitigationHold = false;
                this.fileContent.LitigationHoldDuration = "";
                this.fileContent.MailboxLanguage = "";
                this.customerProfileImported = false;
            }
        }
    }
}