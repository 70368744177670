import { Component, Input } from '@angular/core';
import { MailboxPermissionDetails } from '../../../../Models/History/MailboxPermissionDetails';

@Component({
    selector: 'MailboxPermissionHistoryDetails',
    templateUrl: './mailboxPermissionHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class MailboxPermissionHistoryDetailsComponent{
    @Input() mailboxPermissionDetails: MailboxPermissionDetails;
    mailboxPermissionInfoCollapsed: boolean = false;
}