<div class="row ">
    <table class="table">
        <thead class="thead-default">
            <tr class="historySection" (click)="distributionListInfoCollapsed=!distributionListInfoCollapsed">
                <th style="text-align: center;" colspan="2">
                    DISTRIBUTION LIST MEMBERS INFORMATION 
                    <i *ngIf="distributionListInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">chevron_right</i>
                    <i *ngIf="!distributionListInfoCollapsed" style="float: right;" class="material-icons md-36 pointer">expand_more</i>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!distributionListInfoCollapsed">
            <tr>
                <th>Distribution Group</th>
                <td>{{ distributionListDetails.DistributionGroup }}</td>
            </tr>
            <tr>
                <th [ngSwitch]="distributionListDetails.ProductName">
                    <ng-container *ngSwitchCase="'Add-DistributionList-Members'">
                        Members Added
                    </ng-container>
                    <ng-container *ngSwitchCase="'Set-DistributionList-Members'">
                        Members Set
                    </ng-container>
                    <ng-container *ngSwitchCase="'Remove-DistributionList-Members'">
                        Members Removed
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        Members
                    </ng-container>
                </th>
                <td>
                    <ng-container *ngIf="(!distributionListDetails.Member || distributionListDetails.Member.length == 0) && distributionListDetails.Members && distributionListDetails.Members.length > 0">
                        <div *ngFor="let member of distributionListDetails.Members">
                            {{ member.DisplayName }} <b>{{ member.RecipientType ? '(' + member.RecipientType + ')' : ''}}</b><br />
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(!distributionListDetails.Members || distributionListDetails.Members.length == 0) && distributionListDetails.Member && distributionListDetails.Member.length > 0">
                        <div *ngFor="let member of distributionListDetails.Member; let i = index;">
                            {{ member }} <b>{{ distributionListDetails.RecipientTypes && distributionListDetails.RecipientTypes[i] ? '(' + distributionListDetails.RecipientTypes[i] + ')' : ''}}</b><br />
                        </div>
                    </ng-container>
                </td>                
            </tr>
        </tbody>
    </table>
</div>