<h4>Set Outlook Places:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <GroupIdOrMailInputField label="Group ID or email address" (groupIDDisplayNameChange)="fileContent.GroupIDDisplayName=$event" (groupIDDisplayNameErrorChange)="fileContent.GroupIDDisplayNameError=$event" [(groupID)]="fileContent.GroupID"></GroupIdOrMailInputField>
        </div>
        <div class="col-4">
            <label for="capacityInput">Capacity:</label>
            <input type="text" id="capacityInput" name="capacityInput" class="form-control"
                [(ngModel)]="fileContent.Capacity" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="floorNumber">Floor number:</label>
            <input type="text" id="floorNumber" name="floorNumber" class="form-control"
                [(ngModel)]="fileContent.FloorNumber" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="FloorLabel">Floor Label:</label>
            <input type="text" id="FloorLabel" name="FloorLabel" class="form-control"
                [(ngModel)]="fileContent.FloorLabel" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="Street">Street:</label>
            <input type="text" id="Street" name="Street" class="form-control" [(ngModel)]="fileContent.Street"
                #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="PostalCode">Postal code:</label>
            <input type="text" id="PostalCode" name="PostalCode" class="form-control"
                [(ngModel)]="fileContent.PostalCode" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="City">City:</label>
            <input type="text" id="City" name="City" class="form-control" [(ngModel)]="fileContent.City"
                #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="State">State:</label>
            <input type="text" id="State" name="State" class="form-control" [(ngModel)]="fileContent.State"
                #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="CountryOrRegion">Country or region:</label>
            <input type="text" id="CountryOrRegion" name="CountryOrRegion" class="form-control"
                [(ngModel)]="fileContent.CountryOrRegion" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="Building">Building:</label>
            <input type="text" id="Building" name="Building" class="form-control" [(ngModel)]="fileContent.Building"
                #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="Tags">Tags:</label>
            <input type="text" id="Tags" name="Tags" class="form-control" [(ngModel)]="fileContent.Tags"
                #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="IsWheelChairAccessible">Is wheelchair accessible:</label>
            <input type="checkbox" id="IsWheelChairAccessible" name="IsWheelChairAccessible" class="form-control"
                [(ngModel)]="fileContent.IsWheelChairAccessible" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="AudioDeviceName">Audio device name:</label>
            <input type="text" id="AudioDeviceName" name="AudioDeviceName" class="form-control"
                [(ngModel)]="fileContent.AudioDeviceName" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="VideoDeviceName">Video device name:</label>
            <input type="text" id="VideoDeviceName" name="VideoDeviceName" class="form-control"
                [(ngModel)]="fileContent.VideoDeviceName" #ticketIDInput="ngModel">
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    
    <div class="row form-group">
        <div class="col-1 offset-8">
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest();"
                [disabled]="(fileContentForm.invalid || fileContent.GroupIDDisplayNameError)">Send
                Request</button>
        </div>
    </div>
</form>