import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './Auth/token.interceptor';

import { AuthGuard } from './app.guard';

import { Ng2TableModule } from "../localPackage/ng2-table/ng2-table";
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AppComponent } from './app.component';

import { UserService } from './Services/user.service';
import { RequestItemService } from "./Services/requestItem.service";
import { RequestContainerService } from "./Services/requestContainer.service";
import { BusinessRulesService } from "./Services/businessRules.service";
import { LocalITsService } from "./Services/localITs.service";
import { HomeService } from "./Services/home.service";
import { WebAdministrationService } from "./Services/webAdministration.service";
import { CustomerProfilesService } from "./Services/customerProfiles.service";
import { DnsApiService } from "./Services/dnsApi.service";
import { DomainIDsService } from "./Services/domainIDs.service";
import { GraphApiService } from "./Services/graphApi.service";
import { OktaApiService } from "./Services/oktaApi.service";
import { ZscalerApiService } from "./Services/zscalerApi.service";
import { DeletedO365ObjectsService } from "./Services/deletedO365Objects.service";
import { DeletedDistributionListsService } from "./Services/deletedDistributionLists.service";
import { LicenseCleanupService } from "./Services/licenseCleanup.service";

import { RequestDetailsDialog } from "./Views/RequestsHistory/HistoryDetailsViews/RequestDetailsDialog/requestDetailsDialog.component";
import { LocalItCardsDialog } from "./Views/Administration/LocalItCards/LocalItCardsDialog/localItCardsDialog.component";
import { TopBarMessageDialog } from "./Views/Administration/opBarMessagesNew/TopBarMessageDialog/topBarMessageDialog.component";
import { CustomerProfileDialog } from "./Views/Administration/CustomerProfiles/CustomerProfileDialog/customerProfileDialog.component";
import { DeleteProfileConfirmDialog } from "./Views/Administration/CustomerProfiles/CustomerProfileDialog/deleteProfileConfirmDialog.component";
import { MessageDialog } from "./Views/Template/Dialog/messageDialog.component";
import { RequestErrorDialog } from "./Views/Template/Dialog/requestErrorDialog.component";
import { ConfirmationDialog } from "./Views/Template/Dialog/confirmationDialog.component";
import { ZScalerConfirmationDialog } from "./Views/Template/Dialog/zScalerConfirmationDialog.component";
import { DeleteO365ObjectConfirmationDialog } from "./Views/Template/Dialog/deleteO365ObjectConfirmationDialog.component";

import { GetIdentityPersonalDataHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetIdentityPersonalDataDetails/getIdentityPersonalDataHistoryDetails.component";
import { GetUserInfoHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetUserInfoDetails/getUserInfoHistoryDetails.component";
import { GetDistributionListInfoHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetDistributionListInfoDetails/getDistributionListInfoHistoryDetails.component";
import { GetDeletedUserInfoHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetDeletedUserInfoDetails/getDeletedUserInfoHistoryDetails.component";
import { GetDistributionListMembersHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/getDistributionListMembersHistoryDetails.component";
import { GetMailboxInfoHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetMailboxInfoDetails/getMailboxInfoHistoryDetails.component";
import { RequestDetailsParametersComponent } from "./Views/RequestsHistory/HistoryDetailsViews/RequestDetailsParameters/requestDetailsParameters.component";
import { DistributionListOwnerHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListOwnerHistoryDetails.component";
import { DistributionListMembersHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListMembersHistoryDetails.component";
import { RemoveDistributionListOwnerHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/removeDistributionListOwnerHistoryDetails.component";
import { DistributionListMailAddressesHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListMailAddressesHistoryDetails.component";
import { DistributionListDeliveryRestrictionHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListDeliveryRestrictionHistoryDetails.component";
import { CreatePersonalMailboxHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/CreatePersonalMailboxDetails/createPersonalMailboxHistoryDetails.component";
import { MailboxPermissionHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/MailboxPermissionDetails/mailboxPermissionHistoryDetails.component";
import { EnableLitigationHoldHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/EnableLitigationHoldDetails/enableLitigationHoldHistoryDetails.component";
import { CreateDistributionListHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/CreateDistributionListDetails/createDistributionListHistoryDetails.component";
import { EnableArchiveOnlineHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/EnableArchiveOnlineDetails/enableArchiveOnlineHistoryDetails.component";
import { ChangeMailboxManagerHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/ChangeMailboxManagerDetails/changeMailboxManagerHistoryDetails.component";
import { DeleteO365ObjectHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DeleteO365ObjectDetails/deleteO365ObjectHistoryDetails.component";
import { CreateMEUHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/CreateMEUDetails/createMEUHistoryDetails.component";
import { MailboxMailAddressesHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/MailboxMailAddressesDetails/mailboxMailAddressesHistoryDetails.component";
import { GetOktaAssignedAppHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetOktaAssignedAppDetails/getOktaAssignedAppHistoryDetails.component";
import { GetOktaGroupMembersHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/GetOktaGroupMembersDetails/getOktaGroupMembersHistoryDetails.component";
import { ChangeMailboxPrimarySMTPHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/ChangeMailboxPrimarySMTPDetails/changeMailboxPrimarySMTPHistoryDetails.component";
import { AddIntuneUserHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/AddIntuneUserDetails/addIntuneUserHistoryDetails.component";
import { AddAADGroupMemberHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/AddAADGroupMemberDetails/addAADGroupMemberHistoryDetails.component";
import { CreateHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/CreateDetails/createHistoryDetails.component";

import { DistributionListPrimarySMTPDetailsHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListPrimarySMTPHistoryDetails.component";
import { DistributionListSendAsDetailsHistoryDetailsComponent } from "./Views/RequestsHistory/HistoryDetailsViews/DistributionListDetails/distributionListSendAsHistoryDetails.component";

import { RequestHistoryComponent } from "./Views/RequestsHistory/requestHistory.component";
import { MultiValueInputFieldsComponent } from "./Views/Template/MultiValueInputFields/multiValueInputFields.component";
import { MenuItemComponent } from "./Components/menu-item/menu-item.component";
import { GroupIDInputFieldComponent } from "./Views/Template/GroupIDInputField/groupIDInputField.component";
import { GroupIDInputFieldOktaComponent } from "./Views/Template/GroupIDInputFieldOkta/groupIDInputFieldOkta.component";
import { GroupIdGuidOrMailInputFieldComponent } from "./Views/Template/GroupIdGuidOrMailInputField/groupIdGuidOrMailInputField.component";
import { GroupIdOrMailInputFieldComponent } from "./Views/Template/GroupIdOrMailInputField/groupIdOrMailInputField.component";
import { MailInputFieldOktaComponent } from "./Views/Template/MailInputFieldOkta/mailInputFieldOkta.component";

import { SharedModule } from "./Helpers/keysPipe";
import { MinValueValidatorDirective } from './Helpers/min-value.directive';
import { MaxValueValidatorDirective } from './Helpers/max-value.directive';
import { ModifyRequest } from './Helpers/modifyRequest';

import { HomeComponent } from './Views/home.component';
import { errorPageComponent } from './Views/errorPage.component';
import { LocalItCardsComponent } from './Views/Administration/LocalItCards/localItCards.component';
import { LicensesReportComponent } from './Views/Administration/LicensesReport/licensesReport.component';
import { CsvImportNewComponent } from './Views/Administration/CsvImportNew/csvImportNew.component';
import { LicenseCleanupComponent } from './Views/Administration/LicenseCleanup/licenseCleanup.component';
import { CustomerProfilesComponent } from './Views/Administration/CustomerProfiles/customerProfiles.component';
import { TopBarMessagesNewComponent } from './Views/Administration/opBarMessagesNew/opBarMessagesNew.component';
import { ManageLocalITCardComponent } from './Views/Administration/LocalItCards/ManageLocalITCard/ManageLocalITCard.component';
import { ManageTopBarMessageComponent } from './Views/Administration/opBarMessagesNew/ManageTopBarMessage/manageTopBarMessage.component';
import { ManageCustomerProfileComponent } from './Views/Administration/CustomerProfiles/ManageCustomerProfile/manageCustomerProfile.component';
import { ManageAdminUsersComponent } from './Views/Administration/ManageAdminUsers/manageAdminUsers.component';
import { CreatePersonalMailboxO365Component } from "./Views/Products/CreatePersonalMailboxO365/createPersonalMailboxO365.component";
import { GetDistributionListMembersComponent } from "./Views/Products/GetDistributionListMembers/getDistributionListMembers.component";
import { GetIdentityPersonalDataComponent } from "./Views/Products/GetIdentityPersonalData/getIdentityPersonalData.component";
import { GetUserInfoComponent } from "./Views/Products/GetUserInfo/getUserInfo.component";
import { GetMailboxInfoComponent } from "./Views/Products/GetMailboxInfo/getMailboxInfo.component";
import { AddOktaUserToGroupComponent } from "./Views/Products/AddOktaUserToGroup/addOktaUserToGroup.component";
import { GetOktaGroupMembersComponent } from "./Views/Products/GetOktaGroupMembers/getOktaGroupMembers.component";
import { GetOktaB2BGroupMembersComponent } from "./Views/Products/GetOktaB2BGroupMembers/getOktaB2BGroupMembers.component";
import { AddOktaB2BUserToGroupComponent } from "./Views/Products/AddOktaB2BUserToGroup/addOktaB2BUserToGroup.component";
import { RemoveOktaUserFromGroupComponent } from "./Views/Products/RemoveOktaUserFromGroup/removeOktaUserFromGroup.component";
import { RemoveOktaB2BUserFromGroupComponent } from "./Views/Products/RemoveOktaB2BUserFromGroup/removeOktaB2BUserFromGroup.component";
import { ResetOktaUserMfaComponent } from "./Views/Products/ResetOktaUserMfa/resetOktaUserMfa.component";
import { ResetOktaB2BUserMfaComponent } from "./Views/Products/ResetOktaB2BUserMfa/resetOktaB2BUserMfa.component";
import { CreateAADGroupComponent } from "./Views/Products/CreateAADGroup/createAADGroup.component";
import { CreateAADDynamicGroupComponent } from "./Views/Products/CreateAADDynamicGroup/createAADDynamicGroup.component";
import { AddDistributionListOwnerComponent } from "./Views/Products/AddDistributionListOwner/addDistributionListOwner.component";
import { RemoveDistributionListOwnerComponent } from "./Views/Products/RemoveDistributionListOwner/removeDistributionListOwner.component";
import { ChangeDistributionListOwnerComponent } from "./Views/Products/ChangeDistributionListOwner/changeDistributionListOwner.component";
import { AddDistributionListMembersComponent } from "./Views/Products/AddDistributionListMembers/addDistributionListMembers.component";
import { SetDistributionListMembersComponent } from "./Views/Products/SetDistributionListMembers/setDistributionListMembers.component";
import { RemoveDistributionListMembersComponent } from "./Views/Products/RemoveDistributionListMembers/removeDistributionListMembers.component";
import { EnableMobileDeviceComponent } from "./Views/Products/EnableMobileDevice/enableMobileDevice.component";
import { SetOutlookPlacesComponent } from "./Views/Products/SetOutlookPlaces/SetOutlookPlaces.component";
import { AddMailboxDelegationComponent } from "./Views/Products/AddMailboxDelegation/addMailboxDelegation.component";
import { RemoveMailboxDelegationComponent } from "./Views/Products/RemoveMailboxDelegation/removeMailboxDelegation.component";
import { AssignLicenseComponent } from "./Views/Products/AssignLicense/assignLicense.component";
import { RemoveLicenseComponent } from "./Views/Products/RemoveLicense/removeLicense.component";
import { GetLicensesDetailsComponent } from "./Views/Products/GetLicensesDetails/GetLicensesDetails.component";
import { ChangeLicensePlansComponent } from "./Views/Products/ChangeLicensePlans/ChangeLicensePlans.component";
import { EnableLitigationHoldComponent } from "./Views/Products/EnableLitigationHold/enableLitigationHold.component";
import { DisableLitigationHoldComponent } from "./Views/Products/DisableLitigationHold/disableLitigationHold.component";
import { CreateDistributionListComponent } from "./Views/Products/CreateDistributionList/createDistributionList.component";
import { EnableArchiveOnlineComponent } from "./Views/Products/EnableArchiveOnline/enableArchiveOnline.component";
import { ChangeMailboxDisplayNameComponent } from "./Views/Products/ChangeMailboxDisplayName/changeMailboxDisplayName.component";
import { ChangeMailboxManagerComponent } from "./Views/Products/ChangeMailboxManager/changeMailboxManager.component";
import { ChangeMailboxLanguageComponent } from "./Views/Products/ChangeMailboxLanguage/changeMailboxLanguage.component";
import { ResetOktaCloudAccountPasswordComponent } from "./Views/Products/ResetOktaCloudAccountPassword/resetOktaCloudAccountPassword.component";
import { ReactivateOktaB2BCloudAccountComponent } from "./Views/Products/ReactivateOktaB2BCloudAccount/reactivateOktaB2BCloudAccount.component";
import { ResetOktaB2BCloudAccountPasswordComponent } from "./Views/Products/ResetOktaB2BCloudAccountPassword/resetOktaB2BCloudAccountPassword.component";
import { CreateResourceMailboxComponent } from "./Views/Products/CreateResourceMailbox/createResourceMailbox.component";
import { EnableTeamsRoomMailboxComponent } from "./Views/Products/EnableTeamsRoomMailbox/enableTeamsRoomMailbox.component";
import { CreateSharedMailboxComponent } from "./Views/Products/CreateSharedMailbox/createSharedMailbox.component";
import { CreateAdminMailboxComponent } from "./Views/Products/CreateAdminMailbox/createAdminMailbox.component";
import { CreateAnonymousMailboxComponent } from "./Views/Products/CreateAnonymousMailbox/createAnonymousMailbox.component";
import { CreateApplicationMailboxComponent } from "./Views/Products/CreateApplicationMailbox/createApplicationMailbox.component";
import { CreateOktaCloudAccountComponent } from "./Views/Products/CreateOktaCloudAccount/createOktaCloudAccount.component";
import { CreateOktaB2BCloudAccountComponent } from "./Views/Products/CreateOktaB2BCloudAccount/createOktaB2BCloudAccount.component";
import { UpdateOktaCloudAccountComponent } from "./Views/Products/UpdateOktaCloudAccount/updateOktaCloudAccount.component";
import { UpdateOktaB2BCloudAccountComponent } from "./Views/Products/UpdateOktaB2BCloudAccount/updateOktaB2BCloudAccount.component";
import { GetOktaAssignedAppComponent } from "./Views/Products/GetOktaAssignedApp/getOktaAssignedApp.component";
import { BypassOktaMFAComponent } from "./Views/Products/BypassOktaMFA/bypassOktaMFA.component";
import { ExcludeOktaUserMFAComponent } from "./Views/Products/ExcludeOktaUserMFA/excludeOktaUserMFA.component";
import { DeleteO365ObjectComponent } from "./Views/Products/DeleteO365Object/deleteO365Object.component";
import { CreateMEUComponent } from "./Views/Products/CreateMEU/createMEU.component";
import { AddDistributionListMailAddressesComponent } from "./Views/Products/AddDistributionListMailAddresses/addDistributionListMailAddresses.component";
import { RemoveDistributionListMailAddressesComponent } from "./Views/Products/RemoveDistributionListMailAddresses/removeDistributionListMailAddresses.component";
import { AddDistributionListDeliveryRestrictionComponent } from "./Views/Products/AddDistributionListDeliveryRestriction/addDistributionListDeliveryRestriction.component";
import { RemoveDistributionListDeliveryRestrictionComponent } from "./Views/Products/RemoveDistributionListDeliveryRestriction/removeDistributionListDeliveryRestriction.component";
import { AddMailboxMailAddressesComponent } from "./Views/Products/AddMailboxMailAddresses/addMailboxMailAddresses.component";
import { RemoveMailboxMailAddressesComponent } from "./Views/Products/RemoveMailboxMailAddresses/removeMailboxMailAddresses.component";
import { UndeleteO365ObjectComponent } from "./Views/Products/UndeleteO365Object/undeleteO365Object.component";
import { UndeleteDistributionListComponent } from "./Views/Products/UndeleteDistributionList/undeleteDistributionList.component";
import { ChangeDistributionListPrimarySMTPComponent } from "./Views/Products/ChangeDistributionListPrimarySMTP/changeDistributionListPrimarySMTP.component";
import { ChangeMailboxPrimarySMTPComponent } from "./Views/Products/ChangeMailboxPrimarySMTP/changeMailboxPrimarySMTP.component";
import { RemoveDistributionListSendAsComponent } from "./Views/Products/RemoveDistributionListSendAs/removeDistributionListSendAs.component";
import { HideDistributionListComponent } from "./Views/Products/HideDistributionList/hideDistributionList.component";
import { UnHideDistributionListComponent } from "./Views/Products/UnHideDistributionList/unHideDistributionList.component";
import { HideMailboxComponent } from "./Views/Products/HideMailbox/hideMailbox.component";
import { UnHideMailboxComponent } from "./Views/Products/UnHideMailbox/unHideMailbox.component";
import { AddIntuneUserComponent } from "./Views/Products/AddIntuneUser/addIntuneUser.component";
import { ModifyIntuneUserComponent } from "./Views/Products/ModifyIntuneUser/modifyIntuneUser.component";
import { RemoveIntuneUserComponent } from "./Views/Products/RemoveIntuneUser/removeIntuneUser.component";
import { ResetGuestMFAComponent } from "./Views/Products/ResetGuestMFA/resetGuestMFA.component";
import { BlockUserComponent } from "./Views/Products/BlockUser/blockUser.component";
import { EnableUserComponent } from "./Views/Products/EnableUser/enableUser.component";
import { ResetUserTokenComponent } from "./Views/Products/ResetUserToken/resetUserToken.component";
import { AddAADGroupMemberComponent } from "./Views/Products/AddAADGroupMember/addAADGroupMember.component";
import { DeleteAzureADGroupComponent } from "./Views/Products/DeleteAzureADGroup/deleteAzureADGroup.component";
import { UndeleteAzureADGroupComponent } from "./Views/Products/UndeleteAzureADGroup/undeleteAzureADGroup.component";
import { RemoveAADGroupMemberComponent } from "./Views/Products/RemoveAADGroupMember/removeAADGroupMember.component";
import { EnableTeamsCAPPhoneComponent } from "./Views/Products/EnableTeamsCAPPhone/enableTeamsCAPPhone.component";
import { EnableTeamsPhoneSystemComponent } from "./Views/Products/EnableTeamsPhoneSystem/enableTeamsPhoneSystem.component";
import { RemoveTeamsPhoneSystemComponent } from "./Views/Products/RemoveTeamsPhoneSystem/removeTeamsPhoneSystem.component";
import { ClearOktaUserSessionComponent } from "./Views/Products/ClearOktaUserSession/clearOktaUserSession.component";
import { ClearOktaB2BUserSessionComponent } from "./Views/Products/ClearOktaB2BUserSession/clearOktaB2BUserSession.component";
import { UnlockOktaUserComponent } from "./Views/Products/UnlockOktaUser/unlockOktaUser.component";
import { UnlockOktaB2BUserComponent } from "./Views/Products/UnlockOktaB2BUser/unlockOktaB2BUser.component";
import { SuspendOktaUserComponent } from "./Views/Products/SuspendOktaUser/suspendOktaUser.component";
import { SuspendOktaB2BUserComponent } from "./Views/Products/SuspendOktaB2BUser/suspendOktaB2BUser.component";
import { UnsuspendOktaUserComponent } from "./Views/Products/UnsuspendOktaUser/unsuspendOktaUser.component";
import { UnsuspendOktaB2BUserComponent } from "./Views/Products/UnsuspendOktaB2BUser/unsuspendOktaB2BUser.component";
import { AddZScalerAuthByPassUserComponent } from "./Views/Products/AddZScalerAuthByPass/addZScalerAuthByPass.component";
import { DeleteZScalerAuthByPassUserComponent } from "./Views/Products/DeleteZScalerAuthByPass/deleteZScalerAuthByPass.component";
import { CreateGuestUserComponent } from "./Views/Products/CreateGuestUser/createGuestUser.component";
import { UpdateGuestUserComponent } from "./Views/Products/UpdateGuestUser/updateGuestUser.component";
import { DeleteGuestUserComponent } from "./Views/Products/DeleteGuestUser/deleteGuestUser.component";
import { TransferEmployeeComponent } from "./Views/Products/TransferEmployee/transferEmployee.component";
import { PublicDnsComponent } from "./Views/Products/PublicDns/publicDns.component";
import { ContactFieldCheckComponent } from "./Views/Template/ContactFieldCheck/contactFieldCheck.component";
import { AddMWUserComponent } from "./Views/Products/AddMWUser/addMWUser.component";
import { GetBitlockerKeyComponent } from "./Views/Products/GetBitlockerKey/getBitlockerKey.component";
import { GetLocalAdminPasswordComponent } from "./Views/Products/GetLocalAdminPassword/getLocalAdminPassword.component";
import { RemoveAzureADDeviceComponent } from "./Views/Products/RemoveAzureADDevice/removeAzureADDevice.component";
import { SetUserRingComponent } from "./Views/Products/SetUserRing/setUserRing.component";
import { DeleteDistributionListComponent } from "./Views/Products/DeleteDistributionList/deleteDistributionList.component";
import { AddZscalerFirewallComponent } from "./Views/Products/AddZScalerFirewall/addZscalerFirewall.component";
import { DeleteZScalerFirewallComponent } from "./Views/Products/DeleteZScalerFirewall/deleteZScalerFirewall.component";
import { GetDistributionListInfoComponent } from "./Views/Products/GetDistributionListInfo/getDistributionListInfo.component";
import { ZScalerTunnelingComponent } from "./Views/Products/ZScalerTunneling/zScalerTunneling.component";
import { DeleteZScalerTunnelingComponent } from "./Views/Products/DeleteZScalerTunneling/deleteZScalerTunneling.component";
import { AddAllocIDPermissionComponent } from "./Views/Products/AddAllocIDPermission/addAllocIDPermission.component";
import { RevokeAllocIDPermissionComponent } from "./Views/Products/RevokeAllocIDPermission/revokeAllocIDPermission.component";
import { CreateGIDComponent } from "./Views/Products/CreateGID/createGID.component";
import { ActivateGIDComponent } from "./Views/Products/ActivateGID/activateGID.component";
import { DeactivateGIDComponent } from "./Views/Products/DeactivateGID/deactivateGID.component";
import { UpdateAllocIDDataComponent } from "./Views/Products/UpdateAllocIDData/updateAllocIDData.component";
import { SetOffice365RetentionPolicyComponent } from "./Views/Products/SetOffice365RetentionPolicy/setOffice365RetentionPolicy.component";

//History
import { HeaderComponent } from './header/header.component';

import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './callback.component';
import { ProductHelpLinkComponent } from './Components/product-help-link/product-help-link.component';
import { OrderByPipe } from './order-by.pipe';

const routes: Routes = [
    { path: 'implicit/callback', component: CallbackComponent },
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'error', component: errorPageComponent },
    { path: 'history', component: RequestHistoryComponent, canActivate: [AuthGuard] },
    { path: 'localItCards', component: LocalItCardsComponent, canActivate: [AuthGuard] },
    { path: 'licensesReport', component: LicensesReportComponent, canActivate: [AuthGuard] },
    { path: 'licenseCleanup', component: LicenseCleanupComponent, canActivate: [AuthGuard] },
    { path: 'csvImport', component: CsvImportNewComponent, canActivate: [AuthGuard] },
    { path: 'customerProfiles', component: CustomerProfilesComponent, canActivate: [AuthGuard] },
    { path: 'administration/topBarMessages', component: TopBarMessagesNewComponent, canActivate: [AuthGuard] },
    { path: 'administration/manageLocalITCard', component: ManageLocalITCardComponent, canActivate: [AuthGuard] },
    { path: 'administration/manageTopBarMessage', component: ManageTopBarMessageComponent, canActivate: [AuthGuard] },
    { path: 'administration/manageCustomerProfile', component: ManageCustomerProfileComponent, canActivate: [AuthGuard] },
    { path: 'administration/manageAdminUsers', component: ManageAdminUsersComponent, canActivate: [AuthGuard] },
    { path: 'products/createPersonalMailbox', component: CreatePersonalMailboxO365Component, canActivate: [AuthGuard] },
    { path: 'products/getDistributionListMembers', component: GetDistributionListMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/getIdentityPersonalData', component: GetIdentityPersonalDataComponent, canActivate: [AuthGuard] },
    { path: 'products/getUserInfo', component: GetUserInfoComponent, canActivate: [AuthGuard] },
    { path: 'products/getMailboxInfo', component: GetMailboxInfoComponent, canActivate: [AuthGuard] },
    { path: 'products/addOktaUserToGroup', component: AddOktaUserToGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/getOktaGroupMembers', component: GetOktaGroupMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/getOktaB2BGroupMembers', component: GetOktaB2BGroupMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/addOktaB2BUserToGroup', component: AddOktaB2BUserToGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/removeOktaUserFromGroup', component: RemoveOktaUserFromGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/removeOktaB2BUserFromGroup', component: RemoveOktaB2BUserFromGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/resetOktaUserMFA', component: ResetOktaUserMfaComponent, canActivate: [AuthGuard] },
    { path: 'products/resetOktaB2BUserMFA', component: ResetOktaB2BUserMfaComponent, canActivate: [AuthGuard] },
    { path: 'products/createAADGroup', component: CreateAADGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/createAADDynamicGroup', component: CreateAADDynamicGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/addDistributionListOwner', component: AddDistributionListOwnerComponent, canActivate: [AuthGuard] },
    { path: 'products/removeDistributionListOwner', component: RemoveDistributionListOwnerComponent, canActivate: [AuthGuard] },
    { path: 'products/changeDistributionListOwner', component: ChangeDistributionListOwnerComponent, canActivate: [AuthGuard] },
    { path: 'products/addDistributionListMembers', component: AddDistributionListMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/setDistributionListMembers', component: SetDistributionListMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/removeDistributionListMembers', component: RemoveDistributionListMembersComponent, canActivate: [AuthGuard] },
    { path: 'products/enableMobileDevice', component: EnableMobileDeviceComponent, canActivate: [AuthGuard] },
    { path: 'products/setOutlookPlaces', component: SetOutlookPlacesComponent, canActivate: [AuthGuard] },
    { path: 'products/addMailboxDelegation', component: AddMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/removeMailboxDelegation', component: RemoveMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/addMailboxFullAccess', component: AddMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/removeMailboxFullAccess', component: RemoveMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/addMailboxSendAs', component: AddMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/removeMailboxSendAs', component: RemoveMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/assignLicense', component: AssignLicenseComponent, canActivate: [AuthGuard] },
    { path: 'products/removeLicense', component: RemoveLicenseComponent, canActivate: [AuthGuard] },
    { path: 'products/GetLicensesDetails', component: GetLicensesDetailsComponent, canActivate: [AuthGuard] },
    { path: 'products/ChangeLicensePlans', component: ChangeLicensePlansComponent, canActivate: [AuthGuard] },
    { path: 'products/enableLitigationHold', component: EnableLitigationHoldComponent, canActivate: [AuthGuard] },
    { path: 'products/disableLitigationHold', component: DisableLitigationHoldComponent, canActivate: [AuthGuard] },
    { path: 'products/createDistributionList', component: CreateDistributionListComponent, canActivate: [AuthGuard] },
    { path: 'products/addMailboxSendOnBehalf', component: AddMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/removeMailboxSendOnBehalf', component: RemoveMailboxDelegationComponent, canActivate: [AuthGuard] },
    { path: 'products/enableArchiveOnline', component: EnableArchiveOnlineComponent, canActivate: [AuthGuard] },
    { path: 'products/changeMailboxDisplayName', component: ChangeMailboxDisplayNameComponent, canActivate: [AuthGuard] },
    { path: 'products/changeMailboxManager', component: ChangeMailboxManagerComponent, canActivate: [AuthGuard] },
    { path: 'products/changeMailboxLanguage', component: ChangeMailboxLanguageComponent, canActivate: [AuthGuard] },
    { path: 'products/resetOktaCloudAccountPassword', component: ResetOktaCloudAccountPasswordComponent, canActivate: [AuthGuard] },
    { path: 'products/reactivateOktaB2BCloudAccount', component: ReactivateOktaB2BCloudAccountComponent, canActivate: [AuthGuard] },
    { path: 'products/resetOktaB2BCloudAccountPassword', component: ResetOktaB2BCloudAccountPasswordComponent, canActivate: [AuthGuard] },
    { path: 'products/createResourceMailbox', component: CreateResourceMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/enableTeamsRoomMailbox', component: EnableTeamsRoomMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/createSharedMailbox', component: CreateSharedMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/createAdminMailbox', component: CreateAdminMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/createAnonymousMailbox', component: CreateAnonymousMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/createApplicationMailbox', component: CreateApplicationMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/createOktaCloudAccount', component: CreateOktaCloudAccountComponent, canActivate: [AuthGuard] },
    { path: 'products/createOktaB2BCloudAccount', component: CreateOktaB2BCloudAccountComponent, canActivate: [AuthGuard] },
    { path: 'products/updateOktaCloudAccount', component: UpdateOktaCloudAccountComponent, canActivate: [AuthGuard] },
    { path: 'products/updateOktaB2BCloudAccount', component: UpdateOktaB2BCloudAccountComponent, canActivate: [AuthGuard] },
    { path: 'products/getOktaAssignedApp', component: GetOktaAssignedAppComponent, canActivate: [AuthGuard] },
    { path: 'products/bypassOktaMFA', component: BypassOktaMFAComponent, canActivate: [AuthGuard] },
    { path: 'products/excludeOktaUserMFA', component: ExcludeOktaUserMFAComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteO365Object', component: DeleteO365ObjectComponent, canActivate: [AuthGuard] },
    { path: 'products/createMEU', component: CreateMEUComponent, canActivate: [AuthGuard] },
    { path: 'products/addDistributionListMailAddresses', component: AddDistributionListMailAddressesComponent, canActivate: [AuthGuard] },
    { path: 'products/removeDistributionListMailAddresses', component: RemoveDistributionListMailAddressesComponent, canActivate: [AuthGuard] },
    { path: 'products/addDistributionListDeliveryRestriction', component: AddDistributionListDeliveryRestrictionComponent, canActivate: [AuthGuard] },
    { path: 'products/removeDistributionListDeliveryRestriction', component: RemoveDistributionListDeliveryRestrictionComponent, canActivate: [AuthGuard] },
    { path: 'products/addMailboxMailAddresses', component: AddMailboxMailAddressesComponent, canActivate: [AuthGuard] },
    { path: 'products/removeMailboxMailAddresses', component: RemoveMailboxMailAddressesComponent, canActivate: [AuthGuard] },
    { path: 'products/undeleteO365Object', component: UndeleteO365ObjectComponent, canActivate: [AuthGuard] },
    { path: 'products/undeleteDistributionList', component: UndeleteDistributionListComponent, canActivate: [AuthGuard] },
    { path: 'products/changeDistributionListPrimarySMTP', component: ChangeDistributionListPrimarySMTPComponent, canActivate: [AuthGuard] },
    { path: 'products/changeMailboxPrimarySMTP', component: ChangeMailboxPrimarySMTPComponent, canActivate: [AuthGuard] },
    { path: 'products/removeDistributionListSendAs', component: RemoveDistributionListSendAsComponent, canActivate: [AuthGuard] },
    { path: 'products/hideDistributionList', component: HideDistributionListComponent, canActivate: [AuthGuard] },
    { path: 'products/unHideDistributionList', component: UnHideDistributionListComponent, canActivate: [AuthGuard] },
    { path: 'products/hideMailbox', component: HideMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/unHideMailbox', component: UnHideMailboxComponent, canActivate: [AuthGuard] },
    { path: 'products/addIntuneUser', component: AddIntuneUserComponent, canActivate: [AuthGuard] },
    { path: 'products/modifyIntuneUser', component: ModifyIntuneUserComponent, canActivate: [AuthGuard] },
    { path: 'products/removeIntuneUser', component: RemoveIntuneUserComponent, canActivate: [AuthGuard] },
    { path: 'products/addAADGroupMember', component: AddAADGroupMemberComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteAzureADGroup', component: DeleteAzureADGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/undeleteAzureADGroup', component: UndeleteAzureADGroupComponent, canActivate: [AuthGuard] },
    { path: 'products/removeAADGroupMember', component: RemoveAADGroupMemberComponent, canActivate: [AuthGuard] },
    { path: 'products/resetGuestMFA', component: ResetGuestMFAComponent, canActivate: [AuthGuard] },
    { path: 'products/blockUser', component: BlockUserComponent, canActivate: [AuthGuard] },
    { path: 'products/resetUserToken', component: ResetUserTokenComponent, canActivate: [AuthGuard] },
    { path: 'products/enableUser', component: EnableUserComponent, canActivate: [AuthGuard] },
    { path: 'products/enableTeamsCAPPhone', component: EnableTeamsCAPPhoneComponent, canActivate: [AuthGuard] },
    { path: 'products/enableTeamsPhoneSystem', component: EnableTeamsPhoneSystemComponent, canActivate: [AuthGuard] },
    { path: 'products/removeTeamsPhoneSystem', component: RemoveTeamsPhoneSystemComponent, canActivate: [AuthGuard] },
    { path: 'products/clearOktaUserSession', component: ClearOktaUserSessionComponent, canActivate: [AuthGuard] },
    { path: 'products/clearOktaB2BUserSession', component: ClearOktaB2BUserSessionComponent, canActivate: [AuthGuard] },
    { path: 'products/unlockOktaUser', component: UnlockOktaUserComponent, canActivate: [AuthGuard] },
    { path: 'products/unlockOktaB2BUser', component: UnlockOktaB2BUserComponent, canActivate: [AuthGuard] },
    { path: 'products/suspendOktaUser', component: SuspendOktaUserComponent, canActivate: [AuthGuard] },
    { path: 'products/suspendOktaB2BUser', component: SuspendOktaB2BUserComponent, canActivate: [AuthGuard] },
    { path: 'products/unsuspendOktaUser', component: UnsuspendOktaUserComponent, canActivate: [AuthGuard] },
    { path: 'products/unsuspendOktaB2BUser', component: UnsuspendOktaB2BUserComponent, canActivate: [AuthGuard] },
    { path: 'products/addZscalerBypass', component: AddZScalerAuthByPassUserComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteZscalerBypass', component: DeleteZScalerAuthByPassUserComponent, canActivate: [AuthGuard] },
    { path: 'products/createGuestUser', component: CreateGuestUserComponent, canActivate: [AuthGuard] },
    { path: 'products/updateGuestUser', component: UpdateGuestUserComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteGuestUser', component: DeleteGuestUserComponent, canActivate: [AuthGuard] },
    { path: 'products/transferEmployee', component: TransferEmployeeComponent, canActivate: [AuthGuard] },
    { path: 'products/publicDns', component: PublicDnsComponent, canActivate: [AuthGuard] },
    { path: 'products/addMWUser', component: AddMWUserComponent, canActivate: [AuthGuard] },
    { path: 'products/getBitlockerKey', component: GetBitlockerKeyComponent, canActivate: [AuthGuard] },
    { path: 'products/getLocalAdminPassword', component: GetLocalAdminPasswordComponent, canActivate: [AuthGuard] },
    { path: 'products/removeAzureADDevice', component: RemoveAzureADDeviceComponent, canActivate: [AuthGuard] },
    { path: 'products/setUserRing', component: SetUserRingComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteDistributionList', component: DeleteDistributionListComponent, canActivate: [AuthGuard] },
    { path: 'products/addZscalerFirewall', component: AddZscalerFirewallComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteZscalerFirewall', component: DeleteZScalerFirewallComponent, canActivate: [AuthGuard] },
    { path: 'products/getDistributionListInfo', component: GetDistributionListInfoComponent, canActivate: [AuthGuard] },
    { path: 'products/addZscalerTunneling', component: ZScalerTunnelingComponent, canActivate: [AuthGuard] },
    { path: 'products/deleteZscalerTunneling', component: DeleteZScalerTunnelingComponent, canActivate: [AuthGuard] },
    { path: 'products/addAllocIDPermission', component: AddAllocIDPermissionComponent, canActivate: [AuthGuard] },
    { path: 'products/revokeAllocIDPermission', component: RevokeAllocIDPermissionComponent, canActivate: [AuthGuard] },
    { path: 'products/createGID', component: CreateGIDComponent, canActivate: [AuthGuard] },
    { path: 'products/activateGID', component: ActivateGIDComponent, canActivate: [AuthGuard] },
    { path: 'products/deactivateGID', component: DeactivateGIDComponent, canActivate: [AuthGuard] },
    { path: 'products/updateAllocIDData', component: UpdateAllocIDDataComponent, canActivate: [AuthGuard] },
    { path: 'products/setOffice365RetentionPolicy', component: SetOffice365RetentionPolicyComponent, canActivate: [AuthGuard] }
];

@NgModule({
    declarations: [
        AppComponent,
        CallbackComponent,
        HomeComponent,
        LicensesReportComponent,
        LicenseCleanupComponent,
        CreatePersonalMailboxO365Component,
        ManageTopBarMessageComponent,
        ManageCustomerProfileComponent,
        ManageAdminUsersComponent,
        CsvImportNewComponent,
        CustomerProfilesComponent,
        TopBarMessagesNewComponent,
        errorPageComponent,
        MinValueValidatorDirective,
        MaxValueValidatorDirective,
        RequestDetailsDialog,
        LocalItCardsDialog,
        TopBarMessageDialog,
        CustomerProfileDialog,
        DeleteProfileConfirmDialog,
        MessageDialog,
        RequestErrorDialog,
        ConfirmationDialog,
        ZScalerConfirmationDialog,
        DeleteO365ObjectConfirmationDialog,
        ContactFieldCheckComponent,
        GetIdentityPersonalDataHistoryDetailsComponent,
        GetUserInfoHistoryDetailsComponent,
        GetDeletedUserInfoHistoryDetailsComponent,
        GetDistributionListMembersHistoryDetailsComponent,
        GetMailboxInfoHistoryDetailsComponent,
        RequestDetailsParametersComponent,
        DistributionListOwnerHistoryDetailsComponent,
        RemoveDistributionListOwnerHistoryDetailsComponent,
        DistributionListMembersHistoryDetailsComponent,
        CreatePersonalMailboxHistoryDetailsComponent,
        MailboxPermissionHistoryDetailsComponent,
        EnableLitigationHoldHistoryDetailsComponent,
        CreateDistributionListHistoryDetailsComponent,
        EnableArchiveOnlineHistoryDetailsComponent,
        ChangeMailboxManagerHistoryDetailsComponent,
        DeleteO365ObjectHistoryDetailsComponent,
        CreateMEUHistoryDetailsComponent,
        DistributionListMailAddressesHistoryDetailsComponent,
        DistributionListDeliveryRestrictionHistoryDetailsComponent,
        MailboxMailAddressesHistoryDetailsComponent,
        GetOktaAssignedAppHistoryDetailsComponent,
        GetOktaGroupMembersHistoryDetailsComponent,
        ChangeMailboxPrimarySMTPHistoryDetailsComponent,
        AddIntuneUserHistoryDetailsComponent,
        AddAADGroupMemberHistoryDetailsComponent,
        DistributionListPrimarySMTPDetailsHistoryDetailsComponent,
        DistributionListSendAsDetailsHistoryDetailsComponent,
        CreateHistoryDetailsComponent,
        MultiValueInputFieldsComponent,
        MenuItemComponent,
        GroupIDInputFieldComponent,
        GroupIDInputFieldOktaComponent,
        GroupIdGuidOrMailInputFieldComponent,
        GroupIdOrMailInputFieldComponent,
        MailInputFieldOktaComponent,
        GetDistributionListMembersComponent,
        GetIdentityPersonalDataComponent,
        GetUserInfoComponent,
        RequestHistoryComponent,
        LocalItCardsComponent,
        LicensesReportComponent,
        ManageLocalITCardComponent,
        GetMailboxInfoComponent,
        AddOktaUserToGroupComponent,
        GetOktaGroupMembersComponent,
        GetOktaB2BGroupMembersComponent,
        AddOktaB2BUserToGroupComponent,
        RemoveOktaUserFromGroupComponent,
        RemoveOktaB2BUserFromGroupComponent,
        ResetOktaUserMfaComponent,
        ResetOktaB2BUserMfaComponent,
        CreateAADGroupComponent,
        CreateAADDynamicGroupComponent,
        AddDistributionListOwnerComponent,
        RemoveDistributionListOwnerComponent,
        ChangeDistributionListOwnerComponent,
        AddDistributionListMembersComponent,
        SetDistributionListMembersComponent,
        RemoveDistributionListMembersComponent,
        EnableMobileDeviceComponent,
        SetOutlookPlacesComponent,
        AddMailboxDelegationComponent,
        RemoveMailboxDelegationComponent,
        AssignLicenseComponent,
        EnableLitigationHoldComponent,
        DisableLitigationHoldComponent,
        CreateDistributionListComponent,
        EnableArchiveOnlineComponent,
        ChangeMailboxDisplayNameComponent,
        ChangeMailboxManagerComponent,
        RemoveLicenseComponent,
        GetLicensesDetailsComponent,
        ChangeLicensePlansComponent,
        ChangeMailboxLanguageComponent,
        ResetOktaCloudAccountPasswordComponent,
        ReactivateOktaB2BCloudAccountComponent,
        ResetOktaB2BCloudAccountPasswordComponent,
        CreateResourceMailboxComponent,
        EnableTeamsRoomMailboxComponent,
        CreateSharedMailboxComponent,
        CreateAdminMailboxComponent,
        CreateAnonymousMailboxComponent,
        CreateApplicationMailboxComponent,
        CreateOktaCloudAccountComponent,
        CreateOktaB2BCloudAccountComponent,
        UpdateOktaCloudAccountComponent,
        UpdateOktaB2BCloudAccountComponent,
        GetOktaAssignedAppComponent,
        BypassOktaMFAComponent,
        ExcludeOktaUserMFAComponent,
        DeleteO365ObjectComponent,
        CreateMEUComponent,
        AddDistributionListMailAddressesComponent,
        RemoveDistributionListMailAddressesComponent,
        AddDistributionListDeliveryRestrictionComponent,
        RemoveDistributionListDeliveryRestrictionComponent,
        AddMailboxMailAddressesComponent,
        RemoveMailboxMailAddressesComponent,
        ChangeDistributionListPrimarySMTPComponent,
        ChangeMailboxPrimarySMTPComponent,
        RemoveDistributionListSendAsComponent,
        UndeleteO365ObjectComponent,
        UndeleteDistributionListComponent,
        HideDistributionListComponent,
        UnHideDistributionListComponent,
        HideMailboxComponent,
        UnHideMailboxComponent,
        AddIntuneUserComponent,
        ModifyIntuneUserComponent,
        RemoveIntuneUserComponent,
        ResetGuestMFAComponent,
        BlockUserComponent,
        EnableUserComponent,
        ResetUserTokenComponent,
        AddAADGroupMemberComponent,
        DeleteAzureADGroupComponent,
        UndeleteAzureADGroupComponent,
        RemoveAADGroupMemberComponent,
        EnableTeamsCAPPhoneComponent,
        EnableTeamsPhoneSystemComponent,
        RemoveTeamsPhoneSystemComponent,
        ClearOktaUserSessionComponent,
        ClearOktaB2BUserSessionComponent,
        UnlockOktaUserComponent,
        UnlockOktaB2BUserComponent,
        SuspendOktaUserComponent,
        SuspendOktaB2BUserComponent,
        UnsuspendOktaUserComponent,
        UnsuspendOktaB2BUserComponent,
        AddZScalerAuthByPassUserComponent,
        DeleteZScalerAuthByPassUserComponent,
        CreateGuestUserComponent,
        UpdateGuestUserComponent,
        DeleteGuestUserComponent,
        TransferEmployeeComponent,
        PublicDnsComponent,
        AddMWUserComponent,
        GetBitlockerKeyComponent,
        GetLocalAdminPasswordComponent,
        RemoveAzureADDeviceComponent,
        SetUserRingComponent,
        HeaderComponent,
        DeleteDistributionListComponent,
        AddZscalerFirewallComponent,
        DeleteZScalerFirewallComponent,
        GetDistributionListInfoComponent,
        GetDistributionListInfoHistoryDetailsComponent,
        ZScalerTunnelingComponent,
        DeleteZScalerTunnelingComponent,
        AddAllocIDPermissionComponent,
        RevokeAllocIDPermissionComponent,
        CreateGIDComponent,
        ActivateGIDComponent,
        DeactivateGIDComponent,
        UpdateAllocIDDataComponent,
        SetOffice365RetentionPolicyComponent,
        ProductHelpLinkComponent,
        OrderByPipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        Ng2TableModule,
        SharedModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        MatToolbarModule,
        MatTooltipModule,
        MatMenuModule,
        MatTreeModule,
        MatExpansionModule,
        MatIconModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        CdkTreeModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatProgressBarModule,
        MatCheckboxModule,
        PaginationModule.forRoot(),
        RouterModule.forRoot(routes, {})
    ],
    exports: [],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: navigator.language },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthGuard,
        ModifyRequest,
        RequestItemService,
        RequestContainerService,
        BusinessRulesService,
        UserService,
        LocalITsService,
        HomeService,
        WebAdministrationService,
        CustomerProfilesService,
        DnsApiService,
        DomainIDsService,
        GraphApiService,
        OktaApiService,
        ZscalerApiService,
        DeletedO365ObjectsService,
        DeletedDistributionListsService,
        LicenseCleanupService
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }