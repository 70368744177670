export class CreateOktaCloudAccountDetails{
    GroupID: string = '';
    OktaCloudAccountPassword: string = '';

    constructor(requestItemOutput?) {
        if(requestItemOutput){
            this.GroupID = requestItemOutput.GroupID;
            this.OktaCloudAccountPassword = requestItemOutput.Password;
        }
    }
}