import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { OktaApiService } from '../../../Services/oktaApi.service';
import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'MailInputFieldOkta',
    templateUrl: './mailInputFieldOkta.component.html',
    styleUrls: ['../../../app.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class MailInputFieldOktaComponent{
    public mailPattern:string = '';
    mailDisplayNameValue:string = '';
    mailValue:string = '';
    mailDisplayNameErrorValue:string = '';
    mailProfileValue;

    constructor(private oktaApiService: OktaApiService){}    

    @Input() nameSuffix: string;
    @Input() mailShouldNotExist: boolean;
    @Input() mailShouldBeAnOktaCloudAccount: boolean;
    @Input() mailShouldNotBeAnOktaCloudAccount: boolean;
    @Input() mailShouldBeResetable: boolean;
    @Input() label: string;
    @Input() B2B: string;
    @Input() mandatory?: string;
    @Input() requiredUserType?: string;
    @Output() mailChange = new EventEmitter();
    @Output() mailDisplayNameChange = new EventEmitter();
    @Output() mailDisplayNameErrorChange = new EventEmitter();
    @Output() mailProfileChange = new EventEmitter();

    @Input()
    get mail() {
        return this.mailValue;
    }

    set mail(val){
        this.mailValue = val;
        this.mailChange.emit(this.mailValue);
        this.mailDisplayName = '';
        this.mailDisplayNameError = '';
        this.mailPattern = inputValidationHelper.mailFormatRegEx;
    }

    get mailDisplayNameError() {
        return this.mailDisplayNameErrorValue;
    }

    get mailDisplayName() {
        return this.mailDisplayNameValue;
    }

    get mailProfile(){
        return this.mailProfileValue;
    }

    set mailDisplayName(val){
        this.mailDisplayNameValue = val;
        this.mailDisplayNameChange.emit(this.mailDisplayNameValue);
    }

    set mailDisplayNameError(val){
        this.mailDisplayNameErrorValue = val;
        this.mailDisplayNameErrorChange.emit(this.mailDisplayNameErrorValue);
    }

    set mailProfile(val){
        this.mailProfileValue = val;
        this.mailProfileChange.emit(this.mailProfileValue);
    }

    checkMailValidity(){
        let mailValueCopy = new String(this.mailValue ? this.mailValue : '');
        if (inputValidationHelper.checkMailFormat(mailValueCopy)){
            this.oktaApiService[`get${this.B2B ? this.B2B : ''}UserByMail`](mailValueCopy.toString()).subscribe(
                response => {
                    if (!response) {
                        this.mailDisplayNameError = this.mailShouldNotExist ? mailValueCopy + ' is available!' : mailValueCopy + ' is not found in OKTA!';
                        this.mailDisplayName = '';
                    }
                    else {
                        if (this.mailShouldBeAnOktaCloudAccount && response.provider && response.provider.name != 'OKTA'){
                            this.mailDisplayNameError = mailValueCopy + " isn't an Okta Cloud Account!";
                            this.mailDisplayName = '';
                        }
                        else if (this.mailShouldBeAnOktaCloudAccount && this.B2B && !response.isPartner ){
                            this.mailDisplayNameError = mailValueCopy + " isn't a partner!";
                            this.mailDisplayName = '';
                        }
                        else if (this.mailShouldNotBeAnOktaCloudAccount && response.provider && response.provider.name == 'OKTA') {
                            this.mailDisplayNameError = mailValueCopy + " is an Okta Cloud Account!";
                            this.mailDisplayName = '';
                        } 
                        else if (this.mailShouldBeResetable && ['DEPROVISIONED','STAGED','SUSPENDED'].includes(response.status.toUpperCase())) {
                            this.mailDisplayNameError = `${mailValueCopy} is [${response.status}]`;
                            this.mailDisplayName = '';
                        }
                        else if (this.requiredUserType && response.userType?.toUpperCase() != this.requiredUserType) {
                            this.mailDisplayNameError = `${mailValueCopy} doesn't have userType ${this.requiredUserType} but [${response.userType}]`;
                            this.mailDisplayName = '';
                        } 
                        else {
                            if (response.displayName) {
                                this.mailDisplayName = response.displayName;
                            }
                            else {
                                this.mailDisplayName = response.lastName + ' ' + response.firstName;
                            }
                            this.mailProfile = response;
                            this.mailDisplayNameError = '';
                        }
                    }
                },
                error => {
                    if(error && error.status && error.status == '404'){                        
                        this.mailDisplayNameError = this.mailShouldNotExist ? mailValueCopy + ' is available!' : mailValueCopy + ' is not found in OKTA!';
                        this.mailDisplayName = '';
                    }else{
                        this.mailDisplayNameError = 'Error when trying to check Mail!';
                        this.mailDisplayName = '';
                    }
                }
            )
        }
    }

}