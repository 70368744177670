import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestItemService } from '../../Services/requestItem.service';
import { LocalITsService } from '../../Services/localITs.service';
import { RequestItem, RequestItemPreview } from '../../Models/RequestItem';
import { getStatusColor, getTargetValueByProduct, getQuotaColor } from '../../Helpers/utils';
import { RequestDetailsDialog } from './HistoryDetailsViews/RequestDetailsDialog/requestDetailsDialog.component';
import { UserService } from '../../Services/user.service';
import { UserInfo } from '../../Models/UserInfo';

import { LocalItCardQuota } from '../../Models/LocalItCard';

@Component({
  selector: 'BodyContent',
  templateUrl: './requestHistory.component.html'
})

export class RequestHistoryComponent {
  
  
  
  
  
  
  

  public page: number = 1;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public currentDate = new Date();
  public minHistoryDate = new Date((new Date()).setDate(this.currentDate.getDate() - 90));
  public maxHistoryDate = new Date();

  public ticketID: string = '';
  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  public index = 0;
  public itemsPerPage = 30;
  public order = 'DateCreated DESC'
  public filters = {
    "LocalIT": null,
    "Status": null,
    "Target": null,
    "Product": null,
    "Requestor": null,
    "TicketID": null,
    "fromDate": this.fromDate.toISOString(),
    "toDate": this.toDate.toISOString()
  }

  private _selectedLocalIT: String;
  private data: Array<any> = new Array<any>();
  public currentUserInfo: UserInfo = new UserInfo();
  public selectedLocalITQuota: LocalItCardQuota = new LocalItCardQuota();

  public refreshButtonDisabled: boolean = false;
  public clearFiltersDisabled: boolean = true;

  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: this.filters.LocalIT ? this.filters.LocalIT : "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
    { title: 'Product Name', name: 'ProductName', internalName: 'ProductName', filtering: { filterString: this.filters.Product ? this.filters.Product : "", placeholder: 'Product Filter' }, sort: '', className: 'productColumnWidth' },
    { title: 'Target', name: 'Target', internalName: 'Target', filtering: { filterString: this.filters.Target ? this.filters.Target : "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
    { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: this.filters.Status ? this.filters.Status : "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
    { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'dateColumnWidth' },
    { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'dateColumnWidth' },
    { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: this.filters.Requestor ? this.filters.Requestor : "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' }
  ];

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped']
  };
  public inProgress: boolean = false;

  getQuotaColor(currentQuota, defaultQuota) {
    return getQuotaColor(currentQuota, defaultQuota);
  }

  fromDateChanged(newValue) {
    var tempDate = new Date(newValue);
    if (!isNaN(tempDate.valueOf())) {
      tempDate = new Date(tempDate.setHours(0, 0, 0, 0));
    }
    this.fromDate = tempDate;
  }

  toDateChanged(newValue) {
    var tempDate = new Date(newValue);
    if (!isNaN(tempDate.valueOf())) {
      tempDate = new Date(tempDate.setHours(23, 59, 59, 999));
    }
    this.toDate = tempDate;
  }

  get selectedLocalIT() {
    return this._selectedLocalIT;
  }

  set selectedLocalIT(selectedLocalIT: String) {
    this._selectedLocalIT = selectedLocalIT;
    if (this._selectedLocalIT == 'SHOW ALL') {
      //Add LocalIT column for SHOW ALL mode
      this.config.className = 'table-striped';
    } else {
      //Remove LocalIT column for standard mode
      this.config.className = 'table-striped localit-hidden';
      this.filters.LocalIT = null;
      this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString = "";
    }
    this.refreshData();
  }


  public constructor(public dialog: MatDialog, private requestItemService: RequestItemService, private userService: UserService, private localITsService: LocalITsService) {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 4));
    this.fromDateChanged(this.fromDate);
    this.toDate = new Date();
    this.toDateChanged(this.toDate);
    this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
    this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
    this.dialog.afterAllClosed.subscribe();
  }

  //Mappeur
  mapRequestItemToRequestItemPreview(requestItems: RequestItem[]): Array<any> {
    let requestItemPreviewList = new Array<RequestItemPreview>();
    requestItems.forEach(element => {
      let requestItemPreview = new RequestItemPreview();

      requestItemPreview._key = element._key ? element._key : '';
      requestItemPreview.GUID = element.GUID ? element.GUID : '';
      requestItemPreview.Requestor = element.Requestor ? element.Requestor : '';
      requestItemPreview.Status = element.Status ? '<span style=\'color: ' + getStatusColor(element.Status) + ';font-weight:bold;\'>' + element.Status : '';
      requestItemPreview.Target = getTargetValueByProduct(element);
      requestItemPreview.CreatedOn = element.DateCreated ? new Date(element.DateCreated).toLocaleString(navigator.language) : '';
      requestItemPreview.CreatedOnDate = element.DateCreated;
      requestItemPreview.ModifiedOn = element.DateModified ? new Date(element.DateModified).toLocaleString(navigator.language) : '';
      requestItemPreview.ModifiedOnDate = element.DateModified;
      requestItemPreview.ProductName = element.ProductName ? element.ProductName : '';
      requestItemPreview.LocalIT = element.LocalIT ? element.LocalIT : '';

      requestItemPreviewList.push(requestItemPreview);
    });
    return requestItemPreviewList;
  }

  refreshData(disableButton?) {
    this.getFilters();
    if (this._selectedLocalIT) {
      this.inProgress = true;
      this.refreshButtonDisabled = true;
      this.requestItemService.getRequestItemsCount(this._selectedLocalIT, this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.length = response ? response : 0; });
      this.requestItemService.getRequestItems(this._selectedLocalIT, this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; this.refreshButtonDisabled = false;});
      this.localITsService.getLocalITQuotaByName(this._selectedLocalIT).subscribe(response => this.selectedLocalITQuota = new LocalItCardQuota(response));

      if (disableButton) {
        //this.disableRefreshBtn();
      }
    }
  }

  public getFilters() {
    if (this.config) {
      this.filters.LocalIT = this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString;
      this.filters.Product = this.config.sorting.columns.find(filter => filter.internalName === "ProductName").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "ProductName").filtering.filterString;
      this.filters.Status = this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString;
      this.filters.Target = this.config.sorting.columns.find(filter => filter.internalName === "Target").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Target").filtering.filterString;
      this.filters.Requestor = this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString;
      this.filters.TicketID = this.ticketID;
      this.filters.fromDate = this.fromDate.toISOString();
      this.filters.toDate = this.toDate.toISOString();
    }
    var sorted = this.config.sorting.columns.find(column => column.sort != "");
    this.order = sorted ? sorted.internalName + " " + sorted.sort : this.order;
    if (this.filters.LocalIT == null && this.filters.Product == null && this.filters.Status == null && this.filters.Target == null && this.filters.Requestor == null && this.filters.TicketID == null) {
      this.clearFiltersDisabled = true;
    }
    else {
      this.clearFiltersDisabled = false;
    }
  }

  clearFilters() {
    this.columns = [
      { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
      { title: 'Product Name', name: 'ProductName', internalName: 'ProductName', filtering: { filterString: "", placeholder: 'Product Filter' }, sort: '', className: 'productColumnWidth' },
      { title: 'Target', name: 'Target', internalName: 'Target', filtering: { filterString: "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' },
      { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
      { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'dateColumnWidth' },
      { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'dateColumnWidth' },
      { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' }
    ];
    this.filters = {
      "LocalIT": null,
      "Status": null,
      "Target": null,
      "Product": null,
      "Requestor": null,
      "TicketID": null,
      "fromDate": this.fromDate.toISOString(),
      "toDate": this.toDate.toISOString()
    }
    this.ticketID = null;
    this.config = {
      paging: true,
      sorting: { columns: this.columns },
      filtering: { filterString: '' },
      className: ['table-striped']
    };
    if (this._selectedLocalIT == 'SHOW ALL') {
      //Add LocalIT column for SHOW ALL mode
      this.config.className = 'table-striped';
    } else {
      //Remove LocalIT column for standard mode
      this.config.className = 'table-striped localit-hidden';
    }
    this.refreshData();
  }

  public ngOnDestroy() {
    
  }

  public changePage(event) {
    if (event != null) {
      this.index = (event.page - 1) * this.itemsPerPage;
    }
    this.onChangeTable();
  }

  public onChangeTable(event = null) {
    this.inProgress = true;
    this.getFilters();
    this.requestItemService.getRequestItemsCount(this._selectedLocalIT, this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.length = response ? response : 0; });
    this.requestItemService.getRequestItems(this._selectedLocalIT, this.filters, this.index, this.itemsPerPage, this.order).subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false;});
  }

  public onCellClick(data: any): any {
    if (data && data.row && data.row.GUID) {
      this.openRequestDetailsDialog(data.row.GUID);
    }
  }

  //Popup Initialization
  openRequestDetailsDialog(requestId) {
    const dialogRef = this.dialog.open(RequestDetailsDialog, {
      height: '73%',
      width: '80%',
      data: {
        requestId: requestId,
        selectedLocalIT: this._selectedLocalIT
      }
    });
  }

  disableRefreshBtn() {
    this.refreshButtonDisabled = true;
    setTimeout(() => { this.refreshButtonDisabled = false }, 2000);
  }
}