export const environment = {
    production: true,
    envName: 'pprod',
    domain: 'ensemble.infra.com',
    api_base_url: 'https://peo-api-ppr.myengie.com/api',
    apiv2_base_url: 'https://peo-ppr.myengie.com/api-pre/v2',
    okta_redirectUri: 'https://peo-ppr.myengie.com/implicit/callback',
    okta_logoutredirectUri: 'https://peo-ppr.myengie.com/',
    okta_appClientId: '0oa1h3bu0ub8wDZbV0i7',
    okta_scopes: ['openid', 'profile', 'email', 'groups'],
    okta_issuer: 'https://engie-pre.okta-emea.com/oauth2/aus1go1lrrvLS5vM80i7',
    ns_zones: 'ns-243.awsdns-30.com',
    ns_subzones: 'ns-1206.awsdns-22.org',
    org_it_url: 'https://org-it-ppr.myengie.com',
};