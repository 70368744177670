import { Component, Input } from '@angular/core';
import { CreateDistributionListDetails } from '../../../../Models/History/CreateDistributionListDetails';

@Component({
    selector: 'CreateDistributionListHistoryDetails',
    templateUrl: './createDistributionListHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class CreateDistributionListHistoryDetailsComponent{
    @Input() productCustomHistoryDetails: CreateDistributionListDetails;
    productCustomInfoCollapsed: boolean = false;
}