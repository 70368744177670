import { Component, Input } from '@angular/core';
import { GetDistributionListInfoDetails } from '../../../../Models/History/GetDistributionListInfoDetails';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'GetDistributionListInfoHistoryDetails',
    templateUrl: './getDistributionListInfoHistoryDetails.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class GetDistributionListInfoHistoryDetailsComponent{
    Array = Array;
    @Input() getDistributionListInfoDetails: GetDistributionListInfoDetails;

    distInfoCollapsed:boolean = false;
    ownersInfoCollapsed:boolean = true;
    membersInfoCollapsed:boolean = true;
    trusteeInfoCollapsed:boolean = true;
    acceptFromInfoCollapsed:boolean = true;
    rejectFromInfoCollapsed:boolean = true;
    moderatorsInfoCollapsed:boolean = true;
    
    getStatusColorImport(status:string){
        return getStatusColor(status);
    }

    showAll(){
        this.distInfoCollapsed = false;
        this.ownersInfoCollapsed = false;
        this.membersInfoCollapsed = false;
        this.trusteeInfoCollapsed = false;
        this.acceptFromInfoCollapsed = false;
        this.rejectFromInfoCollapsed = false;
        this.moderatorsInfoCollapsed = false;
    }
    
    hideAll(){
        this.distInfoCollapsed = true;
        this.ownersInfoCollapsed = true;
        this.membersInfoCollapsed = true;
        this.trusteeInfoCollapsed = true;
        this.acceptFromInfoCollapsed = true;
        this.rejectFromInfoCollapsed = true;
        this.moderatorsInfoCollapsed = true;
    }
}