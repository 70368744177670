import { Component, NgModule} from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { CustomerProfilesService } from '../../../Services/customerProfiles.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { AddIntuneUserFileContent, AddIntuneUserRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest} from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './addIntuneUser.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class AddIntuneUserComponent{
    
    
    
    

    public assignableDeviceTypes:Array<string> = new Array<string>();
    public listLocalIT:Array<string> = new Array<string>();
    public localITTeamsGroup:Array<string> = new Array<string>();
    public byDefaultTeamsGroup:Array<string> = new Array<string>();
    public topLocalITTeamsGroup:Array<string> = new Array<string>();
    public defaultTeamsGroupList:Array<string> = new Array<string>();
    public fileContentsList = new Array<AddIntuneUserFileContent>();
    public fileContent = new AddIntuneUserFileContent();
    public currentUserInfo:UserInfo = new UserInfo();
    public selectedLocalIT:String = '';
    public localITNotFound:string = '';
    public topLocalIT:Array<string> = ["IT_999","IT_EGS","IT_WSD"];
    public isTopLocalIT:boolean = false;
    public isAnyLocalITFromOkta:boolean = false;
    public isUserInOkta:boolean = false;
    public howToUseInfo:boolean = true;
    public searchLocalITGroup:string = '';
    public groupIDLabel:string = "Select Local IT:";
    public i = 0;
    public groupIDPattern:string = inputValidationHelper.groupIdFormatRegEx;
    public customerProfileImported:boolean = false;

    public showImportedCompanySuccess: boolean = false;
    public showImportedNoProfileDefaultSuccess: boolean = false;
    public showImportedDefaultSuccess: boolean = false;
    public showImportedCompanyError: boolean = false;
    public showImportedCompanyUserNotFoundError: boolean = false;
    public importedCompanyName: string = "";
    private requestParameters;

    // public testUserProfile: any = null;
    
    constructor(private requestContainerService: RequestContainerService, private userService: UserService, 
        private businessRulesService: BusinessRulesService, 
        private graphApiService: GraphApiService, 
        private oktaApiService: OktaApiService, 
        private localITsService: LocalITsService,
        public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog,
        private customerProfilesService: CustomerProfilesService,
        private modifyRequest: ModifyRequest){
        this.clearFileContentForms();
    }

    ngOnInit(){
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(
            updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.fileContent  = new AddIntuneUserFileContent();
                this.isAnyLocalITFromOkta = false;
                this.isUserInOkta = false;
                this.showImportedCompanySuccess = false;
                this.showImportedNoProfileDefaultSuccess = false;
                this.showImportedDefaultSuccess = false;
                this.showImportedCompanyError = false;
                this.showImportedCompanyUserNotFoundError = false;
                this.getLocalItTeamsGroup(this.selectedLocalIT.toString(), true, () => {});
                this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT).then( cb => {
                    if (cb) {
                        this.requestParameters = new AddIntuneUserFileContent(cb[0]);
                        let requestLocalIT = cb[1];
                        this.getLocalITManagerFromOkta((function(cb1) {
                            this.getLocalItTeamsGroup(this.requestParameters.ManagedLocalIT ? this.requestParameters.ManagedLocalIT.toString() : requestLocalIT.toString(), true, (function(cb2) { 
                                this.fileContent = this.requestParameters }).bind(this));
                        }).bind(this));
                    }
                });
                this.graphApiService.getGroupByDisplayName("EMM-INTUNE-IT_999-App").subscribe(
                    response => this.byDefaultTeamsGroup = response
                )
                // this.isTopLocalIT = this.topLocalIT.some(function(value, index, array){
                //     return value === abc;
                // });
                
                this.isTopLocalIT = this.topLocalIT.some((value) => value === this.selectedLocalIT) 

                if(this.isTopLocalIT){
                    this.localITsService.getAllLocalIT()
                        .subscribe(response => {
                            let localITs = <any> response;
                            this.listLocalIT = localITs.map(({id}) => id);
                            let index = this.listLocalIT.indexOf(this.selectedLocalIT.toString());
                            this.listLocalIT.splice(index, 1);
                        });
                }
                //this.groupIDLabel = "Manage Other Local IT:";
            });
        
        this.businessRulesService.getSpecificListByKey('DLTYPES', 'MobileMailClientTypes')
            .subscribe(response => {this.assignableDeviceTypes = response;});
        // call graph api for it_999 teams group
    }

    ngOnDestroy(){
        
        
        
    }

    isGroupIDFormatValid(groupID){
        return inputValidationHelper.checkGroupIdFormat(groupID);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage(){
        this.clearFileContentForms();
        this.fileContentsList = new Array<AddIntuneUserFileContent>();
    }

    clearFileContentForms(){
        this.fileContent = new AddIntuneUserFileContent();
    }

    handleCollapseBtn(id){
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addFileContentToList(){
        var tempObject = new AddIntuneUserFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
        this.localITTeamsGroup = this.byDefaultTeamsGroup;
    }
    
    removeFileContentFromList(index){
        this.fileContentsList.splice(index, 1);
    }

    sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var data = new AddIntuneUserRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/addIntuneUser')
            .subscribe( response => {
                            this.clearPage();

                            let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                                duration: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "right",
                                panelClass: ['bg-success', 'font-weight-bold'],                  
                            });

                            successSnackBar.afterDismissed().subscribe(null, null, () => {
                                this.router.navigate(['/history']);
                            })
                        }, 
                        error => {
                            this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
                        }
            );        
    }

    onTeamGroupToggle(tGroup:any, $event:any) {
        if ($event.target.checked)
            this.fileContent.Applications.push(tGroup.displayName);
        else {
            var index = this.fileContent.Applications.indexOf(tGroup.displayName);
            // var index = this.fileContent.Applications.map(g => g.id).indexOf(tGroup.id);
            this.fileContent.Applications.splice(index, 1);
        }
    }
    
    getLocalItTeamsGroup(localIT:string, firstcall = false, callback){
        if(localIT){
            let groupPattern = `EMM-INTUNE-${localIT}-App`;
            this.graphApiService.getGroupByDisplayName(groupPattern)
                .subscribe(response => {
                    this.localITTeamsGroup = response;
                    this.fileContent.Applications = [];
                    if(response.length === 0){
                        this.localITNotFound = `No AAD group for applications found for Local IT ${localIT}.`;
                        // this.fileContent.ManagedLocalIT = ''
                    } 
                    else{
                        this.localITNotFound = "";
                        if(localIT === this.selectedLocalIT){
                            this.topLocalITTeamsGroup = response;
                        }
                        // this.fileContent.ManagedLocalIT = this.searchLocalITGroup;
                    }
                    if(localIT != 'IT_999'){
                        this.localITTeamsGroup.push(...this.byDefaultTeamsGroup);
                        if(firstcall){
                            //save in memory the IT_999 and SelecetLocalIt teams app
                            this.defaultTeamsGroupList = this.localITTeamsGroup;
                            console.log(this.defaultTeamsGroupList);
                        }
                    }
                    if (callback) callback(response);
                }); 
        }
    }
    // Get The Manager Of The User From Okta
    getLocalITManagerFromOkta(callback){
        let gID = this.fileContent.GroupID.toString() ;
        this.fileContent.ManagedLocalIT = '';
        this.isAnyLocalITFromOkta = false;
        let managers = [];
        this.localITNotFound = '';
        this.isUserInOkta = false;
        this.howToUseInfo = false;

        if(gID){
            this.oktaApiService.getUserByGroupID(gID)
                .subscribe(
                    response => {
                        this.isUserInOkta = true;
                        let localIT = response.c_msDSCloudExtensionAttribute1
                        if(localIT){
                            let hasMultipleManager = localIT.indexOf(";");
                            this.isAnyLocalITFromOkta = true;
                            if(hasMultipleManager > 0){
                                managers.push(...localIT.split(";"));
                            }
                            else{
                                managers.push(localIT);
                            }

                            // delete IT_999 if it is in list of local it manager
                            let index = managers.indexOf("IT_999");
                            if(index !== -1)managers.splice(index, 1);

                            // #region Old Code - Now Will Be Handled In A DDL
                            // managers.sort();
                            // this.localITTeamsGroup = [];
                            // this.fileContent.Applications = [];

                            // for(let i = 0; i < managers.length;i++){
                            //     let groupPattern = `EMM-INTUNE-${managers[i]}-App`;
                            //     this.graphApiService.getGroupByDisplayName(groupPattern)
                            //         .subscribe(response => {
                            //             if(response!.length > 0){
                            //                 this.localITTeamsGroup.push(...response);
                            //             }
                            //         });
                            // }
                            // if(!this.isTopLocalIT){
                            //     this.localITTeamsGroup.push(...this.byDefaultTeamsGroup);
                            // }

                            // if(this.localITTeamsGroup.length === 0){
                            //     this.localITNotFound = `No AAD group for applications found for Local IT ${localIT}.`;
                            // } 
                            // else{
                            //     this.localITNotFound = "";    
                            // }
                            // #endregion

                            this.listLocalIT = managers;
                            if(!this.listLocalIT.some((value) => value === this.selectedLocalIT.toString())){
                                this.listLocalIT.push(this.selectedLocalIT.toString());
                            }
                            this.localITTeamsGroup = this.byDefaultTeamsGroup;
                           
                        }
                        else{
                            this.localITNotFound = `Local IT attribute not set in OKTA for ${gID}`;
                            this.localITTeamsGroup = [];
                            this.localITTeamsGroup = this.defaultTeamsGroupList;
                            //this.localITTeamsGroup.push(...this.byDefaultTeamsGroup);
                        }
                        if (callback) callback(this.listLocalIT);
                    },
                    (error) => {
                        if(error && error.status && error.status == '404'){
                            this.localITNotFound  = `${gID} not found in Okta!`;
                        }else{
                            this.localITNotFound = 'Error when trying to search GroupID in Okta!';
                        }
                        this.fileContent.Applications = [];
                        this.localITTeamsGroup = [];
                        this.fileContent.GroupID = '';
                        if (callback) callback(this.listLocalIT);
                    }
                    
                );
        }
    }

    importCustomerProfile(){
        this.customerProfileImported = true;
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importCustomerProfile(groupIDValueCopy.toString()).subscribe(
                response => {
                    if (response){
                        this.showImportedCompanySuccess = true;
                        this.importedCompanyName = response.Company;
                        var missingVal = false;
                        if(response.MobileMailClient){
                            this.fileContent.MobileMailClient = response.MobileMailClient;
                            document.getElementById("deviceTypeDropdownList").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                        if(response.AirWatchMigration){
                            this.fileContent.AirWatchMigration = response.AirWatchMigration;
                            document.getElementById("addIntuneUserAirWatchMigration").style.border = "2px solid #388e3c";
                        }else{
                            missingVal = true;
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                        this.importDefaultCustomerProfile();
                    }
                },
                error => {
                    if(error.status === 404){
                        this.showImportedCompanyUserNotFoundError = true;
                    }else{
                        this.showImportedCompanyError = true;
                    }
                }
            )
        }
    }

    importDefaultCustomerProfile(){
        let groupIDValueCopy = new String(this.fileContent.GroupID ? this.fileContent.GroupID : '');
        if(inputValidationHelper.checkGroupIdFormat(groupIDValueCopy)){
            this.customerProfilesService.importDefaultCustomerProfile().subscribe(
                response => {
                    if(response){
                        if(!this.fileContent.MobileMailClient){
                            if(response.MobileMailClient){
                                this.fileContent.MobileMailClient = response.MobileMailClient;
                                document.getElementById("deviceTypeDropdownList").style.border = "2px solid #fbc02d";                         
                            }
                        }
                        if(!this.fileContent.AirWatchMigration){
                            if(response.AirWatchMigration){
                                this.fileContent.AirWatchMigration = response.AirWatchMigration;
                                document.getElementById("addIntuneUserAirWatchMigration").style.border = "2px solid #fbc02d";
                            }
                        }
                    }else{
                        this.showImportedNoProfileDefaultSuccess = true;
                    }
                },
                error => {
                    this.showImportedCompanyError = true;
                }
            )
        }
    }

    public setBackEmptyForm(){
        this.showImportedCompanySuccess = false;
        this.showImportedNoProfileDefaultSuccess = false;
        this.showImportedDefaultSuccess = false;
        this.showImportedCompanyError = false;
        this.showImportedCompanyUserNotFoundError = false;
        this.importedCompanyName = "";
        this.fileContent.ManagedLocalIT = '';
        this.fileContent.Applications = [];
        this.fileContent.MobileMailClient = "";
        this.fileContent.AirWatchMigration = true;
        this.isUserInOkta = false;
        this.localITNotFound = '';
        if(this.isTopLocalIT){
            this.localITTeamsGroup = this.topLocalITTeamsGroup;

            document.getElementById("deviceTypeDropdownList").style.border = "1px solid rgba(0,0,0,.15)";
            document.getElementById("addIntuneUserAirWatchMigration").style.border = "1px solid rgba(0,0,0,.15)";
        
            //this.getLocalItTeamsGroup(this.selectedLocalIT.toString());
        }

        if(this.isAnyLocalITFromOkta){
            this.isAnyLocalITFromOkta = false;
        }
        
        if(this.customerProfileImported){
            var groupId = this.fileContent.GroupID;
            if(!inputValidationHelper.checkGroupIdFormat(groupId)){
                this.customerProfileImported = false;
            }
        }
    }
} 