import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAdmin } from '../Models/UserInfo';
import { BusinessUnit } from '../Models/BusinessUnit';
import { Entity } from '../Models/Entity';

export const API_BASE_URL = environment.api_base_url;
export const API_NAME = "/webAdministrations";
export const API_VERSION = "/v1";

@Injectable()
export class WebAdministrationService {
  constructor(private http: HttpClient) { }

  getUserAdminList(): Observable<UserAdmin[]> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getUserAdminList';
    return this.http.get(request) as Observable<UserAdmin[]>;
  }

  updateUserAdminList(data): any {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateUserAdminList';

    return this.http.put(request, data)
      .pipe(map(response => { return response }));
  }

  getBusinessUnits(params) {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getBusinessUnits';
    return this.http.post(request, { query: params }, { observe: 'response' }) as Observable<any>;
  }

  getGBUs(params) {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getGBUs';
    return this.http.post(request, { query: params }, { observe: 'response' }) as Observable<any>;
  }

  addBusinessUnit(data): Observable<[object]> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addBusinessUnit';
    return this.http.post(request, data) as Observable<[Object]>;
  }
  getBusinessUnitByKey(key): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getBusinessUnitByKey';
    let params = new HttpParams();
    params = params.append('key', key);
    return this.http.get(request, { params: params }) as Observable<any>;
  }

  updateBusinessUnit(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateBusinessUnit';
    return this.http.post(request, data) as Observable<BusinessUnit>;
  }

  deleteBusinessUnit(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteBusinessUnit';
    return this.http.post(request, data) as Observable<BusinessUnit>;
  }

  approuveBusinessUnit(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/approuveBusinessUnit';
    return this.http.post(request, data) as Observable<BusinessUnit>;
  }

  cancelBusinessUnit(bu): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/cancelBusinessUnit';
    return this.http.post(request, bu) as Observable<any>;
  }

  obsoleteBU(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/obsoleteBU';
    return this.http.post(request, data) as Observable<BusinessUnit>;
  }

  //#region entity
  getEntity(): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getEntity';
    return this.http.get(request) as Observable<any>;
  }

  getEntities(params): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getEntities';
    return this.http.post(request, { query: params }, { observe: 'response' }) as Observable<any>;
  }

  getUMs(params): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getUMs';
    return this.http.post(request, { query: params }, { observe: 'response' }) as Observable<any>;
  }

  getCompanies(params): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getCompanies';
    return this.http.post(request, { query: params }, { observe: 'response' }) as Observable<any>;
  }

  addEntity(data): Observable<[object]> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addEntity';
    return this.http.post(request, data) as Observable<[Object]>;
  }

  getEntityByKey(key): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getEntityByKey';
    let params = new HttpParams();
    params = params.append('key', key);
    return this.http.get(request, { params: params }) as Observable<any>;
  }

  updateEntity(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateEntity';
    return this.http.post(request, data) as Observable<Entity>;
  }

  approuveEntity(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/approuveEntity';
    return this.http.post(request, data) as Observable<Entity>;
  }

  deleteEntity(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteEntity';
    return this.http.post(request, data) as Observable<Entity>;
  }

  obsoleteEntity(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/obsoleteEntity';
    return this.http.post(request, data) as Observable<Entity>;
  }

  cancelEntity(entity): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/cancelEntity';
    return this.http.post(request, entity) as Observable<any>;
  }
  //#endregion

  //#region company
  getCompany(): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getCompany';
    return this.http.get(request) as Observable<any>;
  }

  addCompany(data): Observable<[object]> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addCompany';
    return this.http.post(request, data) as Observable<[Object]>;
  }

  getCompanyByKey(key): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getCompanyByKey';
    let params = new HttpParams();
    params = params.append('key', key);
    return this.http.get(request, { params: params }) as Observable<any>;
  }

  updateCompany(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateCompany';
    return this.http.post(request, data) as Observable<any>;
  }

  approuveCompany(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/approuveCompany';
    return this.http.post(request, data) as Observable<any>;
  }

  cancelCompany(entity): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/cancelCompany';
    return this.http.post(request, entity) as Observable<any>;
  }

  obsoleteCompany(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/obsoleteCompany';
    return this.http.post(request, data) as Observable<any>;
  }

  deleteCompany(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteCompany';
    return this.http.post(request, data) as Observable<any>;
  }

  //#endregion company

  //#region UM
  getUM(): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getUM';
    return this.http.get(request) as Observable<any>;
  }

  addUM(data): Observable<[object]> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/addUM';
    return this.http.post(request, data) as Observable<[Object]>;
  }

  getUMByKey(key): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getUMByKey';
    let params = new HttpParams();
    params = params.append('key', key);
    return this.http.get(request, { params: params }) as Observable<any>;
  }

  updateUM(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/updateUM';
    return this.http.post(request, data) as Observable<any>;
  }

  approuveUM(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/approuveUM';
    return this.http.post(request, data) as Observable<any>;
  }

  obsoleteUM(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/obsoleteUM';
    return this.http.post(request, data) as Observable<any>;
  }

  cancelUM(entity): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/cancelUM';
    return this.http.post(request, entity) as Observable<any>;
  }

  deleteUM(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/deleteUM';
    return this.http.post(request, data) as Observable<any>;
  }
  //#endregion

  //#region DirAdmin

  getBusinessUnitRelatedInfo(BU): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getBusinessUnitRelatedInfo';
    // let params = new HttpParams();
    // params = params.append('BU', BU);
    return this.http.get(request) as Observable<any>;
  }

  getCompanyRelatedInfo(): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getOrgAllEntity';
    return this.http.get(request)
  }

  getOrgDirectoryHistory(): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/getOrgDirectoryHistory';
    return this.http.get(request)
  }
  //#endregion

  sendEmail(data): Observable<any> {
    let request = API_BASE_URL + API_NAME + API_VERSION + '/sentMailNotification';
    return this.http.post(request, data) as Observable<any>;
  }
}