export class ChangeMailboxManagerDetails{
    Mailbox: string = '';
    OldManager: string = '';
    NewManager: string = '';
    ManagerSendAs: boolean = null;
    IsRequestStatusSuccess: boolean = false;

    constructor(requestItemOutput?, requestStatus?) {
        if(requestItemOutput){
            this.Mailbox = requestItemOutput.Mailbox ? requestItemOutput.Mailbox : '';
            this.OldManager = requestItemOutput.OldManager ? requestItemOutput.OldManager : '';
            this.NewManager = requestItemOutput.NewManager ? requestItemOutput.NewManager : '';
            this.ManagerSendAs = requestItemOutput.ManagerSendAs ? requestItemOutput.ManagerSendAs  : '';  
            
            if(requestStatus && requestStatus.toUpperCase().includes('SUCCESS')){
                this.IsRequestStatusSuccess = true;
            }
        }
    }
}