<h4>Change Mailbox Display Name:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="Group ID or email address">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                    [(ngModel)]="fileContent.GroupID" required [pattern]="groupIdNoAdminOrMailPattern" (keyup)="setBackEmptyForm()" #groupIDInput="ngModel">
                <i *ngIf="!groupIDInput.invalid" class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;"
                    (click)="getAADUser();">search</i>
            </div>
            <small *ngIf="fileContent.GroupIDDisplayName" id="groupIDDisplayName" class="form-text text-primary">{{ fileContent.GroupIDDisplayName }}</small>
            <small *ngIf="fileContent.GroupIDDisplayNameError" id="groupIDDisplayNameError" class="form-text text-danger">{{ fileContent.GroupIDDisplayNameError }}</small>
        </div>
        <div *ngIf="fileContent.GroupIDDisplayName && (fileContent.ExtensionAttribute11.includes('ROOM') || fileContent.ExtensionAttribute11.includes('EQUIP'))" class="col-4">
            <label class="required" for="createMailboxSiteInput">Site:</label>
            <input type="text" id="createMailboxSiteInput" class="form-control" maxlength="10" minlength="2" (change)="calculateCharacters();" (keyup)="calculateCharacters();"
                [(ngModel)]="fileContent.Site" required name="createMailboxSiteInput" #createMailboxSiteInput="ngModel">
            <div *ngIf="createMailboxSiteInput.invalid && (createMailboxSiteInput.dirty || createMailboxSiteInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxSiteInput.errors.required">
                    Site is required.
                </div>
                <div *ngIf="createMailboxSiteInput.errors?.minlength">
                    Site must be between 2 and 10 characters.
                </div>
            </div>
            <span [class]="errorsInForm() ? 'text-danger' : 'text-primary'" style="white-space: nowrap">{{message}}</span>
        </div>
        <div *ngIf="fileContent.GroupIDDisplayName" class="col-4">
            <label class="required" for="nameInput">Name:</label>
            <input type="text" id="nameInput" name="nameInput" class="form-control" minlength="3" maxlength="42" (change)="calculateCharacters();" (keyup)="calculateCharacters();" [pattern]="namePattern"
                [(ngModel)]="fileContent.Name" #nameInput="ngModel" required>
            <div *ngIf="nameInput.invalid && (nameInput.dirty || nameInput.touched)"
                    class="alert alert-danger">
                <div *ngIf="nameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="nameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
                <div *ngIf="nameInput.errors?.minlength">
                    Name must be between 3 and 42 characters.
                </div>
            </div>            
        </div>
        <div *ngIf="fileContent.GroupIDDisplayName && !fileContent.ExtensionAttribute11.includes('ROOM') && !fileContent.ExtensionAttribute11.includes('EQUIP')" class="col-4">
            <label for="discriminantInput">Discriminant:</label>
            <input type="text" id="discriminantInput" name="discriminantInput" class="form-control"
                [(ngModel)]="fileContent.Discriminant">         
        </div>
    </div>
    <div *ngIf="fileContent.GroupIDDisplayName" class="row form-group">
        <div *ngIf="fileContent.ExtensionAttribute11.includes('ROOM') || fileContent.ExtensionAttribute11.includes('EQUIP')" class="col-4">
            <label class="required" for="createMailboxCountryCodeInput">Country Code:</label>
            <input type="text" id="createMailboxCountryCodeInput" class="form-control"
                [(ngModel)]="fileContent.CountryCode" required name="createMailboxCountryCodeInput" [maxlength]="2" #createMailboxCountryCodeInput="ngModel">
            <div *ngIf="createMailboxCountryCodeInput.invalid && (createMailboxCountryCodeInput.dirty || createMailboxCountryCodeInput.touched)" class="alert alert-danger">
                <div *ngIf="createMailboxCountryCodeInput.errors.required">
                    Country Code is required.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label for="brandInput">Brand:</label>
            <input type="text" id="brandInput" name="brandInput" class="form-control"
                [(ngModel)]="fileContent.Brand">         
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div *ngIf="fileContent.GroupIDDisplayName || (fileContentsList && fileContentsList.length > 0)" class="row form-group">                
        <div class="col-1 offset-8">
            <button type="submit" class="btn btn-primary" (click)="addFileContentToList(); fileContentForm.form.markAsPristine(); fileContentForm.form.markAsUntouched();" [disabled]="fileContentForm.invalid || fileContent.GroupIDDisplayNameError || !fileContent.GroupIDDisplayName || errorsInForm()" style="margin-top:32px">Add to list</button>
        </div>
        <div class="col-2 offset-1" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="((fileContentForm.invalid || fileContent.GroupIDDisplayNameError || errorsInForm()) && (!fileContentsList || (fileContentsList && fileContentsList.length == 0)))">Send Request</button>
        </div>
    </div>
</form>

<div *ngIf="fileContentsList && fileContentsList.length > 0">
    <br />
    <hr class="bg-primary" />
    <br />
</div>

<div class="row" *ngFor="let listedFileContent of fileContentsList; let i = index">
    <a class="col-10" (click)="handleCollapseBtn('listedFileContent' + i + 'expandBtn')" data-toggle="collapse" [attr.href]="'#listedFileContent' + i">
        <span style="font-size:1.5em;">
            <b style="color: #0275d8">{{ listedFileContent.GroupID }} <span *ngIf="listedFileContent.GroupIDDisplayName">({{ listedFileContent.GroupIDDisplayName }})</span></b> 
            <i [attr.id]="'listedFileContent' + i + 'expandBtn'" style="vertical-align: bottom" class="material-icons md-36 pointer">chevron_right</i>                        
        </span>
    </a>
    <i id="{{'removeRequestBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;vertical-align: bottom;" (click)="removeFileContentFromList(i)">remove_circle_outline</i>
    <div class=" collapse" [attr.id]="'listedFileContent' + i">
        <div class="row">
            <div class="col-4">
                <b>Group ID or email address:</b> {{ listedFileContent.GroupID }}
            </div>
            <div *ngIf="listedFileContent.ExtensionAttribute11.includes('ROOM') || listedFileContent.ExtensionAttribute11.includes('EQUIP')" class="col-4">
                <b>Country Code:</b> {{ listedFileContent.CountryCode }}           
            </div>
            <div *ngIf="listedFileContent.ExtensionAttribute11.includes('ROOM') || listedFileContent.ExtensionAttribute11.includes('EQUIP')" class="col-4">
                <b>Site:</b> {{ listedFileContent.Site }}           
            </div>
            <div *ngIf="!listedFileContent.ExtensionAttribute11.includes('ROOM') && !listedFileContent.ExtensionAttribute11.includes('EQUIP')" class="col-4">
                <b>Discriminant:</b> {{ listedFileContent.Discriminant }}           
            </div>
            <div class="col-4">
                <b>Name:</b> {{ listedFileContent.Name }}           
            </div>
            <div class="col-4">
                <b>Brand:</b> {{ listedFileContent.Brand }}           
            </div>
            <div class="col-4">
                <b>Ticket ID:</b> {{ listedFileContent.TicketID }}
            </div> 
        </div>
    </div>
</div>