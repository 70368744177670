import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../../Services/requestContainer.service';
import { CustomerProfile} from '../../../../Models/CustomerProfile';
import { CustomerProfilesService } from '../../../../Services/customerProfiles.service';
import { UserService } from '../../../../Services/user.service';
import { BusinessRulesService } from '../../../../Services/businessRules.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestErrorDialog } from '../../../Template/Dialog/requestErrorDialog.component';
import { DeleteProfileConfirmDialog } from './deleteProfileConfirmDialog.component';
import { getStatusColor } from '../../../../Helpers/utils';

@Component({
    selector: 'customerProfileDialog',
    templateUrl: './customerProfileDialog.component.html',
    providers: [RequestContainerService, BusinessRulesService],
    styleUrls: ['../../../../app.component.css']
})

export class CustomerProfileDialog {

    public fileContentsList = new Array<CustomerProfile>();
    public customerProfile = new CustomerProfile();
    public selectedLocalIT:String = '';
    public isAllowedToEditLocalItCard:boolean = false;
    public timeZones:Array<any> = new Array<any>();
    public licenseTypes:Array<any> = new Array<any>();
    public languages:Array<any> = new Array<any>();
    

    constructor(private userService: UserService, private requestContainerService: RequestContainerService, public dialogRef: MatDialogRef<CustomerProfileDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private customerProfilesService: CustomerProfilesService, private businessRulesService: BusinessRulesService, private router: Router, public dialog: MatDialog, public snackBar: MatSnackBar,) {    
        if (data && data.messageID) {
            this.customerProfilesService.getCustomerProfileById(data.messageID).subscribe(response => { 
                this.customerProfile = new CustomerProfile(response);
            }); 

            this.isAllowedToEditLocalItCard = data.isAdmin ? data.isAdmin : false;
        }
    }

    ngOnInit(){
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);

        this.businessRulesService.getSpecificListByKey('TIMEZONES', 'TimeZones')
            .subscribe(response => {
                this.timeZones = response;
                if(this.customerProfile.TimeZone){
                    this.timeZones.forEach(timeZone => {
                        if(timeZone.StandardName == this.customerProfile.TimeZone){
                            this.customerProfile.TimeZone = timeZone.DisplayName;
                        }
                    });
                }
            });
        
        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'MailboxLicenses')
            .subscribe(response => {
                this.licenseTypes = response;
                if(this.customerProfile.Office365Licenses){
                    this.licenseTypes.forEach(licence => {
                        if(licence.code == this.customerProfile.Office365Licenses){
                            this.customerProfile.Office365Licenses = licence.name;
                        }
                    });
                }
            });

        this.businessRulesService.getSpecificListByKey('LANGUAGES', 'AvailableLanguages')
            .subscribe(response => {
                this.languages = response;
                if(this.customerProfile.MailboxLanguage){
                    this.languages.forEach(language => {
                        if(language.code == this.customerProfile.MailboxLanguage){
                            this.customerProfile.MailboxLanguage = language.name;
                        }
                    });
                }
            });
    }

    getStatusColorImport(status: string) {
        return getStatusColor(status);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    navigateToCustomerProfileEdition(){
        this.dialogRef.close();
        this.router.navigate(['/administration/manageCustomerProfile'], { queryParams: { IT: this.customerProfile.id}});
    }

    confirmDeleteCustomerProfile(){
        this.openCustomerProfileDialog(this.customerProfile.id);
    }

    //Popup Initialization
    openCustomerProfileDialog(messageID) {
        this.dialogRef.close();
        const dialogRef = this.dialog.open(DeleteProfileConfirmDialog, {
            height: '30%',
            width: '50%',
            data: {
                messageID: messageID,
                isAdmin: this.isAllowedToEditLocalItCard
            }
        });
    }

    changeEnableArchiveStatus(){
        this.customerProfile.EnableArchive = !this.customerProfile.EnableArchive;
    }

    changeEnableLitigationHoldStatus(){
        this.customerProfile.EnableLitigationHold = !this.customerProfile.EnableLitigationHold;
    }

    changeExternalStatus(){
        this.customerProfile.External = !this.customerProfile.External;
    }
}
